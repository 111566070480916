import React, { Component } from "react";
import logoheader from "../../assets/logoheaderlifestyleblack.png";
import logoheaderWhite from "../../assets/logoheaderlifestyle.png";
import "./index.css";
import { GiHotMeal } from "react-icons/gi";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiDoubleQuotesR } from "react-icons/ri";
import { FiLogIn } from "react-icons/fi";
import { AiFillGift } from "react-icons/ai";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase";
import countries from "../../DataJSON/countries.json";
import Switch from "@material-ui/core/Switch";
import PersonIcon from "@material-ui/icons/Person";
import FooterBasket from "../../Components/FooterBasket";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { connect } from "react-redux";
import {
  updateAdminOrNot,
  updateGlobalStuff,
} from "../../Redux/actions/authActions";
import axios from "axios";
import whitestarnav from "../../assets/whitestarnav.png";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

AOS.init();

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class Commercial extends Component {
  constructor() {
    super();
    this.state = {
      events: [],
      showDutch: true,
      menuOpen: false,
      allUsers: [],
      country: "155",
      isUserForm: true,
      showCharityStuff: false,
      subscription_data: [],
      plan_id: "",
      plan_amount: "",
      // registration fields
      firstName: "",
      firstNamePopup: false,
      lastName: "",
      lastNamePopup: false,
      email: "",
      emailPopup: false,
      password: "",
      passwordPopup: false,
      country: "155",
      companyRegistration: false,
      companyName: "",
      kvkCompany: "",
      kvkCompanyPopup: false,
      street: "",
      streetPopup: false,
      houseNumber: "",
      houseNumberPopup: false,
      city: "",
      cityPopup: false,
      zipCode: "",
      zipCodePopup: false,
      phoneNumber: "",
      phoneNumberPopup: false,
      company_website: "",
      company_websitePopup: false,
      passwordInputType: "password",
      guestUserCart: [],
      aanmeldenloader: false,
      allUsers: [],
      agreed: false,
      agreedPopup: false,
      currentUserSpecific: "",
      isUserCreated: false,
      currentDataOfUser: "",
      popupopenforright: true,
      commercial_page_banner_dutch: "",
      commercial_page_banner_english: "",
      commercial_page_van_start_dutch: "",
      commercial_page_van_start_english: "",
      commercial_page_events_dutch: "",
      commercial_page_events_english: "",
      commercial_page_fan_base_dutch: "",
      commercial_page_fan_base_english: "",
      commercial_page_profile_dutch: "",
      commercial_page_profile_english: "",
      commercial_page_about_dutch: "",
      commercial_page_about_english: "",
      commercial_page_team_bhodi_dutch: "",
      commercial_page_team_bhodi_english: "",
      commercial_page_aanmelding_dutch: "",
      commercial_page_aanmelding_english: "",
      commercial_page_charity_member_dutch: "",
      commercial_page_charity_member_english: "",
      commercial_page_banner_dutch_heading: "",
      commercial_page_banner_english_heading: "",
      commercial_page_van_start_dutch_heading: "",
      commercial_page_van_start_english_heading: "",
      commercial_page_events_dutch_heading_inner: "",
      commercial_page_events_english_heading_inner: "",
      commercial_page_fan_base_dutch_heading: "",
      commercial_page_fan_base_english_heading: "",
      commercial_page_profile_dutch_heading: "",
      commercial_page_profile_english_heading: "",
      commercial_page_about_dutch_heading: "",
      commercial_page_about_english_heading: "",
      commercial_page_team_bhodi_dutch_heading: "",
      commercial_page_team_bhodi_english_heading: "",
      commercial_page_aanmelding_dutch_heading: "",
      commercial_page_aanmelding_english_heading: "",
      commercial_page_charity_member_dutch_heading: "",
      commercial_page_charity_member_english_heading: "",
      commercial_page_events_dutch_heading: "",
      commercial_page_events_english_heading: "",
      commercial_page_events_dutch_headertxt: "",
      commercial_page_events_english_headertxt: "",
      // Mobile
      commercial_page_banner_dutch_mobile: "",
      commercial_page_banner_english_mobile: "",
      commercial_page_van_start_dutch_mobile: "",
      commercial_page_van_start_english_mobile: "",
      commercial_page_events_dutch_mobile: "",
      commercial_page_events_english_mobile: "",
      commercial_page_fan_base_dutch_mobile: "",
      commercial_page_fan_base_english_mobile: "",
      commercial_page_profile_dutch_mobile: "",
      commercial_page_profile_english_mobile: "",
      commercial_page_about_dutch_mobile: "",
      commercial_page_about_english_mobile: "",
      commercial_page_team_bhodi_dutch_mobile: "",
      commercial_page_team_bhodi_english_mobile: "",
      commercial_page_aanmelding_dutch_mobile: "",
      commercial_page_aanmelding_english_mobile: "",
      commercial_page_charity_member_dutch_mobile: "",
      commercial_page_charity_member_english_mobile: "",
      // Heading
      commercial_page_banner_dutch_heading_mobile: "",
      commercial_page_banner_english_heading_mobile: "",
      commercial_page_van_start_dutch_heading_mobile: "",
      commercial_page_van_start_english_heading_mobile: "",
      commercial_page_events_dutch_heading_inner_mobile: "",
      commercial_page_events_english_heading_inner_mobile: "",
      commercial_page_fan_base_dutch_heading_mobile: "",
      commercial_page_fan_base_english_heading_mobile: "",
      commercial_page_profile_dutch_heading_mobile: "",
      commercial_page_profile_english_heading_mobile: "",
      commercial_page_about_dutch_heading_mobile: "",
      commercial_page_about_english_heading_mobile: "",
      commercial_page_team_bhodi_dutch_heading_mobile: "",
      commercial_page_team_bhodi_english_heading_mobile: "",
      commercial_page_aanmelding_dutch_heading_mobile: "",
      commercial_page_aanmelding_english_heading_mobile: "",
      commercial_page_charity_member_dutch_heading_mobile: "",
      commercial_page_charity_member_english_heading_mobile: "",
      commercial_page_events_dutch_heading_mobile: "",
      commercial_page_events_english_heading_mobile: "",
      commercial_page_events_dutch_headertxt_mobile: "",
      commercial_page_events_english_headertxt_mobile: "",
      onSlideOne: false,
      onSlideTwo: true,
      onSlideThree: false,
      // Slides
      commercial_page_first_slide_dutch_heading: "",
      commercial_page_first_slide_dutch_heading_mobile: "",
      commercial_page_first_slide_dutch_paragraph_mobile: "",
      commercial_page_first_slide_dutch_paragraph: "",
      commercial_page_second_slide_dutch_heading: "",
      commercial_page_second_slide_dutch_heading_mobile: "",
      commercial_page_second_slide_dutch_paragraph_mobile: "",
      commercial_page_second_slide_dutch_paragraph: "",
      commercial_page_third_slide_dutch_heading: "",
      commercial_page_third_slide_dutch_heading_mobile: "",
      commercial_page_third_slide_dutch_paragraph_mobile: "",
      commercial_page_third_slide_dutch_paragraph: "",
      commercial_page_fourth_slide_dutch_heading: "",
      commercial_page_fourth_slide_dutch_heading_mobile: "",
      commercial_page_fourth_slide_dutch_paragraph_mobile: "",
      commercial_page_fourth_slide_dutch_paragraph: "",
      commercial_page_fifth_slide_dutch_heading: "",
      commercial_page_fifth_slide_dutch_heading_mobile: "",
      commercial_page_fifth_slide_dutch_paragraph_mobile: "",
      commercial_page_fifth_slide_dutch_paragraph: "",
      // English
      commercial_page_first_slide_english_heading: "",
      commercial_page_first_slide_english_heading_mobile: "",
      commercial_page_first_slide_english_paragraph_mobile: "",
      commercial_page_first_slide_english_paragraph: "",
      commercial_page_second_slide_english_heading: "",
      commercial_page_second_slide_english_heading_mobile: "",
      commercial_page_second_slide_english_paragraph_mobile: "",
      commercial_page_second_slide_english_paragraph: "",
      commercial_page_third_slide_english_heading: "",
      commercial_page_third_slide_english_heading_mobile: "",
      commercial_page_third_slide_english_paragraph_mobile: "",
      commercial_page_third_slide_english_paragraph: "",
      commercial_page_fourth_slide_english_heading: "",
      commercial_page_fourth_slide_english_heading_mobile: "",
      commercial_page_fourth_slide_english_paragraph_mobile: "",
      commercial_page_fourth_slide_english_paragraph: "",
      commercial_page_fifth_slide_english_heading: "",
      commercial_page_fifth_slide_english_heading_mobile: "",
      commercial_page_fifth_slide_english_paragraph_mobile: "",
      commercial_page_fifth_slide_english_paragraph: "",
      commercial_page_first_slide_image: "",
      commercial_page_second_slide_image: "",
      commercial_page_third_slide_image: "",
      commercial_page_fourth_slide_image: "",
      commercial_page_fifth_slide_image: "",

      commercial_page_slider_dutch_heading_mobile: "",
      commercial_page_slider_dutch_heading: "",
      commercial_page_slider_english_heading_mobile: "",
      commercial_page_slider_english_heading: "",
      commercial_page_slider_english_para: "",
      commercial_page_slider_english_para_mobile: "",
      commercial_page_slider_dutch_para_mobile: "",
      commercial_page_slider_dutch_para: "",

      commercial_banner_video: "",
      commercial_events_image: "",
      commercial_profile_video: "",
      commercial_aanmelden_image: "",
      commercial_charity_member_image: "",
      commercial_page_charity_video: "",
      landing_page_footer_image: "",
    };
  }

  componentDidMount() {
    let res = axios.get(
      "https://us-central1-bhodi-7c327.cloudfunctions.net/getIpLocation"
    );
    res.then((output) => {
      if (output.data.deta) {
        if (output.data.deta.country === "NL") {
          this.setState({ showDutch: true });
        } else {
          this.setState({ showDutch: false });
        }
      }
    });

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        this.setState({
          commercial_page_banner_dutch: a.commercial_page_banner_dutch,
          commercial_page_banner_english: a.commercial_page_banner_english,
          commercial_page_van_start_dutch: a.commercial_page_van_start_dutch,
          commercial_page_van_start_english:
            a.commercial_page_van_start_english,
          commercial_page_events_dutch: a.commercial_page_events_dutch,
          commercial_page_events_english: a.commercial_page_events_english,
          commercial_page_fan_base_dutch: a.commercial_page_fan_base_dutch,
          commercial_page_fan_base_english: a.commercial_page_fan_base_english,
          commercial_page_profile_dutch: a.commercial_page_profile_dutch,
          commercial_page_profile_english: a.commercial_page_profile_english,
          commercial_page_about_dutch: a.commercial_page_about_dutch,
          commercial_page_about_english: a.commercial_page_about_english,
          commercial_page_team_bhodi_dutch: a.commercial_page_team_bhodi_dutch,
          commercial_page_team_bhodi_english:
            a.commercial_page_team_bhodi_english,
          commercial_page_aanmelding_dutch: a.commercial_page_aanmelding_dutch,
          commercial_page_aanmelding_english:
            a.commercial_page_aanmelding_english,
          commercial_page_charity_member_dutch:
            a.commercial_page_charity_member_dutch,
          commercial_page_charity_member_english:
            a.commercial_page_charity_member_english,

          commercial_page_banner_dutch_heading:
            a.commercial_page_banner_dutch_heading,
          commercial_page_banner_english_heading:
            a.commercial_page_banner_english_heading,
          commercial_page_van_start_dutch_heading:
            a.commercial_page_van_start_dutch_heading,
          commercial_page_van_start_english_heading:
            a.commercial_page_van_start_english_heading,
          commercial_page_events_dutch_heading_inner:
            a.commercial_page_events_dutch_heading_inner,
          commercial_page_events_english_heading_inner:
            a.commercial_page_events_english_heading_inner,
          commercial_page_fan_base_dutch_heading:
            a.commercial_page_fan_base_dutch_heading,
          commercial_page_fan_base_english_heading:
            a.commercial_page_fan_base_english_heading,
          commercial_page_profile_dutch_heading:
            a.commercial_page_profile_dutch_heading,
          commercial_page_profile_english_heading:
            a.commercial_page_profile_english_heading,
          commercial_page_about_dutch_heading:
            a.commercial_page_about_dutch_heading,
          commercial_page_about_english_heading:
            a.commercial_page_about_english_heading,
          commercial_page_team_bhodi_dutch_heading:
            a.commercial_page_team_bhodi_dutch_heading,
          commercial_page_team_bhodi_english_heading:
            a.commercial_page_team_bhodi_english_heading,
          commercial_page_aanmelding_dutch_heading:
            a.commercial_page_aanmelding_dutch_heading,
          commercial_page_aanmelding_english_heading:
            a.commercial_page_aanmelding_english_heading,
          commercial_page_charity_member_dutch_heading:
            a.commercial_page_charity_member_dutch_heading,
          commercial_page_charity_member_english_heading:
            a.commercial_page_charity_member_english_heading,
          commercial_page_events_dutch_heading:
            a.commercial_page_events_dutch_heading,
          commercial_page_events_english_heading:
            a.commercial_page_events_english_heading,
          commercial_page_events_dutch_headertxt:
            a.commercial_page_events_dutch_headertxt,
          commercial_page_events_english_headertxt:
            a.commercial_page_events_english_headertxt,
          // Mobile
          commercial_page_banner_dutch_mobile:
            a.commercial_page_banner_dutch_mobile,
          commercial_page_banner_english_mobile:
            a.commercial_page_banner_english_mobile,
          commercial_page_van_start_dutch_mobile:
            a.commercial_page_van_start_dutch_mobile,
          commercial_page_van_start_english_mobile:
            a.commercial_page_van_start_english_mobile,
          commercial_page_events_dutch_mobile:
            a.commercial_page_events_dutch_mobile,
          commercial_page_events_english_mobile:
            a.commercial_page_events_english_mobile,
          commercial_page_fan_base_dutch_mobile:
            a.commercial_page_fan_base_dutch_mobile,
          commercial_page_fan_base_english_mobile:
            a.commercial_page_fan_base_english_mobile,
          commercial_page_profile_dutch_mobile:
            a.commercial_page_profile_dutch_mobile,
          commercial_page_profile_english_mobile:
            a.commercial_page_profile_english_mobile,
          commercial_page_about_dutch_mobile:
            a.commercial_page_about_dutch_mobile,
          commercial_page_about_english_mobile:
            a.commercial_page_about_english_mobile,
          commercial_page_team_bhodi_dutch_mobile:
            a.commercial_page_team_bhodi_dutch_mobile,
          commercial_page_team_bhodi_english_mobile:
            a.commercial_page_team_bhodi_english_mobile,
          commercial_page_aanmelding_dutch_mobile:
            a.commercial_page_aanmelding_dutch_mobile,
          commercial_page_aanmelding_english_mobile:
            a.commercial_page_aanmelding_english_mobile,
          commercial_page_charity_member_dutch_mobile:
            a.commercial_page_charity_member_dutch_mobile,
          commercial_page_charity_member_english_mobile:
            a.commercial_page_charity_member_english_mobile,
          commercial_page_banner_dutch_heading_mobile:
            a.commercial_page_banner_dutch_heading_mobile,
          commercial_page_banner_english_heading_mobile:
            a.commercial_page_banner_english_heading_mobile,
          commercial_page_van_start_dutch_heading_mobile:
            a.commercial_page_van_start_dutch_heading_mobile,
          commercial_page_van_start_english_heading_mobile:
            a.commercial_page_van_start_english_heading_mobile,
          commercial_page_events_dutch_heading_inner_mobile:
            a.commercial_page_events_dutch_heading_inner_mobile,
          commercial_page_events_english_heading_inner_mobile:
            a.commercial_page_events_english_heading_inner_mobile,
          commercial_page_fan_base_dutch_heading_mobile:
            a.commercial_page_fan_base_dutch_heading_mobile,
          commercial_page_fan_base_english_heading_mobile:
            a.commercial_page_fan_base_english_heading_mobile,
          commercial_page_profile_dutch_heading_mobile:
            a.commercial_page_profile_dutch_heading_mobile,
          commercial_page_profile_english_heading_mobile:
            a.commercial_page_profile_english_heading_mobile,
          commercial_page_about_dutch_heading_mobile:
            a.commercial_page_about_dutch_heading_mobile,
          commercial_page_about_english_heading_mobile:
            a.commercial_page_about_english_heading_mobile,
          commercial_page_team_bhodi_dutch_heading_mobile:
            a.commercial_page_team_bhodi_dutch_heading_mobile,
          commercial_page_team_bhodi_english_heading_mobile:
            a.commercial_page_team_bhodi_english_heading_mobile,
          commercial_page_aanmelding_dutch_heading_mobile:
            a.commercial_page_aanmelding_dutch_heading_mobile,
          commercial_page_aanmelding_english_heading_mobile:
            a.commercial_page_aanmelding_english_heading_mobile,
          commercial_page_charity_member_dutch_heading_mobile:
            a.commercial_page_charity_member_dutch_heading_mobile,
          commercial_page_charity_member_english_heading_mobile:
            a.commercial_page_charity_member_english_heading_mobile,
          commercial_page_events_dutch_heading_mobile:
            a.commercial_page_events_dutch_heading_mobile,
          commercial_page_events_english_heading_mobile:
            a.commercial_page_events_english_heading_mobile,
          commercial_page_events_dutch_headertxt_mobile:
            a.commercial_page_events_dutch_headertxt_mobile,
          commercial_page_events_english_headertxt_mobile:
            a.commercial_page_events_english_headertxt_mobile,
          // Slides
          commercial_page_first_slide_dutch_heading:
            a.commercial_page_first_slide_dutch_heading,
          commercial_page_first_slide_dutch_heading_mobile:
            a.commercial_page_first_slide_dutch_heading_mobile,
          commercial_page_first_slide_dutch_paragraph_mobile:
            a.commercial_page_first_slide_dutch_paragraph_mobile,
          commercial_page_first_slide_dutch_paragraph:
            a.commercial_page_first_slide_dutch_paragraph,
          commercial_page_second_slide_dutch_heading:
            a.commercial_page_second_slide_dutch_heading,
          commercial_page_second_slide_dutch_heading_mobile:
            a.commercial_page_second_slide_dutch_heading_mobile,
          commercial_page_second_slide_dutch_paragraph_mobile:
            a.commercial_page_second_slide_dutch_paragraph_mobile,
          commercial_page_second_slide_dutch_paragraph:
            a.commercial_page_second_slide_dutch_paragraph,
          commercial_page_third_slide_dutch_heading:
            a.commercial_page_third_slide_dutch_heading,
          commercial_page_third_slide_dutch_heading_mobile:
            a.commercial_page_third_slide_dutch_heading_mobile,
          commercial_page_third_slide_dutch_paragraph_mobile:
            a.commercial_page_third_slide_dutch_paragraph_mobile,
          commercial_page_third_slide_dutch_paragraph:
            a.commercial_page_third_slide_dutch_paragraph,
          commercial_page_fourth_slide_dutch_heading:
            a.commercial_page_fourth_slide_dutch_heading,
          commercial_page_fourth_slide_dutch_heading_mobile:
            a.commercial_page_fourth_slide_dutch_heading_mobile,
          commercial_page_fourth_slide_dutch_paragraph_mobile:
            a.commercial_page_fourth_slide_dutch_paragraph_mobile,
          commercial_page_fourth_slide_dutch_paragraph:
            a.commercial_page_fourth_slide_dutch_paragraph,
          commercial_page_fifth_slide_dutch_heading:
            a.commercial_page_fifth_slide_dutch_heading,
          commercial_page_fifth_slide_dutch_heading_mobile:
            a.commercial_page_fifth_slide_dutch_heading_mobile,
          commercial_page_fifth_slide_dutch_paragraph_mobile:
            a.commercial_page_fifth_slide_dutch_paragraph_mobile,
          commercial_page_fifth_slide_dutch_paragraph:
            a.commercial_page_fifth_slide_dutch_paragraph,
          // English
          commercial_page_first_slide_english_heading:
            a.commercial_page_first_slide_english_heading,
          commercial_page_first_slide_english_heading_mobile:
            a.commercial_page_first_slide_english_heading_mobile,
          commercial_page_first_slide_english_paragraph_mobile:
            a.commercial_page_first_slide_english_paragraph_mobile,
          commercial_page_first_slide_english_paragraph:
            a.commercial_page_first_slide_english_paragraph,
          commercial_page_second_slide_english_heading:
            a.commercial_page_second_slide_english_heading,
          commercial_page_second_slide_english_heading_mobile:
            a.commercial_page_second_slide_english_heading_mobile,
          commercial_page_second_slide_english_paragraph_mobile:
            a.commercial_page_second_slide_english_paragraph_mobile,
          commercial_page_second_slide_english_paragraph:
            a.commercial_page_second_slide_english_paragraph,
          commercial_page_third_slide_english_heading:
            a.commercial_page_third_slide_english_heading,
          commercial_page_third_slide_english_heading_mobile:
            a.commercial_page_third_slide_english_heading_mobile,
          commercial_page_third_slide_english_paragraph_mobile:
            a.commercial_page_third_slide_english_paragraph_mobile,
          commercial_page_third_slide_english_paragraph:
            a.commercial_page_third_slide_english_paragraph,
          commercial_page_fourth_slide_english_heading:
            a.commercial_page_fourth_slide_english_heading,
          commercial_page_fourth_slide_english_heading_mobile:
            a.commercial_page_fourth_slide_english_heading_mobile,
          commercial_page_fourth_slide_english_paragraph_mobile:
            a.commercial_page_fourth_slide_english_paragraph_mobile,
          commercial_page_fourth_slide_english_paragraph:
            a.commercial_page_fourth_slide_english_paragraph,
          commercial_page_fifth_slide_english_heading:
            a.commercial_page_fifth_slide_english_heading,
          commercial_page_fifth_slide_english_heading_mobile:
            a.commercial_page_fifth_slide_english_heading_mobile,
          commercial_page_fifth_slide_english_paragraph_mobile:
            a.commercial_page_fifth_slide_english_paragraph_mobile,
          commercial_page_fifth_slide_english_paragraph:
            a.commercial_page_fifth_slide_english_paragraph,

          commercial_page_first_slide_image:
            a.commercial_page_first_slide_image,
          commercial_page_second_slide_image:
            a.commercial_page_second_slide_image,
          commercial_page_third_slide_image:
            a.commercial_page_third_slide_image,
          commercial_page_fourth_slide_image:
            a.commercial_page_fourth_slide_image,
          commercial_page_fifth_slide_image:
            a.commercial_page_fifth_slide_image,

          commercial_page_slider_dutch_heading_mobile:
            a.commercial_page_slider_dutch_heading_mobile,
          commercial_page_slider_dutch_heading:
            a.commercial_page_slider_dutch_heading,
          commercial_page_slider_english_heading_mobile:
            a.commercial_page_slider_english_heading_mobile,
          commercial_page_slider_english_heading:
            a.commercial_page_slider_english_heading,
          commercial_page_slider_english_para:
            a.commercial_page_slider_english_para,
          commercial_page_slider_english_para_mobile:
            a.commercial_page_slider_english_para_mobile,
          commercial_page_slider_dutch_para_mobile:
            a.commercial_page_slider_dutch_para_mobile,
          commercial_page_slider_dutch_para:
            a.commercial_page_slider_dutch_para,

          commercial_banner_video: a.commercial_banner_video,
          commercial_events_image: a.commercial_events_image,
          commercial_profile_video: a.commercial_profile_video,
          commercial_aanmelden_image: a.commercial_aanmelden_image,
          commercial_charity_member_image: a.commercial_charity_member_image,
          commercial_page_charity_video: a.commercial_page_charity_video,
          landing_page_footer_image: a.landing_page_footer_image,
        });
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          this.setState({
            commercial_page_banner_dutch: a.commercial_page_banner_dutch,
            commercial_page_banner_english: a.commercial_page_banner_english,
            commercial_page_van_start_dutch: a.commercial_page_van_start_dutch,
            commercial_page_van_start_english:
              a.commercial_page_van_start_english,
            commercial_page_events_dutch: a.commercial_page_events_dutch,
            commercial_page_events_english: a.commercial_page_events_english,
            commercial_page_fan_base_dutch: a.commercial_page_fan_base_dutch,
            commercial_page_fan_base_english:
              a.commercial_page_fan_base_english,
            commercial_page_profile_dutch: a.commercial_page_profile_dutch,
            commercial_page_profile_english: a.commercial_page_profile_english,
            commercial_page_about_dutch: a.commercial_page_about_dutch,
            commercial_page_about_english: a.commercial_page_about_english,
            commercial_page_team_bhodi_dutch:
              a.commercial_page_team_bhodi_dutch,
            commercial_page_team_bhodi_english:
              a.commercial_page_team_bhodi_english,
            commercial_page_aanmelding_dutch:
              a.commercial_page_aanmelding_dutch,
            commercial_page_aanmelding_english:
              a.commercial_page_aanmelding_english,
            commercial_page_charity_member_dutch:
              a.commercial_page_charity_member_dutch,
            commercial_page_charity_member_english:
              a.commercial_page_charity_member_english,
            commercial_page_banner_dutch_heading:
              a.commercial_page_banner_dutch_heading,
            commercial_page_banner_english_heading:
              a.commercial_page_banner_english_heading,
            commercial_page_van_start_dutch_heading:
              a.commercial_page_van_start_dutch_heading,
            commercial_page_van_start_english_heading:
              a.commercial_page_van_start_english_heading,
            commercial_page_events_dutch_heading_inner:
              a.commercial_page_events_dutch_heading_inner,
            commercial_page_events_english_heading_inner:
              a.commercial_page_events_english_heading_inner,
            commercial_page_fan_base_dutch_heading:
              a.commercial_page_fan_base_dutch_heading,
            commercial_page_fan_base_english_heading:
              a.commercial_page_fan_base_english_heading,
            commercial_page_profile_dutch_heading:
              a.commercial_page_profile_dutch_heading,
            commercial_page_profile_english_heading:
              a.commercial_page_profile_english_heading,
            commercial_page_about_dutch_heading:
              a.commercial_page_about_dutch_heading,
            commercial_page_about_english_heading:
              a.commercial_page_about_english_heading,
            commercial_page_team_bhodi_dutch_heading:
              a.commercial_page_team_bhodi_dutch_heading,
            commercial_page_team_bhodi_english_heading:
              a.commercial_page_team_bhodi_english_heading,
            commercial_page_aanmelding_dutch_heading:
              a.commercial_page_aanmelding_dutch_heading,
            commercial_page_aanmelding_english_heading:
              a.commercial_page_aanmelding_english_heading,
            commercial_page_charity_member_dutch_heading:
              a.commercial_page_charity_member_dutch_heading,
            commercial_page_charity_member_english_heading:
              a.commercial_page_charity_member_english_heading,
            commercial_page_events_dutch_heading:
              a.commercial_page_events_dutch_heading,
            commercial_page_events_english_heading:
              a.commercial_page_events_english_heading,
            commercial_page_events_dutch_headertxt:
              a.commercial_page_events_dutch_headertxt,
            commercial_page_events_english_headertxt:
              a.commercial_page_events_english_headertxt,
            // Mobile
            commercial_page_banner_dutch_mobile:
              a.commercial_page_banner_dutch_mobile,
            commercial_page_banner_english_mobile:
              a.commercial_page_banner_english_mobile,
            commercial_page_van_start_dutch_mobile:
              a.commercial_page_van_start_dutch_mobile,
            commercial_page_van_start_english_mobile:
              a.commercial_page_van_start_english_mobile,
            commercial_page_events_dutch_mobile:
              a.commercial_page_events_dutch_mobile,
            commercial_page_events_english_mobile:
              a.commercial_page_events_english_mobile,
            commercial_page_fan_base_dutch_mobile:
              a.commercial_page_fan_base_dutch_mobile,
            commercial_page_fan_base_english_mobile:
              a.commercial_page_fan_base_english_mobile,
            commercial_page_profile_dutch_mobile:
              a.commercial_page_profile_dutch_mobile,
            commercial_page_profile_english_mobile:
              a.commercial_page_profile_english_mobile,
            commercial_page_about_dutch_mobile:
              a.commercial_page_about_dutch_mobile,
            commercial_page_about_english_mobile:
              a.commercial_page_about_english_mobile,
            commercial_page_team_bhodi_dutch_mobile:
              a.commercial_page_team_bhodi_dutch_mobile,
            commercial_page_team_bhodi_english_mobile:
              a.commercial_page_team_bhodi_english_mobile,
            commercial_page_aanmelding_dutch_mobile:
              a.commercial_page_aanmelding_dutch_mobile,
            commercial_page_aanmelding_english_mobile:
              a.commercial_page_aanmelding_english_mobile,
            commercial_page_charity_member_dutch_mobile:
              a.commercial_page_charity_member_dutch_mobile,
            commercial_page_charity_member_english_mobile:
              a.commercial_page_charity_member_english_mobile,
            commercial_page_banner_dutch_heading_mobile:
              a.commercial_page_banner_dutch_heading_mobile,
            commercial_page_banner_english_heading_mobile:
              a.commercial_page_banner_english_heading_mobile,
            commercial_page_van_start_dutch_heading_mobile:
              a.commercial_page_van_start_dutch_heading_mobile,
            commercial_page_van_start_english_heading_mobile:
              a.commercial_page_van_start_english_heading_mobile,
            commercial_page_events_dutch_heading_inner_mobile:
              a.commercial_page_events_dutch_heading_inner_mobile,
            commercial_page_events_english_heading_inner_mobile:
              a.commercial_page_events_english_heading_inner_mobile,
            commercial_page_fan_base_dutch_heading_mobile:
              a.commercial_page_fan_base_dutch_heading_mobile,
            commercial_page_fan_base_english_heading_mobile:
              a.commercial_page_fan_base_english_heading_mobile,
            commercial_page_profile_dutch_heading_mobile:
              a.commercial_page_profile_dutch_heading_mobile,
            commercial_page_profile_english_heading_mobile:
              a.commercial_page_profile_english_heading_mobile,
            commercial_page_about_dutch_heading_mobile:
              a.commercial_page_about_dutch_heading_mobile,
            commercial_page_about_english_heading_mobile:
              a.commercial_page_about_english_heading_mobile,
            commercial_page_team_bhodi_dutch_heading_mobile:
              a.commercial_page_team_bhodi_dutch_heading_mobile,
            commercial_page_team_bhodi_english_heading_mobile:
              a.commercial_page_team_bhodi_english_heading_mobile,
            commercial_page_aanmelding_dutch_heading_mobile:
              a.commercial_page_aanmelding_dutch_heading_mobile,
            commercial_page_aanmelding_english_heading_mobile:
              a.commercial_page_aanmelding_english_heading_mobile,
            commercial_page_charity_member_dutch_heading_mobile:
              a.commercial_page_charity_member_dutch_heading_mobile,
            commercial_page_charity_member_english_heading_mobile:
              a.commercial_page_charity_member_english_heading_mobile,
            commercial_page_events_dutch_heading_mobile:
              a.commercial_page_events_dutch_heading_mobile,
            commercial_page_events_english_heading_mobile:
              a.commercial_page_events_english_heading_mobile,
            commercial_page_events_dutch_headertxt_mobile:
              a.commercial_page_events_dutch_headertxt_mobile,
            commercial_page_events_english_headertxt_mobile:
              a.commercial_page_events_english_headertxt_mobile,
            // Slides
            commercial_page_first_slide_dutch_heading:
              a.commercial_page_first_slide_dutch_heading,
            commercial_page_first_slide_dutch_heading_mobile:
              a.commercial_page_first_slide_dutch_heading_mobile,
            commercial_page_first_slide_dutch_paragraph_mobile:
              a.commercial_page_first_slide_dutch_paragraph_mobile,
            commercial_page_first_slide_dutch_paragraph:
              a.commercial_page_first_slide_dutch_paragraph,
            commercial_page_second_slide_dutch_heading:
              a.commercial_page_second_slide_dutch_heading,
            commercial_page_second_slide_dutch_heading_mobile:
              a.commercial_page_second_slide_dutch_heading_mobile,
            commercial_page_second_slide_dutch_paragraph_mobile:
              a.commercial_page_second_slide_dutch_paragraph_mobile,
            commercial_page_second_slide_dutch_paragraph:
              a.commercial_page_second_slide_dutch_paragraph,
            commercial_page_third_slide_dutch_heading:
              a.commercial_page_third_slide_dutch_heading,
            commercial_page_third_slide_dutch_heading_mobile:
              a.commercial_page_third_slide_dutch_heading_mobile,
            commercial_page_third_slide_dutch_paragraph_mobile:
              a.commercial_page_third_slide_dutch_paragraph_mobile,
            commercial_page_third_slide_dutch_paragraph:
              a.commercial_page_third_slide_dutch_paragraph,
            commercial_page_fourth_slide_dutch_heading:
              a.commercial_page_fourth_slide_dutch_heading,
            commercial_page_fourth_slide_dutch_heading_mobile:
              a.commercial_page_fourth_slide_dutch_heading_mobile,
            commercial_page_fourth_slide_dutch_paragraph_mobile:
              a.commercial_page_fourth_slide_dutch_paragraph_mobile,
            commercial_page_fourth_slide_dutch_paragraph:
              a.commercial_page_fourth_slide_dutch_paragraph,
            commercial_page_fifth_slide_dutch_heading:
              a.commercial_page_fifth_slide_dutch_heading,
            commercial_page_fifth_slide_dutch_heading_mobile:
              a.commercial_page_fifth_slide_dutch_heading_mobile,
            commercial_page_fifth_slide_dutch_paragraph_mobile:
              a.commercial_page_fifth_slide_dutch_paragraph_mobile,
            commercial_page_fifth_slide_dutch_paragraph:
              a.commercial_page_fifth_slide_dutch_paragraph,
            // English
            commercial_page_first_slide_english_heading:
              a.commercial_page_first_slide_english_heading,
            commercial_page_first_slide_english_heading_mobile:
              a.commercial_page_first_slide_english_heading_mobile,
            commercial_page_first_slide_english_paragraph_mobile:
              a.commercial_page_first_slide_english_paragraph_mobile,
            commercial_page_first_slide_english_paragraph:
              a.commercial_page_first_slide_english_paragraph,
            commercial_page_second_slide_english_heading:
              a.commercial_page_second_slide_english_heading,
            commercial_page_second_slide_english_heading_mobile:
              a.commercial_page_second_slide_english_heading_mobile,
            commercial_page_second_slide_english_paragraph_mobile:
              a.commercial_page_second_slide_english_paragraph_mobile,
            commercial_page_second_slide_english_paragraph:
              a.commercial_page_second_slide_english_paragraph,
            commercial_page_third_slide_english_heading:
              a.commercial_page_third_slide_english_heading,
            commercial_page_third_slide_english_heading_mobile:
              a.commercial_page_third_slide_english_heading_mobile,
            commercial_page_third_slide_english_paragraph_mobile:
              a.commercial_page_third_slide_english_paragraph_mobile,
            commercial_page_third_slide_english_paragraph:
              a.commercial_page_third_slide_english_paragraph,
            commercial_page_fourth_slide_english_heading:
              a.commercial_page_fourth_slide_english_heading,
            commercial_page_fourth_slide_english_heading_mobile:
              a.commercial_page_fourth_slide_english_heading_mobile,
            commercial_page_fourth_slide_english_paragraph_mobile:
              a.commercial_page_fourth_slide_english_paragraph_mobile,
            commercial_page_fourth_slide_english_paragraph:
              a.commercial_page_fourth_slide_english_paragraph,
            commercial_page_fifth_slide_english_heading:
              a.commercial_page_fifth_slide_english_heading,
            commercial_page_fifth_slide_english_heading_mobile:
              a.commercial_page_fifth_slide_english_heading_mobile,
            commercial_page_fifth_slide_english_paragraph_mobile:
              a.commercial_page_fifth_slide_english_paragraph_mobile,
            commercial_page_fifth_slide_english_paragraph:
              a.commercial_page_fifth_slide_english_paragraph,

            commercial_page_first_slide_image:
              a.commercial_page_first_slide_image,
            commercial_page_second_slide_image:
              a.commercial_page_second_slide_image,
            commercial_page_third_slide_image:
              a.commercial_page_third_slide_image,
            commercial_page_fourth_slide_image:
              a.commercial_page_fourth_slide_image,
            commercial_page_fifth_slide_image:
              a.commercial_page_fifth_slide_image,

            commercial_page_slider_dutch_heading_mobile:
              a.commercial_page_slider_dutch_heading_mobile,
            commercial_page_slider_dutch_heading:
              a.commercial_page_slider_dutch_heading,
            commercial_page_slider_english_heading_mobile:
              a.commercial_page_slider_english_heading_mobile,
            commercial_page_slider_english_heading:
              a.commercial_page_slider_english_heading,
            commercial_page_slider_english_para:
              a.commercial_page_slider_english_para,
            commercial_page_slider_english_para_mobile:
              a.commercial_page_slider_english_para_mobile,
            commercial_page_slider_dutch_para_mobile:
              a.commercial_page_slider_dutch_para_mobile,
            commercial_page_slider_dutch_para:
              a.commercial_page_slider_dutch_para,

            commercial_banner_video: a.commercial_banner_video,
            commercial_events_image: a.commercial_events_image,
            commercial_profile_video: a.commercial_profile_video,
            commercial_aanmelden_image: a.commercial_aanmelden_image,
            commercial_charity_member_image: a.commercial_charity_member_image,
            commercial_page_charity_video: a.commercial_page_charity_video,
            landing_page_footer_image: a.landing_page_footer_image,
          });
        }
      });
  }

  createMarkupBannerDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_banner_dutch_heading) {
        return { __html: this.state.commercial_page_banner_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_banner_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_banner_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupBannerEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_banner_english_heading) {
        return { __html: this.state.commercial_page_banner_english_heading };
      }
    } else {
      if (this.state.commercial_page_banner_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_banner_english_heading_mobile,
        };
      }
    }
  }

  createMarkupBannerDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_banner_dutch) {
        return { __html: this.state.commercial_page_banner_dutch };
      }
    } else {
      if (this.state.commercial_page_banner_dutch_mobile) {
        return { __html: this.state.commercial_page_banner_dutch_mobile };
      }
    }
  }

  createMarkupBannerEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_banner_english) {
        return { __html: this.state.commercial_page_banner_english };
      }
    } else {
      if (this.state.commercial_page_banner_english_mobile) {
        return { __html: this.state.commercial_page_banner_english_mobile };
      }
    }
  }

  createMarkupVanStartDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_van_start_dutch_heading) {
        return { __html: this.state.commercial_page_van_start_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_van_start_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_van_start_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupVanStartEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_van_start_english_heading) {
        return { __html: this.state.commercial_page_van_start_english_heading };
      }
    } else {
      if (this.state.commercial_page_van_start_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_van_start_english_heading_mobile,
        };
      }
    }
  }

  createMarkupVanStartDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_van_start_dutch) {
        return { __html: this.state.commercial_page_van_start_dutch };
      }
    } else {
      if (this.state.commercial_page_van_start_dutch_mobile) {
        return { __html: this.state.commercial_page_van_start_dutch_mobile };
      }
    }
  }

  createMarkupVanStartEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_van_start_english) {
        return { __html: this.state.commercial_page_van_start_english };
      }
    } else {
      if (this.state.commercial_page_van_start_english_mobile) {
        return { __html: this.state.commercial_page_van_start_english_mobile };
      }
    }
  }

  createMarkupEventsDutchHeadingInner() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_dutch_heading_inner) {
        return {
          __html: this.state.commercial_page_events_dutch_heading_inner,
        };
      }
    } else {
      if (this.state.commercial_page_events_dutch_heading_inner_mobile) {
        return {
          __html: this.state.commercial_page_events_dutch_heading_inner_mobile,
        };
      }
    }
  }

  createMarkupEventsEnglishHeadingInner() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_english_heading_inner) {
        return {
          __html: this.state.commercial_page_events_english_heading_inner,
        };
      }
    } else {
      if (this.state.commercial_page_events_english_heading_inner_mobile) {
        return {
          __html:
            this.state.commercial_page_events_english_heading_inner_mobile,
        };
      }
    }
  }

  createMarkupEventsDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_dutch) {
        return { __html: this.state.commercial_page_events_dutch };
      }
    } else {
      if (this.state.commercial_page_events_dutch_mobile) {
        return { __html: this.state.commercial_page_events_dutch_mobile };
      }
    }
  }

  createMarkupEventsEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_english) {
        return { __html: this.state.commercial_page_events_english };
      }
    } else {
      if (this.state.commercial_page_events_english_mobile) {
        return { __html: this.state.commercial_page_events_english_mobile };
      }
    }
  }

  createMarkupFanBaseDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fan_base_dutch_heading) {
        return { __html: this.state.commercial_page_fan_base_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_fan_base_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_fan_base_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupFanBaseEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fan_base_english_heading) {
        return { __html: this.state.commercial_page_fan_base_english_heading };
      }
    } else {
      if (this.state.commercial_page_fan_base_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_fan_base_english_heading_mobile,
        };
      }
    }
  }

  createMarkupFanBaseDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fan_base_dutch) {
        return { __html: this.state.commercial_page_fan_base_dutch };
      }
    } else {
      if (this.state.commercial_page_fan_base_dutch_mobile) {
        return { __html: this.state.commercial_page_fan_base_dutch_mobile };
      }
    }
  }

  createMarkupFanBaseEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fan_base_english) {
        return { __html: this.state.commercial_page_fan_base_english };
      }
    } else {
      if (this.state.commercial_page_fan_base_english_mobile) {
        return { __html: this.state.commercial_page_fan_base_english_mobile };
      }
    }
  }

  createMarkupProfileDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_profile_dutch_heading) {
        return { __html: this.state.commercial_page_profile_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_profile_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_profile_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupProfileEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_profile_english_heading) {
        return { __html: this.state.commercial_page_profile_english_heading };
      }
    } else {
      if (this.state.commercial_page_profile_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_profile_english_heading_mobile,
        };
      }
    }
  }

  createMarkupProfileDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_profile_dutch) {
        return { __html: this.state.commercial_page_profile_dutch };
      }
    } else {
      if (this.state.commercial_page_profile_dutch_mobile) {
        return { __html: this.state.commercial_page_profile_dutch_mobile };
      }
    }
  }

  createMarkupProfileEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_profile_english) {
        return { __html: this.state.commercial_page_profile_english };
      }
    } else {
      if (this.state.commercial_page_profile_english_mobile) {
        return { __html: this.state.commercial_page_profile_english_mobile };
      }
    }
  }

  createMarkupAboutDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_about_dutch_heading) {
        return { __html: this.state.commercial_page_about_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_about_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_about_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupAboutEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_about_english_heading) {
        return { __html: this.state.commercial_page_about_english_heading };
      }
    } else {
      if (this.state.commercial_page_about_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_about_english_heading_mobile,
        };
      }
    }
  }

  createMarkupAboutDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_about_dutch) {
        return { __html: this.state.commercial_page_about_dutch };
      }
    } else {
      if (this.state.commercial_page_about_dutch_mobile) {
        return { __html: this.state.commercial_page_about_dutch_mobile };
      }
    }
  }

  createMarkupAboutEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_about_english) {
        return { __html: this.state.commercial_page_about_english };
      }
    } else {
      if (this.state.commercial_page_about_english_mobile) {
        return { __html: this.state.commercial_page_about_english_mobile };
      }
    }
  }

  createMarkupTeamBhodiDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_team_bhodi_dutch_heading) {
        return { __html: this.state.commercial_page_team_bhodi_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_team_bhodi_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_team_bhodi_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupTeamBhodiEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_team_bhodi_english_heading) {
        return {
          __html: this.state.commercial_page_team_bhodi_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_team_bhodi_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_team_bhodi_english_heading_mobile,
        };
      }
    }
  }

  createMarkupTeamBhodiDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_team_bhodi_dutch) {
        return { __html: this.state.commercial_page_team_bhodi_dutch };
      }
    } else {
      if (this.state.commercial_page_team_bhodi_dutch_mobile) {
        return { __html: this.state.commercial_page_team_bhodi_dutch_mobile };
      }
    }
  }

  createMarkupTeamBhodiEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_team_bhodi_english) {
        return { __html: this.state.commercial_page_team_bhodi_english };
      }
    } else {
      if (this.state.commercial_page_team_bhodi_english_mobile) {
        return { __html: this.state.commercial_page_team_bhodi_english_mobile };
      }
    }
  }

  createMarkupEventsDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_dutch_heading) {
        return { __html: this.state.commercial_page_events_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_events_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_events_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupEventsEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_english_heading) {
        return { __html: this.state.commercial_page_events_english_heading };
      }
    } else {
      if (this.state.commercial_page_events_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_events_english_heading_mobile,
        };
      }
    }
  }

  createMarkupEventsDutchHeaderTxt() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_dutch_headertxt) {
        return { __html: this.state.commercial_page_events_dutch_headertxt };
      }
    } else {
      if (this.state.commercial_page_events_dutch_headertxt_mobile) {
        return {
          __html: this.state.commercial_page_events_dutch_headertxt_mobile,
        };
      }
    }
  }

  createMarkupEventsEnglishHeaderTxt() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_events_english_headertxt) {
        return { __html: this.state.commercial_page_events_english_headertxt };
      }
    } else {
      if (this.state.commercial_page_events_english_headertxt_mobile) {
        return {
          __html: this.state.commercial_page_events_english_headertxt_mobile,
        };
      }
    }
  }

  createMarkupAanmeldingDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_aanmelding_dutch_heading) {
        return { __html: this.state.commercial_page_aanmelding_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_aanmelding_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_aanmelding_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupAanmeldingEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_aanmelding_english_heading) {
        return {
          __html: this.state.commercial_page_aanmelding_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_aanmelding_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_aanmelding_english_heading_mobile,
        };
      }
    }
  }

  createMarkupAanmeldingDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_aanmelding_dutch_heading) {
        return { __html: this.state.commercial_page_aanmelding_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_aanmelding_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_aanmelding_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupAanmeldingEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_aanmelding_english_heading) {
        return {
          __html: this.state.commercial_page_aanmelding_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_aanmelding_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_aanmelding_english_heading_mobile,
        };
      }
    }
  }

  createMarkupAanmeldingDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_aanmelding_dutch) {
        return { __html: this.state.commercial_page_aanmelding_dutch };
      }
    } else {
      if (this.state.commercial_page_aanmelding_dutch_mobile) {
        return { __html: this.state.commercial_page_aanmelding_dutch_mobile };
      }
    }
  }

  createMarkupAanmeldingEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_aanmelding_english) {
        return { __html: this.state.commercial_page_aanmelding_english };
      }
    } else {
      if (this.state.commercial_page_aanmelding_english_mobile) {
        return { __html: this.state.commercial_page_aanmelding_english_mobile };
      }
    }
  }

  createMarkupCharityMemberDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_charity_member_dutch_heading) {
        return {
          __html: this.state.commercial_page_charity_member_dutch_heading,
        };
      }
    } else {
      if (this.state.commercial_page_charity_member_dutch_heading_mobile) {
        return {
          __html:
            this.state.commercial_page_charity_member_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupCharityMemberEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_charity_member_english_heading) {
        return {
          __html: this.state.commercial_page_charity_member_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_charity_member_english_heading_mobile) {
        return {
          __html:
            this.state.commercial_page_charity_member_english_heading_mobile,
        };
      }
    }
  }

  createMarkupCharityMemberDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_charity_member_dutch) {
        return { __html: this.state.commercial_page_charity_member_dutch };
      }
    } else {
      if (this.state.commercial_page_charity_member_dutch_mobile) {
        return {
          __html: this.state.commercial_page_charity_member_dutch_mobile,
        };
      }
    }
  }

  createMarkupCharityMemberEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_charity_member_english) {
        return { __html: this.state.commercial_page_charity_member_english };
      }
    } else {
      if (this.state.commercial_page_charity_member_english_mobile) {
        return {
          __html: this.state.commercial_page_charity_member_english_mobile,
        };
      }
    }
  }

  createMarkupFirstSlideHeadingDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_first_slide_dutch_heading) {
        return { __html: this.state.commercial_page_first_slide_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_first_slide_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_first_slide_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupFirstSlideHeadingEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_first_slide_english_heading) {
        return {
          __html: this.state.commercial_page_first_slide_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_first_slide_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_first_slide_english_heading_mobile,
        };
      }
    }
  }

  createMarkupFirstSlideParaDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_first_slide_dutch_paragraph) {
        return {
          __html: this.state.commercial_page_first_slide_dutch_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_first_slide_dutch_paragraph_mobile) {
        return {
          __html: this.state.commercial_page_first_slide_dutch_paragraph_mobile,
        };
      }
    }
  }

  createMarkupFirstSlideParaEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_first_slide_english_paragraph) {
        return {
          __html: this.state.commercial_page_first_slide_english_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_first_slide_english_paragraph_mobile) {
        return {
          __html:
            this.state.commercial_page_first_slide_english_paragraph_mobile,
        };
      }
    }
  }

  createMarkupSecondSlideHeadingDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_second_slide_dutch_heading) {
        return {
          __html: this.state.commercial_page_second_slide_dutch_heading,
        };
      }
    } else {
      if (this.state.commercial_page_second_slide_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_second_slide_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupSecondSlideHeadingEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_second_slide_english_heading) {
        return {
          __html: this.state.commercial_page_second_slide_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_second_slide_english_heading_mobile) {
        return {
          __html:
            this.state.commercial_page_second_slide_english_heading_mobile,
        };
      }
    }
  }

  createMarkupSecondSlideParaDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_second_slide_dutch_paragraph) {
        return {
          __html: this.state.commercial_page_second_slide_dutch_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_second_slide_dutch_paragraph_mobile) {
        return {
          __html:
            this.state.commercial_page_second_slide_dutch_paragraph_mobile,
        };
      }
    }
  }

  createMarkupSecondSlideParaEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_second_slide_english_paragraph) {
        return {
          __html: this.state.commercial_page_second_slide_english_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_second_slide_english_paragraph_mobile) {
        return {
          __html:
            this.state.commercial_page_second_slide_english_paragraph_mobile,
        };
      }
    }
  }

  createMarkupThirdSlideHeadingDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_third_slide_dutch_heading) {
        return { __html: this.state.commercial_page_third_slide_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_third_slide_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_third_slide_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupThirdSlideHeadingEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_third_slide_english_heading) {
        return {
          __html: this.state.commercial_page_third_slide_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_third_slide_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_third_slide_english_heading_mobile,
        };
      }
    }
  }

  createMarkupThirdSlideParaDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_third_slide_dutch_paragraph) {
        return {
          __html: this.state.commercial_page_third_slide_dutch_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_third_slide_dutch_paragraph_mobile) {
        return {
          __html: this.state.commercial_page_third_slide_dutch_paragraph_mobile,
        };
      }
    }
  }

  createMarkupThirdSlideParaEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_third_slide_english_paragraph) {
        return {
          __html: this.state.commercial_page_third_slide_english_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_third_slide_english_paragraph_mobile) {
        return {
          __html:
            this.state.commercial_page_third_slide_english_paragraph_mobile,
        };
      }
    }
  }

  createMarkupFourthSlideHeadingDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fourth_slide_dutch_heading) {
        return {
          __html: this.state.commercial_page_fourth_slide_dutch_heading,
        };
      }
    } else {
      if (this.state.commercial_page_fourth_slide_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_fourth_slide_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupFourthSlideHeadingEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fourth_slide_english_heading) {
        return {
          __html: this.state.commercial_page_fourth_slide_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_fourth_slide_english_heading_mobile) {
        return {
          __html:
            this.state.commercial_page_fourth_slide_english_heading_mobile,
        };
      }
    }
  }

  createMarkupFourthSlideParaDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fourth_slide_dutch_paragraph) {
        return {
          __html: this.state.commercial_page_fourth_slide_dutch_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_fourth_slide_dutch_paragraph_mobile) {
        return {
          __html:
            this.state.commercial_page_fourth_slide_dutch_paragraph_mobile,
        };
      }
    }
  }

  createMarkupFourthSlideParaEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fourth_slide_english_paragraph) {
        return {
          __html: this.state.commercial_page_fourth_slide_english_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_fourth_slide_english_paragraph_mobile) {
        return {
          __html:
            this.state.commercial_page_fourth_slide_english_paragraph_mobile,
        };
      }
    }
  }

  createMarkupFifthSlideHeadingDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fifth_slide_dutch_heading) {
        return { __html: this.state.commercial_page_fifth_slide_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_fifth_slide_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_fifth_slide_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupFifthSlideHeadingEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fifth_slide_english_heading) {
        return {
          __html: this.state.commercial_page_fifth_slide_english_heading,
        };
      }
    } else {
      if (this.state.commercial_page_fifth_slide_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_fifth_slide_english_heading_mobile,
        };
      }
    }
  }

  createMarkupFifthSlideParaDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fifth_slide_dutch_paragraph) {
        return {
          __html: this.state.commercial_page_fifth_slide_dutch_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_fifth_slide_dutch_paragraph_mobile) {
        return {
          __html: this.state.commercial_page_fifth_slide_dutch_paragraph_mobile,
        };
      }
    }
  }

  createMarkupFifthSlideParaEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_fifth_slide_english_paragraph) {
        return {
          __html: this.state.commercial_page_fifth_slide_english_paragraph,
        };
      }
    } else {
      if (this.state.commercial_page_fifth_slide_english_paragraph_mobile) {
        return {
          __html:
            this.state.commercial_page_fifth_slide_english_paragraph_mobile,
        };
      }
    }
  }

  createMarkupSliderHeadingDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_slider_dutch_heading) {
        return { __html: this.state.commercial_page_slider_dutch_heading };
      }
    } else {
      if (this.state.commercial_page_slider_dutch_heading_mobile) {
        return {
          __html: this.state.commercial_page_slider_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupSliderHeadingEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_slider_english_heading) {
        return { __html: this.state.commercial_page_slider_english_heading };
      }
    } else {
      if (this.state.commercial_page_slider_english_heading_mobile) {
        return {
          __html: this.state.commercial_page_slider_english_heading_mobile,
        };
      }
    }
  }

  createMarkupSliderParaDutch() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_slider_dutch_para) {
        return { __html: this.state.commercial_page_slider_dutch_para };
      }
    } else {
      if (this.state.commercial_page_slider_dutch_para_mobile) {
        return { __html: this.state.commercial_page_slider_dutch_para_mobile };
      }
    }
  }

  createMarkupSliderParaEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.commercial_page_slider_english_para) {
        return { __html: this.state.commercial_page_slider_english_para };
      }
    } else {
      if (this.state.commercial_page_slider_english_para_mobile) {
        return {
          __html: this.state.commercial_page_slider_english_para_mobile,
        };
      }
    }
  }

  render() {
    return (
      <div className="landingpage-bhodi landingpageupdateddesign commercialpagedesign">
        <div className="header-lpbhodi">
          <Link to="/">
            <img
              src={window.innerWidth > 1008 ? logoheaderWhite : logoheader}
            />
          </Link>
        </div>
        <div className="first-section-background-lpb">
          <div
            className="overlay-fsblpb"
            style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
          ></div>
          <video
            src={this.state.commercial_profile_video}
            // src={this.state.commercial_banner_video}
            autoPlay
            muted
            loop
            playsInline
            style={{ maxHeight: window.innerWidth < 1008 && "100%" }}
          ></video>
          <div className="ace-fsblpb">
            <h1 data-aos="fade-in">
              {/* Charity shopping */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerDutchHeading()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerEnglishHeading()}
                ></div>
              )}
              {/* <div dangerouslySetInnerHTML={this.createMarkupBannerDutchHeading()}></div> */}
            </h1>
            <p data-aos="fade-in">
              {/* Als bedrijf kun je Bhodi ondersteunen door het beschikbaar stellen van mooie<br />
                            aanbiedingen voor je klanten, relaties en de bestaande charity members van Bhodi. */}
              {/* <div dangerouslySetInnerHTML={this.createMarkupBannerDutch()}></div> */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerEnglish()}
                ></div>
              )}
            </p>
            {this.state.showDutch ? (
              <button data-aos="fade-in">
                <a href="#charitysection">ONTDEK</a>
              </button>
            ) : (
              <button data-aos="fade-in">
                <a href="#charitysection">DISCOVER</a>
              </button>
            )}
            {/* <button data-aos="fade-in"><a href="#charitysection">ONTDEK</a></button> */}
          </div>
        </div>
        <div className="second-section-background-lpb">
          <h2 className="heading-ssblbp">
            {/* Van start! */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupVanStartDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupVanStartEnglishHeading()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupVanStartDutchHeading()}></div> */}
          </h2>
          <p className="paragraph-ssblbp">
            {/* In het voorjaar van 2022 gaan  we live met Bhodi.<br />
                        Ontdek de mogelijkheden van het platform */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupVanStartDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupVanStartEnglish()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupVanStartDutch()}></div> */}
          </p>
        </div>
        <div className="fourth-section-lpb">
          <div className="left-fslpb">
            <h2>
              {/* Events */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsDutchHeadingInner()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsEnglishHeadingInner()}
                ></div>
              )}
              {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutchHeadingInner()}></div> */}
            </h2>
            <p className="paragraph-ssblbp">
              {/* Bhodi is een social commerce platform waarbij je<br />
                            als winkelier of dienstverlener exclusieve pop-up events<br />
                            kunt organiseren voor je bestaande en nieuwe klanten.<br />
                            Een pop-up event is een aanbieding dat op een onverwachts<br />
                            beschikbaar kan worden gesteld op je Bhodi profielpagina.<br />
                            <br />
                            <br />
                            Je klanten kun je hiervoor uitnodigen via social media en nieuwsbrieven.
                            Een charity pop-up event heeft als kracht dat het exceptioneel is en de datum en tijdstip verrassend zijn.
                            De inschrijving voor het event, de beperkte beschikbaarheid en dat het een aanbieding is
                            voor het goede doel, zorgt voor een exclusiviteit. */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsEnglish()}
                ></div>
              )}
              {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutch()}></div> */}
            </p>
            {/* <button data-aos="fade-in" onClick={() => {
                            window.location.href = "/registration"
                        }}>AANMELDEN</button> */}
            {/* {this.state.showDutch ? (
              <button
                onClick={() => {
                  window.location.href = "/registration";
                }}
              >
                AANMELDEN
              </button>
            ) : (
              <button
                onClick={() => {
                  window.location.href = "/registration";
                }}
              >
                SIGN UP
              </button>
            )} */}
          </div>
          <div
            className="right-fslpb"
            style={{
              backgroundImage: `url("${this.state.commercial_events_image}")`,
            }}
          ></div>
        </div>
        <div className="second-section-background-lpb favroietesection">
          <h2 className="heading-ssblbp" data-aos="fade-in">
            {/* FANBASE */}
            {/* <div dangerouslySetInnerHTML={this.createMarkupFanBaseDutchHeading()}></div> */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupFanBaseDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupFanBaseEnglishHeading()}
              ></div>
            )}
          </h2>
          <p className="paragraph-ssblbp" data-aos="fade-in">
            {/* Blijf met je Bhodi profielpagina op<br />
                        een leuke manier in contact met je klanten */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupFanBaseDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupFanBaseEnglish()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupFanBaseDutch()}></div> */}
          </p>
        </div>
        <div className="fifth-section-lpb">
          <div className="left-fislpb">
            <h2>
              {/* Profielpagina */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileDutchHeading()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileEnglishHeading()}
                ></div>
              )}
              {/* <div dangerouslySetInnerHTML={this.createMarkupProfileDutchHeading()}></div> */}
            </h2>
            <p className="paragraph-ssblbp">
              {/* Op Bhodi krijg je als bedrijf een eigen profielpagina<br />
                            waarop je eenvoudig de events kan toevoegen.<br />
                            <br />
                            Daarnaast bieden we de mogelijkheid dat je de<br />
                            profielpagina kan voorzien van mooie fotografie en je volgers up to date kunt houden van nieuwe events, aanbiedingen en posts over je bedrijf. */}
              {/* Op Bhodi krijg je als bedrijf een eigen profielpagina<br />
                            waarop je eenvoudig de events kan toevoegen.
                            <br />
                            <br />
                            Daarnaast bieden we de mogelijkheid dat je de<br />
                            profielpagina kan voorzien van content en media<br />
                            en je volgers up to date kunt houden van nieuwe<br />
                            events, aanbiedingen en posts over je bedrijf. */}
              {/* <div dangerouslySetInnerHTML={this.createMarkupProfileDutch()}></div> */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileEnglish()}
                ></div>
              )}
            </p>
            {/* <button data-aos="fade-in" onClick={() => {
                            window.location.href = "/registration"
                        }}>AANMELDEN</button> */}
            {/* {this.state.showDutch ? (
              <button
                data-aos="fade-in"
                onClick={() => {
                  window.location.href = "/registration";
                }}
              >
                AANMELDEN
              </button>
            ) : (
              <button
                data-aos="fade-in"
                onClick={() => {
                  window.location.href = "/registration";
                }}
              >
                SIGN UP
              </button>
            )} */}
          </div>
          <div className="right-fislpb">
            <video
              src={this.state.commercial_profile_video}
              autoPlay
              loop
              muted
              playsInline
              className="videocomplete"
            ></video>
          </div>
        </div>
        <div className="second-section-background-lpb favroietesection">
          <h2 className="heading-ssblbp" data-aos="fade-in">
            {/* POP-UP SALE EVENTS */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsEnglishHeading()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutchHeading()}></div> */}
          </h2>
          <p className="paragraph-ssblbp" data-aos="fade-in">
            {/* Expect the unexpected */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsDutchHeaderTxt()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsEnglishHeaderTxt()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutchHeaderTxt()}></div> */}
          </p>
        </div>
        <div className="commercial-section-template">
          <div className="row">
            <div className="col-12 col-lg-6">
              <img
                src={this.state.commercial_aanmelden_image}
                // width="670"
                // height="351"
                style={{ maxWidth: "90%" }}
              />
            </div>
            <div className="col-12 col-lg-6 responsive-text align-self-center">
              <h2>
                {this.state.showDutch ? (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupAanmeldingDutchHeading()}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupAanmeldingEnglishHeading()}
                  ></div>
                )}
                {/* {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupSecondSlideHeadingDutch()}></div> : <div dangerouslySetInnerHTML={this.createMarkupSecondSlideHeadingEnglish()}></div>} */}
              </h2>
              <p>
                {this.state.showDutch ? (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupAanmeldingDutch()}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupAanmeldingEnglish()}
                  ></div>
                )}
                {/* {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupSecondSlideParaDutch()}></div> : <div dangerouslySetInnerHTML={this.createMarkupSecondSlideParaEnglish()}></div>} */}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <img
                src={this.state.commercial_charity_member_image}
                // width="670"
                // height="351"
                style={{ maxWidth: "90%" }}
              />
            </div>
            <div className="col-12 col-lg-6 responsive-text align-self-center">
              <h2>
                {this.state.showDutch ? (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupCharityMemberDutchHeading()}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupCharityMemberEnglishHeading()}
                  ></div>
                )}
              </h2>
              <p>
                {this.state.showDutch ? (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupCharityMemberDutch()}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={this.createMarkupCharityMemberEnglish()}
                  ></div>
                )}
              </p>
            </div>
          </div>
          {/* <div className='container-cst-inner'>
                        <div className='left-ccsti'>
                            <h3>
                                {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupAanmeldingDutchHeading()}></div> : <div dangerouslySetInnerHTML={this.createMarkupAanmeldingEnglishHeading()}></div>}
                            </h3>
                            <p>

                                {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupAanmeldingDutch()}></div> : <div dangerouslySetInnerHTML={this.createMarkupAanmeldingEnglish()}></div>}
                            </p>
                        </div>
                        <div className='right-ccsti'>
                            <div className='container-bg-rccsti'>

                            </div>
                        </div>
                    </div> */}
          {/* <div className='container-cst-inner'>
                        <div className='left-ccsti'>
                            <h3>
                                {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupCharityMemberDutchHeading()}></div> : <div dangerouslySetInnerHTML={this.createMarkupCharityMemberEnglishHeading()}></div>}
                            </h3>
                            <p>
                                {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupCharityMemberDutch()}></div> : <div dangerouslySetInnerHTML={this.createMarkupCharityMemberEnglish()}></div>}
                            </p>
                        </div>
                        <div className='right-ccsti'>
                            <div className='container-bg-rccsti'>

                            </div>
                        </div>
                    </div> */}
        </div>
        <div className="third-section-background-lpb" id="charitysection">
          {this.state.showDutch ? (
            <div className="left-tsblpb">
              <h2 data-aos="fade-in">Charity Member</h2>
              <GiHotMeal style={{ fontSize: 20, marginBottom: 15 }} />
              {window.innerWidth > 1009 ? (
                <p data-aos="fade-in">
                  Nadat een nieuwe Charity Member zich heeft
                  <br />
                  aangemeld op Bhodi gaan wij direct aan de
                  <br />
                  slag met de maaltijduitgifte op de school.
                </p>
              ) : (
                <p data-aos="fade-in">
                  Nadat een nieuwe Charity Member zich heeft
                  <br />
                  aangemeld gaat ons team in Tanzania direct
                  <br />
                  aan de slag met de maaltijduitgifte.
                </p>
              )}
              <GoDeviceCameraVideo
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth > 1009 ? (
                <p data-aos="fade-in">
                  Charity Members ontvangen hiervan ook iedere
                  <br />
                  maand een persoonlijke en op naam ingesproken
                  <br />
                  bedankvideo vanuit Tanzania. Deze video wordt
                  <br />
                  op hun eigen profielpagina weergegeven.
                </p>
              ) : (
                <p data-aos="fade-in">
                  Charity Members ontvangen hiervan een
                  <br />
                  persoonlijke bedankvideo vanuit Tanzania.
                  <br />
                  Deze video wordt op hun profielpagina
                  <br />
                  weergegeven om te bekijken.
                </p>
              )}
              <AiFillGift
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth > 1009 ? (
                <p data-aos="fade-in">
                  Bovendien worden de Charity Members via hun
                  <br />
                  profielpagina realtime op de hoogte gehouden
                  <br />
                  van jullie nieuwste en komende pop-up events.
                </p>
              ) : (
                <p data-aos="fade-in">
                  Daarnaast worden Charity Members via hun
                  <br />
                  profielpagina op de hoogte gehouden van
                  <br />
                  jullie nieuwste en komende pop-up events.
                </p>
              )}
            </div>
          ) : (
            <div className="left-tsblpb">
              <h2 data-aos="fade-in">Charity shopping</h2>
              <GiHotMeal style={{ fontSize: 20, marginBottom: 15 }} />
              {window.innerWidth > 1009 ? (
                <p data-aos="fade-in">
                  After a new Charity Member has registered on
                  <br />
                  Bhodi, we will immediately get started with
                  <br />
                  the meal distribution at the school.
                </p>
              ) : (
                <p data-aos="fade-in">
                  After a new Charity Member has registered on Bhodi, we will
                  immediately get started with the meal distribution at the
                  school.
                </p>
              )}
              <GoDeviceCameraVideo
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth > 1009 ? (
                <p data-aos="fade-in">
                  Charity Members also receive a monthly,
                  <br />
                  personal and recorded by name, thank-you
                  <br />
                  video from Tanzania. This video will be
                  <br />
                  shown on their own profile page.
                </p>
              ) : (
                <p data-aos="fade-in">
                  Charity Members also receive a monthly, personal and recorded
                  by name, thank-you video from Tanzania. This video will be
                  shown on their own profile page.
                </p>
              )}
              <AiFillGift
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth > 1009 ? (
                <p data-aos="fade-in">
                  Besides that, the Charity Members will be
                  <br />
                  updated in realtime through their profile
                  <br />
                  page of your latest and upcoming events.
                </p>
              ) : (
                <p data-aos="fade-in">
                  Besides that, the Charity Members will be updated in realtime
                  through their profile page of your latest and upcoming events.
                </p>
              )}
            </div>
          )}
          <div className="right-tsblpb">
            <video
              src={this.state.commercial_page_charity_video}
              autoPlay
              muted
              loop
              playsInline
            ></video>
          </div>
        </div>
        <div className="second-section-background-lpb favroietesection">
          {/* <h2 className="heading-ssblbp" data-aos="fade-in">
                        POP-UP EVENTS
                    </h2>
                    <p className="paragraph-ssblbp" data-aos="fade-in">
                        Bekijk de verschillende pop-up events
                    </p> */}
          <h2 className="heading-ssblbp" data-aos="fade-in">
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupSliderHeadingDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupSliderHeadingEnglish()}
              ></div>
            )}
          </h2>
          <p className="paragraph-ssblbp" data-aos="fade-in">
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupSliderParaDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupSliderParaEnglish()}
              ></div>
            )}
          </p>
        </div>
        <div className="slider-section-commercial">
          {/* <div className='first-slide-ssc' style={{ left: this.state.onSlideOne ? 0 : "-100%" }}>
                        

                    </div>
                    <div className='second-slide-ssc' style={{ left: this.state.onSlideTwo ? 0 : "-100%" }}>
                        <div className='buttons-ssc-open'>
                            <button className={this.state.onSlideOne && "active"} onClick={() => this.setState({ onSlideOne: true, onSlideTwo: false, onSlideThree: false })}>Stap 1</button>
                            <button className={this.state.onSlideTwo && "active"} onClick={() => this.setState({ onSlideTwo: true, onSlideOne: false, onSlideThree: false })}>Stap 2</button>
                            <button className={this.state.onSlideThree && "active"} onClick={() => this.setState({ onSlideThree: true, onSlideOne: false, onSlideTwo: false })}>Stap 3</button>
                        </div>
                    </div>
                    <div className='third-slide-ssc' style={{ left: this.state.onSlideThree ? 0 : "-100%" }}>
                        <div className='buttons-ssc-open'>
                            <button className={this.state.onSlideOne && "active"} onClick={() => this.setState({ onSlideOne: true, onSlideTwo: false, onSlideThree: false })}>Stap 1</button>
                            <button className={this.state.onSlideTwo && "active"} onClick={() => this.setState({ onSlideTwo: true, onSlideOne: false, onSlideThree: false })}>Stap 2</button>
                            <button className={this.state.onSlideThree && "active"} onClick={() => this.setState({ onSlideThree: true, onSlideOne: false, onSlideTwo: false })}>Stap 3</button>
                        </div>
                    </div> */}
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
            data-interval="false"
          >
            {/* <ol class="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        </ol> */}
            <div className="buttons-ssc-open">
              {/* <button
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className={this.state.onSlideOne && "active"}
                onClick={() =>
                  this.setState({
                    onSlideOne: true,
                    onSlideTwo: false,
                    onSlideThree: false,
                    onSlideFour: false,
                    onSlideFive: false,
                  })
                }
              >
                Livestream
              </button> */}
              <button
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className={this.state.onSlideTwo && "active"}
                onClick={() =>
                  this.setState({
                    onSlideTwo: true,
                    onSlideOne: false,
                    onSlideThree: false,
                    onSlideFour: false,
                    onSlideFive: false,
                  })
                }
              >
                Giveaway
              </button>
              <button
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
                className={this.state.onSlideThree && "active"}
                onClick={() =>
                  this.setState({
                    onSlideThree: true,
                    onSlideOne: false,
                    onSlideTwo: false,
                    onSlideFour: false,
                    onSlideFive: false,
                  })
                }
              >
                Kortingsvoucher
              </button>
              <button
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
                className={this.state.onSlideFour && "active"}
                onClick={() =>
                  this.setState({
                    onSlideFour: true,
                    onSlideOne: false,
                    onSlideTwo: false,
                    onSlideThree: false,
                    onSlideFive: false,
                  })
                }
              >
                Events
              </button>
              <button
                data-target="#carouselExampleIndicators"
                data-slide-to="3"
                className={this.state.onSlideFive && "active"}
                onClick={() =>
                  this.setState({
                    onSlideFive: true,
                    onSlideOne: false,
                    onSlideTwo: false,
                    onSlideFour: false,
                    onSlideThree: false,
                  })
                }
              >
                Webshopkorting
              </button>
            </div>
            <div class="carousel-inner">
              {/* <div class="carousel-item">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <img
                      src={this.state.commercial_page_first_slide_image}
                      width="527"
                      height="351"
                    />
                  </div>
                  <div className="col-12 col-lg-6 responsive-text align-self-center">
                    <h2>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFirstSlideHeadingDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFirstSlideHeadingEnglish()}
                        ></div>
                      )}
                    </h2>
                    <p>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFirstSlideParaDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFirstSlideParaEnglish()}
                        ></div>
                      )}
                    </p>
                  </div>
                </div>
              </div> */}
              <div class="carousel-item active">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <img
                      src={this.state.commercial_page_second_slide_image}
                      width="527"
                      height="351"
                    />
                  </div>
                  <div className="col-12 col-lg-6 responsive-text align-self-center">
                    <h2>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupSecondSlideHeadingDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupSecondSlideHeadingEnglish()}
                        ></div>
                      )}
                    </h2>
                    <p>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupSecondSlideParaDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupSecondSlideParaEnglish()}
                        ></div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <img
                      src={this.state.commercial_page_third_slide_image}
                      width="527"
                      height="351"
                    />
                  </div>
                  <div className="col-12 col-lg-6 responsive-text align-self-center">
                    <h2>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupThirdSlideHeadingDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupThirdSlideHeadingEnglish()}
                        ></div>
                      )}
                    </h2>
                    <p>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupThirdSlideParaDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupThirdSlideParaEnglish()}
                        ></div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <img
                      src={this.state.commercial_page_fourth_slide_image}
                      width="527"
                      height="351"
                    />
                  </div>
                  <div className="col-12 col-lg-6 responsive-text align-self-center">
                    <h2>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFourthSlideHeadingDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFourthSlideHeadingEnglish()}
                        ></div>
                      )}
                    </h2>
                    <p>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFourthSlideParaDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFourthSlideParaEnglish()}
                        ></div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <img
                      src={this.state.commercial_page_fifth_slide_image}
                      width="527"
                      height="351"
                    />
                  </div>
                  <div className="col-12 col-lg-6 responsive-text align-self-center">
                    <h2>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFifthSlideHeadingDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFifthSlideHeadingEnglish()}
                        ></div>
                      )}
                    </h2>
                    <p>
                      {this.state.showDutch ? (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFifthSlideParaDutch()}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={this.createMarkupFifthSlideParaEnglish()}
                        ></div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="buttons-bottom-carousel">
              {this.state.showDutch ? (
                <button
                  onClick={() => {
                    window.location.href = "/registration";
                  }}
                >
                  AANMELDEN
                </button>
              ) : (
                <button
                  onClick={() => {
                    window.location.href = "/registration";
                  }}
                >
                  SIGN UP
                </button>
              )}
            </div> */}
            {/* <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a> */}
          </div>
        </div>
        <div className="second-section-background-lpb favroietesection">
          <h2 className="heading-ssblbp" data-aos="fade-in">
            {/* About */}
            {/* <div dangerouslySetInnerHTML={this.createMarkupAboutDutchHeading()}></div> */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutEnglishHeading()}
              ></div>
            )}
          </h2>
          <p className="paragraph-ssblbp" data-aos="fade-in">
            {/* Er zijn veel online platforms waarvan ondernemers<br />
                        gebruiken maken voor het verkopen van hun producten of diensten.<br />
                        Door de kortingen en de afdracht van een commissie en kosten<br />
                        aan het platform, komen de marges erg onder druk te staan.<br />
                        <br />
                        Vanuit Bhodi vinden wij dit geen gezonde marktwerking en<br />
                        daarom functioneert Bhodi volledig zonder bemiddelingsbijdrage. */}
            {/* <div dangerouslySetInnerHTML={this.createMarkupAboutDutch()}></div> */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutEnglish()}
              ></div>
            )}
          </p>
        </div>
        <div
          className="sixth-section-lpb"
          style={{
            backgroundImage: `url("${this.state.landing_page_footer_image}")`,
          }}
        >
          <div className="container-sslpb">
            <h2>
              {/* Team Bhodi */}
              {/* <div dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutchHeading()}></div> */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutchHeading()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiEnglishHeading()}
                ></div>
              )}
            </h2>
            <div className="circle-csslpb">
              <RiDoubleQuotesR />
            </div>
            <p className="para-desc-sslpb">
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiEnglish()}
                ></div>
              )}
              {/* <div dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutch()}></div> */}
              {/* Als bedrijf kun je Bhodi ondersteunen door het beschikbaar<br />
                            stellen van exclusieve pop-up events op je profielpagina.
                            <br />
                            <br />
                            De profielpagina kun je onder de aandacht brengen bij je klanten<br />
                            en kunnen ze zich eenvoudig registreren als charity member en<br />
                            gebruik maken van de exclusieve kortingen.
                            <br />
                            <br />
                            Op deze manier kunnen we voor Bhodi nieuwe charity members<br />
                            werven en kinderen en scholen in Tanzania ondersteunen */}
              {/* Bhodi is een social commerce platform<br />en staan wij voor impact first en meer<br />gelijkheid in de wereld.
                            <br />
                            <br />
                            In samenwerking met verschillende winkeliers<br />en dienstverleners stellen
                            wij exclusieve events<br />beschikbaar voor de Bhodi charity members. */}
            </p>
            {/* <p className='name-desc-sslpb'>Maurice Reijersen & Naomi Latuperissa</p> */}
          </div>
        </div>
        <div className="footer-section-lpb">
          <div className="container-fslpb">
            <div className="block-cfslpb">
              <h3 data-aos="fade-in">Charity</h3>
              <p data-aos="fade-in">Maaltijden voor kinderen</p>
            </div>
            <div className="block-cfslpb">
              <h3 data-aos="fade-in">Events</h3>
              <p data-aos="fade-in">Leuke pop-up sale events</p>
            </div>
            <div className="block-cfslpb">
              <h3 data-aos="fade-in">Voorwaarden</h3>
              <p
                data-aos="fade-in"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                Gebruikersvoorwaarden
              </p>
            </div>
          </div>
          {/* <div className="container-fslpb iconscontainer">
            <div
              className="block-cfslpb"
              onClick={() => {
                window.open(
                  "https://www.facebook.com/bhodi.lifestyle",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            >
              <FaFacebookF />
            </div>
            <div
              className="block-cfslpb"
              onClick={() => {
                window.open(
                  "https://twitter.com/bhodi_lifestyle",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            >
              <BsTwitter />
            </div>
            <div
              className="block-cfslpb"
              onClick={() => {
                window.open(
                  "https://www.instagram.com/bhodi_lifestyle",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            >
              <AiOutlineInstagram />
            </div>
          </div> */}
        </div>
        <Footer notOnMainPage={true} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
    user: state.authReducers.user,
    admin_boolean: state.globalReducers.admin_boolean,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAdminOrNot: (admin_boolean) =>
      dispatch(updateAdminOrNot(admin_boolean)),
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Commercial);
