import React, { Component } from 'react'
import { connect } from 'react-redux';
import { history } from '../../../Config/Routes';
import firebase from '../../../Config/Firebase'
import thnxVideo from "../../../assets/thnx.mp4"
import logo from "../../../assets/logoheaderlifestyle.png"
import playiconImage from "../../../assets/playiconlandingpage.png"
import "./index.css"
import { QRCodeCanvas } from 'qrcode.react';
import Footer from '../../../Components/Footer';
import FooterGreen from '../../../Components/FooterGreen';

export class GenerateQRPageCompany extends Component {

    constructor() {
        super()
        this.state = {
            currentUser: "",
            qrPopupOpen: false,
            qrCodeLink: "",
            currentQrQuery: "",
        }
    }

    componentDidMount() {
        if (this.props.currentMemberData) {
            this.setState({ currentUser: this.props.currentMemberData })
            firebase.database().ref("users/" + this.props.currentMemberData.user_id).on("value", (data) => {
                let a = data.val()
                if (a) {
                    this.setState({ currentUser: a })
                }
            })
        }
    }

    render() {
        return (
            <div className='qr-code-page'>
                <div className='left-qcp'>
                    <div className='content-lqcp'>
                        <img src={logo} alt='logo' />
                        <img src={playiconImage} alt='play-icon' className='play-icon' />
                        <p className='desc-clqcp'>Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text</p>
                        {this.state.currentUser ? (<div className='qr-lqcp'>
                            <QRCodeCanvas value={`https://us-central1-bhodi-7c327.cloudfunctions.net/generateStaticLinkCompanies?companyId=${this.state.currentUser.user_name_id}`} size={250} />
                        </div>) : (<></>)}
                        {this.state.currentUser.access_code_payment_link && (<p className='desc-clqcp' style={{ marginTop: 30 }}>Access Code: {this.state.currentUser.access_code_payment_link}</p>)}
                    </div>
                </div>
                <div className='right-qcp'>
                    <video
                        src={thnxVideo}
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="videocomplete"
                    ></video>
                </div>

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

export default connect(
    mapStateToProps
)(GenerateQRPageCompany);