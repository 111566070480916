import React, { Component } from "react";
import "./index.css";
import countries from "../../DataJSON/countries.json";
import { connect } from "react-redux";
import Footer from "../../Components/Footer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import firebase from "../../Config/Firebase";
import {
    updateAdminOrNot,
    updateSignUpFieldsFilled,
} from "../../Redux/actions/authActions";
import FooterGreen from "../../Components/FooterGreen";
import { history } from "../../Config/Routes";
import FooterBasketTimer from "../../Components/FooterBasketTimer";
import swal from "sweetalert";
import Switch from "@material-ui/core/Switch";
import { MdClose } from "react-icons/md";
import { BiCheck } from "react-icons/bi";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { invalidChars } from "../../Config/Constants";
import "./index.css"
import { notifyMe } from "../../Functions/globalFunctions";

const axios = require("axios");

const CancelToken = axios.CancelToken
let cancelPreviousRequest;

export class SignUpAnnouncement extends Component {
    constructor() {
        super();
        this.state = {
            country: "155",
            isUserForm: true,
            showCharityStuff: false,
            subscription_data: [],
            plan_id: "",
            plan_amount: "",
            // registration fields
            firstName: "",
            firstNamePopup: false,
            lastName: "",
            lastNamePopup: false,
            email: "",
            emailPopup: false,
            password: "",
            passwordPopup: false,
            country: "155",
            companyRegistration: false,
            companyName: "",
            kvkCompany: "",
            kvkCompanyPopup: false,
            street: "",
            streetPopup: false,
            houseNumber: "",
            houseNumberPopup: false,
            city: "",
            cityPopup: false,
            zipCode: "",
            zipCodePopup: false,
            phoneNumber: "",
            phoneNumberPopup: false,
            company_website: "",
            company_websitePopup: false,
            passwordInputType: "password",

            aanmeldenloader: false,
            allUsers: [],
            agreed: false,
            agreedPopup: false,
            currentUserSpecific: "",
            isUserCreated: false,
            currentDataOfUser: "",
            popupopenforright: true,
            guestCartTime: "",
            shipmentFeeAvailable: false,
            isAddressLoaded: false,
            loadingAddress: false,
            avoidReadOnly: false,
            isUserInfoFilled: false,

            sessionIdForAutocompleteAPI: "",

            creatingNewUser: true,

            currentDataOfSignedInUser: "",

            productsCheckingOut: true,
            addressMatches: [],
            latitude: "",
            longitude: "",

            organizationUserId: "",

            showDutch: true,

            checkout_step_one_new_text_first: "",
            checkout_step_one_new_text_first_mobile: "",
            checkout_step_one_new_text_first_english: "",
            checkout_step_one_new_text_first_english_mobile: "",
            checkout_step_one_new_second_text: "",
            checkout_step_one_new_second_text_mobile: "",
            checkout_step_one_new_second_text_english: "",
            checkout_step_one_new_second_text_english_mobile: "",
            checkout_step_one_new_text_first_signed_in: "",
            checkout_step_one_new_text_first_signed_in_mobile: "",
            checkout_step_one_new_text_first_signed_in_english: "",
            checkout_step_one_new_text_first_signed_in_english_mobile: "",
            checkout_step_one_new_second_text_signed_in: "",
            checkout_step_one_new_second_text_signed_in_mobile: "",
            checkout_step_one_new_second_text_signed_in_english: "",
            checkout_step_one_new_second_text_signed_in_english_mobile: "",
            checkout_step_one_new_text: "",
            checkout_step_one_new_text_mobile: "",
            checkout_step_one_new_text_english: "",
            checkout_step_one_new_text_english_mobile: "",
            checkout_step_one_new_text_signed_in: "",
            checkout_step_one_new_text_signed_in_mobile: "",
            checkout_step_one_new_text_signed_in_english: "",
            checkout_step_one_new_text_signed_in_english_mobile: "",

            companyIdProducts: "",
        };
    }

    generateUniqueId(length) {
        const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let uniqueId = '';

        while (uniqueId.length < length) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            uniqueId += characters.charAt(randomIndex);
        }

        return uniqueId;
    }

    componentDidMount() {
        let urlMain = new URLSearchParams(window.location.search)
        if (!urlMain.get("companyId")) {
            history.push("/")
            return false;
        }

        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        this.setState({ sessionIdForAutocompleteAPI: this.generateUniqueId(10), companyIdProducts: urlMain.get("companyId") })

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 500);

        let userid = localStorage.getItem("userid");

        if (window.innerWidth > 1008) {
            this.setState({ popupopenforright: true });
        } else {
            this.setState({ popupopenforright: false });
        }

        if (this.props.user) {
            if (this.props.user.length > 0) {
                this.setState({ allUsers: this.props.user });
            }
        }

        firebase
            .database()
            .ref("users")
            .once("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a) {
                    let objected = Object.entries(a);
                    objected.map((g) => {
                        if (
                            g[0] !== undefined &&
                            g[0] !== "undefined" &&
                            g[0] !== null &&
                            g[0] !== "null"
                        ) {
                            arr.push(g[1]);
                        }
                    });
                    let usersWithNumbers = []
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t)
                        }
                    })
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                }
            });

        let filtered = [];
        if (this.props.user) {
            filtered = this.props.user.filter((u) => {

                return u.user_id === userid;
            });
        }
        if (filtered.length > 0) {
            if (filtered[0].first_name) {
                this.setState({ firstName: filtered[0].first_name });
            }
            if (filtered[0].last_name) {
                this.setState({ lastName: filtered[0].last_name });
            }
            if (filtered[0].phoneNumber) {
                this.setState({ phoneNumber: filtered[0].phoneNumber });
            }
            if (filtered[0].company_name) {
                this.setState({
                    companyName: filtered[0].company_name,
                    isUserForm: false,
                });
            }
            if (filtered[0].type === "company") {
                this.setState({ isUserForm: false })
            }
            if (filtered[0].street) {
                this.setState({ street: filtered[0].street });
            }
            if (filtered[0].zipCode) {
                this.setState({ zipCode: filtered[0].zipCode });
            }
            if (filtered[0].city) {
                this.setState({ city: filtered[0].city });
            }
            if (filtered[0].houseNumber) {
                this.setState({ houseNumber: filtered[0].houseNumber });
            }
            if (filtered[0].street || filtered[0].zipCode || filtered[0].city || filtered[0].houseNumber) {
                this.setState({ isAddressLoaded: true })
            }
            this.setState({ address: `${filtered[0].zipCode} ${filtered[0].city} ${filtered[0].street} ${filtered[0].houseNumber}` })

            this.setState({ userSignedIn: true, currentDataOfSignedInUser: filtered[0] });
        }

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    checkout_step_one_new_text_first: a.checkout_step_one_new_text_first || "",
                    checkout_step_one_new_text_first_mobile: a.checkout_step_one_new_text_first_mobile || "",
                    checkout_step_one_new_text_first_english: a.checkout_step_one_new_text_first_english || "",
                    checkout_step_one_new_text_first_english_mobile: a.checkout_step_one_new_text_first_english_mobile || "",
                    checkout_step_one_new_second_text: a.checkout_step_one_new_second_text || "",
                    checkout_step_one_new_second_text_mobile: a.checkout_step_one_new_second_text_mobile || "",
                    checkout_step_one_new_second_text_english: a.checkout_step_one_new_second_text_english || "",
                    checkout_step_one_new_second_text_english_mobile: a.checkout_step_one_new_second_text_english_mobile || "",
                    checkout_step_one_new_text_first_signed_in: a.checkout_step_one_new_text_first_signed_in || "",
                    checkout_step_one_new_text_first_signed_in_mobile: a.checkout_step_one_new_text_first_signed_in_mobile || "",
                    checkout_step_one_new_text_first_signed_in_english: a.checkout_step_one_new_text_first_signed_in_english || "",
                    checkout_step_one_new_text_first_signed_in_english_mobile: a.checkout_step_one_new_text_first_signed_in_english_mobile || "",
                    checkout_step_one_new_second_text_signed_in: a.checkout_step_one_new_second_text_signed_in || "",
                    checkout_step_one_new_second_text_signed_in_mobile: a.checkout_step_one_new_second_text_signed_in_mobile || "",
                    checkout_step_one_new_second_text_signed_in_english: a.checkout_step_one_new_second_text_signed_in_english || "",
                    checkout_step_one_new_second_text_signed_in_english_mobile: a.checkout_step_one_new_second_text_signed_in_english_mobile || "",
                    checkout_step_one_new_text: a.checkout_step_one_new_text || "",
                    checkout_step_one_new_text_mobile: a.checkout_step_one_new_text_mobile || "",
                    checkout_step_one_new_text_english: a.checkout_step_one_new_text_english || "",
                    checkout_step_one_new_text_english_mobile: a.checkout_step_one_new_text_english_mobile || "",
                    checkout_step_one_new_text_signed_in: a.checkout_step_one_new_text_signed_in || "",
                    checkout_step_one_new_text_signed_in_mobile: a.checkout_step_one_new_text_signed_in_mobile || "",
                    checkout_step_one_new_text_signed_in_english: a.checkout_step_one_new_text_signed_in_english || "",
                    checkout_step_one_new_text_signed_in_english_mobile: a.checkout_step_one_new_text_signed_in_english_mobile || "",
                });
            }
        }


        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.setState({
                        checkout_step_one_new_text_first: a.checkout_step_one_new_text_first || "",
                        checkout_step_one_new_text_first_mobile: a.checkout_step_one_new_text_first_mobile || "",
                        checkout_step_one_new_text_first_english: a.checkout_step_one_new_text_first_english || "",
                        checkout_step_one_new_text_first_english_mobile: a.checkout_step_one_new_text_first_english_mobile || "",
                        checkout_step_one_new_second_text: a.checkout_step_one_new_second_text || "",
                        checkout_step_one_new_second_text_mobile: a.checkout_step_one_new_second_text_mobile || "",
                        checkout_step_one_new_second_text_english: a.checkout_step_one_new_second_text_english || "",
                        checkout_step_one_new_second_text_english_mobile: a.checkout_step_one_new_second_text_english_mobile || "",
                        checkout_step_one_new_text_first_signed_in: a.checkout_step_one_new_text_first_signed_in || "",
                        checkout_step_one_new_text_first_signed_in_mobile: a.checkout_step_one_new_text_first_signed_in_mobile || "",
                        checkout_step_one_new_text_first_signed_in_english: a.checkout_step_one_new_text_first_signed_in_english || "",
                        checkout_step_one_new_text_first_signed_in_english_mobile: a.checkout_step_one_new_text_first_signed_in_english_mobile || "",
                        checkout_step_one_new_second_text_signed_in: a.checkout_step_one_new_second_text_signed_in || "",
                        checkout_step_one_new_second_text_signed_in_mobile: a.checkout_step_one_new_second_text_signed_in_mobile || "",
                        checkout_step_one_new_second_text_signed_in_english: a.checkout_step_one_new_second_text_signed_in_english || "",
                        checkout_step_one_new_second_text_signed_in_english_mobile: a.checkout_step_one_new_second_text_signed_in_english_mobile || "",
                        checkout_step_one_new_text: a.checkout_step_one_new_text || "",
                        checkout_step_one_new_text_mobile: a.checkout_step_one_new_text_mobile || "",
                        checkout_step_one_new_text_english: a.checkout_step_one_new_text_english || "",
                        checkout_step_one_new_text_english_mobile: a.checkout_step_one_new_text_english_mobile || "",
                        checkout_step_one_new_text_signed_in: a.checkout_step_one_new_text_signed_in || "",
                        checkout_step_one_new_text_signed_in_mobile: a.checkout_step_one_new_text_signed_in_mobile || "",
                        checkout_step_one_new_text_signed_in_english: a.checkout_step_one_new_text_signed_in_english || "",
                        checkout_step_one_new_text_signed_in_english_mobile: a.checkout_step_one_new_text_signed_in_english_mobile || "",
                    });
                }
            });

    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            if (this.state.email) {
                let filtered = this.props.user.filter((u) => {
                    return u.email === this.state.email;
                });
                if (filtered.length > 0) {
                    this.setState({ currentDataOfUser: filtered[0] });
                } else {
                    this.setState({ currentDataOfUser: "" });
                }
            }
        }
    }

    getDayName(num) {
        if (num === 0) {
            return `Zondag`;
        } else if (num === 1) {
            return `Maandag`;
        } else if (num === 2) {
            return `Dinsdag`;
        } else if (num === 3) {
            return `Woensdag`;
        } else if (num === 4) {
            return `Donderdag`;
        } else if (num === 5) {
            return `Vrijdag`;
        } else if (num === 6) {
            return `Zaterdag`;
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }

    showDateToUserTiming(e, f) {
        if (e === f) {
            let splitted = e.split("-");
            let newDate = new Date(e).getDay();
            return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
                splitted[1]
            )}`;
        } else {
            let splitted = e.split("-");
            let splittedSecond = f.split("-");
            let newDate = new Date(e).getDay();
            let newDateAnother = new Date(f).getDay();
            if (splitted[1] === splittedSecond[1]) {
                return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
                    } ${this.getMonthName(splittedSecond[1])}`;
            } else {
                // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
                return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
                    } ${this.getMonthName(splittedSecond[1])}`;
            }
        }
    }

    randomStr(e) {
        let len = e;
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489";
        var ans = "";
        for (var i = len; i > 0; i--) {
            ans += arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    registerUser() {
        const {
            firstName,
            lastName,
            email,
            agreed,
            password,
            isUserForm,
            companyName,
            phoneNumber,
            street,
            city,
            zipCode,
            houseNumber
        } = this.state;
        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let filteredForEmail = this.props.user.filter((v) => {
            return v.email === email;
        });
        console.log(filteredForEmail, "/working");
        let cookie_item = localStorage.getItem("cookie_item")

        if (firstName === "") {
            this.setState({ firstNamePopup: true });
        } else {
            this.setState({ firstNamePopup: false });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailPopup: true });
        } else {
            this.setState({ emailPopup: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passwordPopup: true });
        } else {
            this.setState({ passwordPopup: false });
        }
        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (lastName === "") {
            this.setState({ lastNamePopup: true });
        } else {
            this.setState({ lastNamePopup: false });
        }
        if (phoneNumber === "") {
            this.setState({ phoneNumberPopup: true });
        } else {
            this.setState({ phoneNumberPopup: false });
        }
        let companyFlag = true
        if (!isUserForm && companyName === "") {
            this.setState({ companyNamePopup: true });
            companyFlag = false
        } else {
            this.setState({ companyNamePopup: false });
            companyFlag = true
        }

        if ((!street || !houseNumber || !zipCode || !city) && this.state.productsCheckingOut) {
            if (street) {
                this.setState({ streetPopup: false })
            } else {
                this.setState({ streetPopup: true })
            }
            if (houseNumber) {
                this.setState({ houseNumberPopup: false })
            } else {
                this.setState({ houseNumberPopup: true })
            }
            if (city) {
                this.setState({ cityPopup: false })
            } else {
                this.setState({ cityPopup: true })
            }
            if (zipCode) {
                this.setState({ zipCodePopup: false })
            } else {
                this.setState({ zipCodePopup: true })
            }
            return false;
        }

        if (
            firstName !== "" &&
            email !== "" &&
            password !== "" &&
            password.length > 5 &&
            regexEmail.test(email) &&
            agreed &&
            lastName !== "" &&
            phoneNumber !== "" &&
            companyFlag
        ) {
            this.setState({ aanmeldenloader: true });

            let promiseAccountCreation = new Promise((resolve, reject) => {
                let toCalculateFrom = firstName + lastName
                if (!isUserForm) {
                    toCalculateFrom = companyName
                }

                let jointName = toCalculateFrom;

                let filteredForJoin = this.state.allUsers.filter((g) => {
                    return g.user_name_id === jointName.toLowerCase();
                });

                if (filteredForJoin.length > 0) {
                    let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                    jointName = toCalculateFrom + randomStr;

                    let filteredForJoinSecond = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });
                    if (filteredForJoinSecond.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                        jointName = toCalculateFrom + randomStr;

                        let filteredForJoinThird = this.state.allUsers.filter((g) => {
                            return g.user_name_id === jointName.toLowerCase();
                        });
                        if (filteredForJoinThird.length > 0) {
                            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                            jointName = toCalculateFrom + randomStr;

                            let filteredForJoinFourth = this.state.allUsers.filter(
                                (g) => {
                                    return g.user_name_id === jointName.toLowerCase();
                                }
                            );
                            if (filteredForJoinFourth.length > 0) {
                                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                jointName = toCalculateFrom + randomStr;
                            }
                        }
                    }
                }

                if (jointName.includes(" ")) {
                    jointName = jointName.replace(/ /g, "_");
                }

                let today = new Date();
                let date =
                    today.getDate() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getFullYear();

                firebase
                    .auth()
                    .createUserWithEmailAndPassword(email, password)
                    .then((e) => {
                        let userId = e.user.uid;
                        // localStorage.setItem("userid", userId);

                        let obj = {
                            first_name: firstName,
                            email: email,
                            password: "",
                            country: "",
                            passwordChanged: false,
                            user_id: userId,
                            type: !isUserForm ? "company" : "user",
                            user_name_id: jointName.toLowerCase(),
                            created_on: date,
                            newly_registered: false,
                            user_id_numbers: this.state.currentUserSpecific,
                            accountType: "global",
                            profileActive: true,
                            phoneNumber,
                            hideWizard: true,
                            cookie_item,
                        };
                        if (lastName) {
                            obj.last_name = lastName;
                        }
                        if (!isUserForm) {
                            obj.company_name = companyName;
                        }
                        if (this.state.companyIdProducts) {
                            obj.company_referred = this.state.companyIdProducts;
                        }
                        if (this.state.productsCheckingOut) {
                            obj.street = street
                            obj.city = city
                            obj.zipCode = zipCode
                            obj.houseNumber = houseNumber
                        }

                        firebase
                            .database()
                            .ref("users/" + userId)
                            .set(obj)
                            .then(async () => {
                                // await notifyMe(obj, this.state.companyIdProducts)

                                localStorage.setItem("userid-guest", e.user.uid);

                                this.props.updateAdminOrNot(false);
                                localStorage.setItem("isAdmin", false);

                                resolve(userId)

                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((error) => {
                        if (error.code === "auth/email-already-in-use") {
                            this.setState({
                                loader: false,
                                stepOne: true,
                                emailTookedPopup: true,
                                aanmeldenloader: false,
                            });
                        }
                    });
            })

            let promiseCreateStripeCustomer = new Promise((res) => {
                axios
                    .post(
                        "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCustomer",
                        {
                            fullName: this.state.firstName + "" + this.state.lastName,
                            email: this.state.email,
                        }
                    )
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            res(response.data.data.id)
                        }
                    })
            })

            Promise.all([promiseAccountCreation, promiseCreateStripeCustomer]).then((data) => {
                let uid = data[0]
                let stripeId = data[1]

                const updates = {
                    [`users/${uid}/stripe_id`]: stripeId,
                };

                firebase.database().ref().update(updates)
                    .then(() => {
                        this.setState({ aanmeldenloader: false });

                        localStorage.setItem("moveback-two", true);

                        this.props.updateSignUpFieldsFilled("")
                        window.location.href = `/signup-announcement/stepTwo?companyId=${this.state.companyIdProducts}`
                        // window.location.href = "/signup-announcement/stepTwo"
                    });
            })

        }
    }

    getSellerName(e) {
        if (e) {
            let filteredUser = this.props.user.filter((g) => {
                if (e.mainParentCreator) {
                    return g.user_id === e.mainParentCreator;
                } else {
                    return g.user_id === e.created_by;
                }
            });
            if (filteredUser.length > 0) {
                if (filteredUser[0].type === "company") {
                    if (filteredUser[0].company_name) {
                        return filteredUser[0].company_name;
                    } else {
                        return filteredUser[0].first_name + " " + filteredUser[0].last_name;
                    }
                } else {
                    return filteredUser[0].first_name + " " + filteredUser[0].last_name;
                }
            }
        }
    }

    checkForEmail = () => {
        const { email } = this.state;
        if (email) {
            if (this.props.user) {
                let filtered = this.props.user.filter((g) => {
                    return g.email === email
                })
                if (filtered.length > 0) {
                    this.setState({ creatingNewUser: false })
                } else {
                    this.setState({ creatingNewUser: true })
                }
                this.setState({ emailLoaded: true })
            }
        } else {
            this.setState({ emailLoaded: false, creatingNewUser: true })
        }
    };


    loginUser() {
        const { email, password } = this.state;

        if (password === "" || password.length < 6) {
            this.setState({ passwordPopup: true });
        } else {
            this.setState({ passwordPopup: false });
        }
        if (password !== "" && password.length > 5) {
            this.setState({ aanmeldenloader: true });

            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((succ) => {
                    firebase
                        .database()
                        .ref("users/" + succ.user.uid)
                        .once("value", async (data) => {
                            let a = data.val();
                            if (a) {

                                if (a.subscription && a.subscription.status === "active") {
                                    localStorage.setItem("userid", a.user_id);
                                    this.props.updateAdminOrNot(false);
                                    localStorage.setItem("isAdmin", false);
                                    localStorage.setItem("user-already-member", true);

                                    await notifyMe(a, this.state.companyIdProducts)

                                    swal({
                                        title: "Awesome",
                                        text: "Je bent reeds charity member, je order wordt nu besteld.",
                                        icon: "success"
                                    }).then(() => {
                                        if (a.type === "company") {
                                            window.location.href = `/company/${a.user_name_id}`
                                        } else {
                                            window.location.href = `/member/${a.user_name_id}`
                                        }
                                    })
                                } else {
                                    if (a.stripe_id) {
                                        localStorage.setItem("userid-nonmem", a.user_id);
                                        localStorage.setItem("moveback-two", true);

                                        // await notifyMe(a, this.state.companyIdProducts)
                                        window.location.href = `/signup-announcement/stepTwo?companyId=${this.state.companyIdProducts}`
                                    } else {
                                        localStorage.setItem("userid-nonmem", a.user_id);
                                        localStorage.setItem("moveback-two", true);

                                        axios
                                            .post(
                                                "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCustomer",
                                                {
                                                    fullName: a.first_name + "" + a.last_name,
                                                    email: a.email,
                                                }
                                            )
                                            .then(async (response) => {
                                                if (response.data.statusCode === 200) {
                                                    const updates = {
                                                        [`users/${a.user_id}/stripe_id`]: response.data.data.id,
                                                    };
                                                    // await notifyMe(a, this.state.companyIdProducts)

                                                    firebase.database().ref().update(updates).then(() => {
                                                        window.location.href = `/signup-announcement/stepTwo?companyId=${this.state.companyIdProducts}`
                                                        // window.location.href = "/signup-announcement/stepTwo"
                                                    })
                                                }
                                            })
                                    }
                                }
                            }
                        });
                })
                .catch((err) => {
                    if (err.code === "auth/wrong-password") {
                        this.setState({
                            aanmeldenloader: false,
                            passwordPopup: true,
                            password: "",
                        });
                    } else {
                        this.setState({
                            aanmeldenloader: false
                        })
                        alert("Login failed, please try again")
                    }
                });
        }
    }

    proceedSignedInUser() {

        if ((!this.state.street || !this.state.houseNumber || !this.state.firstName || !this.state.lastName || !this.state.zipCode || !this.state.city) && this.state.productsCheckingOut) {
            if (this.state.street) {
                this.setState({ streetPopup: false })
            } else {
                this.setState({ streetPopup: true })
            }
            if (this.state.houseNumber) {
                this.setState({ houseNumberPopup: false })
            } else {
                this.setState({ houseNumberPopup: true })
            }
            if (this.state.city) {
                this.setState({ cityPopup: false })
            } else {
                this.setState({ cityPopup: true })
            }
            if (this.state.zipCode) {
                this.setState({ zipCodePopup: false })
            } else {
                this.setState({ zipCodePopup: true })
            }
            if (this.state.firstName) {
                this.setState({ firstNamePopup: false })
            } else {
                this.setState({ firstNamePopup: true })
            }
            if (this.state.lastName) {
                this.setState({ lastNamePopup: false })
            } else {
                this.setState({ lastNamePopup: true })
            }
            return false;
        } else if (!this.state.firstName || !this.state.lastName) {
            if (this.state.firstName) {
                this.setState({ firstNamePopup: false })
            } else {
                this.setState({ firstNamePopup: true })
            }
            if (this.state.lastName) {
                this.setState({ lastNamePopup: false })
            } else {
                this.setState({ lastNamePopup: true })
            }
            return false;
        }

        this.setState({ aanmeldenloader: true });

        let a = this.state.currentDataOfSignedInUser;
        if (a) {
            if (a.stripe_id) {
                if (!this.state.isUserForm) {
                    firebase.database().ref(`users/${a.user_id}/company_name`).set(this.state.companyName)
                }
                if (this.state.latitude && this.state.longitude) {
                    firebase.database().ref(`users/${a.user_id}/longitude`).set(this.state.longitude)
                    firebase.database().ref(`users/${a.user_id}/latitude`).set(this.state.latitude)
                }
                let updates = {}
                if (this.state.productsCheckingOut) {
                    updates = {
                        [`users/${a.user_id}/street`]: this.state.street,
                        [`users/${a.user_id}/city`]: this.state.city,
                        [`users/${a.user_id}/zipCode`]: this.state.zipCode,
                        [`users/${a.user_id}/houseNumber`]: this.state.houseNumber,
                        [`users/${a.user_id}/first_name`]: this.state.firstName,
                        [`users/${a.user_id}/last_name`]: this.state.lastName,
                        [`users/${a.user_id}/phoneNumber`]: this.state.phoneNumber,
                    };
                } else {
                    updates = {
                        [`users/${a.user_id}/first_name`]: this.state.firstName,
                        [`users/${a.user_id}/last_name`]: this.state.lastName,
                        [`users/${a.user_id}/phoneNumber`]: this.state.phoneNumber,
                    };
                }

                firebase.database().ref().update(updates).then(() => {
                    window.location.href = `/signup-announcement/stepTwo?companyId=${this.state.companyIdProducts}`
                    // window.location.href = "/signup-announcement/stepTwo"
                })
            } else {
                axios
                    .post(
                        "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCustomer",
                        {
                            fullName: a.first_name + "" + a.last_name,
                            email: a.email,
                        }
                    )
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            if (!this.state.isUserForm) {
                                firebase.database().ref(`users/${a.user_id}/company_name`).set(this.state.companyName)
                            }
                            const updates = {
                                [`users/${a.user_id}/first_name`]: this.state.firstName,
                                [`users/${a.user_id}/last_name`]: this.state.lastName,
                                [`users/${a.user_id}/phoneNumber`]: this.state.phoneNumber,
                                [`users/${a.user_id}/stripe_id`]: response.data.data.id,
                            };

                            firebase.database().ref().update(updates).then(() => {
                                window.location.href = `/signup-announcement/stepTwo?companyId=${this.state.companyIdProducts}`
                                // window.location.href = "/signup-announcement/stepTwo"
                            })
                        }
                    })
            }
        }
    }

    sendResetLink() {
        const { loginEmail } = this.state;
        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (loginEmail === "" || !regexEmail.test(loginEmail)) {
            this.setState({ loginEmailPopup: true, loginEmail: "" });
        } else {
            this.setState({ loginEmailPopup: false });
        }
        if (loginEmail !== "" && regexEmail.test(loginEmail)) {
            this.setState({ aanmeldenloaderForgetPassword: true });

            var data = JSON.stringify({
                sendToEmail: loginEmail,
            });

            var config = {
                method: "post",
                url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendPasswordResetLinkUpdated",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then((response) => {
                    if (response.data.error) {
                        let errorCode = response.data.error.code;
                        if (
                            errorCode === "auth/email-not-found"
                        ) {
                            this.setState({ emailNotFound: true, aanmeldenloaderForgetPassword: false });
                        }
                    }
                    if (response.data.statusCode === 201) {
                        swal({
                            title: "Gelukt!",
                            text: "Binnen enkele minuten krijg je een e-mail met een link. Door op de link te klikken kun je een nieuw wachtwoord aanmaken.",
                            icon: "success",
                        }).then(() => {
                            this.setState({ aanmeldenloaderForgetPassword: false, showForgetPasswordPanel: false });
                        });
                    }
                })
                .catch((response) => {
                    var errorCode = response.error.code;
                    if (
                        errorCode === "auth/email-not-found"
                    ) {
                        this.setState({ emailNotFound: true, aanmeldenloaderForgetPassword: false });
                    }
                });
        }
    }


    fillFieldsWithContext(context) {
        if (context) {
            // this.setState({ loadingAddress: true })
            axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/getAddressByContext", {
                context: context,
                sessionId: this.state.sessionIdForAutocompleteAPI,
            }, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    if (response.status === 200 && response.data.statusCode === 200) {
                        const { street, locality, buildingNumber, postcode, latitude, longitude } = response.data.data.address
                        this.setState({ street, city: locality, zipCode: postcode, houseNumber: buildingNumber, isAddressLoaded: true, avoidReadOnly: false, latitude, longitude })
                    } else {
                        this.setState({ loadingAddress: false, avoidReadOnly: true, street: "", city: "", zipCode: "", houseNumber: "", isAddressLoaded: true, latitude: "", longitude: "" })
                    }
                })
                .catch((error) => {
                    if (error.message === "Search canceled due to a new request") {
                        return false;
                    }
                    this.setState({ loadingAddress: false, street: "", avoidReadOnly: true, city: "", zipCode: "", houseNumber: "", isAddressLoaded: true, latitude: "", longitude: "" })
                });
        }
    }

    checkForAutoFillSuggestions(address) {
        if (address) {
            // this.setState({ loadingAddress: true })
            let addressSpacesRemoved = address.replace(/\s/g, '%20');

            if (cancelPreviousRequest) {
                cancelPreviousRequest("Search canceled due to a new request")
            }

            const source = CancelToken.source()

            cancelPreviousRequest = source.cancel

            axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/getAddressSuggestions", {
                searchTerm: addressSpacesRemoved,
                sessionId: this.state.sessionIdForAutocompleteAPI,
            }, {
                headers: {
                    "Content-Type": "application/json"
                },
                cancelToken: source.token,
            })
                .then((response) => {
                    if (response.status === 200 && response.data.statusCode === 200) {
                        const { matches } = response.data.data
                        this.setState({ loadingAddress: false, addressMatches: matches })
                    } else {
                        this.setState({ loadingAddress: false, addressMatches: [], avoidReadOnly: true, street: "", city: "", isAddressLoaded: true })
                    }
                })
                .catch((error) => {
                    if (error.message === "Search canceled due to a new request") {
                        return false;
                    }
                    this.setState({ loadingAddress: false, addressMatches: [], street: "", avoidReadOnly: true, city: "", isAddressLoaded: true })
                });
        }
    }

    handlSearchAutoFill(address) {
        this.checkForAutoFillSuggestions(address)
    }

    createMarkup(e) {
        if (e) {
            return { __html: e };
        }
    }

    render() {
        return (
            <div className="global-event-cart globaleventcartfixedstickyfooter">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                {this.state.userSignedIn ? (<div className="left-gec">
                    {window.innerWidth > 1008 && (<div className="button-back-lgec" onClick={() => history.goBack()}>
                        <ChevronLeftIcon />
                    </div>)}
                    <div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text_first_signed_in : this.state.checkout_step_one_new_text_first_signed_in_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text_first_signed_in_english : this.state.checkout_step_one_new_text_first_signed_in_english_mobile))}>
                                {/* Aanmelden */}   
                            </label>
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_one_new_second_text_signed_in : this.state.checkout_step_one_new_second_text_signed_in_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_one_new_second_text_signed_in_english : this.state.checkout_step_one_new_second_text_signed_in_english_mobile))}>
                                {/* Account gegevens */}
                            </label>
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text_signed_in : this.state.checkout_step_one_new_text_signed_in_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text_signed_in_english : this.state.checkout_step_one_new_text_signed_in_english_mobile))}>
                                {/* Maak eenvoudig je Bhodi account aan. */}
                            </label>
                            {/* <label>Indien gewenst kun je het menu later nog wijzigen with the dezctiptrion and a bit to show here</label> */}
                        </div>
                        <div className="forms-clgec">
                            <div className="form-field-row">
                                <div
                                    className={`form-field-clgec ${this.state.firstNamePopup ? "redalertffcl" : ""
                                        }`}
                                >
                                    <input
                                        placeholder="Voornaam"
                                        value={this.state.firstName}
                                        onChange={(e) =>
                                            this.setState({ firstName: e.target.value })
                                        }
                                    />
                                </div>
                                <div
                                    className={`form-field-clgec ${this.state.lastNamePopup ? "redalertffcl" : ""
                                        }`}
                                >
                                    <input
                                        placeholder="Achternaam"
                                        value={this.state.lastName}
                                        onChange={(e) =>
                                            this.setState({ lastName: e.target.value })
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className={`form-field-clgec ${this.state.phoneNumberPopup ? "redalertffcl" : ""
                                    }`}
                            >
                                <input
                                    type="text"
                                    placeholder="Telefoon"
                                    value={this.state.phoneNumber}
                                    onKeyDown={(e) => {
                                        if (invalidChars.includes(e.key.toLowerCase())) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        this.setState({ phoneNumber: e.target.value });
                                    }}
                                />
                            </div>
                            {(!this.state.isUserForm) && (
                                <div
                                    className={`form-field-clgec ${this.state.companyNamePopup ? "redalertffcl" : ""
                                        }`}
                                >
                                    <input
                                        type="text"
                                        placeholder="Bedrijfsnaam"
                                        value={this.state.companyName}
                                        onChange={(e) =>
                                            this.setState({ companyName: e.target.value })
                                        }
                                    />
                                </div>
                            )}
                            {this.state.productsCheckingOut && <>
                                <div
                                    className={`form-field-clgec formfielddropdown-address`}
                                >
                                    <input
                                        type="text"
                                        placeholder="Postcode en huisnummer"
                                        // placeholder="Plaats, straat of postcode"
                                        value={this.state.address}
                                        onChange={(e) => {
                                            this.setState({ address: e.target.value })

                                            this.handlSearchAutoFill(e.target.value)
                                        }}
                                        onFocus={() => {
                                            this.setState({ showingDropdown: true })
                                        }}
                                    />
                                    {this.state.showingDropdown && (<div className="dropdown-box-new">
                                        {this.state.addressMatches.length > 0 && this.state.addressMatches.map((item) => {
                                            return <li className="list-dbn" onClick={() => {
                                                this.fillFieldsWithContext(item.context)

                                                this.setState({ address: item.value, showingDropdown: false })
                                            }}>
                                                {item.label.split('').map((char, i) => (
                                                    <span style={{ fontWeight: i >= item.highlights[0] && i <= item.highlights[1] ? 'bold' : 'normal' }} key={i}>
                                                        {char}
                                                    </span>
                                                ))}
                                                {item.description && (<span className="desc-dbn">{item.description}</span>)}
                                            </li>
                                        })}
                                    </div>)}
                                </div>
                                {this.state.avoidReadOnly && (<div
                                    className={`form-field-clgec`}
                                >
                                    <label style={{ marginBottom: 0 }}>Address not founded</label>
                                </div>)}
                                {this.state.isAddressLoaded && (<div className="form-field-row">
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.zipCodePopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Postcode"
                                            value={this.state.zipCode}
                                            onChange={(e) => this.setState({ zipCode: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.zipCode) && (<BiCheck />)}
                                    </div>
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.houseNumberPopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Huisnummer"
                                            value={this.state.houseNumber}
                                            onChange={(e) => this.setState({ houseNumber: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.houseNumber) && (<BiCheck />)}
                                    </div>
                                </div>)}
                                {this.state.isAddressLoaded && (<div className="form-field-row">
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.streetPopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Straatnaam"
                                            value={this.state.street}
                                            onChange={(e) => this.setState({ street: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.street) && (<BiCheck />)}
                                    </div>
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.cityPopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Plaats"
                                            value={this.state.city}
                                            onChange={(e) => this.setState({ city: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.city) && (<BiCheck />)}
                                    </div>
                                </div>)}
                                {this.state.isAddressLoaded && (<div className="form-field-clgec formselectclgec">
                                    <select
                                        value={this.state.country}
                                        onChange={(e) => {
                                            this.setState({ country: e.target.value });
                                        }}
                                    >
                                        {countries.map((e) => {
                                            return <option value={e.id}>{e.name}</option>;
                                        })}
                                    </select>
                                    <KeyboardArrowDownIcon />
                                </div>)}
                            </>}
                            {window.innerWidth < 1008 && (<div className="steps-container-main">
                                <div className="scm-inner-steps active">

                                </div>
                                <div className="scm-inner-steps">

                                </div>
                                <label>1/2</label>
                            </div>)}
                            <div className="bottom-buttons-clgec">
                                {window.innerWidth < 1008 && !this.state.aanmeldenloader && (
                                    <button onClick={() => history.goBack()}>
                                        {" "}
                                        <ChevronLeftIcon />{" "}
                                    </button>
                                )}
                                {this.state.aanmeldenloader ? (
                                    // window.innerWidth < 1008 && (
                                    //     <button style={{ width: "100%" }}>
                                    //         <div className="lds-ellipsis">
                                    //             <div></div>
                                    //             <div></div>
                                    //             <div></div>
                                    //             <div></div>
                                    //         </div>
                                    //     </button>
                                    // )
                                    <></>
                                ) : (
                                    <button
                                        onClick={this.proceedSignedInUser.bind(this)}
                                    >
                                        Volgende{" "}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                {window.innerWidth > 1008 && (<div className="steps-container-main">
                                    <div className="scm-inner-steps active">

                                    </div>
                                    <div className="scm-inner-steps">

                                    </div>
                                    <label>1/2</label>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>) : (<div className="left-gec">
                    {window.innerWidth > 1008 && (<div className="button-back-lgec" onClick={() => history.goBack()}>
                        <ChevronLeftIcon />
                    </div>)}
                    <div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text_first : this.state.checkout_step_one_new_text_first_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text_first_english : this.state.checkout_step_one_new_text_first_english_mobile))}>
                                {/* Aanmelden */}
                            </label>
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_one_new_second_text : this.state.checkout_step_one_new_second_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_one_new_second_text_english : this.state.checkout_step_one_new_second_text_english_mobile))}>
                                {/* Account gegevens */}
                            </label>
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text : this.state.checkout_step_one_new_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_one_new_text_english : this.state.checkout_step_one_new_text_english_mobile))}>
                                {/* Maak eenvoudig je Bhodi account aan. */}
                            </label>
                        </div>
                        <div className="forms-clgec">
                            <div
                                className={`form-field-clgec ${this.state.emailPopup ? "redalertffcl" : ""
                                    }`}
                            >
                                <input
                                    type="email"
                                    placeholder="Email adres"
                                    autoComplete="off"
                                    value={this.state.email}
                                    readOnly={!this.state.creatingNewUser}
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                        // clearTimeout(this.typingTimer);
                                        // this.typingTimer = setTimeout(this.checkForEmail, 2000); // Adjust the timeout duration as needed
                                    }}
                                />
                                {this.state.emailTookedPopup && (
                                    <div className="alert-field">
                                        <span style={{ letterSpacing: 1 }}>
                                            Email already taken
                                        </span>
                                    </div>
                                )}
                                {this.state.emailPopup && (
                                    <div className="alert-field">
                                        <span style={{ letterSpacing: 1 }}>
                                            Email is invalid
                                        </span>
                                    </div>
                                )}
                            </div>
                            {this.state.emailLoaded && (<div
                                className={`form-field-clgec ${this.state.passwordPopup ? "redalertffcl" : ""
                                    }`}
                            >
                                <input
                                    type={this.state.passwordInputType}
                                    autoComplete="off"
                                    placeholder={this.state.creatingNewUser ? "Kies een wachtwoord" : "Voer je wachtwoord in"}
                                    value={this.state.password}
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value });
                                    }}
                                />
                                {this.state.passwordPopup && (
                                    <div className="alert-field">
                                        <span style={{ letterSpacing: 1 }}>
                                            Password is invalid
                                        </span>
                                    </div>
                                )}
                            </div>)}
                            {(this.state.emailLoaded && this.state.creatingNewUser) && (
                                <div className="form-field-clgec switchformclgec">
                                    {this.state.isUserForm ? (
                                        <label>Consument</label>
                                    ) : (
                                        <label>Zakelijk</label>
                                    )}
                                    <Switch
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                        checked={this.state.isUserForm}
                                        onClick={() => {
                                            this.setState({
                                                isUserForm: !this.state.isUserForm,
                                            });
                                        }}
                                    />
                                </div>
                            )}
                            {(this.state.emailLoaded && this.state.creatingNewUser) && (<div className="form-field-row">
                                <div
                                    className={`form-field-clgec ${this.state.firstNamePopup ? "redalertffcl" : ""
                                        }`}
                                >
                                    <input
                                        placeholder="Voornaam"
                                        value={this.state.firstName}
                                        onChange={(e) =>
                                            this.setState({ firstName: e.target.value })
                                        }
                                    />
                                </div>
                                <div
                                    className={`form-field-clgec ${this.state.lastNamePopup ? "redalertffcl" : ""
                                        }`}
                                >
                                    <input
                                        placeholder="Achternaam"
                                        value={this.state.lastName}
                                        onChange={(e) =>
                                            this.setState({ lastName: e.target.value })
                                        }
                                    />
                                </div>
                            </div>)}
                            {(this.state.emailLoaded && this.state.creatingNewUser) && (
                                <div
                                    className={`form-field-clgec ${this.state.phoneNumberPopup ? "redalertffcl" : ""
                                        }`}
                                >
                                    <input
                                        type="text"
                                        placeholder="Telefoon"
                                        value={this.state.phoneNumber}
                                        onKeyDown={(e) => {
                                            if (invalidChars.includes(e.key.toLowerCase())) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            this.setState({ phoneNumber: e.target.value });
                                        }}
                                    />
                                </div>
                            )}
                            {(this.state.emailLoaded && this.state.creatingNewUser && !this.state.isUserForm) && (
                                <div
                                    className={`form-field-clgec ${this.state.companyNamePopup ? "redalertffcl" : ""
                                        }`}
                                >
                                    <input
                                        type="text"
                                        placeholder="Bedrijfsnaam"
                                        value={this.state.companyName}
                                        onChange={(e) =>
                                            this.setState({ companyName: e.target.value })
                                        }
                                    />
                                </div>
                            )}
                            {this.state.productsCheckingOut && this.state.creatingNewUser && this.state.emailLoaded && (<>
                                <div
                                    className={`form-field-clgec formfielddropdown-address`}
                                >
                                    <input
                                        type="text"
                                        placeholder="Plaats, straat of postcode"
                                        value={this.state.address}
                                        onChange={(e) => {
                                            this.setState({ address: e.target.value })

                                            this.handlSearchAutoFill(e.target.value)
                                        }}
                                        onFocus={() => {
                                            this.setState({ showingDropdown: true })
                                        }}
                                    />
                                    {this.state.showingDropdown && (<div className="dropdown-box-new">
                                        {this.state.addressMatches.length > 0 && this.state.addressMatches.map((item) => {
                                            return <li className="list-dbn" onClick={() => {
                                                this.fillFieldsWithContext(item.context)

                                                this.setState({ address: item.value, showingDropdown: false })
                                            }}>
                                                {item.label.split('').map((char, i) => (
                                                    <span style={{ fontWeight: i >= item.highlights[0] && i <= item.highlights[1] ? 'bold' : 'normal' }} key={i}>
                                                        {char}
                                                    </span>
                                                ))}
                                                {item.description && (<span className="desc-dbn">{item.description}</span>)}
                                            </li>
                                        })}
                                    </div>)}
                                </div>
                                {this.state.avoidReadOnly && (<div
                                    className={`form-field-clgec`}
                                >
                                    <label style={{ marginBottom: 0 }}>Address not founded</label>
                                </div>)}
                                {this.state.isAddressLoaded && (<div className="form-field-row">
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.zipCodePopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Postcode"
                                            value={this.state.zipCode}
                                            onChange={(e) => this.setState({ zipCode: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.zipCode) && (<BiCheck />)}
                                    </div>
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.houseNumberPopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Huisnummer"
                                            value={this.state.houseNumber}
                                            onChange={(e) => this.setState({ houseNumber: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.houseNumber) && (<BiCheck />)}
                                    </div>
                                </div>)}
                                {this.state.isAddressLoaded && (<div className="form-field-row">
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.streetPopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Straatnaam"
                                            value={this.state.street}
                                            onChange={(e) => this.setState({ street: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.street) && (<BiCheck />)}
                                    </div>
                                    <div
                                        className={`form-field-clgec ffclgec-check ${this.state.cityPopup ? "redalertffcl" : ""
                                            }`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Plaats"
                                            value={this.state.city}
                                            onChange={(e) => this.setState({ city: e.target.value })}
                                            readOnly={!this.state.avoidReadOnly}
                                        />
                                        {(!this.state.avoidReadOnly && this.state.city) && (<BiCheck />)}
                                    </div>
                                </div>)}
                                {this.state.isAddressLoaded && (<div className="form-field-clgec formselectclgec">
                                    <select
                                        value={this.state.country}
                                        onChange={(e) => {
                                            this.setState({ country: e.target.value });
                                        }}
                                    >
                                        {countries.map((e) => {
                                            return <option value={e.id}>{e.name}</option>;
                                        })}
                                    </select>
                                    <KeyboardArrowDownIcon />
                                </div>)}
                            </>)}
                            {(this.state.emailLoaded && this.state.creatingNewUser) && (
                                <div
                                    className="fx-registration-inner"
                                    style={{ alignItems: "center" }}
                                >
                                    <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                        <div
                                            className="left-frvn"
                                            style={{
                                                width: "100%",
                                                paddingLeft: 10,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <div className="checking-box">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.agreed}
                                                    onChange={() =>
                                                        this.setState({ agreed: !this.state.agreed })
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        color: this.state.agreedPopup ? "red" : "black",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <font
                                                        style={{
                                                            textDecorationLine: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                "_blank" // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Akkoord met voorwaarden
                                                    </font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(this.state.emailLoaded && !this.state.creatingNewUser) && (<label className='forget-pwd-lpn' style={{ color: "gray" }} onClick={() => {
                                this.setState({ showForgetPasswordPanel: true })
                            }}>Wachtwoord vergeten?</label>)}

                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    <></>
                                ) : !this.state.emailLoaded ? (
                                    <button
                                        onClick={this.checkForEmail.bind(this)}
                                    >
                                        Volgende
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                ) : !this.state.creatingNewUser ? (
                                    <button
                                        onClick={this.loginUser.bind(this)}
                                    >
                                        Login
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                ) : (
                                    <button
                                        onClick={this.registerUser.bind(this)}
                                    >
                                        Maak account{" "}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                <div className="steps-container-main">
                                    <div className="scm-inner-steps active">

                                    </div>
                                    <div className="scm-inner-steps">

                                    </div>
                                    <label>1/2</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
                {this.state.popupopenforright && (
                    <div className="right-gec righgecwithimgsignup">

                    </div>
                )}

                {/* forget password popup */}

                <div className='login-overlay-main' style={{ opacity: this.state.showForgetPasswordPanel ? 1 : 0, pointerEvents: this.state.showForgetPasswordPanel ? "all" : "none", zIndex: 99999999999 }}>
                    <div className='login-container-lpn'>
                        <MdClose className='icon-person-top-lpn' onClick={() => {
                            this.setState({ showForgetPasswordPanel: false })
                        }} />
                        <h2>FORGET PASSWORD</h2>
                        {this.state.showForgetPasswordPanel && (<form style={{ width: "100%" }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <div className={`container-field-cglpn ${this.state.loginEmailPopup ? "containerRedAlerCglpn" : ""}`}>
                                <input type="email" autoComplete='new-password' placeholder='E-mail' value={this.state.loginEmail} onChange={(e) => {
                                    this.setState({ loginEmail: e.target.value })
                                }} />
                                {this.state.emailNotFound && (
                                    <div className="alert-field" style={{ width: "100%" }}>
                                        <span style={{ letterSpacing: 1 }}>
                                            Email not founded in our records
                                        </span>
                                    </div>
                                )}
                            </div>
                        </form>)}
                        <div className='container-button-cglpn'>
                            {this.state.aanmeldenloaderForgetPassword ? (
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (<button onClick={() => this.sendResetLink()} style={{ borderRadius: 6 }}>Send</button>)}
                        </div>
                    </div>
                </div>

                {/* forget password popup */}


                {/* <FooterBasket notOnMainPage={true} /> */}
                <FooterBasketTimer notOnMainPage={true} />
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        user: state.authReducers.user,
        global_campaigns: state.campaignsReducer.global_campaigns,
        signup_fields_info: state.globalReducers.signup_fields_info,
        admin_boolean: state.globalReducers.admin_boolean,
        // products_new: state.campaignsReducer.products_new,
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSignUpFieldsFilled: (signup_fields_info) =>
            dispatch(updateSignUpFieldsFilled(signup_fields_info)),
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpAnnouncement);
