import React, { Component } from 'react'
import { connect } from 'react-redux';
import { history } from '../../Config/Routes';
import firebase from '../../Config/Firebase'
import thnxVideo from "../../assets/thnx.mp4"
import logo from "../../assets/logoheaderlifestyle.png"
import playiconImage from "../../assets/playiconlandingpage.png"
import "./index.css"
import { QRCodeCanvas } from 'qrcode.react';
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';

export class GenerateQRPage extends Component {

    constructor() {
        super()
        this.state = {
            currentUser: "",
            qrPopupOpen: false,
            qrCodeLink: "",
            currentQrQuery: "",
        }
    }

    componentDidMount() {
        if (!this.props.currentMemberData.barber_new) {
            history.goBack()
            return false;
        }
        if (this.props.currentMemberData) {
            this.setState({ currentUser: this.props.currentMemberData })
            firebase.database().ref("users/" + this.props.currentMemberData.user_id).on("value", (data) => {
                let a = data.val()
                if (a) {
                    this.setState({ currentUser: a })
                }
            })
        }
    }

    // componentWillUnmount() {
    //     if (this.state.currentQrQuery) {
    //         firebase.database().ref(this.state.currentQrQuery).off()
    //     }
    // }

    // generateQRCode() {
    //     let inputString = this.state.currentUser.company_name

    //     const initials = inputString
    //         .split(' ')
    //         .filter(word => word.length > 0)  // Filter out any empty words (due to extra spaces)
    //         .map(word => word[0])  // Map each word to its first letter
    //         .join('');  // Join the array of first letters into a single string

    //     // Get the current time
    //     const now = new Date();
    //     const hours = String(now.getHours()).padStart(2, '0');  // Get hours and pad with leading zero if necessary
    //     const minutes = String(now.getMinutes()).padStart(2, '0');  // Get minutes and pad with leading zero if necessary

    //     // Create the time string in the format HHMM
    //     const timeString = hours + minutes;

    //     let number = 1001
    //     if (this.state.currentUser.qr_code_number) {
    //         number = this.state.currentUser.qr_code_number
    //     }

    //     firebase.database().ref(`users/${this.state.currentUser.user_id}/qr_code_number`).set(number + 1)

    //     // Append the number to the resulting string
    //     let finalString = initials + timeString + number;

    //     let query = `qr_codes/${this.state.currentUser.user_id}/${finalString}`


    //     let obj = {
    //         code: finalString,
    //         is_scanned: false,
    //         created_at: new Date().toISOString(),
    //         barber_id: this.state.currentUser.user_id
    //     }

    //     firebase.database().ref(`${query}`).set(obj)

    //     firebase.database().ref(query).on("value", (data) => {
    //         let a = data.val()
    //         if (a && a.is_scanned) {
    //             this.setState({ qrPopupOpen: false, qrCodeLink: "", currentQrQuery: "" })
    //             firebase.database().ref(query).off()
    //         }
    //     })

    //     this.setState({ qrPopupOpen: true, qrCodeLink: `${window.location.origin}/onboarding-barber-client?id=${finalString}&barber_id=${this.state.currentUser.user_name_id}`, currentQrQuery: query })
    // }

    render() {
        return (
            <div className='qr-code-page'>
                <div className='left-qcp'>
                    <div className='content-lqcp'>
                        <img src={logo} alt='logo' />
                        <img src={playiconImage} alt='play-icon' className='play-icon' />
                        <p className='desc-clqcp'>Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text</p>
                        {this.state.currentUser ? (<div className='qr-lqcp'>
                            <QRCodeCanvas value={`https://us-central1-bhodi-7c327.cloudfunctions.net/generateStaticLink?companyId=${this.state.currentUser.user_name_id}`} size={250} />
                        </div>) : (<></>)}
                        {/* <button className='btn-clqcp' onClick={() => {
                            if (this.state.currentQrQuery) {
                                firebase.database().ref(this.state.currentQrQuery).off()
                                this.setState({ qrPopupOpen: false })
                                setTimeout(() => {
                                    this.generateQRCode()
                                }, 1000);
                                return false;
                            }
                            this.generateQRCode()
                        }}>Generate QR</button> */}
                    </div>
                </div>
                <div className='right-qcp'>
                    <video
                        src={thnxVideo}
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="videocomplete"
                    ></video>
                </div>

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

export default connect(
    mapStateToProps
)(GenerateQRPage);