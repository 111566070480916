import React, { Component } from 'react'
import styles from "./index.module.css"
import Footer from '../../Components/Footer'
import firebase from '../../Config/Firebase'
import { history } from '../../Config/Routes'
import axios from "axios";

export class ExclusiveSubscribeProcess extends Component {

    constructor() {
        super()
        this.state = {
            loader: true,
            paymentStatus: "",
            currentCompanyAt: "",
        }
    }

    componentDidMount() {
        let currentUserId = localStorage.getItem("userid")
        let urlMain = new URL(window.location.href)
        let subscribingToUser = urlMain.searchParams.get("subscring");
        if (subscribingToUser) {
            firebase.database().ref(`users/${subscribingToUser}`).on("value", (data) => {
                let a = data.val()
                if (a && a.subscribersForExclusives) {
                    let filtered = Object.entries(a.subscribersForExclusives).reverse().filter((t) => {
                        return t[1].userId === currentUserId
                    })
                    if (filtered.length > 0) {
                        this.setState({ paymentStatus: filtered[0][1].payment_status })
                    } else {
                        this.setState({ paymentStatus: "Failed" })
                    }
                }
                this.setState({ loader: false, currentCompanyAt: a })
            })
        }
        firebase
            .database()
            .ref("users/" + currentUserId)
            .once("value", (data) => {
                let a = data.val();
                if (a) {
                    // if (a.user_name_id === this.props.member_id) {
                    //     this.props.updateSignUpFieldsFilled("")
                    // }
                    if (a.create_subscription) {
                        this.setState({ create_subscription: a.create_subscription });

                        // let description = `Subscription no ${a.user_id_numbers} ${a.first_name} ${a.last_name}`

                        if (a.payment_status === "paid") {
                            setTimeout(() => {
                                firebase
                                    .database()
                                    .ref("users/" + currentUserId + "/create_subscription")
                                    .set(false)
                            }, 1000);
                            // axios
                            //     .post(
                            //         "https://us-central1-bhodi-7c327.cloudfunctions.net/createSubscription",
                            //         {
                            //             customerId: a.stripe_id,
                            //             amount: a.plan_amount,
                            //             startDate: date,
                            //             description,
                            //         }
                            //     )
                            //     .then((response) => {
                            //         firebase
                            //             .database()
                            //             .ref("users/" + currentUserId + "/create_subscription")
                            //             .set(false)
                            //         firebase
                            //             .database()
                            //             .ref("users/" + currentUserId + "/subscription")
                            //             .set(response.data.subscription)
                            //         firebase
                            //             .database()
                            //             .ref("users/" + currentUserId + "/subscription_id")
                            //             .set(response.data.subscription.id)
                            //             .then(() => {
                            //                 firebase
                            //                     .database()
                            //                     .ref("users/" + currentUserId + "/is_canceled")
                            //                     .remove()
                            //             })
                            //     })
                        }
                    }
                    // else if (a.create_subscription_split) {
                    //     let objectPayment = localStorage.getItem("doingPaymentOf")
                    //     if (objectPayment) {
                    //         let parsedObj = JSON.parse(objectPayment)

                    //         if (a.payment_status === "paid") {
                    //             axios.post(
                    //                 "https://us-central1-bhodi-7c327.cloudfunctions.net/createSplitSubscription",
                    //                 {
                    //                     uid: currentUserId,
                    //                     customerId: parsedObj.customerId,
                    //                     amount: parsedObj.amount,
                    //                     routeAmount: parsedObj.routeAmount,
                    //                     orgId: parsedObj.orgId,
                    //                     redirectUrl: "https://www.bhodi.com"
                    //                 }
                    //             )
                    //                 .then(() => {
                    //                     let objSubscription = {
                    //                         status: "active",
                    //                     }
                    //                     firebase
                    //                         .database()
                    //                         .ref("users/" + currentUserId + "/subscription")
                    //                         .set(objSubscription)

                    //                 })
                    //                 .catch((error) => {
                    //                     console.log(error);
                    //                 });
                    //         }
                    //         localStorage.removeItem("doingPaymentOf")
                    //     }
                    // }
                }
            })
    }

    render() {
        return (
            <div>
                {this.state.paymentStatus ? (<div className='no-products-container loadingContainerProducts' style={{ flexDirection: "column" }}>
                    {this.state.paymentStatus === "paid" && (<p style={{ marginTop: 15, color: "black" }}>CONGRATULATIONS!</p>)}
                    <p style={{ color: "black", fontFamily: "Poppins" }}>Payment is {this.state.paymentStatus}</p>
                    {this.state.paymentStatus === "paid" && (<button style={{ backgroundColor: "#285151", marginTop: 15, padding: "8px 12px", border: "none", outline: "none", fontSize: 13, letterSpacing: 1, color: "white" }} onClick={() => {
                        history.push(`/company/${this.state.currentCompanyAt.user_name_id}/products`)
                    }}>Go to products</button>)}
                </div>) : this.state.loader && (<div className='no-products-container loadingContainerProducts' style={{ flexDirection: "column" }}>
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p style={{ marginTop: 15, color: "black" }}>PROCESSING PAYMENT</p>

                </div>)}
                <Footer />
            </div>
        )
    }
}

export default ExclusiveSubscribeProcess