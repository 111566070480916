import React, { Component } from "react";
import SearchIcon from "@material-ui/icons/Search";
import firebase from "../Config/Firebase";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RemoveIcon from "@material-ui/icons/Remove";
import { connect } from "react-redux";
import {
  updateMoreAlbumsAdded,
  updateSelectedExclusive,
  updateSignUpFieldsFilled,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateBhodiFaqs,
  updateBhodiAbouts,
} from "../Redux/actions/authActions";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { FaQ } from "react-icons/fa6";
import { ChevronLeft } from "@material-ui/icons";
import { RiGitRepositoryPrivateLine, RiUserSettingsLine } from "react-icons/ri";
import RightMenuOrders from "./RightMenuOrders";
import orderspicpng from "../assets/ordericontopnavbars.png";
import menupicpng from "../assets/menuicontopnavbar.png";
import CartNonUser from "./CartNonUser";
import { Link } from "react-router-dom";
import { history } from "../Config/Routes";
import { MdOutlinePrivacyTip } from "react-icons/md";
import MySubcribedExclusives from "./MySubcribedExclusives";
import { TbFolderStar } from "react-icons/tb"
import { TiInfo } from "react-icons/ti";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import NavbarMenuMobileFooterStuff from "./NavbarMenuMobileFooterStuff";
import FilterIconGlobalNav from "./FilterIconGlobalNav";
import NavGlobalVoucherEventsCity from "./NavGlobalVoucherEventsCity";
import searchpicpng from "../assets/searchiconglobalevent.png";
import FilterIconGlobalNavVoucher from "./FilterIconGlobalNavVoucher";
import NavGlobalVoucherCity from "./NavGlobalVoucherCity";
import hwb from "../assets/heartwhiteborder.png";
import hbb from "../assets/heartblackborder.png";
import { followUnfollowCompany } from "../Functions/friendFunctions";
import { mergeAdminEvent } from "../Functions/globalFunctions";

const axios = require("axios");

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class NavbarMainFullWidth extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      search: false,
      searchText: "",
      rightModalSettings: false,
      arrForSearch: [],
      checkedPrivate: false,
      checkedHideDonation: false,
      checkedHideFriendsInfo: false,
      checkedHideProfileImage: false,
      checkedHideProfileInfo: false,
      showPostsActive: true,
      showItemsDiv: false,
      showItems: false,
      showLists: true,
      showFriends: false,
      showProductsOwn: false,
      showFriendstab: false,
      showProductsBookmarked: false,
      showBookmarkTab: false,
      showProductsOthertab: false,
      userNameInput: false,
      userDescInput: false,
      lengthOfOtherProducts: [],
      lengthOfOtherVouchers: [],
      lengthOfOtherCampaigns: [],
      userInfoRight: false,
      typeOfOrder: "incoming",
      showCartOrder: true,
      savtBtnText: "SAVE",
      deleteBtnText: "SAVE",
      isDesktop: true,
      isScrolled: false,
      menuItemsModals: true,
      hideMenuContent: false,
      lengthOfCurrentContents: [],
      lengthOfOtherContents: [],
      showContentTab: false,
      currentUserMember: "",
      bhodiFaqs: [],
      arrDropdowns: [],
      bhodiAbouts: [],
      arrDropdownsAbouts: [],
      currentUserOfPopups: "",
      redeem_code: "",
      guestUserCart: [],
      profileActive: true,
      showDelete: false,
      password: "",
      showPassword: false,
      confirmPassword: "",
      showconfirmPassword: false,
      currentPassword: "",
      showcurrentPassword: false,
      copiedTextDone: false,
      commentsHere: [],
      commentsReplies: [],
      currentProductDetailed: "",
      allAlbumsCurrentShared: [],
      commentInformationToShow: "",
      trendingActive: true,
      notSeenMessages: [],
      openedFromPropsAbout: false,
      isCanceled: false,
      charityCount: 0,
      maxLimitAlleReacties: 15,
      passwordForDel: "",
      // trendingActive: true
      allCategories: [],
      availableMainCategories: [],
    };
  }

  // componentWillMount() {
  //     let userid = localStorage.getItem("userid")
  //     let currentData = localStorage.getItem("userdata")
  //     if (currentData) {
  //         let a = JSON.parse(currentData)
  //         if (a.friends) {
  //             let objected = Object.entries(a.friends)
  //             a.objectedFriends = objected
  //         } else {
  //             a.objectedFriends = []
  //         }
  //         if (!a.profile_image) {
  //             a.profile_image = "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
  //         }
  //         this.setState({
  //             currentUserMember: a,
  //             create_subscription: a.create_subscription,
  //             userid: userid,
  //             url: a.user_name_id,
  //             firstName: a.first_name,
  //             lastName: a.last_name,
  //             city: a.city,
  //             zipCode: a.zipCode,
  //             street: a.street,
  //             houseNumber: a.houseNumber,
  //             country: a.country,
  //             phoneNumber: a.phoneNumber,
  //             email: a.email,
  //             profileImageUrl: a.profile_image,
  //             description: a.user_description,
  //         })
  //         if (typeof (a.hideDonation) === "boolean") {
  //             this.setState({
  //                 checkedHideDonation: a.hideDonation,
  //                 checkedHideFriendsInfo: a.hideFriendsInfo,
  //                 checkedHideProfileImage: a.hideProfileImage,
  //                 checkedHideProfileInfo: a.hideProfileInfo,
  //             })
  //         }
  //         if (a.friends || a.following || a.followed_by || a.comingrequests) {
  //             this.setState({ showFriendstab: true })
  //         } else {
  //             this.setState({ showFriendstab: false })
  //         }
  //         if (a.friends || a.following) {
  //             this.setState({ showProductsOthertab: true })
  //         } else {
  //             this.setState({ showProductsOthertab: false })
  //         }
  //         if (a.bookmarked_things) {
  //             this.setState({ showBookmarkTab: true })
  //         } else {
  //             this.setState({ showBookmarkTab: false })
  //         }
  //     }
  // }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";


    let userid = localStorage.getItem("userid");
    let uid = localStorage.getItem("userid");
    this.setState({ currentUserId: userid });

    firebase
      .database()
      .ref("charity_videos/" + userid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let objected = Object.entries(a);
          let filtered = objected.filter((e) => {
            return !e[1].seen
          })
          this.setState({ charityCount: filtered.length });
        }
      });

    if (this.props.bhodi_faqs) {
      let a = this.props.bhodi_faqs;
      if (a) {
        let arrSec = a;
        let arrDropdowns = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdowns.push(obj);
        });
        this.setState({ bhodiFaqs: arrSec, arrDropdowns });
      } else {
        this.setState({ bhodiFaqs: [], arrDropdowns: [] });
      }
    }
    if (this.props.bhodi_abouts) {
      let a = this.props.bhodi_abouts;
      if (a) {
        let arrSec = a;
        let arrDropdownsAbouts = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdownsAbouts.push(obj);
        });
        this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
      } else {
        this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
      }
    }

    firebase
      .database()
      .ref("bhodi_faqs")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdowns = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdowns.push(obj);
          });
          this.setState({ bhodiFaqs: arrSec, arrDropdowns });
        } else {
          this.setState({ bhodiFaqs: [], arrDropdowns: [] });
        }
        this.props.updateBhodiFaqs(arrSec)
      });

    firebase
      .database()
      .ref("bhodi_abouts_user")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdownsAbouts = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdownsAbouts.push(obj);
          });
          this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
        } else {
          this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
        }
        this.props.updateBhodiAbouts(arrSec)
      });

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filterToSeeOwn = this.props.user.filter((u) => {
          return u.user_id === userid;
        });
        if (filterToSeeOwn.length > 0) {
          this.setState({
            isCanceled: !(
              filterToSeeOwn[0].subscription &&
              filterToSeeOwn[0].subscription.status === "active"
            ),
          });
        }

        let filter = this.props.user.filter((u) => {
          return u.user_name_id === this.props.member_id;
        });
        let currentData = filter[0];
        if (this.props.album) {
          if (this.props.album.length > 0) {
            let arrAlbums = [];
            let completeAlbums = [];
            this.props.album.map((e) => {
              e.fullName = e.name;
              let currentUserFiltered = this.props.user.filter((e) => {
                return e.user_id === userid;
              });
              completeAlbums.push(e);
              console.log(e.name, "/e.name");
              console.log(e, "/e");
              if (e.name.length > 8) {
                e.name = e.name;
              } else {
                if (e.name.length > 8) {
                  e.name = `${e.name.substr(0, 8)}...`;
                } else {
                  e.name = e.name;
                }
              }
              if (e.owner === currentData.user_id) {
                if (e.albumShareType === "wholebhodi") {
                  if (e.mediaPresent && e.active) {
                    arrAlbums.push(e);
                  }
                } else if (e.albumShareType === "charitymembers") {
                  if (e.mediaPresent && e.active && currentUserFiltered[0].subscription && currentUserFiltered[0].subscription.stauts === "active") {
                    if (currentUserFiltered.length > 0) {
                      let filteredFirst = []
                      let filteredSecond = []
                      if (currentUserFiltered[0].friends) {
                        let objected = Object.entries(
                          currentUserFiltered[0].friends
                        );
                        filteredFirst = objected.filter((y) => {
                          return y[1].user_id === e.owner;
                        });
                      }
                      if (currentUserFiltered[0].following) {
                        let objected = Object.entries(
                          currentUserFiltered[0].following
                        );
                        filteredSecond = objected.filter((y) => {
                          return y[1].user_id === e.owner;
                        });
                      }
                      if (filteredFirst.length > 0 || filteredSecond.length > 0) {
                        arrAlbums.push(e);
                      }
                    }
                  }
                } else if (e.albumShareType === "allfriends") {
                  if (e.mediaPresent && e.active) {
                    if (currentUserFiltered.length > 0) {
                      if (currentUserFiltered[0].friends) {
                        let objected = Object.entries(
                          currentUserFiltered[0].friends
                        );
                        let filtered = objected.filter((y) => {
                          return y[1].user_id === e.owner;
                        });
                        if (filtered.length > 0) {
                          arrAlbums.push(e);
                        }
                      }
                    }
                  }
                } else if (e.albumShareType === "onlyfollowers") {
                  if (e.mediaPresent && e.active) {
                    if (currentUserFiltered.length > 0) {
                      if (currentUserFiltered[0].following) {
                        let objected = Object.entries(
                          currentUserFiltered[0].following
                        );
                        let filtered = objected.filter((y) => {
                          return y[1].user_id === e.owner;
                        });
                        if (filtered.length > 0) {
                          arrAlbums.push(e);
                        }
                      }
                    }
                  }
                } else {
                  if (e.mediaPresent && e.active && e.initUsers) {
                    let objected = Object.entries(e.initUsers);
                    let filtered = objected.filter((e) => {
                      return e[1].user_id === userid;
                    });
                    if (filtered.length > 0) {
                      arrAlbums.push(e);
                    }
                  }
                }
              }
            });
            this.setState({ allAlbumsCurrentShared: arrAlbums });
          }
        }
        if (filter.length > 0) {
          if (currentData) {
            let a = currentData;
            if (a.friends) {
              let objected = Object.entries(a.friends);
              a.objectedFriends = objected;
            } else {
              a.objectedFriends = [];
            }
            if (!a.profile_image) {
              a.profile_image =
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
            this.setState({
              currentUserMember: a,
              create_subscription: a.create_subscription,
              userid: userid,
              url: a.user_name_id,
              firstName: a.first_name,
              lastName: a.last_name,
              city: a.city,
              zipCode: a.zipCode,
              street: a.street,
              houseNumber: a.houseNumber,
              country: a.country,
              phoneNumber: a.phoneNumber,
              email: a.email,
              profileImageUrl: a.profile_image,
              description: a.user_description,
            });
            if (a.profileActive) {
              this.setState({ profileActive: true })
            } else {
              this.setState({ profileActive: false })
            }
            if (typeof a.hideDonation === "boolean") {
              this.setState({
                checkedHideDonation: a.hideDonation,
                checkedHideFriendsInfo: a.hideFriendsInfo,
                checkedHideProfileImage: a.hideProfileImage,
                checkedHideProfileInfo: a.hideProfileInfo,
              });
            }
            if (a.friends) {
              this.setState({ showFriendstab: true });
            } else {
              this.setState({ showFriendstab: false });
            }
            if (a.friends || a.following) {
              this.setState({ showProductsOthertab: true });
            } else {
              this.setState({ showProductsOthertab: false });
            }
            if (a.bookmarked_things) {
              this.setState({ showBookmarkTab: true });
            } else {
              this.setState({ showBookmarkTab: false });
            }
            if (a.subscription) {
              if (a.subscription.status === "active") {
                this.setState({ showDelete: false });
              } else {
                this.setState({ showDelete: true });
              }
            } else {
              if (checkForSubscribedExclusives(a)) {
                this.setState({ showDelete: false })
              } else {
                this.setState({ showDelete: true });
              }
            }
          }
        }
      }
    }

    let route = window.location.pathname;
    let sessionStored = localStorage.getItem("onCurrentTab");

    if (!this.props.onGlobalPageEvents && !this.props.onGlobalPageVoucher) {
      if (route.includes("vriendenDesktop")) {
        this.setState({
          showVriendenWizard: true,
          showLists: false,
        });
      } else if (sessionStored) {
        if (sessionStored === "products") {
          this.setState({
            showLists: false,
            showItems: false,
            showFriends: false,
            showProductsOwn: false,
            showProductsBookmarked: false,
            showCharity: false,
            shopProductsShop: true,
            showContent: false,
          });
        } else if (sessionStored === "friend") {
          this.setState({
            showLists: false,
            showItems: false,
            showFriends: true,
            showProductsOwn: false,
            showProductsBookmarked: false,
            showCharity: false,
            shopProductsShop: false,
            showContent: false,
          });
        } else if (sessionStored === "charitypage") {
          this.setState({
            showLists: false,
            showItems: false,
            showFriends: false,
            showProductsOwn: false,
            showProductsBookmarked: false,
            showCharity: true,
            shopProductsShop: false,
            showContent: false,
          });
        } else if (sessionStored === "charityConfirmation") {
          this.setState({
            showLists: false,
            showItems: false,
            showFriends: false,
            showProductsOwn: false,
            showProductsBookmarked: false,
            showCharity: false,
            shopProductsShop: false,
            showContent: false,
          });
        }
      }
    }


    if (window.innerWidth > 1009) {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: true,
      });
    } else {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: false,
      });
      window.onscroll = () => {
        if (window.scrollY === 0) {
          this.setState({ isScrolled: false });
        } else {
          this.setState({ isScrolled: true });
        }
      };
    }

    // let promiseFirst = new Promise((resolve, reject) => {})
    // firebase
    //   .database()
    //   .ref("all_events")
    //   .on("value", (data) => {
    //     let a = data.val();
    //     if (a) {
    //       if (this.state.beforeUnloaded) {
    //         console.log("before unloaded");
    //       } else {
    //         let arr = [];
    //         let allProductsCompleted = [];
    //         let allProductsCompletedToRender = [];
    //         let arrCurrentProducts = [];
    //         let objected = Object.entries(a);
    //         objected.map((e) => {
    //           let arrImages = [];
    //           if (e[1].images) {
    //             let objectedImg = Object.entries(e[1].images);
    //             objectedImg.map((f) => {
    //               arrImages.push(f[1].image);
    //             });
    //             e[1].images = arrImages;
    //             e[1].imagesObjected = objectedImg;
    //             e[1].currentImageIndex = 0;
    //             if (e[1].activated && e[1].qty !== 0) {
    //               allProductsCompletedToRender.push(e[1]);
    //               if (e[1].desc) {
    //                 e[1].desc = e[1].desc.substr(0, 200);
    //               }
    //               if (e[1].created_by !== userid) {
    //                 let filtered = this.props.user.filter((f) => {
    //                   return f.user_id === e[1].created_by;
    //                 });
    //                 allProductsCompleted.push(e[1]);
    //                 if (filtered.length > 0) {
    //                   let f = filtered[0];
    //                   if (filtered[0].type === "company") {
    //                     if (f.followed_by) {
    //                       let objectedFriends = Object.entries(f.followed_by);
    //                       objectedFriends.map((g) => {
    //                         if (g[1].user_id === userid && e[1].qty !== 0) {
    //                           arr.push(e[1]);
    //                         }
    //                       });
    //                     }
    //                     if (f.friends) {
    //                       let objectedFriends = Object.entries(f.friends);
    //                       objectedFriends.map((g) => {
    //                         if (g[1].user_id === userid && e[1].qty !== 0) {
    //                           arr.push(e[1]);
    //                         }
    //                       });
    //                     }
    //                   } else {
    //                     if (f.friends) {
    //                       let objectedFriends = Object.entries(f.friends);
    //                       objectedFriends.map((g) => {
    //                         if (g[1].user_id === userid && e[1].qty !== 0) {
    //                           arr.push(e[1]);
    //                         }
    //                       });
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //             if (e[1].created_by === userid) {
    //               // if (e[1].qty !== 0) {
    //               arrCurrentProducts.push(e[1]);
    //               // }
    //             }
    //           }
    //         });
    //         arrCurrentProducts = arrCurrentProducts.reverse();
    //         if (arrCurrentProducts.length === 0) {
    //           this.setState({ currentUserProduct: false });
    //         }
    //         console.log(arr);
    //         this.setState({
    //           allProductsCompleted,
    //           arrCurrentProducts,
    //           lengthOfOtherProducts: arr,
    //         });
    //       }
    //     } else {
    //       let arr = [];
    //       this.setState({
    //         allProducts: arr,
    //         newProducts: [],
    //         noProducts: true,
    //         showingFeaturedProducts: true,
    //         firstTime: false,
    //         newerProducts: false,
    //         featuredProducts: true,
    //         currentUserProduct: false,
    //         arrCurrentProducts: [],
    //       });
    //     }
    //   });

    let bhodi_campaigns = this.props.global_campaigns;
    if (bhodi_campaigns) {
      if (bhodi_campaigns.length > 0) {
        let arr = [];
        let allCampaignsCompleted = [];
        let allCampaignsCompletedToRender = [];
        let arrCurrentCampaigns = [];
        let objected = bhodi_campaigns;
        objected.map((e) => {
          e[1].type = "campaign";
          if (e[1].active) {
            allCampaignsCompletedToRender.push(e[1]);
            // if (e[1].desc) {
            //   e[1].desc = e[1].desc.substr(0, 200);
            // }
            allCampaignsCompleted.push(e[1]);
          }
        });
        allCampaignsCompleted.map((e) => {
          e.type = "campaign";
          if (e.created_by !== uid) {
            let filtered = this.props.user.filter((f) => {
              return f.user_id === e.created_by;
            });
            if (filtered.length > 0) {
              let f = filtered[0];
              if (f.followed_by || f.friends) {
                let objectedFriends = [];
                if (f.followed_by) {
                  objectedFriends = Object.entries(f.followed_by);
                }
                // if (f.friends) {
                //   let objectedCheck = Object.entries(f.friends);
                //   objectedFriends = objectedFriends.concat(objectedCheck);
                // }
                objectedFriends.map((g) => {
                  if (g[1].user_id === uid) {
                    if (e.active) {
                      if (e.desc) {
                        e.desc = e.desc.substr(0, 520);
                        e.short_desc = e.desc.substr(0, 120);
                      }
                      if (e.campaignType !== "charity") {
                        e.images = [e.image];
                        e.imagesObjected = [
                          { image: e.image, pushKey: e.pushKey },
                        ];
                      }
                      if (e.campaignType === "local_event") {
                        if (e.eventForGuestUser) {
                          return false;
                        }
                        if (e.takeFromAdmin) {
                          let obj = mergeAdminEvent(e, this.props.global_campaigns)
                          e = Object.assign({}, obj)
                        }
                        let arrTimingsAvailable = [];
                        if (e.timings) {
                          e.timings.map((y) => {
                            if (y.isoStartTime && y.isoEndTime) {
                              let checkedInOfCurrentBlock = [];
                              if (e.checked_in_by) {
                                Object.entries(e.checked_in_by).map((t) => {
                                  if (t[1].event_id === y.eventId) {
                                    checkedInOfCurrentBlock.push(t[1]);
                                  }
                                });
                              }
                              let getCurrentDate = new Date();
                              if (
                                getCurrentDate.getTime() <
                                new Date(Date.parse(y.isoEndTime)).getTime()
                              ) {
                                if (
                                  checkedInOfCurrentBlock.length <
                                  Number(y.personsAmountEvent)
                                ) {
                                  arrTimingsAvailable.push(y);
                                }
                              }
                            }
                          });
                        }
                        if (arrTimingsAvailable.length > 0) {
                          arr.push(e);
                        }
                      }
                      if (e.campaignType === "kortings_voucher") {
                        let objectedVerzilveredBy = [];
                        let num = 0;
                        if (e.verzilvered_by) {
                          objectedVerzilveredBy = Object.entries(
                            e.verzilvered_by
                          );
                          num = this.getVerzilveredQty(objectedVerzilveredBy);
                        }
                        let bewaaredArray = [];
                        if (!e.kortingsVoucherUnlimited) {
                          if (num + bewaaredArray.length < Number(e.number)) {
                            arr.push(e);
                          }
                        } else {
                          arr.push(e);
                        }
                      }
                      if (e.campaignType === "web_shop") {
                        let objectedVerzilveredBy = [];
                        let num = 0;
                        if (e.verzilvered_by) {
                          objectedVerzilveredBy = Object.entries(
                            e.verzilvered_by
                          );
                          num = this.getVerzilveredQty(
                            objectedVerzilveredBy
                          );
                        }
                        let bewaaredArray = [];
                        if (!e.voucherUnlimited) {
                          if (
                            num + bewaaredArray.length <
                            Number(e.number)
                          ) {
                            arr.push(e);
                          }
                        } else {
                          arr.push(e);
                        }
                      }
                    }
                  }
                });
              }
            }
          }
          if (e.created_by === uid) {
            arrCurrentCampaigns.push(e);
          }
        });
        this.setState({ lengthOfOtherCampaigns: arr });
      }
    }
    firebase
      .database()
      .ref("users/" + userid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          if (a.friends) {
            let objected = Object.entries(a.friends);
            a.objectedFriends = objected;
          } else {
            a.objectedFriends = [];
          }
          if (!a.profile_image) {
            a.profile_image =
              "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
          }

          // localStorage.setItem("userdata", JSON.stringify(data.val()))
          this.setState({
            currentUserMember: a,
            create_subscription: a.create_subscription,
            userid: userid,
            url: a.user_name_id,
            firstName: a.first_name,
            lastName: a.last_name,
            city: a.city,
            zipCode: a.zipCode,
            street: a.street,
            houseNumber: a.houseNumber,
            country: a.country,
            phoneNumber: a.phoneNumber,
            email: a.email,
            profileImageUrl: a.profile_image,
            description: a.user_description,
          });
          if (a.profileActive) {
            this.setState({ profileActive: true })
          } else {
            this.setState({ profileActive: false })
          }
          if (typeof a.hideDonation === "boolean") {
            this.setState({
              checkedHideDonation: a.hideDonation,
              checkedHideFriendsInfo: a.hideFriendsInfo,
              checkedHideProfileImage: a.hideProfileImage,
              checkedHideProfileInfo: a.hideProfileInfo,
            });
          }
          if (a.friends) {
            this.setState({ showFriendstab: true });
          } else {
            this.setState({ showFriendstab: false });
          }
          if (a.friends || a.following) {
            this.setState({ showProductsOthertab: true });
          } else {
            this.setState({ showProductsOthertab: false });
          }
          if (a.hideMenuContent) {
            this.setState({ hideMenuContent: a.hideMenuContent });
          } else {
            this.setState({ hideMenuContent: false });
          }
          if (a.bookmarked_things) {
            this.setState({ showBookmarkTab: true });
          } else {
            this.setState({ showBookmarkTab: false });
          }
          if (a.accountType === "global") {
            this.setState({ checkedPrivate: false });
          } else {
            this.setState({ checkedPrivate: true });
          }
          if (a.subscription) {
            if (a.subscription.status === "active") {
              this.setState({ showDelete: false });
            } else {
              this.setState({ showDelete: true });
            }
          } else {
            if (checkForSubscribedExclusives(a)) {
              this.setState({ showDelete: false })
            } else {
              this.setState({ showDelete: true });
            }
          }
          let sessionStored = localStorage.getItem("onCurrentTab");
          let route = window.location.pathname;

          let email = a.email;
          let password = a.pass;
          var user = firebase.auth().currentUser;
          if (user && email && password) {
            var credential = firebase.auth.EmailAuthProvider.credential(
              email,
              password
            );
            user.reauthenticateWithCredential(credential);
          }
        }
      });
    if (!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing")) {
      firebase
        .database()
        .ref("users/" + userid)
        .once("value", (data) => {
          let a = data.val();
          if (a) {
            if (a.user_name_id === this.props.member_id) {
              this.props.updateSignUpFieldsFilled("");
            }
            if (a.create_subscription) {
              this.setState({ create_subscription: a.create_subscription });
            }
            setTimeout(() => {
              firebase
                .database()
                .ref("users/" + userid + "/create_subscription")
                .set(false)
            }, 1000);
          }
        });
    }
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  saveUrl() {
    const { url } = this.state;
    let userid = localStorage.getItem("userid");
    if (url) {
      firebase
        .database()
        .ref("users/" + userid + "/user_name_id")
        .set(url)
        .then(() => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              localStorage.setItem("userid", "");
              window.location.href = "/login";
            });
        });
    } else {
      console.log("fill some thing");
    }
    let element = document.getElementById("footermain");
    if (element) {
      element.style.display = "flex";
    }
    this.setState({ rightModalSettings: false, profileRight: false });
  }

  saveInfo() {
    this.setState({ savtBtnText: "SAVING.." });
    const {
      firstName,
      lastName,
      street,
      city,
      zipCode,
      houseNumber,
      country,
      phoneNumber,
      profileActive,
    } = this.state;
    const {
      first_name,
      last_name
    } = this.state.currentUserMember;
    let userid = localStorage.getItem("userid");
    if (firstName) {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set(firstName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set("");
    }
    if (lastName) {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set(lastName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set("");
    }
    if (street) {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set(street);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set("");
    }
    if (city) {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set(city);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set("");
    }
    if (zipCode) {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set(zipCode);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set("");
    }
    if (houseNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set(houseNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set("");
    }
    if (country) {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set(country);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set("");
    }
    if (phoneNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set(phoneNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set("");
    }
    if (profileActive) {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(true);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(false);
    }
    if ((first_name !== firstName || last_name !== lastName) && this.state.currentUserMember.stripe_id) {
      axios
        .post(
          "https://us-central1-bhodi-7c327.cloudfunctions.net/updateStripeCustomer",
          {
            name:
              firstName +
              "" +
              lastName,
            email: this.state.currentUserMember.email,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then((response) => {
          this.closingPopupOnSave()
          // this.setState({ savtBtnText: "SAVE" });
        })
    } else {
      setTimeout(() => {
        this.closingPopupOnSave()
        // this.setState({ savtBtnText: "SAVE" });
      }, 3000);
    }
    // this.setState({ rightModalSettings: false, userInfoRight: false })
  }

  savePrivacy() {
    this.setState({ savtBtnText: "SAVING.." });
    let userid = localStorage.getItem("userid");
    if (this.state.checkedPrivate) {
      firebase
        .database()
        .ref("users/" + userid + "/accountType")
        .set("private");
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/accountType")
        .set("global");
    }
    setTimeout(() => {
      this.closingPopupOnSave()
      // this.setState({ savtBtnText: "SAVE" });
    }, 3000);
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        localStorage.removeItem("cookie_item");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
      });
  }

  saveEmailPassword() {
    const { email, password, confirmPassword, url, currentPassword } =
      this.state;

    let userid = localStorage.getItem("userid");
    let filteredEmail = [];
    let filteredURL = [];

    if (email !== this.state.currentUserMember.email) {
      filteredEmail = this.props.user.filter((h) => {
        return h.email === email;
      });
    }

    if (url !== this.state.currentUserMember.user_name_id) {
      filteredURL = this.props.user.filter((h) => {
        return h.user_name_id === url;
      });
    }

    if (filteredEmail.length === 0 && filteredURL.length === 0) {
      this.setState({ savtBtnText: "SAVING.." });

      if (url && url !== this.state.currentUserMember.user_name_id) {
        firebase
          .database()
          .ref("users/" + userid + "/user_name_id")
          .set(url)
          .then(() => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                localStorage.setItem("userid", "");
                window.location.href = "/login";
              });
          });
      } else {
        console.log("fill some thing");
      }
      let changePassword = confirmPassword;
      if (email !== "") {
        var data = JSON.stringify({
          userId: userid,
          email: email,
        });

        var config = {
          method: "post",
          url: "https://us-central1-bhodi-7c327.cloudfunctions.net/updateEmail",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.statusCode === 201 && this.state.currentUserMember.stripe_id) {
              axios
                .post(
                  "https://us-central1-bhodi-7c327.cloudfunctions.net/updateStripeCustomer",
                  {
                    name:
                      this.state.currentUserMember.first_name +
                      "" +
                      this.state.currentUserMember.last_name,
                    email: email,
                    customerId: this.state.currentUserMember.stripe_id,
                  }
                )
                .then((responseInner) => {
                  console.log("mollie Updated")
                })
            }
            if (response.data.statusCode === 201) {
              firebase
                .database()
                .ref("users/" + userid + "/email")
                .set(email);
            }
          })
          .catch((response) => {
            var errorMessage = response.error.message;
            if (
              errorMessage ===
              "There is no user record corresponding to this identifier. The user may have been deleted."
            ) {
              this.setState({ emailNotFound: true, aanmeldenloader: false });
            }
          });
        // user.updateEmail(email).then(() => {
        //     firebase.database().ref("users/" + userid+ "/email").set(email).then(() => {
        //     })
        // })
      }
      if (password !== changePassword) {
        if (password !== "" && changePassword !== "") {
          if (currentPassword === "") {
            this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
          } else {
            this.setState({ fillCurrentPassword: false });
          }
        }
        this.setState({ changedPassword: true });
      } else {
        if (password !== "" && changePassword !== "") {
          if (currentPassword !== "") {
            this.setState({
              changedPassword: false,
              fillCurrentPassword: false,
            });
          } else {
            this.setState({
              savtBtnText: "SAVE",
              changedPassword: false,
              fillCurrentPassword: true,
            });
          }
        }
      }
      if (
        password !== "" &&
        changePassword !== "" &&
        currentPassword !== "" &&
        password === changePassword
      ) {
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          currentPassword
        );
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            this.setState({ fillCurrentPassword: false });
            var newPassword = password;
            user
              .updatePassword(newPassword)
              .then((e) => {
                bcrypt.genSalt(saltRounds, (err, salt) => {
                  bcrypt.hash(newPassword, salt, (err, hash) => {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, newPassword)
                      .then((succ) => {
                        // firebase
                        //   .database()
                        //   .ref("users/" + userid + "/pass")
                        //   .set(newPassword)
                        //   .then(() => {
                        firebase
                          .database()
                          .ref("users/" + userid + "/password")
                          .set(hash)
                          .then(() => {
                            this.setState({
                              password: "",
                              confirmPassword: "",
                            });
                            window.location.reload();
                            // });
                          });
                      });
                  });
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((err) => {
            if (err.code === "auth/wrong-password") {
              this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
            }
          });
      } else {
        setTimeout(() => {
          this.closingPopupOnSave()
          // this.setState({ savtBtnText: "SAVE" });
        }, 3000);
      }

    } else {
      if (filteredEmail.length > 0) {
        this.setState({ emailAlreadyTooked: true });
      } else {
        this.setState({ emailAlreadyTooked: false });
      }
      if (filteredURL.length > 0) {
        this.setState({ urlNotAvailable: true });
      } else {
        this.setState({ urlNotAvailable: false });
      }
    }
  }

  fileUpload(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`userimages/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/profile_image")
          .set(snapUrl)
          .then(() => { });
      });
    });
  }


  getCountComing() {
    let allCounted = this.state.notSeenMessages.length;

    let toShowText = 0
    if (Number(allCounted) > 999) {
      toShowText = "1k+";
    } else if (Number(allCounted) > 99) {
      toShowText = "99+";
    } else {
      toShowText = allCounted;
    }

    if (allCounted > 0) {
      return (<div className="comingrequestsnotify">
        <p>{toShowText}</p>
      </div>)
    }
    // return objected.length + newFollowers + this.state.notSeenMessages.length
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.message !== this.props.message) {
      if (this.props.message) {
        let allMessages = [];
        this.props.message.map((g) => {
          let objected = Object.entries(g[1]);
          objected.map((y) => {
            allMessages.push(y[1]);
          });
        });
        let filtered = allMessages.filter((g) => {
          return !g.seen;
        });
        this.setState({ notSeenMessages: filtered });
      } else {
        this.setState({ notSeenMessages: [] });
      }
    }

    if (this.state.rightModalSettings !== prevState.rightModalSettings) {
      // Call your function here
      if (this.state.rightModalSettings) {
        this.doHtmlOverflowHidden()
      } else {
        this.removeHtmlOverflowHidden()
      }
    }

    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      if (!window.location.href.includes("volgend")) {
        let uid = localStorage.getItem("userid");
        let bhodi_campaigns = this.props.global_campaigns;
        if (bhodi_campaigns) {
          if (bhodi_campaigns.length > 0) {
            let arr = [];
            let allCampaignsCompleted = [];
            let allCampaignsCompletedToRender = [];
            let arrCurrentCampaigns = [];
            let objected = bhodi_campaigns;
            objected.map((e) => {
              e[1].type = "campaign";
              if (e[1].active) {
                allCampaignsCompletedToRender.push(e[1]);
                // if (e[1].desc) {
                //   e[1].desc = e[1].desc.substr(0, 200);
                // }
                allCampaignsCompleted.push(e[1]);
              }
            });
            allCampaignsCompleted.map((e) => {
              e.type = "campaign";
              if (e.created_by !== uid) {
                let filtered = this.props.user.filter((f) => {
                  return f.user_id === e.created_by;
                });
                if (filtered.length > 0) {
                  let f = filtered[0];
                  if (f.followed_by || f.friends) {
                    let objectedFriends = [];
                    if (f.followed_by) {
                      objectedFriends = Object.entries(f.followed_by);
                    }
                    // if (f.friends) {
                    //   let objectedCheck = Object.entries(f.friends);
                    //   objectedFriends = objectedFriends.concat(objectedCheck);
                    // }
                    objectedFriends.map((g) => {
                      if (g[1].user_id === uid) {
                        if (e.active) {
                          if (e.desc) {
                            e.desc = e.desc.substr(0, 520);
                            e.short_desc = e.desc.substr(0, 120);
                          }
                          e.images = [e.image];
                          e.imagesObjected = [
                            { image: e.image, pushKey: e.pushKey },
                          ];
                          if (e.campaignType === "local_event") {
                            if (e.eventForGuestUser) {
                              return false;
                            }
                            if (e.takeFromAdmin) {
                              let obj = mergeAdminEvent(e, this.props.global_campaigns)
                              e = Object.assign({}, obj)
                            }
                            let arrTimingsAvailable = [];
                            if (e.timings) {
                              e.timings.map((y) => {
                                if (y.isoStartTime && y.isoEndTime) {
                                  let checkedInOfCurrentBlock = [];
                                  if (e.checked_in_by) {
                                    Object.entries(e.checked_in_by).map((t) => {
                                      if (t[1].event_id === y.eventId) {
                                        checkedInOfCurrentBlock.push(t[1]);
                                      }
                                    });
                                  }
                                  let getCurrentDate = new Date();
                                  if (
                                    getCurrentDate.getTime() <
                                    new Date(Date.parse(y.isoEndTime)).getTime()
                                    // getEventDate.getTime()
                                  ) {
                                    if (
                                      checkedInOfCurrentBlock.length <
                                      Number(y.personsAmountEvent)
                                    ) {
                                      arrTimingsAvailable.push(y);
                                    }
                                  }
                                }
                              });
                            }
                            if (arrTimingsAvailable.length > 0) {
                              arr.push(e);
                            }
                          }
                          if (e.campaignType === "kortings_voucher") {
                            let objectedVerzilveredBy = [];
                            let num = 0;
                            if (e.verzilvered_by) {
                              objectedVerzilveredBy = Object.entries(
                                e.verzilvered_by
                              );
                              num = this.getVerzilveredQty(objectedVerzilveredBy);
                            }
                            let bewaaredArray = [];
                            if (!e.kortingsVoucherUnlimited) {
                              if (num + bewaaredArray.length < Number(e.number)) {
                                arr.push(e);
                              }
                            } else {
                              arr.push(e);
                            }
                          }
                          if (e.campaignType === "web_shop") {
                            let objectedVerzilveredBy = [];
                            let num = 0;
                            if (e.verzilvered_by) {
                              objectedVerzilveredBy = Object.entries(
                                e.verzilvered_by
                              );
                              num = this.getVerzilveredQty(
                                objectedVerzilveredBy
                              );
                            }
                            let bewaaredArray = [];
                            if (!e.voucherUnlimited) {
                              if (
                                num + bewaaredArray.length <
                                Number(e.number)
                              ) {
                                arr.push(e);
                              }
                            } else {
                              arr.push(e);
                            }
                          }
                        }
                      }
                    });
                  }
                }
              }
              if (e.created_by === uid) {
                arrCurrentCampaigns.push(e);
              }
            });
            this.setState({ lengthOfOtherCampaigns: arr });
          }
        }
      }
    }
    if (this.props.doShowMenuRightAbout) {
      if (this.props.doShowMenuRightAbout !== this.state.showBhodiAbouts) {
        this.setState({
          rightModalSettings: true,
          openedFromPropsAbout: true,
          menuItemsModals: true,
          showBhodiAbouts: true,
        });
      }
    }
  }

  createMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  copyToClipboard(e) {
    this.setState({ copiedTextDone: true });
    var textField = document.createElement("textarea");
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // setTimeout(() => {
    //     this.setState({ copiedTextDone: false })
    // }, 3000);
  }

  async deleteCurrentUser() {
    if (this.state.showDelete) {
      if (this.state.passwordForDel) {
        this.setState({ passwordRequiredDel: false })

        let email = this.state.currentUserMember.email
        let password = this.state.passwordForDel
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );;
        user.reauthenticateWithCredential(credential).then(() => {
          this.setState({ passwordWrongDel: false })
          this.deleteUserMain()
        }).catch(() => {
          this.setState({ passwordWrongDel: true })
          return false;
        })
      } else {
        this.setState({ passwordRequiredDel: true, passwordWrongDel: false })
        return false;
      }
    } else {
      this.setState({
        // cancelSubscriptionPopup: false,
        showDelete: true,
      });
      axios
        .post(
          "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
          {
            subscriptionId:
              this.state.currentUserMember.subscription_id,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then(() => {
          axios
            .post(
              "https://us-central1-bhodi-7c327.cloudfunctions.net/deleteStripeCustomer",
              {
                customerId: this.state.currentUserMember.stripe_id,
              }
            )
            .then(() => {
              this.deleteCurrentUser();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
      // this.setState({ cancelSubscriptionPopup: true });
    }
  }

  async deleteUserMain() {

    this.setState({ deleteBtnText: "SAVING.." });

    let allUsers = this.props.user;
    allUsers.map((u) => {
      if (u.followed_by) {
        let objected = Object.entries(u.followed_by);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          firebase
            .database()
            .ref(
              "users/" + u.user_id + "/followed_by/" + filtered[0][1].pushKey
            )
            .remove();
        }
      }
      if (u.newFollowersList) {
        let objected = Object.entries(u.newFollowersList);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          let newFollowers = u.newFollowers - 1;
          firebase
            .database()
            .ref(
              "users/" +
              u.user_id +
              "/newFollowersList/" +
              filtered[0][1].pushKey
            )
            .remove();
          firebase
            .database()
            .ref("users/" + u.user_id + "/newFollowers")
            .set(newFollowers);
        }
      }
    });

    await firebase
      .database()
      .ref("allMessages/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("guest_carts/" + this.state.currentUserId)
      .remove();
    await firebase.auth().currentUser.delete();

    firebase
      .database()
      .ref("users/" + this.state.currentUserId)
      .remove()
      .then(() => {
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
      });
  }

  doHtmlOverflowHidden() {
    document.querySelector("html").classList.add("htmlvriendenoverflowhidden")
  }

  removeHtmlOverflowHidden() {
    document.querySelector("html").classList.remove("htmlvriendenoverflowhidden")
  }
  // comments liked


  checkForFollowIcon(userToSee) {
    if (userToSee.followed_by) {
      let objected = Object.entries(userToSee.followed_by);
      let filtered = objected.filter((g) => {
        return g[1].user_id === this.state.currentUserId;
      });
      if (filtered.length > 0) {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            return <img src={hwb} style={{ maxWidth: 16, marginRight: 10 }} />;
          }
        } else {
          return <img src={hwb} style={{ maxWidth: 16, marginRight: 10 }} />;
        }
      } else {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            //     return <img src={hwb} style={{ maxWidth: 20, marginRight: 10 }} />
            // } else {
            return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
          }
        } else {
          return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
        }
      }
    } else {
      if (userToSee.friends) {
        let objectedFriends = Object.entries(
          userToSee.friends
        );
        let filteredFriends = objectedFriends.filter((g) => {
          return g[1].user_id === this.state.currentUserId;
        });
        if (filteredFriends.length === 0) {
          //     return <img src={hwb} style={{ maxWidth: 20, marginRight: 10 }} />
          // } else {
          return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
        }
      } else {
        return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
      }
    }
  }

  checkForFollowText(userToSee) {
    if (userToSee.followed_by) {
      let objected = Object.entries(userToSee.followed_by);
      let filtered = objected.filter((g) => {
        return g[1].user_id === this.state.currentUserId;
      });
      if (filtered.length > 0) {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            return <font>Ontvolgen</font>;
          }
        } else {
          return <font>Ontvolgen</font>;
        }
      } else {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            //     return <font>Ontvolgen</font>
            // } else {
            return <font>Volgen</font>;
          }
        } else {
          return <font>Volgen</font>;
        }
      }
    } else {
      if (userToSee.friends) {
        let objectedFriends = Object.entries(
          userToSee.friends
        );
        let filteredFriends = objectedFriends.filter((g) => {
          return g[1].user_id === this.state.currentUserId;
        });
        if (filteredFriends.length === 0) {
          //     return <font>Ontvolgen</font>
          // } else {
          return <font>Volgen</font>;
        }
      } else {
        return <font>Volgen</font>;
      }
    }
  }

  followCompany(userToSee) {
    followUnfollowCompany(userToSee, this.state.currentUserId, this.props.user)
  }

  // followCompany(userToSee) {
  //   if (userToSee.followed_by) {
  //     let objected = Object.entries(userToSee.followed_by);
  //     let filtered = objected.filter((g) => {
  //       return g[1].user_id === this.state.currentUserId;
  //     });
  //     if (filtered.length > 0) {
  //       if (userToSee.newFollowersList) {
  //         let objectedSec = Object.entries(
  //           userToSee.newFollowersList
  //         );
  //         let filteredNewFollowers = objectedSec.filter((g) => {
  //           return g[1].user_id === this.state.currentUserId;
  //         });
  //         if (filteredNewFollowers.length > 0) {
  //           let newFollowers = Number(
  //             userToSee.newFollowers
  //           );
  //           newFollowers = newFollowers - 1;
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               userToSee.user_id +
  //               "/newFollowersList/" +
  //               filteredNewFollowers[0][1].push_key
  //             )
  //             .remove();
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               userToSee.user_id +
  //               "/newFollowers"
  //             )
  //             .set(newFollowers);
  //         }
  //       }
  //       let filteredCurrentUser = this.props.user.filter((h) => {
  //         return h.user_id === this.state.currentUserId;
  //       });
  //       if (filteredCurrentUser[0].following) {
  //         let objectedSec = Object.entries(filteredCurrentUser[0].following);
  //         let filteredSec = objectedSec.filter((g) => {
  //           return g[1].user_id === userToSee.user_id;
  //         });
  //         if (filteredSec.length > 0) {
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               this.state.currentUserId +
  //               "/following/" +
  //               filteredSec[0][1].push_key
  //             )
  //             .remove();
  //         }
  //       }
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/followed_by/" +
  //           filtered[0][1].push_key
  //         )
  //         .remove();
  //     } else {
  //       let pushFirst = firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserId + "/following")
  //         .push().key;
  //       let pushSecond = firebase
  //         .database()
  //         .ref("users/" + userToSee.user_id + "/followed_by")
  //         .push().key;
  //       let pushSecondNew = firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/newFollowersList"
  //         )
  //         .push().key;
  //       let newFollowers = 0;
  //       let obj = {
  //         user_id: userToSee.user_id,
  //         push_key: pushFirst,
  //       };
  //       let objSec = {
  //         user_id: this.state.currentUserId,
  //         push_key: pushSecond,
  //       };
  //       let objThird = {
  //         user_id: this.state.currentUserId,
  //         push_key: pushSecondNew,
  //       };
  //       if (userToSee.newFollowers) {
  //         newFollowers = Number(userToSee.newFollowers);
  //       }
  //       newFollowers = newFollowers + 1;
  //       firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
  //         .set(obj);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/followed_by/" +
  //           pushSecond
  //         )
  //         .set(objSec);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" + userToSee.user_id + "/newFollowers"
  //         )
  //         .set(newFollowers);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/newFollowersList/" +
  //           pushSecondNew
  //         )
  //         .set(objThird);
  //     }
  //   } else {
  //     let pushFirst = firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserId + "/following")
  //       .push().key;
  //     let pushSecond = firebase
  //       .database()
  //       .ref("users/" + userToSee.user_id + "/followed_by")
  //       .push().key;
  //     let pushSecondNew = firebase
  //       .database()
  //       .ref(
  //         "users/" + userToSee.user_id + "/newFollowersList"
  //       )
  //       .push().key;
  //     let newFollowers = 0;
  //     let obj = {
  //       user_id: userToSee.user_id,
  //       push_key: pushFirst,
  //     };
  //     let objSec = {
  //       user_id: this.state.currentUserId,
  //       push_key: pushSecond,
  //     };
  //     let objThird = {
  //       user_id: this.state.currentUserId,
  //       push_key: pushSecondNew,
  //     };
  //     if (userToSee.newFollowers) {
  //       newFollowers = Number(userToSee.newFollowers);
  //     }
  //     newFollowers = newFollowers + 1;
  //     firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
  //       .set(obj);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         userToSee.user_id +
  //         "/followed_by/" +
  //         pushSecond
  //       )
  //       .set(objSec);
  //     firebase
  //       .database()
  //       .ref("users/" + userToSee.user_id + "/newFollowers")
  //       .set(newFollowers);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         userToSee.user_id +
  //         "/newFollowersList/" +
  //         pushSecondNew
  //       )
  //       .set(objThird);
  //   }
  // }

  closingPopupOnSave() {
    if (this.props.closeAboutBhodiRightMenu) {
      this.props.closeAboutBhodiRightMenu();
      if (this.props.doShowMenuRightAbout) {
        setTimeout(() => {
          this.setState({ showBhodiAbouts: false });
          if (this.state.openedFromPropsAbout) {
            let element =
              document.getElementById("footermain");
            if (element) {
              element.style.display = "flex";
            }
            this.setState({
              rightModalSettings: false,
              openedFromPropsAbout: false,
              menuItemsModals: false,
              showBhodiAbouts: false,
            });
          }
        }, 400);
        return false;
      } else {
        let element = document.getElementById("footermain");
        if (element) {
          element.style.display = "flex";
        }
        this.setState({
          rightModalSettings: false,
          privacyRight: false,
          channelPageRight: false,
          userInfoRight: false,
          shopSettings: false,
          voucherSettings: false,
          emailPassword: false,
          myOrdersRight: false,
          showBhodiFaqs: false,
          showBhodiAbouts: false,
          savtBtnText: "SAVE"
        });
        // this.setState({ showBhodiAbouts: false })
      }
    }
    if (this.state.openedFromPropsAbout) {
      this.setState({
        rightModalSettings: false,
        openedFromPropsAbout: false,
        menuItemsModals: false,
        showBhodiAbouts: false,
      });
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "flex";
      }
    }
    this.setState({ showBhodiAbouts: false });
  }

  render() {
    return (
      !this.props.hideNav && (
        <div className="navofwholemember">
          <div className="nav-fullwidth" id="navfullwidthmaincomp">
            <div className="container-nfw">
              <div className="left-nfw">
                <ul>
                  {this.state.currentUserMember.user_id === this.state.currentUserId && (
                    <NavbarMenuMobileFooterStuff />
                  )}
                  {/* {this.state.currentUserMember.user_id === this.state.currentUserId && (
                    <Link
                      className="link-style"
                      to={this.props.linkToVolgend}
                      onClick={() => {
                        this.props.showProducts();
                      }}
                    >
                      <li>
                        Vouchers
                      </li>
                    </Link>
                  )}
                  {this.state.currentUserMember.user_id === this.state.currentUserId && (
                    <Link
                      className="link-style"
                      to={this.props.linkToVolgend}
                      onClick={() => {
                        this.props.showProducts();
                      }}
                    >
                      <li>
                        Producten
                      </li>
                    </Link>
                  )}
                  {this.state.currentUserMember.user_id === this.state.currentUserId && (
                    <Link
                      className="link-style"
                      to={this.props.linkToVolgend}
                      onClick={() => {
                        this.props.showProducts();
                      }}
                    >
                      <li>
                        Events
                      </li>
                    </Link>
                  )} */}
                  {this.state.lengthOfOtherCampaigns.length > 0 &&
                    this.state.currentUserMember.user_id ===
                    this.state.currentUserId && (
                      <Link
                        className="link-style"
                        to={this.props.linkToVolgend}
                        onClick={() => {
                          this.props.showProducts();
                        }}
                      >
                        <li className={this.state.shopProductsShop && "active"}>
                          Volgend
                        </li>
                      </Link>
                    )}
                  {this.state.currentUserMember && (
                    <Link
                      className="link-style"
                      to={this.props.linkToCharity}
                      onClick={() => {
                        // this.setState({ showLists: false, showItems: false, showFriends: false, showCharity: true, showProductsOwn: false, showProductsBookmarked: false, showItemsShops: false, showContent: false })
                        this.props.showCharity();
                      }}
                    >
                      <li className={this.state.showCharity && "active"}>
                        {this.state.charityCount > 0 && (<div className="comingrequestsnotify">
                          <p>{this.state.charityCount}</p>
                        </div>)}
                        Charity
                      </li>
                    </Link>
                  )}
                  {this.state.currentUserMember &&
                    this.state.currentUserMember.user_id ===
                    this.state.currentUserId ? (
                    <li
                      className={(this.state.showFriends || this.state.showVriendenWizard) && "active"}
                      onClick={() => {
                        this.props.showFriends();
                      }}
                    >
                      {/* {this.getCountComing()} */}
                      <font>Vrienden</font>
                    </li>
                  ) : (
                    this.state.currentUserMember &&
                    this.state.showFriendstab &&
                    !this.state.hideFriendsInfo && (
                      <li
                        className={this.state.showFriends && "active"}
                        onClick={() => {
                          this.props.showFriends();
                        }}
                      >
                        Vrienden
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div
                className="center-nfw"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  left:
                    !this.state.isDesktop && this.state.showLists
                      ? 0
                      : !this.state.isDesktop && this.state.showItems
                        ? 0
                        : !this.state.isDesktop &&
                        this.state.showProductsOwn &&
                        0,
                  right: !this.props.menuItemsShowing && "auto",
                  transform:
                    !this.state.isDesktop && this.state.showLists
                      ? "none"
                      : !this.state.isDesktop && this.state.showItems
                        ? "none"
                        : !this.state.isDesktop &&
                        this.state.showProductsOwn &&
                        "none",
                }}
              >
                {this.state.currentUserMember && (
                  <img
                    src={
                      this.state.currentUserMember.profile_image
                        ? this.state.currentUserMember.profile_image
                        : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                    }
                    onClick={() => {
                      history.push(`/member/${this.state.currentUserMember.user_name_id}`)
                    }}
                    style={{
                      width: 30,
                      height: 30,
                      objectFit: "cover",
                      objectPosition: "center",
                      // marginRight: 8,
                      borderRadius: "50%",
                    }}
                  />
                )}
                <label
                  style={{
                    fontSize: 14,
                    letterSpacing: 1,
                    marginTop: 5,
                    marginBottom: 0,
                    fontWeight: 600,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    history.push(`/member/${this.state.currentUserMember.user_name_id}`)
                  }}
                >
                  {this.state.currentUserMember.first_name}{" "}
                  {this.state.currentUserMember.last_name}
                </label>
              </div>
              <div
                className="right-nfw righticons"
                style={{ position: "static" }}
              >

                {this.props.onGlobalPageEvents && (<div className="icons-nclgd">
                  <NavGlobalVoucherEventsCity
                    sector={this.props.sector}
                    onMainSector={(!this.props.isDezeWeek && !this.props.isVolgendeWeek) ? true : false}
                    onCurrentWeek={this.props.isDezeWeek ? true : false}
                    onComingWeek={this.props.isVolgendeWeek ? true : false}
                    noCityFiltered={this.props.noCityFiltered ? true : false}
                    // noCityFiltered={true}
                    noShowCity={true}
                  />
                  <a
                    href="javascript:void(0)"
                    className="btn btn-black orderbasketicon btnpopupwork"
                  >
                    <img
                      src={searchpicpng}
                      style={{ maxWidth: 20, marginTop: 3 }}
                      onClick={() => {
                        this.setState({ showSearchLine: !this.state.showSearchLine })
                      }}
                    />
                    {this.state.showSearchLine && (
                      <input
                        type="text"
                        className="input-inclgd"
                        onChange={(e) => {
                          if (this.props.searchFiltered) {
                            this.props.searchFiltered(e.target.value);
                          }
                        }}
                      />
                    )}
                  </a>
                  <FilterIconGlobalNav sector={this.props.sector} onPopupOpen={(currentWeekEvents, comingWeekEvents) => {
                    this.setState({ currentWeekEvents, comingWeekEvents, showFiltersModal: true })
                  }} />
                </div>)}


                {this.props.onGlobalPageVoucher && (<div className="icons-nclgd">
                  <NavGlobalVoucherCity
                    sector={this.props.sector}
                    onMainSector={this.props.onMainSector}
                    noCityFiltered={this.props.noCityFiltered}
                    noShowCity={this.props.noShowCity}
                    category={this.props.category}
                    onMainCategory={this.props.onMainCategory}
                  />
                  <a
                    href="javascript:void(0)"
                    className="btn btn-black orderbasketicon btnpopupwork"
                  >
                    <img
                      src={searchpicpng}
                      style={{ maxWidth: 20, marginTop: 3 }}
                      onClick={() => {
                        this.setState({ showSearchLine: !this.state.showSearchLine })
                      }}
                    />
                    {this.state.showSearchLine && (
                      <input
                        type="text"
                        className="input-inclgd"
                        onChange={(e) => {
                          if (this.props.searchFiltered) {
                            this.props.searchFiltered(e.target.value);
                          }
                        }}
                      />
                    )}
                  </a>
                  <FilterIconGlobalNavVoucher
                    sector={this.props.sector}
                    onMainSector={this.props.onMainSector}
                    noCityFiltered={this.props.noCityFiltered}
                    noShowCity={this.props.noShowCity}
                    category={this.props.category}
                    onMainCategory={this.props.onMainCategory}
                    onPopupOpen={(availableMainCategories, allCategories) => {
                      this.setState({ availableMainCategories, allCategories, showFiltersModalVouchers: true })
                    }} />
                </div>)}

                {this.state.showFriends &&
                  this.state.isDesktop &&
                  this.state.currentUserMember.user_id ===
                  this.state.currentUserId && (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      className="righticonopacity"
                    >
                      <a
                        href="javascript:void(0)"
                        className="btn btn-black"
                        style={{
                          fontSize: 13,
                          letterSpacing: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          this.setState({ rightModalSearchFriends: true })
                        }
                      >
                        <SearchIcon
                          style={{
                            cursor: "pointer",
                            fill: "white",
                            fontSize: 18,
                            marginRight: 8,
                          }}
                        />
                        <font>Zoek nieuwe vrienden</font>
                      </a>
                    </div>
                  )}
                <div
                  style={{ position: "relative", display: "inline-block" }}
                  className="righticonopacity"
                >
                  {this.props.showFollowIcon && <div
                    className="btn btn-black orderbasketicon btnpopupwork obifollowicon"
                    onClick={this.followCompany.bind(this, this.props.currentMainMember)}
                  >
                    {this.checkForFollowIcon(this.props.currentMainMember)}
                    {this.checkForFollowText(this.props.currentMainMember)}
                  </div>}
                  <a
                    href="javascript:void(0)"
                    className="btn btn-black orderbasketicon btnpopupwork"
                    onClick={() => {

                      this.setState({ ordersRightMenu: true });
                    }}
                  >
                    <img
                      src={orderspicpng}
                      style={{ maxWidth: 20, marginTop: 3 }}
                    />
                    <div id="mustShowOnAddOrder" style={{ left: "-126%" }}>
                      <p style={{ textTransform: "none" }}>Toegevoegd</p>
                    </div>
                    <div
                      className="notification-bpw"
                      style={{ left: "-190%", minWidth: 150 }}
                    >
                      <p>Orderoverzicht</p>
                    </div>
                  </a>
                  {!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing") && (
                    <a
                      href="javascript:void(0)"
                      style={{ position: "relative" }}
                      className="btn btn-black orderbasketicon orderbasketiconmain"
                      onClick={() => { }}
                    >
                      <CartNonUser notNeedOrders={true} />
                    </a>
                  )}
                  <a
                    href="javascript:void(0)"
                    className="btn btn-black btnpopupwork"
                    onClick={() => {
                      let element = document.getElementById("footermain");
                      if (element) {
                        element.style.display = "none";
                      }
                      this.setState({ rightModalSettings: true });
                      if (window.innerWidth < 1009) {
                        this.setState({
                          userInfoRight: false,
                          menuItemsModals: true,
                        });
                      }
                    }}
                  >
                    <img
                      src={menupicpng}
                      style={{ maxWidth: 20, marginTop: 3 }}
                    />
                    {/* {this.getCountComingMainProfile()} */}
                    <div className="notification-bpw">
                      <p>Gegevens</p>
                    </div>
                    {/* <MenuIcon style={{ cursor: "pointer", fill: "white", fontSize: 20 }} /> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {this.state.rightModalSettings && (
            <div
              className={`loaderareaduplicate mcaloader loaderAreaUpdatedNavbar ${this.state.userInfoRight ? "loaderAreaUpdateMustGray" : ""
                } ${this.state.showBhodiFaqs ? "loaderAreaUpdateMustGray" : ""} ${this.state.showBhodiAbouts ? "loaderAreaUpdateMustGray" : ""
                } ${this.state.shopSettings ? "loaderAreaUpdateMustGray" : ""} ${this.state.privacyRight ? "loaderAreaUpdateMustGray" : ""
                } ${this.state.emailPassword ? "loaderAreaUpdateMustGray" : ""} ${this.state.myOrdersRight ? "loaderAreaUpdateMustGray" : ""
                }`}
            >
              {this.state.userInfoRight && (
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                  {!this.state.isDesktop && (
                    <ArrowBackIcon
                      onClick={() => {
                        this.setState({
                          userInfoRight: false,
                          menuItemsModals: true,
                        });
                      }}
                      style={{
                        position: "absolute",
                        top: 15,
                        left: 10,
                        fontSize: 18,
                      }}
                    />
                  )}
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>MIJN GEGEVENS</font>
                    <div>
                      <ChevronLeft
                        onClick={() => {
                          const {
                            first_name,
                            last_name,
                            zipCode,
                            street,
                            houseNumber,
                            country,
                            city,
                            phoneNumber,
                            profileActive
                          } = this.state.currentUserMember;
                          let activatedProfile = false;
                          if (profileActive) {
                            activatedProfile = true
                          } else {
                            activatedProfile = false
                          }
                          if (
                            first_name !== this.state.firstName ||
                            last_name !== this.state.lastName ||
                            zipCode !== this.state.zipCode ||
                            street !== this.state.street ||
                            houseNumber !== this.state.houseNumber ||
                            country !== this.state.country ||
                            city !== this.state.city ||
                            phoneNumber !== this.state.phoneNumber ||
                            activatedProfile !== this.state.profileActive
                          ) {
                            this.setState({ beforeCloseMenuConfirm: true });
                            return false;
                          }
                          this.setState({ userInfoRight: false });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                      <div className="spacing-between-icons"></div>
                      <CloseIcon
                        onClick={() => {
                          let element = document.getElementById("footermain");
                          if (element) {
                            element.style.display = "flex";
                          }
                          this.setState({
                            rightModalSettings: false,
                            privacyRight: false,
                            channelPageRight: false,
                            userInfoRight: false,
                            shopSettings: false,
                            voucherSettings: false,
                            emailPassword: false,
                            myOrdersRight: false,
                            showBhodiFaqs: false,
                            showBhodiAbouts: false,
                          });
                          // this.setState({ userInfoRight: false })
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                          fontSize: 18,
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            width: "90%",
                            margin: "10px auto",
                          }}
                        >
                          {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                MIJN GEGEVENS
                                            </p>
                                        </div> */}
                          <div className="wrapperforfullwidth">
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Voornaam</label>
                                <input
                                  type="text"
                                  value={this.state.firstName}
                                  onChange={(e) =>
                                    this.setState({ firstName: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Achternaam</label>
                                <input
                                  type="text"
                                  value={this.state.lastName}
                                  onChange={(e) =>
                                    this.setState({ lastName: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Straatnaam</label>
                                <input
                                  type="text"
                                  value={this.state.street}
                                  onChange={(e) =>
                                    this.setState({ street: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Huisnummer</label>
                                <input
                                  type="text"
                                  value={this.state.houseNumber}
                                  onChange={(e) =>
                                    this.setState({
                                      houseNumber: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Postcode</label>
                                <input
                                  type="text"
                                  value={this.state.zipCode}
                                  onChange={(e) =>
                                    this.setState({ zipCode: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Plaatsnaam</label>
                                <input
                                  type="text"
                                  value={this.state.city}
                                  onChange={(e) =>
                                    this.setState({ city: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Land</label>
                                <input
                                  type="text"
                                  value={this.state.country}
                                  onChange={(e) =>
                                    this.setState({ country: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Telefoonnummer</label>
                                <input
                                  type="text"
                                  value={this.state.phoneNumber}
                                  onChange={(e) =>
                                    this.setState({
                                      phoneNumber: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "#1C1C1C" }}
                            >
                              <Switch
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                checked={this.state.profileActive}
                                onClick={() => {
                                  this.setState({
                                    profileActive:
                                      !this.state.profileActive,
                                  });
                                }}
                              />
                              {this.state.profileActive ? (
                                <font>Profiel actief</font>
                              ) : (
                                <font>Profiel inactief</font>
                              )}
                            </div>
                            <div
                              className="btn-save-rmcls btnsaveupdateddesktop"
                              style={{ position: "absolute", bottom: -50 }}
                            >
                              {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.saveInfo()
                                }
                              }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                            </div>
                            <div className="delete-account-content" style={{ width: "100%", padding: 5, margin: "20px auto" }}>
                              <p style={{ color: "black", fontWeight: "bold" }}>Het verwijderen van je account betekent:</p>
                              <p style={{ color: "black" }}>Dat je alle gegevens en content in dit account kwijtraakt. Hierna kun je niet meer inloggen met dit account.</p>
                              <div className="red-button-verwijder">
                                {this.state.deleteBtnText !== "SAVE" ? (
                                  <button
                                    style={{ backgroundColor: "red", minWidth: 170 }}
                                  >
                                    <i class="fa fa-spinner fa-spin"></i> DELETING
                                  </button>
                                ) : (
                                  <button
                                    style={{ backgroundColor: "red", minWidth: 170 }}
                                    onClick={() =>
                                      this.setState({ verwijderPopupDel: true })
                                    }
                                  >
                                    Verwijder account
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.subscriptionOfExclusivesRight && (
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
                  {!this.state.isDesktop && (
                    <ArrowBackIcon
                      onClick={() => {
                        this.setState({
                          userInfoRight: false,
                          menuItemsModals: true,
                        });
                      }}
                      style={{
                        position: "absolute",
                        top: 15,
                        left: 10,
                        fontSize: 18,
                      }}
                    />
                  )}
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>MIJN EXCLUSIVES</font>
                    <div>
                      <ChevronLeft
                        onClick={() => {
                          this.setState({ subscriptionOfExclusivesRight: false });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                      <div className="spacing-between-icons"></div>
                      <CloseIcon
                        onClick={() => {
                          let element = document.getElementById("footermain");
                          if (element) {
                            element.style.display = "flex";
                          }
                          this.setState({
                            rightModalSettings: false,
                            subscriptionOfExclusivesRight: false,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                          fontSize: 18,
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            width: "90%",
                            margin: "10px auto",
                          }}
                        >
                          <div
                            className="wrapperforfullwidth"
                            style={{ position: "relative" }}
                          >
                            <MySubcribedExclusives />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.privacyRight && (
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                  {!this.state.isDesktop && (
                    <ArrowBackIcon
                      onClick={() => {
                        this.setState({
                          privacyRight: false,
                          menuItemsModals: true,
                        });
                      }}
                      style={{
                        position: "absolute",
                        top: 15,
                        left: 10,
                        fontSize: 18,
                      }}
                    />
                  )}
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>PRIVACY INSTELLINGEN</font>
                    <div>
                      <ChevronLeft
                        onClick={() => {
                          const { accountType } = this.state.currentUserMember;
                          let checkedTester = false;
                          if (accountType === "global") {
                            checkedTester = false;
                          } else {
                            checkedTester = true;
                          }
                          if (checkedTester !== this.state.checkedPrivate) {
                            this.setState({
                              beforeCloseMenuConfirmPrivacy: true,
                            });
                            return false;
                          }
                          this.setState({ privacyRight: false });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                      <div className="spacing-between-icons"></div>
                      <CloseIcon
                        onClick={() => {
                          let element = document.getElementById("footermain");
                          if (element) {
                            element.style.display = "flex";
                          }
                          this.setState({
                            rightModalSettings: false,
                            privacyRight: false,
                            channelPageRight: false,
                            userInfoRight: false,
                            shopSettings: false,
                            voucherSettings: false,
                            emailPassword: false,
                            myOrdersRight: false,
                            showBhodiFaqs: false,
                            showBhodiAbouts: false,
                          });
                          // this.setState({ privacyRight: false })
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                          fontSize: 18,
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            width: "90%",
                            margin: "10px auto",
                          }}
                        >
                          {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                PRIVACY INSTELLINGEN
                                            </p>
                                        </div> */}
                          <div
                            className="wrapperforfullwidth"
                            style={{ position: "relative" }}
                          >
                            <div
                              className="right-mcls"
                              style={{ color: "#1C1C1C" }}
                            >
                              <Switch
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                checked={this.state.checkedPrivate}
                                onClick={() => {
                                  this.setState({
                                    checkedPrivate: !this.state.checkedPrivate,
                                  });
                                }}
                              />
                              {this.state.checkedPrivate ? (
                                <font>Je account is nu privé</font>
                              ) : (
                                <font>Zet je account op privé</font>
                              )}
                            </div>
                            <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}>
                              {/* <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}> */}
                              {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.savePrivacy()
                                }
                              }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.emailPassword && (
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                  {!this.state.isDesktop && (
                    <ArrowBackIcon
                      onClick={() => {
                        this.setState({
                          emailPassword: false,
                          menuItemsModals: true,
                        });
                      }}
                      style={{
                        position: "absolute",
                        top: 15,
                        left: 10,
                        fontSize: 18,
                      }}
                    />
                  )}
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>INLOGGEGEVENS</font>
                    <div>
                      <ChevronLeft
                        onClick={() => {
                          const { email, user_name_id } =
                            this.state.currentUserMember;
                          if (
                            email !== this.state.email ||
                            user_name_id !== this.state.url ||
                            this.state.currentPassword ||
                            this.state.password ||
                            this.state.confirmPassword
                          ) {
                            this.setState({
                              beforeCloseMenuConfirmInloggevens: true,
                            });
                            return false;
                          }
                          this.setState({ emailPassword: false });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                      <div className="spacing-between-icons"></div>
                      <CloseIcon
                        onClick={() => {
                          let element = document.getElementById("footermain");
                          if (element) {
                            element.style.display = "flex";
                          }
                          this.setState({
                            rightModalSettings: false,
                            privacyRight: false,
                            channelPageRight: false,
                            userInfoRight: false,
                            shopSettings: false,
                            voucherSettings: false,
                            emailPassword: false,
                            myOrdersRight: false,
                            showBhodiFaqs: false,
                            showBhodiAbouts: false,
                          });
                          // this.setState({ emailPassword: false })
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                          fontSize: 18,
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            width: "90%",
                            margin: "10px auto",
                          }}
                        >
                          <div
                            className="wrapperforfullwidth"
                            style={{ position: "relative" }}
                          >
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Gebruikersnaam</label>
                                <input
                                  type="text"
                                  value={this.state.email}
                                  onChange={(e) =>
                                    this.setState({ email: e.target.value })
                                  }
                                />
                                {this.state.emailAlreadyTooked && (
                                  <p style={{ color: "red" }}>
                                    Email is already tooked!
                                  </p>
                                )}
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            ></div>
                            {/* <div
                              className="right-mcls"
                              style={{ color: "white" }}
                            >
                              <div className="form-profile">
                                <label>
                                  Link naar je profielpagina:
                                  www.bhodi.com/member/{this.state.url}
                                </label>
                                <input
                                  type="text"
                                  placeholder="Page url"
                                  value={this.state.url}
                                  onChange={(e) => {
                                    let converted = e.target.value
                                      .split(" ")
                                      .join("");
                                    this.setState({
                                      url: converted.toLowerCase(),
                                    });
                                  }}
                                />
                                {this.state.urlNotAvailable && (
                                  <p style={{ color: "red" }}>
                                    Sorry! this URL is taken
                                  </p>
                                )}
                              </div>
                            </div> */}
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label
                                  style={{ marginBottom: 10, width: "100%" }}
                                >
                                  Wachtwoord wijzigen:{" "}
                                </label>
                                <label>Huidig wachtwoord</label>
                                <div className="toonpasswordcontainer">
                                  <input
                                    type={
                                      !this.state.showcurrentPassword
                                        ? "password"
                                        : "text"
                                    }
                                    value={this.state.currentPassword}
                                    autoComplete="new-password"
                                    onChange={(e) =>
                                      this.setState({
                                        currentPassword: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    style={{ color: "#a9a7a7" }}
                                    onClick={() => {
                                      this.setState({
                                        showcurrentPassword:
                                          !this.state.showcurrentPassword,
                                      });
                                    }}
                                  >
                                    toon
                                  </label>
                                </div>
                                {this.state.fillCurrentPassword && (
                                  <p style={{ color: "red" }}>
                                    Enter your right correct password!
                                  </p>
                                )}
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            ></div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Nieuw wachtwoord</label>
                                <div className="toonpasswordcontainer">
                                  <input
                                    type={
                                      !this.state.showPassword
                                        ? "password"
                                        : "text"
                                    }
                                    value={this.state.password}
                                    autoComplete="new-password"
                                    onChange={(e) =>
                                      this.setState({
                                        password: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    style={{ color: "#a9a7a7" }}
                                    onClick={() => {
                                      this.setState({
                                        showPassword: !this.state.showPassword,
                                      });
                                    }}
                                  >
                                    toon
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            ></div>
                            <div
                              className="right-mcls"
                              style={{ color: "white", margin: 0 }}
                            >
                              <div className="form-profile">
                                <label>Herhaal wachtwoord</label>
                                <div className="toonpasswordcontainer">
                                  <input
                                    type={
                                      !this.state.showconfirmPassword
                                        ? "password"
                                        : "text"
                                    }
                                    value={this.state.confirmPassword}
                                    autoComplete="new-password"
                                    onChange={(e) =>
                                      this.setState({
                                        confirmPassword: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    style={{ color: "#a9a7a7" }}
                                    onClick={() => {
                                      this.setState({
                                        showconfirmPassword:
                                          !this.state.showconfirmPassword,
                                      });
                                    }}
                                  >
                                    toon
                                  </label>
                                </div>
                                {this.state.changedPassword && (
                                  <p style={{ color: "red" }}>
                                    Password and Confirm Password should be same
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}>
                              {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.saveEmailPassword()
                                }
                              }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.showBhodiFaqs && (
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>VEELGESTELDE VRAGEN</font>
                    <div>
                      {!this.state.isDesktop && (
                        <ArrowBackIcon
                          onClick={() => {
                            this.setState({
                              emailPassword: false,
                              menuItemsModals: true,
                            });
                          }}
                          style={{
                            position: "absolute",
                            top: 15,
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                      )}
                      <div className="spacing-between-icons"></div>
                      <ChevronLeft
                        onClick={() => {
                          this.setState({ showBhodiFaqs: false });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                      <div className="spacing-between-icons"></div>
                      <CloseIcon
                        onClick={() => {
                          let element = document.getElementById("footermain");
                          if (element) {
                            element.style.display = "flex";
                          }
                          this.setState({
                            rightModalSettings: false,
                            privacyRight: false,
                            channelPageRight: false,
                            userInfoRight: false,
                            shopSettings: false,
                            voucherSettings: false,
                            emailPassword: false,
                            myOrdersRight: false,
                            showBhodiFaqs: false,
                            showBhodiAbouts: false,
                          });
                          // this.setState({ showBhodiFaqs: false })
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                          fontSize: 18,
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            width: "90%",
                            margin: "10px auto",
                          }}
                        >
                          {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VEELGESTELDE VRAGEN
                                            </p>
                                        </div> */}
                          <div
                            className="wrapperforfullwidth wrapperbhodifaqs"
                            style={{ position: "relative" }}
                          >
                            {this.state.bhodiFaqs.length > 0 &&
                              this.state.bhodiFaqs.map((y, i) => {
                                if (y.title) {
                                  return (
                                    <div className="faq-inner-main">
                                      <div
                                        className="top-faq-rmcls"
                                        onClick={() => {
                                          if (y.desc) {
                                            this.state.arrDropdowns[i].opened =
                                              !this.state.arrDropdowns[i]
                                                .opened;
                                            this.setState({
                                              arrDropdowns:
                                                this.state.arrDropdowns,
                                            });
                                          }
                                        }}
                                      >
                                        <p>{y.title}</p>
                                        {this.state.arrDropdowns[i].opened
                                          ? y.desc && <RemoveIcon />
                                          : y.desc && <AddIcon />}
                                      </div>
                                      {this.state.arrDropdowns[i].opened && (
                                        <div className="bottom-faq-rmcls">
                                          {/* <p>{y.desc}</p> */}
                                          <div
                                            dangerouslySetInnerHTML={this.createMarkup(
                                              y.desc
                                            )}
                                          ></div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.showBhodiAbouts && (
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>ABOUT</font>
                    <div>
                      {!this.state.isDesktop && (
                        <ArrowBackIcon
                          onClick={() => {
                            this.setState({
                              showBhodiAbouts: false,
                              menuItemsModals: true,
                            });
                          }}
                          style={{
                            position: "absolute",
                            top: 15,
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                      )}
                      <div className="spacing-between-icons"></div>
                      {!this.state.openedFromPropsAbout && (
                        <ChevronLeft
                          onClick={() => {
                            if (this.props.closeAboutBhodiRightMenu) {
                              this.props.closeAboutBhodiRightMenu();
                              if (this.props.doShowMenuRightAbout) {
                                setTimeout(() => {
                                  this.setState({ showBhodiAbouts: false });
                                }, 1000);
                                return false;
                              } else {
                                this.setState({ showBhodiAbouts: false });
                              }
                            }
                            this.setState({ showBhodiAbouts: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                      )}
                      <div className="spacing-between-icons"></div>
                      <CloseIcon
                        onClick={() => {
                          if (this.props.closeAboutBhodiRightMenu) {
                            this.props.closeAboutBhodiRightMenu();
                            if (this.props.doShowMenuRightAbout) {
                              setTimeout(() => {
                                this.setState({ showBhodiAbouts: false });
                                if (this.state.openedFromPropsAbout) {
                                  let element =
                                    document.getElementById("footermain");
                                  if (element) {
                                    element.style.display = "flex";
                                  }
                                  this.setState({
                                    rightModalSettings: false,
                                    openedFromPropsAbout: false,
                                    menuItemsModals: false,
                                    showBhodiAbouts: false,
                                  });
                                }
                              }, 400);
                              return false;
                            } else {
                              let element = document.getElementById("footermain");
                              if (element) {
                                element.style.display = "flex";
                              }
                              this.setState({
                                rightModalSettings: false,
                                privacyRight: false,
                                channelPageRight: false,
                                userInfoRight: false,
                                shopSettings: false,
                                voucherSettings: false,
                                emailPassword: false,
                                myOrdersRight: false,
                                showBhodiFaqs: false,
                                showBhodiAbouts: false,
                              });
                              // this.setState({ showBhodiAbouts: false })
                            }
                          }
                          if (this.state.openedFromPropsAbout) {
                            this.setState({
                              rightModalSettings: false,
                              openedFromPropsAbout: false,
                              menuItemsModals: false,
                              showBhodiAbouts: false,
                            });
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                          }
                          this.setState({ showBhodiAbouts: false });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                          fontSize: 18,
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            width: "90%",
                            margin: "10px auto",
                          }}
                        >
                          {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VEELGESTELDE VRAGEN
                                            </p>
                                        </div> */}
                          <div
                            className="wrapperforfullwidth wrapperbhodifaqs"
                            style={{ position: "relative" }}
                          >
                            {this.state.bhodiAbouts.length > 0 &&
                              this.state.bhodiAbouts.map((y, i) => {
                                if (y.title) {
                                  return (
                                    <div className="faq-inner-main">
                                      <div
                                        className="top-faq-rmcls"
                                        onClick={() => {
                                          if (y.desc) {
                                            this.state.arrDropdownsAbouts[
                                              i
                                            ].opened =
                                              !this.state.arrDropdownsAbouts[i]
                                                .opened;
                                            this.setState({
                                              arrDropdownsAbouts:
                                                this.state.arrDropdownsAbouts,
                                            });
                                          }
                                        }}
                                      >
                                        <p>{y.title}</p>
                                        {this.state.arrDropdownsAbouts[i].opened
                                          ? y.desc && <RemoveIcon />
                                          : y.desc && <AddIcon />}
                                      </div>
                                      {this.state.arrDropdownsAbouts[i]
                                        .opened && (
                                          <div className="bottom-faq-rmcls">
                                            {/* <p>{y.desc}</p> */}
                                            <div
                                              dangerouslySetInnerHTML={this.createMarkup(
                                                y.desc
                                              )}
                                            ></div>
                                            {y.image && <img src={y.image} />}
                                          </div>
                                        )}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.isDesktop &&
                !this.state.userInfoRight &&
                !this.state.channelPageRight &&
                !this.state.subscriptionOfExclusivesRight &&
                !this.state.privacyRight &&
                !this.state.emailPassword &&
                !this.state.showBhodiAbouts &&
                !this.state.showBhodiFaqs &&
                !this.state.voucherSettings && (
                  <div
                    className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
                    style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
                  >
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>PROFIELINSTELLINGEN</font>
                      <CloseIcon
                        onClick={() => {
                          let element = document.getElementById("footermain");
                          if (element) {
                            element.style.display = "flex";
                          }
                          if (this.props.closeAboutBhodiRightMenu) {
                            this.props.closeAboutBhodiRightMenu();
                          }
                          this.setState({
                            rightModalSettings: false,
                            showBhodiAbouts: false,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                          fontSize: 18,
                        }}
                      />
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings rmclswithiconsbordered">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                              backgroundColor: "transparent",
                              height: "auto",
                            }}
                          >
                            {/* <div className="right-mcls imageinnermca" style={{ color: "white", position: "relative", maxWidth: 70, margin: "0px auto" }}>
                                            {this.state.profileImageUrl ? <img src={this.state.profileImageUrl} /> : <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />}
                                            <div className="imginnermcaadd">
                                                <AddIcon />
                                            </div>
                                            <input type="file" accept="image/*" onChange={(e) => this.fileUpload(e)} />
                                        </div>
                                        <div className="right-mcls imageinnermca" style={{ color: "white" }}>
                                            <font>{this.state.firstName} {this.state.lastName}</font>
                                        </div> */}
                            <div className="top-mca-updatedlist topmcaupdatediconsdo" style={{ marginBottom: 8 }}>
                              <div
                                className="right-mcls imageinnermca"
                                style={{
                                  color: "white",
                                  position: "relative",
                                  maxWidth: 70,
                                  margin: "0px auto",
                                }}
                              >
                                {this.state.currentUserMember.profile_image ? (
                                  <img
                                    src={this.state.currentUserMember.profile_image}
                                    style={{ width: 30, height: 30 }}
                                  />
                                ) : (
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                                    style={{ width: 30, height: 30 }}
                                  />
                                )}
                                <div className="imginnermcaadd" style={{ top: -3 }}>
                                  <AddIcon />
                                </div>
                                {/* <input type="file" accept="image/*" onChange={(e) => this.fileUpload(e)} /> */}
                              </div>
                              <div
                                className="right-mcls imageinnermca"
                                style={{ color: "white" }}
                              >
                                <font>Profielfoto</font>
                              </div>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => this.fileUpload(e)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  left: 0,
                                  bottom: 0,
                                  cursor: "pointer",
                                  opacity: 0,
                                }}
                              />
                            </div>
                            {/* <div
                              className="right-mcls"
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <div className="captionvoucherstuff charityimageonvoucher"
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    subscriptionOfExclusivesRight: true,
                                  });
                                }}>
                                <TbFolderStar />
                              </div>
                              <font
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    subscriptionOfExclusivesRight: true,
                                  });
                                }}
                              >
                                Mijn exclusives
                              </font>
                            </div> */}
                            <div
                              className={`right-mcls ${this.state.userInfoRight && "active"
                                }`}
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <div className="captionvoucherstuff charityimageonvoucher"
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    userInfoRight: true,
                                    privacyRight: false,
                                    emailPassword: false,
                                    shopSettings: false,
                                    myOrdersRight: false,
                                    showSubscribedContent: false,
                                    showBhodiFaqs: false,
                                    showBhodiAbouts: false,
                                  });
                                }}>
                                <RiUserSettingsLine />
                              </div>
                              <font
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    userInfoRight: true,
                                    privacyRight: false,
                                    emailPassword: false,
                                    shopSettings: false,
                                    myOrdersRight: false,
                                    showSubscribedContent: false,
                                    showBhodiFaqs: false,
                                    showBhodiAbouts: false,
                                  });
                                }}
                              >
                                Mijn gegevens
                              </font>
                            </div>
                            {/* <div
                              className={`right-mcls ${this.state.privacyRight && "active"
                                }`}
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <div className="captionvoucherstuff charityimageonvoucher"
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    privacyRight: true,
                                    userInfoRight: false,
                                    emailPassword: false,
                                    shopSettings: false,
                                    myOrdersRight: false,
                                    showSubscribedContent: false,
                                    showBhodiFaqs: false,
                                    showBhodiAbouts: false,
                                  });
                                }}>
                                <MdOutlinePrivacyTip />
                              </div>
                              <font
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    privacyRight: true,
                                    userInfoRight: false,
                                    emailPassword: false,
                                    shopSettings: false,
                                    myOrdersRight: false,
                                    showSubscribedContent: false,
                                    showBhodiFaqs: false,
                                    showBhodiAbouts: false,
                                  });
                                }}
                              >
                                Privacy instellingen
                              </font>
                            </div> */}
                            <div
                              className={`right-mcls ${this.state.emailPassword && "active"
                                }`}
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <div className="captionvoucherstuff charityimageonvoucher"
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    emailPassword: true,
                                    privacyRight: false,
                                    userInfoRight: false,
                                    shopSettings: false,
                                    myOrdersRight: false,
                                    showSubscribedContent: false,
                                    showBhodiFaqs: false,
                                    showBhodiAbouts: false,
                                  });
                                }}>
                                <RiGitRepositoryPrivateLine />
                              </div>
                              <font
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    emailPassword: true,
                                    privacyRight: false,
                                    userInfoRight: false,
                                    shopSettings: false,
                                    myOrdersRight: false,
                                    showSubscribedContent: false,
                                    showBhodiFaqs: false,
                                    showBhodiAbouts: false,
                                  });
                                }}
                              >
                                Inloggegevens
                              </font>
                            </div>
                            {this.state.bhodiFaqs.length > 0 && (
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      myOrdersRight: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      menuItemsModals: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showBhodiFaqs: true,
                                      showSubscribedContent: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}>
                                  <FaQ />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      myOrdersRight: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      menuItemsModals: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showBhodiFaqs: true,
                                      showSubscribedContent: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}
                                >
                                  FAQ
                                </font>
                              </div>
                            )}
                            {this.state.bhodiAbouts.length > 0 && (
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    this.setState({
                                      myOrdersRight: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      menuItemsModals: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: true,
                                      showSubscribedContent: false,
                                    });
                                  }}>
                                  <TiInfo />
                                </div>
                                <font
                                  onClick={() => {
                                    this.setState({
                                      myOrdersRight: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      menuItemsModals: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: true,
                                      showSubscribedContent: false,
                                    });
                                  }}
                                >
                                  About
                                </font>
                              </div>
                            )}
                            <div
                              className="right-mcls logout-mcls"
                              style={{ color: "white" }}
                            >
                              <ExitToAppIcon onClick={this.logout.bind(this)} />
                              <font onClick={this.logout.bind(this)}>
                                Uitloggen
                              </font>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
          {/* {this.state.rightModalAbout && (
            <div className="loader-area mcaloader">
              <div className="modal-create-album" style={{ width: 400 }}>
                <div
                  className="heading-mca"
                  style={{
                    textAlign: "center",
                    position: "relative",
                    padding: "13px",
                  }}
                >
                  <font>ABOUT</font>
                  <CloseIcon
                    onClick={() => {
                      this.setState({ rightModalAbout: false });
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: 10,
                      fontSize: 24,
                    }}
                  />
                </div>
                <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          height: "auto",
                        }}
                      >
                        <div
                          className="right-mcls imageinnermca"
                          style={{
                            color: "white",
                            position: "relative",
                            maxWidth: 70,
                            margin: "0px auto",
                          }}
                        >
                          {this.state.currentUserMember.profile_image ? (
                            <img
                              src={this.state.currentUserMember.profile_image}
                            />
                          ) : (
                            <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                          )}
                        </div>
                        {this.state.userNameInput ? (
                          <div
                            className="right-mcls imageinnermca"
                            style={{
                              color: "white",
                              flexDirection: "column",
                              width: "83%",
                              margin: "0px auto",
                              marginTop: 30,
                            }}
                          >
                            <div
                              className="form-profile"
                              style={{ textAlign: "left" }}
                            >
                              <label style={{ fontWeight: "initial" }}>
                                Voornaam
                              </label>
                              <input
                                type="text"
                                value={this.state.currentUserMember.first_name}
                                style={{ position: "static", opacity: 1 }}
                                onChange={(e) => {
                                  this.state.currentUserMember.first_name =
                                    e.target.value;
                                  this.setState({
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                  let userid = localStorage.getItem("userid");
                                  firebase
                                    .database()
                                    .ref("users/" + userid + "/first_name")
                                    .set(e.target.value);
                                }}
                                onBlur={() => {
                                  this.setState({ userNameInput: false });
                                }}
                              />
                            </div>
                            <div
                              className="form-profile"
                              style={{ textAlign: "left" }}
                            >
                              <label style={{ fontWeight: "initial" }}>
                                Achternaam
                              </label>
                              <input
                                type="text"
                                value={this.state.currentUserMember.last_name}
                                style={{ position: "static", opacity: 1 }}
                                onChange={(e) => {
                                  this.state.currentUserMember.last_name =
                                    e.target.value;
                                  this.setState({
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                  let userid = localStorage.getItem("userid");
                                  firebase
                                    .database()
                                    .ref("users/" + userid + "/last_name")
                                    .set(e.target.value);
                                }}
                                onBlur={() => {
                                  this.setState({ userNameInput: false });
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="right-mcls imageinnermca"
                            style={{
                              color: "white",
                              width: "83%",
                              margin: "0px auto",
                              marginTop: 30,
                            }}
                            onClick={() =>
                              this.setState({ userNameInput: true })
                            }
                          >
                            <font>
                              {this.state.currentUserMember.first_name}{" "}
                              {this.state.currentUserMember.last_name}
                            </font>{" "}
                            <EditIcon
                              style={{
                                position: "static",
                                fontSize: 16,
                                margin: "0px 15px",
                              }}
                            />
                          </div>
                        )}
                        {this.state.currentUserMember.user_description && (
                          <div
                            className="top-mcls-one tcosmallfift"
                            style={{
                              marginBottom: 20,
                              paddingBottom: 20,
                              width: "83%",
                              marginTop: 20,
                            }}
                          >
                            <p>
                              {this.state.currentUserMember.user_description}
                            </p>
                          </div>
                        )}
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <EventAvailableIcon
                            style={{ position: "static", fontSize: 24 }}
                          />
                          <font>
                            Member sinds{" "}
                            {this.state.currentUserMember.created_on}{" "}
                          </font>
                        </div>
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <LocationOnIcon
                            style={{ position: "static", fontSize: 24 }}
                          />
                          <font>
                            {this.state.currentUserMember.city}{" "}
                            {this.state.currentUserMember.country}
                          </font>
                        </div>
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <PersonIcon
                            style={{ position: "static", fontSize: 24 }}
                          />
                          <font>
                            {
                              this.state.currentUserMember.objectedFriends
                                .length
                            }{" "}
                            vrienden
                          </font>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}


          {/* Order Page popups */}
          <div
            className="verzilver-popup-white-opacity"
            style={{
              opacity: this.state.verzilverPopupWebShop ? 1 : 0,
              pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
            }}
          ></div>
          <div
            className="verzilver-popup-white"
            style={{
              opacity: this.state.verzilverPopupWebShop ? 1 : 0,
              pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
            }}
          >
            <CloseIcon
              className="close-hpw"
              onClick={() =>
                this.setState({
                  verzilverPopupWebShop: false,
                  currentUserOfPopups: "",
                  redeem_code: "",
                })
              }
            />
            <LockOpenIcon className="lock-hpw" />
            <p className="heading-hpw">Yes, gelukt!</p>
            <p className="desc-hpw">
              Kopieer de onderstaande kortingscode
              <br />
              en vul deze in op onze webshop. Bij het
              <br />
              afrekenen wordt deze verwerkt.
              <br />
              <br />
              Kopieer code:
            </p>
            <p>
              <b>{this.state.redeem_code}</b>
            </p>
            {this.state.redeem_code && this.state.copiedTextDone ? (
              <button
                style={{ backgroundColor: "#C17F6B" }}
                onClick={this.copyToClipboard.bind(
                  this,
                  this.state.redeem_code
                )}
              >
                Gekopieerd
              </button>
            ) : (
              this.state.redeem_code && (
                <button
                  onClick={this.copyToClipboard.bind(
                    this,
                    this.state.redeem_code
                  )}
                >
                  Kopiëren
                </button>
              )
            )}
            {/* {this.state.copiedTextDone && <div className='copiedstatusshower'>
                        <p style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Copied</p>
                    </div>} */}
          </div>


          {this.state.ordersRightMenu && (
            <div
              className="bg-overlaymini"
              onClick={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
            ></div>
          )}
          {this.state.ordersRightMenu && (
            <RightMenuOrders
              closePopup={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });

              }}
              showVerzilverPopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverLastMinutePopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverCharityPopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverGiveawayPopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverWebShopPopup={(e, redeem_code) => {
                this.setState({
                  verzilverPopupWebShop: true,
                  currentUserOfPopups: e,
                  redeem_code,
                });
              }}
            />
          )}
          <Dialog
            open={this.state.cancelSubscriptionPopup}
            onClose={() => this.setState({ cancelSubscriptionPopup: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd"
          >
            <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Je bent nog Charity Member.
                <br />
                <br />
                Om je account te verwijderen zul je eerst<br />
                je maandelijkse bijdrage moeten stopzetten.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => {
                  this.setState({
                    cancelSubscriptionPopup: false,
                    showDelete: true,
                  });
                  axios
                    .post(
                      "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
                      {
                        subscriptionId:
                          this.state.currentUserMember.subscription_id,
                        customerId: this.state.currentUserMember.stripe_id,
                      }
                    )
                    .then(() => {
                      axios
                        .post(
                          "https://us-central1-bhodi-7c327.cloudfunctions.net/deleteStripeCustomer",
                          {
                            customerId: this.state.currentUserMember.stripe_id,
                          }
                        )
                        .then(() => {
                          this.deleteCurrentUser();
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
                className="stopplan-button"
              >
                BIJDRAGE STOPPEN
              </button>
              <Button
                onClick={() =>
                  this.setState({ cancelSubscriptionPopup: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
            </DialogActions>
          </Dialog>

          {/* Valid dialogs */}
          <Dialog
            open={this.state.validOnMobilePopup}
            onClose={() => this.setState({ validOnMobilePopup: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Het is alleen mogelijk om te
                <br />
                valideren via mobiele telefoon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ validOnMobilePopup: false })}
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.beforeCloseMenuConfirm}
            onClose={() => this.setState({ beforeCloseMenuConfirm: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ beforeCloseMenuConfirm: false })}
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirm: false,
                    userInfoRight: false,
                  });
                  this.saveInfo();
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  // const { first_name, last_name, zipCode, street, houseNumber, country, city, phoneNumber } = this.state.currentUserMember
                  this.setState({
                    beforeCloseMenuConfirm: false,
                    // userInfoRight: false,
                    // firstName: first_name,
                    // lastName: last_name,
                    // zipCode,
                    // street,
                    // houseNumber,
                    // country,
                    // city,
                    // phoneNumber
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const {
                    first_name,
                    last_name,
                    zipCode,
                    street,
                    houseNumber,
                    country,
                    city,
                    profileActive,
                    phoneNumber,
                  } = this.state.currentUserMember;
                  this.setState({
                    beforeCloseMenuConfirm: false,
                    userInfoRight: false,
                    firstName: first_name,
                    lastName: last_name,
                    zipCode,
                    street,
                    houseNumber,
                    country,
                    city,
                    phoneNumber,
                  });
                  if (profileActive) {
                    this.setState({ profileActive: true })
                  } else {
                    this.setState({ profileActive: false })
                  }
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
              {/* <Button onClick={() => this.setState({ beforeCloseMenuConfirm: false })} color="primary">SAVE</Button> */}
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.beforeCloseMenuConfirmPrivacy}
            onClose={() =>
              this.setState({ beforeCloseMenuConfirmPrivacy: false })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ beforeCloseMenuConfirmPrivacy: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmPrivacy: false,
                    privacyRight: false,
                  });
                  this.savePrivacy();
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  // const { accountType } = this.state.currentUserMember
                  // let checkedTester = false
                  // if (accountType === "global") {
                  //     checkedTester = false
                  // } else {
                  //     checkedTester = true
                  // }
                  this.setState({
                    beforeCloseMenuConfirmPrivacy: false,
                    // privacyRight: false,
                    // checkedPrivate: checkedTester
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const { accountType } = this.state.currentUserMember;
                  let checkedTester = false;
                  if (accountType === "global") {
                    checkedTester = false;
                  } else {
                    checkedTester = true;
                  }
                  this.setState({
                    beforeCloseMenuConfirmPrivacy: false,
                    privacyRight: false,
                    checkedPrivate: checkedTester,
                  });
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.beforeCloseMenuConfirmInloggevens}
            onClose={() =>
              this.setState({ beforeCloseMenuConfirmInloggevens: false })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ beforeCloseMenuConfirmInloggevens: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmInloggevens: false,
                    // emailPassword: false,
                  });
                  this.saveEmailPassword();
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  // const { email, user_name_id } = this.state.currentUserMember
                  this.setState({
                    beforeCloseMenuConfirmInloggevens: false,
                    // emailPassword: false,
                    // email: email,
                    // url: user_name_id,
                    // currentPassword: "",
                    // password: "",
                    // confirmPassword: "",
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const { email, user_name_id } = this.state.currentUserMember;
                  this.setState({
                    beforeCloseMenuConfirmInloggevens: false,
                    emailPassword: false,
                    email: email,
                    url: user_name_id,
                    currentPassword: "",
                    password: "",
                    confirmPassword: "",
                  });
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
            </DialogActions>
          </Dialog>

          {this.state.verwijderPopupDel && (
            <div className="overlay-delete-account">
              <div className="inner-odacc">
                <div className="left-iodacc"></div>
                <div className="right-iodacc">
                  <CloseIcon
                    onClick={() => this.setState({ verwijderPopupDel: false })}
                  />
                  <h4>ACCOUNT VERWIJDEREN</h4>
                  {/* <p>
                    Je staat op het punt om je Bhodi account te<br />
                    verwijderen. Je gegevens worden verwijdert en<br />
                    hierna kun je niet meer op je account inloggen.
                    <br />
                    <br />
                    We zullen je missen, maar wellicht zien we<br />
                    je in de toekomst weer terug op Bhodi!
                  </p> */}
                  {this.state.showDelete ? (<p>
                    Je staat op het punt om je account<br />
                    te verwijderen. Hierna kun je niet<br />
                    meer inloggen met dit account.
                    <br />
                    <br />
                    Voer je wachtwoord opnieuw in<br />
                    om je account te verwijderen.
                    {/* We want to get know your account password in order to delete your account. */}
                  </p>) : (<p>
                    Je staat op het punt om je account<br />
                    te verwijderen. Hierna kun je niet<br />
                    meer inloggen met dit account. Je<br />
                    maandelijkse bijdrage wordt ook<br />
                    automatisch stopgezet.
                    <br />
                    <br />
                    Voer je wachtwoord opnieuw in<br />
                    om je account te verwijderen.
                  </p>)}
                  <div className="input-riodacc">
                    <label>Jouw  wachtwoord</label>
                    <input type="password" value={this.state.passwordForDel} onChange={(e) => {
                      this.setState({ passwordForDel: e.target.value })
                    }} />
                    {this.state.passwordRequiredDel && (
                      <label style={{ color: "red" }}>Please enter password to delete</label>
                    )}
                    {this.state.passwordWrongDel && (
                      <label style={{ color: "red" }}>Please enter correct password</label>
                    )}
                  </div>
                  {this.state.deleteBtnText !== "SAVE" ? (
                    <button>DELETING</button>
                  ) : (
                    <button onClick={this.deleteCurrentUser.bind(this)}>
                      VERWIJDEREN
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}


          {this.state.showFiltersModal && (<div className="loaderareaduplicate mcaloader loaderAreaUpdatedNavbar ">
            <div
              className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
              style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
            >
              <div
                className="heading-mca"
                style={{
                  textAlign: "center",
                  position: "relative",
                  padding: "13px",
                }}
              >
                <font>FILTERS</font>
                <CloseIcon
                  onClick={() => {
                    this.setState({ showFiltersModal: false });
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 10,
                    fontSize: 18,
                  }}
                />
              </div>
              <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                <div className="form-body-mca">
                  <div className="inner-mca-settings rmclswithiconsbordered">
                    <div
                      className="mca-settings-list"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        backgroundColor: "transparent",
                        height: "auto",
                      }}
                    >
                      <div
                        className="right-mcls"
                        style={{ color: "white", paddingLeft: 20 }}
                      >
                        <font
                          onClick={() => {
                            history.push(`/global-voucher-events/${this.props.sector}`)
                          }}
                        >
                          Alle events
                        </font>
                      </div>
                      {this.state.currentWeekEvents.length > 0 && (
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <font
                            onClick={() => {
                              history.push(`/global-voucher-events/${this.props.sector}/filter/currentWeek`)
                            }}
                          >
                            Deze week
                          </font>
                        </div>
                      )}
                      {this.state.comingWeekEvents.length > 0 && (
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <font
                            onClick={() => {
                              history.push(`/global-voucher-events/${this.props.sector}/filter/comingWeek`)
                            }}
                          >
                            Volgende week
                          </font>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}

          {this.state.showFiltersModalVouchers && (<div className="loaderareaduplicate mcaloader loaderAreaUpdatedNavbar ">
            <div
              className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
              style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
            >
              <div
                className="heading-mca"
                style={{
                  textAlign: "center",
                  position: "relative",
                  padding: "13px",
                }}
              >
                <font>FILTERS</font>
                <CloseIcon
                  onClick={() => {
                    this.setState({ showFiltersModalVouchers: false });
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 10,
                    fontSize: 18,
                  }}
                />
              </div>
              <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                <div className="form-body-mca">
                  <div className="inner-mca-settings rmclswithiconsbordered">
                    <div
                      className="mca-settings-list"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        backgroundColor: "transparent",
                        height: "auto",
                      }}
                    >
                      {this.state.allCategories.length > 0 &&
                        this.state.allCategories.map((e) => {
                          if (
                            this.state.availableMainCategories.includes(e.category_id)
                          ) {
                            return (
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <font
                                  onClick={() => {
                                    this.setState({ showFiltersModalVouchers: false });

                                    localStorage.setItem("subcategoryidProduct", e.category_id);
                                    history.push(`/global-vouchers/${this.props.sector}/filterbyCategory/${e.category_id}`)
                                  }}
                                >
                                  {e.title}
                                </font>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}

        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    global_campaigns: state.campaignsReducer.global_campaigns,
    guest_user_cart: state.cartReducer.guest_user_cart,
    bhodi_abouts: state.globalReducers.bhodi_abouts,
    bhodi_faqs: state.globalReducers.bhodi_faqs,
    message: state.authReducers.message,
    bhodi_products: state.productsReducers.bhodi_products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMoreAlbumsAdded: (morealbumsToAdd) =>
      dispatch(updateMoreAlbumsAdded(morealbumsToAdd)),
    updateSelectedExclusive: (selected_exclusive) =>
      dispatch(updateSelectedExclusive(selected_exclusive)),
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
    updateBhodiAbouts: (bhodi_abouts) => dispatch(updateBhodiAbouts(bhodi_abouts)),
    updateBhodiFaqs: (bhodi_faqs) => dispatch(updateBhodiFaqs(bhodi_faqs)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarMainFullWidth);
