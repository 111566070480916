import React, { Component } from "react";
import logoheader from "../../assets/logoheaderlifestyleblack.png";
import logoheaderWhite from "../../assets/logoheaderlifestyle.png";
import "./index.css";
import { GiHotMeal } from "react-icons/gi";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiDoubleQuotesR } from "react-icons/ri";
import { AiFillGift } from "react-icons/ai";
import MenuIcon from "@material-ui/icons/Menu";
import swal from "sweetalert";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase";
import countries from "../../DataJSON/countries.json";
import Switch from "@material-ui/core/Switch";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { connect } from "react-redux";
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../Redux/actions/authActions";
import CheckIcon from "@material-ui/icons/Check";
import FooterBasket from "../../Components/FooterBasket";
import PersonIcon from "@material-ui/icons/Person";
import Footer from "../../Components/Footer";
import axios from "axios";
import FooterGreen from "../../Components/FooterGreen";
import { Link } from "react-router-dom";

AOS.init();

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class LoginAdmin extends Component {
    constructor() {
        super();
        this.state = {
            // events: [
            //     {
            //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/denham.jpg",
            //         companyName: "Company name",
            //         fashionLabel: "Fashion label"
            //     },
            //     {
            //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/butcher.jpg",
            //         companyName: "Company name",
            //         fashionLabel: "Fashion label"
            //     },
            //     {
            //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/boss.jpg",
            //         companyName: "Company name",
            //         fashionLabel: "Fashion label"
            //     },
            //     {
            //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/catwalkjunkie.jpg",
            //         companyName: "Company name",
            //         fashionLabel: "Fashion label"
            //     }
            // ],
            showDutch: true,
            events: [],
            menuOpen: false,
            allUsers: [],
            country: "155",
            isUserForm: true,
            showCharityStuff: false,
            subscription_data: [],
            plan_id: "",
            plan_amount: "",
            // registration fields
            firstName: "",
            firstNamePopup: false,
            lastName: "",
            lastNamePopup: false,
            email: "",
            emailPopup: false,
            password: "",
            passwordPopup: false,
            country: "155",
            companyRegistration: false,
            companyName: "",
            kvkCompany: "",
            kvkCompanyPopup: false,
            street: "",
            streetPopup: false,
            houseNumber: "",
            houseNumberPopup: false,
            city: "",
            cityPopup: false,
            zipCode: "",
            zipCodePopup: false,
            phoneNumber: "",
            phoneNumberPopup: false,
            company_website: "",
            company_websitePopup: false,
            passwordInputType: "password",
            guestUserCart: [],
            aanmeldenloader: false,
            allUsers: [],
            agreed: false,
            agreedPopup: false,
            currentUserSpecific: "",
            isUserCreated: false,
            currentDataOfUser: "",
            popupopenforright: true,
            isAdmin: false,
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        firebase
            .database()
            .ref("users")
            .once("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a && !this.state.stopTakingData) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        arr.push(u[1]);
                    });
                    this.setState({ allUsers: arr });
                } else {
                    this.setState({ allUsers: [] });
                }
            });

        let isAdmin = this.props.admin_boolean;
        if (isAdmin) {
            this.setState({ isAdmin: true });
        } else {
            this.setState({ isAdmin: false });
        }

        let res = axios.get(
            "https://us-central1-bhodi-7c327.cloudfunctions.net/getIpLocation"
        );
        res.then((output) => {
            if (output.data.deta) {
                if (output.data.deta.country === "NL") {
                    this.setState({ showDutch: true });
                } else {
                    this.setState({ showDutch: false });
                }
            }
        });

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    aboutContent: a.about_text,
                    salesContent: a.sales_text,
                    charityContent: a.charity_text,
                    socialsContent: a.socials_text,
                    profilesContent: a.profiles_text,
                    registrationList1: a.registration_list_1,
                    registrationList2: a.registration_list_2,
                    registrationList3: a.registration_list_3,
                    registrationList4: a.registration_list_4,
                    loginList1: a.login_list_1,
                    loginList2: a.login_list_2,
                    loginList3: a.login_list_3,
                    socialsList1: a.socials_list_1,
                    socialsList2: a.socials_list_2,
                    socialsList3: a.socials_list_3,
                    socialsList4: a.socials_list_4,
                    socialsList5: a.socials_list_5,
                    socialsList6: a.socials_list_6,
                    profileList1: a.profile_list_1,
                    profileList2: a.profile_list_2,
                    profileList3: a.profile_list_3,
                    profileList4: a.profile_list_4,
                    profileList5: a.profile_list_5,
                    profileList6: a.profile_list_6,

                    reg_login_about_dutch_heading: a.reg_login_about_dutch_heading,
                    reg_login_about_english_heading: a.reg_login_about_english_heading,
                    reg_login_team_bhodi_dutch_heading:
                        a.reg_login_team_bhodi_dutch_heading,
                    reg_login_team_bhodi_english_heading:
                        a.reg_login_team_bhodi_english_heading,
                    reg_login_about_dutch: a.reg_login_about_dutch,
                    reg_login_about_english: a.reg_login_about_english,
                    reg_login_team_bhodi_dutch: a.reg_login_team_bhodi_dutch,
                    reg_login_team_bhodi_english: a.reg_login_team_bhodi_english,
                    reg_login_about_dutch_heading_mobile:
                        a.reg_login_about_dutch_heading_mobile,
                    reg_login_about_english_heading_mobile:
                        a.reg_login_about_english_heading_mobile,
                    reg_login_team_bhodi_dutch_heading_mobile:
                        a.reg_login_team_bhodi_dutch_heading_mobile,
                    reg_login_team_bhodi_english_heading_mobile:
                        a.reg_login_team_bhodi_english_heading_mobile,
                    reg_login_about_dutch_mobile: a.reg_login_about_dutch_mobile,
                    reg_login_about_english_mobile: a.reg_login_about_english_mobile,
                    reg_login_team_bhodi_dutch_mobile:
                        a.reg_login_team_bhodi_dutch_mobile,
                    reg_login_team_bhodi_english_mobile:
                        a.reg_login_team_bhodi_english_mobile,
                });
                if (a.about_image) {
                    this.setState({ aboutImage: a.about_image, aboutVideo: "" });
                } else {
                    this.setState({ aboutVideo: a.about_video, aboutImage: "" });
                }
                if (a.sales_image) {
                    this.setState({ salesImage: a.sales_image, salesVideo: "" });
                } else {
                    this.setState({ salesVideo: a.sales_video, salesImage: "" });
                }
                if (a.charity_image) {
                    this.setState({ charityImage: a.charity_image, charityVideo: "" });
                } else {
                    this.setState({ charityVideo: a.charity_video, charityImage: "" });
                }
                if (a.socials_image) {
                    this.setState({ socialsImage: a.socials_image, socialsVideo: "" });
                } else {
                    this.setState({ socialsVideo: a.socials_video, socialsImage: "" });
                }
                if (a.profiles_image) {
                    this.setState({ profilesImage: a.profiles_image, profilesVideo: "" });
                } else {
                    this.setState({ profilesVideo: a.profiles_video, profilesImage: "" });
                }
                if (a.main_image) {
                    this.setState({ mainImage: a.main_image, mainVideo: "" });
                } else {
                    this.setState({ mainVideo: a.main_video, mainImage: "" });
                }
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);
                    this.setState({
                        aboutContent: a.about_text,
                        salesContent: a.sales_text,
                        charityContent: a.charity_text,
                        socialsContent: a.socials_text,
                        profilesContent: a.profiles_text,
                        registrationList1: a.registration_list_1,
                        registrationList2: a.registration_list_2,
                        registrationList3: a.registration_list_3,
                        registrationList4: a.registration_list_4,
                        loginList1: a.login_list_1,
                        loginList2: a.login_list_2,
                        loginList3: a.login_list_3,
                        socialsList1: a.socials_list_1,
                        socialsList2: a.socials_list_2,
                        socialsList3: a.socials_list_3,
                        socialsList4: a.socials_list_4,
                        socialsList5: a.socials_list_5,
                        socialsList6: a.socials_list_6,
                        profileList1: a.profile_list_1,
                        profileList2: a.profile_list_2,
                        profileList3: a.profile_list_3,
                        profileList4: a.profile_list_4,
                        profileList5: a.profile_list_5,
                        profileList6: a.profile_list_6,

                        reg_login_about_dutch_heading: a.reg_login_about_dutch_heading,
                        reg_login_about_english_heading: a.reg_login_about_english_heading,
                        reg_login_team_bhodi_dutch_heading:
                            a.reg_login_team_bhodi_dutch_heading,
                        reg_login_team_bhodi_english_heading:
                            a.reg_login_team_bhodi_english_heading,
                        reg_login_about_dutch: a.reg_login_about_dutch,
                        reg_login_about_english: a.reg_login_about_english,
                        reg_login_team_bhodi_dutch: a.reg_login_team_bhodi_dutch,
                        reg_login_team_bhodi_english: a.reg_login_team_bhodi_english,
                        reg_login_about_dutch_heading_mobile:
                            a.reg_login_about_dutch_heading_mobile,
                        reg_login_about_english_heading_mobile:
                            a.reg_login_about_english_heading_mobile,
                        reg_login_team_bhodi_dutch_heading_mobile:
                            a.reg_login_team_bhodi_dutch_heading_mobile,
                        reg_login_team_bhodi_english_heading_mobile:
                            a.reg_login_team_bhodi_english_heading_mobile,
                        reg_login_about_dutch_mobile: a.reg_login_about_dutch_mobile,
                        reg_login_about_english_mobile: a.reg_login_about_english_mobile,
                        reg_login_team_bhodi_dutch_mobile:
                            a.reg_login_team_bhodi_dutch_mobile,
                        reg_login_team_bhodi_english_mobile:
                            a.reg_login_team_bhodi_english_mobile,
                    });
                    if (a.about_image) {
                        this.setState({ aboutImage: a.about_image, aboutVideo: "" });
                    } else {
                        this.setState({ aboutVideo: a.about_video, aboutImage: "" });
                    }
                    if (a.sales_image) {
                        this.setState({ salesImage: a.sales_image, salesVideo: "" });
                    } else {
                        this.setState({ salesVideo: a.sales_video, salesImage: "" });
                    }
                    if (a.charity_image) {
                        this.setState({ charityImage: a.charity_image, charityVideo: "" });
                    } else {
                        this.setState({ charityVideo: a.charity_video, charityImage: "" });
                    }
                    if (a.socials_image) {
                        this.setState({ socialsImage: a.socials_image, socialsVideo: "" });
                    } else {
                        this.setState({ socialsVideo: a.socials_video, socialsImage: "" });
                    }
                    if (a.profiles_image) {
                        this.setState({
                            profilesImage: a.profiles_image,
                            profilesVideo: "",
                        });
                    } else {
                        this.setState({
                            profilesVideo: a.profiles_video,
                            profilesImage: "",
                        });
                    }
                    if (a.main_image) {
                        this.setState({ mainImage: a.main_image, mainVideo: "" });
                    } else {
                        this.setState({ mainVideo: a.main_video, mainImage: "" });
                    }
                }
            });
    }

    logIn() {
        const { email, password } = this.state;
        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailPopup: true, email: "" });
        } else {
            this.setState({ emailPopup: false });
        }
        if (password === "") {
            this.setState({ passwordPopup: true });
        } else {
            this.setState({ passwordPopup: false });
        }
        if (email !== "" && regexEmail.test(email) && password !== "") {
            this.setState({ aanmeldenloader: true });
            if (email === "admin@bhodi.com") {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .then((succ) => {
                        firebase
                            .database()
                            .ref("users/" + succ.user.uid)
                            .on("value", (data) => {
                                localStorage.setItem("userid", succ.user.uid);
                                // localStorage.setItem("userdata", JSON.stringify(data.val()))
                                localStorage.setItem("isAdmin", true);
                                this.props.updateAdminOrNot(true);
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                localStorage.removeItem("cookie_item");
                                setTimeout(() => {
                                    window.location.href = "/admin";
                                }, 400);
                            });
                    })
                    .catch((err) => {
                        if (err.code === "auth/user-not-found") {
                            this.setState({
                                aanmeldenloader: false,
                                emailPopup: true,
                                email: "",
                                password: "",
                            });
                        } else if (err.code === "auth/wrong-password") {
                            this.setState({
                                aanmeldenloader: false,
                                passwordPopup: true,
                                password: "",
                            });
                        } else {
                            this.setState({
                                aanmeldenloader: false
                            })
                            alert("Login failed, please try again")
                        }
                    });
            } else if (email === "subadmin1@bhodi.com" || email === "subadmin2@bhodi.com") {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .then((succ) => {
                        firebase
                            .database()
                            .ref("users/" + succ.user.uid)
                            .on("value", (data) => {
                                localStorage.setItem("userid", succ.user.uid);

                                localStorage.setItem("isAdmin", true);
                                this.props.updateAdminOrNot(true);
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                localStorage.removeItem("cookie_item");
                                setTimeout(() => {
                                    window.location.href = "/account-import";
                                }, 400);
                            });
                    })
                    .catch((err) => {
                        if (err.code === "auth/user-not-found") {
                            this.setState({
                                aanmeldenloader: false,
                                emailPopup: true,
                                email: "",
                                password: "",
                            });
                        } else if (err.code === "auth/wrong-password") {
                            this.setState({
                                aanmeldenloader: false,
                                passwordPopup: true,
                                password: "",
                            });
                        } else {
                            this.setState({
                                aanmeldenloader: false
                            })
                            alert("Login failed, please try again")
                        }
                    });
            }
        }
    }

    render() {
        return (
            <div className="landingpage-bhodi registrationpagebhodiupdated loginpageupdated adminloginpage">
                <div className="third-section-background-lpb">
                    <div className="left-tsblpb" style={{ backgroundColor: "#f7f7f7" }}>
                        <h2 data-aos="fade-in">Login</h2>
                        <div className="forms-clgec">
                            <div
                                className={`form-field-clgec ${this.state.emailPopup ? "redalertffcl" : ""
                                    }`}
                            >
                                <label>Contact</label>
                                <input
                                    type="email"
                                    placeholder={!this.state.emailPopup ? "Email adres" : "E-mail adres is niet gevonden"}
                                    autoComplete="off"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                                {this.state.emailTookedPopup && (
                                    <div className="alert-field">
                                        <span style={{ letterSpacing: 1 }}>
                                            Email already taken
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div
                                className={`form-field-clgec ${this.state.passwordPopup ? "redalertffcl" : ""
                                    }`}
                            >
                                <input
                                    type={this.state.passwordInputType}
                                    autoComplete="off"
                                    placeholder="Kies wachtwoord"
                                    value={this.state.password}
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                ) : (
                                    <button
                                        onClick={this.logIn.bind(this)}
                                        style={{ backgroundColor: "#C17F6B" }}
                                    >
                                        Inloggen
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginAdmin);
