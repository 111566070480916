import React, { Component } from 'react'
import firebase from "../../../Config/Firebase"
import "./index.css"
import axios from "axios";
import { connect } from 'react-redux';
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../../Redux/actions/authActions";
import Footer from '../../../Components/Footer';
import FooterGreen from '../../../Components/FooterGreen';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { history } from '../../../Config/Routes';
import logoSubAdmin from "../../../assets/subadminlogo.png";
import voucherFormImage from "../../../assets/clientonboardpicnew.jpeg"
import restaurantNewPic from "../../../assets/restindustrypic.jpeg"
import swal from 'sweetalert';

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class ClientsAccountOnboard extends Component {
    constructor() {
        super()
        this.state = {
            aanmeldenInfo: false,
            aanmeldenloader: false,
            dropFileCampaignImage: [],
            companyName: "",
            phoneNum: "",
            street: "",
            email: "",
            houseNum: "",
            password: "",
            postCode: "",
            city: "",
            bankName: "",
            allUsers: [],
            currentUserSpecific: "",
            showMainLoader: false,
            fromDirectUrl: false,
            companyNameDefault: "",
            companyUrlRedirect: "",
            companyUrlRedirectGuest: "",
            defaultEmailParam: "",
            defaultKeyParam: "",
            fourthStep: false,

            allListings: [],
            indexOfCurrentCompany: -1,
            currentIndustry: "restaurant",
            currentIndustryMain: "restaurant",

            currentFormType: "voucher",
            currentFormTypeDefault: "voucher",

            allImagesCurrentSectorStock: [],
            allSectors: [],
            mainSectorStock: "",

            arrEvents: [],


            isEventEnabled: true,

            selectedSector: "Fashion & Shoes1633955735296",
            selectedMain: ["Accessoires1633956984219"],


            // new restaurant stuff
            amountOfTables: "",
            subscription_data: [],
            client_id_user: "",
            houseNumber: "",
            street: "",
            zipCode: "",
            city: "",
            plan_id: "",
            plan_amount: "",
            stripe_plan_key: "",
            split_amount: "",
            inputTypeSelect: "",
            amountOfEvent: "",

            paymentLinkType: "stripe",

            useOfVoucherLocalEvent: "",

            isVendorSaloon: false,
            currentIndustryType: "",

            current_client_id_barber: 1000,
            current_client_id_restaurant: 1000,
            allListings: [],

        }
    }

    componentDidMount() {
        if (this.props.global_values) {
            this.setState({ useOfVoucherLocalEvent: this.props.global_values.voucherUseLocalEvent || "" })
        }

        let industryType = this.props.match.params.industry

        if (industryType === "barber") {
            firebase.database().ref("client_id_barbers").on("value", (data) => {
                let a = data.val()
                console.log(a, '/see here')
                if (a) {
                    this.setState({ current_client_id_barber: Number(a) + 1 })
                }
            })

            firebase.database().ref(`accounts_by_admin_barber`).on("value", (data) => {
                let a = data.val()
                console.log(a, '/see here')
                if (a) {
                    this.setState({
                        allListings: a
                    })
                }
            })
        } else if (industryType === "restaurant") {
            firebase.database().ref("client_id_restaurants").on("value", (data) => {
                let a = data.val()
                if (a) {
                    this.setState({ current_client_id_restaurant: Number(a) + 1 })
                }
            })

            firebase.database().ref(`accounts_by_admin`).on("value", (data) => {
                let a = data.val()
                if (a) {
                    this.setState({
                        allListings: a
                    })
                }
            })
        } else {
            swal({
                title: "Sorry",
                text: "Industry not allowed"
            }).then(() => {
                history.goBack()
            })
        }

        this.setState({ currentIndustryType: industryType, currentIndustry: industryType })

        firebase
            .database()
            .ref("users")
            .on("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        arr.push(u[1]);
                    });
                    let usersWithNumbers = [];
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t);
                        }
                    });
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                } else {
                    this.setState({ allUsers: [] });
                }
            });

        let arr = [];
        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        let arr = [];
        if (this.props.subscription_plans !== prevProps.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }
    }

    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    createCompanyAndPaymentLinkShops() {
        const { street, houseNumber, zipCode, city, email, companyName, agreed } = this.state

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let stripe_plan_key = this.state.stripe_plan_key
        let plan_id = this.state.plan_id
        let plan_amount = this.state.plan_amount

        this.updateStateOnCondition(street, "streetPopup")
        this.updateStateOnCondition(city, "cityPopup")
        this.updateStateOnCondition(zipCode, "zipCodePopup")
        this.updateStateOnCondition(houseNumber, "houseNumberPopup")
        this.updateStateOnCondition(companyName, "companyNamePopup")
        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }

        if (
            this.checkForValuePresent(street) &&
            this.checkForValuePresent(zipCode) &&
            this.checkForValuePresent(city) &&
            this.checkForValuePresent(houseNumber) &&
            this.checkForValuePresent(companyName) &&
            agreed && email !== "" && regexEmail.test(email)
        ) {
            let promiseAccountDataEmailSend = new Promise((resolve, reject) => {
                let filteredInner = this.state.allListings.filter((t) => {
                    return t.email === email
                })
                let filteredAllUsers = this.state.allUsers.filter((t) => {
                    return t.email === email
                })
                if (filteredInner.length > 0) {
                    let promiseLogin = new Promise((res) => {
                        this.setState({ aanmeldenloader: true })

                        firebase.auth().signInWithEmailAndPassword(email, filteredInner[0].password)
                            .then((succ) => {
                                res(succ.user.uid)
                            })
                            .catch((err) => {
                                alert(err)
                            })
                    })

                    promiseLogin.then((uid) => {
                        const user = firebase.auth().currentUser;
                        let promiseEmailUpdate = new Promise((res) => {
                            res()
                        })

                        promiseEmailUpdate.then(() => {
                            const updates = {
                                [`users/${uid}/email`]: email,
                                [`users/${uid}/street`]: street,
                                [`users/${uid}/city`]: city,
                                [`users/${uid}/company_name`]: companyName,
                                [`users/${uid}/zipCode`]: zipCode,
                                [`users/${uid}/houseNumber`]: houseNumber,
                                [`users/${uid}/newly_registered`]: false,
                                [`users/${uid}/hideWizard`]: true,
                                [`users/${uid}/profileActive`]: true,
                                [`users/${uid}/client_id_creation`]: filteredInner[0].client_id,
                            };

                            let userCurrent = this.props.user.filter((t) => {
                                return t.user_id === uid
                            })

                            firebase.database().ref().update(updates).then(() => {
                                let redirect_url = ""
                                let redirect_url_guest = ""
                                if (userCurrent.length > 0) {
                                    redirect_url = `${window.location.origin}/company/${userCurrent[0].user_name_id}`
                                    redirect_url_guest = `${window.location.origin}/company/${userCurrent[0].user_name_id}/campaign-guest`
                                }

                                // localStorage.setItem("userid", uid);
                                // localStorage.removeItem("cookie_item");
                                // localStorage.setItem("isAdmin", false);
                                // this.props.updateAdminOrNot(false);

                                let obj = {
                                    id: uid,
                                    urlRedirect: redirect_url,
                                    urlRedirectGuest: redirect_url_guest,
                                }

                                if (this.state.allListings.length > 0) {
                                    let indexOfCurrentCompany = this.state.allListings.findIndex((t) => {
                                        return t.email === email
                                    })
                                    if (indexOfCurrentCompany !== -1) {
                                        this.state.allListings[indexOfCurrentCompany].account_created = true
                                        this.state.allListings[indexOfCurrentCompany].company_name = companyName
                                        this.state.allListings[indexOfCurrentCompany].houseNumber = houseNumber
                                        this.state.allListings[indexOfCurrentCompany].zipCode = zipCode
                                        this.state.allListings[indexOfCurrentCompany].street = street
                                        this.state.allListings[indexOfCurrentCompany].city = city

                                        firebase.database().ref((this.state.currentIndustry === "restaurant") ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustry}`).set(this.state.allListings)
                                    }
                                }

                                resolve(obj)
                            })
                        })
                    })
                } else if (filteredAllUsers.length > 0) {
                    swal({
                        title: "Oops!",
                        text: "This email is already invited via Bhodi",
                        icon: "error",
                    })
                } else {
                    this.setState({ aanmeldenloader: true })

                    let jointName = companyName;

                    let today = new Date();
                    let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

                    let filteredForJoin = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });

                    if (filteredForJoin.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20 - 0) + 0);
                        jointName = companyName + randomStr;

                        let filteredForJoinSecond = this.state.allUsers.filter((g) => {
                            return g.user_name_id === jointName.toLowerCase();
                        });
                        if (filteredForJoinSecond.length > 0) {
                            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                            jointName = companyName + randomStr;

                            let filteredForJoinThird = this.state.allUsers.filter((g) => {
                                return g.user_name_id === jointName.toLowerCase();
                            });
                            if (filteredForJoinThird.length > 0) {
                                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                jointName = companyName + randomStr;

                                let filteredForJoinFourth = this.state.allUsers.filter(
                                    (g) => {
                                        return g.user_name_id === jointName.toLowerCase();
                                    }
                                );
                                if (filteredForJoinFourth.length > 0) {
                                    let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                    jointName = companyName + randomStr;
                                }
                            }
                        }
                    }
                    let password = this.randomStr(8)


                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(password, salt, (err, hash) => {
                            if (jointName.includes(" ")) {
                                jointName = jointName.replace(/ /g, "_");
                            }

                            let obj = {
                                first_name: "",
                                email: email,
                                last_name: "",
                                password: hash,
                                country: "Netherlands",
                                passwordChanged: false,
                                street: street,
                                city: city,
                                zipCode: zipCode,
                                houseNumber: houseNumber,
                                company_website: "",
                                kvkCompany: "",
                                phoneNumber: "",
                                type: "company",
                                user_name_id: jointName.toLowerCase(),
                                created_on: date,
                                company_name: companyName,
                                lunch_show_contact: true,
                                dinner_show_contact: true,
                                dinner_title_contact: "Dinner",
                                lunch_title_contact: "Dinner",
                                newly_registered: false,
                                user_id_numbers: this.state.currentUserSpecific,
                                hideWizard: true,
                                profileActive: true,
                                client_id_creation: this.state.currentIndustry === "barber" ? this.state.current_client_id_barber : this.state.current_client_id_restaurant,
                            };
                            if (this.state.currentIndustryType === "barber") {
                                obj.barber_new = true
                            }
                            let objSecond = {
                                email: email,
                                password: password,
                                industry: this.state.currentIndustryType,
                            };
                            firebase.auth().createUserWithEmailAndPassword(email, password)
                                .then((succ) => {
                                    let userId = succ.user.uid
                                    obj.user_id = userId
                                    firebase.database().ref("users/" + userId).set(obj).then(async () => {
                                        let redirect_url = `${window.location.origin}/company/${jointName.toLowerCase()}`
                                        let redirect_url_guest = `${window.location.origin}/company/${jointName.toLowerCase()}/campaign-guest`

                                        // localStorage.setItem("userid", userId);
                                        // localStorage.removeItem("cookie_item");
                                        // localStorage.setItem("isAdmin", false);
                                        // this.props.updateAdminOrNot(false);

                                        let obj = {
                                            id: userId,
                                            urlRedirect: redirect_url,
                                            urlRedirectGuest: redirect_url_guest,
                                        }

                                        if (this.state.allListings.length > 0) {
                                            let cloned = [...this.state.allListings]
                                            let objToPushInner = {
                                                first_name: "",
                                                last_name: "",
                                                street: street,
                                                email: email,
                                                houseNumber: houseNumber,
                                                zipCode: zipCode,
                                                client_id: this.state.currentIndustry === "barber" ? this.state.current_client_id_barber : this.state.current_client_id_restaurant,
                                                city: city,
                                                phoneNumber: "",
                                                contactPerson: "",
                                                company_name: companyName,
                                                kvkCompany: "",
                                                company_website: "",
                                                selected: false,
                                                type: "event",
                                                paymentLinkType: "stripe",
                                                selectedMain: [],
                                                selectedSector: "",
                                                password,
                                                account_created: true,
                                            };
                                            if (this.state.currentIndustryType === "restaurant") {
                                                // adding for new form changes
                                                objToPushInner.restaurant_is_new_form = true
                                            }
                                            if (this.state.currentIndustryType === "barber") {
                                                // adding for new form changes
                                                objToPushInner.barber_is_new_form = true

                                                delete objToPushInner.selectedMain
                                                delete objToPushInner.selectedSector
                                                delete objToPushInner.type
                                            }

                                            cloned.push(objToPushInner)

                                            await firebase.database().ref(this.state.currentIndustry === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustry}`).set(cloned)
                                            await firebase.database().ref(this.state.currentIndustry === "restaurant" ? "client_id_restaurants" : `client_id_barbers`).set(this.state.currentIndustry === "barber" ? this.state.current_client_id_barber : this.state.current_client_id_restaurant)
                                        }

                                        let pushKey = firebase.database().ref("accounts_created_uniquelink").push().key
                                        await firebase.database().ref(`accounts_created_uniquelink/${pushKey}`).set(objSecond)

                                        resolve(obj)
                                    })
                                })
                        })
                    })
                }
            })
            let promiseGetLatLong = new Promise((resolve, reject) => {
                let address = `${street} ${houseNumber}, ${zipCode} ${city}, Netherlands`;

                var config = {
                    method: "get",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        let coords = response.data.results[0].geometry.location;

                        let obj = {
                            lat: coords.lat,
                            lng: coords.lng,
                        }
                        resolve(obj)
                    })
                    .catch(function () {
                        let obj = {
                            lat: "",
                            lng: "",
                        }
                        resolve(obj)
                    });
            })
            Promise.all([promiseAccountDataEmailSend, promiseGetLatLong]).then((data) => {
                let userId = data[0].id
                let latitude = data[1].lat
                let longitude = data[1].lng

                let storageKey = firebase.database().ref("bhodi_campaigns/" + userId).push().key;
                let timestamp = Math.floor(Date.now() / 100);

                let objToSend = {
                    campaignType: "local_event",
                    pushKey: storageKey,
                    active: true,
                    created_by: userId,
                    timestamp,
                    number: "",
                    created_at: new Date().getTime(),
                    customExpireTypeThreeMonth: true,
                    takeFromAdmin: true,
                    stockAmount: "10000000000"
                };

                firebase
                    .database()
                    .ref("bhodi_campaigns/" + userId + "/" + storageKey)
                    .set(objToSend)
                    .then(() => {
                        let promiseGeneratePaymentLink = new Promise((resolve, reject) => {
                            let filtered = this.state.subscription_data.filter((t) => {
                                return t.price === "7.50"
                            })
                            if (filtered.length > 0) {
                                stripe_plan_key = filtered[0].stripe_plan_key
                                plan_id = filtered[0].plan_id
                                plan_amount = filtered[0].price
                            }

                            var dataForAPI = JSON.stringify({
                                restaurant_id: this.state.client_id_user,
                                company_id: userId,
                                campaign_id: storageKey,
                                stripe_plan_key,
                                plan_id,
                                plan_amount,
                            });

                            var config = {
                                method: "post",
                                url: "https://us-central1-bhodi-7c327.cloudfunctions.net/createPaymentLink",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                data: dataForAPI,
                            };

                            axios(config)
                                .then((response) => {
                                    let apiData = response.data.data
                                    let pushKey = firebase.database().ref("payment_links_stripe").push().key
                                    let obj = {
                                        pushKey,
                                        company_id: this.state.client_id_user,
                                        payment_id: apiData.id,
                                        active: true,
                                        payment_url: apiData.url,
                                        noOfSeats: 1,
                                        plan_amount,
                                        payment_link_of_type: this.state.currentIndustry,
                                        brochure_type: "Normal",
                                        company_name: this.state.companyNameDefault,
                                        address: `${street} ${houseNumber}, ${zipCode} ${city}, Netherlands`
                                    }

                                    resolve(obj)
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });

                        })
                        let promiseEmailSend = new Promise((resolve, reject) => {
                            // var dataForAPI = JSON.stringify({
                            //     sendToEmail: email,
                            //     subject: "Guest Link!",
                            //     innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><p>Link For Guests: ${data[0].urlRedirectGuest}</p><p>Email: ${email}</p><p>Password: ${this.state.defaultKeyParam}</p></body></html>`,
                            // });

                            // var config = {
                            //     method: "post",
                            //     url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendEmailMailjet",
                            //     headers: {
                            //         "Content-Type": "application/json",
                            //     },
                            //     data: dataForAPI,
                            // };

                            // axios(config)
                            //     .then((response) => {
                            //         resolve(response)
                            //     })
                            resolve()
                        })
                        Promise.all([promiseGeneratePaymentLink, promiseEmailSend]).then((dataInner) => {
                            let obj = dataInner[0]
                            firebase.database().ref(`payment_links_stripe/${obj.pushKey}`).set(obj)
                            firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                            firebase.database().ref("users/" + userId + "/longitude").set(longitude)
                            if (this.state.useOfVoucherLocalEvent) {
                                firebase.database().ref("users/" + userId + "/useOfVoucherLocalEvent").set(this.state.useOfVoucherLocalEvent)
                            }
                            firebase.database().ref("users/" + userId + "/donation_link_client").set(obj.payment_url)

                            this.setState({ fifthStep: true, companyUrlRedirect: data[0].urlRedirect, companyUrlRedirectGuest: data[0].urlRedirectGuest, companyUserId: userId })
                        })
                    });
            })
        }
    }

    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                    {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
                </div>
            ) : (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink clientaccountonboardsubscribepagelink">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    {this.state.fifthStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Jullie aanmelding is verwerkt.</p>
                            <p className='third-smt-fbcoco'>
                                Binnenkort ontvangen jullie<br />
                                van ons de informatie voor het<br />
                                versturen van de nieuwsbrief.
                            </p>
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "flex-start" }}>
                            <button style={{ marginLeft: 0 }} onClick={() => {
                                window.location.href = "/"
                            }}>SLUIT PAGINA</button>
                        </div>
                    </div>) : (<div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label>Aanmelden</label>
                            <label>Account gegevens</label>
                            <label style={{ maxWidth: "none" }}>
                                Leuk dat jullie willen meedoen met de Bhodi charity actie!<br />
                                Voeg jullie gegevens toe en meld je eenvoudig aan.
                            </label>
                        </div>
                        <div className="forms-clgec">
                            <>
                                <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='E-mail'
                                        type='email'
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.companyNameInvalid ? "redalertffcl" : ""}`}>
                                    <label>Bedrijfsnaam</label>
                                    <input
                                        placeholder='Bedrijfsnaam'
                                        value={this.state.companyName}
                                        onChange={(e) => {
                                            this.setState({ companyName: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.streetPopup ? "redalertffcl" : ""}`}>
                                    <label>Straatnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Straatnaam"
                                        value={this.state.street}
                                        onChange={(e) => this.setState({ street: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.houseNumberPopup ? "redalertffcl" : ""}`}>
                                    <label>Huisnummer</label>
                                    <input
                                        type="text"
                                        placeholder="Huisnummer"
                                        value={this.state.houseNumber}
                                        onChange={(e) => this.setState({ houseNumber: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.zipCodePopup ? "redalertffcl" : ""}`}>
                                    <label>Postcode</label>
                                    <input
                                        type="text"
                                        placeholder="Postcode"
                                        value={this.state.zipCode}
                                        onChange={(e) => this.setState({ zipCode: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.cityPopup ? "redalertffcl" : ""}`}>
                                    <label>Plaatsnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Plaats"
                                        value={this.state.city}
                                        onChange={(e) => this.setState({ city: e.target.value })}
                                    />
                                </div>
                                <div
                                    className="fx-registration-inner"
                                    style={{ alignItems: "center" }}
                                >
                                    <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                        <div
                                            className="left-frvn"
                                            style={{
                                                width: "100%",
                                                paddingLeft: 10,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <div className="checking-box">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.agreed}
                                                    onChange={() =>
                                                        this.setState({ agreed: !this.state.agreed })
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        color: this.state.agreedPopup ? "red" : "black",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <font
                                                        style={{
                                                            textDecorationLine: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                "_blank" // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Akkoord met voorwaarden
                                                    </font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            this.createCompanyAndPaymentLinkShops()
                                        }}
                                    >
                                        {"Volgende"}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec" style={{ backgroundImage: `url("${voucherFormImage}")` }}>

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        <div className="popup-opener-stogec pos-inner-img">
                            <img src={logoSubAdmin} alt='logo' width={50} />
                        </div>
                    </div>
                )}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>)
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
        stock_images: state.globalReducers.stock_images,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientsAccountOnboard);