import React, { Component } from 'react'
import Switch from "@material-ui/core/Switch";
import AddEditProduct from './AddEditProduct';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from 'react-redux';
import AddIcon from "@material-ui/icons/Add";
import {
    updateGlobalStuff,
    updateGuestUserCart,
    updateStockImages,
    updateGuestUserCartTime,
    updateGuestUserCartExtendOpportunity,
    updateBhodiProducts,
} from "../../Redux/actions/authActions";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase"
import "./styles.css"
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ExclusiveProductsEditor from '../../Components/ExclusiveProductsEditor';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BiCalendar, BiChevronRight, BiDownload } from 'react-icons/bi';
import PublishIcon from "@material-ui/icons/Publish";
import * as XLSX from "xlsx";
import "./rightmenustyles.css"
import { BsCheck2 } from 'react-icons/bs';
import swal from "sweetalert";
import { AiOutlineArrowUp, AiOutlineInfoCircle } from 'react-icons/ai';
import { MdAdd, MdTimer } from 'react-icons/md';
import { FiChevronRight } from 'react-icons/fi';
import { ChevronLeft } from '@material-ui/icons';
import GuestProductEditor from '../../Components/GuestProductEditor';
import moment from 'moment';
import { GiConsoleController } from 'react-icons/gi';
var convert = require("xml-js")

let dbRef = firebase.database().ref("bhodi_products")

const axios = require("axios");

export class EditProductScreen extends Component {

    constructor() {
        super()
        this.state = {
            allProducts: [],
            categoryData: {},
            allSectors: [],
            sectors: [],
            completeCategories: [],
            completeSizes: [],
            onCurrentPage: 1,
            editScreenOpen: true,
            allProductsDuplicated: [],
            currentProductToEdit: "",
            productsAsExclusive: false,
            productsAsCarousel: true,
            currentUserHere: "",
            productsTimer: "",
            currentProductCarousel: 0,
            dropdownKeys: [],
            csvSelectedName: "",
            titleImportField: "",
            descImportField: "",
            priceImportField: "",
            skuImportField: "",
            sizesImportField: "",
            sizesSkuImportField: "",
            categoriesImportField: "",
            genderImportField: "",
            mainSector: "",
            allSectors: [],
            categoriesToMap: [],
            sizesToMap: [],
            gendersToMap: [],
            all_categories: [],
            all_sizes: [],
            completeCSVArray: [],
            dropFileImages: [],
            productImages: [],
            keysAttribute: [],
            globalDesc: "",
            deliveryNote: "",
            specialGrouping: "",
            csvSelectedNameStock: "",
            dropdownKeysStock: [],
            maxProductsToShow: 1,
            completeCSVArrayStock: [],
            skuImportFieldStock: "",
            stockImportFieldStock: "",
            priceImportFieldStock: "",
            alreadyCategoriesData: [],
            alreadySizesData: [],
            alreadyGendersData: [],
            defaultLoad: 20,
            showMoveToTop: false,
            xmlUrl: "",
            xmlUrlShifted: "https://",
            xmlUrlStock: "",
            xmlUrlShiftedStock: "https://",
            savingProductsImport: false,
            imageImportType: "manual",
            productImportType: "manual",
            stockImportType: "manual",
            dropdownKeysImages: [],
            completeCSVArrayImages: [],
            filteredProducts: [],
            defaultLoadFiltered: 20,
            globalGender: [],
            itemsToShow: 1,
            itemsMaxProductCart: "unlimited",
            intervalTimer: "",
            allTimes: [
                { label: "01:00" },
                { label: "01:15" },
                { label: "01:30" },
                { label: "01:45" },
                { label: "02:00" },
                { label: "02:15" },
                { label: "02:30" },
                { label: "02:45" },
                { label: "03:00" },
                { label: "03:15" },
                { label: "03:30" },
                { label: "03:45" },
                { label: "04:00" },
                { label: "04:15" },
                { label: "04:30" },
                { label: "04:45" },
                { label: "05:00" },
                { label: "05:15" },
                { label: "05:30" },
                { label: "05:45" },
                { label: "06:00" },
                { label: "06:15" },
                { label: "06:30" },
                { label: "06:45" },
                { label: "07:00" },
                { label: "07:15" },
                { label: "07:30" },
                { label: "07:45" },
                { label: "08:00" },
                { label: "08:15" },
                { label: "08:30" },
                { label: "08:45" },
                { label: "09:00" },
                { label: "09:15" },
                { label: "09:30" },
                { label: "09:45" },
                { label: "10:00" },
                { label: "10:15" },
                { label: "10:30" },
                { label: "10:45" },
                { label: "11:00" },
                { label: "11:15" },
                { label: "11:30" },
                { label: "11:45" },
                { label: "12:00" },
                { label: "12:15" },
                { label: "12:30" },
                { label: "12:45" },
                { label: "13:00" },
                { label: "13:15" },
                { label: "13:30" },
                { label: "13:45" },
                { label: "14:00" },
                { label: "14:15" },
                { label: "14:30" },
                { label: "14:45" },
                { label: "15:00" },
                { label: "15:15" },
                { label: "15:30" },
                { label: "15:45" },
                { label: "16:00" },
                { label: "16:15" },
                { label: "16:30" },
                { label: "16:45" },
                { label: "17:00" },
                { label: "17:15" },
                { label: "17:30" },
                { label: "17:45" },
                { label: "18:00" },
                { label: "18:15" },
                { label: "18:30" },
                { label: "18:45" },
                { label: "19:00" },
                { label: "19:15" },
                { label: "19:30" },
                { label: "19:45" },
                { label: "20:00" },
                { label: "20:15" },
                { label: "20:30" },
                { label: "20:45" },
                { label: "21:00" },
                { label: "21:15" },
                { label: "21:30" },
                { label: "21:45" },
                { label: "22:00" },
                { label: "22:15" },
                { label: "22:30" },
                { label: "22:45" },
                { label: "23:00" },
                { label: "23:15" },
                { label: "23:30" },
                { label: "23:45" },
                { label: "24:00" },
            ],
            startDateAnnouncement: "",
            startTimeAnnouncement: "",

            announcementTitle: "",
            announcementDesc: "",
            announcementMediaUrl: "",
            announcementMediaFile: "",
            announcementMediaType: "",
            announcementEnabled: false,
            notRequireImage: false,
            currentAnnouncementPresent: "",


            recurringType: "weekly",
            recurringDay: "tuesday",
            startTimeRecurring: "",

            recurringTitle: "",
            recurringDesc: "",
            recurringAmount: 1,
            recurringMediaUrl: "",
            recurringMediaFile: "",
            recurringMediaType: "",
            recurringEnabled: false,
            notRequireImageRecurring: false,
            currentRecurringPresent: "",
            // recurringBefore: "day",

            intervalSmallBannerImage: "",
            intervalBigBannerImage: "",

            shippingCost: "free",
            subscription_data: [],

            subscriptionAmountProducts: "",
            subscriptionAmountProductsSplit: "",

            allIndexes: [],
            allProductsToCheckSwap: [],

            shopOnline: true,
        }
    }

    async componentDidMount() {
        this.checkForShowingIndexes()

        let userid = localStorage.getItem("userid");

        if (window.innerWidth < 1008) {
            document.querySelector(".navbarfullwidthMobile").classList.add("navbartodowhitedefault")
            document.getElementById("navbartohidemainly").style.overflowX = "hidden"
        }

        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                let arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }

        let arr = []
        firebase
            .database()
            .ref("subscription_plans")
            .on("child_added", (data) => {
                if (data.val().price !== "0.00") {
                    arr.push(data.val());
                    this.setState({
                        subscription_data: arr,
                    });
                }
            });

        // let newUrl = new URL(window.location.href);
        // let trackingId = newUrl.searchParams.get("merchantId");
        // if (trackingId) {
        //     var config = {
        //         method: "get",
        //         url: "https://us-central1-bhodi-7c327.cloudfunctions.net/getAuthDetails",
        //         headers: {},
        //     };

        //     axios(config)
        //         .then((response) => {
        //             if (response.status === 200) {
        //                 let accessToken = response.data.access_token;

        //                 var configAnother = {
        //                     method: "get",
        //                     url: `https://us-central1-bhodi-7c327.cloudfunctions.net/getMerchantId?trackingId=${trackingId}`,
        //                     headers: {
        //                         authToken: accessToken,
        //                         "Content-Type": "application/json",
        //                     },
        //                 };

        //                 axios(configAnother)
        //                     .then((responseNew) => {
        //                         let obj = {
        //                             merchant_id: responseNew.data.merchant_id,
        //                             tracking_id: responseNew.data.tracking_id,
        //                         };
        //                         firebase
        //                             .database()
        //                             .ref("users/" + userid + "/paypalInfo")
        //                             .set(obj)
        //                             .then(() => {
        //                                 swal({
        //                                     title: "Cool.",
        //                                     text: "Paypal connected successfully",
        //                                     icon: "success",
        //                                 }).then(() => {
        //                                     setTimeout(() => {
        //                                         window.location.href = `/company/${this.props.memberId}/edit-products`;
        //                                     }, 1000);
        //                                 })
        //                             });
        //                     })
        //                     .catch(function (error) {
        //                         console.log(error);
        //                     });
        //             }
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // }


        let newDate = new Date();
        let monthToday = newDate.getMonth() + 1;
        let dateTodayToMake = newDate.getDate();
        if (monthToday < 10) {
            monthToday = `0${monthToday}`;
        }
        if (dateTodayToMake < 10) {
            dateTodayToMake = `0${dateTodayToMake}`;
        }
        let makeToday = `${newDate.getFullYear()}-${monthToday}-${dateTodayToMake}`;

        this.setState({
            todayDateToBeMax: makeToday,
        });

        window.onscroll = () => {
            if (this.state.filteredProducts.length > 0) {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 300) && this.state.filteredProducts.length > this.state.defaultLoadFiltered && !this.state.showLoader) {
                    this.setState({ showLoader: true, showMoveToTop: true })
                    setTimeout(() => {
                        this.setState({ defaultLoadFiltered: this.state.defaultLoadFiltered + 20, showLoader: false })
                    }, 2000);
                }
            } else {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 300) && this.state.allProductsDuplicated.length > this.state.defaultLoad && !this.state.showLoader) {
                    this.setState({ showLoader: true, showMoveToTop: true })
                    setTimeout(() => {
                        this.setState({ defaultLoad: this.state.defaultLoad + 20, showLoader: false })
                    }, 2000);
                }
            }
        }

        document.getElementById("root").style.position = "static"
        document.getElementById("root").style.overflow = "auto"


        // if (window.location.search) {
        //     let urlMain = new URLSearchParams(window.location.search)
        //     if (urlMain.get("code")) {
        //         let obj = {
        //             code: urlMain.get("code"),
        //         }
        //         if (urlMain.get("state")) {
        //             obj.state = urlMain.get("state")
        //         }
        //         axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/generateMollieAccessToken", { code: obj.code })
        //             .then((response) => {
        //                 if (response.data.statusCode === 201) {
        //                     obj.auth_token = response.data.access_token

        //                     axios({
        //                         url: "https://us-central1-bhodi-7c327.cloudfunctions.net/getOrganizationDetails",
        //                         method: "get",
        //                         headers: {
        //                             authToken: response.data.access_token,
        //                         }
        //                     }).then((resp) => {
        //                         if (resp.data.statusCode === 201) {
        //                             obj.org_id = resp.data.id

        //                             firebase.database().ref(`users/${userid}/mollie_credentials`).set(obj).then(() => {
        //                                 swal({
        //                                     title: "Cool.",
        //                                     text: "Mollie connected successfully",
        //                                     icon: "success",
        //                                 })
        //                             })
        //                         }
        //                     }).then((err) => {
        //                         swal({
        //                             title: "Sorry.",
        //                             text: "Error occured in connecting mollie",
        //                             icon: "error",
        //                         })
        //                         console.log(err, '/err')
        //                     })
        //                 }
        //             }).catch((err) => {
        //                 swal({
        //                     title: "Sorry.",
        //                     text: "Error occured in connecting mollie",
        //                     icon: "error",
        //                 })
        //                 console.log(err, '/err')
        //             })
        //     }
        // }

        // firebase.database().ref("product_images_to_upload").remove()

        firebase.database().ref("mapped_categories/" + userid).on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ alreadyCategoriesData: a })
            }
        })

        firebase.database().ref("mapped_sizes/" + userid).on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ alreadySizesData: a })
            }
        })

        firebase.database().ref("mapped_genders/" + userid).on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ alreadyGendersData: a })
            }
        })

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((j) => {
                    return j.user_id === userid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    if (a.productsAsExclusive) {
                        this.setState({ productsAsExclusive: true })
                    }
                    if (a.productsInfo) {
                        if (a.productsInfo.subscriptionAmountExclusive) {
                            this.setState({ subscriptionAmountProducts: a.productsInfo.subscriptionAmountExclusive })
                        }
                        if (a.productsInfo.subscriptionAmountExclusiveSplitted) {
                            this.setState({ subscriptionAmountProductsSplit: a.productsInfo.subscriptionAmountExclusive })
                        }
                    }
                    if (a.recurring) {
                        this.setState({
                            recurringTitle: a.recurring.title,
                            recurringDesc: a.recurring.desc,
                            recurringAmount: a.recurring.recurringAmount,
                            // recurringBefore: a.recurring.recurringBefore,
                            recurringType: a.recurring.recurringType,
                            recurringDay: a.recurring.recurringDay,
                            recurringBannerTime: a.recurring.recurringBannerTime,
                            notRequireImageRecurring: true,
                            currentRecurringPresent: a.recurring,
                            recurringMediaType: a.recurring.bannerType,
                            recurringMediaUrl: a.recurring.bannerMedia,
                        })
                        if (a.recurring.active) {
                            this.setState({
                                recurringEnabled: true,
                            })
                        }
                    }
                    this.setState({ currentUserHere: a, isGuestUser: false });
                }
            }
        }

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });
                if (arr.length > 0) {
                    if (this.state.selectedSector) {
                        this.setState({ sectors: arr });
                    } else {
                        this.setState({ sectors: arr, currentSector: arr[0] });
                    }
                    firebase
                        .database()
                        .ref("bhodi_categories_products")
                        .on("value", (data) => {
                            let a = data.val();
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let categories = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        categories.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        categories.push(obj);
                                    }
                                });
                                if (categories.length > 0) {
                                    this.setState({
                                        completeCategories: objected,
                                    });
                                }
                            } else {
                                this.setState({ completeCategories: [] });
                            }
                        });
                }
            }
        }

        firebase
            .database()
            .ref("users/" + userid)
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    if (a.productsAsExclusive) {
                        this.setState({ productsAsExclusive: true })
                    }
                    if (a.productsInfo) {
                        if (a.productsInfo.subscriptionAmountExclusive) {
                            this.setState({ subscriptionAmountProducts: a.productsInfo.subscriptionAmountExclusive })
                        }
                        if (a.productsInfo.subscriptionAmountExclusiveSplitted) {
                            this.setState({ subscriptionAmountProductsSplit: a.productsInfo.subscriptionAmountExclusiveSplitted })
                        }
                    }
                    if (a.recurring) {
                        this.setState({
                            recurringTitle: a.recurring.title,
                            recurringDesc: a.recurring.desc,
                            recurringAmount: a.recurring.recurringAmount,
                            // recurringBefore: a.recurring.recurringBefore,
                            recurringType: a.recurring.recurringType,
                            recurringDay: a.recurring.recurringDay,
                            recurringBannerTime: a.recurring.recurringBannerTime,
                            notRequireImageRecurring: true,
                            currentRecurringPresent: a.recurring,
                            recurringMediaType: a.recurring.bannerType,
                            recurringMediaUrl: a.recurring.bannerMedia,
                        })
                        if (a.recurring.active) {
                            this.setState({
                                recurringEnabled: true,
                            })
                        }
                    }
                    this.setState({ currentUserHere: a, isGuestUser: false });
                }
            });


        if (this.props.bhodi_products) {
            let filtered = this.props.bhodi_products.filter((g) => {
                return g.created_by === userid
            })
            let sortedProducts = [...filtered].sort((a, b) => {
                return b.timestamp * 100 - a.timestamp * 100;
            });
            if (filtered.length > 0) {
                // sortedProducts.map((e) => {
                //     firebase.database().ref(`bhodi_products/${e.pushKey}/desc`).set("")
                //     firebase.database().ref(`bhodi_products/${e.pushKey}/gender`).set([])
                // })
                this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts })
            } else {
                this.setState({ allProducts: [], allProductsDuplicated: [] })
            }

            let filteredAvailable = this.props.bhodi_products.filter((g) => {
                return g.created_by === this.props.currentMemberData.user_id && g.images
            })
            let sortedProductsAvailable = [...filteredAvailable].sort((a, b) => {
                return b.timestamp * 100 - a.timestamp * 100;
            });
            if (filteredAvailable.length > 0) {
                let availableStockProducts = []
                sortedProductsAvailable.map((product) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.inCartOf) {
                        if (currentProduct.sizesActive) {
                            let stocksAvailableSizes = []
                            currentProduct.variations_data.map((e) => {
                                let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                                if (filteredStockLength.length < Number(e.qty)) {
                                    stocksAvailableSizes.push(e)
                                }
                            })
                            if (stocksAvailableSizes.length === 0) {
                                console.log("no stock")
                            } else {
                                availableStockProducts.push(product)
                            }
                        } else {
                            let objected = Object.entries(product.inCartOf)
                            if (objected.length >= Number(currentProduct.qty)) {
                                console.log("no stock")
                            } else {
                                availableStockProducts.push(product)
                            }
                        }
                    } else {
                        availableStockProducts.push(product)
                    }
                })
                if (availableStockProducts.length > 0) {
                    let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
                    this.setState({ currentProductCarousel: currentInd })
                }
            }
        } else {
            this.setState({ allProducts: [], allProductsDuplicated: [] })
        }

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });
                if (arr.length > 0) {
                    if (this.state.selectedSector) {
                        this.setState({ sectors: arr });
                    } else {
                        this.setState({ sectors: arr, currentSector: arr[0] });
                    }
                    firebase
                        .database()
                        .ref("bhodi_categories_products")
                        .on("value", (data) => {
                            let a = data.val();
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let categories = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        categories.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        categories.push(obj);
                                    }
                                });
                                if (categories.length > 0) {
                                    this.setState({
                                        completeCategories: objected,
                                    });
                                }
                            } else {
                                this.setState({ completeCategories: [] });
                            }
                        });
                    firebase
                        .database()
                        .ref("bhodi_sizes_products")
                        .on("value", (data) => {
                            let a = data.val();
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let sizes = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        sizes.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        sizes.push(obj);
                                    }
                                });
                                if (sizes.length > 0) {
                                    this.setState({
                                        completeSizes: objected,
                                    });
                                }
                            } else {
                                this.setState({ completeCategories: [] });
                            }
                        });
                }
            }
        }

        // await fetch("https://www.zinzi.nl/media/catalog/product/C/H/7970_CH-A2_1.jpg").then((r) => {
        //     console.log(r, '/check')
        //     console.log(r.blob(), '/check')
        // })
    }


    checkForShowingIndexes() {
        let maxProductsToShow = this.state.maxProductsToShow
        if (this.props.global_values.maxProductsToShow) {
            maxProductsToShow = this.props.global_values.maxProductsToShow
            this.setState({ maxProductsToShow: this.props.global_values.maxProductsToShow })
        }

        let indexToStartProducts = 0
        let indexToStartProductsWeekly = 0
        let indexToStartProductsDaily = 0

        let allDatesWeekly = []
        let currentWeekAt = ""
        let currentDayAt = ""
        let allDatesDaily = []

        if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
            let recurring = this.props.currentMemberData.recurring
            if (recurring.recurringType === "daily") {
                let recurringStart = new Date(recurring.storedAt)
                let allDatesThousandsDays = []

                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                recurringStart.setSeconds("00")

                if (recurringStart.getTime() > new Date(recurring.storedAt).getTime()) {
                    recurringStart.setDate(recurringStart.getDate() - 1)
                }


                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                allDatesThousandsDays.push(newDateFirst)

                for (let i = 0; i < 1000; i++) {
                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                    allDatesThousandsDays.push(newDate)
                }


                let availableDates = allDatesThousandsDays.filter((e) => {
                    return new Date(e).getTime() > new Date().getTime()
                })
                console.log(availableDates[0], '/check all days dates here')

                let getIndex = allDatesThousandsDays.findIndex((g) => {
                    return g === availableDates[0]
                })
                console.log(getIndex, '/check all days dates here')

                allDatesDaily = allDatesThousandsDays
                currentDayAt = availableDates[0]

                indexToStartProducts = getIndex - 1
                indexToStartProductsDaily = getIndex - 1
            } else {
                let recurringStart = new Date(recurring.storedAt)
                let allDatesThousands = []

                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                recurringStart.setSeconds("00")

                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                if (this.state.weekdays[recurringStart.getDay()] === recurring.recurringDay && new Date(newDateFirst).getTime() > new Date(recurring.storedAt).getTime()) {
                    allDatesThousands.push(newDateFirst)
                }

                for (let i = 0; i < 5000; i++) {
                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                    if (this.state.weekdays[new Date(newDate).getDay()] === recurring.recurringDay) {
                        allDatesThousands.push(newDate)
                    }
                }

                let availableDates = allDatesThousands.filter((e) => {
                    return new Date(e).getTime() > new Date().getTime()
                })

                let getIndex = allDatesThousands.findIndex((g) => {
                    return g === availableDates[0]
                })

                allDatesWeekly = allDatesThousands
                currentWeekAt = availableDates[0]

                this.setState({ weeklyAvailableDate: availableDates[0] })

                indexToStartProducts = getIndex
                indexToStartProductsWeekly = getIndex
            }

            maxProductsToShow = Number(this.props.currentMemberData.recurring.recurringAmount)
            this.setState({ maxProductsToShow: Number(this.props.currentMemberData.recurring.recurringAmount) })
        }

        if (this.props.currentMemberData) {
            this.setState({ currentMemberData: this.props.currentMemberData })
            if (this.props.currentMemberData.announcement && this.props.currentMemberData.announcement.active) {
                if (new Date(Date.parse(this.props.currentMemberData.announcement.end_date_iso)).getTime() > new Date().getTime()) {
                    this.setState({ showBannerAnnouncement: true, loading: false })
                }
            }
            firebase
                .database()
                .ref("users/" + this.props.currentMemberData.user_id)
                .on("value", (data) => {
                    let a = data.val();
                    if (a) {
                        if (this.props.global_values.maxProductsToShow && this.props.global_values.maxProductsToShow !== this.state.maxProductsToShow) {
                            maxProductsToShow = this.props.global_values.maxProductsToShow
                            this.setState({ maxProductsToShow: this.props.global_values.maxProductsToShow })
                        }

                        if (a.recurring && a.recurring.active) {
                            let recurring = a.recurring
                            if (recurring.recurringType === "daily") {
                                let recurringStart = new Date(recurring.storedAt)
                                let allDatesThousandsDays = []

                                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                                recurringStart.setSeconds("00")

                                if (recurringStart.getTime() > new Date(recurring.storedAt).getTime()) {
                                    recurringStart.setDate(recurringStart.getDate() - 1)
                                }


                                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                                allDatesThousandsDays.push(newDateFirst)

                                for (let i = 0; i < 1000; i++) {
                                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                                    allDatesThousandsDays.push(newDate)
                                }


                                let availableDates = allDatesThousandsDays.filter((e) => {
                                    return new Date(e).getTime() > new Date().getTime()
                                })

                                let getIndex = allDatesThousandsDays.findIndex((g) => {
                                    return g === availableDates[0]
                                })

                                allDatesDaily = allDatesThousandsDays
                                currentDayAt = availableDates[0]

                                indexToStartProducts = getIndex - 1
                                indexToStartProductsDaily = getIndex - 1
                            } else {
                                let recurringStart = new Date(recurring.storedAt)
                                let allDatesThousands = []

                                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                                recurringStart.setSeconds("00")

                                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                                if (this.state.weekdays[recurringStart.getDay()] === recurring.recurringDay && new Date(newDateFirst).getTime() > new Date(recurring.storedAt).getTime()) {
                                    allDatesThousands.push(newDateFirst)
                                }

                                for (let i = 0; i < 5000; i++) {
                                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                                    if (this.state.weekdays[new Date(newDate).getDay()] === recurring.recurringDay) {
                                        allDatesThousands.push(newDate)
                                    }
                                }

                                let availableDates = allDatesThousands.filter((e) => {
                                    return new Date(e).getTime() > new Date().getTime()
                                })

                                let getIndex = allDatesThousands.findIndex((g) => {
                                    return g === availableDates[0]
                                })

                                allDatesWeekly = allDatesThousands
                                currentWeekAt = availableDates[0]

                                this.setState({ weeklyAvailableDate: availableDates[0] })

                                indexToStartProducts = getIndex
                                indexToStartProductsWeekly = getIndex
                            }

                            maxProductsToShow = Number(a.recurring.recurringAmount)
                            if (Number(a.recurring.recurringAmount) !== this.state.maxProductsToShow) {
                                this.setState({ maxProductsToShow: Number(a.recurring.recurringAmount) })
                            }
                        }

                        this.setState({ currentMemberData: a });

                        setTimeout(() => {
                            if (this.props.bhodi_products) {
                                let filtered = this.props.bhodi_products.filter((g) => {
                                    let isAvailable = true
                                    return g.created_by === this.props.currentMemberData.user_id && isAvailable
                                })

                                let isRepeating = false
                                let availableProducts = []
                                filtered.map((product, index) => {
                                    let currentProduct = Object.assign({}, product)
                                    if (product.active) {
                                        if (product.inCartOf || product.verzilvered_by) {
                                            if (currentProduct.sizesActive) {
                                                let stocksAvailableSizes = []
                                                currentProduct.variations_data.map((e) => {
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            } else {
                                                let objectedCart = []
                                                let objectedVerzilvered = []

                                                if (product.inCartOf) {
                                                    objectedCart = Object.entries(product.inCartOf)
                                                }
                                                if (product.verzilvered_by) {
                                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                                }
                                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            }
                                        } else {
                                            if (product.sizesActive) {
                                                let stocksAvailableSizes = []
                                                product.variations_data.map((e) => {
                                                    if (Number(e.qty) === 0) {
                                                        return false;
                                                    }
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            } else {
                                                if (Number(product.qty) === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            }
                                        }
                                    }
                                })

                                if (a.recurring && a.recurring.active) {
                                    if (a.recurring.recurringType === "daily") {
                                        let maximumWeeksToShow = Math.ceil(filtered.length / Number(a.recurring.recurringAmount))

                                        let onIndex = allDatesDaily.findIndex((t) => {
                                            return t === currentDayAt
                                        })

                                        if (onIndex > maximumWeeksToShow + 1) {
                                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(a.recurring.recurringAmount))

                                            const separateArrays = [];
                                            let allDatesDailyNew = allDatesDaily.slice()
                                            // allDatesDailyNew.shift()

                                            for (let i = 0; i < allDatesDailyNew.length; i += maximumWeeksToShowAvailable) {
                                                separateArrays.push(allDatesDailyNew.slice(i, i + maximumWeeksToShowAvailable));
                                            }

                                            let indexGot = 0;
                                            separateArrays.map((t) => {
                                                let indexGotNew = t.findIndex((g) => {
                                                    return Number(g) === Number(currentDayAt)
                                                })
                                                if (indexGotNew !== -1) {
                                                    indexGot = indexGotNew + 1
                                                }
                                            })

                                            indexToStartProducts = indexGot

                                            isRepeating = true
                                        }
                                    } else {
                                        let maximumWeeksToShow = Math.ceil(filtered.length / Number(a.recurring.recurringAmount))

                                        let onIndex = allDatesWeekly.findIndex((t) => {
                                            return t === currentWeekAt
                                        })

                                        console.log(onIndex, '/check here main new')
                                        if (onIndex > maximumWeeksToShow) {
                                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(a.recurring.recurringAmount))

                                            const separateArrays = [];
                                            let allDatesWeeklyNew = allDatesWeekly.slice()
                                            allDatesWeeklyNew.shift()

                                            for (let i = 0; i < allDatesWeeklyNew.length; i += maximumWeeksToShowAvailable) {
                                                separateArrays.push(allDatesWeeklyNew.slice(i, i + maximumWeeksToShowAvailable));
                                            }

                                            let indexGot = 0;
                                            separateArrays.map((t) => {
                                                let indexGotNew = t.findIndex((g) => {
                                                    return Number(g) === Number(currentWeekAt)
                                                })
                                                if (indexGotNew !== -1) {
                                                    indexGot = indexGotNew + 1
                                                }
                                            })

                                            indexToStartProducts = indexGot

                                            isRepeating = true
                                        }
                                    }
                                }

                                this.processProducts(filtered, maxProductsToShow, a.recurring, indexToStartProducts, "", isRepeating)
                            }
                        }, 2000);
                    }
                });
        }

        let userid = localStorage.getItem("userid");
        this.setState({ currentUserId: userid })

        let lastCaught = ""
        dbRef.orderByChild("created_by").equalTo(this.props.currentMemberData.user_id).on("value", (data) => {
            let a = data.val()
            if (a) {
                let allProducts = []
                let objected = Object.entries(a)
                objected.map((e) => {
                    // if (e[1].images) {
                    allProducts.push(e[1])
                    // }
                })

                let bhodiProducts = this.props.bhodi_products
                if (bhodiProducts && bhodiProducts.length > 0) {
                    let arrToSet = []
                    bhodiProducts.map((e) => {
                        let alreadyAvailable = allProducts.filter((t) => {
                            return t.pushKey === e.pushKey
                        })
                        if (alreadyAvailable.length > 0) {
                            arrToSet.push(alreadyAvailable[0])
                        } else {
                            arrToSet.push(e)
                        }
                    })

                    this.props.updateBhodiProducts(arrToSet)
                }

                let isRepeating = false
                let availableProducts = []
                allProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            }
                        } else {
                            if (product.sizesActive) {
                                let stocksAvailableSizes = []
                                product.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            } else {
                                if (Number(product.qty) === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            }
                            // availableProducts.push(product)
                        }
                    }
                })

                if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
                    if (this.props.currentMemberData.recurring.recurringType === "daily") {
                        let maximumWeeksToShow = Math.ceil(allProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))

                        let onIndex = allDatesDaily.findIndex((t) => {
                            return t === currentDayAt
                        })
                        if (onIndex > maximumWeeksToShow + 1) {
                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))

                            const separateArrays = [];
                            let allDatesDailyNew = allDatesDaily.slice()

                            for (let i = 0; i < allDatesDailyNew.length; i += maximumWeeksToShowAvailable) {
                                separateArrays.push(allDatesDailyNew.slice(i, i + maximumWeeksToShowAvailable));
                            }

                            let indexGot = 0;
                            separateArrays.map((t) => {
                                let indexGotNew = t.findIndex((g) => {
                                    return Number(g) === Number(currentDayAt)
                                })
                                if (indexGotNew !== -1) {
                                    indexGot = indexGotNew + 1
                                }
                            })

                            indexToStartProducts = indexGot

                            isRepeating = true
                        }
                    } else {
                        let maximumWeeksToShow = Math.ceil(allProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))
                        let onIndex = allDatesWeekly.findIndex((t) => {
                            return t === currentWeekAt
                        })
                        if (onIndex > maximumWeeksToShow) {
                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))

                            const separateArrays = [];
                            let allDatesWeeklyNew = allDatesWeekly.slice()
                            allDatesWeeklyNew.shift()

                            for (let i = 0; i < allDatesWeeklyNew.length; i += maximumWeeksToShowAvailable) {
                                separateArrays.push(allDatesWeeklyNew.slice(i, i + maximumWeeksToShowAvailable));
                            }

                            let indexGot = 0;
                            separateArrays.map((t) => {
                                let indexGotNew = t.findIndex((g) => {
                                    return Number(g) === Number(currentWeekAt)
                                })
                                if (indexGotNew !== -1) {
                                    indexGot = indexGotNew + 1
                                }
                            })

                            indexToStartProducts = indexGot

                            isRepeating = true
                        }
                    }
                }

                this.processProducts(allProducts, maxProductsToShow, this.props.currentMemberData.recurring, indexToStartProducts, "", isRepeating)
            }
        })
    }


    processProducts(filtered, maxProductsToShow, recurring, indexToStartProducts, avoidAvailability, isRepeating) {
        let mustProcess = true
        if (!this.props.currentMemberData.recurring) {
            if (this.state.keepShowing) {
                mustProcess = false
            }
        } else {
            if (!this.props.currentMemberData.recurring.active) {
                if (this.state.keepShowing) {
                    mustProcess = false
                }
            }
        }

        let currentIndex = indexToStartProducts - 1
        let sortedProducts = [...filtered].sort((a, b) => {
            return a.sort_time * 100 - b.sort_time * 100;
        });

        if (filtered.length > 0) {
            let availableStockProducts = []
            let availableIndexes = []

            if (recurring && recurring.active) {
                sortedProducts.map((product, index) => {
                    if (isRepeating) {
                        let currentProduct = Object.assign({}, product)
                        if (product.active) {
                            if (product.inCartOf || product.verzilvered_by) {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        console.log("no stock")
                                    } else {
                                        availableStockProducts.push(product)
                                        availableIndexes.push(index)
                                    }
                                } else {
                                    let objectedCart = []
                                    let objectedVerzilvered = []

                                    if (product.inCartOf) {
                                        objectedCart = Object.entries(product.inCartOf)
                                    }
                                    if (product.verzilvered_by) {
                                        objectedVerzilvered = Object.entries(product.verzilvered_by)
                                    }
                                    if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                        console.log("no stock")
                                    } else {
                                        availableStockProducts.push(product)
                                        availableIndexes.push(index)
                                    }
                                }
                            } else {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        if (Number(e.qty) === 0) {
                                            return false;
                                        }
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        return false;
                                    }
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                } else {
                                    if (Number(currentProduct.qty) === 0) {
                                        return false;
                                    }
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            }
                        }
                    } else {
                        availableStockProducts.push(product)
                        availableIndexes.push(index)
                    }
                })

                let productsAvailableForRecurr = []
                sortedProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    productsAvailableForRecurr.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    productsAvailableForRecurr.push(product)
                                }
                            }
                        } else {
                            productsAvailableForRecurr.push(product)
                        }
                    }
                })

                this.setState({ productsAvailableForRecurr })

            } else {
                sortedProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.variations_data) {
                        let objected = Object.entries(product.variations_data)
                        let filtered = objected.filter((t) => {
                            return Number(t[1].qty) > 0
                        })
                        if (filtered.length === 0) {
                            return false;
                        }
                    } else {
                        if (Number(product.qty) === 0) {
                            return false;
                        }
                    }
                    if (!product.images) {
                        return false;
                    }
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            }
                        } else {
                            availableStockProducts.push(product)
                            availableIndexes.push(index)
                        }
                    }
                })
            }

            if (availableStockProducts.length === 3) {
                this.setState({ keepShowing: true })
            } else {
                this.setState({ keepShowing: false })
            }

            if (availableStockProducts.length === 0) {
                this.setState({ noCarouselProducts: true })
            }
            let maxIndexesToShow = []
            let maxIndexesToShowNext = []

            if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
                maxIndexesToShow = [...availableIndexes].splice(currentIndex * maxProductsToShow, maxProductsToShow)

                maxIndexesToShowNext = availableIndexes.splice(indexToStartProducts * maxProductsToShow, maxProductsToShow)
            } else {

                maxIndexesToShow = [...availableIndexes].splice(0, maxProductsToShow).reverse()

                maxIndexesToShowNext = availableIndexes.splice(0, maxProductsToShow).reverse()
            }

            let productsToRender = []
            let productsAllRecurring = []

            if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {

                let allIndexesRecurShowing = []
                if (this.state.productsToRender.length === 3 && this.props.currentMemberData.recurring.recurringAmount == 3) {
                    // if ((this.state.productsToRender.length === 3 && this.props.currentMemberData.recurring.recurringAmount == 3) || (this.state.productsToRender.length === 2 && this.props.currentMemberData.recurring.recurringAmount == 3)) {
                    this.state.productsToRender.map((product) => {
                        let findIndex = sortedProducts.findIndex((e) => {
                            return e.pushKey === product.pushKey
                        })
                        let currentProductOptionsAdd = sortedProducts.filter((e) => {
                            return e.pushKey === product.pushKey
                        })
                        if (currentProductOptionsAdd.length > 0) {
                            productsToRender.push(currentProductOptionsAdd[0])
                            allIndexesRecurShowing.push(findIndex)
                        }
                    })
                } else {
                    // to check recurring end product to make sort time accordingly
                    maxIndexesToShow.map((index, ind) => {
                        let currentProduct = Object.assign(sortedProducts[index], {})
                        let product = Object.assign(sortedProducts[index], {})
                        if (product.active) {
                            if (product.inCartOf || product.verzilvered_by) {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        console.log("no stock")
                                    } else {
                                        productsAllRecurring.push(currentProduct)
                                    }
                                } else {
                                    let objectedCart = []
                                    let objectedVerzilvered = []

                                    if (product.inCartOf) {
                                        objectedCart = Object.entries(product.inCartOf)
                                    }
                                    if (product.verzilvered_by) {
                                        objectedVerzilvered = Object.entries(product.verzilvered_by)
                                    }
                                    if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                        console.log("no stock")
                                    } else {
                                        productsAllRecurring.push(currentProduct)
                                    }
                                }
                            } else {
                                productsAllRecurring.push(currentProduct)
                            }
                        }
                    })

                    // to check recurring end product to make sort time accordingly
                    maxIndexesToShow.map((index, ind) => {
                        let currentProduct = Object.assign(sortedProducts[index], {})
                        let product = Object.assign(sortedProducts[index], {})
                        if (productsToRender.length < this.props.global_values.maxProductsToShow) {
                            if (product.active) {
                                if (product.inCartOf || product.verzilvered_by) {
                                    if (currentProduct.sizesActive) {
                                        let stocksAvailableSizes = []
                                        currentProduct.variations_data.map((e) => {
                                            let filteredStockLength = []
                                            let filteredStockLengthVerzilvered = []
                                            if (product.inCartOf) {
                                                filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                    return t[1].selectedSize === e.size
                                                })
                                            }
                                            if (product.verzilvered_by) {
                                                filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                    return t[1].selectedSize === e.size
                                                })
                                            }
                                            if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                stocksAvailableSizes.push(e)
                                            }
                                        })
                                        if (stocksAvailableSizes.length === 0) {
                                            console.log("no stock")
                                        } else {
                                            productsToRender.push(currentProduct)
                                            allIndexesRecurShowing.push(index)
                                        }
                                    } else {
                                        let objectedCart = []
                                        let objectedVerzilvered = []

                                        if (product.inCartOf) {
                                            objectedCart = Object.entries(product.inCartOf)
                                        }
                                        if (product.verzilvered_by) {
                                            objectedVerzilvered = Object.entries(product.verzilvered_by)
                                        }
                                        if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                            console.log("no stock")
                                        } else {
                                            productsToRender.push(currentProduct)
                                            allIndexesRecurShowing.push(index)
                                        }
                                    }
                                } else {
                                    productsToRender.push(currentProduct)
                                    allIndexesRecurShowing.push(index)
                                }
                            }
                        }
                    })
                    maxIndexesToShow = allIndexesRecurShowing.reverse()
                    productsToRender = productsToRender.reverse()
                }

            } else {
                maxIndexesToShow.map((index, ind) => {
                    let currentProduct = Object.assign(sortedProducts[index], {})
                    productsToRender.push(currentProduct)
                })
            }

            let productsToRenderNext = []
            maxIndexesToShowNext.map((index, ind) => {
                let currentProduct = Object.assign(sortedProducts[index], {})
                productsToRenderNext.push(currentProduct)
            })

            // check for recurring
            if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
                let checkForRecurring = []
                let checkForRecurringNext = []
                productsToRender.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurring.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurring.push(product)
                                }
                            }
                        } else {
                            checkForRecurring.push(product)
                        }
                    }
                })
                productsToRenderNext.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurringNext.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurringNext.push(product)
                                }
                            }
                        } else {
                            checkForRecurringNext.push(product)
                        }
                    }
                })

                if (checkForRecurring.length === 0 || currentIndex === -1 && !isRepeating) {
                    if (checkForRecurringNext.length > 0) {
                        this.setState({ noCarouselProducts: false, showRecurringBanner: true })
                    } else {
                        this.setState({ noCarouselProducts: true, showRecurringBanner: false })
                    }
                } else {
                    this.setState({ showRecurringBanner: false })
                }
                //     // check for recurring
            }


            // let productsSwapped = productsToSwap.concat(productsToRender).concat(productsTooked)
            // let productsSwapped = this.state.productsSwapped.concat(productsToSwap).concat(productsToRender)

            this.setState({ allIndexes: maxIndexesToShow, productsToRender, allProductsToCheckSwap: sortedProducts })

        }
    }

    componentWillUnmount() {
        dbRef.orderByChild("created_by").equalTo(this.props.currentMemberData.user_id).off()
    }

    getCreatorName(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "user") {
                    return filtered[0].first_name
                } else {
                    return filtered[0].company_name
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bhodi_products !== this.props.bhodi_products) {
            if (this.props.bhodi_products) {
                let userid = localStorage.getItem("userid");

                let filtered = this.props.bhodi_products.filter((g) => {
                    return g.created_by === userid
                })
                let sortedProducts = [...filtered].sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (filtered.length > 0) {
                    if (this.state.filteredProducts.length > 0) {
                        let duplicatedFilter = this.state.filteredProducts.slice()
                        let newArr = []
                        duplicatedFilter.map((t, i) => {
                            let filteredInner = sortedProducts.filter((g) => {
                                return g.pushKey === t.pushKey
                            })
                            if (filteredInner.length > 0) {
                                newArr.push(filteredInner[0])
                            }
                        })
                        this.setState({ filteredProducts: newArr })
                    }
                    this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts })
                } else {
                    this.setState({ allProducts: [], allProductsDuplicated: [] })
                }

                let filteredAvailable = this.props.bhodi_products.filter((g) => {
                    return g.created_by === this.props.currentMemberData.user_id && g.images
                })
                let sortedProductsAvailable = [...filteredAvailable].sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (filteredAvailable.length > 0) {
                    let availableStockProducts = []
                    sortedProductsAvailable.map((product) => {
                        let currentProduct = Object.assign({}, product)
                        if (product.inCartOf) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                        return t[1].selectedSize === e.size
                                    })
                                    if (filteredStockLength.length < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                }
                            } else {
                                let objected = Object.entries(product.inCartOf)
                                if (objected.length >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                }
                            }
                        } else {
                            availableStockProducts.push(product)
                        }
                    })
                    if (availableStockProducts.length > 0) {
                        let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
                        this.setState({ currentProductCarousel: currentInd })
                    }
                }
            } else {
                this.setState({ allProducts: [], allProductsDuplicated: [] })
            }
        }
    }

    saveCurrentData() {
        let userid = localStorage.getItem("userid")

        firebase.database().ref(`users/${userid}/productsAsExclusive`).set(this.state.productsAsExclusive)

        if (this.state.subscriptionAmountProducts) {
            firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusive`).set(this.state.subscriptionAmountProducts)
            if (this.state.subscriptionAmountProductsSplit) {
                firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusiveSplitted`).set(this.state.subscriptionAmountProductsSplit)
            } else {
                firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusiveSplitted`).set("")
            }
        } else {
            firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusive`).set("")
            firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusiveSplitted`).set("")
        }


        setTimeout(() => {
            document.querySelector("html").style.overflowY = "auto"
            this.setState({ settingsPopup: false })
        }, 1000);
    }

    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    getAllStock(product) {
        let qty = 0;
        if (product.variations_data && product.variations_data.length > 0) {
            product.variations_data.map((t) => {
                qty += Number(t.qty)
            })
        } else {
            qty = product.qty
        }
        return qty
    }


    getAvailableStock(product) {
        let qty = 0;
        let inCartOf = []
        let verzilveredBy = []
        if (product.inCartOf) {
            inCartOf = Object.entries(product.inCartOf)
        }
        if (product.verzilvered_by) {
            verzilveredBy = Object.entries(product.verzilvered_by)
        }
        if (product.variations_data && product.variations_data.length > 0) {
            product.variations_data.map((t) => {
                qty += Number(t.qty)
            })
        } else {
            qty = product.qty
        }
        return qty - inCartOf.length - verzilveredBy.length
    }

    render() {
        return (
            this.state.guestEditorMain ? (
                <GuestProductEditor closePopup={() => {
                    this.setState({ guestEditorMain: false })
                }} />
            ) : this.state.guestEditor ? (
                <ExclusiveProductsEditor closePopup={() => {
                    this.setState({ guestEditor: false })
                }} />
            ) : <div>
                {this.state.settingsPopup && (<div className='right-menu-main-settings rmms-popups-morewidth'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>SETTINGS</span>
                            <CloseIcon onClick={() => {
                                document.querySelector("html").style.overflowY = "auto"
                                this.setState({ settingsPopup: false })
                            }} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Aanmeldpagina
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ aanmeldpaginaInfo: !this.state.aanmeldpaginaInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.aanmeldpaginaInfo ? 1 : 0, pointerEvents: this.state.aanmeldpaginaInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ aanmeldpaginaInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Voorzie de aanmeldpagina van jullie eigen look and feel.
                                        </label>
                                    </div>
                                </label>
                                {/* <label>
                                    Producten Guest Editor
                                </label> */}
                                <div className='wrapper-select-new' style={{ marginBottom: 18, cursor: "pointer" }} onClick={() => {
                                    this.setState({ guestEditorMain: true })
                                }}>
                                    <input value="Bewerk guestpagina" readOnly />
                                    <EditIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <label>
                                        Memberships
                                    </label>
                                    <div className='switch-icon-wrapper swi-smallone' style={{ marginBottom: 15, position: "relative" }}>
                                        <Switch
                                            color="primary"
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            checked={this.state.productsAsExclusive}
                                            onClick={() => {
                                                this.setState({ productsAsExclusive: !this.state.productsAsExclusive })
                                            }}
                                        />
                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ memberShipInfo: !this.state.memberShipInfo })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.memberShipInfo ? 1 : 0, pointerEvents: this.state.memberShipInfo ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ memberShipInfo: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Wanneer je de producten exlusief maakt, dienen
                                                de members zich hiervoor extra aan te melden.
                                                Van de bijdragen per maand, ontvangen jullie als bedrijf een deel:
                                                2, 5, 8 of 12 euro per maand. Op deze manier kunnen jullie
                                                een subscription model toepassen op het productaanbod.
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className='green-round-info'>?</div> */}
                                </div>
                                {this.state.productsAsExclusive && (<div className='wrapper-select-new' style={{ marginBottom: 18, cursor: "pointer" }} onClick={() => {
                                    this.setState({ guestEditor: true })
                                }}>
                                    <input value="Bewerk aanmeldpagina" readOnly />
                                    <EditIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>)}
                                {this.state.productsAsExclusive && (<div className='wrapper-select-new' style={{ marginBottom: 18 }}>
                                    <select
                                        value={this.state.subscriptionAmountProducts}
                                        onChange={(e) => {
                                            if (e.target.value === "show_all") {
                                                this.setState({
                                                    subscriptionAmountProducts: e.target.value,
                                                    subscriptionAmountProductsSplit: "",
                                                })
                                            } else {
                                                let filtered = this.state.subscription_data.filter((g) => {
                                                    return g.price === e.target.value
                                                })
                                                if (filtered.length > 0) {
                                                    this.setState({
                                                        subscriptionAmountProducts: e.target.value,
                                                        // subscriptionAmountProductsData: filtered[0],
                                                        subscriptionAmountProductsSplit: filtered[0].split_amount,
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        <option value="">Bijdrage per maand</option>
                                        {this.state.subscription_data.length > 0 &&
                                            [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                                return (
                                                    <option value={e.price}>{e.price}</option>
                                                );
                                            })}
                                        <option value="show_all">Show All plans</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>)}
                            </div>
                            <div className='save-button-main'>
                                <button onClick={this.saveCurrentData.bind(this)}>OPSLAAN</button>
                            </div>
                        </div>
                    </div>
                </div>)}
                {this.state.editScreenOpen && (<div className="edit-screen-products" style={{ width: window.innerWidth < 1008 && "100%" }}>
                    <div className='top-edit-screen-products'>
                        <CloseIcon onClick={() => this.setState({ editScreenOpen: false })} />
                        <label>ADJUSTMENTS</label>
                    </div>
                    <div className='buttons-top-main' style={{ justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                        <div style={{ display: "flex" }}>
                            <button style={{ padding: 0, backgroundColor: "transparent", color: "black", textAlign: window.innerWidth < 1008 && "left" }}>Total: {this.state.allProducts.length}</button>
                            {window.innerWidth > 1008 && (<div className='input-btm-products'>
                                <input type='text' placeholder='Search Products' onChange={(e) => {
                                    if (e.target.value) {
                                        let filtered = [...this.state.allProducts].filter((g) => {
                                            return g.title.toLowerCase().includes(e.target.value.toLowerCase()) || g.sku.toLowerCase().includes(e.target.value.toLowerCase())
                                        })
                                        if (filtered.length > 0) {
                                            this.setState({
                                                filteredProducts: filtered,
                                                defaultLoadFiltered: 20,
                                                noFilteredProducts: false,
                                            })
                                        } else {
                                            this.setState({
                                                noFilteredProducts: true,
                                            })
                                        }
                                    } else {
                                        this.setState({
                                            noFilteredProducts: false,
                                            filteredProducts: [],
                                        })
                                    }
                                }} />
                            </div>)}
                        </div>
                        {window.innerWidth < 1008 && (<div className='input-btm-products' style={{ width: "100%", marginTop: 15 }}>
                            <input type='text' style={{ width: "100%" }} placeholder='Search Products' onChange={(e) => {
                                if (e.target.value) {
                                    let filtered = [...this.state.allProducts].filter((g) => {
                                        return g.title.toLowerCase().includes(e.target.value.toLowerCase()) || g.sku.toLowerCase().includes(e.target.value.toLowerCase())
                                    })
                                    if (filtered.length > 0) {
                                        this.setState({
                                            filteredProducts: filtered,
                                            defaultLoadFiltered: 20,
                                            noFilteredProducts: false,
                                        })
                                    } else {
                                        this.setState({
                                            noFilteredProducts: true,
                                        })
                                    }
                                } else {
                                    this.setState({
                                        noFilteredProducts: false,
                                        filteredProducts: [],
                                    })
                                }
                            }} />
                        </div>)}
                        <div style={{ display: "flex", overflowX: window.innerWidth < 1008 && "auto" }} className='scroller-button-mobile'>
                            <button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({ settingsPopup: true })
                            }}>SETTINGS</button>
                        </div>
                    </div>
                    <div className='content-edit-screen-products' style={{ overflow: window.innerWidth < 1008 && "hidden" }}>
                        <div className='table-esproducts'>
                            <div className='table-heading-esproducts'>
                                <p>Image</p>
                                <p>Title</p>
                                <p>SKU</p>
                                <p>Stock Available</p>
                            </div>
                            <div className='table-content-esproducts-main'>
                                {this.state.noFilteredProducts ? <p>No available products</p> : this.state.filteredProducts.length > 0 ? [...this.state.filteredProducts].slice(0, this.state.defaultLoadFiltered).map((product, index) => {
                                    return (<div className='table-content-esproducts'>
                                        <p>
                                            <div className='bg-img' style={{ backgroundImage: (product.images && product.images.length > 0) ? `url("${product.images[product.featuredImage ? product.featuredImage : 0]}")` : `url("${process.env.REACT_APP_PLACEHOLDER_IMAGE}")` }}>

                                            </div>
                                            {/* {(product.images && product.images.length > 0) ? (<img src={product.images[product.featuredImage ? product.featuredImage : 0]} />) : <img src={process.env.REACT_APP_PLACEHOLDER_IMAGE} />} */}
                                        </p>
                                        <p style={{ textTransform: "uppercase" }}>{product.title}</p>
                                        <p>{product.sku}</p>
                                        <p>{this.getAvailableStock(product)}</p>
                                    </div>)
                                }) : [...this.state.allProductsDuplicated].slice(0, this.state.defaultLoad).map((product, index) => {
                                    return (<div className='table-content-esproducts'>
                                        <p>
                                            <div className='bg-img' style={{ backgroundImage: (product.images && product.images.length > 0) ? `url("${product.images[product.featuredImage ? product.featuredImage : 0]}")` : `url("${process.env.REACT_APP_PLACEHOLDER_IMAGE}")` }}>

                                            </div>
                                        </p>
                                        <p style={{ textTransform: "uppercase" }}>{product.title}</p>
                                        <p>{product.sku}</p>
                                        <p>{this.getAvailableStock(product)}</p>
                                        {window.innerWidth < 1008 && (<p>
                                            <AiOutlineInfoCircle onClick={() => {
                                                this.setState({ currentProductToShowInfo: product, showingInfoOfProduct: true })
                                            }} />
                                        </p>)}
                                    </div>)
                                })}
                            </div>
                        </div>
                        {this.state.showLoader && <div className="loadMoreProducts">
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <label>Loading More</label>
                        </div>}
                        {this.state.showMoveToTop && <div className='move-to-top-button' onClick={() => {
                            window.scrollTo(0, 0)
                            this.setState({ showMoveToTop: false })
                        }}>
                            <AiOutlineArrowUp />
                        </div>}
                    </div>
                </div>)}
                <Dialog
                    open={this.state.showingInfoOfProduct}
                    onClose={() => this.setState({ showingInfoOfProduct: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-more-info-showinginfo"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    {this.state.currentProductToShowInfo && (<DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            SKU: {this.state.currentProductToShowInfo.sku}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Stock: {this.getAllStock(this.state.currentProductToShowInfo)}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Stock Available: {this.getAvailableStock(this.state.currentProductToShowInfo)}
                        </DialogContentText>
                    </DialogContent>)}
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ showingInfoOfProduct: false, currentProductToShowInfo: "" })
                            }}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                {window.innerWidth > 1008 && <Footer notOnMainPage={this.state.addEditProductPopup || this.state.connectSettingsPopup || this.state.importProductsPopup || this.state.importImagesPopup || this.state.importStockPopup || this.state.settingsPopup} />}
                {window.innerWidth < 1008 && <FooterGreen />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        global_campaigns: state.campaignsReducer.global_campaigns,
        images: state.postsReducers.images,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        subscription_plans: state.globalReducers.subscription_plans,
        globalsectors: state.categoriesReducer.globalsectors,
        products_new: state.campaignsReducer.products_new,
        admin_boolean: state.globalReducers.admin_boolean,
        stock_images: state.globalReducers.stock_images,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (
            guest_user_cart_extend_opportunity
        ) =>
            dispatch(
                updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
            ),
        updateStockImages: (stock_images) =>
            dispatch(updateStockImages(stock_images)),
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProductScreen);