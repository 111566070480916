import React, { Component } from 'react'
import firebase from "../Config/Firebase"
import "./index.css"
import axios from "axios";
import { connect } from 'react-redux';
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
    updateCategoriesVouchers,
} from "../Redux/actions/authActions";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { history } from '../Config/Routes';
import { Switch } from '@material-ui/core';
import countries from "../DataJSON/countries.json";
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from "react-google-places-autocomplete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Footer from './Footer';
import FooterGreen from './FooterGreen';
import { MdClose } from 'react-icons/md';
import swal from 'sweetalert';

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class SignupComponent extends Component {
    constructor() {
        super()
        this.state = {
            aanmeldenInfo: false,
            secondStep: false,
            aanmeldenloader: false,

            companyName: "",
            phoneNum: "",
            street: "",
            email: "",
            houseNum: "",
            password: "",
            postCode: "",
            bankAccountNumber: "",
            city: "",
            country: "155",
            bankName: "",
            allUsers: [],
            firstName: "",
            lastName: "",

            isUserForm: true,

            showMainLoader: false,

            thirdStep: false,

            subscription_data: [],

            loaderSendingEmail: false,

            sendEmailPopup: false,
            sendEmailPopupMeta: "",
            isManualInfo: false,

            isSliding: false,
            emailExisting: false,
        }
    }

    componentDidMount() {
        this.setState({ isSliding: true })

        let arr = [];
        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.subscription_plans !== this.props.subscription_plans) {
            let arr = [];
            if (this.props.subscription_plans) {
                if (this.props.subscription_plans.length > 0) {
                    arr = this.props.subscription_plans;
                    this.setState({
                        subscription_data: arr,
                    });
                }
            }
        }
    }

    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }


    moveToSecondStepDirect() {
        const { email, password, agreed } = this.state

        // this.updateStateOnCondition(bankAccountNumber, "bankAccountNumberEmpty")
        // this.updateStateOnCondition(bankName, "bankNameEmpty")

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passInvalid: true });
        } else {
            this.setState({ passInvalid: false });
        }

        if (
            email !== "" && regexEmail.test(email) && password !== "" && password.length > 5 &&
            agreed
        ) {
            if (this.props.alreadyInvitedCompanies && this.props.alreadyInvitedCompanies.length > 0) {
                let filtered = this.props.alreadyInvitedCompanies.filter((t) => {
                    return t.email === email
                })
                if (filtered.length > 0) {
                    this.setState({ sendEmailPopup: true, sendEmailPopupMeta: filtered[0], isManualInfo: false })
                    return false;
                }
            }
            if (this.props.alreadyInvitedCompaniesManual && this.props.alreadyInvitedCompaniesManual.length > 0) {
                let filtered = this.props.alreadyInvitedCompaniesManual.filter((t) => {
                    return t.email === email
                })
                if (filtered.length > 0) {
                    this.setState({ sendEmailPopup: true, sendEmailPopupMeta: filtered[0], isManualInfo: true })
                    return false;
                }
            }
            let userExists = this.props.user.find((t) => {
                return t.email === email
            })
            if (userExists) {
                this.setState({ emailExisting: true })
                return false;
            } else {
                this.setState({ emailExisting: false })
            }
            this.setState({ secondStep: true })
        }
    }

    moveToThirdStepDirect() {
        const { firstName, lastName, city, companyName } = this.state

        this.updateStateOnCondition(firstName, "firstNamePopup")
        this.updateStateOnCondition(lastName, "lastNamePopup")
        this.updateStateOnCondition(city, "cityPopup")
        if (!this.state.isUserForm) {
            this.updateStateOnCondition(companyName, "companyNamePopup")
        }

        let companyNamePresent = true
        if (!this.state.isUserForm) {
            if (this.checkForValuePresent(companyName)) {
                companyNamePresent = true
            } else {
                companyNamePresent = false
            }
        }

        if (
            this.checkForValuePresent(firstName) &&
            this.checkForValuePresent(lastName) &&
            this.checkForValuePresent(city) &&
            companyNamePresent
        ) {
            this.setState({ secondStep: false, thirdStep: true })
        }
    }

    createCompanyAndCampaign() {
        const { kortingsVoucherTitle, arrEvents, kortingsVoucherAmount, kortingsVoucherDesc, kortingsVoucherNormalPrice, kortingsVoucherSalePrice, dropFileCampaignImage, mainSectorStock, street, houseNum, postCode, city, localEventPercentage, localEventTitle, localEventDesc, localEventAmount, email, password, bankName, bankAccountNumber } = this.state

        if (this.state.currentFormType === "event") {
            this.updateStateOnCondition(localEventTitle, "localEventTitleAlert")
            this.updateStateOnCondition(localEventDesc, "localEventDescAlert")
        } else {
            this.updateStateOnCondition(kortingsVoucherTitle, "kortingsVoucherTitleAlert")
            this.updateStateOnCondition(kortingsVoucherDesc, "kortingsVoucherDescAlert")
        }
        if (this.state.currentFormType === "event") {
            this.updateStateOnCondition(localEventAmount, "localEventAmountAlert")
        } else if (this.state.currentFormType === "voucher") {
            this.updateStateOnCondition(kortingsVoucherNormalPrice, "kortingsVoucherNormalPriceAlert")
            this.updateStateOnCondition(kortingsVoucherSalePrice, "kortingsVoucherSalePriceAlert")
        } else {
            this.updateStateOnCondition(kortingsVoucherAmount, "kortingsVoucherAmountAlert")
        }

        if (dropFileCampaignImage.length > 0 || this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb") {
            this.setState({ campaignImagePreviewAlert: false })
        } else {
            this.setState({ campaignImagePreviewAlert: true })
        }

        let booleanValueCheck = true
        if (this.state.currentFormType === "event") {
            if (this.checkForValuePresent(localEventAmount)) {
                booleanValueCheck = true
            } else {
                booleanValueCheck = false
            }
        } else if (this.state.currentFormType === "voucher") {
            if (this.checkForValuePresent(kortingsVoucherNormalPrice) && this.checkForValuePresent(kortingsVoucherSalePrice)) {
                booleanValueCheck = true
            } else {
                booleanValueCheck = false
            }
        } else {
            if (this.checkForValuePresent(kortingsVoucherAmount)) {
                booleanValueCheck = true
            } else {
                booleanValueCheck = false
            }
        }

        let booleanValueCheckTitleDesc = true
        if (this.state.currentFormType === "event") {
            if (this.checkForValuePresent(localEventTitle) && this.checkForValuePresent(localEventDesc)) {
                booleanValueCheckTitleDesc = true
            } else {
                booleanValueCheckTitleDesc = false
            }
        } else {
            if (this.checkForValuePresent(kortingsVoucherTitle) && this.checkForValuePresent(kortingsVoucherDesc)) {
                booleanValueCheckTitleDesc = true
            } else {
                booleanValueCheckTitleDesc = false
            }
        }

        let booleanValueCheckTiming = true
        let arrTimings = []
        if (this.state.currentFormType === "event") {
            arrEvents.map((y) => {
                if (
                    y.startDateEvent &&
                    y.endDateEvent &&
                    y.personsAmountEvent &&
                    y.personsAmountEvent !== "00"
                ) {
                    arrTimings.push(y);
                }
            });

            if (arrTimings.length > 0) {
                arrTimings.map((g) => {
                    if (g.startTimeEvent && g.endTimeEvent) {
                        let isoTimeTimerBtn = new Date(Number(g.startDateEvent.split("-")[0]), Number(g.startDateEvent.split("-")[1] - 1), Number(g.startDateEvent.split("-")[2]), Number(g.startTimeEvent.split(":")[0]), Number(g.startTimeEvent.split(":")[1]), 0).toISOString()
                        let isoTimeEndDate = new Date(Number(g.endDateEvent.split("-")[0]), Number(g.endDateEvent.split("-")[1] - 1), Number(g.endDateEvent.split("-")[2]), Number(g.endTimeEvent.split(":")[0]), Number(g.endTimeEvent.split(":")[1]), 0).toISOString()
                        g.isoStartTime = isoTimeTimerBtn
                        g.isoEndTime = isoTimeEndDate
                    } else {
                        let isoTimeTimerBtn = new Date(Number(g.startDateEvent.split("-")[0]), Number(g.startDateEvent.split("-")[1] - 1), Number(g.startDateEvent.split("-")[2]), 0, 0, 0).toISOString()
                        let isoTimeEndDate = new Date(Number(g.endDateEvent.split("-")[0]), Number(g.endDateEvent.split("-")[1] - 1), Number(g.endDateEvent.split("-")[2]), 23, 59, 59).toISOString()
                        g.isoStartTime = isoTimeTimerBtn
                        g.isoEndTime = isoTimeEndDate
                    }
                })

                booleanValueCheckTiming = true
                this.setState({ noTimingSelectedEvent: false })
            } else {
                booleanValueCheckTiming = false
                this.setState({ noTimingSelectedEvent: true })
            }
        }

        if (
            booleanValueCheckTitleDesc &&
            booleanValueCheck &&
            booleanValueCheckTiming &&
            (dropFileCampaignImage.length > 0 || this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb")
        ) {
            let promiseAccountDataEmailSend = new Promise((resolve, reject) => {
                let promiseLogin = new Promise((res) => {
                    let urlMain = new URLSearchParams(window.location.search)
                    this.setState({ aanmeldenloader: true })

                    if (urlMain.get("email") && urlMain.get("key")) {
                        firebase.auth().signInWithEmailAndPassword(this.state.defaultEmailParam, this.state.defaultKeyParam)
                            .then((succ) => {
                                res(succ.user.uid)
                            })
                    }
                })

                promiseLogin.then((uid) => {
                    let myPlaintextPassword = password;
                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {

                            let promisePwdUpdate = new Promise((res) => {
                                const user = firebase.auth().currentUser;
                                user.updatePassword(myPlaintextPassword)
                                    .then(() => {
                                        firebase
                                            .auth()
                                            .signInWithEmailAndPassword(this.state.defaultEmailParam, myPlaintextPassword)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                            })

                            promisePwdUpdate.then(() => {
                                const user = firebase.auth().currentUser;
                                let promiseEmailUpdate = new Promise((res) => {
                                    if (email !== this.state.defaultEmailParam) {
                                        user.updateEmail(email)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    } else {
                                        res()
                                    }
                                })

                                promiseEmailUpdate.then(() => {
                                    const updates = {
                                        [`users/${uid}/email`]: email,
                                        [`users/${uid}/password`]: hash,
                                        [`users/${uid}/bankName`]: bankName,
                                        [`users/${uid}/bankAccountNumber`]: bankAccountNumber,
                                        [`users/${uid}/hideWizard`]: true,
                                        [`users/${uid}/profileActive`]: true
                                    };

                                    let userCurrent = this.props.user.filter((t) => {
                                        return t.user_id === uid
                                    })

                                    firebase.database().ref().update(updates).then(() => {
                                        let redirect_url = ""
                                        if (userCurrent.length > 0) {
                                            redirect_url = `${window.location.origin}/company/${userCurrent[0].user_name_id}`
                                        }

                                        localStorage.setItem("userid", uid);
                                        localStorage.removeItem("cookie_item");
                                        localStorage.setItem("isAdmin", false);
                                        this.props.updateAdminOrNot(false);

                                        let obj = {
                                            id: uid,
                                            urlRedirect: redirect_url,
                                        }

                                        if (this.state.allListings.length > 0 && this.state.indexOfCurrentCompany !== -1) {
                                            this.state.allListings[this.state.indexOfCurrentCompany].account_created = true
                                            firebase.database().ref(this.state.currentIndustry === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustry}`).set(this.state.allListings)
                                        }

                                        resolve(obj)
                                    })
                                })
                            })
                        })
                    })
                })
            })
            let promiseUploadImg = new Promise((resolve, reject) => {
                if (dropFileCampaignImage.length > 0 && this.state.campaignImagePreviewType === "uploadfromcomp") {
                    dropFileCampaignImage.map((e) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`campaigns/${e.name}${new Date().getTime()}`)
                            .put(e)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((snapUrl) => {
                                    resolve(snapUrl)
                                });
                            });
                    });
                } else {
                    resolve(this.state.campaignImagePreview[0])
                }
            })
            let promiseGetLatLong = new Promise((resolve, reject) => {
                let address = ""
                if (this.state.fromDirectUrl) {
                    address = `${this.state.allListings[this.state.indexOfCurrentCompany].street} ${this.state.allListings[this.state.indexOfCurrentCompany].houseNumber}, ${this.state.allListings[this.state.indexOfCurrentCompany].zipCode} ${this.state.allListings[this.state.indexOfCurrentCompany].city}, Netherlands`;
                } else {
                    address = `${street} ${houseNum}, ${postCode} ${city}, Netherlands`;
                }

                var config = {
                    method: "get",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        let coords = response.data.results[0].geometry.location;

                        let obj = {
                            lat: coords.lat,
                            lng: coords.lng,
                        }
                        resolve(obj)
                    })
                    .catch(function () {
                        let obj = {
                            lat: "",
                            lng: "",
                        }
                        resolve(obj)
                    });
            })
            Promise.all([promiseAccountDataEmailSend, promiseUploadImg, promiseGetLatLong]).then((data) => {
                let userId = data[0].id
                let snapUrl = data[1]
                let latitude = data[2].lat
                let longitude = data[2].lng

                let storageKey = firebase.database().ref("bhodi_campaigns/" + userId).push().key;
                let timestamp = Math.floor(Date.now() / 100);

                let obj = {
                    title: kortingsVoucherTitle,
                    desc: kortingsVoucherDesc,
                    kortingsVoucherImageType: this.state.campaignImagePreviewType,
                    kortingsVoucherPrijs: false,
                    kortingsVoucherPercentage: false,
                    campaignType: "kortings_voucher",
                    pushKey: storageKey,
                    image: snapUrl,
                    active: true,
                    created_by: userId,
                    timestamp,
                    number: "",
                    kortingsVoucherUnlimited: true,
                    created_at: new Date().getTime(),
                    kortingsVoucherSalePrice,
                    kortingsVoucherNormalPrice,
                    main_sector: this.state.selectedSector,
                    main_category: this.state.selectedMain,
                };
                if (this.state.currentFormType === "voucher-percentage") {
                    delete obj.kortingsVoucherSalePrice
                    delete obj.kortingsVoucherNormalPrice

                    obj.kortingsVoucherPercentage = true
                    obj.amount = kortingsVoucherAmount
                }
                if (this.state.currentFormType === "event") {
                    delete obj.kortingsVoucherSalePrice
                    delete obj.kortingsVoucherNormalPrice
                    delete obj.kortingsVoucherUnlimited
                    delete obj.kortingsVoucherImageType

                    obj.title = localEventTitle
                    obj.desc = localEventDesc
                    obj.campaignType = "local_event"
                    obj.localEventUnlimited = true
                    obj.localEventImageType = this.state.campaignImagePreviewType
                    obj.timings = arrTimings
                    obj.localEventPlaceHolderIsImage = true
                    obj.amount = localEventAmount
                    obj.discountAvailable = true

                    let type = "";
                    if (localEventPercentage) {
                        type = "percentage";
                    } else {
                        type = "price";
                    }

                    obj.localEventType = type
                }
                if (this.state.campaignImagePreviewType === "takefromlibrary") {
                    obj.mainSectorStock = mainSectorStock
                }

                firebase
                    .database()
                    .ref("bhodi_campaigns/" + userId + "/" + storageKey)
                    .set(obj)
                    .then(() => {
                        firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                        firebase.database().ref("users/" + userId + "/longitude").set(longitude)

                        if (this.state.fromDirectUrl) {
                            this.setState({ fourthStep: true, companyUrlRedirect: data[0].urlRedirect, companyUserId: userId })
                        } else {
                            setTimeout(() => {
                                window.location.href = data[0].urlRedirect
                            }, 500);
                        }
                    });
            })
        }
    }

    moveUserToCheckout() {
        const {
            firstName,
            lastName,
            email,
            password,
            plan_amount,
            plan_id,
            country,
            city,
            companyName,
            agreed,
            stripe_plan_key,
            isUserForm
        } = this.state;


        if (!this.state.plan_id) {
            this.setState({ mustSelectPlanId: true })

            setTimeout(() => {
                this.setState({ mustSelectPlanId: false })
            }, 2000);
            return;
        }

        let obj = {
            firstName,
            lastName,
            email,
            password,
            plan_amount,
            plan_id,
            country,
            city,
            companyName,
            agreed,
            stripe_plan_key,
            isUserForm
        }

        this.setState({ aanmeldenloader: true })

        this.props.subscribeAsCharityMember(obj)
    }

    onlyCreateAccount() {
        const {
            firstName,
            lastName,
            email,
            password,
            country,
            city,
            companyName,
            isUserForm
        } = this.state;


        let obj = {
            firstName,
            lastName,
            email,
            password,
            country,
            city,
            companyName,
            isUserForm
        }

        this.setState({ aanmeldenloaderNew: true })

        this.props.createAccountOnly(obj)
    }

    resendEmail() {
        this.setState({ loaderSendingEmail: true })

        let e = this.state.sendEmailPopupMeta

        let unique_url = `${window.location.origin}/loginCompanyDirectly?email=${e.email}&key=${e.password}&industry=${e.industry}`

        if (this.state.isManualInfo) {
            unique_url = `${window.location.origin}/onboard-company?email=${e.email}&key=${e.password}&industry=${e.industry}`
        }

        var dataForAPI = JSON.stringify({
            sendToEmail: e.email,
            subject: "Your invite link!",
            innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>Here goes your link!</h2><p>Link: ${unique_url}</p></body></html>`,
        });

        var config = {
            method: "post",
            url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendEmailMailjet",
            headers: {
                "Content-Type": "application/json",
            },
            data: dataForAPI,
        };

        axios(config)
            .then((response) => {
                if (response.data.statusCode === 201) {
                    swal({
                        title: "Cool",
                        text: "Email sent successfully",
                        icon: "success",
                    }).then(() => {
                        this.props.closeScreen()
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : <div className={`global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink onboarduserspage ${this.state.isSliding && "slide-right"}`}>
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                {this.state.sendEmailPopup && (<div className='sending-email-screen'>
                    <div className='seemsc-popup'>
                        <div>
                            <MdClose style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ sendEmailPopup: false })
                            }} />
                        </div>
                        <h3>Sorry</h3>
                        <p>
                            Je e-mailadres is reeds in gebruik, stuur je wachtwoord opnieuw.
                        </p>
                        <div>
                            {this.state.loaderSendingEmail ? (<div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>) : (<button onClick={() => this.resendEmail()}>Resend Email</button>)}
                        </div>
                    </div>
                </div>)}
                <div className="left-gec">
                    {window.innerWidth > 1008 && !this.state.fourthStep && (<div className="button-back-lgec" onClick={() => {
                        if (this.state.thirdStep) {
                            this.setState({ thirdStep: false, secondStep: true })
                        } else if (this.state.secondStep) {
                            this.setState({ secondStep: false })
                        } else {
                            this.props.closeScreen()
                        }
                    }}>
                        <ChevronLeftIcon />
                    </div>)}
                    <div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label>Aanmelden</label>
                            <label>Account gegevens</label>
                            {this.state.secondStep ? (<label style={{ maxWidth: "100%" }}>
                                Voer je gegevens in en selecteer je plaats. <br />
                                Zodat we je dichtstbijzijnde aanbiedingen kunnen laten zien.
                            </label>) : !this.state.thirdStep && (<label>
                                Leuk dat je mee wilt gaan met Bhodi!<br />
                                Maak eenvoudig je Bhodi account aan.
                            </label>)}
                        </div>
                        <div className="forms-clgec">
                            {this.state.thirdStep ? (<>
                                <div className="selecteer-tooltipdes" style={{ position: "relative" }}>
                                    <p
                                        className="text-clgec-charity texboldclged"
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            color: "rgb(33, 37, 41)",
                                        }}
                                    >
                                        Selecteer je bijdrage
                                    </p>
                                    <span
                                        className="tooltiptext tooltipinfo"
                                        style={{ display: this.state.mustSelectPlanId ? "inline-flex" : "none" }}
                                    >
                                        Selecteer
                                    </span>
                                </div>
                                <div className="radios-charity-lfss" style={{ marginTop: 20 }}>
                                    {this.state.subscription_data.length > 0 &&
                                        [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                            if (!e.active) {
                                                return false;
                                            }
                                            return (
                                                <div className="inner-rclfss">
                                                    <input
                                                        type="radio"
                                                        id="radiocharity"
                                                        name="charity"
                                                        value={e.plan_id}
                                                        checked={e.plan_id === this.state.plan_id}
                                                        onChange={() =>
                                                            this.setState({
                                                                plan_id: e.plan_id,
                                                                stripe_plan_key: e.stripe_plan_key,
                                                                plan_amount: e.price,
                                                                split_amount: e.split_amount,
                                                            })
                                                        }
                                                    />
                                                    <span>€ {Number(e.price)}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                                {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                    Alle aangesloten winkeliers en aanbieders stellen hun exclusieve&nbsp;
                                    <br />
                                    pop-up events en acties speciaal beschikbaar voor Charity Members.
                                </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 350 }}>
                                    De exclusieve pop-up events en acties zijn alleen beschikbaar voor Charity Members.
                                </p>)}
                                {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: window.innerWidth > 1008 ? 550 : "90%" }}>
                                    Als Charity Member ondersteun je kinderen en scholen in Tanzania&nbsp;
                                    <br />
                                    met maaltijden en materialen. Van deze uitgifte ontvang je een&nbsp;
                                    <br />
                                    persoonlijke en op naam ingesproken bedankvideo.
                                </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 335 }}>
                                    Als Charity Member ondersteun je kinderen en scholen in Tanzania met maaltijden en materialen. Van deze uitgifte ontvang je een persoonlijke bedankvideo van ons.
                                </p>)}
                                {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                    Jouw maandelijkse bijdrage aan Bhodi kun je heel eenvoudig&nbsp;
                                    <br />
                                    en op ieder gewenst moment zelf aanpassen of stopzetten.
                                </p>) : <p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                    Jouw maandelijkse bijdrage kun je op ieder gewenst moment aanpassen of stopzetten.
                                </p>}
                            </>) : this.state.secondStep ? (<>
                                <div className={`form-field-clgec ${this.state.firstNamePopup ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder="Voornaam"
                                        value={this.state.firstName}
                                        onChange={(e) =>
                                            this.setState({ firstName: e.target.value })
                                        }
                                    />
                                </div>
                                <div className={`form-field-clgec ${this.state.lastNamePopup ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder="Achternaam"
                                        value={this.state.lastName}
                                        onChange={(e) =>
                                            this.setState({ lastName: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="form-field-clgec switchformclgec">
                                    {this.state.isUserForm ? (
                                        <label>Consument</label>
                                    ) : (
                                        <label>Zakelijk</label>
                                    )}
                                    <Switch
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                        checked={this.state.isUserForm}
                                        onClick={() => {
                                            this.setState({
                                                isUserForm: !this.state.isUserForm,
                                            });
                                        }}
                                    />
                                </div>
                                {!this.state.isUserForm && (<div className={`form-field-clgec ${this.state.companyNamePopup ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder="Bedrijfsnaam"
                                        value={this.state.companyName}
                                        onChange={(e) =>
                                            this.setState({ companyName: e.target.value })
                                        }
                                    />
                                </div>)}

                                <div className={`form-field-clgec selectcountry-cfglpn`}>
                                    <select
                                        value={this.state.country}
                                        onChange={(e) => {
                                            this.setState({ country: e.target.value });
                                        }}
                                    >
                                        {countries.map((e) => {
                                            return <option value={e.id} key={Math.random()}>{e.name}</option>;
                                        })}
                                    </select>
                                    <KeyboardArrowDownIcon style={{ color: "black" }} />
                                </div>
                                {/* <div className={`container-field-cglpn ${this.state.cityPopup ? "containerRedAlerCglpn" : ""} citypickercontainer`}> */}
                                <div className={`form-field-clgec  citypickercontainer`}>
                                    <GooglePlacesAutocomplete
                                        apiKey="AIzaSyBing0s1IsYV0GaPy2Fu513--sq5EPGLFg"
                                        autocompletionRequest={{ types: ["locality"] }}
                                        selectProps={{
                                            value: this.state.checkCity,
                                            placeholder: "Plaats",
                                            onChange: (val) => {
                                                this.setState({ checkCity: val });
                                                geocodeByPlaceId(val.value.place_id)
                                                    .then((results) => {
                                                        let filtered = results[0].address_components.filter(
                                                            (e) => {
                                                                return (
                                                                    e.types[0] === "locality" &&
                                                                    e.types[1] === "political"
                                                                );
                                                            }
                                                        );
                                                        this.setState({ city: filtered[0].long_name })
                                                    })
                                                    .catch((error) => console.error(error));
                                            },
                                        }}
                                    />
                                </div>
                                {this.state.cityPopup && (
                                    <div className="alert-field">
                                        <span style={{ letterSpacing: 1 }}>
                                            Please select city
                                        </span>
                                    </div>
                                )}
                            </>) : (<>
                                <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='E-mail'
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                    {this.state.emailExisting && <label style={{ color: "red", paddingLeft: 10, marginTop: 10, marginBottom: 0 }}>Email already exists</label>}
                                </div>
                                <div className={`form-field-clgec ${this.state.passInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Kies wachtwoord'
                                        type='password'
                                        value={this.state.password}
                                        onChange={(e) => {
                                            this.setState({ password: e.target.value })
                                        }}
                                    />
                                </div>
                                <div
                                    className="fx-registration-inner"
                                    style={{ alignItems: "center" }}
                                >
                                    <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                        <div
                                            className="left-frvn"
                                            style={{
                                                width: "100%",
                                                paddingLeft: 10,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <div className="checking-box">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.agreed}
                                                    onChange={() =>
                                                        this.setState({ agreed: !this.state.agreed })
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        color: this.state.agreedPopup ? "red" : "black",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <font
                                                        style={{
                                                            textDecorationLine: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                "_blank" // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Akkoord met voorwaarden
                                                    </font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                            {/* {window.innerWidth < 1008 && (<div className="steps-container-main">
                                <div className={`scm-inner-steps ${!this.state.thirdStep && !this.state.secondStep && "active"}`}>

                                </div>
                                <div className={`scm-inner-steps ${this.state.secondStep && "active"}`}>

                                </div>
                                <div className={`scm-inner-steps ${this.state.thirdStep && "active"}`}>

                                </div>
                                {this.state.thirdStep ? (<label>3/3</label>) : this.state.secondStep ? (<label>2/3</label>) : (<label>1/3</label>)}
                            </div>)} */}
                            <div className="bottom-buttons-clgec">
                                {/* {window.innerWidth < 1008 && !this.state.aanmeldenloader && (
                                    <button onClick={() => history.goBack()}>
                                        {" "}
                                        <ChevronLeftIcon />{" "}
                                    </button>
                                )} */}
                                {this.state.thirdStep && !this.state.aanmeldenloaderNew && (<button onClick={() => {
                                    this.onlyCreateAccount()
                                }}>Overslaan</button>)}
                                {this.state.aanmeldenloaderNew && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        // <button style={{ width: "100%" }}>
                                        //     <div className="lds-ellipsis">
                                        //         <div></div>
                                        //         <div></div>
                                        //         <div></div>
                                        //         <div></div>
                                        //     </div>
                                        // </button>
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            if (this.state.thirdStep) {
                                                this.moveUserToCheckout()
                                            } else if (this.state.secondStep) {
                                                this.moveToThirdStepDirect()
                                            } else {
                                                this.moveToSecondStepDirect()
                                            }
                                        }}
                                    >
                                        {this.state.thirdStep ? "Afronden" : "Volgende"}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                <div className="steps-container-main">
                                    <div className={`scm-inner-steps ${!this.state.thirdStep && !this.state.secondStep && "active"}`}>

                                    </div>
                                    <div className={`scm-inner-steps ${this.state.secondStep && "active"}`}>

                                    </div>
                                    <div className={`scm-inner-steps ${this.state.thirdStep && "active"}`}>

                                    </div>
                                    {this.state.thirdStep ? (<label>3/3</label>) : this.state.secondStep ? (<label>2/3</label>) : (<label>1/3</label>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        {/* <h2>BHODI</h2> */}
                        <div className="popup-opener-stogec">
                            <div className="button-back-lgec" onClick={() => {
                                if (this.state.thirdStep) {
                                    this.setState({ thirdStep: false, secondStep: true })
                                } else if (this.state.secondStep) {
                                    this.setState({ secondStep: false })
                                } else {
                                    this.props.closeScreen()
                                }
                            }}>
                                <ChevronLeftIcon />
                            </div>
                        </div>
                    </div>
                )}


                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
        campaigns_category: state.categoriesReducer.campaigns_category,
        categories_vouchers: state.categoriesReducer.categories_vouchers,
        stock_images: state.globalReducers.stock_images,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
        updateCategoriesVouchers: (categories_vouchers) => dispatch(updateCategoriesVouchers(categories_vouchers)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupComponent);