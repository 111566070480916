import React, { Component } from "react";
import logoheader from "../../assets/logoheaderlifestyleblack.png";
import logoheaderWhite from "../../assets/logoheaderlifestyle.png";
import "./index.css";
import { GiHotMeal } from "react-icons/gi";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiDoubleQuotesR } from "react-icons/ri";
import { AiFillGift } from "react-icons/ai";
import MenuIcon from "@material-ui/icons/Menu";
import swal from "sweetalert";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase";
import countries from "../../DataJSON/countries.json";
import Switch from "@material-ui/core/Switch";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { connect } from "react-redux";
import {
  updateAdminOrNot,
  updateGlobalStuff,
  updateAlbumToAdd,
} from "../../Redux/actions/authActions";
import CheckIcon from "@material-ui/icons/Check";
import FooterBasket from "../../Components/FooterBasket";
import PersonIcon from "@material-ui/icons/Person";
import Footer from "../../Components/Footer";
import axios from "axios";
import FooterGreen from "../../Components/FooterGreen";
import { Link } from "react-router-dom";

AOS.init();

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class LoginUpdated extends Component {
  constructor() {
    super();
    this.state = {
      // events: [
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/denham.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     },
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/butcher.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     },
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/boss.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     },
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/catwalkjunkie.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     }
      // ],
      showDutch: true,
      events: [],
      menuOpen: false,
      allUsers: [],
      country: "155",
      isUserForm: true,
      showCharityStuff: false,
      subscription_data: [],
      plan_id: "",
      plan_amount: "",
      // registration fields
      firstName: "",
      firstNamePopup: false,
      lastName: "",
      lastNamePopup: false,
      email: "",
      emailPopup: false,
      password: "",
      passwordPopup: false,
      country: "155",
      companyRegistration: false,
      companyName: "",
      kvkCompany: "",
      kvkCompanyPopup: false,
      street: "",
      streetPopup: false,
      houseNumber: "",
      houseNumberPopup: false,
      city: "",
      cityPopup: false,
      zipCode: "",
      zipCodePopup: false,
      phoneNumber: "",
      phoneNumberPopup: false,
      company_website: "",
      company_websitePopup: false,
      passwordInputType: "password",
      guestUserCart: [],
      aanmeldenloader: false,
      allUsers: [],
      agreed: false,
      agreedPopup: false,
      currentUserSpecific: "",
      isUserCreated: false,
      currentDataOfUser: "",
      popupopenforright: true,
      isAdmin: false,
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        let uid = localStorage.getItem("userid");
        if (uid) {
          window.history.forward();
        }
      }
    });

    firebase
      .database()
      .ref("users")
      .once("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a && !this.state.stopTakingData) {
          let objected = Object.entries(a);
          objected.map((u) => {
            arr.push(u[1]);
          });
          this.setState({ allUsers: arr });
        } else {
          this.setState({ allUsers: [] });
        }
      });

    let isAdmin = this.props.admin_boolean;
    if (isAdmin) {
      this.setState({ isAdmin: true });
    } else {
      this.setState({ isAdmin: false });
    }

    let res = axios.get(
      "https://us-central1-bhodi-7c327.cloudfunctions.net/getIpLocation"
    );
    res.then((output) => {
      if (output.data.deta) {
        if (output.data.deta.country === "NL") {
          this.setState({ showDutch: true });
        } else {
          this.setState({ showDutch: false });
        }
      }
    });

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        this.setState({
          aboutContent: a.about_text,
          salesContent: a.sales_text,
          charityContent: a.charity_text,
          socialsContent: a.socials_text,
          profilesContent: a.profiles_text,
          registrationList1: a.registration_list_1,
          registrationList2: a.registration_list_2,
          registrationList3: a.registration_list_3,
          registrationList4: a.registration_list_4,
          loginList1: a.login_list_1,
          loginList2: a.login_list_2,
          loginList3: a.login_list_3,
          socialsList1: a.socials_list_1,
          socialsList2: a.socials_list_2,
          socialsList3: a.socials_list_3,
          socialsList4: a.socials_list_4,
          socialsList5: a.socials_list_5,
          socialsList6: a.socials_list_6,
          profileList1: a.profile_list_1,
          profileList2: a.profile_list_2,
          profileList3: a.profile_list_3,
          profileList4: a.profile_list_4,
          profileList5: a.profile_list_5,
          profileList6: a.profile_list_6,

          reg_login_about_dutch_heading: a.reg_login_about_dutch_heading,
          reg_login_about_english_heading: a.reg_login_about_english_heading,
          reg_login_team_bhodi_dutch_heading:
            a.reg_login_team_bhodi_dutch_heading,
          reg_login_team_bhodi_english_heading:
            a.reg_login_team_bhodi_english_heading,
          reg_login_about_dutch: a.reg_login_about_dutch,
          reg_login_about_english: a.reg_login_about_english,
          reg_login_team_bhodi_dutch: a.reg_login_team_bhodi_dutch,
          reg_login_team_bhodi_english: a.reg_login_team_bhodi_english,
          reg_login_about_dutch_heading_mobile:
            a.reg_login_about_dutch_heading_mobile,
          reg_login_about_english_heading_mobile:
            a.reg_login_about_english_heading_mobile,
          reg_login_team_bhodi_dutch_heading_mobile:
            a.reg_login_team_bhodi_dutch_heading_mobile,
          reg_login_team_bhodi_english_heading_mobile:
            a.reg_login_team_bhodi_english_heading_mobile,
          reg_login_about_dutch_mobile: a.reg_login_about_dutch_mobile,
          reg_login_about_english_mobile: a.reg_login_about_english_mobile,
          reg_login_team_bhodi_dutch_mobile:
            a.reg_login_team_bhodi_dutch_mobile,
          reg_login_team_bhodi_english_mobile:
            a.reg_login_team_bhodi_english_mobile,
        });
        if (a.about_image) {
          this.setState({ aboutImage: a.about_image, aboutVideo: "" });
        } else {
          this.setState({ aboutVideo: a.about_video, aboutImage: "" });
        }
        if (a.sales_image) {
          this.setState({ salesImage: a.sales_image, salesVideo: "" });
        } else {
          this.setState({ salesVideo: a.sales_video, salesImage: "" });
        }
        if (a.charity_image) {
          this.setState({ charityImage: a.charity_image, charityVideo: "" });
        } else {
          this.setState({ charityVideo: a.charity_video, charityImage: "" });
        }
        if (a.socials_image) {
          this.setState({ socialsImage: a.socials_image, socialsVideo: "" });
        } else {
          this.setState({ socialsVideo: a.socials_video, socialsImage: "" });
        }
        if (a.profiles_image) {
          this.setState({ profilesImage: a.profiles_image, profilesVideo: "" });
        } else {
          this.setState({ profilesVideo: a.profiles_video, profilesImage: "" });
        }
        if (a.main_image) {
          this.setState({ mainImage: a.main_image, mainVideo: "" });
        } else {
          this.setState({ mainVideo: a.main_video, mainImage: "" });
        }
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          this.setState({
            aboutContent: a.about_text,
            salesContent: a.sales_text,
            charityContent: a.charity_text,
            socialsContent: a.socials_text,
            profilesContent: a.profiles_text,
            registrationList1: a.registration_list_1,
            registrationList2: a.registration_list_2,
            registrationList3: a.registration_list_3,
            registrationList4: a.registration_list_4,
            loginList1: a.login_list_1,
            loginList2: a.login_list_2,
            loginList3: a.login_list_3,
            socialsList1: a.socials_list_1,
            socialsList2: a.socials_list_2,
            socialsList3: a.socials_list_3,
            socialsList4: a.socials_list_4,
            socialsList5: a.socials_list_5,
            socialsList6: a.socials_list_6,
            profileList1: a.profile_list_1,
            profileList2: a.profile_list_2,
            profileList3: a.profile_list_3,
            profileList4: a.profile_list_4,
            profileList5: a.profile_list_5,
            profileList6: a.profile_list_6,

            reg_login_about_dutch_heading: a.reg_login_about_dutch_heading,
            reg_login_about_english_heading: a.reg_login_about_english_heading,
            reg_login_team_bhodi_dutch_heading:
              a.reg_login_team_bhodi_dutch_heading,
            reg_login_team_bhodi_english_heading:
              a.reg_login_team_bhodi_english_heading,
            reg_login_about_dutch: a.reg_login_about_dutch,
            reg_login_about_english: a.reg_login_about_english,
            reg_login_team_bhodi_dutch: a.reg_login_team_bhodi_dutch,
            reg_login_team_bhodi_english: a.reg_login_team_bhodi_english,
            reg_login_about_dutch_heading_mobile:
              a.reg_login_about_dutch_heading_mobile,
            reg_login_about_english_heading_mobile:
              a.reg_login_about_english_heading_mobile,
            reg_login_team_bhodi_dutch_heading_mobile:
              a.reg_login_team_bhodi_dutch_heading_mobile,
            reg_login_team_bhodi_english_heading_mobile:
              a.reg_login_team_bhodi_english_heading_mobile,
            reg_login_about_dutch_mobile: a.reg_login_about_dutch_mobile,
            reg_login_about_english_mobile: a.reg_login_about_english_mobile,
            reg_login_team_bhodi_dutch_mobile:
              a.reg_login_team_bhodi_dutch_mobile,
            reg_login_team_bhodi_english_mobile:
              a.reg_login_team_bhodi_english_mobile,
          });
          if (a.about_image) {
            this.setState({ aboutImage: a.about_image, aboutVideo: "" });
          } else {
            this.setState({ aboutVideo: a.about_video, aboutImage: "" });
          }
          if (a.sales_image) {
            this.setState({ salesImage: a.sales_image, salesVideo: "" });
          } else {
            this.setState({ salesVideo: a.sales_video, salesImage: "" });
          }
          if (a.charity_image) {
            this.setState({ charityImage: a.charity_image, charityVideo: "" });
          } else {
            this.setState({ charityVideo: a.charity_video, charityImage: "" });
          }
          if (a.socials_image) {
            this.setState({ socialsImage: a.socials_image, socialsVideo: "" });
          } else {
            this.setState({ socialsVideo: a.socials_video, socialsImage: "" });
          }
          if (a.profiles_image) {
            this.setState({
              profilesImage: a.profiles_image,
              profilesVideo: "",
            });
          } else {
            this.setState({
              profilesVideo: a.profiles_video,
              profilesImage: "",
            });
          }
          if (a.main_image) {
            this.setState({ mainImage: a.main_image, mainVideo: "" });
          } else {
            this.setState({ mainVideo: a.main_video, mainImage: "" });
          }
        }
      });
  }

  getCompanyName(createdBy) {
    let filtered = this.state.allUsers.filter((j) => {
      return j.user_id === createdBy;
    });
    if (filtered.length > 0) {
      return filtered[0].company_name.substr(0, 30);
    }
  }

  redirectCompany(createdBy) {
    let filtered = this.state.allUsers.filter((j) => {
      return j.user_id === createdBy;
    });
    if (filtered.length > 0) {
      window.location.href = `/company/${filtered[0].user_name_id}`;
    }
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  logIn() {
    const { email, password } = this.state;
    let regexEmail =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "" || !regexEmail.test(email)) {
      this.setState({ emailPopup: true, email: "" });
    } else {
      this.setState({ emailPopup: false });
    }
    if (password === "") {
      this.setState({ passwordPopup: true });
    } else {
      this.setState({ passwordPopup: false });
    }
    if (email !== "" && regexEmail.test(email) && password !== "") {
      this.setState({ aanmeldenloader: true });
      if (email === "admin@bhodi.com") {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((succ) => {
            firebase
              .database()
              .ref("users/" + succ.user.uid)
              .on("value", (data) => {
                localStorage.setItem("userid", succ.user.uid);
                // localStorage.setItem("userdata", JSON.stringify(data.val()))
                localStorage.setItem("isAdmin", true);
                this.props.updateAdminOrNot(true);
                localStorage.removeItem("onCurrentTab");
                localStorage.removeItem("onCurrentTabCompany");
                localStorage.removeItem("cookie_item");
                setTimeout(() => {
                  window.location.href = "/admin";
                }, 400);
              });
          })
          .catch((err) => {
            if (err.code === "auth/user-not-found") {
              this.setState({
                aanmeldenloader: false,
                emailPopup: true,
                email: "",
                password: "",
              });
            } else if (err.code === "auth/wrong-password") {
              this.setState({
                aanmeldenloader: false,
                passwordPopup: true,
                password: "",
              });
            } else {
              this.setState({
                aanmeldenloader: false
              })
              alert("Login failed, please try again")
            }
          });
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((succ) => {
            console.log(succ.user.uid);
            firebase
              .database()
              .ref("users/" + succ.user.uid)
              .on("value", (data) => {
                let a = data.val();
                if (a) {
                  if (a.type === "user") {
                    localStorage.removeItem("onStoriesActive");
                    this.props.updateAlbumToAdd("vrienden");
                  }
                  let promiseOne = new Promise((res) => {
                    if (a.type === "user") {
                      navigator.geolocation.getCurrentPosition((position) => {
                        firebase
                          .database()
                          .ref("users/" + succ.user.uid + "/latitude")
                          .set(position.coords.latitude);
                        firebase
                          .database()
                          .ref("users/" + succ.user.uid + "/longitude")
                          .set(position.coords.longitude);
                        localStorage.setItem(
                          "latitudeAfterLogin",
                          position.coords.latitude
                        );
                        localStorage.setItem(
                          "longitudeAfterLogin",
                          position.coords.longitude
                        );
                        res();
                      });
                    } else {
                      navigator.geolocation.getCurrentPosition((position) => {
                        localStorage.setItem(
                          "latitudeAfterLogin",
                          position.coords.latitude
                        );
                        localStorage.setItem(
                          "longitudeAfterLogin",
                          position.coords.longitude
                        );
                        res();
                      });
                    }
                    setTimeout(() => {
                      res();
                    }, 4000);
                  });
                  promiseOne.then(() => {
                    localStorage.setItem("userid", succ.user.uid);
                    // localStorage.setItem("userdata", JSON.stringify(a))
                    localStorage.setItem("isAdmin", false);
                    this.props.updateAdminOrNot(false);
                    localStorage.removeItem("onCurrentTab");
                    localStorage.removeItem("onCurrentTabCompany");
                    localStorage.setItem("currentAlbumOwnImage", "vrienden");
                    localStorage.setItem(
                      "currentAlbumOwnImageFriends",
                      "vrienden"
                    );
                    localStorage.removeItem("cookie_item");
                    setTimeout(() => {
                      if (a.type === "user") {
                        window.location.href = `/member/${a.user_name_id}`;
                        // if (window.innerWidth < 1009) {
                        //     this.setState({ loader: false })
                        // }
                      } else {
                        window.location.href = `/company/${a.user_name_id}`;
                        // if (window.innerWidth < 1009) {
                        //     this.setState({ loader: false })
                        // }
                      }
                    }, 400);
                  });
                }
              });
          })
          .catch((err) => {
            if (err.code === "auth/user-not-found") {
              this.setState({
                aanmeldenloader: false,
                emailPopup: true,
                email: "",
                password: "",
              });
            } else if (err.code === "auth/wrong-password") {
              this.setState({
                aanmeldenloader: false,
                passwordPopup: true,
                password: "",
              });
            } else {
              this.setState({
                aanmeldenloader: false
              })
              alert("Login failed, please try again")
            }
          });
      }
    }
  }

  sendResetLink() {
    const { email, password } = this.state;
    let regexEmail =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "" || !regexEmail.test(email)) {
      this.setState({ emailPopup: true, email: "" });
    } else {
      this.setState({ emailPopup: false });
    }
    if (email !== "" && regexEmail.test(email)) {
      this.setState({ aanmeldenloader: true });
      // firebase.auth().sendPasswordResetEmail(email)
      //     .then(() => {
      //         this.setState({ aanmeldenloader: false, forgetPassword: false })
      //     })
      //     .catch((error) => {
      //         var errorMessage = error.message;
      //         if (errorMessage === "There is no user record corresponding to this identifier. The user may have been deleted.") {
      //             this.setState({ emailNotFound: true, aanmeldenloader: false })
      //         }
      //     });
      var data = JSON.stringify({
        sendToEmail: email,
      });

      var config = {
        method: "post",
        url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendPasswordResetLinkUpdated",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data.statusCode === 201) {
            swal({
              title: "Gelukt!",
              text: "Binnen enkele minuten krijg je een e-mail met een link. Door op de link te klikken kun je een nieuw wachtwoord aanmaken.",
              icon: "success",
            }).then(() => {
              this.setState({ aanmeldenloader: false, forgetPassword: false });
            });
          }
        })
        .catch((response) => {
          var errorMessage = response.error.message;
          if (
            errorMessage ===
            "There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            this.setState({ emailNotFound: true, aanmeldenloader: false });
          }
        });
    }
  }

  createMarkupAboutRegLoginDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_about_dutch_heading) {
        return { __html: this.state.reg_login_about_dutch_heading };
      }
    } else {
      if (this.state.reg_login_about_dutch_heading_mobile) {
        return { __html: this.state.reg_login_about_dutch_heading_mobile };
      }
    }
  }

  createMarkupAboutRegLoginEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_about_english_heading) {
        return { __html: this.state.reg_login_about_english_heading };
      }
    } else {
      if (this.state.reg_login_about_english_heading_mobile) {
        return { __html: this.state.reg_login_about_english_heading_mobile };
      }
    }
  }

  createMarkupAboutRegLoginDutch() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_about_dutch) {
        return { __html: this.state.reg_login_about_dutch };
      }
    } else {
      if (this.state.reg_login_about_dutch_mobile) {
        return { __html: this.state.reg_login_about_dutch_mobile };
      }
    }
  }

  createMarkupAboutRegLoginEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_about_english) {
        return { __html: this.state.reg_login_about_english };
      }
    } else {
      if (this.state.reg_login_about_english_mobile) {
        return { __html: this.state.reg_login_about_english_mobile };
      }
    }
  }

  createMarkupTeamBhodiRegLoginDutch() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_team_bhodi_dutch) {
        return { __html: this.state.reg_login_team_bhodi_dutch };
      }
    } else {
      if (this.state.reg_login_team_bhodi_dutch_mobile) {
        return { __html: this.state.reg_login_team_bhodi_dutch_mobile };
      }
    }
  }

  createMarkupTeamBhodiRegLoginEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_team_bhodi_english) {
        return { __html: this.state.reg_login_team_bhodi_english };
      }
    } else {
      if (this.state.reg_login_team_bhodi_english_mobile) {
        return { __html: this.state.reg_login_team_bhodi_english_mobile };
      }
    }
  }

  createMarkupTeamBhodiRegLoginDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_team_bhodi_dutch_heading) {
        return { __html: this.state.reg_login_team_bhodi_dutch_heading };
      }
    } else {
      if (this.state.reg_login_team_bhodi_dutch_heading_mobile) {
        return { __html: this.state.reg_login_team_bhodi_dutch_heading_mobile };
      }
    }
  }

  createMarkupTeamBhodiRegLoginEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.reg_login_team_bhodi_english_heading) {
        return { __html: this.state.reg_login_team_bhodi_english_heading };
      }
    } else {
      if (this.state.reg_login_team_bhodi_english_heading_mobile) {
        return {
          __html: this.state.reg_login_team_bhodi_english_heading_mobile,
        };
      }
    }
  }

  render() {
    return (
      <div className="landingpage-bhodi registrationpagebhodiupdated loginpageupdated">
        {!this.props.dontshowmenu && <div className="header-lpbhodi">
          <Link className="link-style-trending" to="/">
            <img
              src={window.innerWidth > 1008 ? logoheaderWhite : logoheader}
            // onClick={() => {
            //   window.location.href = "/";
            // }}
            />
          </Link>
          {window.innerWidth > 1009 ? (
            <div className="menu-hlpbhodi">
              <ul>
                {/* <li>Accessories</li>
                            <li>Dames</li>
                            <li>Heren</li>
                            <li>Onze merken</li>
                            <li>Kus van kiki</li> */}
                <li
                // onClick={() => (window.location.href = "/")}
                >
                  <Link
                    to="/"
                    className="link-style-trending"
                  >
                    Home
                  </Link>
                </li>
                <li
                // onClick={() => (window.location.href = "/charity-page" )}
                >
                  <Link to="/charity-page" className="link-style-trending">
                    Charity
                  </Link>
                </li>
                <li>
                  <Link to="/gift" className="link-style-trending">
                    Relatiegeschenk
                  </Link>
                </li>
                <li
                //  onClick={() => (window.location.href = "/commercial")}
                >
                  <Link to="/commercial" className="link-style-trending">
                    Zakelijk
                  </Link>
                </li>
                <li
                // onClick={() =>
                //   (window.location.href = "/registration")
                // }
                >
                  <Link
                    to="/registration"
                    className="link-style-trending"
                  >
                    Aanmelden
                  </Link>
                </li>
                {/* <li className='active' onClick={() => window.location.href = "/login"}>Inloggen</li> */}
              </ul>
            </div>
          ) : (
            <div className="menu-hlpbhodi">
              {/* <p>Selecteer een pagina</p> */}
              {!this.state.menuOpen ? (
                <MenuIcon onClick={() => this.setState({ menuOpen: true })} />
              ) : (
                <CloseIcon onClick={() => this.setState({ menuOpen: false })} />
              )}
            </div>
          )}
          {window.innerWidth < 1009 && (
            <div
              className={`menu-open-hlpbhodi ${this.state.menuOpen && "active"
                }`}
            >
              <ul>
                {/* <li>Accessories</li>
                            <li>Dames</li>
                            <li>Heren</li>
                            <li>Onze merken</li>
                            <li>Kus van kiki</li> */}
                <li
                // onClick={() => (window.location.href = "/")}
                >
                  <Link
                    className="link-style-trending"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li
                // onClick={() => (window.location.href = "/commercial")}
                >
                  <Link to="/commercial" className="link-style-trending">
                    Zakelijk
                  </Link>
                </li>
                <li

                // onClick={() => (window.location.href = "/charity-page")}
                >
                  <Link to="/charity-page" className="link-style-trending">
                    Charity
                  </Link>
                </li>
                <li>
                  <Link to="/gift" className="link-style-trending">
                    Relatiegeschenk
                  </Link>
                </li>
                <li
                // onClick={() =>
                //   (window.location.href = "/registration")
                // }
                >
                  <Link
                    to="/registration"
                    className="link-style-trending"
                  >Aanmelden</Link>
                </li>
                {/* <li
                  className="active"
                onClick={() => (window.location.href = "/login")}
                >
                  <Link to="/login" className="link-style-trending">
                    Inloggen
                  </Link>
                </li> */}
              </ul>
            </div>
          )}
          <div className="icons-hlpbhodi">
            <Link className="link-style-trending" to="/login">
              <PersonIcon
              // onClick={() => (window.location.href = "/login")}
              />
            </Link>
          </div>
          <FooterBasket />
        </div>}
        {/* <div className="second-section-background-lpb favroietesection">
                    <h2 className="heading-ssblbp" data-aos="fade-in">Charity</h2>
                    <p className="paragraph-ssblbp" data-aos="fade-in">Gekleed fashion label speciaal <br /> ontworpen voor heren</p>
                </div> */}
        <div className="third-section-background-lpb">
          {this.state.forgetPassword ? (
            <div className="left-tsblpb" style={{ backgroundColor: "#f7f7f7" }}>
              <h2 data-aos="fade-in">Login</h2>
              <div className="forms-clgec">
                <div
                  className={`form-field-clgec ${this.state.emailPopup ? "redalertffcl" : ""
                    }`}
                >
                  <label>Contact</label>
                  <input
                    type="email"
                    placeholder={!this.state.emailPopup ? "Email adres" : "E-mail adres is niet gevonden"}
                    autoComplete="off"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  {this.state.emailNotFound && (
                    <div className="alert-field" style={{ width: "100%" }}>
                      <span style={{ letterSpacing: 1 }}>
                        Email not founded in our records
                      </span>
                    </div>
                  )}
                </div>
                <div className="bottom-buttons-clgec">
                  {this.state.aanmeldenloader ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <button
                      onClick={this.sendResetLink.bind(this)}
                      style={{ backgroundColor: "#C17F6B" }}
                    >
                      Send
                    </button>
                  )}
                </div>
                <div
                  className="selectoptionfull"
                  style={{ marginTop: 25, width: "100%" }}
                >
                  <div
                    className="left-frvn"
                    style={{
                      width: "100%",
                      paddingLeft: 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    <label
                      style={{ fontSize: 13, color: "black" }}
                      onClick={() => this.setState({ forgetPassword: false })}
                    >
                      Login
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="left-tsblpb" style={{ backgroundColor: "#f7f7f7" }}>
              <h2 data-aos="fade-in">Login</h2>
              <div className="forms-clgec">
                <div
                  className={`form-field-clgec ${this.state.emailPopup ? "redalertffcl" : ""
                    }`}
                >
                  <label>Contact</label>
                  <input
                    type="email"
                    placeholder={!this.state.emailPopup ? "Email adres" : "E-mail adres is niet gevonden"}
                    autoComplete="off"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  {this.state.emailTookedPopup && (
                    <div className="alert-field">
                      <span style={{ letterSpacing: 1 }}>
                        Email already taken
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={`form-field-clgec ${this.state.passwordPopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    type={this.state.passwordInputType}
                    autoComplete="off"
                    placeholder="Kies wachtwoord"
                    value={this.state.password}
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                </div>
                <div className="bottom-buttons-clgec">
                  {this.state.aanmeldenloader ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <button
                      onClick={this.logIn.bind(this)}
                      style={{ backgroundColor: "#C17F6B" }}
                    >
                      Inloggen
                    </button>
                  )}
                </div>
                <div
                  className="selectoptionfull"
                  style={{ marginTop: 25, width: "100%" }}
                >
                  <div
                    className="left-frvn"
                    style={{
                      width: "100%",
                      paddingLeft: 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    <label
                      style={{ fontSize: 13, color: "black" }}
                      onClick={() =>
                        this.setState({
                          forgetPassword: true,
                          emailPopup: false,
                        })
                      }
                    >
                      Wachtwoord vergeten?
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="right-tsblpb" style={{ width: "80%" }}>
            <video
              src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/charity.mp4?alt=media&token=13699f6f-4811-47b1-8050-94fab4be6494"
              autoPlay
              muted
              loop
              playsInline
              style={{ maxHeight: window.innerWidth > 1920 && "none" }}
            ></video>
          </div>
        </div>
        <div className="second-section-background-lpb favroietesection">
          <h2 className="heading-ssblbp" data-aos="fade-in">
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutRegLoginDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutRegLoginEnglishHeading()}
              ></div>
            )}
          </h2>
          <p className="paragraph-ssblbp" data-aos="fade-in">
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutRegLoginDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutRegLoginEnglish()}
              ></div>
            )}
            {/* In Tanzania zijn er veel schoolkinderen die overdags vrijwel geen eten krijgen,<br />
                        wij vinden dat iedereen recht heeft op de primaire levensbehoeftes.<br />
                        Met bhodi ondersteunen wij kinderen en scholen in Tanzania met maaltijden. */}
          </p>
        </div>
        <div className="sixth-section-lpb">
          <div className="container-sslpb">
            <h2>
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiRegLoginDutchHeading()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiRegLoginEnglishHeading()}
                ></div>
              )}
            </h2>
            <div className="circle-csslpb">
              <RiDoubleQuotesR />
            </div>
            <p className="para-desc-sslpb">
              {/* Bhodi is een social commerce platform<br />en staan wij voor impact first en meer<br />gelijkheid in de wereld.
                            <br />
                            <br />
                            In samenwerking met verschillende winkeliers<br />en dienstverleners stellen
                            wij exclusieve events<br />beschikbaar voor de Bhodi charity members. */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiRegLoginDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiRegLoginEnglish()}
                ></div>
              )}
            </p>
            {/* <p className='name-desc-sslpb'>Maurice Reijersen & Naomi Latuperissa</p> */}
          </div>
        </div>
        <div className="footer-section-lpb">
          <div className="container-fslpb">
            <div className="block-cfslpb">
              <h3 data-aos="fade-in">Charity</h3>
              <p data-aos="fade-in">Maaltijden voor kinderen</p>
            </div>
            <div className="block-cfslpb">
              <h3 data-aos="fade-in">Events</h3>
              <p data-aos="fade-in">Leuke pop-up sale events</p>
            </div>
            <div className="block-cfslpb">
              <h3 data-aos="fade-in">Retailers</h3>
              <p data-aos="fade-in">Lokale aanbieders</p>
            </div>
          </div>
          {/* <div className="container-fslpb iconscontainer">
            <div className="block-cfslpb">
              <FaFacebookF />
            </div>
            <div className="block-cfslpb">
              <BsTwitter />
            </div>
            <div className="block-cfslpb">
              <AiOutlineInstagram />
            </div>
          </div> */}
        </div>
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
    user: state.authReducers.user,
    admin_boolean: state.globalReducers.admin_boolean,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
    updateAdminOrNot: (admin_boolean) =>
      dispatch(updateAdminOrNot(admin_boolean)),
    updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginUpdated);
