import React, { Component } from 'react'
import playicon from "../../assets/playiconlogomobile.png"
import Footer from '../../Components/Footer'
import FooterGreen from '../../Components/FooterGreen'
import { connect } from 'react-redux'
import { history } from '../../Config/Routes'
import ButtonTimerProductsNew from '../../Components/ButtonTimerProductsNew'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import VouchersNewSection from '../../Components/VouchersNewSection'
import EventsNewSection from '../../Components/EventsNewSection'
import axios from "axios";
import NewProductWrapper from '../ProductsPage/NewProductsWrapper'
import { mergeAdminEvent } from '../../Functions/globalFunctions'
import VouchersNewGridDesktop from '../../Components/VouchersNewGridDesktop'
import VouchersNewMobile from '../VouchersNewMobile'

export class CompanyTrending extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: "",
            adminProduct: "",
            allFourProducts: [],
            memberFirstHeading: "",
            bhodiNewsHeading: "",
            bhodiNewsText: "",
            memberSecondHeading: "",
            dashboardMemberDesc: "",
            currentCampaignToShow: "",
            isDesktop: false,
            localEventBlock: [],
            showUploadPopup: false,
            currentUser: "",
            allImagesStock: [],
            allSectors: [],
            mainSector: "",
            mainSectorStock: "",
            showDesktopLanding: true,
            allImagesCurrentSectorStock: [],
            latestEventOrVoucher: "",
            latestEventOrVoucherSecond: "",
        }
        this.sectionRef = React.createRef();
        this.charityRef = React.createRef();
        this.contactRef = React.createRef();
        this.sectionRefProducts = React.createRef();
    }


    scrollToSection = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRef.current) {
            this.sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToProducts = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRefProducts.current) {
            this.sectionRefProducts.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToCharity = () => {
        // Find the target section by its ref and scroll to it
        setTimeout(() => {
            if (this.charityRef.current) {
                this.charityRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    };

    scrollToContact = () => {
        // Find the target section by its ref and scroll to it
        setTimeout(() => {
            if (this.contactRef.current) {
                this.contactRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
        // window.scrollTo(0, document.body.scrollHeight)
    };

    componentDidMount() {
        if (window.location.href.includes("/charity")) {
            this.scrollToCharity()
        }
        if (window.location.href.includes("/contact")) {
            this.scrollToContact()
        }

        if (document.querySelector(".nav-fullwidth")) {
            document.querySelector(".nav-fullwidth").classList.add("navbarfixedpositionupdatedmember")
        }

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });
            }
        }

        if (this.props.stock_images) {
            this.setState({ allImagesStock: this.props.stock_images })
        }

        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";

        let userid = localStorage.getItem("userid")
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
        }
        this.setState({ currentUserId: userid })

        // let htmlsForVouchers = ""


        // let currentProduct = this.props.bhodi_products[0]
        // console.log(currentProduct, '/see here')

        // let allCampaigns = [
        //     {
        //         title: currentProduct.title,
        //         description: currentProduct.desc,
        //     }
        // ]

        // allCampaigns.map((t) => {
        //     htmlsForVouchers += `<div><h3>${t.title}</h3><p>${t.description}</p></div>`
        // })


        // var dataForAPI = JSON.stringify({
        //     sendToEmail: "fahimrazaofficial2022@gmail.com",
        //     subject: "Order Confirmation at Bhodi!",
        //     innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>Congratulations On Your Order!</h2><p>Your order is processed at Bhodi.</p>${htmlsForVouchers}</body></html>`,
        // });

        // var config = {
        //     method: "post",
        //     url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendEmailMailjet",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     data: dataForAPI,
        // };

        // axios(config)
        //     .then((response) => {
        //         if (response.data.statusCode === 201) {
        //             console.log(response)
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });

        // if (this.props.bhodi_products && userid) {
        //     let filtered = this.props.bhodi_products.filter((g) => {
        //         return g.created_by === process.env.REACT_APP_ADMIN_ID && g.is_bookmarked
        //     })
        //     if (filtered.length > 0) {
        //         let indexOfImage = 0

        //         let allImagesNew = []
        //         if (filtered[0].images && filtered[0].images.length > 0) {
        //             let duplicated = Array.from(filtered[0].images)

        //             let allImagesUrl = []
        //             duplicated.map((g) => {
        //                 if (g) {
        //                     let filePath = g.split("/")[7].split("?")[0]
        //                     allImagesUrl.push(filePath)
        //                 }
        //             })

        //             allImagesUrl.sort().map((t) => {
        //                 let filtered = duplicated.filter((g) => {
        //                     return g.includes(t)
        //                 })

        //                 if (filtered.length > 0) {
        //                     allImagesNew.push(filtered[0])
        //                 }
        //             })
        //             if (typeof filtered[0].featuredImage !== "undefined" && filtered[0].featuredImage !== null) {
        //                 if (filtered[0].featuredImage < filtered[0].images.length) {
        //                     let filteredIndex = allImagesNew.findIndex((g) => {
        //                         return g === filtered[0].images[filtered[0].featuredImage]
        //                     })
        //                     if (filteredIndex !== -1) {
        //                         indexOfImage = filteredIndex
        //                     }
        //                 }
        //             }
        //         }

        //         filtered[0].allImagesNew = allImagesNew
        //         filtered[0].indexOfImage = indexOfImage

        //         this.setState({ adminProduct: filtered[0] })
        //     }

        // let filteredProducts = this.props.bhodi_products.filter((t) => {
        //     return !t.availableOnTime && t.images && !t.is_bookmarked && !t.isSpecialProduct && !t.availableForCompanyGuest && t.created_by === process.env.REACT_APP_ADMIN_ID
        // })
        // let sortedProducts = [...filteredProducts].sort((a, b) => {
        //     return b.timestamp * 100 - a.timestamp * 100;
        // });
        // if (sortedProducts.length > 0) {
        //     this.setState({ allFourProducts: [...sortedProducts].splice(0, 4) })
        // }

        // }

        let companyPageText = ""
        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                const {
                    memberFirstHeading,
                    memberSecondHeading,
                    dashboardMemberDesc,
                    memberDashboardMobileUrl,
                    memberDashboardMobileType,
                    memberDashboardDesktopUrl,
                    memberDashboardDesktopType,
                    bhodiNewsHeading,
                    bhodiNewsText,
                } = a;

                companyPageText = a.companyPageText;

                let charityImage = ""
                let charityVideo = ""

                if (a.charity_image) {
                    charityImage = a.charity_image
                    charityVideo = ""
                    // this.setState({ charityImage: a.charity_image, charityVideo: "" });
                } else {
                    charityVideo = a.charity_video
                    charityImage = ""
                    // this.setState({ charityVideo: a.charity_video, charityImage: "" });
                }

                this.setState({
                    memberFirstHeading: memberFirstHeading || "",
                    memberSecondHeading: memberSecondHeading || "",
                    dashboardMemberDesc: dashboardMemberDesc || "",
                    memberDashboardMobileUrl: memberDashboardMobileUrl || "",
                    memberDashboardMobileType: memberDashboardMobileType || "",
                    memberDashboardDesktopUrl: memberDashboardDesktopUrl || "",
                    memberDashboardDesktopType: memberDashboardDesktopType || "",
                    companyPageText: companyPageText || "",
                    bhodiNewsHeading: bhodiNewsHeading || "",
                    bhodiNewsText: bhodiNewsText || "",
                    charityImage,
                    charityVideo,
                });
            }
        }


        if (window.innerWidth > 1008) {
            this.setState({ isDesktop: true })
        } else {
            this.setState({ isDesktop: false })
        }


        if (this.props.global_campaigns) {
            let arrCompleteVouchersEvents = []
            let toDoFilter = this.props.global_campaigns

            toDoFilter.map((t) => {
                let g = t[1]
                if (g.campaignType === "local_event") {
                    if (g.hideOnGlobalEvent) {
                        return false;
                    }
                    if (g.eventForGuestUser) {
                        if (userid) {
                            return false;
                        }
                    }
                    if (g.takeFromAdmin) {
                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                    }
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (g.created_by !== userid) {
                        if (arrTimingsAvailable.length > 0) {
                            if (g.active && !g.takeFromAdmin && g.created_by !== process.env.REACT_APP_ADMIN_ID) {
                                arrCompleteVouchersEvents.push(g)
                            }
                        }
                    }
                }
                if (g.campaignType === "kortings_voucher") {
                    if (g.created_by !== userid) {
                        if (g.active) {
                            arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
            })

            // if (arrCompleteVouchersEvents.length > 0) {
            //     let sortedCampaigns = arrCompleteVouchersEvents.sort((a, b) => {
            //         return b.timestamp * 100 - a.timestamp * 100;
            //     });
            //     if (sortedCampaigns.length > 0) {
            //         this.setState({ latestEventOrVoucher: arrCompleteVouchersEvents[0] })
            //     }
            // }
            if (arrCompleteVouchersEvents.length > 0) {
                let sortedCampaigns = arrCompleteVouchersEvents.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    let latestEventOrVoucher = [...sortedCampaigns][0]
                    latestEventOrVoucher.type = "campaign";
                    latestEventOrVoucher.currentImageIndex = 0;

                    if (latestEventOrVoucher.desc) {
                        latestEventOrVoucher.short_desc = latestEventOrVoucher.desc.substr(0, 32);
                    }
                    latestEventOrVoucher.images = [latestEventOrVoucher.image];
                    latestEventOrVoucher.imagesObjected = [
                        { image: latestEventOrVoucher.image, pushKey: latestEventOrVoucher.pushKey },
                    ];

                    this.setState({ latestEventOrVoucher })
                }
            }
            if (arrCompleteVouchersEvents.length > 0) {
                let sortedCampaigns = arrCompleteVouchersEvents.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 1) {
                    this.setState({ latestEventOrVoucherSecond: sortedCampaigns[1] })
                }
            }
        }
    }

    componentWillUnmount() {
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "auto"
        }

        if (document.querySelector(".nav-fullwidth")) {
            document.querySelector(".nav-fullwidth").classList.add("navbarfixedpositionupdatedmember")
        }
    }


    checkForCreatorName(uid) {
        let filtered = this.props.user.filter((t) => {
            return t.user_id === uid
        })
        if (filtered.length > 0) {
            return `${filtered[0].company_name}`
        }
    }

    componentDidUpdate(prevProps) {
        // if (this.props.bhodi_products !== prevProps.bhodi_products && this.state.currentUserId) {
        //     let filtered = this.props.bhodi_products.filter((g) => {
        //         return g.created_by === process.env.REACT_APP_ADMIN_ID && g.is_bookmarked
        //     })
        //     if (filtered.length > 0) {
        //         let indexOfImage = 0

        //         let allImagesNew = []
        //         if (filtered[0].images && filtered[0].images.length > 0) {
        //             let duplicated = Array.from(filtered[0].images)

        //             let allImagesUrl = []
        //             duplicated.map((g) => {
        //                 if (g) {
        //                     let filePath = g.split("/")[7].split("?")[0]
        //                     allImagesUrl.push(filePath)
        //                 }
        //             })

        //             allImagesUrl.sort().map((t) => {
        //                 let filtered = duplicated.filter((g) => {
        //                     return g.includes(t)
        //                 })

        //                 if (filtered.length > 0) {
        //                     allImagesNew.push(filtered[0])
        //                 }
        //             })
        //             if (typeof filtered[0].featuredImage !== "undefined" && filtered[0].featuredImage !== null) {
        //                 if (filtered[0].featuredImage < filtered[0].images.length) {
        //                     let filteredIndex = allImagesNew.findIndex((g) => {
        //                         return g === filtered[0].images[filtered[0].featuredImage]
        //                     })
        //                     if (filteredIndex !== -1) {
        //                         indexOfImage = filteredIndex
        //                     }
        //                 }
        //             }
        //         }

        //         filtered[0].allImagesNew = allImagesNew
        //         filtered[0].indexOfImage = indexOfImage

        //         this.setState({ adminProduct: filtered[0] })
        //     }

        // let filteredProducts = this.props.bhodi_products.filter((t) => {
        //     return !t.availableOnTime && t.images && !t.is_bookmarked && !t.isSpecialProduct && !t.availableForCompanyGuest && t.created_by === process.env.REACT_APP_ADMIN_ID
        // })
        // let sortedProducts = [...filteredProducts].sort((a, b) => {
        //     return b.timestamp * 100 - a.timestamp * 100;
        // });
        // if (sortedProducts.length > 0) {
        //     this.setState({ allFourProducts: [...sortedProducts].splice(0, 4) })
        // }

        // }


        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            let arrCompleteVouchersEvents = []
            let userid = localStorage.getItem("userid")
            let toDoFilter = this.props.global_campaigns

            toDoFilter.map((t) => {
                let g = t[1]
                if (g.campaignType === "local_event") {
                    if (g.hideOnGlobalEvent) {
                        return false;
                    }
                    if (g.eventForGuestUser) {
                        if (this.state.currentUserId) {
                            return false;
                        }
                    }
                    if (g.takeFromAdmin) {
                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                    }
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(g.timings[0].isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (g.created_by !== userid) {
                        if (arrTimingsAvailable.length > 0) {
                            if (g.active) {
                                arrCompleteVouchersEvents.push(g)
                            }
                        }
                    }
                }
                if (g.campaignType === "kortings_voucher") {
                    if (g.created_by !== userid) {
                        if (g.active) {
                            arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
            })
            // else {
            //     this.setState({ latestEventOrVoucher: "" })
            // }

            if (this.state.latestEventOrVoucher && window.innerWidth > 1008) {
                let sortedCampaigns = this.props.global_campaigns.filter((g) => {
                    return g[1].pushKey === this.state.latestEventOrVoucher.pushKey
                })
                if (sortedCampaigns.length > 0) {
                    let latestEventOrVoucher = [...sortedCampaigns][0][1]
                    latestEventOrVoucher.type = "campaign";
                    latestEventOrVoucher.currentImageIndex = 0;

                    if (latestEventOrVoucher.desc) {
                        latestEventOrVoucher.short_desc = latestEventOrVoucher.desc.substr(0, 32);
                    }
                    latestEventOrVoucher.images = [latestEventOrVoucher.image];
                    latestEventOrVoucher.imagesObjected = [
                        { image: latestEventOrVoucher.image, pushKey: latestEventOrVoucher.pushKey },
                    ];

                    this.setState({ latestEventOrVoucher })
                }
            }
            if (this.state.latestEventOrVoucherSecond && window.innerWidth > 1008) {
                let sortedCampaigns = this.props.global_campaigns.filter((g) => {
                    return g[1].pushKey === this.state.latestEventOrVoucherSecond.pushKey
                })
                if (sortedCampaigns.length > 1) {
                    let latestEventOrVoucherSecond = [...sortedCampaigns][1][1]
                    latestEventOrVoucherSecond.type = "campaign";
                    latestEventOrVoucherSecond.currentImageIndex = 0;

                    if (latestEventOrVoucherSecond.desc) {
                        latestEventOrVoucherSecond.short_desc = latestEventOrVoucherSecond.desc.substr(0, 32);
                    }
                    latestEventOrVoucherSecond.images = [latestEventOrVoucherSecond.image];
                    latestEventOrVoucherSecond.imagesObjected = [
                        { image: latestEventOrVoucherSecond.image, pushKey: latestEventOrVoucherSecond.pushKey },
                    ];

                    this.setState({ latestEventOrVoucherSecond })
                }
            }
        }


        if (this.props.global_values !== prevProps.global_values) {
            let a = this.props.global_values;
            if (a) {
                const {
                    memberFirstHeading,
                    memberSecondHeading,
                    dashboardMemberDesc,
                    memberDashboardMobileUrl,
                    memberDashboardMobileType,
                    memberDashboardDesktopUrl,
                    memberDashboardDesktopType,
                    bhodiNewsHeading,
                    bhodiNewsText,
                } = a;

                let charityImage = ""
                let charityVideo = ""

                if (a.charity_image) {
                    charityImage = a.charity_image
                    charityVideo = ""
                    // this.setState({ charityImage: a.charity_image, charityVideo: "" });
                } else {
                    charityVideo = a.charity_video
                    charityImage = ""
                    // this.setState({ charityVideo: a.charity_video, charityImage: "" });
                }

                this.setState({
                    memberFirstHeading: memberFirstHeading || "",
                    memberSecondHeading: memberSecondHeading || "",
                    dashboardMemberDesc: dashboardMemberDesc || "",
                    memberDashboardMobileUrl: memberDashboardMobileUrl || "",
                    memberDashboardMobileType: memberDashboardMobileType || "",
                    memberDashboardDesktopUrl: memberDashboardDesktopUrl || "",
                    memberDashboardDesktopType: memberDashboardDesktopType || "",
                    bhodiNewsHeading: bhodiNewsHeading || "",
                    bhodiNewsText: bhodiNewsText || "",
                    charityImage,
                    charityVideo,
                });
            }
        }


        // if (this.props.global_campaigns !== prevProps.global_campaigns) {
        //     let userid = localStorage.getItem("userid")

        //     let arrCompleteVouchersEvents = []
        //     let toDoFilter = this.props.global_campaigns

        //     toDoFilter.map((t) => {
        //         let g = t[1]
        //         if (g.campaignType === "local_event") {
        //             let arrTimingsAvailable = [];
        //             if (g.timings) {
        //                 g.timings.map((e) => {
        //                     if (g.timings[0].isoStartTime && g.timings[0].isoEndTime) {
        //                         let checkedInOfCurrentBlock = [];
        //                         if (g.checked_in_by) {
        //                             Object.entries(g.checked_in_by).map((t) => {
        //                                 checkedInOfCurrentBlock.push(t[1]);
        //                             });
        //                         }
        //                         let getCurrentDate = new Date();
        //                         if (getCurrentDate.getTime() < new Date(Date.parse(g.timings[0].isoEndTime)).getTime()) {
        //                             if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
        //                                 arrTimingsAvailable.push(e);
        //                             }
        //                         }
        //                     }
        //                 });
        //             }
        //             if (g.created_by === userid) {
        //                 if (arrTimingsAvailable.length > 0) {
        //                     if (g.active) {
        //                         arrCompleteVouchersEvents.push(g)
        //                     }
        //                 }
        //             }
        //         }
        //         if (g.campaignType === "kortings_voucher") {
        //             if (g.created_by !== userid) {
        //                 if (g.active) {
        //                     arrCompleteVouchersEvents.push(g)
        //                 }
        //             }
        //         }
        //     })

        //     if (arrCompleteVouchersEvents.length > 0) {
        //         let sortedCampaigns = arrCompleteVouchersEvents.sort((a, b) => {
        //             return b.timestamp * 100 - a.timestamp * 100;
        //         });
        //         if (sortedCampaigns.length > 0) {
        //             this.setState({ latestEventOrVoucher: arrCompleteVouchersEvents[0] })
        //         }
        //     }
        // }
    }

    createMarkupDesc() {
        if (this.state.dashboardMemberDesc) {
            return { __html: this.state.dashboardMemberDesc };
        }
    }

    checkForButtonProducts(e) {
        if (!e.sizesActive) {
            if (e.inCartOf || e.verzilvered_by) {
                let objectedCart = []
                let objectedVerzilvered = []

                if (e.inCartOf) {
                    objectedCart = Object.entries(e.inCartOf)
                }

                if (e.verzilvered_by) {
                    objectedVerzilvered = Object.entries(e.verzilvered_by)
                }

                if ((objectedCart.length + objectedVerzilvered.length) >= Number(e.qty)) {
                    return (<button>Uitverkocht</button>)
                }
            }
            if (Number(e.qty) === 0) {
                return (<button>Uitverkocht</button>)
            }
        }

        return (<button onClick={() => {
            history.push(`/product-detailed?key=${e.pushKey}`)
        }}>Bekijk</button>)
    }

    render() {
        return (
            <div className='member-home-new'>
                {/* {this.state.adminProduct && (<div className='banner-mhn bmhn-admin' style={{ backgroundImage: `url("${this.state.adminProduct.allImagesNew[this.state.adminProduct.indexOfImage]}")`, height: !this.state.isDesktop && 500 }}>
                    <div className='content-bmhn'>
                        <p>DAILY NEW ADDED</p>
                        <p>{this.state.adminProduct.title}</p>
                        <p>{this.state.adminProduct.desc && this.state.adminProduct.desc.substr(0, 140)}</p>
                        <p><s>€{this.state.adminProduct.price}</s> for free</p>
                        {(this.state.adminProduct.variations_data && this.state.adminProduct.variations_data.length > 0) && (<p>
                            Beschikbaar in
                            {this.state.adminProduct.variations_data.map((e) => {
                                return (<font>&nbsp;&nbsp;{e.size}</font>)
                            })}
                        </p>)}
                        <div className='button-admin-note'>
                            {this.state.showNoteButtonTimer && (<div className='banote-inner'>
                                <p>Note</p>
                                <p>Wanneer de timer is voltooid, bestel dan zo snel mogelijk als je kunt! Want op is op.</p>
                            </div>)}
                            {this.state.adminProduct.timerToStartProduct && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(this.state.adminProduct.timerToStartProduct)).getTime()} btnClicked={() => {
                                
                                history.push(`/product-detailed?key=${this.state.adminProduct.pushKey}`)
                            }} />}
                            {this.checkForButtonProducts(this.state.adminProduct)}
                        </div>
                    </div>
                    <div className='overlay-bchn'>
                    </div>

                    {window.innerWidth > 1008 && (<div className="bottom-csfs-shop">
                        <div
                            className="unds-buttons"
                            style={{ alignItems: "center" }}
                        >
                            <div
                                className="inner-undb"
                                onClick={() => {
                                    this.scrollToProducts();
                                }}
                            >
                                <KeyboardArrowDownIcon />
                            </div>
                        </div>
                    </div>)}
                </div>)} */}
                {this.state.latestEventOrVoucher && (window.innerWidth > 1008 ? <VouchersNewGridDesktop
                    completeArrProducts={[this.state.latestEventOrVoucher]}
                    filteredProductsToShow={[this.state.latestEventOrVoucher]}
                    allProducts={[this.state.latestEventOrVoucher]}
                    noProducts={false}
                    onHomePages={true}
                /> : <VouchersNewMobile
                    productsWhole={[this.state.latestEventOrVoucher]}
                    lastNodeEmpty={true}
                    shouldAlwaysUpdate={true}
                />)}
                {/* {this.state.currentUserId && (<div className='ssmhn-trending' ref={this.sectionRefProducts} style={{ marginBottom: this.state.latestEventOrVoucher && 0, paddingBottom: this.state.latestEventOrVoucher && 0, }}>
                    <NewProductWrapper toShowProductOf={process.env.REACT_APP_ADMIN_ID} />
                </div>)} */}
                {(this.state.latestEventOrVoucherSecond && this.state.latestEventOrVoucherSecond.campaignType === "local_event" && !this.props.atCompanyPage) && (
                    <div ref={this.sectionRef}>
                        {(this.state.latestEventOrVoucherSecond && window.innerWidth > 1008) && (<div className='top-events-section'>
                            <p>Event</p>
                            <p>{this.checkForCreatorName(this.state.latestEventOrVoucherSecond.created_by)}</p>
                        </div>)}
                        <EventsNewSection
                            campaignToShow={[this.state.latestEventOrVoucherSecond]}
                            onEditOpen={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    openAddPopup: true,
                                    doEditLocalEvent: true,
                                });
                            }}
                        />
                    </div>
                )}
                {(this.state.latestEventOrVoucherSecond && this.state.latestEventOrVoucherSecond.campaignType === "kortings_voucher" && !this.props.atCompanyPage) && (
                    <div ref={this.sectionRefVoucher}>
                        {(this.state.latestEventOrVoucherSecond && window.innerWidth > 1008) && (<div className='top-events-section'>
                            <p>Voucher</p>
                            <p>{this.checkForCreatorName(this.state.latestEventOrVoucherSecond.created_by)}</p>
                        </div>)}
                        <VouchersNewSection
                            campaignToShow={[this.state.latestEventOrVoucherSecond]}
                            onEditOpen={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    openAddPopup: true,
                                    doEditVoucher: true,
                                });
                            }}
                        />
                    </div>
                )}
                {window.innerWidth < 1008 ? (<div className='fourth-section-mhn fsm-updated-mobile' ref={this.contactRef} style={{ backgroundImage: "none" }}>
                    <div className='top-events-section' style={{ paddingBottom: 20 }}>
                        <p>{this.state.bhodiNewsHeading}</p>
                        <p>{this.state.bhodiNewsText}</p>
                    </div>
                    <div className='image-section-fsmhn' style={{ backgroundImage: this.state.isDesktop ? (this.state.memberDashboardDesktopType === "image" ? `url("${this.state.memberDashboardDesktopUrl}")` : "none") : (this.state.memberDashboardMobileType === "image" ? `url("${this.state.memberDashboardMobileUrl}")` : "none") }}>
                        {(this.state.memberDashboardDesktopType === "video" && this.state.isDesktop) && (<video
                            src={this.state.memberDashboardDesktopUrl}
                            playsInline
                            muted
                            autoPlay
                            loop
                        ></video>)}
                        {(this.state.memberDashboardMobileType === "video" && !this.state.isDesktop) && (<video
                            src={this.state.memberDashboardMobileUrl}
                            playsInline
                            muted
                            autoPlay
                            loop
                        ></video>)}
                    </div>
                    <div className='container-fsmhn'>
                        <p className='desc-first-cfsmhn'>{this.state.memberFirstHeading}</p>
                        <p className='desc-second-cfsmhn'>{this.state.memberSecondHeading}</p>
                        <p className='normal-desc-cfsmhn'>
                            <div dangerouslySetInnerHTML={this.createMarkupDesc()}></div>
                        </p>
                    </div>
                </div>) : (<div className='fourth-section-mhn' ref={this.contactRef} style={{ backgroundImage: this.state.isDesktop ? (this.state.memberDashboardDesktopType === "image" ? `url("${this.state.memberDashboardDesktopUrl}")` : "none") : (this.state.memberDashboardMobileType === "image" ? `url("${this.state.memberDashboardMobileUrl}")` : "none") }}>
                    {(this.state.memberDashboardDesktopType === "video" && this.state.isDesktop) && (<video
                        src={this.state.memberDashboardDesktopUrl}
                        playsInline
                        muted
                        autoPlay
                        loop
                    ></video>)}
                    {(this.state.memberDashboardMobileType === "video" && !this.state.isDesktop) && (<video
                        src={this.state.memberDashboardMobileUrl}
                        playsInline
                        muted
                        autoPlay
                        loop
                    ></video>)}
                    <div className='container-fsmhn'>
                        <p className='desc-first-cfsmhn'>{this.state.memberFirstHeading}</p>
                        <p className='desc-second-cfsmhn'>{this.state.memberSecondHeading}</p>
                        <p className='normal-desc-cfsmhn'>
                            <div dangerouslySetInnerHTML={this.createMarkupDesc()}></div>
                        </p>
                    </div>
                </div>)}

                {window.innerWidth > 1008 && (<Footer notOnMainPage={true} />)}
                {window.innerWidth < 1008 && (<FooterGreen notOnMainPage={true} />)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bhodi_products: state.productsReducers.bhodi_products,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
        globalsectors: state.categoriesReducer.globalsectors,
        stock_images: state.globalReducers.stock_images,
        user: state.authReducers.user,
    };
};


export default connect(mapStateToProps)(CompanyTrending)