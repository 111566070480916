import React, { Component } from "react";
import logoheader from "../../assets/logoheaderlifestyleblack.png";
import logoheaderWhite from "../../assets/logoheaderlifestyle.png";
import "./index.css";
import { GiHotMeal } from "react-icons/gi";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiDoubleQuotesR } from "react-icons/ri";
import { AiFillGift } from "react-icons/ai";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase";
import PersonIcon from "@material-ui/icons/Person";
import FooterBasket from "../../Components/FooterBasket";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { connect } from "react-redux";
import {
  updateAdminOrNot,
  updateGlobalStuff,
} from "../../Redux/actions/authActions";
import Footer from "../../Components/Footer";
import whitestarnav from "../../assets/whitestarnav.png";
import teacherVideo from "../../assets/teacher.mp4";
// const axios = require('axios');
import axios from "axios";
import { Link } from "react-router-dom";

AOS.init();

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class CharityLandingPage extends Component {
  constructor() {
    super();
    this.state = {
      // events: [
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/denham.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     },
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/butcher.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     },
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/boss.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     },
      //     {
      //         image: "https://morseltsite.retailerstoday.nl/wp-content/uploads/2021/11/catwalkjunkie.jpg",
      //         companyName: "Company name",
      //         fashionLabel: "Fashion label"
      //     }
      // ],
      events: [],
      menuOpen: false,
      allUsers: [],
      country: "155",
      isUserForm: true,
      showCharityStuff: false,
      subscription_data: [],
      plan_id: "",
      plan_amount: "",
      // registration fields
      firstName: "",
      firstNamePopup: false,
      lastName: "",
      lastNamePopup: false,
      email: "",
      emailPopup: false,
      password: "",
      passwordPopup: false,
      country: "155",
      companyRegistration: false,
      companyName: "",
      kvkCompany: "",
      kvkCompanyPopup: false,
      street: "",
      streetPopup: false,
      houseNumber: "",
      houseNumberPopup: false,
      city: "",
      cityPopup: false,
      zipCode: "",
      zipCodePopup: false,
      phoneNumber: "",
      phoneNumberPopup: false,
      company_website: "",
      company_websitePopup: false,
      passwordInputType: "password",
      guestUserCart: [],
      aanmeldenloader: false,
      allUsers: [],
      agreed: false,
      agreedPopup: false,
      currentUserSpecific: "",
      isUserCreated: false,
      currentDataOfUser: "",
      popupopenforright: true,
      charity_page_banner_english: "",
      charity_page_banner_dutch: "",
      charity_page_events_english: "",
      charity_page_events_dutch: "",
      charity_page_new_events_dutch: "",
      charity_page_new_events_english: "",
      charity_page_profile_dutch: "",
      charity_page_profile_english: "",
      charity_page_about_dutch: "",
      charity_page_about_english: "",
      charity_page_team_bhodi_dutch: "",
      charity_page_team_bhodi_english: "",
      charity_page_banner_heading_english: "",
      charity_page_banner_heading_dutch: "",
      charity_page_events_dutch_heading: "",
      charity_page_events_english_heading: "",
      charity_page_events_dutch_headertxt: "",
      charity_page_events_english_headertxt: "",
      charity_page_events_dutch_heading_inner: "",
      charity_page_events_english_heading_inner: "",
      charity_page_new_events_dutch_heading: "",
      charity_page_new_events_english_heading: "",
      charity_page_profile_dutch_heading: "",
      charity_page_profile_english_heading: "",
      charity_page_about_dutch_heading: "",
      charity_page_about_english_heading: "",
      charity_page_team_bhodi_dutch_heading: "",
      charity_page_team_bhodi_english_heading: "",
      charity_page_connect_dutch_heading: "",
      charity_page_connect_english_heading: "",
      charity_page_connect_dutch: "",
      charity_page_connect_english: "",
      // Mobile
      charity_page_banner_english_mobile: "",
      charity_page_banner_dutch_mobile: "",
      charity_page_events_english_mobile: "",
      charity_page_events_dutch_mobile: "",
      charity_page_new_events_dutch_mobile: "",
      charity_page_new_events_english_mobile: "",
      charity_page_profile_dutch_mobile: "",
      charity_page_profile_english_mobile: "",
      charity_page_about_dutch_mobile: "",
      charity_page_about_english_mobile: "",
      charity_page_team_bhodi_dutch_mobile: "",
      charity_page_team_bhodi_english_mobile: "",
      charity_page_banner_heading_english_mobile: "",
      charity_page_banner_heading_dutch_mobile: "",
      charity_page_events_dutch_heading_mobile: "",
      charity_page_events_english_heading_mobile: "",
      charity_page_events_dutch_headertxt_mobile: "",
      charity_page_events_english_headertxt_mobile: "",
      charity_page_events_dutch_heading_inner_mobile: "",
      charity_page_events_english_heading_inner_mobile: "",
      charity_page_new_events_dutch_heading_mobile: "",
      charity_page_new_events_english_heading_mobile: "",
      charity_page_profile_dutch_heading_mobile: "",
      charity_page_profile_english_heading_mobile: "",
      charity_page_about_dutch_heading_mobile: "",
      charity_page_about_english_heading_mobile: "",
      charity_page_team_bhodi_dutch_heading_mobile: "",
      charity_page_team_bhodi_english_heading_mobile: "",
      charity_page_connect_dutch_heading_mobile: "",
      charity_page_connect_english_heading_mobile: "",
      charity_page_connect_dutch_mobile: "",
      charity_page_connect_english_mobile: "",
      charity_page_banner_top_video: "",
      charity_page_charity_image: "",
      charity_page_event_right_image: "",
      landing_page_footer_image: "",
      showDutch: true,


      commercial_page_charity_title: "",
      commercial_page_charity_title_mobile: "",
      commercial_page_charity_title_english: "",
      commercial_page_charity_title_english_mobile: "",
      commercial_page_charity_first: "",
      commercial_page_charity_first_mobile: "",
      commercial_page_charity_first_english: "",
      commercial_page_charity_first_english_mobile: "",
      commercial_page_charity_second: "",
      commercial_page_charity_second_mobile: "",
      commercial_page_charity_second_english: "",
      commercial_page_charity_second_english_mobile: "",
      commercial_page_charity_third: "",
      commercial_page_charity_third_mobile: "",
      commercial_page_charity_third_english: "",
      commercial_page_charity_third_english_mobile: "",
    };
    this.howDoesItWorkRef = React.createRef()
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    // let res = axios.get('https://ipinfo.io/json?token=042c5575817029')
    // res.then((output) => {
    //     if (output.data) {
    //         if (output.data.country === "NL") {
    //             this.setState({ showDutch: true })
    //         } else {
    //             this.setState({ showDutch: false })
    //         }
    //     }
    // })

    // let getCampaigns = new Promise((resolve, reject) => {
    //     firebase
    //         .database()
    //         .ref("bhodi_campaigns")
    //         .once("value")
    //         .then(function (snapshot) {
    //             let allCampaignsToUnactive = [];
    //             snapshot.forEach((childSnapshot) => {
    //                 childSnapshot.forEach((colorSnapshot) => {
    //                     if (colorSnapshot.val().campaignType === "local_event" && colorSnapshot.val().active) {
    //                         if (colorSnapshot.val().timings) {
    //                             let objected = Object.entries(colorSnapshot.val().timings)
    //                             let dateMax = new Date(objected[0][1].startDateEvent)
    //                             let startTimeGood = objected[0][1].startTimeEvent.concat(":00")
    //                             dateMax.setHours(startTimeGood.split(":")[0])
    //                             dateMax.setMinutes(startTimeGood.split(":")[1])
    //                             if (dateMax.getTime() < new Date().getTime()) {
    //                                 allCampaignsToUnactive.push(colorSnapshot.val());
    //                             }
    //                         }
    //                     }
    //                 });
    //             });
    //             resolve(allCampaignsToUnactive);
    //         });
    // });
    // getCampaigns.then((campaignsToUnactive) => {
    //     campaignsToUnactive.map((e) => {
    //         console.log("bhodi_campaigns/" + e.created_by + "/" + e.pushKey + "/active")
    //     })
    // });

    let res = axios.get(
      "https://us-central1-bhodi-7c327.cloudfunctions.net/getIpLocation"
    );
    res.then((output) => {
      if (output.data.deta) {
        if (output.data.deta.country === "NL") {
          this.setState({ showDutch: true });
        } else {
          this.setState({ showDutch: false });
        }
      }
    });

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        this.setState({
          charity_page_banner_english: a.charity_page_banner_english,
          charity_page_banner_dutch: a.charity_page_banner_dutch,
          charity_page_events_english: a.charity_page_events_english,
          charity_page_events_dutch: a.charity_page_events_dutch,
          charity_page_new_events_dutch: a.charity_page_new_events_dutch,
          charity_page_new_events_english: a.charity_page_new_events_english,
          charity_page_profile_dutch: a.charity_page_profile_dutch,
          charity_page_profile_english: a.charity_page_profile_english,
          charity_page_about_dutch: a.charity_page_about_dutch,
          charity_page_about_english: a.charity_page_about_english,
          charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch,
          charity_page_team_bhodi_english: a.charity_page_team_bhodi_english,
          charity_page_banner_heading_english:
            a.charity_page_banner_heading_english,
          charity_page_banner_heading_dutch:
            a.charity_page_banner_heading_dutch,
          charity_page_events_dutch_heading:
            a.charity_page_events_dutch_heading,
          charity_page_events_english_heading:
            a.charity_page_events_english_heading,
          charity_page_events_dutch_heading_inner:
            a.charity_page_events_dutch_heading_inner,
          charity_page_events_english_heading_inner:
            a.charity_page_events_english_heading_inner,
          charity_page_events_dutch_headertxt:
            a.charity_page_events_dutch_headertxt,
          charity_page_events_english_headertxt:
            a.charity_page_events_english_headertxt,
          charity_page_profile_dutch_heading:
            a.charity_page_profile_dutch_heading,
          charity_page_profile_english_heading:
            a.charity_page_profile_english_heading,
          charity_page_new_events_dutch_heading:
            a.charity_page_new_events_dutch_heading,
          charity_page_new_events_english_heading:
            a.charity_page_new_events_english_heading,
          charity_page_about_dutch_heading: a.charity_page_about_dutch_heading,
          charity_page_about_english_heading:
            a.charity_page_about_english_heading,
          charity_page_team_bhodi_dutch_heading:
            a.charity_page_team_bhodi_dutch_heading,
          charity_page_team_bhodi_english_heading:
            a.charity_page_team_bhodi_english_heading,
          charity_page_connect_dutch_heading:
            a.charity_page_connect_dutch_heading,
          charity_page_connect_english_heading:
            a.charity_page_connect_english_heading,
          charity_page_connect_dutch: a.charity_page_connect_dutch,
          charity_page_connect_english: a.charity_page_connect_english,
          // Mobile
          charity_page_banner_english_mobile:
            a.charity_page_banner_english_mobile,
          charity_page_banner_dutch_mobile: a.charity_page_banner_dutch_mobile,
          charity_page_events_english_mobile:
            a.charity_page_events_english_mobile,
          charity_page_events_dutch_mobile: a.charity_page_events_dutch_mobile,
          charity_page_new_events_dutch_mobile:
            a.charity_page_new_events_dutch_mobile,
          charity_page_new_events_english_mobile:
            a.charity_page_new_events_english_mobile,
          charity_page_profile_dutch_mobile:
            a.charity_page_profile_dutch_mobile,
          charity_page_profile_english_mobile:
            a.charity_page_profile_english_mobile,
          charity_page_about_dutch_mobile: a.charity_page_about_dutch_mobile,
          charity_page_about_english_mobile:
            a.charity_page_about_english_mobile,
          charity_page_team_bhodi_dutch_mobile:
            a.charity_page_team_bhodi_dutch_mobile,
          charity_page_team_bhodi_english_mobile:
            a.charity_page_team_bhodi_english_mobile,
          charity_page_banner_heading_english_mobile:
            a.charity_page_banner_heading_english_mobile,
          charity_page_banner_heading_dutch_mobile:
            a.charity_page_banner_heading_dutch_mobile,
          charity_page_events_dutch_heading_mobile:
            a.charity_page_events_dutch_heading_mobile,
          charity_page_events_english_heading_mobile:
            a.charity_page_events_english_heading_mobile,
          charity_page_events_dutch_heading_inner_mobile:
            a.charity_page_events_dutch_heading_inner_mobile,
          charity_page_events_english_heading_inner_mobile:
            a.charity_page_events_english_heading_inner_mobile,
          charity_page_events_dutch_headertxt_mobile:
            a.charity_page_events_dutch_headertxt_mobile,
          charity_page_events_english_headertxt_mobile:
            a.charity_page_events_english_headertxt_mobile,
          charity_page_profile_dutch_heading_mobile:
            a.charity_page_profile_dutch_heading_mobile,
          charity_page_profile_english_heading_mobile:
            a.charity_page_profile_english_heading_mobile,
          charity_page_new_events_dutch_heading_mobile:
            a.charity_page_new_events_dutch_heading_mobile,
          charity_page_new_events_english_heading_mobile:
            a.charity_page_new_events_english_heading_mobile,
          charity_page_about_dutch_heading_mobile:
            a.charity_page_about_dutch_heading_mobile,
          charity_page_about_english_heading_mobile:
            a.charity_page_about_english_heading_mobile,
          charity_page_team_bhodi_dutch_heading_mobile:
            a.charity_page_team_bhodi_dutch_heading_mobile,
          charity_page_team_bhodi_english_heading_mobile:
            a.charity_page_team_bhodi_english_heading_mobile,
          charity_page_connect_dutch_heading_mobile:
            a.charity_page_connect_dutch_heading_mobile,
          charity_page_connect_english_heading_mobile:
            a.charity_page_connect_english_heading_mobile,
          charity_page_connect_dutch_mobile:
            a.charity_page_connect_dutch_mobile,
          charity_page_connect_english_mobile:
            a.charity_page_connect_english_mobile,
          charity_page_banner_top_video: a.charity_page_banner_top_video,
          charity_page_charity_image: a.charity_page_charity_image,
          charity_page_event_right_image: a.charity_page_event_right_image,
          landing_page_footer_image: a.landing_page_footer_image,


          commercial_page_charity_title: a.commercial_page_charity_title || "",
          commercial_page_charity_title_english: a.commercial_page_charity_title_english || "",
          commercial_page_charity_title_mobile: a.commercial_page_charity_title_mobile || "",
          commercial_page_charity_title_english_mobile: a.commercial_page_charity_title_english_mobile || "",
          commercial_page_charity_first: a.commercial_page_charity_first || "",
          commercial_page_charity_first_english: a.commercial_page_charity_first_english || "",
          commercial_page_charity_first_mobile: a.commercial_page_charity_first_mobile || "",
          commercial_page_charity_first_english_mobile: a.commercial_page_charity_first_english_mobile || "",
          commercial_page_charity_second: a.commercial_page_charity_second || "",
          commercial_page_charity_second_english: a.commercial_page_charity_second_english || "",
          commercial_page_charity_second_mobile: a.commercial_page_charity_second_mobile || "",
          commercial_page_charity_second_english_mobile: a.commercial_page_charity_second_english_mobile || "",
          commercial_page_charity_third: a.commercial_page_charity_third || "",
          commercial_page_charity_third_english: a.commercial_page_charity_third_english || "",
          commercial_page_charity_third_mobile: a.commercial_page_charity_third_mobile || "",
          commercial_page_charity_third_english_mobile: a.commercial_page_charity_third_english_mobile || "",
          charity_page_profile_video: a.charity_page_profile_video || "",
        });
        if (a.profiles_image) {
          this.setState({ profilesImage: a.profiles_image, profilesVideo: "" });
        } else {
          this.setState({ profilesVideo: a.profiles_video, profilesImage: "" });
        }
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          this.setState({
            charity_page_banner_english: a.charity_page_banner_english,
            charity_page_banner_dutch: a.charity_page_banner_dutch,
            charity_page_events_english: a.charity_page_events_english,
            charity_page_events_dutch: a.charity_page_events_dutch,
            charity_page_new_events_dutch: a.charity_page_new_events_dutch,
            charity_page_new_events_english: a.charity_page_new_events_english,
            charity_page_profile_dutch: a.charity_page_profile_dutch,
            charity_page_profile_english: a.charity_page_profile_english,
            charity_page_about_dutch: a.charity_page_about_dutch,
            charity_page_about_english: a.charity_page_about_english,
            charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch,
            charity_page_team_bhodi_english: a.charity_page_team_bhodi_english,
            charity_page_banner_heading_english:
              a.charity_page_banner_heading_english,
            charity_page_banner_heading_dutch:
              a.charity_page_banner_heading_dutch,
            charity_page_events_dutch_heading:
              a.charity_page_events_dutch_heading,
            charity_page_events_english_heading:
              a.charity_page_events_english_heading,
            charity_page_events_dutch_heading_inner:
              a.charity_page_events_dutch_heading_inner,
            charity_page_events_english_heading_inner:
              a.charity_page_events_english_heading_inner,
            charity_page_events_dutch_headertxt:
              a.charity_page_events_dutch_headertxt,
            charity_page_events_english_headertxt:
              a.charity_page_events_english_headertxt,
            charity_page_profile_dutch_heading:
              a.charity_page_profile_dutch_heading,
            charity_page_profile_english_heading:
              a.charity_page_profile_english_heading,
            charity_page_new_events_dutch_heading:
              a.charity_page_new_events_dutch_heading,
            charity_page_new_events_english_heading:
              a.charity_page_new_events_english_heading,
            charity_page_about_dutch_heading:
              a.charity_page_about_dutch_heading,
            charity_page_about_english_heading:
              a.charity_page_about_english_heading,
            charity_page_team_bhodi_dutch_heading:
              a.charity_page_team_bhodi_dutch_heading,
            charity_page_team_bhodi_english_heading:
              a.charity_page_team_bhodi_english_heading,
            charity_page_connect_dutch_heading:
              a.charity_page_connect_dutch_heading,
            charity_page_connect_english_heading:
              a.charity_page_connect_english_heading,
            charity_page_connect_dutch: a.charity_page_connect_dutch,
            charity_page_connect_english: a.charity_page_connect_english,
            // Mobile
            charity_page_banner_english_mobile:
              a.charity_page_banner_english_mobile,
            charity_page_banner_dutch_mobile:
              a.charity_page_banner_dutch_mobile,
            charity_page_events_english_mobile:
              a.charity_page_events_english_mobile,
            charity_page_events_dutch_mobile:
              a.charity_page_events_dutch_mobile,
            charity_page_new_events_dutch_mobile:
              a.charity_page_new_events_dutch_mobile,
            charity_page_new_events_english_mobile:
              a.charity_page_new_events_english_mobile,
            charity_page_profile_dutch_mobile:
              a.charity_page_profile_dutch_mobile,
            charity_page_profile_english_mobile:
              a.charity_page_profile_english_mobile,
            charity_page_about_dutch_mobile: a.charity_page_about_dutch_mobile,
            charity_page_about_english_mobile:
              a.charity_page_about_english_mobile,
            charity_page_team_bhodi_dutch_mobile:
              a.charity_page_team_bhodi_dutch_mobile,
            charity_page_team_bhodi_english_mobile:
              a.charity_page_team_bhodi_english_mobile,
            charity_page_banner_heading_english_mobile:
              a.charity_page_banner_heading_english_mobile,
            charity_page_banner_heading_dutch_mobile:
              a.charity_page_banner_heading_dutch_mobile,
            charity_page_events_dutch_heading_mobile:
              a.charity_page_events_dutch_heading_mobile,
            charity_page_events_english_heading_mobile:
              a.charity_page_events_english_heading_mobile,
            charity_page_events_dutch_heading_inner_mobile:
              a.charity_page_events_dutch_heading_inner_mobile,
            charity_page_events_english_heading_inner_mobile:
              a.charity_page_events_english_heading_inner_mobile,
            charity_page_events_dutch_headertxt_mobile:
              a.charity_page_events_dutch_headertxt_mobile,
            charity_page_events_english_headertxt_mobile:
              a.charity_page_events_english_headertxt_mobile,
            charity_page_profile_dutch_heading_mobile:
              a.charity_page_profile_dutch_heading_mobile,
            charity_page_profile_english_heading_mobile:
              a.charity_page_profile_english_heading_mobile,
            charity_page_new_events_dutch_heading_mobile:
              a.charity_page_new_events_dutch_heading_mobile,
            charity_page_new_events_english_heading_mobile:
              a.charity_page_new_events_english_heading_mobile,
            charity_page_about_dutch_heading_mobile:
              a.charity_page_about_dutch_heading_mobile,
            charity_page_about_english_heading_mobile:
              a.charity_page_about_english_heading_mobile,
            charity_page_team_bhodi_dutch_heading_mobile:
              a.charity_page_team_bhodi_dutch_heading_mobile,
            charity_page_team_bhodi_english_heading_mobile:
              a.charity_page_team_bhodi_english_heading_mobile,
            charity_page_connect_dutch_heading_mobile:
              a.charity_page_connect_dutch_heading_mobile,
            charity_page_connect_english_heading_mobile:
              a.charity_page_connect_english_heading_mobile,
            charity_page_connect_dutch_mobile:
              a.charity_page_connect_dutch_mobile,
            charity_page_connect_english_mobile:
              a.charity_page_connect_english_mobile,
            charity_page_banner_top_video: a.charity_page_banner_top_video,
            charity_page_charity_image: a.charity_page_charity_image,
            landing_page_footer_image: a.landing_page_footer_image,
            charity_page_event_right_image: a.charity_page_event_right_image,


            commercial_page_charity_title: a.commercial_page_charity_title || "",
            commercial_page_charity_title_english: a.commercial_page_charity_title_english || "",
            commercial_page_charity_title_mobile: a.commercial_page_charity_title_mobile || "",
            commercial_page_charity_title_english_mobile: a.commercial_page_charity_title_english_mobile || "",
            commercial_page_charity_first: a.commercial_page_charity_first || "",
            commercial_page_charity_first_english: a.commercial_page_charity_first_english || "",
            commercial_page_charity_first_mobile: a.commercial_page_charity_first_mobile || "",
            commercial_page_charity_first_english_mobile: a.commercial_page_charity_first_english_mobile || "",
            commercial_page_charity_second: a.commercial_page_charity_second || "",
            commercial_page_charity_second_english: a.commercial_page_charity_second_english || "",
            commercial_page_charity_second_mobile: a.commercial_page_charity_second_mobile || "",
            commercial_page_charity_second_english_mobile: a.commercial_page_charity_second_english_mobile || "",
            commercial_page_charity_third: a.commercial_page_charity_third || "",
            commercial_page_charity_third_english: a.commercial_page_charity_third_english || "",
            commercial_page_charity_third_mobile: a.commercial_page_charity_third_mobile || "",
            commercial_page_charity_third_english_mobile: a.commercial_page_charity_third_english_mobile || "",
            charity_page_profile_video: a.charity_page_profile_video || "",
          });
          if (a.profiles_image) {
            this.setState({
              profilesImage: a.profiles_image,
              profilesVideo: "",
            });
          } else {
            this.setState({
              profilesVideo: a.profiles_video,
              profilesImage: "",
            });
          }
        }
      });

    let users = new Promise((res, rej) => {
      firebase
        .database()
        .ref("users")
        .once("value", (data) => {
          let a = data.val();
          let arr = [];
          if (a && !this.state.stopTakingData) {
            let objected = Object.entries(a);
            objected.map((u) => {
              arr.push(u[1]);
            });
            this.setState({ allUsers: arr });
          } else {
            this.setState({ allUsers: [] });
          }
          res();
        });
    });
  }

  getCompanyName(createdBy) {
    let filtered = this.state.allUsers.filter((j) => {
      return j.user_id === createdBy;
    });
    if (filtered.length > 0) {
      return filtered[0].company_name.substr(0, 30);
    }
  }

  redirectCompany(createdBy) {
    let filtered = this.state.allUsers.filter((j) => {
      return j.user_id === createdBy;
    });
    if (filtered.length > 0) {
      window.location.href = `/company/${filtered[0].user_name_id}`;
    }
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  createMarkupBannerDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_banner_heading_dutch) {
        return { __html: this.state.charity_page_banner_heading_dutch };
      }
    } else {
      if (this.state.charity_page_banner_heading_dutch_mobile) {
        return { __html: this.state.charity_page_banner_heading_dutch_mobile };
      }
    }
  }

  createMarkupBannerEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_banner_heading_english) {
        return { __html: this.state.charity_page_banner_heading_english };
      }
    } else {
      if (this.state.charity_page_banner_heading_english_mobile) {
        return {
          __html: this.state.charity_page_banner_heading_english_mobile,
        };
      }
    }
  }

  createMarkupBannerDutch() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_banner_dutch) {
        return { __html: this.state.charity_page_banner_dutch };
      }
    } else {
      if (this.state.charity_page_banner_dutch_mobile) {
        return { __html: this.state.charity_page_banner_dutch_mobile };
      }
    }
  }

  createMarkupBannerEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_banner_english) {
        return { __html: this.state.charity_page_banner_english };
      }
    } else {
      if (this.state.charity_page_banner_english_mobile) {
        return { __html: this.state.charity_page_banner_english_mobile };
      }
    }
  }

  createMarkupEventsDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_dutch_heading) {
        return { __html: this.state.charity_page_events_dutch_heading };
      }
    } else {
      if (this.state.charity_page_events_dutch_heading_mobile) {
        return { __html: this.state.charity_page_events_dutch_heading_mobile };
      }
    }
  }

  createMarkupEventsEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_english_heading) {
        return { __html: this.state.charity_page_events_english_heading };
      }
    } else {
      if (this.state.charity_page_events_english_heading_mobile) {
        return {
          __html: this.state.charity_page_events_english_heading_mobile,
        };
      }
    }
  }

  createMarkupEventsDutchHeadingInner() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_dutch_heading_inner) {
        return { __html: this.state.charity_page_events_dutch_heading_inner };
      }
    } else {
      if (this.state.charity_page_events_dutch_heading_inner_mobile) {
        return {
          __html: this.state.charity_page_events_dutch_heading_inner_mobile,
        };
      }
    }
  }

  createMarkupEventsEnglishHeadingInner() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_english_heading_inner) {
        return { __html: this.state.charity_page_events_english_heading_inner };
      }
    } else {
      if (this.state.charity_page_events_english_heading_inner_mobile) {
        return {
          __html: this.state.charity_page_events_english_heading_inner_mobile,
        };
      }
    }
  }

  createMarkupEventsDutchHeaderTxt() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_dutch_headertxt) {
        return { __html: this.state.charity_page_events_dutch_headertxt };
      }
    } else {
      if (this.state.charity_page_events_dutch_headertxt_mobile) {
        return {
          __html: this.state.charity_page_events_dutch_headertxt_mobile,
        };
      }
    }
  }

  createMarkupEventsEnglishHeaderTxt() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_english_headertxt) {
        return { __html: this.state.charity_page_events_english_headertxt };
      }
    } else {
      if (this.state.charity_page_events_english_headertxt_mobile) {
        return {
          __html: this.state.charity_page_events_english_headertxt_mobile,
        };
      }
    }
  }

  createMarkupEventsDutch() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_dutch) {
        return { __html: this.state.charity_page_events_dutch };
      }
    } else {
      if (this.state.charity_page_events_dutch_mobile) {
        return { __html: this.state.charity_page_events_dutch_mobile };
      }
    }
  }

  createMarkupEventsEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_events_english) {
        return { __html: this.state.charity_page_events_english };
      }
    } else {
      if (this.state.charity_page_events_english_mobile) {
        return { __html: this.state.charity_page_events_english_mobile };
      }
    }
  }

  createMarkupNewEventsDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_new_events_dutch_heading) {
        return { __html: this.state.charity_page_new_events_dutch_heading };
      }
    } else {
      if (this.state.charity_page_new_events_dutch_heading_mobile) {
        return {
          __html: this.state.charity_page_new_events_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupNewEventsEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_new_events_english_heading) {
        return { __html: this.state.charity_page_new_events_english_heading };
      }
    } else {
      if (this.state.charity_page_new_events_english_heading_mobile) {
        return {
          __html: this.state.charity_page_new_events_english_heading_mobile,
        };
      }
    }
  }

  createMarkupNewEventsDutch() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_new_events_dutch) {
        return { __html: this.state.charity_page_new_events_dutch };
      }
    } else {
      if (this.state.charity_page_new_events_dutch_mobile) {
        return { __html: this.state.charity_page_new_events_dutch_mobile };
      }
    }
  }

  createMarkupNewEventsEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_new_events_english) {
        return { __html: this.state.charity_page_new_events_english };
      }
    } else {
      if (this.state.charity_page_new_events_english_mobile) {
        return { __html: this.state.charity_page_new_events_english_mobile };
      }
    }
  }

  createMarkupProfileDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_profile_dutch_heading) {
        return { __html: this.state.charity_page_profile_dutch_heading };
      }
    } else {
      if (this.state.charity_page_profile_dutch_heading_mobile) {
        return { __html: this.state.charity_page_profile_dutch_heading_mobile };
      }
    }
  }

  createMarkupProfileEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_profile_english_heading) {
        return { __html: this.state.charity_page_profile_english_heading };
      }
    } else {
      if (this.state.charity_page_profile_english_heading_mobile) {
        return {
          __html: this.state.charity_page_profile_english_heading_mobile,
        };
      }
    }
  }

  createMarkupProfileDutch() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_profile_dutch) {
        return { __html: this.state.charity_page_profile_dutch };
      }
    } else {
      if (this.state.charity_page_profile_dutch_mobile) {
        return { __html: this.state.charity_page_profile_dutch_mobile };
      }
    }
  }

  createMarkupProfileEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_profile_english) {
        return { __html: this.state.charity_page_profile_english };
      }
    } else {
      if (this.state.charity_page_profile_english_mobile) {
        return { __html: this.state.charity_page_profile_english_mobile };
      }
    }
  }

  createMarkupAboutDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_about_dutch_heading) {
        return { __html: this.state.charity_page_about_dutch_heading };
      }
    } else {
      if (this.state.charity_page_about_dutch_heading_mobile) {
        return { __html: this.state.charity_page_about_dutch_heading_mobile };
      }
    }
  }

  createMarkupAboutEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_about_english_heading) {
        return { __html: this.state.charity_page_about_english_heading };
      }
    } else {
      if (this.state.charity_page_about_english_heading_mobile) {
        return { __html: this.state.charity_page_about_english_heading_mobile };
      }
    }
  }

  createMarkupAboutDutch() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_about_dutch) {
        return { __html: this.state.charity_page_about_dutch };
      }
    } else {
      if (this.state.charity_page_about_dutch_mobile) {
        return { __html: this.state.charity_page_about_dutch_mobile };
      }
    }
  }

  createMarkupAboutEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_about_english) {
        return { __html: this.state.charity_page_about_english };
      }
    } else {
      if (this.state.charity_page_about_english_mobile) {
        return { __html: this.state.charity_page_about_english_mobile };
      }
    }
  }

  createMarkupTeamBhodiDutch() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_team_bhodi_dutch) {
        return { __html: this.state.charity_page_team_bhodi_dutch };
      }
    } else {
      if (this.state.charity_page_team_bhodi_dutch_mobile) {
        return { __html: this.state.charity_page_team_bhodi_dutch_mobile };
      }
    }
  }

  createMarkupTeamBhodiEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_team_bhodi_english) {
        return { __html: this.state.charity_page_team_bhodi_english };
      }
    } else {
      if (this.state.charity_page_team_bhodi_english_mobile) {
        return { __html: this.state.charity_page_team_bhodi_english_mobile };
      }
    }
  }

  createMarkupTeamBhodiDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_team_bhodi_dutch_heading) {
        return { __html: this.state.charity_page_team_bhodi_dutch_heading };
      }
    } else {
      if (this.state.charity_page_team_bhodi_dutch_heading_mobile) {
        return {
          __html: this.state.charity_page_team_bhodi_dutch_heading_mobile,
        };
      }
    }
  }

  createMarkupTeamBhodiEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_team_bhodi_english_heading) {
        return { __html: this.state.charity_page_team_bhodi_english_heading };
      }
    } else {
      if (this.state.charity_page_team_bhodi_english_heading_mobile) {
        return {
          __html: this.state.charity_page_team_bhodi_english_heading_mobile,
        };
      }
    }
  }

  createMarkupConnectDutch() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_connect_dutch) {
        return { __html: this.state.charity_page_connect_dutch };
      }
    } else {
      if (this.state.charity_page_connect_dutch_mobile) {
        return { __html: this.state.charity_page_connect_dutch_mobile };
      }
    }
  }

  createMarkupConnectEnglish() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_connect_english) {
        return { __html: this.state.charity_page_connect_english };
      }
    } else {
      if (this.state.charity_page_connect_english_mobile) {
        return { __html: this.state.charity_page_connect_english_mobile };
      }
    }
  }

  createMarkupConnectDutchHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_connect_dutch_heading) {
        return { __html: this.state.charity_page_connect_dutch_heading };
      }
    } else {
      if (this.state.charity_page_connect_dutch_heading_mobile) {
        return { __html: this.state.charity_page_connect_dutch_heading_mobile };
      }
    }
  }

  createMarkupConnectEnglishHeading() {
    if (window.innerWidth > 768) {
      if (this.state.charity_page_connect_english_heading) {
        return { __html: this.state.charity_page_connect_english_heading };
      }
    } else {
      if (this.state.charity_page_connect_english_heading_mobile) {
        return {
          __html: this.state.charity_page_connect_english_heading_mobile,
        };
      }
    }
  }

  createMarkupDynamic(e) {
    if (e) {
      return { __html: e };
    }
  }

  render() {
    return (
      <div
        className={`landingpage-bhodi landingpageupdateddesign charity-page-new`}
      >
        {!this.props.dontshowMenu && (
          <div className="header-lpbhodi">
            <Link className="link-style-trending" to="/">
              <img
                src={window.innerWidth > 1008 ? logoheaderWhite : logoheader}
              // onClick={() => {
              //   window.location.href = "/";
              // }}
              />
            </Link>
            {window.innerWidth > 1009 ? (
              <div className="menu-hlpbhodi">
                <ul>
                  {/* <li>Accessories</li>
                            <li>Dames</li>
                            <li>Heren</li>
                            <li>Onze merken</li>
                            <li>Kus van kiki</li> */}
                  <li
                  // onClick={() =>
                  //   (window.location.href = "/" )
                  // }
                  >
                    <Link
                      to="/"
                      className="link-style-trending"
                    >
                      Home
                    </Link>
                  </li>
                  <li
                    className="active"
                  // onClick={() => (window.location.href = "/charity-page")}
                  >
                    <Link to="/charity-page" className="link-style-trending">
                      Charity
                      <img src={whitestarnav} className="absoluteimgbottom" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/gift" className="link-style-trending">
                      Relatiegeschenk
                    </Link>
                  </li>
                  <li
                  // onClick={() => (window.location.href = "/commercial" )}
                  >
                    <Link to="/commercial" className="link-style-trending">
                      Zakelijk
                    </Link>
                  </li>

                  <li
                  // onClick={() =>
                  //   (window.location.href = "/registration")
                  // }
                  >
                    <Link
                      to="/registration"
                      className="link-style-trending"
                    >
                      Aanmelden
                    </Link>
                  </li>
                  {/* <li onClick={() => window.location.href = "/login"}>Inloggen</li> */}
                </ul>
              </div>
            ) : (
              <div className="menu-hlpbhodi">
                {/* <p>Selecteer een pagina</p> */}
                {!this.state.menuOpen ? (
                  <MenuIcon onClick={() => this.setState({ menuOpen: true })} />
                ) : (
                  <CloseIcon
                    onClick={() => this.setState({ menuOpen: false })}
                  />
                )}
              </div>
            )}
            {window.innerWidth < 1009 && (
              <div
                className={`menu-open-hlpbhodi ${this.state.menuOpen && "active"
                  }`}
              >
                <ul>
                  {/* <li>Accessories</li>
                            <li>Dames</li>
                            <li>Heren</li>
                            <li>Onze merken</li>
                        <li>Kus van kiki</li> */}
                  <li
                  // onClick={() =>
                  //   (window.location.href = "/" )
                  // }
                  >
                    <Link
                      to="/"
                      className="link-style-trending"
                    >
                      Home
                    </Link>
                  </li>

                  <li
                  // onClick={() => (window.location.href = "/commercial" )}
                  >
                    <Link to="/commercial" className="link-style-trending">
                      Zakelijk
                    </Link>
                  </li>
                  <li
                    className="active"
                  // onClick={() => (window.location.href = "/charity-page" )}
                  >
                    <Link to="/charity-page" className="link-style-trending">
                      Charity
                    </Link>
                  </li>
                  <li>
                    <Link to="/gift" className="link-style-trending">
                      Relatiegeschenk
                    </Link>
                  </li>
                  <li
                  // onClick={() =>
                  //   (window.location.href = "/registration")
                  // }
                  >
                    <Link
                      to="/registration"
                      className="link-style-trending"
                    >
                      Aanmelden
                    </Link>
                  </li>
                  {/* <li onClick={() => (window.location.href = "/login")}>
                    Inloggen
                  </li> */}
                </ul>
              </div>
            )}
            <div className="icons-hlpbhodi">
              <Link to="/login" className="link-style-trending">
                <PersonIcon
                // onClick={() => (window.location.href = "/login")}
                />
              </Link>
            </div>
            <FooterBasket />
          </div>
        )}
        {/* <div className="first-section-background-lpb" style={{ height: window.innerWidth > 1009 && this.props.onLandingPage && "100vh" }}>
          <video
            src={this.state.charity_page_banner_top_video}
            autoPlay
            muted
            loop
            playsInline
            id="videoloaded"
            style={{ maxHeight: window.innerWidth < 1008 && "100%" }}
          ></video>
          <div className="ace-fsblpb">
            <h1 data-aos={!this.props.fromPopup && "fade-in"}>
              {this.props.onLandingPage ? "Charity" : this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerDutchHeading()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerEnglishHeading()}
                ></div>
              )}
            </h1>
            <p data-aos={!this.props.fromPopup && "fade-in"}>
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupBannerEnglish()}
                ></div>
              )}
            </p>
            {!this.props.onLandingPage && (this.props.fromPopup ? (this.state.showDutch ? (
              <button>
                <a href="#charitysection">ONTDEK</a>
              </button>
            ) : (
              <button>
                <a href="#charitysection">DISCOVER</a>
              </button>
            )) : (this.state.showDutch ? (
              <button data-aos="fade-in">
                <a href="#charitysection">ONTDEK</a>
              </button>
            ) : (
              <button data-aos="fade-in">
                <a href="#charitysection">DISCOVER</a>
              </button>
            )))}
          </div>
        </div> */}
        <div className="second-section-background-lpb" style={{ backgroundColor: this.props.isSpecialProductsPage && "#FBFBFB" }}>
          <h2 className="heading-ssblbp">
            {/* Nieuwste events */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupNewEventsDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupNewEventsEnglishHeading()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupNewEventsDutchHeading()}></div> */}
          </h2>
          {/* <p className="paragraph-ssblbp">Meld je aan en krijg toegang <br /> tot alle events en aanbiedingen</p> */}
          {(<p className="paragraph-ssblbp">
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupNewEventsDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupNewEventsEnglish()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupNewEventsDutch()}></div> */}
          </p>)}
          {/* <div className="cards-event-ssblpb">
                        {[...this.state.events].splice(0, 4).map((e) => {
                            return <div className="ce-ssblpb" data-aos={!this.props.fromPopup && "fade-in"}>
                                <div className="image-cessblpb" style={{ backgroundImage: `url("${e.imageToRender}")` }}>

                                </div>
                                <div className="content-cessblpb">
                                    <h3>{this.getCompanyName(e.created_by)}</h3>
                                    <p>{e.title.substr(0, 18)}</p>
                                    <button onClick={this.redirectCompany.bind(this, e.created_by)}>Bekijk</button>
                                </div>
                            </div>
                        })}
                    </div> */}
          {(!this.props.fromPopup && !this.props.onLandingPage) && (<div className="bottom-main-button-ssblpb">
            {this.state.showDutch ? (
              <button
                onClick={() => {
                  window.location.href = "/registration";
                }}
              >
                AANMELDEN
              </button>
            ) : (
              <button
                onClick={() => {
                  window.location.href = "/registration";
                }}
              >
                SIGN UP
              </button>
            )}
          </div>)}
        </div>
        {!this.props.fromPopup && (<div className="third-section-background-lpb" id="charitysection" ref={this.props.refProp}>
          {this.state.showDutch ? (
            <div className="left-tsblpb">
              {window.innerWidth < 1008 ? (<h2 data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_title_mobile)}></h2>) : (<h2 data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_title)}></h2>)}
              {/* <h2 data-aos={!this.props.fromPopup && "fade-in"}>Charity Member</h2> */}
              <AiFillGift style={{ fontSize: 20, marginBottom: 15 }} />
              {window.innerWidth < 1008 ? (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_first_mobile)}></p>) : (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_first)}></p>)}
              <GiHotMeal
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth < 1008 ? (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_second_mobile)}></p>) : (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_second)}></p>)}
              {/* {window.innerWidth > 1009 ? (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  Tijdens je aanmelding kun je Charity Member
                  <br />
                  worden vanaf 6 EURO. Met jouw maandelijkse
                  <br />
                  bijdrage ondersteun je kinderen en scholen
                  <br />
                  in Tanzania met maaltijden en materialen.
                </p>
              ) : (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  Tijdens je aanmelding kun je Charity Member
                  <br />
                  worden vanaf 6 EUR. Met jouw maandelijkse
                  <br />
                  bijdrage ondersteun je kinderen en scholen
                  <br />
                  in Tanzania met maaltijden en materialen.
                </p>
              )} */}
              <GoDeviceCameraVideo
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth < 1008 ? (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_third_mobile)}></p>) : (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_third)}></p>)}
              {/* <p data-aos={!this.props.fromPopup && "fade-in"}>
                Voor je bijdrage ontvang je een persoonlijke
                <br />
                en op naam ingesproken bedankvideo vanuit
                <br />
                de school in Tanzania. En kun je direct gebruik
                <br />
                maken van alle exclusieve events op Bhodi.
              </p> */}
            </div>
          ) : (
            <div className="left-tsblpb">
              {window.innerWidth < 1008 ? (<h2 data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_title_english_mobile)}></h2>) : (<h2 data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_title_english)}></h2>)}
              {/* <h2 data-aos={!this.props.fromPopup && "fade-in"}>Charity Member</h2> */}
              <AiFillGift style={{ fontSize: 20, marginBottom: 15 }} />
              {/* {window.innerWidth > 1009 ? (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  The affiliated retailers and service providers
                  <br />
                  make exclusive pop-up events available on Bhodi.
                </p>
              ) : (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  The affiliated retailers and service
                  <br />
                  providers make exclusive pop-up events
                  <br />
                  available for Charity Members on Bhodi.
                </p>
              )} */}
              {window.innerWidth < 1008 ? (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_first_english_mobile)}></p>) : (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_first_english)}></p>)}
              <GiHotMeal
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth < 1008 ? (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_second_english_mobile)}></p>) : (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_second_english)}></p>)}
              {/* {window.innerWidth > 1009 ? (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  During your registration you can become a Charity
                  <br />
                  Member from 6 EUR. With your monthly contribution
                  <br />
                  you support the children and schools in Tanzania.
                  <br />
                  with meals and materials.
                </p>
              ) : (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  You can become a Charity Member starting
                  <br />
                  from 6 EUR. With your monthly contribution
                  <br />
                  you support the children and schools in
                  <br />
                  Tanzania with meals and materials.
                </p>
              )} */}
              <GoDeviceCameraVideo
                style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}
              />
              {window.innerWidth < 1008 ? (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_third_english_mobile)}></p>) : (<p data-aos={!this.props.fromPopup && "fade-in"} dangerouslySetInnerHTML={this.createMarkupDynamic(this.state.commercial_page_charity_third_english)}></p>)}
              {/* {window.innerWidth > 1009 ? (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  For your contribution you will receive a personal
                  <br />
                  and by name recorded thank-you video from the
                  <br />
                  school in Tanzania. And you can immediately use
                  <br />
                  all the exclusive events on Bhodi.
                </p>
              ) : (
                <p data-aos={!this.props.fromPopup && "fade-in"}>
                  For your contribution you will receive a<br />
                  personal and by name recorded thank-you
                  <br />
                  video from the school in Tanzania. And you
                  <br />
                  can immediately use all the exclusive
                  <br />
                  pop up events on Bhodi.
                </p>
              )} */}
            </div>
          )}
          <div className="right-tsblpb">
            <video
              src={this.state.charity_page_charity_image}
              autoPlay
              muted
              loop
              playsInline
            ></video>
          </div>
        </div>)}
        {(window.innerWidth > 1008 || (window.innerWidth < 1008 && !this.props.onLandingPage)) && !this.props.fromPopup && (<div className="second-section-background-lpb favroietesection">
          <h2 className="heading-ssblbp" data-aos={!this.props.fromPopup && "fade-in"}>
            {/* POP-UP EVENTS */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsEnglishHeading()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutchHeading()}></div> */}
          </h2>
          <p className="paragraph-ssblbp" data-aos={!this.props.fromPopup && "fade-in"}>
            {/* Expect the unexpected */}
            {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutchHeaderTxt()}></div> */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsDutchHeaderTxt()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupEventsEnglishHeaderTxt()}
              ></div>
            )}
          </p>
        </div>)}
        <div className="fourth-section-lpb" id="section-one-special">
          <div className="left-fslpb">
            <h2>
              {/* POP-UP EVENTS */}
              {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutchHeadingInner()}></div> */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsDutchHeadingInner()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsEnglishHeadingInner()}
                ></div>
              )}
            </h2>
            <p className="paragraph-ssblbp">
              {/* Verschillende winkeliers en dienstverleners organiseren op onverwachte momenten zeer exclusieve aanbiedingen, evenementen, workshops of winkeltoegang met een korting.
                            <br />
                            <br />
                            Meld je aan als charity member en krijg toegang tot exclusieve pop-up sale events. */}
              {/* Verschillende winkeliers en dienstverleners<br />
                            organiseren op Bhodi exclusieve pop-up events.
                            <br />
                            <br />
                            Op onverwachte momenten krijg je toegang tot<br />
                            allerlei aanbiedingen, zoals een winkeltoegang<br />
                            met een hoge korting of bijvoorbeeld een kortingscode<br />
                            die je exclusief kunt gebruiken op hun webshop.
                            <br />
                            <br />
                            Meld je aan als Bhodi charity member en krijg toegang<br />
                            tot alle exclusieve aanbiedingen op Bhodi. */}
              {/* <div dangerouslySetInnerHTML={this.createMarkupEventsDutch()}></div> */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupEventsEnglish()}
                ></div>
              )}
            </p>
            {(!this.props.fromPopup && !this.props.onLandingPage) && <>
              {this.state.showDutch ? (
                <button
                  data-aos={!this.props.fromPopup && "fade-in"}
                  onClick={() => {
                    window.location.href = "/registration";
                  }}
                >
                  AANMELDEN
                </button>
              ) : (
                <button
                  data-aos={!this.props.fromPopup && "fade-in"}
                  onClick={() => {
                    window.location.href = "/registration";
                  }}
                >
                  SIGN UP
                </button>
              )}
            </>}
          </div>
          <div
            className="right-fslpb"
            style={{
              backgroundImage: `url("${this.state.charity_page_event_right_image}")`,
            }}
          ></div>
        </div>
        {(window.innerWidth > 1008 || (window.innerWidth < 1008 && !this.props.onLandingPage)) && (<div className="second-section-background-lpb favroietesection">
          <h2 className="heading-ssblbp" data-aos={!this.props.fromPopup && "fade-in"}>
            {/* LET'S CONNECT */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupConnectDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupConnectEnglishHeading()}
              ></div>
            )}
            {/* <div dangerouslySetInnerHTML={this.createMarkupConnectDutchHeading()}></div> */}
          </h2>
          <p className="paragraph-ssblbp" data-aos={!this.props.fromPopup && "fade-in"}>
            {/* Volg je vrienden, favorieten merken en aanbieders */}
            {/* <div dangerouslySetInnerHTML={this.createMarkupConnectDutch()}></div> */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupConnectDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupConnectEnglish()}
              ></div>
            )}
          </p>
        </div>)}
        <div className="fifth-section-lpb" id="section-two-special">
          <div className="left-fislpb">
            <h2>
              {/* Profilepage */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileDutchHeading()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileEnglishHeading()}
                ></div>
              )}
              {/* <div dangerouslySetInnerHTML={this.createMarkupProfileDutchHeading()}></div> */}
            </h2>
            <p className="paragraph-ssblbp">
              {/* Op Bhodi krijg je een eigen profielpagina. <br />
                            Volg je favoriete merken en aanbieders en wordt <br />
                            als eerste op de hoogte gebracht van zeer exclusieve pop-up events. <br />
                            Krijg als charity member toegang tot de events van de aangesloten winkeliers.
                            <br />
                            <br />
                            Plaats je mooiste momenten op je Bhodi profielpagina!<br />
                            Geef toegang tot je foto's en video's en deel deze met je volgers. */}
              {/* <div dangerouslySetInnerHTML={this.createMarkupProfileDutch()}></div> */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupProfileEnglish()}
                ></div>
              )}
            </p>
            {(!this.props.fromPopup && !this.props.onLandingPage) && <>
              {this.state.showDutch ? (
                <button
                  data-aos={!this.props.fromPopup && "fade-in"}
                  onClick={() => {
                    window.location.href = "/registration";
                  }}
                >
                  AANMELDEN
                </button>
              ) : (
                <button
                  data-aos={!this.props.fromPopup && "fade-in"}
                  onClick={() => {
                    window.location.href = "/registration";
                  }}
                >
                  SIGN UP
                </button>
              )}
            </>}
          </div>
          <div
            className="right-fislpb"
          // style={{
          //   backgroundImage:
          //     this.state.profilesImage !== ""
          //       ? `url("${this.state.profilesImage}")`
          //       : "none",
          // }}
          >
            {/* {this.state.profilesVideo && (
              <video
                src={this.state.profilesVideo}
                autoPlay
                loop
                muted
                playsInline
                className="videocomplete"
              ></video>
            )} */}
            <video
              src={this.state.charity_page_profile_video ? this.state.charity_page_profile_video : teacherVideo}
              autoPlay
              loop
              muted
              playsInline
              className="videocomplete"
            ></video>
          </div>
        </div>
        <div className="second-section-background-lpb favroietesection" id="section-three-special">
          <h2 className="heading-ssblbp" data-aos={!this.props.fromPopup && "fade-in"}>
            {/* About */}
            {/* <div dangerouslySetInnerHTML={this.createMarkupAboutDutchHeading()}></div> */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutDutchHeading()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutEnglishHeading()}
              ></div>
            )}
          </h2>
          <p className="paragraph-ssblbp" data-aos={!this.props.fromPopup && "fade-in"}>
            {/* In Tanzania zijn er veel schoolkinderen die overdags vrijwel geen eten krijgen,<br />
                        wij vinden dat iedereen recht heeft op de primaire levensbehoeftes.<br />
                        Met bhodi ondersteunen wij kinderen en scholen in Tanzania met maaltijden. */}
            {/* Als Bhodi charity member kun je vanaf €4 per maand<br />
                        de kinderen en scholen in Tanzania ondersteunen met maaltijden en materialen.
                        <br />
                        <br />
                        De aangesloten winkeliers en dienstverleners stellen exclusieve<br />
                        pop-up events beschikbaar voor de charity members van Bhodi.<br />
                        pop-up events zijn aanbiedingen op zeer onverwachte momenten.
                        <br />
                        <br />
                        Met je aankoop ondersteun je eveneens de lokale winkeliers<br />
                        en dienstverleners en ontvang je hoge kortingen op je aankoop.<br />
                        Wie goed doet, goed ontmoet! */}
            {/* <div dangerouslySetInnerHTML={this.createMarkupAboutDutch()}></div> */}
            {this.state.showDutch ? (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutDutch()}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={this.createMarkupAboutEnglish()}
              ></div>
            )}
          </p>
        </div>
        <div
          className="sixth-section-lpb"
          style={{
            backgroundImage: `url("${this.state.landing_page_footer_image}")`,
          }}
        >
          <div className="container-sslpb">
            <h2>
              {/* Team Bhodi */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutchHeading()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiEnglishHeading()}
                ></div>
              )}
              {/* <div dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutchHeading()}></div> */}
            </h2>
            <div className="circle-csslpb">
              <RiDoubleQuotesR />
            </div>
            <p className="para-desc-sslpb">
              {/* Bhodi is een social commerce platform<br />en staan wij voor impact first en meer<br />gelijkheid in de wereld.
                            <br />
                            <br />
                            In samenwerking met verschillende winkeliers<br />en dienstverleners stellen
                            wij exclusieve events<br />beschikbaar voor de Bhodi charity members. */}
              {/* <div dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutch()}></div> */}
              {this.state.showDutch ? (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutch()}
                ></div>
              ) : (
                <div
                  dangerouslySetInnerHTML={this.createMarkupTeamBhodiEnglish()}
                ></div>
              )}
            </p>
            {/* <p className='name-desc-sslpb'>Maurice Reijersen & Naomi Latuperissa</p> */}
          </div>
        </div>
        <div className="footer-section-lpb">
          <div className="container-fslpb">
            <div className="block-cfslpb">
              <h3 data-aos={!this.props.fromPopup && "fade-in"}>Charity</h3>
              <p data-aos={!this.props.fromPopup && "fade-in"}>Maaltijden voor kinderen</p>
            </div>
            <div className="block-cfslpb">
              <h3 data-aos={!this.props.fromPopup && "fade-in"}>Events</h3>
              <p data-aos={!this.props.fromPopup && "fade-in"}>Leuke pop-up sale events</p>
            </div>
            {window.innerWidth < 1008 ? (<div className="block-cfslpb">
              <h3>Voorwaarden</h3>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                Gebruikersvoorwaarden
              </p>
            </div>) : (<div className="block-cfslpb">
              <h3 data-aos={!this.props.fromPopup && "fade-in"}>Voorwaarden</h3>
              {this.props.fromPopup ? (<p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                Gebruikersvoorwaarden
              </p>) : (<p
                style={{ cursor: "pointer" }}
                data-aos="fade-in"
                onClick={() => {
                  window.open(
                    "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                Gebruikersvoorwaarden
              </p>)}
            </div>)}
          </div>
          {/* <div className="container-fslpb iconscontainer">
            <div
              className="block-cfslpb"
              onClick={() => {
                window.open(
                  "https://www.facebook.com/bhodi.lifestyle",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            >
              <FaFacebookF />
            </div>
            <div
              className="block-cfslpb"
              onClick={() => {
                window.open(
                  "https://twitter.com/bhodi_lifestyle",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            >
              <BsTwitter />
            </div>
            <div
              className="block-cfslpb"
              onClick={() => {
                window.open(
                  "https://www.instagram.com/bhodi_lifestyle",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            >
              <AiOutlineInstagram />
            </div>
          </div> */}
        </div>
        {!this.props.isSpecialProductsPage && (<Footer notOnMainPage={true} />)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
    user: state.authReducers.user,
    admin_boolean: state.globalReducers.admin_boolean,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAdminOrNot: (admin_boolean) =>
      dispatch(updateAdminOrNot(admin_boolean)),
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CharityLandingPage);
