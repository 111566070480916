import React, { Component } from 'react'
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import firebase from "../../Config/Firebase"
import logo from "../../assets/logocompanyregistration.png"
import "./index.css"
import axios from "axios";
import { connect } from 'react-redux';
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../Redux/actions/authActions";
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import playicon from "../../assets/playiconlogomobile.png"
import { invalidChars } from '../../Config/Constants';

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class CompanySubscribe extends Component {
    constructor() {
        super()
        this.state = {
            aanmeldenInfo: false,
            secondStep: false,
            aanmeldenloader: false,
            dropFileKortingsVoucher: [],
            kortingsVoucherImage: [
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
            ],
            companyName: "",
            phoneNum: "",
            street: "",
            email: "",
            houseNum: "",
            password: "",
            postCode: "",
            bankAccountNumber: "",
            city: "",
            bankName: "",
            allUsers: [],
            kortingsVoucherTitle: "",
            kortingsVoucherNormalPrice: "",
            kortingsVoucherSalePrice: "",
            kortingsVoucherDesc: "",
            currentUserSpecific: "",
            showMainLoader: false,
            fromDirectUrl: false,
            companyNameDefault: "",
            thirdStep: false,
            companyUrlRedirect: "",
            defaultEmailParam: "",
            defaultKeyParam: "",

            allListings: [],
            indexOfCurrentCompany: -1,
            currentIndustry: "restaurant"
        }
    }

    componentDidMount() {

        let urlMain = new URLSearchParams(window.location.search)

        if (urlMain.get("email") && urlMain.get("key")) {
            this.setState({ showMainLoader: true })

            let industry = "restaurant"
            if (urlMain.get("industry")) {
                industry = urlMain.get("industry")
            }

            firebase.database().ref(industry === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${industry}`).once("value", (data) => {
                let a = data.val()
                if (a) {
                    let filtered = a.filter((t) => {
                        return t.email === urlMain.get("email")
                    })
                    let indexOfCurrent = a.findIndex((t) => {
                        return t.email === urlMain.get("email")
                    })
                    if (filtered.length > 0) {
                        this.setState({ fromDirectUrl: true, showMainLoader: false, allListings: a, companyNameDefault: filtered[0].company_name, defaultEmailParam: urlMain.get("email"), email: urlMain.get("email"), defaultKeyParam: urlMain.get("key"), indexOfCurrentCompany: indexOfCurrent, currentIndustry: industry })
                    }
                }
            })
        }


        firebase
            .database()
            .ref("users")
            .once("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a && !this.state.stopTakingData) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        arr.push(u[1]);
                    });
                    let usersWithNumbers = [];
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t);
                        }
                    });
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                } else {
                    this.setState({ allUsers: [] });
                }
            });
    }

    uploadImg(e) {
        e.preventDefault();
        let dropFiles = [];

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            objected.map((f, i) => {
                const reader = new FileReader();
                dropFiles.push(objected[i][1]);
                reader.addEventListener("load", () => {
                    let img = new Image();
                    let result = reader.result;
                    img.onload = (e) => {
                        let arr = [];
                        arr.push(result);

                        this.setState({
                            kortingsVoucherImage: arr,
                            dropFileKortingsVoucher: dropFiles,
                        });

                    };
                    img.src = result;
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });
        }
    }


    checkForDeleteKortingsVoucher() {
        if (this.state.kortingsVoucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb") {
            return (
                <div
                    className="editimageupload"
                    onClick={() => {
                        this.handleValuesKortingsVoucher()

                        this.setState({
                            dropFileKortingsVoucher: [],
                            kortingsVoucherImage: [
                                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                            ],
                        });
                    }}
                >
                    <DeleteIcon />
                </div>
            );
        }
    }

    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }

    moveToSecondStep() {
        const { companyName, phoneNum, street, email, houseNum, password, postCode, bankAccountNumber, city, bankName, agreed } = this.state

        this.updateStateOnCondition(companyName, "companyNameEmpty")
        this.updateStateOnCondition(phoneNum, "phoneNumEmpty")
        this.updateStateOnCondition(street, "streetEmpty")
        this.updateStateOnCondition(houseNum, "houseNumEmpty")
        this.updateStateOnCondition(postCode, "postCodeEmpty")
        this.updateStateOnCondition(bankAccountNumber, "bankAccountNumberEmpty")
        this.updateStateOnCondition(city, "cityEmpty")
        this.updateStateOnCondition(bankName, "bankNameEmpty")

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let filteredForEmail = this.state.allUsers.filter((v) => {
            return v.email === email;
        });

        if (filteredForEmail.length === 0) {
            this.setState({ emailInvalid: false });
        } else {
            if (email && regexEmail.test(email)) {
                this.setState({ emailInvalid: true });
            }
        }

        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passInvalid: true });
        } else {
            this.setState({ passInvalid: false });
        }

        if (
            this.checkForValuePresent(companyName) &&
            this.checkForValuePresent(phoneNum) &&
            this.checkForValuePresent(street) &&
            this.checkForValuePresent(houseNum) &&
            this.checkForValuePresent(postCode) &&
            this.checkForValuePresent(bankAccountNumber) &&
            this.checkForValuePresent(city) &&
            this.checkForValuePresent(bankName) &&
            email !== "" && regexEmail.test(email) && password !== "" && password.length > 5 &&
            agreed
        ) {
            this.setState({ secondStep: true })
        }
    }

    moveToSecondStepDirect() {
        const { email, password, bankAccountNumber, bankName, agreed } = this.state

        this.updateStateOnCondition(bankAccountNumber, "bankAccountNumberEmpty")
        this.updateStateOnCondition(bankName, "bankNameEmpty")

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let filteredForEmail = this.state.allUsers.filter((v) => {
            return v.email === email;
        });

        if (filteredForEmail.length === 0) {
            this.setState({ emailInvalid: false });
        } else {
            if (email && regexEmail.test(email)) {
                this.setState({ emailInvalid: true });
            }
        }

        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passInvalid: true });
        } else {
            this.setState({ passInvalid: false });
        }

        if (
            email !== "" && regexEmail.test(email) && password !== "" && password.length > 5 &&
            agreed
        ) {
            this.setState({ secondStep: true })
        }
    }

    createCompanyAndCampaign() {
        const { kortingsVoucherTitle, kortingsVoucherDesc, kortingsVoucherNormalPrice, kortingsVoucherSalePrice, dropFileKortingsVoucher, companyName, phoneNum, street, email, houseNum, password, postCode, bankAccountNumber, city, bankName } = this.state

        this.updateStateOnCondition(kortingsVoucherTitle, "kortingsVoucherTitleAlert")
        this.updateStateOnCondition(kortingsVoucherDesc, "kortingsVoucherDescAlert")
        this.updateStateOnCondition(kortingsVoucherNormalPrice, "kortingsVoucherNormalPriceAlert")
        this.updateStateOnCondition(kortingsVoucherSalePrice, "kortingsVoucherSalePriceAlert")

        if (dropFileKortingsVoucher.length > 0) {
            this.setState({ kortingsVoucherImageAlert: false })
        } else {
            this.setState({ kortingsVoucherImageAlert: true })
        }

        if (
            this.checkForValuePresent(kortingsVoucherTitle) &&
            this.checkForValuePresent(kortingsVoucherDesc) &&
            this.checkForValuePresent(kortingsVoucherNormalPrice) &&
            this.checkForValuePresent(kortingsVoucherSalePrice) &&
            dropFileKortingsVoucher.length > 0
        ) {
            let promiseAccountDataEmailSend = new Promise((resolve, reject) => {
                if (!this.state.fromDirectUrl) {
                    let myPlaintextPassword = password;
                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {

                            this.setState({ aanmeldenloader: true });

                            let jointName = companyName;

                            let filteredForJoin = this.state.allUsers.filter((g) => {
                                return g.user_name_id === jointName.toLowerCase();
                            });

                            if (filteredForJoin.length > 0) {
                                let randomStr = Math.ceil(Math.random() * (20 - 0) + 0);
                                jointName = companyName + randomStr;
                            }

                            if (jointName.includes(" ")) {
                                jointName = jointName.replace(/ /g, "_");
                            }

                            let today = new Date();
                            let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

                            firebase
                                .auth()
                                .createUserWithEmailAndPassword(email, password)
                                .then((e) => {
                                    let userId = e.user.uid;
                                    localStorage.setItem("userid", userId);
                                    let obj = {
                                        first_name: "",
                                        last_name: "",
                                        email: email,
                                        password: hash,
                                        country: "Netherlands",
                                        passwordChanged: false,
                                        user_id: userId,
                                        user_name_id: jointName.toLowerCase(),
                                        created_on: date,
                                        newly_registered: true,
                                        user_id_numbers: this.state.currentUserSpecific,
                                        accountType: "global",
                                        street,
                                        city,
                                        zipCode: postCode,
                                        phoneNumber: phoneNum,
                                        houseNumber: houseNum,
                                        company_name: companyName,
                                        bankName,
                                        bankAccountNumber,
                                        profileActive: true,
                                        hideWizard: true,
                                        lunch_show_contact: true,
                                        dinner_show_contact: true,
                                        type: "company",
                                        dinner_title_contact: "Dinner",
                                        lunch_title_contact: "Dinner",
                                    };

                                    firebase
                                        .database()
                                        .ref("users/" + userId)
                                        .set(obj)
                                        .then(() => {
                                            // if (this.props.mustFollowUser) {
                                            //     this.followCompany(userId)
                                            // }
                                            localStorage.setItem("userid", e.user.uid);
                                            localStorage.removeItem("cookie_item");
                                            localStorage.setItem("isAdmin", false);
                                            this.props.updateAdminOrNot(false);

                                            var dataForAPI = JSON.stringify({
                                                sendToEmail: email,
                                                subject: "Account Created at Bhodi!",
                                                innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><p>Company Name: ${companyName}</p><p>Email: ${email}</p><p>Password: ${myPlaintextPassword}</p></body></html>`,
                                            });

                                            var config = {
                                                method: "post",
                                                url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendEmailMailjet",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                },
                                                data: dataForAPI,
                                            };

                                            axios(config)
                                                .then((response) => {
                                                    if (response.data.statusCode === 201) {
                                                        let redirect_url = ""
                                                        if (!this.state.isUserForm) {
                                                            redirect_url = `${window.location.origin}/company/${jointName.toLowerCase()}`
                                                        } else {
                                                            redirect_url = `${window.location.origin}/member/${jointName.toLowerCase()}`
                                                        }

                                                        let obj = {
                                                            id: userId,
                                                            urlRedirect: redirect_url,
                                                        }

                                                        resolve(obj)
                                                    }
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                        });
                                })
                                .catch((error) => {
                                    if (error.code === "auth/email-already-in-use") {
                                        this.setState({
                                            loader: false,
                                            stepOne: true,
                                            emailTookedPopup: true,
                                            aanmeldenloader: false,
                                            secondStep: false,
                                        });
                                    }
                                    console.log(error);
                                });
                        });
                    });
                } else {

                    let promiseLogin = new Promise((res) => {
                        let urlMain = new URLSearchParams(window.location.search)
                        this.setState({ aanmeldenloader: true })

                        if (urlMain.get("email") && urlMain.get("key")) {
                            firebase.auth().signInWithEmailAndPassword(this.state.defaultEmailParam, this.state.defaultKeyParam)
                                .then((succ) => {
                                    res(succ.user.uid)
                                })
                        }
                    })

                    promiseLogin.then((uid) => {
                        let myPlaintextPassword = password;
                        bcrypt.genSalt(saltRounds, (err, salt) => {
                            bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {

                                let promisePwdUpdate = new Promise((res) => {
                                    const user = firebase.auth().currentUser;
                                    user.updatePassword(myPlaintextPassword)
                                        .then(() => {
                                            firebase
                                                .auth()
                                                .signInWithEmailAndPassword(this.state.defaultEmailParam, myPlaintextPassword)
                                                .then(() => {
                                                    res()
                                                }).catch((err) => {
                                                    console.log(err)
                                                })
                                        }).catch((err) => {
                                            console.log(err)
                                        })
                                })

                                promisePwdUpdate.then(() => {
                                    const user = firebase.auth().currentUser;
                                    let promiseEmailUpdate = new Promise((res) => {
                                        if (email !== this.state.defaultEmailParam) {
                                            user.updateEmail(email)
                                                .then(() => {
                                                    res()
                                                }).catch((err) => {
                                                    console.log(err)
                                                })
                                        } else {
                                            res()
                                        }
                                    })

                                    promiseEmailUpdate.then(() => {
                                        const updates = {
                                            [`users/${uid}/email`]: email,
                                            [`users/${uid}/password`]: hash,
                                            [`users/${uid}/bankName`]: bankName,
                                            [`users/${uid}/bankAccountNumber`]: bankAccountNumber,
                                            [`users/${uid}/hideWizard`]: true,
                                            [`users/${uid}/profileActive`]: true
                                        };

                                        let userCurrent = this.props.user.filter((t) => {
                                            return t.user_id === uid
                                        })

                                        firebase.database().ref().update(updates).then(() => {
                                            let redirect_url = ""
                                            if (userCurrent.length > 0) {
                                                redirect_url = `${window.location.origin}/company/${userCurrent[0].user_name_id}`
                                            }

                                            localStorage.setItem("userid", uid);
                                            localStorage.removeItem("cookie_item");
                                            localStorage.setItem("isAdmin", false);
                                            this.props.updateAdminOrNot(false);

                                            let obj = {
                                                id: uid,
                                                urlRedirect: redirect_url,
                                            }

                                            if (this.state.allListings.length > 0 && this.state.indexOfCurrentCompany !== -1) {
                                                this.state.allListings[this.state.indexOfCurrentCompany].account_created = true
                                                firebase.database().ref("accounts_by_admin").set(this.state.allListings)
                                            }

                                            resolve(obj)
                                        })
                                    })
                                })
                            })
                        })
                    })
                }
            })
            let promiseUploadImg = new Promise((resolve, reject) => {
                dropFileKortingsVoucher.map((e) => {
                    firebase
                        .storage()
                        .ref()
                        .child(`campaigns/${e.name}${new Date().getTime()}`)
                        .put(e)
                        .then((snapshot) => {
                            snapshot.ref.getDownloadURL().then((snapUrl) => {
                                resolve(snapUrl)
                            });
                        });
                });
            })
            let promiseGetLatLong = new Promise((resolve, reject) => {
                let address = ""
                if (this.state.fromDirectUrl) {
                    address = `${this.state.allListings[this.state.indexOfCurrentCompany].street} ${this.state.allListings[this.state.indexOfCurrentCompany].houseNumber}, ${this.state.allListings[this.state.indexOfCurrentCompany].zipCode} ${this.state.allListings[this.state.indexOfCurrentCompany].city}, Netherlands`;
                } else {
                    address = `${street} ${houseNum}, ${postCode} ${city}, Netherlands`;
                }

                var config = {
                    method: "get",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        let coords = response.data.results[0].geometry.location;

                        let obj = {
                            lat: coords.lat,
                            lng: coords.lng,
                        }
                        resolve(obj)
                    })
                    .catch(function () {
                        let obj = {
                            lat: "",
                            lng: "",
                        }
                        resolve(obj)
                    });
            })
            Promise.all([promiseAccountDataEmailSend, promiseUploadImg, promiseGetLatLong]).then((data) => {
                let userId = data[0].id
                let snapUrl = data[1]
                let latitude = data[2].lat
                let longitude = data[2].lng

                let storageKey = firebase.database().ref("bhodi_campaigns/" + userId).push().key;
                let timestamp = Math.floor(Date.now() / 100);

                let obj = {
                    title: kortingsVoucherTitle,
                    desc: kortingsVoucherDesc,
                    kortingsVoucherImageType: "uploadfromcomp",
                    kortingsVoucherPrijs: false,
                    kortingsVoucherPercentage: false,
                    campaignType: "kortings_voucher",
                    pushKey: storageKey,
                    image: snapUrl,
                    active: true,
                    created_by: userId,
                    timestamp,
                    number: "",
                    kortingsVoucherUnlimited: true,
                    created_at: new Date().getTime(),
                    kortingsVoucherSalePrice,
                    kortingsVoucherNormalPrice,
                    main_sector: "Fashion & Shoes1633955735296",
                    main_category: ["Accessoires1633956984219"]
                };

                firebase
                    .database()
                    .ref("bhodi_campaigns/" + userId + "/" + storageKey)
                    .set(obj)
                    .then(() => {
                        firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                        firebase.database().ref("users/" + userId + "/longitude").set(longitude)

                        if (this.state.fromDirectUrl) {
                            this.setState({ thirdStep: true, companyUrlRedirect: data[0].urlRedirect, companyUserId: userId })
                        } else {
                            setTimeout(() => {
                                window.location.href = data[0].urlRedirect
                            }, 500);
                        }
                    });
            })
        }
    }


    copyToClipboard() {
        this.setState({ copiedTextDone: true });
        var textField = document.createElement("textarea");
        textField.innerText = this.state.companyUrlRedirect;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        setTimeout(() => {
            this.setState({ copiedTextDone: false })
        }, 3000);
    }


    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                    {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
                </div>
            ) : <div className='company-subscribe-main'>
                <video
                    src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bg-bhodi.mp4?alt=media&token=a1fdec11-797f-4619-8414-f0d329c23fb4&_gl=1*edigq3*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5NjYwMTYzNS40MDMuMS4xNjk2NjAyMjY5LjYwLjAuMA."
                    autoPlay
                    playsInline
                    muted
                    loop
                    className="absolutevideofullbg"
                ></video>
                <div className='content-cosumain'>
                    <div className='top-above-form-cocosum' style={{ marginBottom: (this.state.fromDirectUrl && window.innerWidth < 1008) && 20 }}>
                        {/* <h4>BHODI</h4>
                        <p>For the love of delicious food</p> */}
                        {!(this.state.fromDirectUrl && window.innerWidth < 1008) && (<img src={logo} alt='logo' className='main-logo-tafc' />)}
                        {(this.state.fromDirectUrl && window.innerWidth < 1008) && !this.state.secondStep && !this.state.thirdStep && (<div className='form-direct-url-top'>
                            <img src={playicon} alt='playicon' />
                            <p>Hi Team van {this.state.companyNameDefault}</p>
                            <p className='third-smt-fbcoco' style={{ marginTop: 15 }}>
                                Leuk dat jullie willen mee gaan doen!<br />
                                Binnen 2 stappen kunnen jullie de<br />
                                aanmelding compleet maken.
                            </p>
                        </div>)}
                    </div>
                    {this.state.thirdStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Yess! Jullie profielpagina is aangemaakt.</p>
                            <p className='third-smt-fbcoco'>
                                De pagina kunnen jullie indien gewenst nog personaliseren
                                met een mooie eigen foto of video van jullie restaurant.
                            </p>
                            <p className='third-smt-fbcoco'>
                                Mochten jullie nog voorwaarden of instucties willen
                                toevoegen voor het gebruik van het menu, dan kan dat
                                heel eenvoudig via de settings op jullie account.
                            </p>
                            <p className='third-smt-fbcoco'>
                                Jullie profielpagina is voorzien van een unieke link.
                                Deze link kunnen jullie gebruiken om eenvoudig gasten
                                uit te nodigen voor het menu en deze te delen via de
                                socials en natuurlijk jullie nieuwsbrief.
                            </p>
                            <p className='third-smt-fbcoco'>
                                {this.state.companyUrlRedirect} {this.state.copiedTextDone ? (<font style={{ color: "#C17F6B", cursor: "pointer" }}>Gekopieerd</font>) : (<font style={{ color: "#C17F6B", cursor: "pointer" }} onClick={() => this.copyToClipboard()}>Kopieer</font>)}
                            </p>
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "center" }}>
                            <button onClick={() => {
                                window.location.href = this.state.companyUrlRedirect
                            }}>BEKIJK DE PAGINA</button>
                        </div>
                    </div>) : this.state.secondStep ? (<div className='form-box-cocosum'>
                        <p className='first-smt-fbcoco'>Restaurant</p>
                        <div className='second-headingwrap-fbcoco' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Menu toevoegen</p>
                            <p className='third-smt-fbcoco'>Indien gewenst kun je het menu later nog wijzigen</p>
                        </div>
                        <div className='form-wrapper-cocosum'>
                            <div className='fullwidth-inp'>
                                <input
                                    placeholder='Titel'
                                    style={{ color: this.state.kortingsVoucherTitleAlert && "red" }}
                                    className={this.state.kortingsVoucherTitleAlert && 'inputplaceholderred'}
                                    value={this.state.kortingsVoucherTitle}
                                    onChange={(e) => {
                                        this.setState({ kortingsVoucherTitle: e.target.value })
                                    }}
                                />
                            </div>
                            <input
                                type="number"
                                placeholder='Prijs p.p €'
                                style={{ color: this.state.kortingsVoucherNormalPriceAlert && "red" }}
                                className={this.state.kortingsVoucherNormalPriceAlert && 'inputplaceholderred'}
                                value={this.state.kortingsVoucherNormalPrice}
                                onChange={(e) => {
                                    this.setState({ kortingsVoucherNormalPrice: e.target.value })
                                }}
                            />
                            <input
                                type="number"
                                placeholder='Actie prijs p.p €'
                                style={{ color: this.state.kortingsVoucherSalePriceAlert && "red" }}
                                className={this.state.kortingsVoucherSalePriceAlert && 'inputplaceholderred'}
                                value={this.state.kortingsVoucherSalePrice}
                                onChange={(e) => {
                                    this.setState({ kortingsVoucherSalePrice: e.target.value })
                                }}
                            />
                            <div className='fullwidth-inp'>
                                <textarea
                                    placeholder='Omschrijving'
                                    style={{ color: this.state.kortingsVoucherDescAlert && "red" }}
                                    className={this.state.kortingsVoucherDescAlert && 'inputplaceholderred'}
                                    value={this.state.kortingsVoucherDesc}
                                    onChange={(e) => {
                                        this.setState({ kortingsVoucherDesc: e.target.value })
                                    }}
                                ></textarea>
                            </div>
                            <div className='image-wrappwr-fwcoco'>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <div className='wrapper-select-new'>
                                        <input
                                            type="text"
                                            value="Upload foto"
                                            readOnly
                                            className="dolabelsuppercase"
                                        />
                                        <input
                                            type="file"
                                            onChange={(e) => this.uploadImg(e)}
                                            accept="image/*,video/*"
                                            style={{
                                                position: "absolute",
                                                width: "100%",
                                                height: "80%",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                opacity: 0,
                                            }}
                                        />
                                        <PublishIcon
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: 5,
                                                bottom: this.state.localEventImageAlert ? 37 : 10,
                                                fontSize: 18,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                                {this.state.kortingsVoucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" && (<div className="image-campaigns-new">
                                    <div
                                        className="image-sfcplp"
                                        style={{
                                            backgroundImage: `url("${this.state.kortingsVoucherImage[0]}")`,
                                        }}
                                    >
                                    </div>
                                    {this.checkForDeleteKortingsVoucher()}
                                </div>)}
                            </div>
                            {this.state.kortingsVoucherImageAlert && (
                                <label style={{ color: "red", fontSize: 13, letterSpacing: 1, marginTop: 10 }}>
                                    Verplicht veld
                                </label>
                            )}
                        </div>
                        <div className='bottom-cocosum'>
                            {this.state.aanmeldenloader ? (
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (<button onClick={() => {
                                this.createCompanyAndCampaign()
                            }}>AFRONDEN</button>)}
                        </div>
                    </div>) : this.state.fromDirectUrl ? ((<div className='form-box-cocosum'>
                        {window.innerWidth > 1008 && (<p className='first-smt-fbcoco'>Aanmelden</p>)}
                        {window.innerWidth > 1008 && (<div className='second-headingwrap-fbcoco' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Hi Team van {this.state.companyNameDefault}</p>
                            <p className='third-smt-fbcoco' style={{ marginTop: 15 }}>Wat leuk dat jullie met ons mee willen gaan meedoen!<br />In 2 stappen kunnen jullie de aanmelding compleet maken.</p>
                        </div>)}
                        <div className='form-wrapper-cocosum'>
                            <div className='label-default-profiles'>
                                <label>Inloggegevens voor Bhodi</label>
                            </div>
                            <input
                                placeholder='E-mail'
                                value={this.state.email}
                                className={this.state.emailInvalid && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ email: e.target.value })
                                }}
                                style={{ color: this.state.emailInvalid && "red" }}
                            />
                            <input
                                placeholder='Kies wachtwoord'
                                type='password'
                                value={this.state.password}
                                className={this.state.passInvalid && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ password: e.target.value })
                                }}
                                style={{ color: this.state.passInvalid && "red" }}
                            />
                            <div className='label-default-profiles' style={{ margin: "20px 0px", marginTop: 40 }}>
                                <label>Op welk rekeningnummer mogen{window.innerWidth < 1008 && (<br />)}wij de fee storten?</label>

                                <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                    this.setState({ aanmeldenInfo: !this.state.aanmeldenInfo })
                                }}>
                                    <font style={{ marginRight: 3 }}>?</font>
                                </div>
                                <div className="popup-gri-thifc" style={{ opacity: this.state.aanmeldenInfo ? 1 : 0, pointerEvents: this.state.aanmeldenInfo ? "all" : "none" }}>
                                    <CloseIcon onClick={() => this.setState({ aanmeldenInfo: false })} />
                                    <label style={{ fontSize: 15 }}>
                                        Wanneer je de producten exlusief maakt, dienen
                                        de members zich hiervoor extra aan te melden.
                                        Van de bijdragen per maand, ontvangen jullie als bedrijf een deel:
                                        2, 5, 8 of 12 euro per maand. Op deze manier kunnen jullie
                                        een subscription model toepassen op het productaanbod.
                                    </label>
                                </div>
                            </div>
                            <input
                                placeholder='Bankrekeningnummer'
                                value={this.state.bankAccountNumber}
                                className={this.state.bankAccountNumberEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ bankAccountNumber: e.target.value })
                                }}
                                style={{ color: this.state.bankAccountNumberEmpty && "red" }}
                            />
                            <input
                                placeholder='Naam rekeninghouder'
                                value={this.state.bankName}
                                className={this.state.bankNameEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ bankName: e.target.value })
                                }}
                                style={{ color: this.state.bankNameEmpty && "red" }}
                            />
                        </div>
                        <div className='bottom-cocosum'>
                            <div className="checking-box">
                                <input
                                    type="checkbox"
                                    checked={this.state.agreed}
                                    onChange={() =>
                                        this.setState({ agreed: !this.state.agreed })
                                    }
                                />
                                <label
                                    style={{
                                        color: this.state.agreedPopup ? "red" : "#d3d3d3",
                                        fontSize: 13, width: "100%"
                                    }}
                                >
                                    <font
                                        style={{
                                            // textDecorationLine: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            window.open(
                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                    >
                                        Akkoord met voorwaarden
                                    </font>
                                </label>
                            </div>
                            <button onClick={() => {
                                this.moveToSecondStepDirect()
                            }}>VOLGENDE</button>
                        </div>
                    </div>)) : (<div className='form-box-cocosum'>
                        <p className='first-smt-fbcoco'>Restaurant</p>
                        <div className='second-headingwrap-fbcoco'>
                            <p>Aanmelden</p>
                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ aanmeldenInfo: !this.state.aanmeldenInfo })
                            }}>
                                <font style={{ marginRight: 3 }}>?</font>
                            </div>
                            <div className="popup-gri-thifc" style={{ opacity: this.state.aanmeldenInfo ? 1 : 0, pointerEvents: this.state.aanmeldenInfo ? "all" : "none" }}>
                                <CloseIcon onClick={() => this.setState({ aanmeldenInfo: false })} />
                                <label style={{ fontSize: 15 }}>
                                    Wanneer je de producten exlusief maakt, dienen
                                    de members zich hiervoor extra aan te melden.
                                    Van de bijdragen per maand, ontvangen jullie als bedrijf een deel:
                                    2, 5, 8 of 12 euro per maand. Op deze manier kunnen jullie
                                    een subscription model toepassen op het productaanbod.
                                </label>
                            </div>
                        </div>
                        <div className='form-wrapper-cocosum'>
                            <input
                                placeholder='Bedrijfsnaam'
                                value={this.state.companyName}
                                className={this.state.companyNameEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ companyName: e.target.value })
                                }}
                                style={{ color: this.state.companyNameEmpty && "red" }}
                            />
                            <input
                                placeholder='Telefoonnummer'
                                value={this.state.phoneNum}
                                className={this.state.phoneNumEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ phoneNum: e.target.value })
                                }}
                                style={{ color: this.state.phoneNumEmpty && "red" }}
                                onKeyDown={(e) => {
                                    if (invalidChars.includes(e.key.toLowerCase())) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <input
                                placeholder='Straatnaam'
                                value={this.state.street}
                                className={this.state.streetEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ street: e.target.value })
                                }}
                                style={{ color: this.state.streetEmpty && "red" }}
                            />
                            <input
                                placeholder='E-mail'
                                value={this.state.email}
                                className={this.state.emailInvalid && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ email: e.target.value })
                                }}
                                style={{ color: this.state.emailInvalid && "red" }}
                            />
                            <input
                                placeholder='Huisnummer'
                                value={this.state.houseNum}
                                className={this.state.houseNumEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ houseNum: e.target.value })
                                }}
                                style={{ color: this.state.houseNumEmpty && "red" }}
                            />
                            <input
                                placeholder='Kies wachtwoord'
                                type='password'
                                value={this.state.password}
                                className={this.state.passInvalid && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ password: e.target.value })
                                }}
                                style={{ color: this.state.passInvalid && "red" }}
                            />
                            <input
                                placeholder='Postcode'
                                value={this.state.postCode}
                                className={this.state.postCodeEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ postCode: e.target.value })
                                }}
                                style={{ color: this.state.postCodeEmpty && "red" }}
                            />
                            <input
                                placeholder='Bankrekeningnummer'
                                value={this.state.bankAccountNumber}
                                className={this.state.bankAccountNumberEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ bankAccountNumber: e.target.value })
                                }}
                                style={{ color: this.state.bankAccountNumberEmpty && "red" }}
                            />
                            <input
                                placeholder='Plaatsnaam'
                                value={this.state.city}
                                className={this.state.cityEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ city: e.target.value })
                                }}
                                style={{ color: this.state.cityEmpty && "red" }}
                            />
                            <input
                                placeholder='Naam rekeninghouder'
                                value={this.state.bankName}
                                className={this.state.bankNameEmpty && 'inputplaceholderred'}
                                onChange={(e) => {
                                    this.setState({ bankName: e.target.value })
                                }}
                                style={{ color: this.state.bankNameEmpty && "red" }}
                            />
                        </div>
                        <div className='bottom-cocosum'>
                            <div className="checking-box">
                                <input
                                    type="checkbox"
                                    checked={this.state.agreed}
                                    onChange={() =>
                                        this.setState({ agreed: !this.state.agreed })
                                    }
                                />
                                <label
                                    style={{
                                        color: this.state.agreedPopup ? "red" : "#d3d3d3",
                                        fontSize: 13, width: "100%"
                                    }}
                                >
                                    <font
                                        style={{
                                            // textDecorationLine: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            window.open(
                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                    >
                                        Akkoord met voorwaarden
                                    </font>
                                </label>
                            </div>
                            <button onClick={() => {
                                this.moveToSecondStep()
                            }}>VOLGENDE</button>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySubscribe);