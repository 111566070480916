

import React, { Component } from "react";
import "./index.css";
import countries from "../../DataJSON/countries.json";
import { connect } from "react-redux";
import Footer from "../../Components/Footer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import firebase from "../../Config/Firebase";
import {
    updateAdminOrNot,
    updateSignUpFieldsFilled,
} from "../../Redux/actions/authActions";
import FooterBasket from "../../Components/FooterBasket";
import FooterGreen from "../../Components/FooterGreen";
import { history } from "../../Config/Routes";
import FooterBasketTimer from "../../Components/FooterBasketTimer";
import swal from "sweetalert";
const axios = require("axios");

export class SignUpAnnouncementStepTwo extends Component {
    constructor() {
        super();
        this.state = {
            country: "155",
            isUserForm: true,
            showCharityStuff: true,
            subscription_data: [],
            plan_id: "",
            plan_amount: "",
            split_amount: "",
            // registration fields
            firstName: "",
            firstNamePopup: false,
            lastName: "",
            lastNamePopup: false,
            email: "",
            emailPopup: false,
            password: "",
            passwordPopup: false,
            country: "155",
            companyRegistration: false,
            companyName: "",
            kvkCompany: "",
            kvkCompanyPopup: false,
            street: "",
            streetPopup: false,
            houseNumber: "",
            houseNumberPopup: false,
            city: "",
            cityPopup: false,
            zipCode: "",
            zipCodePopup: false,
            phoneNumber: "",
            phoneNumberPopup: false,
            company_website: "",
            company_websitePopup: false,
            passwordInputType: "password",
            aanmeldenloader: false,
            allUsers: [],
            agreed: false,
            agreedPopup: false,
            currentUserSpecific: "",
            isUserCreated: false,
            currentDataOfUser: "",
            popupopenforright: true,
            mustSelectPlanId: false,
            organizationId: "",
            organizationUserId: "",
            productsCheckingOut: false,
            guestCartTime: "",
            deliveryNote: "",
            shipmentFeeAvailable: false,
            paymentMethodSelected: "",
            showPaymentMethods: false,
            showProductsTextCheckout: false,

            checkout_step_two_new_text_first: "",
            checkout_step_two_new_text_first_mobile: "",
            checkout_step_two_new_text_first_english: "",
            checkout_step_two_new_text_first_english_mobile: "",
            checkout_step_two_new_second_text: "",
            checkout_step_two_new_second_text_mobile: "",
            checkout_step_two_new_second_text_english: "",
            checkout_step_two_new_second_text_english_mobile: "",
            checkout_step_two_new_top_text: "",
            checkout_step_two_new_top_text_mobile: "",
            checkout_step_two_new_top_text_english: "",
            checkout_step_two_new_top_text_english_mobile: "",
            checkout_step_two_new_top_products_text: "",
            checkout_step_two_new_top_products_text_mobile: "",
            checkout_step_two_new_top_products_text_english: "",
            checkout_step_two_new_top_products_text_english_mobile: "",
            checkout_step_two_new_bottom_text: "",
            checkout_step_two_new_bottom_text_mobile: "",
            checkout_step_two_new_bottom_text_english: "",
            checkout_step_two_new_bottom_text_english_mobile: "",
            showFourEuroNew: false,

            showDutch: true,
            companyIdProducts: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        let urlMain = new URLSearchParams(window.location.search)
        if (!urlMain.get("companyId")) {
            history.push("/")
            return false;
        }
        this.setState({ companyIdProducts: urlMain.get("companyId") })

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 500);

        let userid = localStorage.getItem("userid");
        let useridGuest = localStorage.getItem("userid-guest");
        let useridNonMem = localStorage.getItem("userid-nonmem");
        let cookieItem = localStorage.getItem("cookie_item");

        // let movedToCheckout = localStorage.getItem("movedToCheckout")
        // if (movedToCheckout) {
        //     let filteredUser = this.props.user.filter((u) => {
        //         return u.user_id === userid;
        //     });

        //     firebase.database().ref("guest_carts/" + cookieItem).once("value", (data) => {
        //         let a = data.val()
        //         let arr = []
        //         if (a) {
        //             let objected = Object.entries(a)
        //             objected.map((t) => {
        //                 arr.push(t[1])
        //             })
        //             arr.map((g) => {
        //                 if (g.item_type === "bhodi_product") {
        //                     firebase.database().ref(`bhodi_products/${g.itemPush}/inCartOf/${g.pushKeyInCartOf}/cookie`).set(userid)
        //                     g.cookieItem = userid
        //                     firebase.database().ref(`guest_carts/${userid}/${g.pushKey}`).set(g)
        //                     firebase.database().ref(`users/${userid}/cookie_item`).remove()
        //                 }
        //             })
        //         }

        //         firebase.database().ref(`guest_carts/${cookieItem}`).remove()
        //         localStorage.removeItem("cookie_item")
        //         localStorage.removeItem("movedToCheckout")
        //     })
        //     this.setState({ loadSwalStyle: true })
        //     swal({
        //         title: `Hi ${filteredUser[0].type === "company" ? filteredUser[0].company_name : filteredUser[0].first_name}`,
        //         text: "Je betaling is helaas mislukt, maar je account is wel aangemaakt! Je item blijft nog eventjes voor je bewaard in je winkelmand, zodat je de bestelling alsnog kunt afronden.",
        //         className: "swal-darker-overlay",
        //         icon: "warning"
        //     }).then(() => {
        //         if (filteredUser[0].type === "company") {
        //             window.location.href = `/company/${filteredUser[0].user_name_id.toLowerCase()}`
        //         } else {
        //             window.location.href = `/member/${filteredUser[0].user_name_id.toLowerCase()}`
        //         }
        //     })
        // }

        if (window.innerWidth > 1008) {
            this.setState({ popupopenforright: true });
        } else {
            this.setState({ popupopenforright: false });
        }

        if (this.props.user) {
            if (this.props.user.length > 0) {
                this.setState({ allUsers: this.props.user });
            }
        }

        firebase
            .database()
            .ref("users")
            .once("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a) {
                    let objected = Object.entries(a);
                    objected.map((g) => {
                        if (
                            g[0] !== undefined &&
                            g[0] !== "undefined" &&
                            g[0] !== null &&
                            g[0] !== "null"
                        ) {
                            arr.push(g[1]);
                        }
                    });
                    let usersWithNumbers = []
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t)
                        }
                    })
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific:
                            Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                }
            });

        let arr = [];
        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }

        let filtered = [];
        if (this.props.user) {
            filtered = this.props.user.filter((u) => {
                if (useridNonMem) {
                    return u.user_id === useridNonMem;
                } else if (useridGuest) {
                    return u.user_id === useridGuest;
                } else {
                    return u.user_id === userid;
                }
            });
        }
        if (filtered.length > 0) {
            if (filtered[0].first_name) {
                this.setState({ firstName: filtered[0].first_name });
            }
            if (filtered[0].email) {
                this.setState({ email: filtered[0].email });
            }
            if (filtered[0].last_name) {
                this.setState({ lastName: filtered[0].last_name });
            }
            if (filtered[0].password) {
                this.setState({ password: filtered[0].password });
            }
            if (filtered[0].country) {
                let filteredSecond = countries.filter((y) => {
                    return y.name === filtered[0].country;
                });
                if (filteredSecond.length > 0) {
                    this.setState({ country: filteredSecond[0].id });
                }
            }
            if (filtered[0].street) {
                this.setState({ street: filtered[0].street });
            }
            if (filtered[0].city) {
                this.setState({ city: filtered[0].city });
            }
            if (filtered[0].zipCode) {
                this.setState({ zipCode: filtered[0].zipCode });
            }
            if (filtered[0].houseNumber) {
                this.setState({ houseNumber: filtered[0].houseNumber });
            }
            if (filtered[0].company_website) {
                this.setState({ company_website: filtered[0].company_website });
            }
            if (filtered[0].kvkCompany) {
                this.setState({ kvkCompany: filtered[0].kvkCompany });
            }
            if (filtered[0].phoneNumber) {
                this.setState({ phoneNumber: filtered[0].phoneNumber });
            }
            if (filtered[0].company_name) {
                this.setState({
                    companyName: filtered[0].company_name,
                    isUserForm: false,
                });
            }
            if (filtered[0]) {
                if (filtered[0].type === "company") {
                    this.setState({
                        redirectUrl: `${window.location.origin}/company/${filtered[0].user_name_id}?subscribingForCompany=${this.state.companyIdProducts}`,
                    });
                } else {
                    this.setState({
                        redirectUrl: `${window.location.origin}/member/${filtered[0].user_name_id}?subscribingForCompany=${this.state.companyIdProducts}`,
                    });
                }
            }
            this.setState({ isUserCreated: true, currentDataOfUser: filtered[0] });
        } else {
            this.setState({ isUserCreated: false, currentDataOfUser: "" });
        }

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    checkout_step_two_new_text_first: a.checkout_step_two_new_text_first || "",
                    checkout_step_two_new_text_first_mobile: a.checkout_step_two_new_text_first_mobile || "",
                    checkout_step_two_new_text_first_english: a.checkout_step_two_new_text_first_english || "",
                    checkout_step_two_new_text_first_english_mobile: a.checkout_step_two_new_text_first_english_mobile || "",
                    checkout_step_two_new_second_text: a.checkout_step_two_new_second_text || "",
                    checkout_step_two_new_second_text_mobile: a.checkout_step_two_new_second_text_mobile || "",
                    checkout_step_two_new_second_text_english: a.checkout_step_two_new_second_text_english || "",
                    checkout_step_two_new_second_text_english_mobile: a.checkout_step_two_new_second_text_english_mobile || "",
                    checkout_step_two_new_top_text: a.checkout_step_two_new_top_text || "",
                    checkout_step_two_new_top_text_mobile: a.checkout_step_two_new_top_text_mobile || "",
                    checkout_step_two_new_top_text_english: a.checkout_step_two_new_top_text_english || "",
                    checkout_step_two_new_top_text_english_mobile: a.checkout_step_two_new_top_text_english_mobile || "",
                    checkout_step_two_new_top_products_text: a.checkout_step_two_new_top_products_text || "",
                    checkout_step_two_new_top_products_text_mobile: a.checkout_step_two_new_top_products_text_mobile || "",
                    checkout_step_two_new_top_products_text_english: a.checkout_step_two_new_top_products_text_english || "",
                    checkout_step_two_new_top_products_text_english_mobile: a.checkout_step_two_new_top_products_text_english_mobile || "",
                    checkout_step_two_new_bottom_text: a.checkout_step_two_new_bottom_text || "",
                    checkout_step_two_new_bottom_text_mobile: a.checkout_step_two_new_bottom_text_mobile || "",
                    checkout_step_two_new_bottom_text_english: a.checkout_step_two_new_bottom_text_english || "",
                    checkout_step_two_new_bottom_text_english_mobile: a.checkout_step_two_new_bottom_text_english_mobile || "",
                    showFourEuroNew: a.showFourEuroNew || false,
                });
            }
        }


        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.setState({
                        checkout_step_two_new_text_first: a.checkout_step_two_new_text_first || "",
                        checkout_step_two_new_text_first_mobile: a.checkout_step_two_new_text_first_mobile || "",
                        checkout_step_two_new_text_first_english: a.checkout_step_two_new_text_first_english || "",
                        checkout_step_two_new_text_first_english_mobile: a.checkout_step_two_new_text_first_english_mobile || "",
                        checkout_step_two_new_second_text: a.checkout_step_two_new_second_text || "",
                        checkout_step_two_new_second_text_mobile: a.checkout_step_two_new_second_text_mobile || "",
                        checkout_step_two_new_second_text_english: a.checkout_step_two_new_second_text_english || "",
                        checkout_step_two_new_second_text_english_mobile: a.checkout_step_two_new_second_text_english_mobile || "",
                        checkout_step_two_new_top_text: a.checkout_step_two_new_top_text || "",
                        checkout_step_two_new_top_text_mobile: a.checkout_step_two_new_top_text_mobile || "",
                        checkout_step_two_new_top_text_english: a.checkout_step_two_new_top_text_english || "",
                        checkout_step_two_new_top_text_english_mobile: a.checkout_step_two_new_top_text_english_mobile || "",
                        checkout_step_two_new_top_products_text: a.checkout_step_two_new_top_products_text || "",
                        checkout_step_two_new_top_products_text_mobile: a.checkout_step_two_new_top_products_text_mobile || "",
                        checkout_step_two_new_top_products_text_english: a.checkout_step_two_new_top_products_text_english || "",
                        checkout_step_two_new_top_products_text_english_mobile: a.checkout_step_two_new_top_products_text_english_mobile || "",
                        checkout_step_two_new_bottom_text: a.checkout_step_two_new_bottom_text || "",
                        checkout_step_two_new_bottom_text_mobile: a.checkout_step_two_new_bottom_text_mobile || "",
                        checkout_step_two_new_bottom_text_english: a.checkout_step_two_new_bottom_text_english || "",
                        checkout_step_two_new_bottom_text_english_mobile: a.checkout_step_two_new_bottom_text_english_mobile || "",
                        showFourEuroNew: a.showFourEuroNew || false,
                    });
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user && !this.props.signup_fields_info) {
            if (this.state.email) {
                let filtered = this.props.user.filter((u) => {
                    return u.email === this.state.email;
                });
                if (filtered.length > 0) {
                    this.setState({ currentDataOfUser: filtered[0] });
                } else {
                    this.setState({ currentDataOfUser: "" });
                }
            }
        }
    }

    getDayName(num) {
        if (num === 0) {
            return `Zondag`;
        } else if (num === 1) {
            return `Maandag`;
        } else if (num === 2) {
            return `Dinsdag`;
        } else if (num === 3) {
            return `Woensdag`;
        } else if (num === 4) {
            return `Donderdag`;
        } else if (num === 5) {
            return `Vrijdag`;
        } else if (num === 6) {
            return `Zaterdag`;
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }

    showDateToUserTiming(e, f) {
        if (e === f) {
            let splitted = e.split("-");
            let newDate = new Date(e).getDay();
            return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
                splitted[1]
            )}`;
        } else {
            let splitted = e.split("-");
            let splittedSecond = f.split("-");
            let newDate = new Date(e).getDay();
            let newDateAnother = new Date(f).getDay();
            if (splitted[1] === splittedSecond[1]) {
                return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
                    } ${this.getMonthName(splittedSecond[1])}`;
            } else {
                // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
                return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
                    } ${this.getMonthName(splittedSecond[1])}`;
            }
        }
    }

    randomStr(e) {
        let len = e;
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489";
        var ans = "";
        for (var i = len; i > 0; i--) {
            ans += arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    getCharityObjectedQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop]);
                }
                return total;
            };
            return arrItems.sum("used_time");
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    saveSubscription() {
        let userid = localStorage.getItem("userid")

        if (!this.state.plan_id) {
            this.setState({ mustSelectPlanId: true })

            setTimeout(() => {
                this.setState({ mustSelectPlanId: false })
            }, 2000);
            return;
        }

        this.setState({ aanmeldenloader: true, mustSelectPlanId: false });
        let uid = localStorage.getItem("userid");
        let uidGuest = localStorage.getItem("userid-guest");
        let uidNonMem = localStorage.getItem("userid-nonmem");

        let plan_amount = this.state.plan_amount;
        let plan_id = this.state.plan_id;

        let redirect_url = "";
        if (this.state.currentDataOfUser.type === "company") {
            redirect_url = `${window.location.origin
                }/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}?subscribingForCompany=${this.state.companyIdProducts}`;
        } else {
            redirect_url = `${window.location.origin
                }/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}?subscribingForCompany=${this.state.companyIdProducts}`;
        }

        let promiseCustomer = new Promise((res, rej) => {
            if (this.state.currentDataOfUser.stripe_id) {
                res(this.state.currentDataOfUser.stripe_id)
            } else {
                axios
                    .post(
                        "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCustomer",
                        {
                            fullName: this.state.companyName ? this.state.companyName : this.state.firstName + "" + this.state.lastName,
                            email: this.state.email,
                        }
                    )
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            res(response.data.data.id)
                        }
                    })
            }
        })
        promiseCustomer.then((stripeId) => {
            let paymentObject = {
                priceId: this.state.stripe_plan_key,
                customerId: stripeId,
                successUrl: redirect_url,
                cancelUrl: `${window.location.origin}/signup-announcement/stepTwo?companyId=${this.state.companyIdProducts}`,
            }

            if (this.state.organizationUserId) {
                paymentObject.routeAmount = this.state.split_amount
                paymentObject.orgId = this.state.organizationUserId
                paymentObject.uid = uid
                paymentObject.description = `${this.state.firstName + "" + this.state.lastName}'s recurring payment`
                let pushKeyOfData = firebase.database().ref(`payouts_info/${this.state.organizationUserId}/${stripeId}`).push().key
                paymentObject.pushKeyOfData = pushKeyOfData
            }

            axios
                .post(
                    "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCheckout",
                    paymentObject,
                )
                .then((responseSec) => {
                    if (uidGuest) {
                        localStorage.setItem("userid", uidGuest)
                        localStorage.removeItem("userid-guest")
                    }
                    if (uidNonMem) {
                        localStorage.setItem("userid", uidNonMem)
                        localStorage.removeItem("userid-nonmem")
                    }
                    const updates = {
                        [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/plan_id`]: plan_id,
                        [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/plan_amount`]: plan_amount,
                        [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/subscribing_for_company`]: true,
                        [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/payment_id`]: responseSec.data.data.id,
                    };

                    firebase.database().ref().update(updates)
                        .then(() => {
                            this.setState({ aanmeldenloader: false });
                            document.querySelector(".paypal-pay-btn").style.display = "inline-flex"

                            window.location.href = responseSec.data.data.url
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        })

        // })
    }

    getSellerName(e) {
        if (e) {
            let filteredUser = this.props.user.filter((g) => {
                if (e.mainParentCreator) {
                    return g.user_id === e.mainParentCreator;
                } else {
                    return g.user_id === e.created_by;
                }
            });
            if (filteredUser.length > 0) {
                if (filteredUser[0].type === "company") {
                    if (filteredUser[0].company_name) {
                        return filteredUser[0].company_name;
                    } else {
                        return filteredUser[0].first_name + " " + filteredUser[0].last_name;
                    }
                } else {
                    return filteredUser[0].first_name + " " + filteredUser[0].last_name;
                }
            }
        }
    }

    getDiscountedValue(e, discount) {
        if (discount !== "Giveaway") {
            let splitted = discount.split("%");
            var numVal1 = Number(e);
            var numVal2 = Number(splitted[0]) / 100;
            var totalValue = numVal1 - numVal1 * numVal2;
            return totalValue;
        }
    }

    createMarkup(e) {
        if (e) {
            return { __html: e };
        }
    }

    render() {
        return (
            <div className="global-event-cart globaleventcartfixedstickyfooter">
                {this.state.loadSwalStyle && <link rel="stylesheet" type="text/css" href="/stepTwoSwalStyle.css"></link>}
                <div className="left-gec charitylgec">
                    {window.innerWidth > 1008 && (<div className="button-back-lgec" onClick={() => {
                        let moveBackTwo = localStorage.getItem("moveback-two")
                        if (moveBackTwo) {
                            localStorage.removeItem("moveback-two")
                            history.go(-2)
                        } else {
                            history.goBack()
                        }
                    }}>
                        <ChevronLeftIcon />
                    </div>)}
                    <div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_new_text_first : this.state.checkout_step_two_new_text_first_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_new_text_first_english : this.state.checkout_step_two_new_text_first_english_mobile))}>
                                {/* Aanmelden */}
                            </label>
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_new_second_text : this.state.checkout_step_two_new_second_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_new_second_text_english : this.state.checkout_step_two_new_second_text_english_mobile))}>
                                {/* Account gegevens */}
                            </label>
                            {this.state.showProductsTextCheckout ? (<label style={{ maxWidth: 470 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_new_top_products_text : this.state.checkout_step_two_new_top_products_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_new_top_products_text_english : this.state.checkout_step_two_new_top_products_text_english_mobile))}>
                                {/* Word charity member en krijg toegang tot<br />
                                exclusieve acties en gratis fashion & lifestyle items.
                                <br />
                                <br />
                                Als Charity Member ondersteun je kinderen en scholen in Tanzania<br />
                                met maaltijden en materialen. Van deze uitgifte ontvang je een<br />
                                persoonlijke en op naam ingesproken bedankvideo! */}
                            </label>) : (<label style={{ maxWidth: 470 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_new_top_text : this.state.checkout_step_two_new_top_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_new_top_text_english : this.state.checkout_step_two_new_top_text_english_mobile))}>
                                {/* Als Charity Member ondersteun je kinderen en scholen in Tanzania
                                met maaltijden en materialen. Van deze uitgifte ontvang je een
                                persoonlijke en op naam ingesproken bedankvideo en krijg je
                                toegang tot alle exclusieve acties en events. */}
                            </label>)}
                        </div>
                        {!this.state.productsCheckingOut && (<div className="selecteer-tooltipdes" style={{ position: "relative" }}>
                            <p
                                className="text-clgec-charity texboldclged"
                                style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: "rgb(33, 37, 41)",
                                }}
                            >
                                Selecteer je bijdrage
                            </p>
                            <span
                                className="tooltiptext tooltipinfo"
                                style={{ display: this.state.mustSelectPlanId ? "inline-flex" : "none" }}
                            >
                                Selecteer
                            </span>
                        </div>)}
                        {!this.state.productsCheckingOut && (<div className="radios-charity-lfss" style={{ marginTop: 20 }}>
                            {this.state.subscription_data.length > 0 &&
                                [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                    if (!this.state.showFourEuroNew && this.state.showProductsTextCheckout && e.price === "4.00") {
                                        return false;
                                    }
                                    return (
                                        <div className="inner-rclfss">
                                            <input
                                                type="radio"
                                                id="radiocharity"
                                                name="charity"
                                                value={e.plan_id}
                                                checked={e.plan_id === this.state.plan_id}
                                                onChange={() =>
                                                    this.setState({
                                                        plan_id: e.plan_id,
                                                        stripe_plan_key: e.stripe_plan_key,
                                                        plan_amount: e.price,
                                                        split_amount: e.split_amount,
                                                    })
                                                }
                                            />
                                            <span>€ {Number(e.price)}</span>
                                        </div>
                                    );
                                })}
                        </div>)}
                        <>
                            {window.innerWidth < 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (this.state.checkout_step_two_new_bottom_text_mobile) : (this.state.checkout_step_two_new_bottom_text_english_mobile))}>
                                {/* Jouw maandelijkse bijdrage aan Bhodi kun je heel eenvoudig&nbsp;
                                <br />
                                en op ieder gewenst moment zelf aanpassen of direct stopzetten. */}
                            </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 350 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (this.state.checkout_step_two_new_bottom_text) : (this.state.checkout_step_two_new_bottom_text_english))}>
                                {/* Jouw maandelijkse bijdrage aan Bhodi kun je heel eenvoudig
                                en op ieder gewenst moment zelf aanpassen of direct stopzetten. */}
                            </p>)}
                            {/* {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                Alle aangesloten winkeliers en aanbieders stellen hun exclusieve&nbsp;
                                <br />
                                pop-up events en acties speciaal beschikbaar voor Charity Members.
                            </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 350 }}>
                                De exclusieve pop-up events en acties zijn alleen beschikbaar voor Charity Members.
                            </p>)}
                            {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: window.innerWidth > 1008 ? 550 : "90%" }}>
                                Als Charity Member ondersteun je kinderen en scholen in Tanzania&nbsp;
                                <br />
                                met maaltijden en materialen. Van deze uitgifte ontvang je een&nbsp;
                                <br />
                                persoonlijke en op naam ingesproken bedankvideo.
                            </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 335 }}>
                                Als Charity Member ondersteun je kinderen en scholen in Tanzania met maaltijden en materialen. Van deze uitgifte ontvang je een persoonlijke bedankvideo van ons.
                            </p>)}
                            {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                Jouw maandelijkse bijdrage aan Bhodi kun je heel eenvoudig&nbsp;
                                <br />
                                en op ieder gewenst moment zelf aanpassen of stopzetten.
                            </p>) : <p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                Jouw maandelijkse bijdrage kun je op ieder gewenst moment aanpassen of stopzetten.
                            </p>} */}
                        </>
                        <div className="bottom-buttons-clgec">
                            {this.state.aanmeldenloader ? (
                                <></>
                            ) : (<>
                                <button onClick={this.saveSubscription.bind(this)} className="paypal-pay-btn">
                                    Afronden
                                    {window.innerWidth < 1008 && <ChevronRightIcon />}
                                </button>
                                <div id="ideal-pay-btn" style={{ display: "none" }}>

                                </div>
                            </>)}
                            {this.state.aanmeldenloader && (
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            )}
                            <div className="steps-container-main">
                                <div className="scm-inner-steps">

                                </div>
                                <div className="scm-inner-steps active">

                                </div>
                                <label>2/2</label>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.popupopenforright && (
                    <div className="right-gec righgecwithimgsignup">
                    </div>
                )}
                {/* <FooterBasket notOnMainPage={true} />
                <Footer notOnMainPage={true} /> */}
                <FooterBasket notOnMainPage={true} />
                <FooterBasketTimer notOnMainPage={true} />
                {/* {this.state.showFooterBasket ? (<FooterBasket notOnMainPage={true} />) : (<CartNonUser notOnMainPage={true} />)} */}
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subscription_plans: state.globalReducers.subscription_plans,
        user: state.authReducers.user,
        user: state.authReducers.user,
        global_campaigns: state.campaignsReducer.global_campaigns,
        signup_fields_info: state.globalReducers.signup_fields_info,
        admin_boolean: state.globalReducers.admin_boolean,
        // products_new: state.campaignsReducer.products_new,
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSignUpFieldsFilled: (signup_fields_info) =>
            dispatch(updateSignUpFieldsFilled(signup_fields_info)),
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpAnnouncementStepTwo);


