import React, { Component } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux'
import { updateUser } from '../Redux/actions/authActions'
import firebase from '../Config/Firebase'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { FiInfo } from "react-icons/fi"
import AdminNavbar from './AdminNavbar';
import { CSVLink } from "react-csv"
const axios = require('axios');

export class AllUsersToEdit extends Component {

  constructor() {
    super()
    this.state = {
      allUsers: [],
      allUsersFiltered: [],
      filterText: "",
      currentUserMember: "",
      filteredUsersSpecial: [],
      allUsersDuplicate: [],
      openDetailedPopup: false,
      showClientsOf: "",
      preparingCsv: true,
      allCsvs: [],
      typeOfPage: "",
    }
  }

  componentDidMount() {
    let type = new URL(window.location.href).searchParams.get("type")
    if (type) {
      if (this.props.user) {
        let filtered = []
        if (type === "member") {
          filtered = this.props.user.filter((t) => {
            return t.type === "user" && (!t.subscription || (t.subscription && t.subscription.status !== "active"))
          })
        } else if (type === "member-charity") {
          filtered = this.props.user.filter((t) => {
            return t.type === "user" && t.subscription && t.subscription.status === "active"
          })
        } else if (type === "company") {
          filtered = this.props.user.filter((t) => {
            return t.type === "company" && (!t.subscription || (t.subscription && t.subscription.status !== "active"))
          })
        } else if (type === "company-charity") {
          filtered = this.props.user.filter((t) => {
            return t.type === "company" && t.subscription && t.subscription.status === "active"
          })
        }
        this.setState({ allUsers: filtered, allUsersDuplicate: filtered, typeOfPage: type })
      }
    } else {
      this.setState({ allUsers: this.props.user, allUsersDuplicate: this.props.user })
    }

    firebase.database().ref("uploaded_clients").once("value", (data) => {
      let a = data.val()
      let arr = []
      if (a) {
        let objected = Object.entries(a)
        objected.map((t) => {
          console.log(t, '/check')
          if (t[1] && t.length > 0) {
            t[1].map((g) => {
              g.mainCreator = t[0];
              arr.push(g)
            })
          }
        })
        this.setState({ allCsvs: arr })
      }
    })

    firebase.database().ref("users").on("value", (data) => {
      let a = data.val()
      let arr = []
      if (a) {
        let objected = Object.entries(a)
        objected.map((j) => {
          arr.push(j[1])
          if (this.state.showClientsOf) {
            if (this.state.showClientsOf.user_id === j[1].user_id) {
              this.setState({ showClientsOf: j[1] })
            }
          }
        })
      }
      if (this.state.filteredUsersSpecial.length > 0) {
        let filtered = this.state.allUsers.filter((e) => {
          return e.specialAccountDonator
        })
        this.setState({ filteredUsersSpecial: filtered })
      }
      if (type) {
        let filtered = []
        if (type === "member") {
          filtered = arr.filter((t) => {
            return t.type === "user" && (!t.subscription || (t.subscription && t.subscription.status !== "active"))
          })
        } else if (type === "member-charity") {
          filtered = arr.filter((t) => {
            return t.type === "user" && t.subscription && t.subscription.status === "active"
          })
        } else if (type === "company") {
          filtered = arr.filter((t) => {
            return t.type === "company" && (!t.subscription || (t.subscription && t.subscription.status !== "active"))
          })
        } else if (type === "company-charity") {
          filtered = arr.filter((t) => {
            return t.type === "company" && t.subscription && t.subscription.status === "active"
          })
        }
        this.setState({ allUsers: filtered, allUsersDuplicate: filtered })
      } else {
        this.setState({ allUsers: arr, allUsersDuplicate: arr })
      }
    })
  }

  async deleteUser(userid) {
    this.setState({ deleteBtnText: "SAVING.." })

    let allUsers = this.props.user

    allUsers.map((u) => {
      if (u.friends) {
        let objected = Object.entries(u.friends)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/friends/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.following) {
        let objected = Object.entries(u.following)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/following/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.followed_by) {
        let objected = Object.entries(u.followed_by)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/followed_by/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.comingrequests) {
        let objected = Object.entries(u.comingrequests)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/comingrequests/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.usersToHide) {
        let objected = Object.entries(u.usersToHide)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/usersToHide/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.sentrequests) {
        let objected = Object.entries(u.sentrequests)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/sentrequests/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.rejectlist) {
        let objected = Object.entries(u.rejectlist)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/rejectlist/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.inRejectList) {
        let objected = Object.entries(u.inRejectList)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/inRejectList/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.blocked) {
        let objected = Object.entries(u.blocked)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          firebase.database().ref("users/" + u.user_id + "/blocked/" + filtered[0][1].pushKey).remove()
        }
      }
      if (u.newFollowersList) {
        let objected = Object.entries(u.newFollowersList)
        let filtered = objected.filter((b) => {
          return b[1].user_id === userid
        })
        if (filtered.length > 0) {
          let newFollowers = u.newFollowers - 1
          firebase.database().ref("users/" + u.user_id + "/newFollowersList/" + filtered[0][1].pushKey).remove()
          firebase.database().ref("users/" + u.user_id + "/newFollowers").set(newFollowers)
        }
      }
    })
    firebase.database().ref("all_albums").orderByChild("owner").equalTo(userid).on("child_added", (data) => {
      firebase.database().ref("all_albums/" + data.val().album_id).remove()
    })
    firebase.database().ref("allImages").orderByChild("created_by").equalTo(userid).on("child_added", (data) => {
      firebase.database().ref("allImages/" + data.val().push).remove()
    })
    await firebase.database().ref("bhodi_campaigns/" + userid).remove()
    await firebase.database().ref("impressiePhotos/" + userid).remove()
    await firebase.database().ref("allMessages/" + userid).remove()
    await firebase.database().ref("guest_carts/" + userid).remove()

    // let deleteUser = firebase.functions().httpsCallable('deleteFirebaseAuthUser');
    // deleteUser({ userId: userid }).then((response) => {
    //     firebase.database().ref("users/" + userid).remove().then(() => {
    //         window.location.reload()
    //     })
    // }).catch((err) => {
    //     console.log(err, '====> this', err)
    // })

    axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/deleteFirebaseAuthUser", {
      userId: userid,
    })
      .then(() => {
        firebase.database().ref("users/" + userid).remove().then(() => {
          window.location.reload()
        })
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/deleteFirebaseAuthUser", {
    //     userId: userid,
    // })
    //     .then(() => {
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });

  }

  // componentDidUpdate(prevProps) {
  //     if (prevProps.user !== this.props.user) {
  //         this.setState({ allUsers: this.props.user })
  //     }
  // }

  takeMainCompanyName(uniqueCode) {
    let allCompaniesDonator = this.props.user.filter((g) => {
      return g.specialAccountDonator
    })
    if (allCompaniesDonator.length > 0) {
      let checkUniqueCode = allCompaniesDonator.filter((e) => {
        let thereAmount = Number(e.flyerStart)
        let arrCodes = []
        for (let i = thereAmount + 1; i <= Number(e.flyerEnd); i++) {
          arrCodes.push(i)
        }
        if (arrCodes.includes(Number(uniqueCode))) {
          return e
        }
      })
      if (checkUniqueCode.length > 0) {
        return `(${checkUniqueCode[0].company_name})`
      }
    }
  }

  getClientsOfCompany(userMainCompany) {
    let thereAmount = Number(userMainCompany.flyerStart)
    let arrCodes = []
    for (let i = thereAmount + 1; i <= Number(userMainCompany.flyerEnd); i++) {
      arrCodes.push(i)
    }
    let filtered = this.props.user.filter((g) => {
      if (g.uniqueCode) {
        return arrCodes.includes(Number(g.uniqueCode))
      }
    })
    if (filtered.length > 0) {
      return <>
        <div className="row-tcsma">
          <p className="profile-rtcs">Profile Image: </p>
          <p className="name-rtcs">Information</p>
          <p className="email-rtcs">Email</p>
          <p className="type-rtcs">Type</p>
          <p className="subscription-rtcs">Subscription</p>
          <p className="friends-rtcs">Friends</p>
          <p className="followers-rtcs">Followers</p>
        </div>
        {filtered.map((t) => {
          return <div className="row-tcsma">
            <p className="profile-rtcs">{t.profile_image ? <img src={t.profile_image} /> : <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />}</p>
            <p className="name-rtcs">{t.company_name}</p>
            <p className="email-rtcs">{t.email}</p>
            <p className="type-rtcs">{t.type === "user" ? "Member" : "Company"}</p>
            <p className="subscription-rtcs">{t.subscription ? t.subscription.status : "No"}</p>
            <p className="friends-rtcs">{t.friends ? Object.entries(t.friends).length : "0"}</p>
            <p className="followers-rtcs">{t.followed_by ? Object.entries(t.followed_by).length : "0"}</p>
          </div>
        })}
      </>
    } else {
      return <label style={{ fontSize: 13, letterSpacing: 1, marginLeft: 30 }}>No account created</label>
    }
    // if (arrCodes.includes(Number(uniqueCode))) {
    //     return e
    // }
  }

  checkForExportButton() {
    let filtered = this.state.allCsvs.filter((g) => {
      return g.mainCreator === this.state.showClientsOf.user_id
    })
    let csvReport = {
      data: filtered,
      headers: [
        { label: "Bedrijsfnaam", key: "companyName" },
        { label: "Adres", key: "address" },
        { label: "Woonplats", key: "city" },
        { label: "PostCode", key: "postCode" },
        { label: "Unique Code", key: "uniqueCode" },
      ],
      filename: `${this.state.showClientsOf.user_name_id}.csv`
    }
    if (filtered.length > 0) {
      return <button><CSVLink {...csvReport}>Export CSV</CSVLink></button>
    }
    // firebase.database().ref("uploaded_clients/" + this.state.showClientsOf.user_id).once("value", (data) => {
    //   let arr = data.val()
    //   console.log(arr)
    //   if (arr) {
    //     return <button>Export CSV</button>
    //   } else {
    //     return <button>No CSV uploaded</button>
    //   }
    // })
  }

  getCompanyName(userid) {
    let filtered = this.props.user.filter((user) => {
      return user.user_id === userid
    })
    if (filtered.length > 0) {
      return filtered[0].company_name
    }
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar usersPage={true} />
        <div className="container-sma" style={{ maxWidth: this.state.typeOfPage === "member-charity" ? 1720 : 1280 }}>
          <div className="top-container-sma">
            <div className="sector-tcsma">
              <div className="category-tcsma">
                {/* <p><EditIcon /> USERS </p> */}
                <p style={{ cursor: "pointer" }} onClick={() => {
                  this.setState({ filteredUsersSpecial: [] })
                }}>USERS </p>
                {/* <p style={{ cursor: "pointer", marginLeft: 15 }} onClick={() => {
                  let filtered = this.state.allUsers.filter((e) => {
                    return e.specialAccountDonator
                  })
                  filtered.map((g) => {
                    g.dropdownOpened = false
                  })
                  this.setState({ filteredUsersSpecial: filtered })
                }}>SPECIAL USERS</p> */}
              </div>
            </div>
            {this.state.filteredUsersSpecial.length === 0 && (
              <div className="category-tcsma" style={{ display: "flex", alignItems: "center" }}>
                {this.state.specialUsersOpen ? <label style={{ fontSize: 13, letterSpacing: 1, cursor: "pointer", marginBottom: 0, marginRight: 10 }} onClick={() => {
                  this.setState({ allUsers: this.state.allUsersDuplicate, specialUsersOpen: false })
                }}>Close</label> : <label style={{ fontSize: 13, letterSpacing: 1, cursor: "pointer", marginBottom: 0, marginRight: 10 }} onClick={() => {
                  let filtered = this.state.allUsersDuplicate.filter((e) => {
                    return e.specialAccountDonator
                  })
                  filtered.map((g) => {
                    g.dropdownOpened = false
                  })
                  this.setState({ allUsers: filtered, specialUsersOpen: true })
                }}>Filter</label>}
                <input className="inputctcsma" placeholder="Search users..." value={this.state.filterText} onChange={(e) => {
                  this.setState({ filterText: e.target.value })
                  if (e.target.value !== "") {
                    if (this.props.user.length > 0) {
                      let a = this.props.user
                      let filtered = a.filter((f) => {
                        if (f.company_name) {
                          if (f.email) {
                            return f.company_name.toLowerCase().includes(e.target.value.toLowerCase()) || f.email.toLowerCase().includes(e.target.value.toLowerCase())
                          }
                        } else {
                          if (f.first_name) {
                            if (f.email) {
                              return f.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || f.email.toLowerCase().includes(e.target.value.toLowerCase())
                            }
                          }
                        }
                      })
                      this.setState({ allUsersFiltered: filtered })
                    }
                  } else {
                    this.setState({ allUsersFiltered: [] })
                  }
                }} />
              </div>
            )}
          </div>
          <div className="table-container-sma">
            {this.state.filteredUsersSpecial.length > 0 ? (<div className="row-tcsma">
              <p className="profile-rtcs">Profile Image: </p>
              <p className="name-rtcs">Information</p>
              <p className="email-rtcs">Email (Flyer Count)</p>
              <p className="email-rtcs">Actions</p>
            </div>) : (<div className="row-tcsma">
              <p className="profile-rtcs">Profile Image: </p>
              <p className="name-rtcs">Information</p>
              <p className="email-rtcs">Email</p>
              {this.state.typeOfPage === "member-charity" && (<p className="date-rtcs">Date</p>)}
              {this.state.typeOfPage === "member-charity" && (<p className="spid-rtcs">Specific ID</p>)}
              {this.state.typeOfPage === "member-charity" && (<p className="referred-rtcs">Joined Via</p>)}
              <p className="type-rtcs">Type</p>
              <p className="subscription-rtcs">Subscription</p>
              <p className="friends-rtcs">Friends</p>
              <p className="followers-rtcs">Followers</p>
              <p className="actions-rtcs">Actions</p>
            </div>)}
            {this.state.filteredUsersSpecial.length > 0 ? (this.state.filteredUsersSpecial.map((y) => {
              return <>
                <div className="row-tcsma" style={{ position: "relative" }}>
                  <p className="profile-rtcs">{y.profile_image ? <img src={y.profile_image} /> : <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />}</p>
                  <p className="name-rtcs">{y.type === "user" ? `${y.first_name} ${y.last_name}` : y.company_name}</p>
                  <p className="email-rtcs" style={{ maxWidth: 480 }}>{y.email} ({y.flyerStart}-{y.flyerEnd})</p>
                  {y.clients_confirmed ? <p className="email-rtcs" >
                    Status: <select value={y.flyers_status} onChange={(g) => {
                      firebase.database().ref("users/" + y.user_id + "/flyers_status").set(g.target.value)
                      if (g.target.value === "delivered") {
                        firebase.database().ref("users/" + y.user_id + "/clients_confirmed").remove()
                        firebase.database().ref("users/" + y.user_id + "/flyers_status").remove()
                      }
                    }}>
                      <option value="pending">Pending</option>
                      <option value="sent">Sent</option>
                      <option value="delivered">Delivered</option>
                    </select>
                  </p> : <p className="email-rtcs" >
                    Status: <font>Not Confimed</font>
                  </p>}
                  {y.dropdownOpened ? <BsChevronUp style={{ marginLeft: "auto", position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)" }} onClick={() => {
                    y.dropdownOpened = false
                    this.setState({ filteredUsersSpecial: this.state.filteredUsersSpecial })
                  }} /> : <BsChevronDown style={{ marginLeft: "auto", position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)" }} onClick={() => {
                    y.dropdownOpened = true
                    this.setState({ filteredUsersSpecial: this.state.filteredUsersSpecial })
                  }} />}
                </div>
                {y.dropdownOpened && this.getClientsOfCompany(y)}
              </>
            })) : this.state.allUsersFiltered.length > 0 ? (this.state.allUsersFiltered.map((y) => {
              return <div className="row-tcsma">
                <p className="profile-rtcs">{y.profile_image ? <img src={y.profile_image} /> : <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />}</p>
                <p className="name-rtcs">{y.type === "user" ? `${y.first_name} ${y.last_name}` : y.company_name}</p>
                <p className="email-rtcs">{y.email} {y.uniqueCode && this.takeMainCompanyName(y.uniqueCode)}</p>
                {this.state.typeOfPage === "member-charity" && (<p className="date-rtcs">{y.created_on}</p>)}
                {this.state.typeOfPage === "member-charity" && (<p className="spid-rtcs">{y.user_name_id}</p>)}
                {this.state.typeOfPage === "member-charity" && (<p className="referred-rtcs">{y.company_referred ? this.getCompanyName(y.company_referred) : "Checkout"}</p>)}
                <p className="type-rtcs">{y.type === "user" ? "Member" : "Company"}</p>
                <p className="subscription-rtcs">{y.subscription ? y.subscription.status : "No"}</p>
                <p className="friends-rtcs">{y.friends ? Object.entries(y.friends).length : "0"}</p>
                <p className="followers-rtcs">{y.followed_by ? Object.entries(y.followed_by).length : "0"}</p>
                <p className="actions-rtcs" onClick={() => {
                  if (y.subscription) {
                    if (y.subscription.status !== "active") {
                      this.deleteUser(y.user_id)
                    } else {
                      this.setState({ currentUserMember: y, cancelSubscriptionPopup: true })
                    }
                  } else {
                    this.deleteUser(y.user_id)
                  }
                }}>
                  <DeleteIcon />
                </p>
              </div>
            })) : this.state.allUsers.length > 0 && (this.state.allUsers.map((y) => {
              return <div className="row-tcsma">
                <p className="profile-rtcs">{y.profile_image ? <img src={y.profile_image} /> : <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />}</p>
                <p className="name-rtcs">{y.type === "user" ? `${y.first_name} ${y.last_name}` : y.company_name}</p>
                <p className="email-rtcs">{y.email} {y.uniqueCode && this.takeMainCompanyName(y.uniqueCode)} {y.specialAccountDonator && <FiInfo style={{ marginLeft: 15, cursor: "pointer" }} onClick={() => {
                  this.setState({ openDetailedPopup: true, showClientsOf: y })
                }} />}</p>
                {this.state.typeOfPage === "member-charity" && (<p className="date-rtcs">{y.created_on}</p>)}
                {this.state.typeOfPage === "member-charity" && (<p className="spid-rtcs">{y.user_name_id}</p>)}
                {this.state.typeOfPage === "member-charity" && (<p className="referred-rtcs">{y.company_referred ? this.getCompanyName(y.company_referred) : "Checkout"}</p>)}
                <p className="type-rtcs">{y.type === "user" ? "Member" : "Company"}</p>
                <p className="subscription-rtcs">{y.subscription ? y.subscription.status : "No"}</p>
                <p className="friends-rtcs">{y.friends ? Object.entries(y.friends).length : "0"}</p>
                <p className="followers-rtcs">{y.followed_by ? Object.entries(y.followed_by).length : "0"}</p>
                <p className="actions-rtcs" onClick={() => {
                  if (y.subscription) {
                    if (y.subscription.status !== "active") {
                      this.deleteUser(y.user_id)
                    } else {
                      this.setState({ currentUserMember: y, cancelSubscriptionPopup: true })
                    }
                  } else {
                    this.deleteUser(y.user_id)
                  }
                }}>
                  <DeleteIcon />
                </p>
              </div>
            }))}
          </div>
          {this.state.openDetailedPopup && (<div className='detailed-page-users'>
            <CloseIcon className='close-icon-dpus' onClick={() => {
              this.setState({ openDetailedPopup: false, showClientsOf: "" })
            }} />
            <div className='container-dpusers'>
              <div className='export-csv-button'>
                {this.checkForExportButton()}
              </div>
              {this.state.showClientsOf.clients_confirmed ? <p className="email-rtcs" style={{ fontSize: 13, letterSpacing: 1, marginLeft: 30 }}>
                Status: <select value={this.state.showClientsOf.flyers_status} onChange={(g) => {
                  firebase.database().ref("users/" + this.state.showClientsOf.user_id + "/flyers_status").set(g.target.value)
                  if (g.target.value === "delivered") {
                    firebase.database().ref("users/" + this.state.showClientsOf.user_id + "/clients_confirmed").remove()
                    firebase.database().ref("users/" + this.state.showClientsOf.user_id + "/flyers_status").remove()
                  }
                }}>
                  <option value="pending">Pending</option>
                  <option value="sent">Sent</option>
                  <option value="delivered">Delivered</option>
                </select>
              </p> : <p className="email-rtcs" style={{ fontSize: 13, letterSpacing: 1, marginLeft: 30 }}>
                Status: <font>Not Confimed</font>
              </p>}
              {this.state.showClientsOf && this.getClientsOfCompany(this.state.showClientsOf)}
            </div>
          </div>)}
          <Dialog
            open={this.state.cancelSubscriptionPopup}
            onClose={() => this.setState({ cancelSubscriptionPopup: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd"
          >
            <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">To delete your account, you first must stop your charity plan.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({ cancelSubscriptionPopup: false })
                axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription", {
                  subscriptionId: this.state.currentUserMember.subscription_id,
                  customerId: this.state.currentUserMember.stripe_id,
                })
                  .then(() => {
                    axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/deleteStripeCustomer", {
                      customerId: this.state.currentUserMember.stripe_id,
                    })
                      .then(() => {
                        this.deleteUser(this.state.currentUserMember.user_id)
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }} color="primary">Click here to stop plan</Button>
              <Button onClick={() => this.setState({ cancelSubscriptionPopup: false })} color="primary" className="close-modal-btn-edcd"><CloseIcon /></Button>
            </DialogActions>
          </Dialog>
        </div>
      </div >
    )
  }
}



const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AllUsersToEdit)
