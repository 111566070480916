import React, { Component } from 'react'
import PublishIcon from "@material-ui/icons/Publish";
import firebase from "../../Config/Firebase"
import "./index.css"
import axios from "axios";
import { connect } from 'react-redux';
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
    updateCategoriesVouchers,
} from "../../Redux/actions/authActions";
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { history } from '../../Config/Routes';
import { MdClose, MdTimer } from 'react-icons/md';
import { CheckCircle, ExpandMore } from '@material-ui/icons';
import { AiOutlineSelect } from 'react-icons/ai';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { BiCalendar } from "react-icons/bi";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import swal from 'sweetalert';
import logoSubAdmin from "../../assets/subadminlogo.png";
import voucherFormImage from "../../assets/charityaccountmakerimage.jpeg"
import restaurantNewPic from "../../assets/restindustrypic.jpeg"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const bcrypt = require("bcryptjs");
const saltRounds = 10;

let categoriesVouchersRef = firebase.database().ref("bhodi_categories_vouchers")

export class CompanySubscribeViaLink extends Component {
    constructor() {
        super()
        this.state = {
            aanmeldenInfo: false,
            secondStep: false,
            aanmeldenloader: false,
            dropFileCampaignImage: [],
            campaignImagePreview: [
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8",
            ],
            companyName: "",
            phoneNum: "",
            street: "",
            email: "",
            houseNum: "",
            password: "",
            postCode: "",
            bankAccountNumber: "",
            city: "",
            bankName: "",
            allUsers: [],
            kortingsVoucherTitle: "",
            kortingsVoucherNormalPrice: "",
            kortingsVoucherSalePrice: "",
            kortingsVoucherAmount: "",
            kortingsVoucherDesc: "",
            kortingsVoucherNumber: "",
            currentUserSpecific: "",
            showMainLoader: false,
            fromDirectUrl: false,
            companyNameDefault: "",
            thirdStep: false,
            companyUrlRedirect: "",
            companyUrlRedirectGuest: "",
            defaultEmailParam: "",
            defaultKeyParam: "",
            fourthStep: false,

            allListings: [],
            indexOfCurrentCompany: -1,
            currentIndustry: "restaurant",
            currentIndustryMain: "restaurant",

            campaignImagePreviewType: "uploadfromcomp",
            currentFormType: "voucher",
            currentFormTypeDefault: "voucher",

            allImagesCurrentSectorStock: [],
            allSectors: [],
            mainSectorStock: "",

            allImagesStock: [],

            allTimes: [
                { label: "01:00" },
                { label: "01:15" },
                { label: "01:30" },
                { label: "01:45" },
                { label: "02:00" },
                { label: "02:15" },
                { label: "02:30" },
                { label: "02:45" },
                { label: "03:00" },
                { label: "03:15" },
                { label: "03:30" },
                { label: "03:45" },
                { label: "04:00" },
                { label: "04:15" },
                { label: "04:30" },
                { label: "04:45" },
                { label: "05:00" },
                { label: "05:15" },
                { label: "05:30" },
                { label: "05:45" },
                { label: "06:00" },
                { label: "06:15" },
                { label: "06:30" },
                { label: "06:45" },
                { label: "07:00" },
                { label: "07:15" },
                { label: "07:30" },
                { label: "07:45" },
                { label: "08:00" },
                { label: "08:15" },
                { label: "08:30" },
                { label: "08:45" },
                { label: "09:00" },
                { label: "09:15" },
                { label: "09:30" },
                { label: "09:45" },
                { label: "10:00" },
                { label: "10:15" },
                { label: "10:30" },
                { label: "10:45" },
                { label: "11:00" },
                { label: "11:15" },
                { label: "11:30" },
                { label: "11:45" },
                { label: "12:00" },
                { label: "12:15" },
                { label: "12:30" },
                { label: "12:45" },
                { label: "13:00" },
                { label: "13:15" },
                { label: "13:30" },
                { label: "13:45" },
                { label: "14:00" },
                { label: "14:15" },
                { label: "14:30" },
                { label: "14:45" },
                { label: "15:00" },
                { label: "15:15" },
                { label: "15:30" },
                { label: "15:45" },
                { label: "16:00" },
                { label: "16:15" },
                { label: "16:30" },
                { label: "16:45" },
                { label: "17:00" },
                { label: "17:15" },
                { label: "17:30" },
                { label: "17:45" },
                { label: "18:00" },
                { label: "18:15" },
                { label: "18:30" },
                { label: "18:45" },
                { label: "19:00" },
                { label: "19:15" },
                { label: "19:30" },
                { label: "19:45" },
                { label: "20:00" },
                { label: "20:15" },
                { label: "20:30" },
                { label: "20:45" },
                { label: "21:00" },
                { label: "21:15" },
                { label: "21:30" },
                { label: "21:45" },
                { label: "22:00" },
                { label: "22:15" },
                { label: "22:30" },
                { label: "22:45" },
                { label: "23:00" },
                { label: "23:15" },
                { label: "23:30" },
                { label: "23:45" },
                { label: "24:00" },
            ],
            arrEvents: [],

            localEventPercentage: true,
            localEventTitle: "",
            localEventDesc: "",
            localEventAmount: "",


            isEventEnabled: true,

            selectedSector: "Fashion & Shoes1633955735296",
            selectedMain: ["Accessoires1633956984219"],


            // new restaurant stuff
            amountOfTables: "",
            subscription_data: [],
            client_id_user: "",
            houseNumber: "",
            street: "",
            zipCode: "",
            city: "",
            plan_id: "",
            plan_amount: "",
            stripe_plan_key: "",
            split_amount: "",
            inputTypeSelect: "",
            amountOfEvent: "",

            paymentLinkType: "stripe",

            useOfVoucherLocalEvent: "",

            isVendorSaloon: false,

        }
    }

    componentDidMount() {
        // local event
        let newDateLocalEvent = new Date();
        let monthTodayLocalEvent = newDateLocalEvent.getMonth() + 1;
        let dateTodayToMakeLocalEvent = newDateLocalEvent.getDate();
        if (monthTodayLocalEvent < 10) {
            monthTodayLocalEvent = `0${monthTodayLocalEvent}`;
        }
        if (dateTodayToMakeLocalEvent < 10) {
            dateTodayToMakeLocalEvent = `0${dateTodayToMakeLocalEvent}`;
        }
        let makeTodayLocalEvent = `${newDateLocalEvent.getFullYear()}-${monthTodayLocalEvent}-${dateTodayToMakeLocalEvent}`;

        let objToPush = {
            startDateEvent: makeTodayLocalEvent,
            startTimeEvent: "",
            endDateEvent: "",
            endTimeEvent: "",
            personsAmountEvent: "",
            eventId: 1,
        };
        let arrEvents = [objToPush];
        this.setState({
            startDateEvent: makeTodayLocalEvent,
            startDateLiveEvent: makeTodayLocalEvent,
            todayDateToBeMax: makeTodayLocalEvent,
            endDateEvent: makeTodayLocalEvent,
            arrEvents,
            arrEventsDuplicated: arrEvents,
        });
        // local event

        if (this.props.global_values) {
            this.setState({ useOfVoucherLocalEvent: this.props.global_values.voucherUseLocalEvent || "" })
        }

        let urlMain = new URLSearchParams(window.location.search)

        if (urlMain.get("email") && urlMain.get("key")) {
            this.setState({ showMainLoader: true })

            let industry = "restaurant"
            if (urlMain.get("industry")) {
                industry = urlMain.get("industry")
            }

            firebase.database().ref((industry === "restaurant" || industry === "restaurant_new") ? "accounts_by_admin" : industry === "barber_new" ? "accounts_by_admin_barber" : `accounts_by_admin_${industry}`).once("value", (data) => {
                let a = data.val()
                if (a) {
                    let filtered = a.filter((t) => {
                        return t.email === urlMain.get("email")
                    })
                    let indexOfCurrent = a.findIndex((t) => {
                        return t.email === urlMain.get("email")
                    })
                    if (filtered.length > 0) {
                        if (filtered[0].selectedSector) {
                            const { selectedSector, selectedMain, type } = filtered[0]
                            if (type === "retailer-start-event") {
                                this.setState({ isEventEnabled: true })
                            }
                            this.setState({ selectedSector, selectedMain, currentFormType: type, currentFormTypeDefault: type })
                        }
                        if (filtered[0].account_created) {
                            swal({
                                title: "Sorry",
                                text: "Account with this email already created",
                                icon: "error"
                            }).then(() => {
                                window.location.href = "/"
                            })
                        }
                        this.setState({
                            fromDirectUrl: true, showMainLoader: false, allListings: a, companyNameDefault: filtered[0].company_name,
                            defaultEmailParam: urlMain.get("email"), email: urlMain.get("email"), defaultKeyParam: urlMain.get("key"),
                            indexOfCurrentCompany: indexOfCurrent, currentIndustry: "shops", currentIndustryMain: industry,
                            houseNumber: filtered[0].houseNumber, street: filtered[0].street, zipCode: filtered[0].zipCode, city: filtered[0].city,
                            client_id_user: filtered[0].client_id,
                            paymentLinkType: filtered[0].paymentLinkType || "stripe",
                            isVendorSaloon: filtered[0].isVendorSaloon || false
                        })
                    }
                }
            })
        }

        if (this.props.stock_images) {
            this.setState({ allImagesStock: this.props.stock_images });
        }

        this.checkForSector()
        // if (this.props.globalsectors) {
        //     if (this.props.globalsectors.length > 0) {
        //         let arr = this.props.globalsectors;
        //         this.setState({ allSectors: arr });


        //         if (this.props.campaigns_category) {
        //             this.setState({ categoryData: this.props.campaigns_category })
        //         }
        //         if (arr.length > 0) {
        //             if (this.state.selectedSector) {
        //                 this.setState({ sectors: arr });
        //             } else {
        //                 this.setState({ sectors: arr, currentSector: arr[0] });
        //             }
        //             if (this.props.categories_vouchers && this.props.campaigns_category) {
        //                 let a = this.props.categories_vouchers;
        //                 let arrSec = [];
        //                 if (a) {
        //                     let objected = Object.entries(a);
        //                     objected.map((g) => {
        //                         if (g[0] === arr[0].sector_id) {
        //                             arrSec = g[1];
        //                         }
        //                     });
        //                     let categories = [];
        //                     arrSec.map((h) => {
        //                         if (h.children) {
        //                             let obj = {
        //                                 title: h.title,
        //                                 category_id: h.category_id,
        //                                 sub_category: h.children,
        //                             };
        //                             categories.push(obj);
        //                         } else {
        //                             let obj = {
        //                                 title: h.title,
        //                                 category_id: h.category_id,
        //                             };
        //                             categories.push(obj);
        //                         }
        //                     });
        //                     if (categories.length > 0) {
        //                         this.setState({
        //                             completeCategories: objected,
        //                             treeData: arrSec,
        //                         });
        //                     }
        //                 } else {
        //                     this.setState({ completeCategories: [] });
        //                 }

        //                 this.setState({ allValuesLoaded: true });
        //                 if (this.state.showingLoaderCreateEdit) {
        //                     setTimeout(() => {
        //                         this.setState({ showingLoaderCreateEdit: false });
        //                     }, 2000);
        //                 }
        //             } else {
        //                 categoriesVouchersRef.on("value", (data) => {
        //                     let a = data.val();

        //                     this.props.updateCategoriesVouchers(a)
        //                     let arrSec = [];
        //                     if (a) {
        //                         let objected = Object.entries(a);
        //                         objected.map((g) => {
        //                             if (g[0] === arr[0].sector_id) {
        //                                 arrSec = g[1];
        //                             }
        //                         });
        //                         let categories = [];
        //                         arrSec.map((h) => {
        //                             if (h.children) {
        //                                 let obj = {
        //                                     title: h.title,
        //                                     category_id: h.category_id,
        //                                     sub_category: h.children,
        //                                 };
        //                                 categories.push(obj);
        //                             } else {
        //                                 let obj = {
        //                                     title: h.title,
        //                                     category_id: h.category_id,
        //                                 };
        //                                 categories.push(obj);
        //                             }
        //                         });
        //                         if (categories.length > 0) {
        //                             this.setState({
        //                                 completeCategories: objected,
        //                                 treeData: arrSec,
        //                             });
        //                         }
        //                     } else {
        //                         this.setState({ completeCategories: [] });
        //                     }

        //                     this.setState({ allValuesLoaded: true });
        //                     if (this.state.showingLoaderCreateEdit) {
        //                         setTimeout(() => {
        //                             this.setState({ showingLoaderCreateEdit: false });
        //                         }, 2000);
        //                     }
        //                 });
        //             }
        //         }
        //     }
        // }

        let arr = [];
        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }
    }

    componentWillUnmount() {
        categoriesVouchersRef.off()
    }

    componentDidUpdate(prevProps) {
        if (this.props.stock_images !== prevProps.stock_images) {
            this.setState({ allImagesStock: this.props.stock_images });
        }

        if (this.props.globalsectors !== prevProps.globalsectors && this.state.allSectors.length === 0) {
            this.checkForSector()
        }


        let arr = [];
        if (this.props.subscription_plans !== prevProps.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }
    }

    checkForSector() {
        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });


                if (this.props.campaigns_category) {
                    this.setState({ categoryData: this.props.campaigns_category })
                }
                if (arr.length > 0) {
                    if (this.state.selectedSector) {
                        this.setState({ sectors: arr });
                    } else {
                        this.setState({ sectors: arr, currentSector: arr[0] });
                    }
                    if (this.props.categories_vouchers && this.props.campaigns_category) {
                        let a = this.props.categories_vouchers;
                        let arrSec = [];
                        if (a) {
                            let objected = Object.entries(a);
                            objected.map((g) => {
                                if (g[0] === arr[0].sector_id) {
                                    arrSec = g[1];
                                }
                            });
                            let categories = [];
                            arrSec.map((h) => {
                                if (h.children) {
                                    let obj = {
                                        title: h.title,
                                        category_id: h.category_id,
                                        sub_category: h.children,
                                    };
                                    categories.push(obj);
                                } else {
                                    let obj = {
                                        title: h.title,
                                        category_id: h.category_id,
                                    };
                                    categories.push(obj);
                                }
                            });
                            if (categories.length > 0) {
                                this.setState({
                                    completeCategories: objected,
                                    treeData: arrSec,
                                });
                            }
                        } else {
                            this.setState({ completeCategories: [] });
                        }

                        this.setState({ allValuesLoaded: true });
                        if (this.state.showingLoaderCreateEdit) {
                            setTimeout(() => {
                                this.setState({ showingLoaderCreateEdit: false });
                            }, 2000);
                        }
                    } else {
                        categoriesVouchersRef.on("value", (data) => {
                            let a = data.val();

                            this.props.updateCategoriesVouchers(a)
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let categories = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        categories.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        categories.push(obj);
                                    }
                                });
                                if (categories.length > 0) {
                                    this.setState({
                                        completeCategories: objected,
                                        treeData: arrSec,
                                    });
                                }
                            } else {
                                this.setState({ completeCategories: [] });
                            }

                            this.setState({ allValuesLoaded: true });
                            if (this.state.showingLoaderCreateEdit) {
                                setTimeout(() => {
                                    this.setState({ showingLoaderCreateEdit: false });
                                }, 2000);
                            }
                        });
                    }
                }
            }
        }
    }

    uploadImg(e) {
        e.preventDefault();
        let dropFiles = [];

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            objected.map((f, i) => {
                const reader = new FileReader();
                dropFiles.push(objected[i][1]);
                reader.addEventListener("load", () => {
                    let img = new Image();
                    let result = reader.result;
                    img.onload = (e) => {
                        let arr = [];
                        arr.push(result);

                        this.setState({
                            campaignImagePreview: arr,
                            dropFileCampaignImage: dropFiles,
                        });

                    };
                    img.src = result;
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });
        }
    }


    checkForDeleteKortingsVoucher() {
        if (this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8") {
            return (
                <div
                    className="editimageupload"
                    onClick={() => {
                        this.setState({
                            dropFileCampaignImage: [],
                            campaignImagePreview: [
                                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8",
                            ],
                        });
                    }}
                >
                    <MdClose />
                </div>
            );
        }
    }

    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }


    moveToSecondStepDirect() {
        const { email, password, agreed, amountOfEvent } = this.state

        // this.updateStateOnCondition(bankAccountNumber, "bankAccountNumberEmpty")
        // this.updateStateOnCondition(bankName, "bankNameEmpty")

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passInvalid: true });
        } else {
            this.setState({ passInvalid: false });
        }
        // if (this.state.currentIndustry === "restaurant_new") {
        //     if (amountOfEvent === "") {
        //         this.setState({ amountOfEventEmpty: true });
        //     } else {
        //         this.setState({ amountOfEventEmpty: false });
        //     }
        // }

        if (
            email !== "" && regexEmail.test(email) && password !== "" && password.length > 5 &&
            agreed
        ) {
            if (this.state.currentIndustry === "restaurant" || this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") {
                // if (!amountOfEvent) {
                //     return false;
                // }
                this.setState({ secondStep: true })
            } else {
                this.setState({ thirdStep: true })
            }
        }
    }

    validateTableAndPlanAmount() {
        const { amountOfTables, plan_id, inputTypeSelect, amountOfEvent } = this.state

        if (this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") {
            this.updateStateOnCondition(amountOfEvent, "amountOfEventEmpty")

            if (this.checkForValuePresent(amountOfEvent)) {
                this.setState({ secondStep: false, thirdStep: true })
            }
        } else {
            if (this.state.inputTypeSelect === "input-amout") {
                this.updateStateOnCondition(amountOfTables, "amountOfTablesEmpty")
            }
            this.updateStateOnCondition(inputTypeSelect, "inputTypeSelectEmpty")

            if (!plan_id) {
                this.setState({ mustSelectPlanId: true })

                setTimeout(() => {
                    this.setState({ mustSelectPlanId: false })
                }, 2000);
                return;
            }

            if (this.checkForValuePresent(amountOfTables) && this.checkForValuePresent(inputTypeSelect) && plan_id) {
                this.setState({ secondStep: false, thirdStep: true })
            }
        }

    }

    validateAddres() {
        const { houseNumber, zipCode, city, street } = this.state

        this.updateStateOnCondition(street, "streetPopup")
        this.updateStateOnCondition(city, "cityPopup")
        this.updateStateOnCondition(zipCode, "zipCodePopup")
        this.updateStateOnCondition(houseNumber, "houseNumberPopup")

        if (
            this.checkForValuePresent(street) &&
            this.checkForValuePresent(zipCode) &&
            this.checkForValuePresent(city) &&
            this.checkForValuePresent(houseNumber)
        ) {
            this.setState({ thirdStep: false, fourthStep: true })
        }
    }

    moveToThirdStepDirect() {
        const { bankAccountNumber, bankName } = this.state

        this.updateStateOnCondition(bankAccountNumber, "bankAccountNumberEmpty")
        this.updateStateOnCondition(bankName, "bankNameEmpty")

        if (
            this.checkForValuePresent(bankName) &&
            this.checkForValuePresent(bankAccountNumber)
        ) {
            this.setState({ secondStep: false, thirdStep: true })
        }
    }

    createCompanyAndCampaign() {
        const { kortingsVoucherTitle, arrEvents, kortingsVoucherAmount, kortingsVoucherDesc, kortingsVoucherNumber, kortingsVoucherNormalPrice, kortingsVoucherSalePrice, dropFileCampaignImage, mainSectorStock, street, houseNum, postCode, city, localEventPercentage, localEventTitle, localEventDesc, localEventAmount, email, password, bankName, bankAccountNumber } = this.state

        if (this.state.currentFormType === "event" | this.state.currentFormType === "retailer-start-event") {
            this.updateStateOnCondition(localEventTitle, "localEventTitleAlert")
            this.updateStateOnCondition(localEventDesc, "localEventDescAlert")
        } else {
            this.updateStateOnCondition(kortingsVoucherTitle, "kortingsVoucherTitleAlert")
            this.updateStateOnCondition(kortingsVoucherDesc, "kortingsVoucherDescAlert")
        }
        if (this.state.currentFormType === "event" || this.state.currentFormType === "retailer-start-event") {
            this.updateStateOnCondition(localEventAmount, "localEventAmountAlert")
        } else if (this.state.currentFormType === "voucher") {
            this.updateStateOnCondition(kortingsVoucherNormalPrice, "kortingsVoucherNormalPriceAlert")
            // this.updateStateOnCondition(kortingsVoucherSalePrice, "kortingsVoucherSalePriceAlert")
        } else {
            this.updateStateOnCondition(kortingsVoucherAmount, "kortingsVoucherAmountAlert")
            this.updateStateOnCondition(kortingsVoucherNumber, "kortingsVoucherNumberAlert")
        }

        if (dropFileCampaignImage.length > 0 || this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8") {
            this.setState({ campaignImagePreviewAlert: false })
        } else {
            this.setState({ campaignImagePreviewAlert: true })
        }

        let booleanValueCheck = true
        if (this.state.currentFormType === "event" || this.state.currentFormType === "retailer-start-event") {
            if (this.checkForValuePresent(localEventAmount)) {
                booleanValueCheck = true
            } else {
                booleanValueCheck = false
            }
        } else if (this.state.currentFormType === "voucher") {
            if (this.checkForValuePresent(kortingsVoucherNormalPrice)) {
                booleanValueCheck = true
            } else {
                booleanValueCheck = false
            }
        } else {
            if (this.checkForValuePresent(kortingsVoucherAmount) && this.checkForValuePresent(kortingsVoucherNumber)) {
                booleanValueCheck = true
            } else {
                booleanValueCheck = false
            }
        }

        let booleanValueCheckTitleDesc = true
        if (this.state.currentFormType === "event" || this.state.currentFormType === "retailer-start-event") {
            if (this.checkForValuePresent(localEventTitle) && this.checkForValuePresent(localEventDesc)) {
                booleanValueCheckTitleDesc = true
            } else {
                booleanValueCheckTitleDesc = false
            }
        } else {
            if (this.checkForValuePresent(kortingsVoucherTitle) && this.checkForValuePresent(kortingsVoucherDesc)) {
                booleanValueCheckTitleDesc = true
            } else {
                booleanValueCheckTitleDesc = false
            }
        }

        let booleanValueCheckTiming = true
        let arrTimings = []
        if (this.state.currentFormType === "event" || this.state.currentFormType === "retailer-start-event") {
            if (this.state.currentFormType === "retailer-start-event") {
                arrEvents[0].startDateEvent = "2023-11-23"
                arrEvents[0].startTimeEvent = "10:00"
                arrEvents[0].endDateEvent = "2023-11-30"
                arrEvents[0].endTimeEvent = "17:00"
            }

            arrEvents.map((y) => {
                if (
                    y.startDateEvent &&
                    y.endDateEvent &&
                    y.personsAmountEvent &&
                    y.personsAmountEvent !== "00"
                ) {
                    arrTimings.push(y);
                }
            });

            if (arrTimings.length > 0) {
                arrTimings.map((g) => {
                    if (g.startTimeEvent && g.endTimeEvent) {
                        let isoTimeTimerBtn = new Date(Number(g.startDateEvent.split("-")[0]), Number(g.startDateEvent.split("-")[1] - 1), Number(g.startDateEvent.split("-")[2]), Number(g.startTimeEvent.split(":")[0]), Number(g.startTimeEvent.split(":")[1]), 0).toISOString()
                        let isoTimeEndDate = new Date(Number(g.endDateEvent.split("-")[0]), Number(g.endDateEvent.split("-")[1] - 1), Number(g.endDateEvent.split("-")[2]), Number(g.endTimeEvent.split(":")[0]), Number(g.endTimeEvent.split(":")[1]), 0).toISOString()
                        g.isoStartTime = isoTimeTimerBtn
                        g.isoEndTime = isoTimeEndDate
                    } else {
                        let isoTimeTimerBtn = new Date(Number(g.startDateEvent.split("-")[0]), Number(g.startDateEvent.split("-")[1] - 1), Number(g.startDateEvent.split("-")[2]), 0, 0, 0).toISOString()
                        let isoTimeEndDate = new Date(Number(g.endDateEvent.split("-")[0]), Number(g.endDateEvent.split("-")[1] - 1), Number(g.endDateEvent.split("-")[2]), 23, 59, 59).toISOString()
                        g.isoStartTime = isoTimeTimerBtn
                        g.isoEndTime = isoTimeEndDate
                    }
                })

                booleanValueCheckTiming = true
                this.setState({ noTimingSelectedEvent: false })
            } else {
                booleanValueCheckTiming = false
                this.setState({ noTimingSelectedEvent: true })
            }
        }

        if (
            booleanValueCheckTitleDesc &&
            booleanValueCheck &&
            booleanValueCheckTiming &&
            (dropFileCampaignImage.length > 0 || this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8")
        ) {
            let promiseAccountDataEmailSend = new Promise((resolve, reject) => {
                let promiseLogin = new Promise((res) => {
                    let urlMain = new URLSearchParams(window.location.search)
                    this.setState({ aanmeldenloader: true })

                    if (urlMain.get("email") && urlMain.get("key")) {
                        firebase.auth().signInWithEmailAndPassword(this.state.defaultEmailParam, this.state.defaultKeyParam)
                            .then((succ) => {
                                res(succ.user.uid)
                            })
                    }
                })

                promiseLogin.then((uid) => {
                    let myPlaintextPassword = password;
                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {

                            let promisePwdUpdate = new Promise((res) => {
                                const user = firebase.auth().currentUser;
                                user.updatePassword(myPlaintextPassword)
                                    .then(() => {
                                        firebase
                                            .auth()
                                            .signInWithEmailAndPassword(this.state.defaultEmailParam, myPlaintextPassword)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                            })

                            promisePwdUpdate.then(() => {
                                const user = firebase.auth().currentUser;
                                let promiseEmailUpdate = new Promise((res) => {
                                    if (email !== this.state.defaultEmailParam) {
                                        user.updateEmail(email)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    } else {
                                        res()
                                    }
                                })

                                promiseEmailUpdate.then(() => {
                                    const updates = {
                                        [`users/${uid}/email`]: email,
                                        [`users/${uid}/password`]: hash,
                                        // [`users/${uid}/bankName`]: bankName,
                                        // [`users/${uid}/bankAccountNumber`]: bankAccountNumber,
                                        [`users/${uid}/newly_registered`]: false,
                                        [`users/${uid}/hideWizard`]: true,
                                        [`users/${uid}/profileActive`]: true
                                    };

                                    let userCurrent = this.props.user.filter((t) => {
                                        return t.user_id === uid
                                    })

                                    firebase.database().ref().update(updates).then(() => {
                                        let redirect_url = ""
                                        if (userCurrent.length > 0) {
                                            redirect_url = `${window.location.origin}/company/${userCurrent[0].user_name_id}`
                                        }

                                        localStorage.setItem("userid", uid);
                                        localStorage.removeItem("cookie_item");
                                        localStorage.setItem("isAdmin", false);
                                        this.props.updateAdminOrNot(false);

                                        let obj = {
                                            id: uid,
                                            urlRedirect: redirect_url,
                                        }

                                        if (this.state.allListings.length > 0 && this.state.indexOfCurrentCompany !== -1) {
                                            this.state.allListings[this.state.indexOfCurrentCompany].account_created = true
                                            firebase.database().ref((this.state.currentIndustryMain === "restaurant" || this.state.currentIndustryMain === "restaurant_new") ? "accounts_by_admin" : this.state.currentIndustryMain === "barber_new" ? "accounts_by_admin_barber" : `accounts_by_admin_${this.state.currentIndustryMain}`).set(this.state.allListings)
                                        }

                                        resolve(obj)
                                    })
                                })
                            })
                        })
                    })
                })
            })
            let promiseUploadImg = new Promise((resolve, reject) => {
                if (dropFileCampaignImage.length > 0 && this.state.campaignImagePreviewType === "uploadfromcomp") {
                    dropFileCampaignImage.map((e) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`campaigns/${e.name}${new Date().getTime()}`)
                            .put(e)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((snapUrl) => {
                                    resolve(snapUrl)
                                });
                            });
                    });
                } else {
                    resolve(this.state.campaignImagePreview[0])
                }
            })
            let promiseGetLatLong = new Promise((resolve, reject) => {
                let address = ""
                if (this.state.fromDirectUrl) {
                    address = `${this.state.allListings[this.state.indexOfCurrentCompany].street} ${this.state.allListings[this.state.indexOfCurrentCompany].houseNumber}, ${this.state.allListings[this.state.indexOfCurrentCompany].zipCode} ${this.state.allListings[this.state.indexOfCurrentCompany].city}, Netherlands`;
                } else {
                    address = `${street} ${houseNum}, ${postCode} ${city}, Netherlands`;
                }

                var config = {
                    method: "get",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        let coords = response.data.results[0].geometry.location;

                        let obj = {
                            lat: coords.lat,
                            lng: coords.lng,
                        }
                        resolve(obj)
                    })
                    .catch(function () {
                        let obj = {
                            lat: "",
                            lng: "",
                        }
                        resolve(obj)
                    });
            })
            Promise.all([promiseAccountDataEmailSend, promiseUploadImg, promiseGetLatLong]).then((data) => {
                let userId = data[0].id
                let snapUrl = data[1]
                let latitude = data[2].lat
                let longitude = data[2].lng

                let storageKey = firebase.database().ref("bhodi_campaigns/" + userId).push().key;
                let timestamp = Math.floor(Date.now() / 100);

                let obj = {
                    title: kortingsVoucherTitle,
                    desc: kortingsVoucherDesc,
                    kortingsVoucherImageType: this.state.campaignImagePreviewType,
                    kortingsVoucherPrijs: false,
                    kortingsVoucherPercentage: false,
                    campaignType: "kortings_voucher",
                    pushKey: storageKey,
                    image: snapUrl,
                    active: true,
                    created_by: userId,
                    timestamp,
                    number: "",
                    kortingsVoucherUnlimited: true,
                    created_at: new Date().getTime(),
                    kortingsVoucherSalePrice,
                    kortingsVoucherNormalPrice,
                    main_sector: this.state.selectedSector,
                    main_category: this.state.selectedMain,
                };
                if (this.state.currentFormType === "voucher-percentage") {
                    delete obj.kortingsVoucherSalePrice
                    delete obj.kortingsVoucherNormalPrice

                    obj.kortingsVoucherPercentage = true
                    obj.amount = kortingsVoucherAmount
                    obj.kortingsVoucherUnlimited = false
                    obj.number = kortingsVoucherNumber

                    let isoTimeTimerBtn = new Date(2023, 10, 20, 19, 0, 0).toISOString()
                    obj.isoTimeTimerBtn = isoTimeTimerBtn
                    obj.timerTimeWaiting = "19:00"
                    obj.timerDateWaiting = "2023-11-20"
                }
                if (this.state.currentFormType === "voucher") {
                    delete obj.kortingsVoucherSalePrice
                    delete obj.kortingsVoucherNormalPrice

                    obj.kortingsVoucherPrijs = true
                    obj.amount = kortingsVoucherNormalPrice

                    let isoTimeTimerBtn = new Date(2023, 10, 20, 19, 0, 0).toISOString()
                    obj.isoTimeTimerBtn = isoTimeTimerBtn
                    obj.timerTimeWaiting = "19:00"
                    obj.timerDateWaiting = "2023-11-20"
                }
                if (this.state.currentFormType === "event" || this.state.currentFormType === "retailer-start-event") {
                    delete obj.kortingsVoucherSalePrice
                    delete obj.kortingsVoucherNormalPrice
                    delete obj.kortingsVoucherUnlimited
                    delete obj.kortingsVoucherImageType

                    obj.title = localEventTitle
                    obj.desc = localEventDesc
                    obj.campaignType = "local_event"
                    obj.localEventUnlimited = true
                    obj.localEventImageType = this.state.campaignImagePreviewType
                    obj.timings = arrTimings
                    obj.localEventPlaceHolderIsImage = true
                    obj.discountAvailable = true
                    obj.amount = localEventAmount

                    if (this.state.currentFormType === "retailer-start-event") {
                        obj.localEventType = "percentage"

                        let isoTimeTimerBtn = new Date(2023, 10, 20, 19, 0, 0).toISOString()
                        obj.isoTimeTimerBtn = isoTimeTimerBtn
                    } else {
                        let type = "";
                        if (localEventPercentage) {
                            type = "percentage";
                        } else {
                            type = "price";
                        }

                        obj.localEventType = type
                    }
                }
                if (this.state.campaignImagePreviewType === "takefromlibrary") {
                    obj.mainSectorStock = mainSectorStock
                }

                firebase
                    .database()
                    .ref("bhodi_campaigns/" + userId + "/" + storageKey)
                    .set(obj)
                    .then(() => {
                        firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                        firebase.database().ref("users/" + userId + "/longitude").set(longitude)

                        if (this.state.fromDirectUrl) {
                            this.setState({ fourthStep: true, companyUrlRedirect: data[0].urlRedirect, companyUserId: userId })
                        } else {
                            setTimeout(() => {
                                window.location.href = data[0].urlRedirect
                            }, 500);
                        }
                    });
            })
        }
    }


    copyToClipboard() {
        this.setState({ copiedTextDone: true });
        var textField = document.createElement("textarea");
        textField.innerText = this.state.companyUrlRedirect;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        setTimeout(() => {
            this.setState({ copiedTextDone: false })
        }, 3000);
    }


    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    createCompanyAndPaymentLink() {
        const { street, houseNumber, zipCode, city, email, password, bankName, bankAccountNumber, amountOfTables } = this.state

        let stripe_plan_key = this.state.stripe_plan_key
        let plan_id = this.state.plan_id
        let plan_amount = this.state.plan_amount

        this.updateStateOnCondition(bankAccountNumber, "bankAccountNumberEmpty")
        this.updateStateOnCondition(bankName, "bankNameEmpty")

        if (
            this.checkForValuePresent(bankAccountNumber) &&
            this.checkForValuePresent(bankName)
        ) {
            let promiseAccountDataEmailSend = new Promise((resolve, reject) => {
                let promiseLogin = new Promise((res) => {
                    let urlMain = new URLSearchParams(window.location.search)
                    this.setState({ aanmeldenloader: true })

                    if (urlMain.get("email") && urlMain.get("key")) {
                        firebase.auth().signInWithEmailAndPassword(this.state.defaultEmailParam, this.state.defaultKeyParam)
                            .then((succ) => {
                                res(succ.user.uid)
                            })
                    }
                })

                promiseLogin.then((uid) => {
                    let myPlaintextPassword = password;
                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {

                            let promisePwdUpdate = new Promise((res) => {
                                const user = firebase.auth().currentUser;
                                user.updatePassword(myPlaintextPassword)
                                    .then(() => {
                                        firebase
                                            .auth()
                                            .signInWithEmailAndPassword(this.state.defaultEmailParam, myPlaintextPassword)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                            })

                            promisePwdUpdate.then(() => {
                                const user = firebase.auth().currentUser;
                                let promiseEmailUpdate = new Promise((res) => {
                                    if (email !== this.state.defaultEmailParam) {
                                        user.updateEmail(email)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    } else {
                                        res()
                                    }
                                })

                                promiseEmailUpdate.then(() => {
                                    const updates = {
                                        [`users/${uid}/email`]: email,
                                        [`users/${uid}/password`]: hash,
                                        [`users/${uid}/street`]: street,
                                        [`users/${uid}/city`]: city,
                                        [`users/${uid}/zipCode`]: zipCode,
                                        [`users/${uid}/houseNumber`]: houseNumber,
                                        [`users/${uid}/newly_registered`]: false,
                                        [`users/${uid}/hideWizard`]: true,
                                        [`users/${uid}/profileActive`]: true,
                                        [`users/${uid}/client_id_creation`]: this.state.client_id_user,
                                    };

                                    let userCurrent = this.props.user.filter((t) => {
                                        return t.user_id === uid
                                    })

                                    firebase.database().ref().update(updates).then(() => {
                                        let redirect_url = ""
                                        if (userCurrent.length > 0) {
                                            redirect_url = `${window.location.origin}/company/${userCurrent[0].user_name_id}`
                                        }

                                        localStorage.setItem("userid", uid);
                                        localStorage.removeItem("cookie_item");
                                        localStorage.setItem("isAdmin", false);
                                        this.props.updateAdminOrNot(false);

                                        let obj = {
                                            id: uid,
                                            urlRedirect: redirect_url,
                                        }

                                        if (this.state.allListings.length > 0 && this.state.indexOfCurrentCompany !== -1) {
                                            this.state.allListings[this.state.indexOfCurrentCompany].account_created = true
                                            firebase.database().ref((this.state.currentIndustry === "restaurant" || this.state.currentIndustry === "restaurant_new") ? "accounts_by_admin" : this.state.currentIndustry === "barber_new" ? "accounts_by_admin_barber" : `accounts_by_admin_${this.state.currentIndustry}`).set(this.state.allListings)
                                        }

                                        resolve(obj)
                                    })
                                })
                            })
                        })
                    })
                })
            })
            let promiseGeneratePaymentLink = new Promise((resolve, reject) => {
                if (this.state.isVendorSaloon) {
                    resolve({})
                    return;
                }

                if (this.state.currentIndustry === "barber" || this.state.currentIndustry === "barber_new") {
                    let filtered = this.state.subscription_data.filter((t) => {
                        return t.price === "4.00"
                    })
                    if (filtered.length > 0) {
                        stripe_plan_key = filtered[0].stripe_plan_key
                        plan_id = filtered[0].plan_id
                        plan_amount = filtered[0].price
                    }
                }
                if (this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") {
                    let filtered = this.state.subscription_data.filter((t) => {
                        return t.price === "7.50"
                    })
                    if (filtered.length > 0) {
                        stripe_plan_key = filtered[0].stripe_plan_key
                        plan_id = filtered[0].plan_id
                        plan_amount = filtered[0].price
                    }
                }

                if (this.state.paymentLinkType === "bhodi") {
                    let pushKey = firebase.database().ref("payment_links_stripe").push().key
                    let obj = {
                        pushKey,
                        company_id: this.state.client_id_user,
                        payment_id: "",
                        active: true,
                        payment_url: `${window.location.origin}/pay-via-link?key=${pushKey}`,
                        noOfSeats: (this.state.currentIndustry === "barber" || this.state.currentIndustry === "barber_new") ? 4 : amountOfTables || 1,
                        plan_amount,
                        payment_link_of_type: this.state.currentIndustry,
                        brochure_type: (this.state.currentIndustry === "barber" || this.state.currentIndustry === "barber_new") ? "With Holders" : this.state.inputTypeSelect === "default" ? "With Holders" : "Normal",
                        company_name: this.state.companyNameDefault,
                        address: `${street} ${houseNumber}, ${zipCode} ${city}, Netherlands`
                    }

                    resolve(obj)
                } else {
                    var dataForAPI = JSON.stringify({
                        company_id: this.state.client_id_user,
                        stripe_plan_key,
                        plan_id,
                        plan_amount,
                    });

                    var config = {
                        method: "post",
                        url: "https://us-central1-bhodi-7c327.cloudfunctions.net/createPaymentLink",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: dataForAPI,
                    };

                    axios(config)
                        .then((response) => {
                            let apiData = response.data.data
                            let pushKey = firebase.database().ref("payment_links_stripe").push().key
                            let obj = {
                                pushKey,
                                company_id: this.state.client_id_user,
                                payment_id: apiData.id,
                                active: true,
                                payment_url: apiData.url,
                                noOfSeats: (this.state.currentIndustry === "barber" || this.state.currentIndustry === "barber_new") ? 4 : amountOfTables || 1,
                                plan_amount,
                                payment_link_of_type: this.state.currentIndustry,
                                brochure_type: (this.state.currentIndustry === "barber" || this.state.currentIndustry === "barber_new") ? "With Holders" : this.state.inputTypeSelect === "default" ? "With Holders" : "Normal",
                                company_name: this.state.companyNameDefault,
                                address: `${street} ${houseNumber}, ${zipCode} ${city}, Netherlands`
                            }

                            resolve(obj)
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }

            })
            let promiseGetLatLong = new Promise((resolve, reject) => {
                let address = `${street} ${houseNumber}, ${zipCode} ${city}, Netherlands`;

                var config = {
                    method: "get",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        let coords = response.data.results[0].geometry.location;

                        let obj = {
                            lat: coords.lat,
                            lng: coords.lng,
                        }
                        resolve(obj)
                    })
                    .catch(function () {
                        let obj = {
                            lat: "",
                            lng: "",
                        }
                        resolve(obj)
                    });
            })
            Promise.all([promiseAccountDataEmailSend, promiseGeneratePaymentLink, promiseGetLatLong]).then((data) => {
                let userId = data[0].id
                let obj = data[1]
                let paymentLinkClient = data[1].payment_url
                let latitude = data[2].lat
                let longitude = data[2].lng

                if (this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") {
                    let storageKey = firebase.database().ref("bhodi_campaigns/" + userId).push().key;
                    let timestamp = Math.floor(Date.now() / 100);

                    let objToSend = {
                        campaignType: "local_event",
                        pushKey: storageKey,
                        active: true,
                        created_by: userId,
                        timestamp,
                        number: "",
                        created_at: new Date().getTime(),
                        customExpireTypeThreeMonth: true,
                        takeFromAdmin: true,
                        stockAmount: this.state.amountOfEvent === "unlimited" ? "10000000000" : this.state.amountOfEvent.toString()
                    };

                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + userId + "/" + storageKey)
                        .set(objToSend)
                        .then(() => {
                            firebase.database().ref(`payment_links_stripe/${obj.pushKey}`).set(obj)
                            firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                            firebase.database().ref("users/" + userId + "/longitude").set(longitude)
                            if (this.state.useOfVoucherLocalEvent) {
                                firebase.database().ref("users/" + userId + "/useOfVoucherLocalEvent").set(this.state.useOfVoucherLocalEvent)
                            }
                            firebase.database().ref("users/" + userId + "/donation_link_client").set(paymentLinkClient)

                            this.setState({ fifthStep: true, companyUrlRedirect: data[0].urlRedirect, companyUserId: userId, fourthStep: false, secondStep: false, thirdStep: false, })
                        });
                } else {
                    if (this.state.currentIndustry !== "barber_vendors") {
                        firebase.database().ref(`payment_links_stripe/${obj.pushKey}`).set(obj)
                        firebase.database().ref("users/" + userId + "/donation_link_client").set(paymentLinkClient)
                    }

                    firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                    firebase.database().ref("users/" + userId + "/longitude").set(longitude)

                    this.setState({ fifthStep: true, companyUrlRedirect: data[0].urlRedirect, companyUserId: userId, fourthStep: false, secondStep: false, thirdStep: false, })
                }


            })
        }
    }


    createCompanyAndPaymentLinkShops() {
        const { street, houseNumber, zipCode, city, email, password, agreed } = this.state

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let stripe_plan_key = this.state.stripe_plan_key
        let plan_id = this.state.plan_id
        let plan_amount = this.state.plan_amount

        this.updateStateOnCondition(street, "streetPopup")
        this.updateStateOnCondition(city, "cityPopup")
        this.updateStateOnCondition(zipCode, "zipCodePopup")
        this.updateStateOnCondition(houseNumber, "houseNumberPopup")
        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }

        if (
            this.checkForValuePresent(street) &&
            this.checkForValuePresent(zipCode) &&
            this.checkForValuePresent(city) &&
            this.checkForValuePresent(houseNumber) &&
            agreed && email !== "" && regexEmail.test(email)
        ) {
            let promiseAccountDataEmailSend = new Promise((resolve, reject) => {
                let promiseLogin = new Promise((res) => {
                    let urlMain = new URLSearchParams(window.location.search)
                    this.setState({ aanmeldenloader: true })

                    if (urlMain.get("email") && urlMain.get("key")) {
                        firebase.auth().signInWithEmailAndPassword(this.state.defaultEmailParam, this.state.defaultKeyParam)
                            .then((succ) => {
                                res(succ.user.uid)
                            })
                    }
                })

                promiseLogin.then((uid) => {
                    let myPlaintextPassword = password;
                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {

                            const user = firebase.auth().currentUser;
                            let promiseEmailUpdate = new Promise((res) => {
                                if (email !== this.state.defaultEmailParam) {
                                    user.updateEmail(email)
                                        .then(() => {
                                            res()
                                        }).catch((err) => {
                                            console.log(err)
                                        })
                                } else {
                                    res()
                                }
                            })

                            promiseEmailUpdate.then(() => {
                                const updates = {
                                    [`users/${uid}/email`]: email,
                                    [`users/${uid}/street`]: street,
                                    [`users/${uid}/city`]: city,
                                    [`users/${uid}/zipCode`]: zipCode,
                                    [`users/${uid}/houseNumber`]: houseNumber,
                                    [`users/${uid}/newly_registered`]: false,
                                    [`users/${uid}/hideWizard`]: true,
                                    [`users/${uid}/profileActive`]: true,
                                    [`users/${uid}/client_id_creation`]: this.state.client_id_user,
                                };

                                let userCurrent = this.props.user.filter((t) => {
                                    return t.user_id === uid
                                })

                                firebase.database().ref().update(updates).then(() => {
                                    let redirect_url = ""
                                    let redirect_url_guest = ""
                                    if (userCurrent.length > 0) {
                                        redirect_url = `${window.location.origin}/company/${userCurrent[0].user_name_id}`
                                        redirect_url_guest = `${window.location.origin}/company/${userCurrent[0].user_name_id}/campaign-guest`
                                    }

                                    // localStorage.setItem("userid", uid);
                                    // localStorage.removeItem("cookie_item");
                                    // localStorage.setItem("isAdmin", false);
                                    // this.props.updateAdminOrNot(false);

                                    let obj = {
                                        id: uid,
                                        urlRedirect: redirect_url,
                                        urlRedirectGuest: redirect_url_guest,
                                    }

                                    if (this.state.allListings.length > 0 && this.state.indexOfCurrentCompany !== -1) {
                                        this.state.allListings[this.state.indexOfCurrentCompany].account_created = true
                                        firebase.database().ref((this.state.currentIndustryMain === "restaurant" || this.state.currentIndustryMain === "restaurant_new") ? "accounts_by_admin" : this.state.currentIndustryMain === "barber_new" ? "accounts_by_admin_barber" : `accounts_by_admin_${this.state.currentIndustryMain}`).set(this.state.allListings)
                                    }

                                    resolve(obj)
                                })
                            })
                        })
                    })
                })
            })
            let promiseGetLatLong = new Promise((resolve, reject) => {
                let address = `${street} ${houseNumber}, ${zipCode} ${city}, Netherlands`;

                var config = {
                    method: "get",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        let coords = response.data.results[0].geometry.location;

                        let obj = {
                            lat: coords.lat,
                            lng: coords.lng,
                        }
                        resolve(obj)
                    })
                    .catch(function () {
                        let obj = {
                            lat: "",
                            lng: "",
                        }
                        resolve(obj)
                    });
            })
            Promise.all([promiseAccountDataEmailSend, promiseGetLatLong]).then((data) => {
                let userId = data[0].id
                let latitude = data[1].lat
                let longitude = data[1].lng

                let storageKey = firebase.database().ref("bhodi_campaigns/" + userId).push().key;
                let timestamp = Math.floor(Date.now() / 100);

                let objToSend = {
                    campaignType: "local_event",
                    pushKey: storageKey,
                    active: true,
                    created_by: userId,
                    timestamp,
                    number: "",
                    created_at: new Date().getTime(),
                    customExpireTypeThreeMonth: true,
                    takeFromAdmin: true,
                    stockAmount: "10000000000"
                };

                firebase
                    .database()
                    .ref("bhodi_campaigns/" + userId + "/" + storageKey)
                    .set(objToSend)
                    .then(() => {
                        let promiseGeneratePaymentLink = new Promise((resolve, reject) => {
                            let filtered = this.state.subscription_data.filter((t) => {
                                return t.price === "7.50"
                            })
                            if (filtered.length > 0) {
                                stripe_plan_key = filtered[0].stripe_plan_key
                                plan_id = filtered[0].plan_id
                                plan_amount = filtered[0].price
                            }

                            var dataForAPI = JSON.stringify({
                                restaurant_id: this.state.client_id_user,
                                company_id: userId,
                                campaign_id: storageKey,
                                stripe_plan_key,
                                plan_id,
                                plan_amount,
                            });

                            var config = {
                                method: "post",
                                url: "https://us-central1-bhodi-7c327.cloudfunctions.net/createPaymentLink",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                data: dataForAPI,
                            };

                            axios(config)
                                .then((response) => {
                                    let apiData = response.data.data
                                    let pushKey = firebase.database().ref("payment_links_stripe").push().key
                                    let obj = {
                                        pushKey,
                                        company_id: this.state.client_id_user,
                                        payment_id: apiData.id,
                                        active: true,
                                        payment_url: apiData.url,
                                        noOfSeats: 1,
                                        plan_amount,
                                        payment_link_of_type: this.state.currentIndustryMain,
                                        brochure_type: "Normal",
                                        company_name: this.state.companyNameDefault,
                                        address: `${street} ${houseNumber}, ${zipCode} ${city}, Netherlands`
                                    }

                                    resolve(obj)
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });

                        })
                        let promiseEmailSend = new Promise((resolve, reject) => {
                            // var dataForAPI = JSON.stringify({
                            //     sendToEmail: email,
                            //     subject: "Guest Link!",
                            //     innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><p>Link For Guests: ${data[0].urlRedirectGuest}</p><p>Email: ${email}</p><p>Password: ${this.state.defaultKeyParam}</p></body></html>`,
                            // });

                            // var config = {
                            //     method: "post",
                            //     url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendEmailMailjet",
                            //     headers: {
                            //         "Content-Type": "application/json",
                            //     },
                            //     data: dataForAPI,
                            // };

                            // axios(config)
                            //     .then((response) => {
                            //         resolve(response)
                            //     })
                            resolve()
                        })
                        Promise.all([promiseGeneratePaymentLink, promiseEmailSend]).then((dataInner) => {
                            let obj = dataInner[0]
                            firebase.database().ref(`payment_links_stripe/${obj.pushKey}`).set(obj)
                            firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                            firebase.database().ref("users/" + userId + "/longitude").set(longitude)
                            if (this.state.useOfVoucherLocalEvent) {
                                firebase.database().ref("users/" + userId + "/useOfVoucherLocalEvent").set(this.state.useOfVoucherLocalEvent)
                            }
                            firebase.database().ref("users/" + userId + "/donation_link_client").set(obj.payment_url)

                            this.setState({ fifthStep: true, companyUrlRedirect: data[0].urlRedirect, companyUrlRedirectGuest: data[0].urlRedirectGuest, companyUserId: userId, fourthStep: false, secondStep: false, thirdStep: false, })
                        })
                    });
            })
        }
    }

    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                    {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
                </div>
            ) : (this.state.currentIndustry === "barber" || this.state.currentIndustry === "barber_new" || this.state.currentIndustry === "barber_vendors") ? (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink companysubscribepagelinkbarber">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    {window.innerWidth > 1008 && (this.state.secondStep || this.state.thirdStep || this.state.fourthStep) && (<div className="button-back-lgec" onClick={() => {
                        if (this.state.fourthStep) {
                            this.setState({ fourthStep: false, thirdStep: true })
                        } else if (this.state.thirdStep) {
                            this.setState({ thirdStep: false, secondStep: false })
                        } else {
                            history.goBack()
                        }
                    }}>
                        <ChevronLeftIcon />
                    </div>)}
                    {this.state.fifthStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Yess! Jullie account is aangemaakt.</p>

                            <p className='third-smt-fbcoco'>
                                Indien gewenst kunnen jullie de pagina nog verder personaliseren
                                met een foto of video van jullie zaak.
                            </p>
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "flex-start" }}>
                            <button style={{ marginLeft: 0 }} onClick={() => {
                                window.location.href = this.state.companyUrlRedirect
                            }}>BEKIJK PAGINA</button>
                        </div>
                    </div>) : (<div className="container-lgec">
                        <div className="top-step-one-clgec">
                            {this.state.fourthStep ? (<label>Gegevens</label>) : this.state.thirdStep ? (<label>Controleren</label>) : this.state.secondStep ? (<label>Kaarten</label>) : (<label>Aanmelden</label>)}
                            {this.state.fourthStep ? (<label>Bankgegevens</label>) : this.state.thirdStep ? (<label>Adresgegevens</label>) : this.state.secondStep ? (<label>Tegemoetkoming</label>) : (<label>Account gegevens</label>)}
                            {this.state.fourthStep ? (<label style={{ maxWidth: "100%" }}>
                                Voor het uitkeren van de maandelijkse tegemoetkoming {window.innerWidth > 1008 && (<br />)}
                                hebben wij jullie bankgegevens nodig.
                            </label>) : this.state.thirdStep ? (<label style={{ maxWidth: "100%" }}>
                                Controleer de adresgegevens voor het toesturen van de kaarten.
                            </label>) : this.state.secondStep ? (<label style={{ maxWidth: "100%" }}>
                                De kaarten en houders krijgen jullie gratis van ons aangeboden. {window.innerWidth > 1008 && (<br />)}
                                Geef het aantal tafels door:
                            </label>) : (<label>
                                Leuk dat jullie willen meedoen met Bhodi!<br />
                                Controleer het e-mailadres en kies een wachtwoord.
                            </label>)}
                        </div>
                        <div className="forms-clgec">
                            {this.state.fourthStep ? (<>
                                <div className={`form-field-clgec ${this.state.bankAccountNumberEmpty ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Bankrekeningnummer'
                                        value={this.state.bankAccountNumber}
                                        type='text'
                                        onChange={(e) => {
                                            this.setState({ bankAccountNumber: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ${this.state.bankNameEmpty ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Naam rekeninghouder'
                                        value={this.state.bankName}
                                        type='text'
                                        onChange={(e) => {
                                            this.setState({ bankName: e.target.value })
                                        }}
                                    />
                                </div>
                            </>) : this.state.thirdStep ? (<>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.streetPopup ? "redalertffcl" : ""}`}>
                                    <label>Straatnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Straatnaam"
                                        value={this.state.street}
                                        onChange={(e) => this.setState({ street: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.houseNumberPopup ? "redalertffcl" : ""}`}>
                                    <label>Huisnummer</label>
                                    <input
                                        type="text"
                                        placeholder="Huisnummer"
                                        value={this.state.houseNumber}
                                        onChange={(e) => this.setState({ houseNumber: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.zipCodePopup ? "redalertffcl" : ""}`}>
                                    <label>Postcode</label>
                                    <input
                                        type="text"
                                        placeholder="Postcode"
                                        value={this.state.zipCode}
                                        onChange={(e) => this.setState({ zipCode: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.cityPopup ? "redalertffcl" : ""}`}>
                                    <label>Plaatsnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Plaats"
                                        value={this.state.city}
                                        onChange={(e) => this.setState({ city: e.target.value })}
                                    />
                                </div>
                            </>) : (<>
                                <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='E-mail'
                                        type='email'
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ${this.state.passInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Kies wachtwoord'
                                        type='password'
                                        value={this.state.password}
                                        onChange={(e) => {
                                            this.setState({ password: e.target.value })
                                        }}
                                    />
                                </div>
                                <div
                                    className="fx-registration-inner"
                                    style={{ alignItems: "center" }}
                                >
                                    <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                        <div
                                            className="left-frvn"
                                            style={{
                                                width: "100%",
                                                paddingLeft: 10,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <div className="checking-box">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.agreed}
                                                    onChange={() =>
                                                        this.setState({ agreed: !this.state.agreed })
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        color: this.state.agreedPopup ? "red" : "black",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <font
                                                        style={{
                                                            textDecorationLine: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                "_blank" // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Akkoord met voorwaarden
                                                    </font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            if (this.state.fourthStep) {
                                                this.createCompanyAndPaymentLink()
                                            } else if (this.state.thirdStep) {
                                                this.validateAddres()
                                            } else {
                                                this.moveToSecondStepDirect()
                                            }
                                        }}
                                    >
                                        {this.state.fourthStep ? "Afronden" : "Volgende"}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                <div className="steps-container-main">
                                    <div className={`scm-inner-steps ${!this.state.fourthStep && !this.state.thirdStep && !this.state.secondStep && "active"}`}>

                                    </div>
                                    <div className={`scm-inner-steps ${this.state.thirdStep && "active"}`}>

                                    </div>
                                    <div className={`scm-inner-steps ${this.state.fourthStep && "active"}`}>

                                    </div>
                                    {this.state.fourthStep ? (<label>3/3</label>) : this.state.thirdStep ? (<label>2/3</label>) : (<label>1/3</label>)}
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        <div className="popup-opener-stogec pos-inner-img">
                            {(this.state.secondStep || this.state.thirdStep || this.state.fourthStep) && (<div className="button-back-lgec" onClick={() => {
                                if (this.state.fourthStep) {
                                    this.setState({ fourthStep: false, thirdStep: true })
                                } else if (this.state.thirdStep) {
                                    this.setState({ thirdStep: false, secondStep: false })
                                } else {
                                    history.goBack()
                                }
                            }}>
                                <ChevronLeftIcon />
                            </div>)}
                            <img src={logoSubAdmin} alt='logo' width={50} />
                        </div>
                    </div>
                )}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : this.state.currentIndustry === "shops" ? (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    {this.state.fifthStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Jullie aanmelding is verwerkt.</p>
                            <p className='third-smt-fbcoco'>
                                Op 5 augustus ontvangen jullie<br />
                                van ons de teksten en de unieke link<br />
                                voor het versturen van de nieuwsbrief.
                            </p>
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "flex-start" }}>
                            <button style={{ marginLeft: 0 }} onClick={() => {
                                window.location.href = "/"
                                // history.goBack()
                                // window.location.href = this.state.companyUrlRedirectGuest
                            }}>SLUIT PAGINA</button>
                        </div>
                    </div>) : (<div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label>Aanmelden</label>
                            <label>Account gegevens</label>
                            <label style={{ maxWidth: "none" }}>
                                Leuk dat jullie willen meedoen met de Bhodi charity actie!<br />
                                Controleer de onderstaande gegevens en meld je eenvoudig aan.
                            </label>
                        </div>
                        <div className="forms-clgec">
                            <>
                                <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='E-mail'
                                        type='email'
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.streetPopup ? "redalertffcl" : ""}`}>
                                    <label>Straatnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Straatnaam"
                                        value={this.state.street}
                                        onChange={(e) => this.setState({ street: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.houseNumberPopup ? "redalertffcl" : ""}`}>
                                    <label>Huisnummer</label>
                                    <input
                                        type="text"
                                        placeholder="Huisnummer"
                                        value={this.state.houseNumber}
                                        onChange={(e) => this.setState({ houseNumber: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.zipCodePopup ? "redalertffcl" : ""}`}>
                                    <label>Postcode</label>
                                    <input
                                        type="text"
                                        placeholder="Postcode"
                                        value={this.state.zipCode}
                                        onChange={(e) => this.setState({ zipCode: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.cityPopup ? "redalertffcl" : ""}`}>
                                    <label>Plaatsnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Plaats"
                                        value={this.state.city}
                                        onChange={(e) => this.setState({ city: e.target.value })}
                                    />
                                </div>
                                <div
                                    className="fx-registration-inner"
                                    style={{ alignItems: "center" }}
                                >
                                    <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                        <div
                                            className="left-frvn"
                                            style={{
                                                width: "100%",
                                                paddingLeft: 10,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <div className="checking-box">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.agreed}
                                                    onChange={() =>
                                                        this.setState({ agreed: !this.state.agreed })
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        color: this.state.agreedPopup ? "red" : "black",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <font
                                                        style={{
                                                            textDecorationLine: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                "_blank" // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Akkoord met voorwaarden
                                                    </font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            this.createCompanyAndPaymentLinkShops()
                                        }}
                                    >
                                        {"Volgende"}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec" style={{ backgroundImage: (this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") ? `url("${restaurantNewPic}")` : this.state.currentFormTypeDefault === "voucher" && `url("${voucherFormImage}")` }}>

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        <div className="popup-opener-stogec pos-inner-img">
                            {(this.state.secondStep || this.state.thirdStep || this.state.fourthStep) && (<div className="button-back-lgec" onClick={() => {
                                history.goBack()
                            }}>
                                <ChevronLeftIcon />
                            </div>)}
                            <img src={logoSubAdmin} alt='logo' width={50} />
                        </div>
                    </div>
                )}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : (this.state.currentIndustry === "restaurant" || this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") ? (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    {window.innerWidth > 1008 && (this.state.secondStep || this.state.thirdStep || this.state.fourthStep) && (<div className="button-back-lgec" onClick={() => {
                        if (this.state.fourthStep) {
                            this.setState({ fourthStep: false, thirdStep: true })
                        } else if (this.state.thirdStep) {
                            this.setState({ thirdStep: false, secondStep: true })
                        } else if (this.state.secondStep) {
                            this.setState({ secondStep: false })
                        } else {
                            history.goBack()
                        }
                    }}>
                        <ChevronLeftIcon />
                    </div>)}
                    {this.state.fifthStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Yess! Jullie account is aangemaakt.</p>

                            <p className='third-smt-fbcoco'>
                                Indien gewenst kunnen jullie de pagina nog verder personaliseren
                                met een foto of video van jullie zaak.
                            </p>
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "flex-start" }}>
                            <button style={{ marginLeft: 0 }} onClick={() => {
                                window.location.href = this.state.companyUrlRedirect
                            }}>BEKIJK PAGINA</button>
                        </div>
                    </div>) : (<div className="container-lgec">
                        <div className="top-step-one-clgec">
                            {this.state.fourthStep ? (<label>Gegevens</label>) : this.state.thirdStep ? (<label>Controleren</label>) : this.state.secondStep ? (<label>{this.state.currentIndustry === "restaurant_new" ? "Actie" : "Kaarten"}</label>) : (<label>Aanmelden</label>)}
                            {this.state.fourthStep ? (<label>Bankgegevens</label>) : this.state.thirdStep ? (<label>Adresgegevens</label>) : this.state.secondStep ? (<label>{this.state.currentIndustry === "restaurant_new" ? "3-gangenmenu 1+1 gratis" : "Keuze"}</label>) : (<label>Account gegevens</label>)}
                            {this.state.fourthStep ? (<label style={{ maxWidth: "100%" }}>
                                Voor het uitkeren van de maandelijkse tegemoetkoming {window.innerWidth > 1008 && (<br />)}
                                hebben wij jullie bankgegevens nodig.
                            </label>) : this.state.thirdStep ? (<label style={{ maxWidth: "100%" }}>
                                {(this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") ? "Controleer de adresgegevens van jullie restaurant" : "Controleer de adresgegevens voor het toesturen van de kaarten."}
                            </label>) : this.state.secondStep ? (<label style={{ maxWidth: "100%" }}>
                                {(this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") ? " Hoeveel gasten mogen er online gebruiken maken van deze actie:" : "De kaarten en houders krijgen jullie gratis van ons aangeboden en per post toegestuurd. Selecteer type kaart:"}
                            </label>) : (<label>
                                Leuk dat jullie willen meedoen met de Bhodi charity actie!<br />
                                Controleer het e-mailadres en kies een wachtwoord.
                            </label>)}
                        </div>
                        <div className="forms-clgec">
                            {this.state.fourthStep ? (<>
                                <div className={`form-field-clgec ${this.state.bankAccountNumberEmpty ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Bankrekeningnummer'
                                        value={this.state.bankAccountNumber}
                                        type='text'
                                        onChange={(e) => {
                                            this.setState({ bankAccountNumber: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ${this.state.bankNameEmpty ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Naam rekeninghouder'
                                        value={this.state.bankName}
                                        type='text'
                                        onChange={(e) => {
                                            this.setState({ bankName: e.target.value })
                                        }}
                                    />
                                </div>
                            </>) : this.state.thirdStep ? (<>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.streetPopup ? "redalertffcl" : ""}`}>
                                    <label>Straatnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Straatnaam"
                                        value={this.state.street}
                                        onChange={(e) => this.setState({ street: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.houseNumberPopup ? "redalertffcl" : ""}`}>
                                    <label>Huisnummer</label>
                                    <input
                                        type="text"
                                        placeholder="Huisnummer"
                                        value={this.state.houseNumber}
                                        onChange={(e) => this.setState({ houseNumber: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.zipCodePopup ? "redalertffcl" : ""}`}>
                                    <label>Postcode</label>
                                    <input
                                        type="text"
                                        placeholder="Postcode"
                                        value={this.state.zipCode}
                                        onChange={(e) => this.setState({ zipCode: e.target.value })}
                                    />
                                </div>
                                <div className={`form-field-clgec ffclg-label-new ${this.state.cityPopup ? "redalertffcl" : ""}`}>
                                    <label>Plaatsnaam</label>
                                    <input
                                        type="text"
                                        placeholder="Plaats"
                                        value={this.state.city}
                                        onChange={(e) => this.setState({ city: e.target.value })}
                                    />
                                </div>
                            </>) : this.state.secondStep ? (<>
                                {(this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") ? (<div className={`form-field-clgec selectcountry-cfglpn selecttypeamount ${this.state.amountOfEventEmpty ? "redalertffcl" : ""}`}>
                                    <select value={this.state.amountOfEvent} onChange={(e) => {
                                        this.setState({ amountOfEvent: e.target.value, amountOfEventEmpty: false })
                                    }}>
                                        <option value="">Selecteer aantal</option>
                                        <option value='50'>50</option>
                                        <option value='100'>100</option>
                                        <option value='150'>150</option>
                                        <option value='200'>200</option>
                                        <option value='250'>250</option>
                                        <option value='300'>300</option>
                                        <option value='350'>350</option>
                                        <option value='400'>400</option>
                                        <option value='450'>450</option>
                                        <option value='500'>500</option>
                                        <option value='unlimited'>Unlimited</option>
                                    </select>
                                    <KeyboardArrowDownIcon style={{ color: "black" }} />
                                </div>) : (<>
                                    <div className={`form-field-clgec selectcountry-cfglpn selecttypeamount ${this.state.inputTypeSelectEmpty ? "redalertffcl" : ""}`}>
                                        <select value={this.state.inputTypeSelect} onChange={(e) => {
                                            if (e.target.value === "default") {
                                                this.setState({ amountOfTables: "4" })
                                            } else {
                                                this.setState({ amountOfTables: "" })
                                            }
                                            this.setState({ inputTypeSelect: e.target.value, amountOfTablesEmpty: false, inputTypeSelectEmpty: false })
                                        }}>
                                            <option value="">Selecteer</option>
                                            <option value='default'>Losse kaart op schuimplaat</option>
                                            <option value='input-amout'>Kaart met houder van beukenhout </option>
                                        </select>
                                        <KeyboardArrowDownIcon style={{ color: "black" }} />
                                    </div>
                                    {this.state.inputTypeSelect === "input-amout" && (<div className={`form-field-clgec ${this.state.amountOfTablesEmpty ? "redalertffcl" : ""}`}>
                                        <input
                                            placeholder='Aantal'
                                            value={this.state.amountOfTables}
                                            type='number'
                                            onChange={(e) => {
                                                this.setState({ amountOfTables: e.target.value })
                                            }}
                                        />
                                    </div>)}
                                </>)}
                                <p className='text-clgec-charity tcc-headingContent' style={{ color: "#c17f6b", fontWeight: 600, letterSpacing: 1 }}>Tegemoetkoming</p>
                                <div className="selecteer-tooltipdes" style={{ position: "relative", marginTop: 15 }}>
                                    <p
                                        className="text-clgec-charity texboldclged"
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            color: "rgb(33, 37, 41)",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        {/* Vanuit Bhodi ontvangen jullie als restaurant een tegemoetkoming<br />
                                        in de kosten per aangebrachte charity member. De tegemoetkoming<br />
                                        is maximaal €30 per charity member. */}
                                        Als restaurant ontvangen jullie een tegemoetkoming in de kosten <br />
                                        voor het weggeven van een gratis driegangenmenu per deelnemende gast. <br />
                                        De tegemoetkoming is maximaal €30. Dit is afhankelijk van de duur van <br />
                                        de maandelijkse bijdrage per deelnemende gast.
                                        {/* Als restaurant kunnen jullie kiezen voor welke bijdrage de gasten<br />
                                        zich kunnen aanmelden. We bieden de keuze uit €6, €8 of €12 per maand.
                                        <br />
                                        <br />
                                        Per aangebrachte nieuwe charity member ontvangen jullie een doorlopende vergoeding
                                        van €2, €3 of €4 per maand <font style={{ fontSize: 11 }}>(afhankelijk van de bovengenoemde keuze).</font> */}
                                    </p>
                                    <span
                                        className="tooltiptext tooltipinfo"
                                        style={{ display: this.state.mustSelectPlanId ? "inline-flex" : "none" }}
                                    >
                                        Selecteer
                                    </span>
                                </div>
                                {(this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") ? (
                                    !this.state.amountOfEvent ? <></> : this.state.amountOfEvent === "unlimited" ? <></> : <p
                                        className="text-clgec-charity texboldclged"
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            color: "rgb(33, 37, 41)",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        Op basis van het aantal geselecteerde gasten, is de tegemoetkoming: € {Number(this.state.amountOfEvent) * 30}
                                    </p>
                                ) : (<div className="radios-charity-lfss" style={{ marginTop: 20, marginBottom: 50, borderTop: 0 }}>
                                    {this.state.subscription_data.length > 0 &&
                                        [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                            if (e.price === "6.00" || e.price === "8.00" || e.price === "12.00") {
                                                return (
                                                    <div className="inner-rclfss">
                                                        <input
                                                            type="radio"
                                                            id="radiocharity"
                                                            name="charity"
                                                            value={e.plan_id}
                                                            checked={e.plan_id === this.state.plan_id}
                                                            onChange={() =>
                                                                this.setState({
                                                                    plan_id: e.plan_id,
                                                                    stripe_plan_key: e.stripe_plan_key,
                                                                    plan_amount: e.price,
                                                                    split_amount: e.split_amount,
                                                                })
                                                            }
                                                        />
                                                        <span>€ {Number(e.price)}</span>
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>)}
                            </>) : (<>
                                <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='E-mail'
                                        type='email'
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ${this.state.passInvalid ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Kies wachtwoord'
                                        type='password'
                                        value={this.state.password}
                                        onChange={(e) => {
                                            this.setState({ password: e.target.value })
                                        }}
                                    />
                                </div>
                                <div
                                    className="fx-registration-inner"
                                    style={{ alignItems: "center" }}
                                >
                                    <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                        <div
                                            className="left-frvn"
                                            style={{
                                                width: "100%",
                                                paddingLeft: 10,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <div className="checking-box">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.agreed}
                                                    onChange={() =>
                                                        this.setState({ agreed: !this.state.agreed })
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        color: this.state.agreedPopup ? "red" : "black",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <font
                                                        style={{
                                                            textDecorationLine: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                "_blank" // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Akkoord met voorwaarden
                                                    </font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                            {/* {window.innerWidth < 1008 && (<div className="steps-container-main">
                            <div className={`scm-inner-steps ${!this.state.thirdStep && !this.state.secondStep && "active"}`}>

                            </div>
                            <div className={`scm-inner-steps ${this.state.secondStep && "active"}`}>

                            </div>
                            <div className={`scm-inner-steps ${this.state.thirdStep && "active"}`}>

                            </div>
                            {this.state.thirdStep ? (<label>3/3</label>) : this.state.secondStep ? (<label>2/3</label>) : (<label>1/3</label>)}
                        </div>)} */}
                            <div className="bottom-buttons-clgec">
                                {/* {window.innerWidth < 1008 && !this.state.aanmeldenloader && (
                                <button onClick={() => history.goBack()}>
                                    {" "}
                                    <ChevronLeftIcon />{" "}
                                </button>
                            )} */}
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            if (this.state.fourthStep) {
                                                this.createCompanyAndPaymentLink()
                                            } else if (this.state.thirdStep) {
                                                this.validateAddres()
                                            } else if (this.state.secondStep) {
                                                this.validateTableAndPlanAmount()
                                            } else {
                                                this.moveToSecondStepDirect()
                                            }
                                        }}
                                    >
                                        {this.state.fourthStep ? "Afronden" : "Volgende"}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                <div className="steps-container-main">
                                    <div className={`scm-inner-steps ${!this.state.fourthStep && !this.state.thirdStep && !this.state.secondStep && "active"}`}>

                                    </div>
                                    <div className={`scm-inner-steps ${this.state.secondStep && "active"}`}>

                                    </div>
                                    <div className={`scm-inner-steps ${this.state.thirdStep && "active"}`}>

                                    </div>
                                    <div className={`scm-inner-steps ${this.state.fourthStep && "active"}`}>

                                    </div>
                                    {this.state.fourthStep ? (<label>4/4</label>) : this.state.thirdStep ? (<label>3/4</label>) : this.state.secondStep ? (<label>2/4</label>) : (<label>1/4</label>)}
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec" style={{ backgroundImage: (this.state.currentIndustry === "restaurant_new" || this.state.currentIndustry === "wellness") ? `url("${restaurantNewPic}")` : this.state.currentFormTypeDefault === "voucher" && `url("${voucherFormImage}")` }}>

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        <div className="popup-opener-stogec pos-inner-img">
                            {(this.state.secondStep || this.state.thirdStep || this.state.fourthStep) && (<div className="button-back-lgec" onClick={() => {
                                if (this.state.fourthStep) {
                                    this.setState({ fourthStep: false, thirdStep: true })
                                } else if (this.state.thirdStep) {
                                    this.setState({ thirdStep: false, secondStep: true })
                                } else if (this.state.secondStep) {
                                    this.setState({ secondStep: false })
                                } else {
                                    history.goBack()
                                }
                            }}>
                                <ChevronLeftIcon />
                            </div>)}
                            <img src={logoSubAdmin} alt='logo' width={50} />
                        </div>
                    </div>
                )}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : <div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    {window.innerWidth > 1008 && (this.state.secondStep || this.state.thirdStep) && (<div className="button-back-lgec" onClick={() => {
                        if (this.state.thirdStep) {
                            this.setState({ thirdStep: false, secondStep: false })
                        } else if (this.state.secondStep) {
                            this.setState({ secondStep: false })
                        } else {
                            history.goBack()
                        }
                    }}>
                        <ChevronLeftIcon />
                    </div>)}
                    {this.state.fourthStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Yess! Jullie profielpagina is aangemaakt.</p>
                            {/* <p className='third-smt-fbcoco'>
                                De pagina kunnen jullie indien gewenst nog personaliseren
                                met een mooie eigen foto of video van jullie restaurant.
                            </p>
                            <p className='third-smt-fbcoco'>
                                Mochten jullie nog voorwaarden of instucties willen
                                toevoegen voor het gebruik van het menu, dan kan dat
                                heel eenvoudig via de settings op jullie account.
                            </p>
                            <p className='third-smt-fbcoco'>
                                Jullie profielpagina is voorzien van een unieke link.
                                Deze link kunnen jullie gebruiken om eenvoudig gasten
                                uit te nodigen voor het menu en deze te delen via de
                                socials en natuurlijk jullie nieuwsbrief.
                            </p> */}
                            <p className='third-smt-fbcoco'>
                                Indien gewenst kunnen jullie de pagina nog verder personaliseren
                                met een foto of video van jullie winkel of van een bekend merk.
                            </p>
                            <p className='third-smt-fbcoco'>
                                Uiteraard kunnen jullie ook de kinderen en scholen in Tanzania
                                ondersteunen met maaltijden en materialen. Tijdens de uitgiftes
                                maakt ons team speciaal voor jullie een persoonlijke en op naam
                                ingesproken bedankvideo.
                            </p>
                            {/* <p className='third-smt-fbcoco'>
                                {this.state.companyUrlRedirect} {this.state.copiedTextDone ? (<font style={{ color: "#C17F6B", cursor: "pointer" }}>Gekopieerd</font>) : (<font style={{ color: "#C17F6B", cursor: "pointer" }} onClick={() => this.copyToClipboard()}>Kopieer</font>)}
                            </p> */}
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "flex-start" }}>
                            <button style={{ marginLeft: 0 }} onClick={() => {
                                window.location.href = this.state.companyUrlRedirect
                            }}>BEKIJK PAGINA</button>
                            <button style={{ marginLeft: 10 }} onClick={() => {
                                window.location.href = `${this.state.companyUrlRedirect}/charity`
                            }}>WORD MEMBER</button>
                        </div>
                    </div>) : (<div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label>Aanmelden</label>
                            {this.state.thirdStep ? (<label>{this.state.currentFormType === "retailer-start-event" ? "Event toevoegen" : "Charity menu toevoegen"}</label>) : this.state.secondStep ? (<label>Maandelijkse vergoeding</label>) : (<label>Account gegevens</label>)}
                            {this.state.thirdStep ? (this.state.currentFormType === "retailer-start-event" ? (<label style={{ maxWidth: "100%" }}>
                                Geef een pakkende titel en omschrijf de actie. <br />
                                Bijvoorbeeld de hoogte van de korting en hoeveel <br />
                                producten de klant mag kopen tijdens dit event.
                            </label>) : <label style={{ maxWidth: "100%" }}>
                                Geef een pakkende titel en een leuke omschrijving van het het menu.
                            </label>) : this.state.secondStep ? (<label style={{ maxWidth: "100%" }}>
                                Voor iedere nieuwe member die deelneemt aan jullie event, ontvangen jullie een
                                vergoeding. Vul de gegevens in waarop wij het tegoed mogen storten.
                            </label>) : (<label>
                                Leuk dat jullie willen gaan meedoen! <br />
                                Controleer het e-mail adres en kies een wachtwoord.
                            </label>)}
                            {/* <label>Indien gewenst kun je het menu later nog wijzigen with the dezctiptrion and a bit to show here</label> */}
                        </div>
                        <div className="forms-clgec">
                            {/* <div className='voucher-event-swap'>
                                <Switch
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    checked={this.state.isEventEnabled}
                                    onClick={() => {
                                        if (this.state.isEventEnabled) {
                                            this.setState({ currentFormType: "voucher-percentage" })
                                        } else {
                                            this.setState({ currentFormType: "retailer-start-event" })
                                        }
                                        this.setState({
                                            isEventEnabled: !this.state.isEventEnabled,
                                        });
                                    }}
                                />
                                <label>{this.state.isEventEnabled ? "Event" : "Voucher"}</label>
                            </div> */}
                            {(this.state.thirdStep && this.state.currentFormTypeDefault === "retailer-start-event") && (<div className={`form-field-clgec`}>
                                <div className='wrapper-select-new'>
                                    <select
                                        value={this.state.currentFormType}
                                        onChange={(e) => {
                                            this.setState({ currentFormType: e.target.value })
                                        }}
                                        style={{ width: "100%" }}
                                    >
                                        <option value="retailer-start-event">Event</option>
                                        <option value="voucher-percentage">
                                            Voucher
                                        </option>
                                    </select>
                                    <ExpandMore
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: 5,
                                            bottom: 10,
                                            fontSize: 18,
                                            pointerEvents: "none",
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>)}
                            {(this.state.thirdStep && (this.state.currentFormType === "event" || this.state.currentFormType === "retailer-start-event")) ? ((<>
                                <div className={`form-field-clgec ${this.state.localEventTitleAlert ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Titel'
                                        value={this.state.localEventTitle}
                                        onChange={(e) => {
                                            this.setState({ localEventTitle: e.target.value })
                                        }}
                                    />
                                </div>
                                <div className={`form-field-clgec ${this.state.localEventDescAlert ? "redalertffcl" : ""}`}>
                                    <textarea
                                        placeholder='Omschrijving'
                                        value={this.state.localEventDesc}
                                        onChange={(e) => {
                                            this.setState({ localEventDesc: e.target.value })
                                        }}
                                    ></textarea>
                                </div>
                                {this.state.currentFormType === "event" && (<div className={`form-field-clgec ${this.state.noTimingSelectedEvent ? "redalertffcl" : ""}`}>
                                    <div className='image-wrappwr-fwcoco'>
                                        <div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            <div className='wrapper-select-new'>
                                                <input
                                                    type="text"
                                                    placeholder="Datum en tijd voor het event"
                                                    readOnly
                                                    className="dolabelsuppercase"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        this.setState({ moreTimingsPopup: true })
                                                    }}
                                                />
                                                <ChevronRightIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: this.state.localEventImageAlert ? 37 : 10,
                                                        fontSize: 18,
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                                {this.state.currentFormType === "event" ? (<div className="form-field-row">
                                    <div className={`form-field-clgec`}>
                                        <div className='wrapper-select-new'>
                                            <select
                                                value={
                                                    this.state.localEventPercentage ? "per" : "pri"
                                                }
                                                onChange={(e) => {
                                                    if (e.target.value === "per") {
                                                        this.setState({
                                                            localEventPercentage: true,
                                                            localEventAmount: "",
                                                            discountAvailable: true,
                                                        });
                                                    } else {
                                                        this.setState({
                                                            localEventPercentage: false,
                                                            localEventAmount: "",
                                                            discountAvailable: true,
                                                        });
                                                    }
                                                }}
                                                style={{ width: "100%" }}
                                            >
                                                <option value="per">Percentage</option>
                                                <option value="pri">Bedrag</option>
                                            </select>
                                            <ExpandMore
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    right: 5,
                                                    bottom: 10,
                                                    fontSize: 18,
                                                    pointerEvents: "none",
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                    </div>
                                    {(<div className={`form-field-clgec`}>
                                        <div className={`form-field-percentage ${this.state.localEventAmountAlert ? "redalertffcl" : ""}`} style={{ marginBottom: 0 }}>
                                            <label>Amount</label>
                                            <div className='input-ffpercent'>
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        left: 8,
                                                        color: "#8c8c8c",
                                                        fontSize: 13,
                                                        top: "50%",
                                                        transform: "translateY(-50%)",
                                                        zIndex: 99999,
                                                    }}
                                                >
                                                    {this.state.localEventPercentage ? "%" : "€"}
                                                </span>
                                                <input type="number"
                                                    value={this.state.localEventAmount}
                                                    onChange={(e) => {
                                                        if (Number(e.target.value) > 100 && this.state.localEventPercentage) {
                                                            this.setState({ localEventAmount: 100 })
                                                            return false;
                                                        }
                                                        this.setState({ localEventAmount: e.target.value })
                                                    }} />
                                            </div>
                                        </div>
                                    </div>)}
                                </div>) : (<>
                                    <div className={`form-field-clgec`}>
                                        <div className={`form-field-percentage ${this.state.noTimingSelectedEvent ? "redalertffcl" : ""}`} style={{ marginBottom: 0 }}>
                                            <label>Aantal klanten (min 10 pers)</label>
                                            <div className='input-ffpercent'>
                                                <input type="number"
                                                    value={this.state.arrEvents[0].personsAmountEvent}
                                                    onChange={(e) => {
                                                        this.state.arrEvents[0].personsAmountEvent = e.target.value
                                                        this.setState({ arrEvents: this.state.arrEvents })
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-field-clgec`}>
                                        <div className={`form-field-percentage ${this.state.localEventAmountAlert ? "redalertffcl" : ""}`} style={{ marginBottom: 0 }}>
                                            <label>Kortingspercentage</label>
                                            <div className='input-ffpercent'>
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        left: 8,
                                                        color: "#8c8c8c",
                                                        fontSize: 13,
                                                        top: "50%",
                                                        transform: "translateY(-50%)",
                                                        zIndex: 99999,
                                                    }}
                                                >
                                                    %
                                                </span>
                                                <input type="number"
                                                    value={this.state.localEventAmount}
                                                    onChange={(e) => {
                                                        if (Number(e.target.value) > 100 && this.state.localEventPercentage) {
                                                            this.setState({ localEventAmount: 100 })
                                                            return false;
                                                        }
                                                        this.setState({ localEventAmount: e.target.value })
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                                <div className="form-field-row">
                                    <div className={`form-field-clgec`}>
                                        <div className='wrapper-select-new'>
                                            <select
                                                value={this.state.campaignImagePreviewType}
                                                onChange={(e) => {
                                                    this.setState({
                                                        campaignImagePreviewType: e.target.value,
                                                        campaignImagePreview: [
                                                            "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8",
                                                        ],
                                                        dropFileCampaignImage: [],
                                                    });
                                                }}
                                                style={{ width: "100%" }}
                                            >
                                                <option value="uploadfromcomp">Eigen foto</option>
                                                <option value="takefromlibrary">
                                                    Bibliotheek
                                                </option>
                                            </select>
                                            <ExpandMore
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    right: 5,
                                                    bottom: 10,
                                                    fontSize: 18,
                                                    pointerEvents: "none",
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                    </div>
                                    {this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8" ? (<div className={`form-field-clgec ${this.state.kortingsVoucherDescAlert ? "redalertffcl" : ""}`}>
                                        <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                            <input />
                                            <div className="image-campaigns-new icn-newupdatedoneline">
                                                <div
                                                    className="image-sfcplp"
                                                    style={{
                                                        backgroundImage: `url("${this.state.campaignImagePreview[0]}")`,
                                                    }}
                                                >
                                                </div>
                                                {this.checkForDeleteKortingsVoucher()}
                                            </div>
                                        </div>
                                    </div>) : (<div className={`form-field-clgec`}>
                                        <div className='image-wrappwr-fwcoco'>
                                            <div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                {this.state.campaignImagePreviewType === "takefromlibrary" ? (<div className='wrapper-select-new'>
                                                    <input
                                                        type="text"
                                                        value="Selecteer foto"
                                                        readOnly
                                                        className="dolabelsuppercase"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            this.setState({ addStockImage: true })
                                                        }}
                                                    />
                                                    <AiOutlineSelect
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.localEventImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>) : (<div className='wrapper-select-new'>
                                                    <input
                                                        type="text"
                                                        value="Upload foto"
                                                        readOnly
                                                        className="dolabelsuppercase"
                                                    />
                                                    <input
                                                        type="file"
                                                        onChange={(e) => this.uploadImg(e)}
                                                        accept="image/*,video/*"
                                                        style={{
                                                            position: "absolute",
                                                            width: "100%",
                                                            height: "100%",
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            opacity: 0,
                                                        }}
                                                    />
                                                    <PublishIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.localEventImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>)}
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                                {this.state.campaignImagePreviewAlert && (
                                    <label style={{ color: "red", fontSize: 13, letterSpacing: 1, marginTop: 0 }}>
                                        Verplicht veld
                                    </label>
                                )}
                            </>)) : this.state.thirdStep ? (<>
                                <div className={`form-field-clgec ${this.state.kortingsVoucherTitleAlert ? "redalertffcl" : ""}`}>
                                    <input
                                        placeholder='Titel'
                                        value={this.state.kortingsVoucherTitle}
                                        onChange={(e) => {
                                            this.setState({ kortingsVoucherTitle: e.target.value })
                                        }}
                                    />
                                </div>
                                {this.state.currentFormType === "voucher" && (<div className={`form-field-percentage ${this.state.kortingsVoucherNormalPriceAlert ? "redalertffcl" : ""}`}>
                                    <label>Prijs p.p</label>
                                    <div className='input-ffpercent'>
                                        <span
                                            style={{
                                                position: "absolute",
                                                left: 8,
                                                color: "#8c8c8c",
                                                fontSize: 13,
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                zIndex: 99999,
                                            }}
                                        >
                                            €
                                        </span>
                                        <input
                                            type="number"
                                            value={this.state.kortingsVoucherNormalPrice}
                                            onChange={(e) => {
                                                this.setState({ kortingsVoucherNormalPrice: e.target.value })
                                            }}
                                        />
                                    </div>
                                </div>)}
                                {/* {this.state.currentFormType === "voucher" && (<div className={`form-field-percentage ${this.state.kortingsVoucherSalePriceAlert ? "redalertffcl" : ""}`}>
                                    <label>Actie prijs p.p</label>
                                    <div className='input-ffpercent'>
                                        <span
                                            style={{
                                                position: "absolute",
                                                left: 8,
                                                color: "#8c8c8c",
                                                fontSize: 13,
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                zIndex: 99999,
                                            }}
                                        >
                                            €
                                        </span>
                                        <input
                                            type="number"
                                            value={this.state.kortingsVoucherSalePrice}
                                            onChange={(e) => {
                                                this.setState({ kortingsVoucherSalePrice: e.target.value })
                                            }}
                                        />
                                    </div>
                                </div>)} */}
                                {this.state.currentFormType === "voucher-percentage" && (<div className={`form-field-percentage ${this.state.kortingsVoucherAmountAlert ? "redalertffcl" : ""}`}>
                                    <label>Vul de kortings in</label>
                                    <div className='input-ffpercent'>
                                        <span
                                            style={{
                                                position: "absolute",
                                                left: 8,
                                                color: "#8c8c8c",
                                                fontSize: 13,
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                zIndex: 99999,
                                            }}
                                        >
                                            %
                                        </span>
                                        <input type="number"
                                            value={this.state.kortingsVoucherAmount}
                                            onChange={(e) => {
                                                this.setState({ kortingsVoucherAmount: e.target.value })
                                            }} />
                                    </div>
                                </div>)}
                                <div className={`form-field-clgec ${this.state.kortingsVoucherDescAlert ? "redalertffcl" : ""}`}>
                                    <textarea
                                        placeholder='Omschrijving'
                                        value={this.state.kortingsVoucherDesc}
                                        onChange={(e) => {
                                            this.setState({ kortingsVoucherDesc: e.target.value })
                                        }}
                                    ></textarea>
                                </div>
                                {this.state.currentFormType === "voucher-percentage" && (<div className={`form-field-percentage ${this.state.kortingsVoucherAmountAlert ? "redalertffcl" : ""}`}>
                                    <label>Aantal kortingsvoucher</label>
                                    <div className='input-ffpercent'>
                                        <input type="number"
                                            value={this.state.kortingsVoucherNumber}
                                            onChange={(e) => {
                                                this.setState({ kortingsVoucherNumber: e.target.value })
                                            }} />
                                    </div>
                                </div>)}
                                <div className="form-field-row">
                                    <div className={`form-field-clgec`}>
                                        <div className='wrapper-select-new'>
                                            <select
                                                value={this.state.campaignImagePreviewType}
                                                onChange={(e) => {
                                                    this.setState({
                                                        campaignImagePreviewType: e.target.value,
                                                        campaignImagePreview: [
                                                            "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8",
                                                        ],
                                                        dropFileCampaignImage: [],
                                                    });
                                                }}
                                                style={{ width: "100%" }}
                                            >
                                                <option value="uploadfromcomp">Eigen foto</option>
                                                <option value="takefromlibrary">
                                                    Bibliotheek
                                                </option>
                                            </select>
                                            <ExpandMore
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    right: 5,
                                                    bottom: 10,
                                                    fontSize: 18,
                                                    pointerEvents: "none",
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                    </div>
                                    {this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8" ? (<div className={`form-field-clgec ${this.state.kortingsVoucherDescAlert ? "redalertffcl" : ""}`}>
                                        <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                            <input />
                                            <div className="image-campaigns-new icn-newupdatedoneline">
                                                <div
                                                    className="image-sfcplp"
                                                    style={{
                                                        backgroundImage: `url("${this.state.campaignImagePreview[0]}")`,
                                                    }}
                                                >
                                                </div>
                                                {this.checkForDeleteKortingsVoucher()}
                                            </div>
                                        </div>
                                    </div>) : (<div className={`form-field-clgec`}>
                                        <div className='image-wrappwr-fwcoco'>
                                            <div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                {this.state.campaignImagePreviewType === "takefromlibrary" ? (<div className='wrapper-select-new'>
                                                    <input
                                                        type="text"
                                                        value="Selecteer foto"
                                                        readOnly
                                                        className="dolabelsuppercase"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            this.setState({ addStockImage: true })
                                                        }}
                                                    />
                                                    <AiOutlineSelect
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.localEventImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>) : (<div className='wrapper-select-new'>
                                                    <input
                                                        type="text"
                                                        value="Upload foto"
                                                        readOnly
                                                        className="dolabelsuppercase"
                                                    />
                                                    <input
                                                        type="file"
                                                        onChange={(e) => this.uploadImg(e)}
                                                        accept="image/*,video/*"
                                                        style={{
                                                            position: "absolute",
                                                            width: "100%",
                                                            height: "100%",
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            opacity: 0,
                                                        }}
                                                    />
                                                    <PublishIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.localEventImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>)}
                                            </div>
                                        </div>
                                    </div>)}
                                    {/* <div className={`form-field-clgec ${this.state.kortingsVoucherDescAlert ? "redalertffcl" : ""}`}>
                                        <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                            <input />
                                            {this.state.campaignImagePreview[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8" && (<div className="image-campaigns-new icn-newupdatedoneline">
                                                <div
                                                    className="image-sfcplp"
                                                    style={{
                                                        backgroundImage: `url("${this.state.campaignImagePreview[0]}")`,
                                                    }}
                                                >
                                                </div>
                                                {this.checkForDeleteKortingsVoucher()}
                                            </div>)}
                                        </div>
                                    </div> */}
                                </div>
                                {this.state.campaignImagePreviewAlert && (
                                    <label style={{ color: "red", fontSize: 13, letterSpacing: 1, marginTop: 0 }}>
                                        Verplicht veld
                                    </label>
                                )}
                            </>)
                                // : this.state.secondStep ? (<>
                                //     <div className={`form-field-clgec ${this.state.bankAccountNumberEmpty ? "redalertffcl" : ""}`}>
                                //         <input
                                //             placeholder='Bankrekeningnummer'
                                //             value={this.state.bankAccountNumber}
                                //             type='text'
                                //             onChange={(e) => {
                                //                 this.setState({ bankAccountNumber: e.target.value })
                                //             }}
                                //         />
                                //     </div>
                                //     <div className={`form-field-clgec ${this.state.bankNameEmpty ? "redalertffcl" : ""}`}>
                                //         <input
                                //             placeholder='Naam rekeninghouder'
                                //             value={this.state.bankName}
                                //             type='text'
                                //             onChange={(e) => {
                                //                 this.setState({ bankName: e.target.value })
                                //             }}
                                //         />
                                //     </div>
                                // </>)
                                : (<>
                                    <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                        <input
                                            placeholder='E-mail'
                                            value={this.state.email}
                                            onChange={(e) => {
                                                this.setState({ email: e.target.value })
                                            }}
                                        />
                                    </div>
                                    <div className={`form-field-clgec ${this.state.passInvalid ? "redalertffcl" : ""}`}>
                                        <input
                                            placeholder='Kies wachtwoord'
                                            type='password'
                                            value={this.state.password}
                                            onChange={(e) => {
                                                this.setState({ password: e.target.value })
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="fx-registration-inner"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                            <div
                                                className="left-frvn"
                                                style={{
                                                    width: "100%",
                                                    paddingLeft: 10,
                                                    backgroundColor: "transparent",
                                                }}
                                            >
                                                <div className="checking-box">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.agreed}
                                                        onChange={() =>
                                                            this.setState({ agreed: !this.state.agreed })
                                                        }
                                                    />
                                                    <label
                                                        style={{
                                                            color: this.state.agreedPopup ? "red" : "black",
                                                            fontSize: 13,
                                                        }}
                                                    >
                                                        <font
                                                            style={{
                                                                textDecorationLine: "underline",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                window.open(
                                                                    "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                    "_blank" // <- This is what makes it open in a new window.
                                                                );
                                                            }}
                                                        >
                                                            Akkoord met voorwaarden
                                                        </font>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                            {/* {window.innerWidth < 1008 && (<div className="steps-container-main">
                                <div className={`scm-inner-steps ${!this.state.thirdStep && !this.state.secondStep && "active"}`}>

                                </div>
                                <div className={`scm-inner-steps ${this.state.secondStep && "active"}`}>

                                </div>
                                <div className={`scm-inner-steps ${this.state.thirdStep && "active"}`}>

                                </div>
                                {this.state.thirdStep ? (<label>3/3</label>) : this.state.secondStep ? (<label>2/3</label>) : (<label>1/3</label>)}
                            </div>)} */}
                            <div className="bottom-buttons-clgec">
                                {/* {window.innerWidth < 1008 && !this.state.aanmeldenloader && (
                                    <button onClick={() => history.goBack()}>
                                        {" "}
                                        <ChevronLeftIcon />{" "}
                                    </button>
                                )} */}
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            if (this.state.thirdStep) {
                                                this.createCompanyAndCampaign()
                                            } else if (this.state.secondStep) {
                                                this.moveToThirdStepDirect()
                                            } else {
                                                this.moveToSecondStepDirect()
                                            }
                                        }}
                                    >
                                        {this.state.thirdStep ? "Afronden" : "Volgende"}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                <div className="steps-container-main">
                                    <div className={`scm-inner-steps ${!this.state.thirdStep && !this.state.secondStep && "active"}`}>

                                    </div>
                                    {/* <div className={`scm-inner-steps ${this.state.secondStep && "active"}`}>

                                    </div> */}
                                    <div className={`scm-inner-steps ${this.state.thirdStep && "active"}`}>

                                    </div>
                                    {this.state.thirdStep ? (<label>2/2</label>) : (<label>1/2</label>)}
                                    {/* {this.state.thirdStep ? (<label>3/3</label>) : this.state.secondStep ? (<label>2/3</label>) : (<label>1/3</label>)} */}
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec" style={{ backgroundImage: this.state.currentFormTypeDefault === "voucher" && `url("${voucherFormImage}")` }}>

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        <div className="popup-opener-stogec pos-inner-img">
                            {(this.state.secondStep || this.state.thirdStep) && (<div className="button-back-lgec" onClick={() => {
                                if (this.state.thirdStep) {
                                    this.setState({ thirdStep: false, secondStep: false })
                                } else if (this.state.secondStep) {
                                    this.setState({ secondStep: false })
                                } else {
                                    history.goBack()
                                }
                            }}>
                                <ChevronLeftIcon />
                            </div>)}
                            <img src={logoSubAdmin} alt='logo' width={50} />
                        </div>
                    </div>
                )}


                {this.state.addStockImage && (<div className="separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final">
                    <div
                        className="rightmenuaddproduct rightmenucompletewidthaddproduct"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className="overlay-rmadfofw"></div>

                        <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenuaddproductstockselection rightmenuproductstockselectionupdated updatedrightmenustock">
                            <div
                                className="mid-rmap"
                                style={{ position: "relative", padding: 13 }}
                            >
                                <span>SELECTEER AFBEELDING</span>
                                <ChevronLeftIcon
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        left: 20,
                                        fontSize: 18,
                                    }}
                                    onClick={() => this.setState({ addStockImage: false })}
                                />
                            </div>
                            <div className="bottom-rmap">
                                <div className="mid-img-uploading-rmap">
                                    <div
                                        className="form-miur"
                                        style={{ marginBottom: 30, position: "relative" }}
                                    >
                                        <label>TYPE BEDRIJF</label>
                                        <div className='wrapper-select-new'>
                                            <select
                                                defaultValue="Selecteer"
                                                value={this.state.mainSectorStock}
                                                onChange={(e) => {
                                                    if (e.target.value !== "Selecteer") {
                                                        if (this.state.allImagesStock.length > 0) {
                                                            let filteredSecond =
                                                                this.state.allImagesStock.filter((u) => {
                                                                    return u[0] === e.target.value;
                                                                });
                                                            if (filteredSecond.length > 0) {
                                                                let a = filteredSecond[0];
                                                                let arr = [];
                                                                if (a.length > 0) {
                                                                    let objected = Object.entries(a[1]);
                                                                    objected.map((y) => {
                                                                        arr.push(y[1]);
                                                                    });
                                                                    this.setState({
                                                                        allImagesCurrentSectorStock: arr,
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        allImagesCurrentSectorStock: [],
                                                                    });
                                                                }
                                                            }
                                                        }
                                                        this.setState({ mainSectorStock: e.target.value });
                                                    }
                                                }}
                                            >
                                                {!this.state.mainSector && <option>Selecteer</option>}
                                                {this.state.allSectors.map((g) => {
                                                    return (
                                                        <option value={g.sector_id}>{g.sector_name}</option>
                                                    );
                                                })}
                                            </select>
                                            <ExpandMore
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    right: 5,
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                    </div>
                                    {this.state.allImagesCurrentSectorStock.length > 0 && (
                                        <div className="imagesstockselectionrightmenu">
                                            {this.state.allImagesCurrentSectorStock.map((y) => {
                                                return (
                                                    <div
                                                        className="imagecontainerstockuploader"
                                                        style={{
                                                            backgroundImage: `url("${y.resizedUrl}")`,
                                                        }}
                                                        onClick={() => {
                                                            this.state.campaignImagePreview = [y.snapUrl];
                                                            if (window.innerWidth < 1008) {
                                                                this.setState({ addStockImage: false });
                                                            }
                                                            this.setState({
                                                                campaignImagePreview: this.state.campaignImagePreview,
                                                                dropFileCampaignImage: [],
                                                            });
                                                        }}
                                                    >
                                                        {(this.state.campaignImagePreview.length > 0 && this.state.campaignImagePreview[0] === y.snapUrl) && <div className="overlay-icontstup">
                                                            <CheckCircle />
                                                        </div>}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}

                {this.state.moreTimingsPopup && (<div className="separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final">
                    <div
                        className="rightmenuaddproduct rightmenucompletewidthaddproduct"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {this.state.moreTimingsPopup && (
                            <div className="overlay-rmadfofw"></div>
                        )}
                        {this.state.moreTimingsPopup && (
                            <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategorynew newcategoriesrightmenu">
                                <div
                                    className="mid-rmap"
                                    style={{ position: "relative", padding: 13 }}
                                >
                                    <span>TIJDSLOT INSTELLINGEN</span>
                                    <div className="top-header-infocont">
                                        <div style={{ position: "relative" }}>
                                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                this.setState({ categoryInfoOpen: !this.state.categoryInfoOpen })
                                            }}>
                                                <font>?</font>
                                            </div>
                                            <div className="popup-gri-thifc" style={{ opacity: this.state.categoryInfoOpen ? 1 : 0, pointerEvents: this.state.categoryInfoOpen ? "all" : "none" }}>
                                                <CloseIcon onClick={() => this.setState({ categoryInfoOpen: false })} />
                                                <label style={{ fontSize: 15 }}>
                                                    Selecteer de juiste categorie(ën)
                                                    <br />
                                                    waaronder je actie wordt getoond.
                                                </label>
                                            </div>
                                        </div>
                                        <ChevronLeftIcon
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: 20,
                                                fontSize: 18,
                                            }}
                                            onClick={() => this.setState({ moreTimingsPopup: false, moreTimingsPopup: false })}
                                        />
                                    </div>
                                </div>
                                <div className="bottom-rmap">
                                    <div className="mid-img-uploading-rmap miur-multipletimes">

                                        {this.state.arrEvents.length > 0 && this.state.arrEvents.map((timing, index) => {
                                            if (index === 0) {
                                                return (<div
                                                    className="form-miur formfullwidthftcic form-inner-for-datetime"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>Tijd {index + 1}</label>
                                                    <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                        <label>Starttijd van het event</label>
                                                        <div className="right-fifd">
                                                            <div className="datinput-fifd">
                                                                <BiCalendar />
                                                                {this.state.arrEvents[0].startDateEvent ? (
                                                                    <label>
                                                                        {this.getFormattedDate(this.state.arrEvents[0].startDateEvent)}
                                                                    </label>
                                                                ) : (
                                                                    <label>Datum</label>
                                                                )}
                                                                <input
                                                                    type="date"
                                                                    min={this.state.todayDateToBeMax}
                                                                    max={this.state.arrEvents[0].endDateEvent}
                                                                    value={this.state.arrEvents[0].startDateEvent}
                                                                    onChange={(e) => {
                                                                        this.state.arrEvents.map((g) => {
                                                                            g.startDateEvent = e.target.value
                                                                        })
                                                                        this.setState({
                                                                            arrEvents: this.state.arrEvents,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="select-fifd">
                                                                <MdTimer />
                                                                <select
                                                                    value={this.state.arrEvents[0].startTimeEvent}
                                                                    onChange={(e) => {
                                                                        this.state.arrEvents[0].startTimeEvent =
                                                                            e.target.value;
                                                                        this.setState({
                                                                            arrEvents: this.state.arrEvents,
                                                                        });
                                                                    }}
                                                                >
                                                                    <option disabled selected hidden value="">
                                                                        Tijd
                                                                    </option>
                                                                    {this.state.allTimes.map((y) => {
                                                                        return (
                                                                            <option value={y.label}>
                                                                                {y.label}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                        <label>Eindtijd van het event</label>
                                                        <div className="right-fifd">
                                                            <div className="datinput-fifd">
                                                                <BiCalendar />
                                                                {this.state.arrEvents[0].endDateEvent ? (
                                                                    <label>
                                                                        {this.getFormattedDate(this.state.arrEvents[0].endDateEvent)}
                                                                    </label>
                                                                ) : (
                                                                    <label>Datum</label>
                                                                )}
                                                                <input
                                                                    type="date"
                                                                    min={this.state.arrEvents[0].startDateEvent}
                                                                    value={this.state.arrEvents[0].endDateEvent}
                                                                    onChange={(e) => {
                                                                        this.state.arrEvents.map((g) => {
                                                                            g.endDateEvent = e.target.value
                                                                        })
                                                                        this.setState({
                                                                            arrEvents: this.state.arrEvents,
                                                                        });
                                                                        // this.setState({ endDateEvent: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="select-fifd">
                                                                <MdTimer />
                                                                <select
                                                                    value={this.state.arrEvents[0].endTimeEvent}
                                                                    onChange={(e) => {
                                                                        this.state.arrEvents[0].endTimeEvent =
                                                                            e.target.value;
                                                                        this.setState({
                                                                            arrEvents: this.state.arrEvents,
                                                                        });
                                                                        // this.setState({ endTimeEvent: e.target.value })
                                                                    }}
                                                                >
                                                                    <option disabled selected hidden value="">
                                                                        Tijd
                                                                    </option>
                                                                    {this.state.allTimes.map((y) => {
                                                                        if (this.state.arrEvents[0].startTimeEvent) {
                                                                            if (this.state.arrEvents[0].endDateEvent) {
                                                                                let currentDate = new Date(
                                                                                    this.state.arrEvents[0].endDateEvent
                                                                                );
                                                                                currentDate.setHours(0, 0, 0, 0);
                                                                                let currentDateStart = new Date(
                                                                                    this.state.arrEvents[0].startDateEvent
                                                                                );
                                                                                currentDateStart.setHours(0, 0, 0, 0);
                                                                                if (currentDate.getTime() === currentDateStart.getTime()) {
                                                                                    let splittedFirst = y.label.split(":")[0];
                                                                                    let splittedMinutesFirst = y.label.split(":")[1];
                                                                                    let splittedSecond = this.state.arrEvents[0].startTimeEvent.split(":")[0];
                                                                                    let splittedMinutesSecond = this.state.arrEvents[0].startTimeEvent.split(":")[1];
                                                                                    if (Number(splittedSecond) < Number(splittedFirst)) {
                                                                                        return (
                                                                                            <option value={y.label}>
                                                                                                {y.label}
                                                                                            </option>
                                                                                        );
                                                                                    } else if (Number(splittedSecond) === Number(splittedFirst)) {
                                                                                        if (Number(splittedMinutesSecond) < Number(splittedMinutesFirst)) {
                                                                                            return (
                                                                                                <option value={y.label}>
                                                                                                    {y.label}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    return (
                                                                                        <option value={y.label}>
                                                                                            {y.label}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <option value={y.label}>
                                                                                        {y.label}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        } else {
                                                                            return (
                                                                                <option value={y.label}>
                                                                                    {y.label}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-miur formfullwidthftcic" style={{ paddingTop: 15 }}>
                                                        <label>Aantal</label>
                                                        <input
                                                            defaultValue={this.state.arrEvents[index].personsAmountEvent}
                                                            type="number"
                                                            onBlur={(e) => {
                                                                let arrEvents = [...this.state.arrEvents];

                                                                arrEvents[index].personsAmountEvent = Number(e.target.value).toString();
                                                                this.setState({ arrEvents });
                                                            }}
                                                        />
                                                    </div>
                                                </div>)
                                            }
                                            return (<div
                                                className="form-miur formfullwidthftcic form-inner-for-datetime form-inner-widthfull"
                                                style={{ position: "relative" }}
                                            >
                                                <label>Tijd {index + 1}</label>
                                                <div className="inner-top-main">
                                                    <label>Starttijd van het event</label>
                                                    <div className="right-fifd">
                                                        <div className="select-fifd">
                                                            <MdTimer />
                                                            <select
                                                                value={this.state.arrEvents[index].startTimeEvent}
                                                                onChange={(e) => {
                                                                    this.state.arrEvents[index].startTimeEvent = e.target.value;

                                                                    this.setState({
                                                                        arrEvents: this.state.arrEvents,
                                                                    });
                                                                }}
                                                            >
                                                                <option disabled selected hidden value="">
                                                                    Tijd
                                                                </option>
                                                                {[...this.state.allTimes].filter(time => time.label > this.state.arrEvents[0].startTimeEvent && time.label < this.state.arrEvents[0].endTimeEvent).map((y) => {
                                                                    return (
                                                                        <option value={y.label}>
                                                                            {y.label}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                    <label>Eindtijd van het event</label>
                                                    <div className="right-fifd">
                                                        <div className="select-fifd">
                                                            <MdTimer />
                                                            <select
                                                                value={this.state.arrEvents[index].endTimeEvent}
                                                                onChange={(e) => {
                                                                    this.state.arrEvents[index].endTimeEvent = e.target.value;
                                                                    this.setState({
                                                                        arrEvents: this.state.arrEvents,
                                                                    });
                                                                    // this.setState({ endTimeEvent: e.target.value })
                                                                }}
                                                            >
                                                                <option disabled selected hidden value="">
                                                                    Tijd
                                                                </option>
                                                                {[...this.state.allTimes].filter(time => time.label > this.state.arrEvents[index].startTimeEvent && time.label < this.state.arrEvents[0].endTimeEvent).map((y) => {
                                                                    return (
                                                                        <option value={y.label}>
                                                                            {y.label}
                                                                        </option>
                                                                    );

                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-miur formfullwidthftcic" style={{ paddingTop: 15 }}>
                                                    <label>Aantal</label>
                                                    <input
                                                        defaultValue={this.state.arrEvents[index].personsAmountEvent}
                                                        type="number"
                                                        onBlur={(e) => {
                                                            let arrEvents = [...this.state.arrEvents];

                                                            arrEvents[index].personsAmountEvent = Number(e.target.value).toString();
                                                            this.setState({ arrEvents });
                                                        }}
                                                    />
                                                </div>
                                            </div>)
                                        })}
                                        {this.state.multipleTimesOpened && (<div className="add-timings" style={{ marginBottom: 20 }} onClick={() => {
                                            let obj = {
                                                startDateEvent: this.state.arrEvents[0].startDateEvent,
                                                startTimeEvent: "",
                                                endDateEvent: this.state.arrEvents[0].endDateEvent,
                                                endTimeEvent: "",
                                                personsAmountEvent: "",
                                                eventId: this.state.arrEvents.length + 1,
                                            }

                                            let allEvents = [...this.state.arrEvents]
                                            allEvents.push(obj)
                                            this.setState({ arrEvents: allEvents })
                                        }}>
                                            <AddCircleOutlineIcon />
                                            <label>Add Timing</label>
                                        </div>)}

                                        <div style={{ width: "100%", height: 15 }}>
                                        </div>
                                        {(this.state.arrEvents[0].startDateEvent && this.state.arrEvents[0].endDateEvent) &&
                                            new Date(this.state.arrEvents[0].startDateEvent).getDate() === new Date(this.state.arrEvents[0].endDateEvent).getDate() &&
                                            new Date(this.state.arrEvents[0].startDateEvent).getMonth() === new Date(this.state.arrEvents[0].endDateEvent).getMonth() &&
                                            new Date(this.state.arrEvents[0].startDateEvent).getFullYear() === new Date(this.state.arrEvents[0].endDateEvent).getFullYear() &&
                                            (<div className="savebtnwork" style={{ width: "100%", position: "static" }}>
                                                <div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Tijdslot instellingen
                                                    </label>
                                                </div>
                                                <button>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.multipleTimesOpened}
                                                        onClick={() => {
                                                            if (this.state.multipleTimesOpened) {
                                                                let allEvents = [...this.state.arrEvents]
                                                                allEvents.splice(1)
                                                                this.setState({
                                                                    arrEvents: allEvents,
                                                                })
                                                            }
                                                            this.setState({
                                                                multipleTimesOpened: !this.state.multipleTimesOpened,
                                                            });
                                                        }}
                                                    />
                                                    {this.state.multipleTimesOpened ? (
                                                        <label>Actief</label>
                                                    ) : (
                                                        <label>Niet Actief</label>
                                                    )}
                                                </button>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>)}
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
        campaigns_category: state.categoriesReducer.campaigns_category,
        categories_vouchers: state.categoriesReducer.categories_vouchers,
        stock_images: state.globalReducers.stock_images,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
        updateCategoriesVouchers: (categories_vouchers) => dispatch(updateCategoriesVouchers(categories_vouchers)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySubscribeViaLink);