import React, { Component } from "react";
import firebase from "../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import {
  updateCurrentMoments,
  updateAlbumToAdd,
  updateMoreAlbumsAdded,
  updateSelectedExclusiveFriends,
  updateSelectedExclusive,
  updateCurrentMomentsFriendsPage,
  updateAlbumToAddFriends,
  updateMoreAlbumsAddedFriends,
  updateSignUpFieldsFilled,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateBhodiFaqs,
  updateBhodiAbouts,
} from "../Redux/actions/authActions";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import RemoveIcon from "@material-ui/icons/Remove";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FooterGreen from "./FooterGreen";
import RightMenuOrders from "./RightMenuOrders";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MySubcribedExclusives from "./MySubcribedExclusives";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
const axios = require("axios");

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class SettingsSeparateUsers extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      search: false,
      searchText: "",
      rightModalSettings: false,
      arrForSearch: [],
      checkedPrivate: false,
      checkedHideDonation: false,
      checkedHideFriendsInfo: false,
      checkedHideProfileImage: false,
      checkedHideProfileInfo: false,
      showPostsActive: true,
      showItemsDiv: false,
      showItems: false,
      showLists: true,
      showFriends: false,
      showProductsOwn: false,
      showFriendstab: false,
      showProductsBookmarked: false,
      showBookmarkTab: false,
      showProductsOthertab: false,
      userNameInput: false,
      userDescInput: false,
      lengthOfOtherProducts: [],
      lengthOfOtherVouchers: [],
      lengthOfOtherCampaigns: [],
      userInfoRight: true,
      typeOfOrder: "incoming",
      showCartOrder: true,
      savtBtnText: "SAVE",
      isDesktop: true,
      isScrolled: false,
      menuItemsModals: true,
      hideMenuContent: false,
      lengthOfCurrentContents: [],
      lengthOfOtherContents: [],
      showContentTab: false,
      currentUserMember: "",
      bhodiFaqs: [],
      arrDropdowns: [],
      bhodiAbouts: [],
      arrDropdownsAbouts: [],
      currentUserOfPopups: "",
      redeem_code: "",
      guestUserCart: [],
      deleteBtnText: "SAVE",
      showDelete: false,
      password: "",
      showPassword: false,
      confirmPassword: "",
      showconfirmPassword: false,
      currentPassword: "",
      showcurrentPassword: false,
      profileActive: true,
      passwordForDel: "",
    };
  }
  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let userid = localStorage.getItem("userid");
    this.setState({ currentUserId: userid });

    this.setState({ rightModalSettings: true });
    if (window.innerWidth < 1009) {
      document.getElementById("root").style.position = "fixed";
      document.getElementById("root").style.overflow = "hidden";
      this.setState({ menuItemsModals: true });
    }

    let settings_id = "";

    if (this.props.fromMainPage) {
      settings_id = this.props.type;
    } else {
      settings_id = this.props.match.params.settings_id;
    }

    if (settings_id === "mijn_gegevens") {
      this.setState({
        userInfoRight: true,
        privacyRight: false,
        emailPassword: false,
        shopSettings: false,
        myOrdersRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        showMijnAbonnementen: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "privacy") {
      this.setState({
        privacyRight: true,
        userInfoRight: false,
        emailPassword: false,
        shopSettings: false,
        myOrdersRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        showSubscribedContent: false,
        showMijnAbonnementen: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "inloggegevens") {
      this.setState({
        emailPassword: true,
        privacyRight: false,
        userInfoRight: false,
        shopSettings: false,
        myOrdersRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        showMijnAbonnementen: false,
        voucherSettings: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "my_orders") {
      this.setState({
        myOrdersRight: true,
        shopSettings: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        showMijnAbonnementen: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "faqs") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        showBhodiFaqs: true,
        showSubscribedContent: false,
        showBhodiAbouts: false,
        showMijnAbonnementen: false,
      });
    } else if (settings_id === "abouts") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        showBhodiAbouts: true,
        showBhodiFaqs: false,
        showSubscribedContent: false,
        showMijnAbonnementen: false,
      });
    } else if (settings_id === "mijn_abonnementen") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        showBhodiAbouts: false,
        showMijnAbonnementen: true,
        showBhodiFaqs: false,
        showSubscribedContent: false,
      });
    }

    if (this.props.bhodi_faqs) {
      let a = this.props.bhodi_faqs;
      if (a) {
        let arrSec = a;
        let arrDropdowns = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdowns.push(obj);
        });
        this.setState({ bhodiFaqs: arrSec, arrDropdowns });
      } else {
        this.setState({ bhodiFaqs: [], arrDropdowns: [] });
      }
    }
    if (this.props.bhodi_abouts) {
      let a = this.props.bhodi_abouts;
      if (a) {
        let arrSec = a;
        let arrDropdownsAbouts = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdownsAbouts.push(obj);
        });
        this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
      } else {
        this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
      }
    }

    firebase
      .database()
      .ref("bhodi_faqs")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdowns = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdowns.push(obj);
          });
          this.setState({ bhodiFaqs: arrSec, arrDropdowns });
        } else {
          this.setState({ bhodiFaqs: [], arrDropdowns: [] });
        }
        this.props.updateBhodiFaqs(arrSec)
      });

    firebase
      .database()
      .ref("bhodi_abouts_user")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdownsAbouts = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdownsAbouts.push(obj);
          });
          this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
        } else {
          this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
        }
        this.props.updateBhodiAbouts(arrSec)
      });

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filter = this.props.user.filter((u) => {
          return u.user_id === userid;
        });
        let currentData = filter[0];
        if (filter.length > 0) {
          if (currentData) {
            let a = currentData;
            if (a.friends) {
              let objected = Object.entries(a.friends);
              a.objectedFriends = objected;
            } else {
              a.objectedFriends = [];
            }
            if (!a.profile_image) {
              a.profile_image =
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
            this.setState({
              currentUserMember: a,
              create_subscription: a.create_subscription,
              userid: userid,
              url: a.user_name_id,
              firstName: a.first_name,
              lastName: a.last_name,
              city: a.city,
              zipCode: a.zipCode,
              street: a.street,
              houseNumber: a.houseNumber,
              country: a.country,
              phoneNumber: a.phoneNumber,
              email: a.email,
              profileImageUrl: a.profile_image,
              description: a.user_description,
            });
            if (a.profileActive) {
              this.setState({ profileActive: true })
            } else {
              this.setState({ profileActive: false })
            }
            if (typeof a.hideDonation === "boolean") {
              this.setState({
                checkedHideDonation: a.hideDonation,
                checkedHideFriendsInfo: a.hideFriendsInfo,
                checkedHideProfileImage: a.hideProfileImage,
                checkedHideProfileInfo: a.hideProfileInfo,
              });
            }
            if (a.accountType === "global") {
              this.setState({ checkedPrivate: false });
            } else {
              this.setState({ checkedPrivate: true });
            }
            if (a.subscription) {
              if (a.subscription.status === "active") {
                this.setState({ showDelete: false });
              } else {
                this.setState({ showDelete: true });
              }
            } else {
              if (checkForSubscribedExclusives(a)) {
                this.setState({ showDelete: false })
              } else {
                this.setState({ showDelete: true });
              }
            }
          }
        }
      }
    }

    if (window.innerWidth > 1009) {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: true,
      });
    } else {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: false,
      });
      window.onscroll = () => {
        if (window.scrollY === 0) {
          this.setState({ isScrolled: false });
        } else {
          this.setState({ isScrolled: true });
        }
      };
    }

    firebase
      .database()
      .ref("users/" + userid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          if (a.friends) {
            let objected = Object.entries(a.friends);
            a.objectedFriends = objected;
          } else {
            a.objectedFriends = [];
          }
          if (!a.profile_image) {
            a.profile_image =
              "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
          }

          // localStorage.setItem("userdata", JSON.stringify(data.val()))
          this.setState({
            currentUserMember: a,
            create_subscription: a.create_subscription,
            userid: userid,
            url: a.user_name_id,
            firstName: a.first_name,
            lastName: a.last_name,
            city: a.city,
            zipCode: a.zipCode,
            street: a.street,
            houseNumber: a.houseNumber,
            country: a.country,
            phoneNumber: a.phoneNumber,
            email: a.email,
            profileImageUrl: a.profile_image,
            description: a.user_description,
          });
          if (a.profileActive) {
            this.setState({ profileActive: true })
          } else {
            this.setState({ profileActive: false })
          }
          if (typeof a.hideDonation === "boolean") {
            this.setState({
              checkedHideDonation: a.hideDonation,
              checkedHideFriendsInfo: a.hideFriendsInfo,
              checkedHideProfileImage: a.hideProfileImage,
              checkedHideProfileInfo: a.hideProfileInfo,
            });
          }
          if (a.accountType === "global") {
            this.setState({ checkedPrivate: false });
          } else {
            this.setState({ checkedPrivate: true });
          }
          if (a.subscription) {
            if (a.subscription.status === "active") {
              this.setState({ showDelete: false });
            } else {
              this.setState({ showDelete: true });
            }
          } else {
            if (checkForSubscribedExclusives(a)) {
              this.setState({ showDelete: false })
            } else {
              this.setState({ showDelete: true });
            }
          }
        }
      });
  }

  saveUrl() {
    const { url } = this.state;
    let userid = localStorage.getItem("userid");
    if (url) {
      firebase
        .database()
        .ref("users/" + userid + "/user_name_id")
        .set(url)
        .then(() => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              localStorage.setItem("userid", "");
              window.location.href = "/login";
            });
        });
    } else {
      console.log("fill some thing");
    }
    let element = document.getElementById("footermain");
    if (element) {
      element.style.display = "flex";
    }
    this.setState({ rightModalSettings: false, profileRight: false });
  }

  saveInfo(type) {
    this.setState({ savtBtnText: "SAVING.." });
    const {
      firstName,
      lastName,
      street,
      city,
      zipCode,
      houseNumber,
      country,
      phoneNumber,
      profileActive
    } = this.state;
    const { first_name, last_name } = this.state.currentUserMember;
    let userid = localStorage.getItem("userid");
    if (firstName) {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set(firstName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set("");
    }
    if (lastName) {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set(lastName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set("");
    }
    if (street) {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set(street);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set("");
    }
    if (city) {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set(city);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set("");
    }
    if (zipCode) {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set(zipCode);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set("");
    }
    if (houseNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set(houseNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set("");
    }
    if (country) {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set(country);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set("");
    }
    if (phoneNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set(phoneNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set("");
    }
    if (profileActive) {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(true);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(false);
    }
    if (
      (first_name !== firstName || last_name !== lastName) && this.state.currentUserMember.stripe_id
    ) {
      axios
        .post(
          "https://us-central1-bhodi-7c327.cloudfunctions.net/updateStripeCustomer",
          {
            name: firstName + "" + lastName,
            email: this.state.currentUserMember.email,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then((response) => {
          if (type === "goback") {
            if (this.state.closeOnlyCurrent) {
              this.props.closeOnlyCurrent();
            }
            if (this.state.closeIncludingRightMenu) {
              this.props.closeIncludingRightMenu();
            }
          } else {
            if (this.props.fromMainPage) {
              this.props.closeIncludingRightMenu();
            } else {
              window.history.back();
            }
            // this.setState({ savtBtnText: "SAVE" });
          }
        });
    } else {
      setTimeout(() => {
        if (type === "goback") {
          // localStorage.setItem("mustShowOpenRightMenu", true)
          // setTimeout(() => {
          //     window.history.back()
          // }, 100);
          if (this.state.closeOnlyCurrent) {
            this.props.closeOnlyCurrent();
          }
          if (this.state.closeIncludingRightMenu) {
            this.props.closeIncludingRightMenu();
          }
        } else {
          if (this.props.fromMainPage) {
            this.props.closeIncludingRightMenu();
          } else {
            window.history.back();
          }
          // this.setState({ savtBtnText: "SAVE" });
        }
      }, 3000);
    }
    // this.setState({ rightModalSettings: false, userInfoRight: false })
  }

  savePrivacy() {
    this.setState({ savtBtnText: "SAVING.." });
    if (this.state.checkedPrivate) {
      firebase
        .database()
        .ref("users/" + this.state.userid + "/accountType")
        .set("private");
    } else {
      firebase
        .database()
        .ref("users/" + this.state.userid + "/accountType")
        .set("global");
    }
    setTimeout(() => {
      if (this.props.fromMainPage) {
        this.props.closeIncludingRightMenu();
      } else {
        window.history.back();
      }
    }, 3000);
    // if (this.state.checkedHideDonation) {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideDonation")
    //     .set(true);
    // } else {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideDonation")
    //     .set(false);
    // }
    // if (this.state.checkedHideProfileInfo) {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideProfileInfo")
    //     .set(true);
    // } else {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideProfileInfo")
    //     .set(false);
    // }
    // if (this.state.checkedHideProfileImage) {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideProfileImage")
    //     .set(true);
    // } else {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideProfileImage")
    //     .set(false);
    // }
    // if (this.state.checkedHideFriendsInfo) {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideFriendsInfo")
    //     .set(true)
    //     .then(() => {
    //       if (type === "goback") {
    //         if (this.state.closeOnlyCurrent) {
    //           this.props.closeOnlyCurrent();
    //         }
    //         if (this.state.closeIncludingRightMenu) {
    //           this.props.closeIncludingRightMenu();
    //         }
    //       } else {
    //         if (this.props.fromMainPage) {
    //           this.props.closeIncludingRightMenu();
    //         } else {
    //           window.history.back();
    //         }
    //         // this.setState({ savtBtnText: "SAVE" });
    //       }
    //     });
    // } else {
    //   firebase
    //     .database()
    //     .ref("users/" + this.state.userid + "/hideFriendsInfo")
    //     .set(false)
    //     .then(() => {
    //       if (type === "goback") {
    //         if (this.state.closeOnlyCurrent) {
    //           this.props.closeOnlyCurrent();
    //         }
    //         if (this.state.closeIncludingRightMenu) {
    //           this.props.closeIncludingRightMenu();
    //         }
    //       } else {
    //         if (this.props.fromMainPage) {
    //           this.props.closeIncludingRightMenu();
    //         } else {
    //           window.history.back();
    //         }
    //         // this.setState({ savtBtnText: "SAVE" });
    //       }
    //     });
    // }
    // this.setState({ rightModalSettings: false, privacyRight: false })
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.setItem("userid", "");
        window.location.href = "/";
      });
  }

  saveEmailPassword(type) {
    const { email, password, confirmPassword, url, currentPassword } =
      this.state;

    let filteredEmail = [];
    let filteredURL = [];

    if (email !== this.state.currentUserMember.email) {
      filteredEmail = this.props.user.filter((h) => {
        return h.email === email;
      });
    }

    if (url !== this.state.currentUserMember.user_name_id) {
      filteredURL = this.props.user.filter((h) => {
        return h.user_name_id === url;
      });
    }

    if (filteredEmail.length === 0 && filteredURL.length === 0) {
      this.setState({ savtBtnText: "SAVING.." });

      let userid = localStorage.getItem("userid");
      if (url && url !== this.state.currentUserMember.user_name_id) {
        firebase
          .database()
          .ref("users/" + userid + "/user_name_id")
          .set(url)
          .then(() => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                localStorage.setItem("userid", "");
                window.location.href = "/login";
              });
          });
      } else {
        console.log("fill some thing");
      }
      let changePassword = confirmPassword;
      if (email !== "") {
        // let user = firebase.auth().currentUser;
        // user.updateEmail(email).then(() => {
        //   firebase
        //     .database()
        //     .ref("users/" + this.state.userid + "/email")
        //     .set(email)
        //     .then(() => { });
        // });
        var data = JSON.stringify({
          userId: userid,
          email: email,
        });

        var config = {
          method: "post",
          url: "https://us-central1-bhodi-7c327.cloudfunctions.net/updateEmail",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.statusCode === 201 && this.state.currentUserMember.stripe_id) {
              axios
                .post(
                  "https://us-central1-bhodi-7c327.cloudfunctions.net/updateStripeCustomer",
                  {
                    name:
                      this.state.currentUserMember.first_name +
                      "" +
                      this.state.currentUserMember.last_name,
                    email: email,
                    customerId: this.state.currentUserMember.stripe_id,
                  }
                )
                .then((responseInner) => {
                  console.log("mollie Updated");
                });
            }
            if (response.data.statusCode === 201) {
              firebase
                .database()
                .ref("users/" + userid + "/email")
                .set(email);
            }
          })
          .catch((response) => {
            var errorMessage = response.error.message;
            if (
              errorMessage ===
              "There is no user record corresponding to this identifier. The user may have been deleted."
            ) {
              this.setState({ emailNotFound: true, aanmeldenloader: false });
            }
          });
      }
      if (password !== changePassword) {
        if (password !== "" && changePassword !== "") {
          if (currentPassword === "") {
            this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
          } else {
            this.setState({ fillCurrentPassword: false });
          }
        }
        this.setState({ changedPassword: true });
      } else {
        if (password !== "" && changePassword !== "") {
          if (currentPassword !== "") {
            this.setState({
              changedPassword: false,
              fillCurrentPassword: false,
            });
          } else {
            this.setState({
              savtBtnText: "SAVE",
              changedPassword: false,
              fillCurrentPassword: true,
            });
          }
        }
      }
      if (
        password !== "" &&
        changePassword !== "" &&
        currentPassword !== "" &&
        password === changePassword
      ) {
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          currentPassword
        );
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            this.setState({ fillCurrentPassword: false });
            var newPassword = password;
            user
              .updatePassword(newPassword)
              .then((e) => {
                bcrypt.genSalt(saltRounds, (err, salt) => {
                  bcrypt.hash(newPassword, salt, (err, hash) => {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, newPassword)
                      .then((succ) => {
                        // firebase
                        //   .database()
                        //   .ref("users/" + this.state.userid + "/pass")
                        //   .set(newPassword)
                        //   .then(() => {
                        firebase
                          .database()
                          .ref("users/" + this.state.userid + "/password")
                          .set(hash)
                          .then(() => {
                            this.setState({
                              password: "",
                              confirmPassword: "",
                            });
                            window.location.reload();
                          });
                        // });
                      });
                  });
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((err) => {
            if (err.code === "auth/wrong-password") {
              this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
            }
          });
      } else {
        setTimeout(() => {
          if (type === "goback") {
            if (this.state.closeOnlyCurrent) {
              this.props.closeOnlyCurrent();
            }
            if (this.state.closeIncludingRightMenu) {
              this.props.closeIncludingRightMenu();
            }
          } else {
            if (this.props.fromMainPage) {
              this.props.closeIncludingRightMenu();
            } else {
              window.history.back();
            }
            // this.setState({ savtBtnText: "SAVE" });
          }
        }, 3000);
      }

    } else {
      if (filteredEmail.length > 0) {
        this.setState({ emailAlreadyTooked: true });
      } else {
        this.setState({ emailAlreadyTooked: false });
      }
      if (filteredURL.length > 0) {
        this.setState({ urlNotAvailable: true });
      } else {
        this.setState({ urlNotAvailable: false });
      }
    }
  }

  fileUpload(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`userimages/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/profile_image")
          .set(snapUrl)
          .then(() => { });
      });
    });
  }

  onPopup() {
    this.setState({
      drag: true,
      isRemoving: true,
      filesName: [],
      activate: false,
    });
  }

  async deleteCurrentUser() {
    if (this.state.showDelete) {
      if (this.state.passwordForDel) {
        this.setState({ passwordRequiredDel: false })

        let email = this.state.currentUserMember.email
        let password = this.state.passwordForDel
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );;
        user.reauthenticateWithCredential(credential).then(() => {
          this.setState({ passwordWrongDel: false })
          this.deleteUserMain()
        }).catch(() => {
          this.setState({ passwordWrongDel: true })
          return false;
        })
      } else {
        this.setState({ passwordRequiredDel: true, passwordWrongDel: false })
        return false;
      }
    } else {
      // this.setState({ cancelSubscriptionPopup: true });
      this.setState({
        // cancelSubscriptionPopup: false,
        showDelete: true,
      });
      axios
        .post(
          "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
          {
            subscriptionId:
              this.state.currentUserMember.subscription_id,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then(() => {
          axios
            .post(
              "https://us-central1-bhodi-7c327.cloudfunctions.net/deleteStripeCustomer",
              {
                customerId: this.state.currentUserMember.stripe_id,
              }
            )
            .then(() => {
              this.deleteCurrentUser();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async deleteUserMain() {
    this.setState({ deleteBtnText: "SAVING.." });

    let allUsers = this.props.user;
    allUsers.map((u) => {
      if (u.following) {
        let objected = Object.entries(u.following);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          firebase
            .database()
            .ref(
              "users/" + u.user_id + "/following/" + filtered[0][1].pushKey
            )
            .remove();
        }
      }
      if (u.followed_by) {
        let objected = Object.entries(u.followed_by);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          firebase
            .database()
            .ref(
              "users/" + u.user_id + "/followed_by/" + filtered[0][1].pushKey
            )
            .remove();
        }
      }
      if (u.newFollowersList) {
        let objected = Object.entries(u.newFollowersList);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          let newFollowers = u.newFollowers - 1;
          firebase
            .database()
            .ref(
              "users/" +
              u.user_id +
              "/newFollowersList/" +
              filtered[0][1].pushKey
            )
            .remove();
          firebase
            .database()
            .ref("users/" + u.user_id + "/newFollowers")
            .set(newFollowers);
        }
      }
    });
    await firebase
      .database()
      .ref("bhodi_campaigns/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("impressiePhotos/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("allMessages/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("guest_carts/" + this.state.currentUserId)
      .remove();
    await firebase.auth().currentUser.delete();

    firebase
      .database()
      .ref("users/" + this.state.currentUserId)
      .remove()
      .then(() => {
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
        // window.location.href = "/login";
      });
  }

  createMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  render() {
    return (
      <div className="separate-settings">
        {this.state.rightModalSettings && (
          <div
            className={`loaderareaduplicate mcaloader ${this.state.userInfoRight ? "userinfomodalfull" : ""
              } ${this.state.shopSettings ? "shopsettingsmodalfull" : ""} ${this.state.privacyRight ? "privacymodalfull" : ""
              } ${this.state.emailPassword ? "emailpwdmodalfull" : ""}`}
          >
            {this.state.userInfoRight && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>MIJN GEGEVENS</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const {
                          first_name,
                          last_name,
                          zipCode,
                          street,
                          houseNumber,
                          country,
                          city,
                          phoneNumber,
                          profileActive,
                        } = this.state.currentUserMember;
                        if (
                          first_name !== this.state.firstName ||
                          last_name !== this.state.lastName ||
                          zipCode !== this.state.zipCode ||
                          street !== this.state.street ||
                          houseNumber !== this.state.houseNumber ||
                          country !== this.state.country ||
                          city !== this.state.city ||
                          phoneNumber !== this.state.phoneNumber ||
                          profileActive !== this.state.profileActive
                        ) {
                          this.setState({
                            beforeCloseMenuConfirm: true,
                            closeIncludingRightMenu: false,
                            closeOnlyCurrent: true,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                        // localStorage.setItem("mustShowOpenRightMenu", true)
                        // setTimeout(() => {
                        //     window.history.back()
                        // }, 100);
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const {
                          first_name,
                          last_name,
                          zipCode,
                          street,
                          houseNumber,
                          country,
                          city,
                          phoneNumber,
                          profileActive,
                        } = this.state.currentUserMember;
                        if (
                          first_name !== this.state.firstName ||
                          last_name !== this.state.lastName ||
                          zipCode !== this.state.zipCode ||
                          street !== this.state.street ||
                          houseNumber !== this.state.houseNumber ||
                          country !== this.state.country ||
                          city !== this.state.city ||
                          phoneNumber !== this.state.phoneNumber ||
                          profileActive !== this.state.profileActive
                        ) {
                          this.setState({
                            beforeCloseMenuConfirm: true,
                            closeIncludingRightMenu: true,
                            closeOnlyCurrent: false,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                        // window.history.back()
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                            this.setState({ userInfoRight: false, menuItemsModals: true })
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                MIJN GEGEVENS
                                            </p>
                                        </div> */}
                        <div className="wrapperforfullwidth">
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Voornaam</label>
                              <input
                                type="text"
                                value={this.state.firstName}
                                onChange={(e) =>
                                  this.setState({ firstName: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Achternaam</label>
                              <input
                                type="text"
                                value={this.state.lastName}
                                onChange={(e) =>
                                  this.setState({ lastName: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Straatnaam</label>
                              <input
                                type="text"
                                value={this.state.street}
                                onChange={(e) =>
                                  this.setState({ street: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Huisnummer</label>
                              <input
                                type="text"
                                value={this.state.houseNumber}
                                onChange={(e) =>
                                  this.setState({ houseNumber: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Postcode</label>
                              <input
                                type="text"
                                value={this.state.zipCode}
                                onChange={(e) =>
                                  this.setState({ zipCode: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Plaatsnaam</label>
                              <input
                                type="text"
                                value={this.state.city}
                                onChange={(e) =>
                                  this.setState({ city: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Land</label>
                              <input
                                type="text"
                                value={this.state.country}
                                onChange={(e) =>
                                  this.setState({ country: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Telefoonnummer</label>
                              <input
                                type="text"
                                value={this.state.phoneNumber}
                                onChange={(e) =>
                                  this.setState({ phoneNumber: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "#1C1C1C", flexDirection: "row" }}
                          >
                            <Switch
                              color="primary"
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              checked={this.state.profileActive}
                              onClick={() => {
                                this.setState({
                                  profileActive:
                                    !this.state.profileActive,
                                });
                              }}
                            />
                            {this.state.profileActive ? (
                              <font>Profiel actief</font>
                            ) : (
                              <font>Profiel inactief</font>
                            )}
                          </div>
                        </div>
                        <div
                          className="btn-save-rmcls"
                          style={{
                            position: "absolute",
                            paddingTop: 25,
                            marginTop: 30,
                            paddingBottom: 20,
                          }}
                        >
                          {/* <button style={{ marginLeft: 10 }} onClick={() => {
                                                window.history.back()
                                            }}>SLUITEN</button> */}
                          {this.state.savtBtnText === "SAVE" ? (
                            <button
                              style={{ backgroundColor: "#285151" }}
                              onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.saveInfo();
                                }
                              }}
                            >
                              OPSLAAN
                            </button>
                          ) : (
                            <button>
                              <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                            </button>
                          )}
                        </div>
                        <div className="delete-account-content" style={{ width: "90%", margin: "20px auto", marginTop: 0 }}>
                          <p style={{ color: "black", fontWeight: "bold" }}>Het verwijderen van je account betekent:</p>
                          <p style={{ color: "black" }}>Dat je alle gegevens en content in dit account kwijtraakt. Hierna kun je niet meer inloggen met dit account.</p>
                          <div className="red-button-verwijder">
                            {this.state.deleteBtnText !== "SAVE" ? (
                              <button
                                style={{ backgroundColor: "red", minWidth: 170 }}
                              >
                                <i class="fa fa-spinner fa-spin"></i> DELETING
                              </button>
                            ) : (
                              <button
                                style={{ backgroundColor: "red", minWidth: 170 }}
                                // onClick={this.deleteCurrentUser.bind(this)}
                                onClick={() =>
                                  this.setState({ verwijderPopupDel: true })
                                }
                              >
                                Verwijder account
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.showMijnAbonnementen && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>MIJN EXCLUSIVES</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        <div
                          className="wrapperforfullwidth"
                          style={{ position: "relative" }}
                        >
                          <MySubcribedExclusives />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.shopSettings && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white" }}
                >
                  <ChevronLeftIcon
                    style={{ fontSize: 20, marginRight: 10 }}
                    onClick={() => {
                      window.history.back();
                    }}
                  />
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                            this.setState({ shopSettings: false, menuItemsModals: true })
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        <div className="top-mcls-one">
                          <p>
                            <AccountCircleOutlinedIcon />
                            SHOP SETTINGS
                          </p>
                        </div>
                        <div className="wrapperforfullwidth">
                          <div
                            className="right-mcls"
                            style={{ color: "black", marginTop: 20 }}
                          >
                            <font>BETAALMETHODE</font>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.privacyRight && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>PRIVACY INSTELLINGEN</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { accountType } = this.state.currentUserMember;
                        let checkedTester = false;
                        if (accountType === "global") {
                          checkedTester = false;
                        } else {
                          checkedTester = true;
                        }
                        if (checkedTester !== this.state.checkedPrivate) {
                          this.setState({
                            beforeCloseMenuConfirmPrivacy: true,
                            closeIncludingRightMenu: false,
                            closeOnlyCurrent: true,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { accountType } = this.state.currentUserMember;
                        let checkedTester = false;
                        if (accountType === "global") {
                          checkedTester = false;
                        } else {
                          checkedTester = true;
                        }
                        if (checkedTester !== this.state.checkedPrivate) {
                          this.setState({
                            beforeCloseMenuConfirmPrivacy: true,
                            closeIncludingRightMenu: true,
                            closeOnlyCurrent: false,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                          <p>
                            <AccountCircleOutlinedIcon />
                            PRIVACY INSTELLINGEN
                          </p>
                        </div> */}
                        <div className="wrapperforfullwidth">
                          <div
                            className="right-mcls"
                            style={{ color: "#1C1C1C", flexDirection: "row" }}
                          >
                            <Switch
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              checked={this.state.checkedPrivate}
                              onClick={() => {
                                this.setState({
                                  checkedPrivate: !this.state.checkedPrivate,
                                });
                              }}
                            />
                            {this.state.checkedPrivate ? (
                              <font>Je account is nu privé</font>
                            ) : (
                              <font>Zet je account op privé</font>
                            )}
                          </div>
                        </div>
                        <div
                          className="btn-save-rmcls"
                          style={{
                            position: "absolute",
                            paddingTop: 25,
                            marginTop: 30,
                            paddingBottom: 20,
                          }}
                        >
                          {/* <button
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              // let element = document.getElementById("footermain")
                              // if (element) {
                              //     element.style.display = "block"
                              // }
                              window.history.back();
                              // this.setState({ privacyRight: false, menuItemsModals: true })
                            }}
                          >
                            SLUITEN
                          </button> */}
                          {this.state.savtBtnText === "SAVE" ? (
                            <button
                              style={{ backgroundColor: "#285151" }}
                              onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.savePrivacy();
                                }
                              }}
                            >
                              OPSLAAN
                            </button>
                          ) : (
                            <button>
                              <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.emailPassword && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>INLOGGEGEVENS</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { email, user_name_id } =
                          this.state.currentUserMember;
                        if (
                          email !== this.state.email ||
                          user_name_id !== this.state.url ||
                          this.state.currentPassword ||
                          this.state.password ||
                          this.state.confirmPassword
                        ) {
                          this.setState({
                            beforeCloseMenuConfirmInloggevens: true,
                            closeIncludingRightMenu: false,
                            closeOnlyCurrent: true,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { email, user_name_id } =
                          this.state.currentUserMember;
                        if (
                          email !== this.state.email ||
                          user_name_id !== this.state.url ||
                          this.state.currentPassword ||
                          this.state.password ||
                          this.state.confirmPassword
                        ) {
                          this.setState({
                            beforeCloseMenuConfirmInloggevens: true,
                            closeIncludingRightMenu: true,
                            closeOnlyCurrent: false,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                            this.setState({ emailPassword: false, menuItemsModals: true })
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                INLOGGEGEVENS
                                            </p>
                                        </div> */}
                        <div className="wrapperforfullwidth">
                          {/* <div className="right-mcls" style={{ color: "white" }}>
                                                <div style={{ width: "100%", marginTop: this.state.isDesktop && 25, marginLeft: this.state.isDesktop && 20 }}>
                                                    <p style={{ fontSize: 28, fontWeight: 600, letterSpacing: 0.8, textTransform: "none", color: "#1C1C1C" }}>www.bhodi.com/company/{this.state.url}</p>
                                                </div>
                                            </div> */}
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Gebruikersnaam is je email adres</label>
                              <input
                                type="text"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                              {this.state.emailAlreadyTooked && (
                                <p style={{ color: "red" }}>
                                  Email is already tooked!
                                </p>
                              )}
                            </div>
                          </div>
                          {/* <div
                            className="right-mcls"
                            style={{ color: "white" }}
                          >
                            <div className="form-profile">
                              <label>
                                Link naar je profielpagina:
                                www.bhodi.com/member/{this.state.url}
                              </label>
                              <input
                                type="text"
                                placeholder="Page url"
                                value={this.state.url}
                                onChange={(e) => {
                                  let converted = e.target.value
                                    .split(" ")
                                    .join("");
                                  this.setState({
                                    url: converted.toLowerCase(),
                                  });
                                }}
                              />
                              {this.state.urlNotAvailable && (
                                <p style={{ color: "red" }}>
                                  Sorry! this URL is taken
                                </p>
                              )}
                            </div>
                          </div> */}
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label className="tmo-dup-heading">
                                Wachtwoord wijzigen
                              </label>
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Huidig wachtwoord</label>
                              <div className="toonpasswordcontainer">
                                <input
                                  type={
                                    !this.state.showcurrentPassword
                                      ? "password"
                                      : "text"
                                  }
                                  value={this.state.currentPassword}
                                  onChange={(e) =>
                                    this.setState({
                                      currentPassword: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  onClick={() => {
                                    this.setState({
                                      showcurrentPassword:
                                        !this.state.showcurrentPassword,
                                    });
                                  }}
                                >
                                  toon
                                </label>
                              </div>
                              {this.state.fillCurrentPassword && (
                                <p style={{ color: "red" }}>
                                  Enter your right correct password!
                                </p>
                              )}
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Nieuw wachtwoord</label>
                              <div className="toonpasswordcontainer">
                                <input
                                  type={
                                    !this.state.showPassword
                                      ? "password"
                                      : "text"
                                  }
                                  value={this.state.password}
                                  onChange={(e) =>
                                    this.setState({ password: e.target.value })
                                  }
                                />
                                <label
                                  onClick={() => {
                                    this.setState({
                                      showPassword: !this.state.showPassword,
                                    });
                                  }}
                                >
                                  toon
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Herhaal wachtwoord</label>
                              <div className="toonpasswordcontainer">
                                <input
                                  type={
                                    !this.state.showconfirmPassword
                                      ? "password"
                                      : "text"
                                  }
                                  value={this.state.confirmPassword}
                                  onChange={(e) =>
                                    this.setState({
                                      confirmPassword: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  onClick={() => {
                                    this.setState({
                                      showconfirmPassword:
                                        !this.state.showconfirmPassword,
                                    });
                                  }}
                                >
                                  toon
                                </label>
                              </div>
                              {this.state.changedPassword && (
                                <p style={{ color: "red" }}>
                                  Password and Confirm Password should be same
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="btn-save-rmcls"
                          style={{
                            position: "absolute",
                            paddingTop: 25,
                            marginTop: 30,
                            paddingBottom: 20,
                          }}
                        >
                          {/* <button style={{ marginRight: 10, backgroundColor: "#285151" }} onClick={() => {
                                                this.setState({ currentPassword: "", password: "", confirmPassword: "", changePassword: "", fillCurrentPassword: "" })
                                            }}>ANNULEREN</button>
                                            <button style={{ marginLeft: 10 }} onClick={() => {
                                                window.history.back()
                                            }}>SLUITEN</button> */}
                          {this.state.savtBtnText === "SAVE" ? (
                            <button
                              style={{ backgroundColor: "#285151" }}
                              onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.saveEmailPassword();
                                }
                              }}
                            >
                              OPSLAAN
                            </button>
                          ) : (
                            <button>
                              <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.myOrdersRight && (
              <div className="modal-create-album modalFullWidth orderModalFullWidth">
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white" }}
                >
                  <ChevronLeftIcon
                    style={{ fontSize: 20, marginRight: 10 }}
                    onClick={() => {
                      if (this.props.fromMainPage) {
                        this.props.closeOnlyCurrent();
                      } else {
                        localStorage.setItem("mustShowOpenRightMenu", true);
                        setTimeout(() => {
                          window.history.back();
                        }, 100);
                      }
                    }}
                  />
                  <label>ORDEROVERZICHT</label>
                  <CloseIcon
                    style={{ fontSize: 20, marginRight: 10 }}
                    onClick={() => {
                      if (this.props.fromMainPage) {
                        this.props.closeIncludingRightMenu();
                      } else {
                        window.history.back();
                      }
                    }}
                  />
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                            this.setState({ myOrdersRight: false, menuItemsModals: true })
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                {/* <MyOrders showVerzilverPopup={(e) => {
                            this.setState({ verzilverPopup: true, currentUserOfPopups: e })
                        }} showVerzilverLastMinutePopup={(e) => {
                            this.setState({ verzilverPopupLastMinute: true, currentUserOfPopups: e })
                        }} showVerzilverCharityPopup={(e) => {
                            this.setState({ verzilverPopupCharity: true, currentUserOfPopups: e })
                        }} showVerzilverGiveawayPopup={(e) => {
                            this.setState({ verzilverPopupGiveaway: true, currentUserOfPopups: e })
                        }} showVerzilverWebShopPopup={(e, redeem_code) => {
                            this.setState({ verzilverPopupWebShop: true, currentUserOfPopups: e, redeem_code })
                        }} /> */}
                <RightMenuOrders
                  showFooter={true}
                  onSettingsPage={true}
                  closePopup={() => {
                    this.props.closeOnlyCurrent();
                  }}
                  closePopupCompletely={() => {
                    this.props.closeIncludingRightMenu();
                  }}
                  showVerzilverPopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverLastMinutePopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverCharityPopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverGiveawayPopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverWebShopPopup={(e, redeem_code) => {
                    this.setState({
                      verzilverPopupWebShop: true,
                      currentUserOfPopups: e,
                      redeem_code,
                    });
                  }}
                />
              </div>
            )}
            {this.state.showBhodiFaqs && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum changedbackgroundmodalfaqabout"
                style={{ width: 400, paddingBottom: 60 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>VEELGESTELDE VRAGEN</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                            this.setState({ showBhodiFaqs: false, menuItemsModals: true })
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VEELGESTELDE VRAGEN
                                            </p>
                                        </div> */}
                        <div
                          className="wrapperforfullwidth wrapperbhodifaqs"
                          style={{ position: "relative" }}
                        >
                          {this.state.bhodiFaqs.length > 0 &&
                            this.state.bhodiFaqs.map((y, i) => {
                              if (y.title) {
                                return (
                                  <div className="faq-inner-main">
                                    <div
                                      className="top-faq-rmcls"
                                      onClick={() => {
                                        if (y.descMobile) {
                                          this.state.arrDropdowns[i].opened =
                                            !this.state.arrDropdowns[i].opened;
                                          this.setState({
                                            arrDropdowns:
                                              this.state.arrDropdowns,
                                          });
                                        }
                                      }}
                                    >
                                      <p>{y.title}</p>
                                      {this.state.arrDropdowns[i].opened
                                        ? y.descMobile && <RemoveIcon />
                                        : y.descMobile && <AddIcon />}
                                    </div>
                                    {this.state.arrDropdowns[i].opened && (
                                      <div className="bottom-faq-rmcls">
                                        {/* <p>{y.desc}</p> */}
                                        <div
                                          dangerouslySetInnerHTML={this.createMarkup(
                                            y.descMobile
                                          )}
                                        ></div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.showBhodiAbouts && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum changedbackgroundmodalfaqabout"
                style={{ width: 400, paddingBottom: 60 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>ABOUT</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VEELGESTELDE VRAGEN
                                            </p>
                                        </div> */}
                        <div
                          className="wrapperforfullwidth wrapperbhodifaqs"
                          style={{ position: "relative" }}
                        >
                          {this.state.bhodiAbouts.length > 0 &&
                            this.state.bhodiAbouts.map((y, i) => {
                              if (y.title) {
                                return (
                                  <div className="faq-inner-main">
                                    <div
                                      className="top-faq-rmcls"
                                      onClick={() => {
                                        if (y.descMobile) {
                                          this.state.arrDropdownsAbouts[
                                            i
                                          ].opened =
                                            !this.state.arrDropdownsAbouts[i]
                                              .opened;
                                          this.setState({
                                            arrDropdownsAbouts:
                                              this.state.arrDropdownsAbouts,
                                          });
                                        }
                                      }}
                                    >
                                      <p>{y.title}</p>
                                      {this.state.arrDropdownsAbouts[i].opened
                                        ? y.descMobile && <RemoveIcon />
                                        : y.descMobile && <AddIcon />}
                                    </div>
                                    {this.state.arrDropdownsAbouts[i]
                                      .opened && (
                                        <div className="bottom-faq-rmcls">
                                          {/* <p>{y.desc}</p> */}
                                          <div
                                            dangerouslySetInnerHTML={this.createMarkup(
                                              y.descMobile
                                            )}
                                          ></div>
                                          {y.image && <img src={y.image} />}
                                        </div>
                                      )}
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.isDesktop ? (
              <div className="modal-create-album" style={{ width: 400 }}>
              </div>
            ) : (
              this.state.menuItemsModals && (
                <div className="modal-create-album" style={{ width: 400 }}>
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>PROFIELINSTELLINGEN</font>
                    <CloseIcon
                      onClick={() => {
                        let element = document.getElementById("footermain");
                        if (element) {
                          element.style.display = "flex";
                        }
                        if (this.props.closeAboutBhodiRightMenu) {
                          this.props.closeAboutBhodiRightMenu();
                        }
                        this.setState({ rightModalSettings: false });
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: 10,
                        fontSize: 24,
                      }}
                    />
                  </div>
                  <div className="body-mca groupsettingsbody">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            height: "auto",
                          }}
                        >
                          <div
                            className="right-mcls imageinnermca"
                            style={{
                              color: "white",
                              position: "relative",
                              maxWidth: 70,
                              margin: "0px auto",
                            }}
                          >
                            {this.state.profileImageUrl ? (
                              <img src={this.state.profileImageUrl} />
                            ) : (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            )}
                            <div className="imginnermcaadd">
                              <AddIcon />
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => this.fileUpload(e)}
                            />
                          </div>
                          <div
                            className="right-mcls imageinnermca"
                            style={{ color: "white" }}
                          >
                            <font>
                              {this.state.firstName} {this.state.lastName}
                            </font>
                          </div>
                          <div className="line-rcmls"></div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() =>
                                this.setState({
                                  userInfoRight: true,
                                  privacyRight: false,
                                  emailPassword: false,
                                  shopSettings: false,
                                  myOrdersRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                })
                              }
                            >
                              MIJN GEGEVENS
                            </font>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() =>
                                this.setState({
                                  privacyRight: true,
                                  userInfoRight: false,
                                  emailPassword: false,
                                  shopSettings: false,
                                  myOrdersRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                })
                              }
                            >
                              PRIVACY INSTELLINGEN
                            </font>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() =>
                                this.setState({
                                  emailPassword: true,
                                  privacyRight: false,
                                  userInfoRight: false,
                                  shopSettings: false,
                                  myOrdersRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                })
                              }
                            >
                              INLOGGEGEVENS
                            </font>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() =>
                                this.setState({
                                  myOrdersRight: true,
                                  shopSettings: false,
                                  privacyRight: false,
                                  emailPassword: false,
                                  userInfoRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                })
                              }
                            >
                              MIJN ORDEROVERZICHT
                            </font>
                          </div>
                          {this.state.bhodiFaqs.length > 0 && (
                            <div
                              className="right-mcls"
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <font
                                onClick={() =>
                                  this.setState({
                                    myOrdersRight: false,
                                    privacyRight: false,
                                    emailPassword: false,
                                    userInfoRight: false,
                                    allOrdersRight: false,
                                    menuItemsModals: false,
                                    shopSettings: false,
                                    voucherSettings: false,
                                    showBhodiFaqs: true,
                                    showSubscribedContent: false,
                                    showBhodiAbouts: false,
                                  })
                                }
                              >
                                FAQ
                              </font>
                            </div>
                          )}
                          {this.state.bhodiAbouts.length > 0 && (
                            <div
                              className="right-mcls"
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <font
                                onClick={() =>
                                  this.setState({
                                    myOrdersRight: false,
                                    privacyRight: false,
                                    emailPassword: false,
                                    userInfoRight: false,
                                    allOrdersRight: false,
                                    menuItemsModals: false,
                                    shopSettings: false,
                                    voucherSettings: false,
                                    showBhodiAbouts: true,
                                    showBhodiFaqs: false,
                                    showSubscribedContent: false,
                                  })
                                }
                              >
                                ABOUT
                              </font>
                            </div>
                          )}
                          <div
                            className="right-mcls logout-mcls"
                            style={{ color: "white" }}
                          >
                            <ExitToAppIcon onClick={this.logout.bind(this)} />
                            <font onClick={this.logout.bind(this)}>
                              UITLOGGEN
                            </font>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
        {this.state.rightModalAbout && (
          <div className="loader-area mcaloader">
            <div className="modal-create-album" style={{ width: 400 }}>
              <div
                className="heading-mca"
                style={{
                  textAlign: "center",
                  position: "relative",
                  padding: "13px",
                }}
              >
                <font>ABOUT</font>
                <CloseIcon
                  onClick={() => {
                    this.setState({ rightModalAbout: false });
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 10,
                    fontSize: 24,
                  }}
                />
              </div>
              <div className="body-mca groupsettingsbody">
                <div className="form-body-mca">
                  <div className="inner-mca-settings">
                    <div
                      className="mca-settings-list"
                      style={{
                        flexDirection: "column",
                        backgroundColor: "transparent",
                        height: "auto",
                      }}
                    >
                      <div
                        className="right-mcls imageinnermca"
                        style={{
                          color: "white",
                          position: "relative",
                          maxWidth: 70,
                          margin: "0px auto",
                        }}
                      >
                        {this.state.currentUserMember.profile_image ? (
                          <img
                            src={this.state.currentUserMember.profile_image}
                          />
                        ) : (
                          <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                        )}
                      </div>
                      {this.state.userNameInput ? (
                        <div
                          className="right-mcls imageinnermca"
                          style={{ color: "white", flexDirection: "column" }}
                        >
                          <div
                            className="form-profile"
                            style={{ textAlign: "left" }}
                          >
                            <label>First Name</label>
                            <input
                              type="text"
                              value={this.state.currentUserMember.first_name}
                              style={{ position: "static", opacity: 1 }}
                              onChange={(e) => {
                                this.state.currentUserMember.first_name =
                                  e.target.value;
                                this.setState({
                                  currentUserMember:
                                    this.state.currentUserMember,
                                });
                                let userid = localStorage.getItem("userid");
                                firebase
                                  .database()
                                  .ref("users/" + userid + "/first_name")
                                  .set(e.target.value);
                              }}
                              onBlur={() => {
                                this.setState({ userNameInput: false });
                              }}
                            />
                          </div>
                          <div
                            className="form-profile"
                            style={{ textAlign: "left" }}
                          >
                            <label>Last Name</label>
                            <input
                              type="text"
                              value={this.state.currentUserMember.last_name}
                              style={{ position: "static", opacity: 1 }}
                              onChange={(e) => {
                                this.state.currentUserMember.last_name =
                                  e.target.value;
                                this.setState({
                                  currentUserMember:
                                    this.state.currentUserMember,
                                });
                                let userid = localStorage.getItem("userid");
                                firebase
                                  .database()
                                  .ref("users/" + userid + "/last_name")
                                  .set(e.target.value);
                              }}
                              onBlur={() => {
                                this.setState({ userNameInput: false });
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="right-mcls imageinnermca"
                          style={{ color: "white" }}
                          onClick={() => this.setState({ userNameInput: true })}
                        >
                          <font>
                            {this.state.currentUserMember.first_name}{" "}
                            {this.state.currentUserMember.last_name}
                          </font>{" "}
                          <EditIcon
                            style={{
                              position: "static",
                              fontSize: 16,
                              margin: "0px 15px",
                            }}
                          />
                        </div>
                      )}
                      {this.state.currentUserMember.user_description ? (
                        <div
                          className="top-mcls-one"
                          style={{ marginBottom: 20 }}
                        >
                          {this.state.userDescInput ? (
                            <div
                              className="form-profile"
                              style={{ textAlign: "left" }}
                            >
                              <textarea
                                value={
                                  this.state.currentUserMember.user_description
                                }
                                style={{ position: "static", opacity: 1 }}
                                onChange={(e) => {
                                  this.state.currentUserMember.user_description =
                                    e.target.value;
                                  this.setState({
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                  let userid = localStorage.getItem("userid");
                                  firebase
                                    .database()
                                    .ref(
                                      "users/" + userid + "/user_description"
                                    )
                                    .set(e.target.value);
                                }}
                                onBlur={() => {
                                  this.setState({ userDescInput: false });
                                }}
                              ></textarea>
                            </div>
                          ) : (
                            <p
                              onClick={() =>
                                this.setState({ userDescInput: true })
                              }
                            >
                              {this.state.currentUserMember.user_description}{" "}
                              <EditIcon
                                style={{
                                  position: "static",
                                  fontSize: 16,
                                  margin: "0px 15px",
                                }}
                              />
                            </p>
                          )}
                        </div>
                      ) : (
                        <div
                          className="top-mcls-one"
                          style={{ marginBottom: 20 }}
                        >
                          {this.state.userDescInput ? (
                            <div
                              className="form-profile"
                              style={{ textAlign: "left" }}
                            >
                              <textarea
                                value=""
                                style={{ position: "static", opacity: 1 }}
                                onChange={(e) => {
                                  this.state.currentUserMember.user_description =
                                    e.target.value;
                                  this.setState({
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                  let userid = localStorage.getItem("userid");
                                  firebase
                                    .database()
                                    .ref(
                                      "users/" + userid + "/user_description"
                                    )
                                    .set(e.target.value);
                                }}
                                onBlur={() => {
                                  this.setState({ userDescInput: false });
                                }}
                              ></textarea>
                            </div>
                          ) : (
                            <p
                              onClick={() =>
                                this.setState({ userDescInput: true })
                              }
                            >
                              Description{" "}
                              <EditIcon
                                style={{
                                  position: "static",
                                  fontSize: 16,
                                  margin: "0px 15px",
                                }}
                              />
                            </p>
                          )}
                        </div>
                      )}
                      <div
                        className="right-mcls"
                        style={{ color: "white", paddingLeft: 20 }}
                      >
                        <EventAvailableIcon
                          style={{ position: "static", fontSize: 24 }}
                        />
                        <font>
                          Member Sinds {this.state.currentUserMember.created_on}{" "}
                        </font>
                      </div>
                      <div
                        className="right-mcls"
                        style={{ color: "white", paddingLeft: 20 }}
                      >
                        <LocationOnIcon
                          style={{ position: "static", fontSize: 24 }}
                        />
                        <font>
                          {this.state.currentUserMember.city}{" "}
                          {this.state.currentUserMember.country}
                        </font>
                      </div>
                      <div
                        className="right-mcls"
                        style={{ color: "white", paddingLeft: 20 }}
                      >
                        <PersonIcon
                          style={{ position: "static", fontSize: 24 }}
                        />
                        <font>
                          {this.state.currentUserMember.objectedFriends.length}{" "}
                          vrienden
                        </font>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Dialog
          open={this.state.cancelSubscriptionPopup}
          onClose={() => this.setState({ cancelSubscriptionPopup: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd"
        >
          <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Je bent nog Charity Member.
              <br />
              <br />
              Om je account te verwijderen zul je eerst<br />
              je maandelijkse bijdrage moeten stopzetten.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => {
                this.setState({
                  cancelSubscriptionPopup: false,
                  showDelete: true,
                });
                axios
                  .post(
                    "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
                    {
                      subscriptionId:
                        this.state.currentUserMember.subscription_id,
                      customerId: this.state.currentUserMember.stripe_id,
                    }
                  )
                  .then(() => {
                    axios
                      .post(
                        "https://us-central1-bhodi-7c327.cloudfunctions.net/deleteStripeCustomer",
                        {
                          customerId: this.state.currentUserMember.stripe_id,
                        }
                      )
                      .then(() => {
                        this.deleteCurrentUser();
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              className="stopplan-button"
            >
              BIJDRAGE STOPPEN
            </button>
            <Button
              onClick={() => this.setState({ cancelSubscriptionPopup: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.beforeCloseMenuConfirm}
          onClose={() => this.setState({ beforeCloseMenuConfirm: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je verder <br /> wilt zonder op te slaan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ beforeCloseMenuConfirm: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirm: false,
                  // userInfoRight: false,
                });
                this.saveInfo("goback");
              }}
              color="primary"
            >
              OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // const { first_name, last_name, zipCode, street, houseNumber, country, city, phoneNumber } = this.state.currentUserMember
                this.setState({
                  beforeCloseMenuConfirm: false,
                  // userInfoRight: false,
                  // firstName: first_name,
                  // lastName: last_name,
                  // zipCode,
                  // street,
                  // houseNumber,
                  // country,
                  // city,
                  // phoneNumber
                });

                // if (this.state.closeOnlyCurrent) {
                //   this.props.closeOnlyCurrent();
                // }
                // if (this.state.closeIncludingRightMenu) {
                //   this.props.closeIncludingRightMenu();
                // }
              }}
              color="primary"
            >
              NIET OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // window.history.back()
                if (this.state.closeOnlyCurrent) {
                  this.props.closeOnlyCurrent();
                }
                if (this.state.closeIncludingRightMenu) {
                  this.props.closeIncludingRightMenu();
                }
              }}
              color="primary"
            >
              ANNULEREN
            </Button>
            {/* <Button onClick={() => this.setState({ beforeCloseMenuConfirm: false })} color="primary">SAVE</Button> */}
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.beforeCloseMenuConfirmPrivacy}
          onClose={() =>
            this.setState({ beforeCloseMenuConfirmPrivacy: false })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je verder <br /> wilt zonder op te slaan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ beforeCloseMenuConfirmPrivacy: false })
              }
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmPrivacy: false,
                  // privacyRight: false,
                });
                this.savePrivacy("goback");
              }}
              color="primary"
            >
              OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // const { accountType } = this.state.currentUserMember
                // let checkedTester = false
                // if (accountType === "global") {
                //     checkedTester = false
                // } else {
                //     checkedTester = true
                // }
                this.setState({
                  beforeCloseMenuConfirmPrivacy: false,
                  // privacyRight: false,
                  // checkedPrivate: checkedTester
                });

                // if (this.state.closeOnlyCurrent) {
                //   this.props.closeOnlyCurrent();
                // }
                // if (this.state.closeIncludingRightMenu) {
                //   this.props.closeIncludingRightMenu();
                // }
              }}
              color="primary"
            >
              NIET OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // window.history.back()
                if (this.state.closeOnlyCurrent) {
                  this.props.closeOnlyCurrent();
                }
                if (this.state.closeIncludingRightMenu) {
                  this.props.closeIncludingRightMenu();
                }
              }}
              color="primary"
            >
              ANNULEREN
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.beforeCloseMenuConfirmInloggevens}
          onClose={() =>
            this.setState({ beforeCloseMenuConfirmInloggevens: false })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je verder <br /> wilt zonder op te slaan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ beforeCloseMenuConfirmInloggevens: false })
              }
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmInloggevens: false,
                  // emailPassword: false,
                });
                this.saveEmailPassword("goback");
              }}
              color="primary"
            >
              OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // const { email, user_name_id } = this.state.currentUserMember
                this.setState({
                  beforeCloseMenuConfirmInloggevens: false,
                  // emailPassword: false,
                  // email: email,
                  // url: user_name_id,
                  // currentPassword: "",
                  // password: "",
                  // confirmPassword: "",
                });

                // if (this.state.closeOnlyCurrent) {
                //   this.props.closeOnlyCurrent();
                // }
                // if (this.state.closeIncludingRightMenu) {
                //   this.props.closeIncludingRightMenu();
                // }
              }}
              color="primary"
            >
              NIET OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // window.history.back()
                if (this.state.closeOnlyCurrent) {
                  this.props.closeOnlyCurrent();
                }
                if (this.state.closeIncludingRightMenu) {
                  this.props.closeIncludingRightMenu();
                }
              }}
              color="primary"
            >
              ANNULEREN
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.verwijderPopupDel && (
          <div className="overlay-delete-account">
            <div className="inner-odacc">
              <div className="right-iodacc">
                <CloseIcon
                  onClick={() => this.setState({ verwijderPopupDel: false })}
                />
                <h4>ACCOUNT VERWIJDEREN</h4>
                {/* <p>
                  Je staat op het punt om je Bhodi account te<br />
                  verwijderen. Je gegevens worden verwijdert en<br />
                  hierna kun je niet meer op je account inloggen.
                  <br />
                  <br />
                  We zullen je missen, maar wellicht zien we<br />
                  je in de toekomst weer terug op Bhodi!
                </p> */}
                {this.state.showDelete ? (<p>
                  Je staat op het punt om je account<br />
                  te verwijderen. Hierna kun je niet<br />
                  meer inloggen met dit account.
                  <br />
                  <br />
                  Voer je wachtwoord opnieuw in<br />
                  om je account te verwijderen.
                  {/* We want to get know your account password in order to delete your account. */}
                </p>) : (<p>
                  Je staat op het punt om je account<br />
                  te verwijderen. Hierna kun je niet<br />
                  meer inloggen met dit account. Je<br />
                  maandelijkse bijdrage wordt ook<br />
                  automatisch stopgezet.
                  <br />
                  <br />
                  Voer je wachtwoord opnieuw in<br />
                  om je account te verwijderen.
                </p>)}
                <div className="input-riodacc">
                  <label>Jouw  wachtwoord</label>
                  <input type="password" value={this.state.passwordForDel} onChange={(e) => {
                    this.setState({ passwordForDel: e.target.value })
                  }} />
                  {this.state.passwordRequiredDel && (
                    <label style={{ color: "red" }}>Please enter password to delete</label>
                  )}
                  {this.state.passwordWrongDel && (
                    <label style={{ color: "red" }}>Please enter correct password</label>
                  )}
                </div>
                {this.state.deleteBtnText !== "SAVE" ? (
                  <button>DELETING</button>
                ) : (
                  <button onClick={this.deleteCurrentUser.bind(this)}>
                    VERWIJDEREN
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <FooterGreen notOnMainPage={true} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    global_campaigns: state.campaignsReducer.global_campaigns,
    bhodi_abouts: state.globalReducers.bhodi_abouts,
    bhodi_faqs: state.globalReducers.bhodi_faqs,
    guest_user_cart: state.cartReducer.guest_user_cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentMoments: (currentMoments) =>
      dispatch(updateCurrentMoments(currentMoments)),
    updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    updateMoreAlbumsAdded: (morealbumsToAdd) =>
      dispatch(updateMoreAlbumsAdded(morealbumsToAdd)),
    updateSelectedExclusive: (selected_exclusive) =>
      dispatch(updateSelectedExclusive(selected_exclusive)),
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
    updateAlbumToAddFriends: (albumToAddFriends) =>
      dispatch(updateAlbumToAddFriends(albumToAddFriends)),
    updateBhodiAbouts: (bhodi_abouts) => dispatch(updateBhodiAbouts(bhodi_abouts)),
    updateBhodiFaqs: (bhodi_faqs) => dispatch(updateBhodiFaqs(bhodi_faqs)),
    updateMoreAlbumsAddedFriends: (morealbumsToAddFriends) =>
      dispatch(updateMoreAlbumsAddedFriends(morealbumsToAddFriends)),
    updateSelectedExclusiveFriends: (selected_exclusive_friends) =>
      dispatch(updateSelectedExclusiveFriends(selected_exclusive_friends)),
    updateCurrentMomentsFriendsPage: (currentMomentsFriends) =>
      dispatch(updateCurrentMomentsFriendsPage(currentMomentsFriends)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSeparateUsers);
