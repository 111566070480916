import * as firebase from "firebase";

export const firebaseConfig = {
  // apiKey: "AIzaSyCYLy2usyh-jZ2ZvIplc2PjtfcOkniRoWY",
  // authDomain: "bhodi-7c327.firebaseapp.com",
  // databaseURL: "https://bhodi-7c327.firebaseio.com",
  // projectId: "bhodi-7c327",
  // storageBucket: "bhodi-7c327.appspot.com",
  // messagingSenderId: "206128177666",
  // appId: "1:206128177666:web:8142f5559fea68029afd1b",
  // measurementId: "G-XXT7DT4122",
  apiKey: "AIzaSyBing0s1IsYV0GaPy2Fu513--sq5EPGLFg",
  authDomain: "bhodi-7c327.firebaseapp.com",
  databaseURL: "https://bhodi-7c327.firebaseio.com",
  projectId: "bhodi-7c327",
  storageBucket: "bhodi-7c327.appspot.com",
  messagingSenderId: "206128177666",
  appId: "1:206128177666:web:8142f5559fea68029afd1b",
  measurementId: "G-XXT7DT4122"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
