import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../Config/Firebase";
import {
    updateUser,
    updateGuestUserCart,
    updateGuestUserCartExtendOpportunity,
    updateGuestUserCartTime,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddIcon from "@material-ui/icons/Add";
import { isDesktop } from "react-device-detect";
import NumberFormatter from "./NumberFormatter";
import { BsChevronRight, BsTag, BsThreeDots } from "react-icons/bs";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import { AiFillStar, AiOutlineEdit, AiOutlineStar } from "react-icons/ai";
import { bookmarkCurrentProduct, unBookmarkCurrentProduct } from "../Functions/shopFunctions";
import { history } from "../Config/Routes";
import { ChevronLeft } from "@material-ui/icons";
import { VscInfo } from "react-icons/vsc"
import { RiArrowDownSLine, RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import DOMPurify from "dompurify";
import ButtonTimerCampaign from "./ButtonTimerCampaign";
import { followUnfollowCompany } from "../Functions/friendFunctions";
import { mergeAdminEvent } from "../Functions/globalFunctions";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import logolandingpage from "../assets/logolandingpage.png";
import logolandingpagemobile from "../assets/landingpagelogomobile.png";
import playiconlandingpage from "../assets/playiconlandingpage.png"
import playiconlandingpagemobile from "../assets/playiconlogomobile.png"
import descriptionLogoTop from "../assets/toplandingpagecompany.png"
import { FaChevronDown, FaChevronRight, FaChevronUp } from "react-icons/fa6";

export class EventsNewSection extends Component {
    constructor() {
        super();
        this.state = {
            activateTimer: false,
            obj: {
                "category 1": {
                    sub_category: "sub category 1",
                },
                "category 2": {
                    sub_category: "sub category 2",
                },
            },
            minutes: "",
            arrCurrentProducts: [],
            completeCategories: [],
            subCategoryPresent: true,
            currentUser: "",
            orderidToUpload: 0,
            verzilverPopup: false,
            verzilverPopupGreen: false,
            isDesktop: true,
            currentProductDetailed: "",
            activeSection: 0,
            currentUserHere: "",
            selectedPersons: 2,
            campaignsSorted: [],
            currentCampaignHere: [],
            guestUserCart: [],
            showPlaceholderLocalEvent: true,
            notShowTimerGuestLocalEvent: false,
            currentUserId: "",
            currentUserCharityMember: false,
            onCurrentProductComments: "",

            selectedTimeIndex: 0,
            selectedTiming: false,
            reserved_event_id: 1,
            useOfVoucherLocalEventGlobal: "",

            expandDescription: false,
        };
        // window.addEventListener("beforeunload", function() { debugger; }, false)
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        firebase.database().ref("current_order_id").on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ orderidToUpload: Number(a) });
            }
        })

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                let localEventInfoMobile = ""

                if (a.localEventInfoMobile) {
                    localEventInfoMobile = a.localEventInfoMobile
                }
                this.setState({
                    localEventInfoMobile,
                    useOfVoucherLocalEventGlobal: a.voucherUseLocalEvent || "",
                    landing_page_new_logo_main: a.landing_page_new_logo_main || "",
                    landing_page_new_logo_main_mobile: a.landing_page_new_logo_main_mobile || "",
                    landing_page_new_logo_small: a.landing_page_new_logo_small || "",
                    landing_page_new_logo_small_mobile: a.landing_page_new_logo_small_mobile || "",
                });
            }
        }

        if (this.props.guest_user_cart) {
            this.setState({ guestUserCart: this.props.guest_user_cart });
        }

        let uid = localStorage.getItem("userid");
        this.setState({ currentUserId: uid });

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    if (a) {
                        if (a.subscription) {
                            if (a.subscription.status === "active") {
                                this.setState({ currentUserCharityMember: true });
                            } else {
                                if (checkForSubscribedExclusives(a)) {
                                    this.setState({ currentUserCharityMember: true })
                                }
                            }
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true })
                            }
                        }
                        this.setState({
                            currentUserHere: a,
                            currentUserId: uid,
                            isGuestUser: false,
                        });
                    }
                }

                let filteredCreator = this.props.user.filter((y) => {
                    return y.user_id === this.props.campaignToShow[0].created_by;
                });
                if (filteredCreator.length > 0) {
                    let a = filteredCreator[0];
                    if (a) {
                        this.setState({ currentUser: a });
                    }
                }
            }
        }

        if (this.props.campaignToShow[0].campaignType === "local_event") {
            if (
                this.props.campaignToShow[0].timings &&
                this.props.campaignToShow[0].timings.length > 0
            ) {
                if (this.props.campaignToShow[0].timings[this.state.selectedTimeIndex].isoStartTime && this.props.campaignToShow[0].timings[this.state.selectedTimeIndex].isoEndTime) {
                    if (new Date(Date.parse(this.props.campaignToShow[0].timings[this.state.selectedTimeIndex].isoEndTime)).getTime() > new Date().getTime()) {
                        this.setState({
                            showPlaceholderLocalEvent: true,
                            notShowTimerGuestLocalEvent: false,
                        });
                    } else {
                        this.setState({ showPlaceholderLocalEvent: false });
                        if (uid) {
                            this.setState({ notShowTimerGuestLocalEvent: false });
                        } else {
                            this.setState({ notShowTimerGuestLocalEvent: true });
                        }
                    }
                }
            }
        }
        this.setState({
            currentCampaignHere: this.props.campaignToShow,
            campaignsSorted: [this.props.campaignToShow[0].campaignType],
        });

        firebase
            .database()
            .ref(
                "bhodi_campaigns/" +
                this.props.campaignToShow[0].created_by +
                "/" +
                this.props.campaignToShow[0].pushKey
            )
            .on("value", (data) => {
                let a = data.val();
                if (a) {

                    if (a.campaignType === "local_event") {
                        if (a.timings && a.timings.length > 0) {
                            if (a.timings[this.state.selectedTimeIndex].isoStartTime && a.timings[this.state.selectedTimeIndex].isoEndTime) {
                                if (new Date(Date.parse(a.timings[this.state.selectedTimeIndex].isoEndTime)).getTime() > new Date().getTime()) {
                                    this.setState({
                                        showPlaceholderLocalEvent: true,
                                        notShowTimerGuestLocalEvent: false,
                                    });
                                } else {
                                    this.setState({ showPlaceholderLocalEvent: false });
                                    if (uid) {
                                        this.setState({ notShowTimerGuestLocalEvent: false });
                                    } else {
                                        this.setState({ notShowTimerGuestLocalEvent: true });
                                    }
                                }
                            }
                        }
                    }
                }
            });
    }

    // uses etc

    getUseOfVoucherlocalEvent(e) {
        if (e.created_by === process.env.REACT_APP_ADMIN_ID) {
            if (this.state.useOfVoucherLocalEventGlobal) {
                return (
                    <label onClick={() => {
                        this.setState({
                            useOfVouchersPopup: true,
                            currentUseOfVoucher: this.state.useOfVoucherLocalEventGlobal,
                        });
                    }}
                    >
                        <font style={{ marginRight: 15 }}>|</font>
                        GEBRUIK
                    </label>
                );
            }
        } else if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].useOfVoucherLocalEvent) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                useOfVouchersPopup: true,
                                currentUseOfVoucher: filtered[0].useOfVoucherLocalEvent,
                            });
                        }}
                        >
                            <font style={{ marginRight: 15 }}>|</font>
                            GEBRUIK
                        </label>
                    );
                }
            }
        }
    }


    getUseOfVoucherlocalEventMobile() {
        if (this.state.currentCampaignHere[0].created_by === process.env.REACT_APP_ADMIN_ID) {
            if (this.state.useOfVoucherLocalEventGlobal) {
                return (
                    <>
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        >
                            GEBRUIK
                        </p>
                        {this.state.useOfVoucherLocalEventGlobal.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                            this.setState({
                                headingOdiMain: "GEBRUIK",
                                paraOdiMain: this.state.useOfVoucherLocalEventGlobal,
                                openDetailedInner: true,
                            })
                        }}>
                            {this.state.useOfVoucherLocalEventGlobal.substr(0, 130)}
                        </p>) : (<p className="desc-content-asp">
                            {this.state.useOfVoucherLocalEventGlobal}
                        </p>)}
                    </>
                );
            }
        } else {
            let creator = this.props.user.filter((j) => {
                return j.user_id === this.state.currentCampaignHere[0].created_by;
            });
            if (creator.length > 0) {
                if (creator[0].useOfVoucherLocalEvent) {
                    return (
                        <>
                            <p
                                className="desc-heading-asp"
                                style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                            >
                                GEBRUIK
                            </p>
                            {creator[0].useOfVoucherLocalEvent.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                                this.setState({
                                    headingOdiMain: "GEBRUIK",
                                    paraOdiMain: creator[0].useOfVoucherLocalEvent,
                                    openDetailedInner: true,
                                })
                            }}>
                                {creator[0].useOfVoucherLocalEvent.substr(0, 130)}
                            </p>) : (<p className="desc-content-asp">
                                {creator[0].useOfVoucherLocalEvent}
                            </p>)}
                        </>
                    );
                }
            }
        }
    }


    getAboutLocalEventMobile() {
        if (this.state.localEventInfoMobile) {
            return (
                <>
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                    >
                        INFO
                    </p>
                    {this.state.localEventInfoMobile.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                        this.setState({
                            headingOdiMain: "INFO",
                            paraOdiMain: this.state.localEventInfoMobile,
                            openDetailedInner: true,
                        })
                    }}>
                        {this.state.localEventInfoMobile.substr(0, 30)}...
                    </p>) : (<p className="desc-content-asp">
                        {this.state.localEventInfoMobile}
                    </p>)}
                </>
            );
        }
    }

    getfilelink(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].requirement_file) {
                    return (
                        <li
                            style={{
                                fontSize: 13,
                                fontWeight: 500,
                                letterSpacing: 1,
                                color: "#212529",
                            }}
                            onClick={() => {
                                window.open(
                                    filtered[0].requirement_file,
                                    "_blank" // <- This is what makes it open in a new window.
                                );
                            }}
                        >
                            <font style={{ marginRight: 15 }}>|</font>
                            VOORWAARDEN
                        </li>
                    );
                } else if (filtered[0].termsText) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                termsTextPopup: true,
                                currentTermsText: filtered[0].termsText,
                            });
                        }}
                        >
                            <font style={{ marginRight: 15 }}>|</font>
                            VOORWAARDEN
                        </label>
                    );
                }
            }
        }
        if (this.props.global_values) {
            if (this.props.global_values.requirement_file) {
                return (
                    <li
                        style={{
                            fontSize: 13,
                            fontWeight: 500,
                            letterSpacing: 1,
                            color: "#212529",
                        }}
                        onClick={() => {
                            window.open(
                                this.props.global_values.requirement_file,
                                "_blank" // <- This is what makes it open in a new window.
                            );
                        }}
                    >
                        <font style={{ marginRight: 15 }}>|</font>
                        VOORWAARDEN
                    </li>
                );
            } else if (this.props.global_values.termsText) {
                return (
                    <label onClick={() => {
                        this.setState({
                            termsTextPopup: true,
                            currentTermsText: this.props.global_values.termsText,
                        });
                    }}
                    >
                        <font style={{ marginRight: 15 }}>|</font>
                        VOORWAARDEN
                    </label>
                );
            }
        }
    }

    getfilelinkMobile(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === this.state.currentCampaignHere[0].created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].requirement_file) {
                    return (
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                            onClick={() => {
                                window.open(
                                    filtered[0].requirement_file,
                                    "_blank" // <- This is what makes it open in a new window.
                                );
                            }}
                        >
                            VOORWAARDEN
                        </p>
                    );
                } else if (filtered[0].termsText) {
                    return (
                        <>
                            <p
                                className="desc-heading-asp"
                                style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                            >
                                VOORWAARDEN
                            </p>
                            {filtered[0].termsText.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                                this.setState({
                                    headingOdiMain: "VOORWAARDEN",
                                    openDetailedInner: true,
                                    paraOdiMain: filtered[0].termsText
                                })
                            }}>
                                {filtered[0].termsText.substr(0, 130)}...
                            </p>) : (<p className="desc-content-asp">
                                {filtered[0].termsText}
                            </p>)}
                        </>
                    );
                }
            }
        }
        if (this.props.global_values) {
            if (this.props.global_values.requirement_file) {
                return (
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        onClick={() => {
                            window.open(
                                this.props.global_values.requirement_file,
                                "_blank" // <- This is what makes it open in a new window.
                            );
                        }}
                    >
                        VOORWAARDEN
                    </p>
                );
            } else if (this.props.global_values.termsText) {
                return (
                    <>
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        >
                            VOORWAARDEN
                        </p>
                        {this.props.global_values.termsText.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                            this.setState({
                                headingOdiMain: "VOORWAARDEN",
                                openDetailedInner: true,
                                paraOdiMain: this.props.global_values.termsText
                            })
                        }}>
                            {this.props.global_values.termsText.substr(0, 130)}...
                        </p>) : (<p className="desc-content-asp">
                            {this.props.global_values.termsText}
                        </p>)}
                    </>
                );
            }
        }
    }


    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    componentDidUpdate(prevProps) {


        if (this.props.global_values !== prevProps.global_values) {
            let a = this.props.global_values;
            if (a) {
                let localEventInfoMobile = ""

                if (a.localEventInfoMobile) {
                    localEventInfoMobile = a.localEventInfoMobile
                }
                this.setState({
                    localEventInfoMobile,
                });
            }
        }

        if (this.props.guest_user_cart) {
            if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
                this.setState({ guestUserCart: this.props.guest_user_cart });
            }
        }
        if (this.props.global_campaigns) {
            if (prevProps.global_campaigns !== this.props.global_campaigns) {
                let filtered = this.props.global_campaigns.filter((g) => {
                    return g[1].pushKey === this.state.currentCampaignHere[0].pushKey
                })
                if (filtered.length > 0) {
                    let campaignToUpload = Object.assign({}, filtered[0][1])

                    if (campaignToUpload.takeFromAdmin) {
                        let obj = mergeAdminEvent(campaignToUpload, this.props.global_campaigns)
                        campaignToUpload = Object.assign({}, obj)
                    }
                    campaignToUpload.type = "campaign"

                    this.state.currentCampaignHere[0] = campaignToUpload
                    this.setState({ currentCampaignHere: this.state.currentCampaignHere })
                }
            }
        }
        if (this.props.user !== prevProps.user) {
            let uid = localStorage.getItem("userid");
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((j) => {
                    return j.user_id === uid;
                });
                if (filtered.length > 0) {
                    if (this.state.currentUserHere !== filtered[0]) {
                        this.setState({ currentUserHere: filtered[0] });
                    }
                }

                if (this.state.currentCampaignHere.length > 0) {
                    let filteredMore = this.props.user.filter((g) => {
                        return g.user_id === this.props.campaignToShow[0].created_by;
                    });
                    if (filteredMore.length > 0) {
                        this.setState({ currentUser: filteredMore[0] });
                    }
                }
            }
        }
    }

    getDayName(num) {
        if (num === 0) {
            return `Zondag`;
        } else if (num === 1) {
            return `Maandag`;
        } else if (num === 2) {
            return `Dinsdag`;
        } else if (num === 3) {
            return `Woensdag`;
        } else if (num === 4) {
            return `Donderdag`;
        } else if (num === 5) {
            return `Vrijdag`;
        } else if (num === 6) {
            return `Zaterdag`;
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (window.innerWidth < 1008) {
            if (i === 1) {
                return "jan";
            } else if (i === 2) {
                return "feb";
            } else if (i === 3) {
                return "mrt";
            } else if (i === 4) {
                return "apr";
            } else if (i === 5) {
                return "mei";
            } else if (i === 6) {
                return "jun";
            } else if (i === 7) {
                return "jul";
            } else if (i === 8) {
                return "aug";
            } else if (i === 9) {
                return "sep";
            } else if (i === 10) {
                return "okt";
            } else if (i === 11) {
                return "nov";
            } else if (i === 12) {
                return "dec";
            }
        }
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }

    getFormattedStartDateLocalEvent() {
        if (this.state.currentCampaignHere[0].timings && this.state.currentCampaignHere[0].timings.length > 0) {
            let currentTimings = this.state.currentCampaignHere[0].timings[this.state.selectedTimeIndex]
            let date = new Date(
                currentTimings.isoStartTime ? currentTimings.isoStartTime : currentTimings.startDateEvent
            );
            let endDate = new Date(
                currentTimings.isoEndTime ? currentTimings.isoEndTime : currentTimings.endDateEvent
            );
            if (date.getMonth() === endDate.getMonth() && date.getFullYear() === endDate.getFullYear()) {
                if (date.getDate() === endDate.getDate()) {
                    return `${date.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} 
                    ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) ? `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur` : ""} 
                    ${(!this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) ? "|" : ""} ${date.getFullYear()}`;
                } else {
                    return `${date.getDate()} t/m ${endDate.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} 
                    ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) ? `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur` : ""} 
                    ${(!this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) ? "|" : ""} ${date.getFullYear()}`;
                }
            } else {
                if (date.getFullYear() === endDate.getFullYear()) {
                    return `${date.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} t/m ${endDate.getDate()} ${this.getMonthName(
                        endDate.getMonth() + 1
                    )} 
                     ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) ? `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur` : ""} 
                    ${(!this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) ? "|" : ""} ${date.getFullYear()}`;
                } else {
                    return `${date.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} ${date.getFullYear()} t/m ${endDate.getDate()} ${this.getMonthName(
                        endDate.getMonth() + 1
                    )} ${endDate.getFullYear()}
                     ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) ? `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur` : ""} `;
                }
            }
        } else {
            return ""
        }
    }

    getLocalEventSubscribeButton() {
        let event = this.state.currentCampaignHere[0]
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }
        let alreadyInCart = false;

        if (localEventIfSharedThen.inCartOf) {
            let objected = Object.entries(localEventIfSharedThen.inCartOf);
            objected = objected.filter((t) => {
                return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
            })
            let cookieItem = localStorage.getItem("cookie_item");
            let filtered = objected.filter((h) => {
                if (this.state.currentUserId) {
                    return h[1].cookie === this.state.currentUserId && h[1].event_id === this.state.reserved_event_id;
                } else {
                    return h[1].cookie === cookieItem && h[1].event_id === this.state.reserved_event_id;
                }
            });
            if (filtered.length > 0) {
                alreadyInCart = true;
            }
        }
        if (alreadyInCart) {
            return (
                <button
                    style={{ backgroundColor: "#C17F6B", color: "white" }}
                >
                    Toegevoegd
                </button>
            );
        } else {
            let qtyToMinus = 0;
            if (localEventIfSharedThen.inCartOf) {
                let objected = Object.entries(localEventIfSharedThen.inCartOf);
                objected = objected.filter((t) => {
                    return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                })
                qtyToMinus = qtyToMinus + objected.length;
            }
            if (localEventIfSharedThen.checked_in_by) {
                let objected = Object.entries(localEventIfSharedThen.checked_in_by);
                objected = objected.filter((t) => {
                    return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                })
                qtyToMinus = qtyToMinus + objected.length;
            }
            if (currentLocalEvent.timings && currentLocalEvent.timings.length > 0) {
                if (
                    Number(currentLocalEvent.timings[this.state.selectedTimeIndex].personsAmountEvent) -
                    qtyToMinus <=
                    0
                ) {
                    return (
                        <button style={{ backgroundColor: "#C17F6B", color: "white" }}>
                            Uitverkocht
                        </button>
                    );
                }
            }
            return (
                <>
                    <button
                        onClick={this.addToCartLocalEvent.bind(this, currentLocalEvent)}
                    >
                        {currentLocalEvent.created_by === this.state.currentUserId ? "Boek nu" : `Boek nu`}
                    </button>
                    <div className="image-front addIconCart">
                        <AddIcon />
                    </div>
                </>
            );
        }
    }

    addToCartLocalEvent() {

        let e = this.state.currentCampaignHere[0];

        let imgtodrag = document.getElementsByClassName("addIconCart")[0];
        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let arrCart = [...this.state.guestUserCart];
        let cookieItem = localStorage.getItem("userid");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
            event_id: this.state.reserved_event_id,
        };

        let pushKeyInCartOf = firebase
            .database()
            .ref(
                `bhodi_campaigns/${this.state.currentUser.user_id}/${this.state.currentCampaignHere[0].pushKey}/inCartOf`
            )
            .push().key;
        let objInCartOf = {
            cookie: cookieItem,
            pushKey: pushKeyInCartOf,
            event_id: this.state.reserved_event_id,
        };

        objToMake.pushKeyInCartOf = pushKeyInCartOf;
        objToMake.creatorCompanyId = this.state.currentUser.user_id;

        firebase
            .database()
            .ref(
                `bhodi_campaigns/${this.state.currentUser.user_id}/${this.state.currentCampaignHere[0].pushKey}/inCartOf/${pushKeyInCartOf}`
            )
            .set(objInCartOf);

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;

            objToMake.pushKey = pushKey;

            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);
        }

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }

    getWinkelmandbuttonUsersLocalBlock() {
        let event = this.state.currentCampaignHere[0]
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";

        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }

        let e = localEventIfSharedThen;
        if (this.state.currentUserHere) {
            if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
                if (window.innerWidth > 1009) {
                    let added_via_payment_link = []
                    if (e.added_via_payment_link) {
                        added_via_payment_link = Object.entries(e.added_via_payment_link)
                    }
                    if (e.checked_in_by || e.added_via_payment_link) {
                        let objected = []
                        if (e.checked_in_by) {
                            objected = Object.entries(e.checked_in_by)
                        }
                        if (added_via_payment_link.length > 0) {
                            objected.push(...added_via_payment_link)
                        }
                        let filtered = objected.filter((h) => {
                            return h[1].user_id === this.state.currentUserId && h[1].event_id === this.state.reserved_event_id;
                        });
                        if (filtered.length > 0) {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B", display: "inline-flex", alignItems: "center", color: "white" }}
                                // style={{ backgroundColor: "#C17F6B", display: "flex", alignItems: "center" }}
                                //         className="pink-color-btndcw pinkcolorbutton"
                                >
                                    <CheckIcon
                                        style={{ fontSize: 18, marginRight: 15, marginLeft: 0 }}
                                    />
                                    <font>{window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}</font>
                                </button>
                            );
                        } else {
                            let qtyToMinus = 0;
                            if (localEventIfSharedThen.inCartOf) {
                                let objected = Object.entries(localEventIfSharedThen.inCartOf);
                                objected = objected.filter((t) => {
                                    return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                                })
                                qtyToMinus = qtyToMinus + objected.length;
                            }
                            if (localEventIfSharedThen.checked_in_by) {
                                let objected = Object.entries(
                                    localEventIfSharedThen.checked_in_by
                                );
                                objected = objected.filter((t) => {
                                    return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                                })
                                qtyToMinus = qtyToMinus + objected.length;
                            }
                            if (
                                currentLocalEvent.timings &&
                                currentLocalEvent.timings.length > 0
                            ) {
                                if (
                                    Number(currentLocalEvent.timings[this.state.selectedTimeIndex].personsAmountEvent) -
                                    qtyToMinus <=
                                    0
                                ) {
                                    return (
                                        <button
                                            style={{ backgroundColor: "#C17F6B", color: "white" }}
                                        >
                                            Uitverkocht
                                        </button>
                                    );
                                }
                            }
                            return (
                                <button
                                    onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)}
                                >
                                    <font>
                                        Boek nu
                                    </font>
                                    <div className="image-local-event addIconCart">
                                        <AddIcon />
                                    </div>
                                </button>
                            );
                        }
                    } else {
                        let qtyToMinus = 0;
                        if (localEventIfSharedThen.inCartOf) {
                            let objected = Object.entries(localEventIfSharedThen.inCartOf);
                            objected = objected.filter((t) => {
                                return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                            })
                            qtyToMinus = qtyToMinus + objected.length;
                        }
                        if (localEventIfSharedThen.checked_in_by) {
                            let objected = Object.entries(
                                localEventIfSharedThen.checked_in_by
                            );
                            objected = objected.filter((t) => {
                                return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                            })
                            qtyToMinus = qtyToMinus + objected.length;
                        }
                        if (
                            currentLocalEvent.timings &&
                            currentLocalEvent.timings.length > 0
                        ) {
                            if (
                                Number(currentLocalEvent.timings[this.state.selectedTimeIndex].personsAmountEvent) -
                                qtyToMinus <=
                                0
                            ) {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B", color: "white" }}
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        }
                        return (
                            <button
                                onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)}
                            >
                                <a>
                                    Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                                </a>
                                <div className="image-local-event addIconCart">
                                    <AddIcon />
                                </div>
                            </button>
                        );
                    }
                } else {
                    if (
                        this.state.currentUserHere.stripe_id &&
                        !this.state.userNotSubscribed
                    ) {
                        let added_via_payment_link = []
                        if (e.added_via_payment_link) {
                            added_via_payment_link = Object.entries(e.added_via_payment_link)
                        }
                        if (e.checked_in_by || e.added_via_payment_link) {
                            let objected = []
                            if (e.checked_in_by) {
                                objected = Object.entries(e.checked_in_by)
                            }
                            if (added_via_payment_link.length > 0) {
                                objected.concat(added_via_payment_link)
                            }
                            let filtered = objected.filter((h) => {
                                return h[1].user_id === this.state.currentUserId && h[1].event_id === this.state.reserved_event_id;
                            });
                            if (filtered.length > 0) {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B", display: "inline-flex", alignItems: "center", color: "white" }}
                                    >
                                        <CheckIcon
                                            style={{ fontSize: 18, marginRight: 15, marginLeft: 0 }}
                                        />
                                        <font>{window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}</font>
                                        {/* <a>In orderoverzicht</a> */}
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        onClick={this.verzilverLocalBlock.bind(
                                            this,
                                            currentLocalEvent
                                        )}
                                    >
                                        <font>
                                            Boek nu
                                        </font>
                                        <div className="image-local-event addIconCart">
                                            <AddIcon />
                                        </div>
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button
                                    onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)}
                                >
                                    <font>
                                        Boek nu
                                    </font>
                                    <div className="image-local-event addIconCart">
                                        <AddIcon />
                                    </div>
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={() => (window.location.href = "/charity")}>
                                Register
                            </button>
                        );
                    }
                }
            }
        }
    }

    verzilverLocalBlock() {
        this.setState({
            verzilverPopupLocalEvent: true,
        });
    }

    proceedLocalBlock() {
        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);

        this.state.currentCampaignHere[0].reserved_event_id = this.state.reserved_event_id;

        let obj = {
            created_on: today,
            order_id,
            products: [this.state.currentCampaignHere[0]],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        let pushuserOrderCurrentUser = firebase
            .database()
            .ref("users/" + this.state.currentUserId + "/orders")
            .push().key;
        firebase
            .database()
            .ref(
                "users/" +
                this.state.currentUserId +
                "/orders/" +
                pushuserOrderCurrentUser
            )
            .set(obj);
        let pushuserOrder = firebase
            .database()
            .ref("users/" + this.state.currentUser.user_id + "/orders")
            .push().key;
        firebase
            .database()
            .ref(
                "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
            )
            .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;

        obj.pushKey = orderKey;

        if (obj.products[0].campaignType === "local_event" && !obj.products[0].manual_code_import) {
            let toSendEmailkey = firebase.database().ref("events_for_emails").push().key;
            obj.toSendEmailkey = toSendEmailkey;


            let objToSendEmailValidate = {
                orderKey,
                pushKey: toSendEmailkey,
                created_at: new Date().toISOString(),
                event_key: obj.products[0].pushKey,
                buyer_id: obj.buyer,
            }

            if (obj.products[0].timings) {
                if (
                    obj.products[0].timings.length > 0
                ) {
                    let filtered = obj.products[0].timings.filter((j) => {
                        return j.eventId === obj.products[0].reserved_event_id;
                    });
                    if (filtered.length > 0) {
                        let dateMax = new Date(filtered[0].startDateEvent);
                        if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                            dateMax = new Date(Date.parse(filtered[0].isoStartTime))
                        } else {
                            let endTimeGood = filtered[0].startTimeEvent.concat(":00");
                            dateMax.setHours(endTimeGood.split(":")[0]);
                            dateMax.setMinutes(endTimeGood.split(":")[1]);
                        }
                        objToSendEmailValidate.send_email_at = dateMax.toISOString()

                        firebase.database().ref("events_for_emails/" + toSendEmailkey).set(objToSendEmailValidate)
                    }
                }
            }
        }

        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);

        // checked In

        let newDate = new Date().getTime();
        let arr = [];
        arr.push(newDate);
        let pushKey = firebase
            .database()
            .ref(
                "bhodi_campaigns/" +
                this.state.currentUser.user_id +
                "/" +
                this.state.currentCampaignHere[0].pushKey +
                "/checked_in_by"
            )
            .push().key;
        let objCheckedIn = {
            user_id: this.state.currentUserId,
            date: newDate,
            allDates: arr,
            pushKey,
            event_id: this.state.reserved_event_id,
            order_key: orderKey,
        };
        firebase
            .database()
            .ref(
                "bhodi_campaigns/" +
                this.state.currentUser.user_id +
                "/" +
                this.state.currentCampaignHere[0].pushKey +
                "/checked_in_by/" +
                pushKey
            )
            .set(objCheckedIn);

        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
        // if (!isDesktop) {
        //     this.setState({ verzilverPopup: false, verzilverPopupGreen: true })
        // } else {
        //     this.setState({ verzilverPopup: false, verzilverPopupGreen: false })
        // }
        this.setState({ verzilverPopup: false, verzilverPopupGreen: false, verzilverPopupLocalEvent: false, verzilverPopupGreenLocalEvent: true });
    }

    getStartTimeOfReservedEvent() {
        if (this.state.currentCampaignHere.length > 0) {
            if (this.state.currentCampaignHere[0].campaignType === "local_event") {
                let filtered = this.state.currentCampaignHere[0].timings
                if (filtered.length > 0) {
                    return filtered[this.state.selectedTimeIndex].startTimeEvent;
                }
            }
        }
    }

    getEndTimeOfReservedEvent() {
        if (this.state.currentCampaignHere.length > 0) {
            if (this.state.currentCampaignHere[0].campaignType === "local_event") {
                let filtered = this.state.currentCampaignHere[0].timings
                if (filtered.length > 0) {
                    return filtered[this.state.selectedTimeIndex].endTimeEvent;
                }
            }
        }
    }


    checkForBookMarkIconEvent(g) {
        if (g.bookmarked_by) {
            let objected = Object.entries(
                g.bookmarked_by
            );
            let filtered = objected.filter((g) => {
                return g[1].bookmarked_by === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() =>
                            unBookmarkCurrentProduct(g)
                        }
                    >
                        <AiFillStar />
                    </div>
                );
            } else {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() => bookmarkCurrentProduct(g)}
                    >
                        <AiOutlineStar />
                    </div>
                );
            }
        } else {
            return (
                <div
                    className="icons-lcltsmhnc"
                    onClick={() => bookmarkCurrentProduct(g)}
                >
                    <AiOutlineStar />
                </div>
            );
        }
    }

    createAndMoveToPaymentLink() {
        let filtered = this.props.subscription_plans.filter((t) => {
            return t.price === "7.50"
        })

        if (filtered.length > 0) {
            let stripe_plan_key = filtered[0].stripe_plan_key
            let plan_id = filtered[0].plan_id
            let plan_amount = filtered[0].price

            let apiDataRaw = {
                company_id: this.props.currentCompany.user_id,
                campaign_id: this.state.currentCampaignHere[0].pushKey,
                stripe_plan_key,
                access_code: this.props.accessCode,
                plan_id,
                plan_amount,
            }

            var dataForAPI = JSON.stringify(apiDataRaw);

            var config = {
                method: "post",
                url: "https://us-central1-bhodi-7c327.cloudfunctions.net/createPaymentLink",
                headers: {
                    "Content-Type": "application/json",
                },
                data: dataForAPI,
            };

            axios(config)
                .then(async (response) => {
                    let apiData = response.data.data
                    let pushKey = firebase.database().ref("payment_links_temporary/" + this.props.currentCompany.user_id).push().key
                    let obj = {
                        pushKey,
                        company_id: this.props.currentCompany.user_id,
                        access_code: this.props.accessCode,
                        payment_id: apiData.id,
                        payment_url: apiData.url,
                        plan_amount,
                    }
                    await firebase.database().ref("payment_links_temporary/" + this.props.currentCompany.user_id + "/" + pushKey).set(obj)
                    window.location.href = obj.payment_url
                })
        }
    }

    checkForWinkelmandButtonLocalEvent() {
        let event = this.state.currentCampaignHere[0]
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }
        if (this.state.guestUserCart) {
            let filtered = this.state.guestUserCart.filter((h) => {
                return h.pushKey === localEventIfSharedThen.pushKey && (h.event_id === this.state.reserved_event_id || h.reserved_event_id === this.state.reserved_event_id);
            });
            if (filtered.length > 0) {
                return (
                    <button
                        style={{ backgroundColor: "#C17F6B" }}
                        className="pink-color-btndcw pinkcolorbutton"
                    >
                        <a>Toegevoegd</a>
                    </button>
                );
            } else {
                let qtyToMinus = 0;
                if (localEventIfSharedThen.inCartOf) {
                    let objected = Object.entries(localEventIfSharedThen.inCartOf);
                    objected = objected.filter((t) => {
                        return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                    })
                    qtyToMinus = qtyToMinus + objected.length;
                }
                if (localEventIfSharedThen.checked_in_by) {
                    let objected = Object.entries(localEventIfSharedThen.checked_in_by);
                    objected = objected.filter((t) => {
                        return t[1].event_id === currentLocalEvent.timings[this.state.selectedTimeIndex].eventId
                    })
                    qtyToMinus = qtyToMinus + objected.length;
                }
                if (currentLocalEvent.timings && currentLocalEvent.timings.length > 0) {
                    if (
                        Number(currentLocalEvent.timings[this.state.selectedTimeIndex].personsAmountEvent) - qtyToMinus <= 0
                    ) {
                        return (
                            <button style={{ backgroundColor: "#C17F6B", color: "white" }} className="pinkcolorbutton">
                                Uitverkocht
                            </button>
                        );
                    }
                }
                if (this.props.showQrCode) {
                    return (<div className="qr-at-campaign">
                        <QRCodeCanvas value={`${window.location.origin}/campaign/${this.props.currentCompany.user_id}`} size={200} />
                    </div>)
                }
                if ((this.props.currentCompany && this.props.currentCompany.redirect_guest_to_link_via_event) || this.props.showPaymentLink || this.props.redirectToPaymentLink) {
                    return (<button
                        style={{ position: "relative" }}
                        onClick={() => {
                            if (this.props.showPaymentLink) {
                                this.createAndMoveToPaymentLink()
                            } else {
                                window.location.href = this.props.currentCompany.donation_link_client
                            }
                        }}
                        className={window.innerWidth < 1008 && "pink-color-btndcw"}
                    >
                        <a>AANMELDEN</a>
                        <div className="image-live-event addIconCart">
                            <AddIcon />
                        </div>
                    </button>)
                }
                return (
                    <button
                        style={{ position: "relative" }}
                        onClick={this.addToCartLocalEventGuestUser.bind(
                            this,
                            localEventIfSharedThen
                        )} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                    >
                        <a>Boek nu</a>
                        <div className="image-live-event addIconCart">
                            <AddIcon />
                        </div>
                    </button>
                );
            }
        } else {
            if (this.props.showQrCode) {
                return (<div className="qr-at-campaign">
                    <QRCodeCanvas value={`${window.location.origin}/campaign/${this.props.currentCompany.user_id}`} size={200} />
                </div>)
            }
            if ((this.props.currentCompany && this.props.currentCompany.redirect_guest_to_link_via_event) || this.props.showPaymentLink || this.props.redirectToPaymentLink) {
                return (<button
                    style={{ position: "relative" }}
                    onClick={() => {
                        if (this.props.showPaymentLink) {
                            this.createAndMoveToPaymentLink()
                        } else {
                            window.location.href = this.props.currentCompany.donation_link_client
                        }
                    }}
                    className={window.innerWidth < 1008 && "pink-color-btndcw"}
                >
                    <a>AANMELDEN</a>
                    <div className="image-live-event addIconCart">
                        <AddIcon />
                    </div>
                </button>)
            }
            return (
                <button
                    style={{ position: "relative" }}
                    onClick={this.addToCartLocalEventGuestUser.bind(
                        this,
                        localEventIfSharedThen
                    )} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                >
                    <a>Boek nu</a>
                    {/* <a>WINKELMAND</a> */}
                    <div className="image-live-event addIconCart">
                        <AddIcon />
                    </div>
                </button>
            );
        }
    }


    addToCartLocalEventGuestUser(e) {
        // let f = e
        // let imgtodrag = document.getElementsByClassName('image-live-event')[0];
        let imgtodrag = document.getElementsByClassName("addIconCart")[0];
        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let arrCart = [...this.state.guestUserCart];
        let cookieItem = localStorage.getItem("cookie_item");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
            event_id: this.state.reserved_event_id,
        };

        let pushKeyInCartOf = firebase
            .database()
            .ref(
                `bhodi_campaigns/${this.state.currentUser.user_id}/${e.pushKey}/inCartOf`
            )
            .push().key;
        let objInCartOf = {
            cookie: cookieItem,
            pushKey: pushKeyInCartOf,
            event_id: this.state.reserved_event_id,
        };

        objToMake.pushKeyInCartOf = pushKeyInCartOf;
        objToMake.creatorCompanyId = this.state.currentUser.user_id;

        firebase
            .database()
            .ref(
                `bhodi_campaigns/${this.state.currentUser.user_id}/${e.pushKey}/inCartOf/${pushKeyInCartOf}`
            )
            .set(objInCartOf);

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);
        } else {
            if (cookieItem) {
                let pushKey = firebase
                    .database()
                    .ref("guest_carts/" + cookieItem)
                    .push().key;
                objToMake.pushKey = pushKey;
                firebase
                    .database()
                    .ref("guest_carts/" + cookieItem + "/" + pushKey)
                    .set(objToMake);
            }
        }

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }

    getUserName(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].user_id === process.env.REACT_APP_ADMIN_ID) {
                    return `${filtered[0].first_name}`
                } else {
                    return `${filtered[0].company_name}`
                }
            }
        }
    }

    getUserCity(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].user_id === process.env.REACT_APP_ADMIN_ID) {
                    return `Borne | Netherlands`
                }
                if (filtered[0].city) {
                    return `${filtered[0].city} | ${filtered[0].country}`
                }
                return `${filtered[0].country}`
            }
        }
    }

    getUserProfilePic(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                    return `${filtered[0].profile_image}`
                }
            }
        }
        return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
    }

    checkForFollowIcon(creatorId) {
        let currentUserMember = this.props.user.filter((t) => {
            return t.user_id === creatorId
        })
        if (currentUserMember[0].followed_by) {
            let objected = Object.entries(currentUserMember[0].followed_by);
            let filtered = objected.filter((g) => {
                return g[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <RiUserUnfollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
                );
            } else {
                return (
                    <RiUserFollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
                );
            }
        } else {
            return (
                <RiUserFollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
            );
        }
    }

    redirectUser(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "company") {
                    history.push(`/company/${filtered[0].user_name_id}`)
                } else {
                    history.push(`/member/${filtered[0].user_name_id}`)
                }
            }
        }
    }

    followCompany(currentUser) {
        followUnfollowCompany(currentUser, this.state.currentUserId, this.props.user)
    }

    // followCompany(currentUser) {
    //     if (currentUser.followed_by) {
    //         let objected = Object.entries(currentUser.followed_by);
    //         let filtered = objected.filter((g) => {
    //             return g[1].user_id === this.state.currentUserId;
    //         });
    //         if (filtered.length > 0) {
    //             if (currentUser.newFollowersList) {
    //                 let objectedSec = Object.entries(
    //                     currentUser.newFollowersList
    //                 );
    //                 let filteredNewFollowers = objectedSec.filter((g) => {
    //                     return g[1].user_id === this.state.currentUserId;
    //                 });
    //                 if (filteredNewFollowers.length > 0) {
    //                     let newFollowers = Number(currentUser.newFollowers);
    //                     newFollowers = newFollowers - 1;
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             currentUser.user_id +
    //                             "/newFollowersList/" +
    //                             filteredNewFollowers[0][1].push_key
    //                         )
    //                         .remove();
    //                     firebase
    //                         .database()
    //                         .ref("users/" + currentUser.user_id + "/newFollowers")
    //                         .set(newFollowers);
    //                 }
    //             }
    //             let filteredCurrentUser = this.props.user.filter((h) => {
    //                 return h.user_id === this.state.currentUserId;
    //             });
    //             if (filteredCurrentUser[0].following) {
    //                 let objectedSec = Object.entries(filteredCurrentUser[0].following);
    //                 let filteredSec = objectedSec.filter((g) => {
    //                     return g[1].user_id === currentUser.user_id;
    //                 });
    //                 if (filteredSec.length > 0) {
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.state.currentUserId +
    //                             "/following/" +
    //                             filteredSec[0][1].push_key
    //                         )
    //                         .remove();
    //                 }
    //             }
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/followed_by/" +
    //                     filtered[0][1].push_key
    //                 )
    //                 .remove();
    //         } else {
    //             let pushFirst = firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following")
    //                 .push().key;
    //             let pushSecond = firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/followed_by")
    //                 .push().key;
    //             let pushSecondNew = firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/newFollowersList")
    //                 .push().key;
    //             let newFollowers = 0;
    //             let obj = {
    //                 user_id: currentUser.user_id,
    //                 push_key: pushFirst,
    //             };
    //             let objSec = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecond,
    //             };
    //             let objThird = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecondNew,
    //             };
    //             if (currentUser.newFollowers) {
    //                 newFollowers = Number(currentUser.newFollowers);
    //             }
    //             newFollowers = newFollowers + 1;
    //             firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //                 .set(obj);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/followed_by/" +
    //                     pushSecond
    //                 )
    //                 .set(objSec);
    //             firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/newFollowers")
    //                 .set(newFollowers);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/newFollowersList/" +
    //                     pushSecondNew
    //                 )
    //                 .set(objThird);
    //         }
    //     } else {
    //         let pushFirst = firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following")
    //             .push().key;
    //         let pushSecond = firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/followed_by")
    //             .push().key;
    //         let pushSecondNew = firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/newFollowersList")
    //             .push().key;
    //         let newFollowers = 0;
    //         let obj = {
    //             user_id: currentUser.user_id,
    //             push_key: pushFirst,
    //         };
    //         let objSec = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecond,
    //         };
    //         let objThird = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecondNew,
    //         };
    //         if (currentUser.newFollowers) {
    //             newFollowers = Number(currentUser.newFollowers);
    //         }
    //         newFollowers = newFollowers + 1;
    //         firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //             .set(obj);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 currentUser.user_id +
    //                 "/followed_by/" +
    //                 pushSecond
    //             )
    //             .set(objSec);
    //         firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/newFollowers")
    //             .set(newFollowers);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 currentUser.user_id +
    //                 "/newFollowersList/" +
    //                 pushSecondNew
    //             )
    //             .set(objThird);
    //     }
    // }

    truncateAndSanitize(html, maxLength) {
        // Use DOMPurify to sanitize the HTML content.
        const sanitizedHTML = DOMPurify.sanitize(html);

        if (sanitizedHTML.length <= maxLength) {
            return sanitizedHTML;
        } else {
            return sanitizedHTML.substring(0, maxLength) + '...';
        }
    };

    createMarkup(e) {
        if (e) {
            if (window.innerWidth > 1008) {
                const truncatedDescription = this.truncateAndSanitize(e, window.innerWidth < 1008 ? 80 : 320);

                return { __html: truncatedDescription };
            } else {
                return { __html: e };
            }
        }
    }

    createMarkupComplete(e) {
        if (e) {
            return { __html: e };
        }
    }

    createDescPopup(e) {
        if (e) {
            return { __html: e };
        }
    }

    render() {
        return (
            <div className={`third-section-mhn ${window.innerWidth < 1008 && "tsmhn-global"}`}>
                {/* local event verzilver */}
                <div
                    className="verzilver-popup-white-opacity"
                    style={{
                        opacity: this.state.verzilverPopupLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupLocalEvent ? "all" : "none",
                    }}
                ></div>
                <div
                    className="verzilver-popup-white"
                    style={{
                        opacity: this.state.verzilverPopupLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupLocalEvent ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        className="close-hpw"
                        onClick={() => this.setState({ verzilverPopupLocalEvent: false })}
                    />
                    <p
                        className="heading-hpw"
                        style={{
                            textTransform: "uppercase",
                            marginBottom: 6,
                            fontSize: 16,
                            letterSpacing: 2,
                        }}
                    >
                        Aanmelding
                    </p>
                    <div
                        style={{
                            width: 40,
                            height: 3,
                            backgroundColor: "#C17F6B",
                            margin: "0px auto",
                            marginBottom: 25,
                        }}
                    ></div>
                    <p className="desc-hpw">
                        Leuk, je staat op het punt om je
                        <br />
                        aan te melden voor ons event!
                        <br />
                        <br />
                        Klik op de onderstaande knop
                        <br />
                        om dit te bevestigen.
                    </p>
                    <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                        <button
                            style={{ marginRight: 5 }}
                            onClick={() => {
                                this.proceedLocalBlock()
                            }}
                        >
                            OK
                        </button>
                    </div>
                </div>
                <div
                    className="verzilver-popup-white-opacity"
                    style={{
                        opacity: this.state.verzilverPopupGreenLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupGreenLocalEvent
                            ? "all"
                            : "none",
                    }}
                ></div>
                <div
                    className="verzilver-popup-white"
                    style={{
                        opacity: this.state.verzilverPopupGreenLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupGreenLocalEvent
                            ? "all"
                            : "none",
                    }}
                >
                    <CloseIcon
                        className="close-hpw"
                        onClick={() =>
                            this.setState({ verzilverPopupGreenLocalEvent: false })
                        }
                    />
                    <p
                        className="heading-hpw"
                        style={{
                            textTransform: "uppercase",
                            marginBottom: 6,
                            fontSize: 16,
                            letterSpacing: 2,
                        }}
                    >
                        {this.state.currentUser.company_name}
                    </p>
                    <p className="heading-hpw" style={{ textTransform: "uppercase" }}>
                        Bevestiging
                    </p>
                    <div
                        style={{
                            width: 40,
                            height: 3,
                            backgroundColor: "#C17F6B",
                            margin: "0px auto",
                            marginBottom: 25,
                        }}
                    ></div>
                    <p className="desc-hpw">
                        Je aanmelding is ontvangen, <br />
                        deze vind je terug in je <br />
                        <u
                            style={{ cursor: "pointer" }}
                            onClick={() => (window.location.href = "/order-list")}
                        >
                            orderoverzicht
                        </u>
                        .
                        <br />
                        <br />
                        {/* We zien je graag op{" "}
                        <br />
                        {this.getStartTimeOfReservedEvent()} tot{" "}
                        {this.getEndTimeOfReservedEvent()} */}
                        {/* <br /> */}
                        Wij verheugen ons op je komst! <br />
                    </p>
                    {/* {this.state.currentUser.phoneNumber && <p className="heading-hpw" style={{ marginBottom: 6 }}>Tel: {this.state.currentUser.phoneNumber}</p>}
                    {this.state.currentUser.company_website && <p className="heading-hpw">{this.state.currentUser.company_website}</p>} */}
                    <button
                        style={{ backgroundColor: "#C17F6B" }}
                        onClick={() => {
                            this.setState({
                                verzilverPopupLocalEvent: false,
                                verzilverPopupGreenLocalEvent: false,
                            });
                        }}
                    >
                        SLUIT
                    </button>
                </div>

                {/* local event verzilver */}

                {this.state.campaignsSorted.length > 0 &&
                    this.state.campaignsSorted.map((g) => {
                        if (g === "local_event") {
                            return (
                                <div style={{ position: "relative" }}>
                                    {window.innerWidth < 1008 && (<div className="top-userinfo-tsco">
                                        <img src={this.getUserProfilePic(this.state.currentCampaignHere[0].created_by)} onClick={() => {
                                            localStorage.setItem("scrollToTop", true)
                                            this.redirectUser(this.state.currentCampaignHere[0].created_by)
                                        }} />
                                        <div className="cont-tus-tsco">
                                            <p onClick={() => {
                                                localStorage.setItem("scrollToTop", true)
                                                this.redirectUser(this.state.currentCampaignHere[0].created_by)
                                            }}>{this.getUserName(this.state.currentCampaignHere[0].created_by)}</p>
                                            <p>{this.getUserCity(this.state.currentCampaignHere[0].created_by)}</p>
                                        </div>
                                        <div className="icons-main-lcl">
                                            {this.state.currentUserHere.user_id === this.state.currentCampaignHere[0].created_by && !this.props.redirectToPaymentLink && <div className="icons-lcltsmhnc" style={{ display: "inline-block", marginRight: 0 }} onClick={() => {
                                                if (window.innerWidth > 1008) {
                                                    this.props.onEditOpen()
                                                } else {
                                                    localStorage.setItem("localEventInfo", JSON.stringify(this.state.currentCampaignHere[0]))
                                                    history.push(`/editor/local_event`)
                                                }
                                            }}>
                                                <AiOutlineEdit style={{ color: "white" }} />
                                            </div>}
                                            {this.state.currentUserHere.user_id !== this.state.currentCampaignHere[0].created_by && this.state.currentUserId && this.checkForFollowIcon(this.state.currentCampaignHere[0].created_by)}
                                        </div>
                                    </div>)}
                                    <div className='tsmhn-container'>
                                        <div className='left-tsmhnc'>
                                            <div className='container-ltsmhnc'>
                                                <p>
                                                    Event
                                                    {(this.state.currentCampaignHere[0].amount && this.state.currentCampaignHere[0].localEventType === "percentage" && !this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) && (
                                                        <font>
                                                            &nbsp;{this.state.currentCampaignHere[0].amount}% Off
                                                        </font>
                                                    )}
                                                </p>
                                                <p>{this.state.currentCampaignHere[0].title}</p>
                                                {!this.state.currentCampaignHere[0].active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                {/* <p>{this.state.currentCampaignHere[0].desc}</p> */}
                                                {this.props.redirectToPaymentLink ? (<p>
                                                    <div dangerouslySetInnerHTML={this.createMarkupComplete(this.state.currentCampaignHere[0].desc)} className="description-content-cltsm"></div>
                                                </p>) : (window.innerWidth > 1008 && this.state.currentCampaignHere[0].first_desc) ? (<>
                                                    <p>
                                                        <div dangerouslySetInnerHTML={this.createMarkupComplete(this.state.currentCampaignHere[0].first_desc)} className="description-content-cltsm"></div>
                                                    </p>
                                                    <div style={{ marginTop: 10, display: "flex", alignItems: "center", justifyContent: "flex-start" }} onClick={() => {
                                                        this.setState({ showDescriptionPopupDesktop: true })
                                                    }}>
                                                        <p style={{ margin: 0, fontSize: 14, fontWeight: 500, color: "black", marginRight: 4, cursor: "pointer" }}>Lees meer</p>
                                                        <FaChevronRight size={12} style={{ cursor: "pointer" }} />
                                                    </div>
                                                </>) : (window.innerWidth < 1008 && this.state.currentCampaignHere[0].first_desc) ? (<>
                                                    <p>
                                                        <div dangerouslySetInnerHTML={this.createMarkupComplete(this.state.currentCampaignHere[0].first_desc)} className="description-content-cltsm"></div>
                                                        {this.state.expandDescription && (<div dangerouslySetInnerHTML={this.createMarkupComplete(this.state.currentCampaignHere[0].second_desc)} className="description-content-cltsm"></div>)}
                                                    </p>
                                                    {this.state.currentCampaignHere[0].second_desc && (<div style={{ marginTop: 10, display: "flex", alignItems: "center", justifyContent: "flex-start" }} onClick={() => {
                                                        this.setState({ expandDescription: !this.state.expandDescription })
                                                    }}>
                                                        <p style={{ margin: 0, fontSize: 11, fontWeight: 500, color: "black", marginRight: 4, cursor: "pointer" }}>{this.state.expandDescription ? "Lees minder" : "Lees meer"}</p>
                                                        {this.state.expandDescription ? (<FaChevronUp size={9} style={{ cursor: "pointer" }} />) : (<FaChevronDown size={9} style={{ cursor: "pointer" }} />)}
                                                    </div>)}
                                                </>) : (<p>
                                                    <div dangerouslySetInnerHTML={this.createMarkup(this.state.currentCampaignHere[0].desc)} className="description-content-cltsm" onClick={() => {
                                                        const truncatedDescription = this.truncateAndSanitize(this.state.currentCampaignHere[0].desc, window.innerWidth < 1008 ? 80 : 180);
                                                        if (window.innerWidth < 1008 && truncatedDescription.length > 80) {
                                                            this.setState({ showDescriptionPopup: true })
                                                        } else if (truncatedDescription.length > 180) {
                                                            this.setState({ showDescriptionPopupDesktop: true })
                                                        }
                                                    }}></div>
                                                </p>)}
                                                {/* {(window.innerWidth < 1008 && this.state.currentCampaignHere[0].desc.length > 80) ? (<p onClick={() => {
                                                    this.setState({ showDescriptionPopup: true })
                                                }}>{this.state.currentCampaignHere[0].desc.substr(0, 80)}...</p>) : (window.innerWidth > 1008 && this.state.currentCampaignHere[0].desc.length > 180) ? (<p onClick={() => {
                                                    this.setState({ showDescriptionPopupDesktop: true })
                                                }}>{this.state.currentCampaignHere[0].desc.substr(0, 180)}...</p>) : (<p>{this.state.currentCampaignHere[0].desc}</p>)} */}
                                                {!this.state.currentCampaignHere[0].hide_date_on_page && (<p>{this.getFormattedStartDateLocalEvent()}</p>)}
                                                {(this.state.currentCampaignHere[0].amount && this.state.currentCampaignHere[0].localEventType !== "percentage" && !this.state.currentCampaignHere[0].takeFromAdmin && this.state.currentCampaignHere[0].created_by !== process.env.REACT_APP_ADMIN_ID) && (<p>
                                                    <font>
                                                        €{" "}
                                                        <NumberFormatter
                                                            toFormat={this.state.currentCampaignHere[0].amount}
                                                        />{" "}
                                                        Korting
                                                    </font>
                                                </p>)}
                                                {/* <button>BOEK NU</button> */}
                                                {(this.state.currentCampaignHere[0].isoTimeTimerBtn && new Date(Date.parse(this.state.currentCampaignHere[0].isoTimeTimerBtn)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(this.state.currentCampaignHere[0].isoTimeTimerBtn)).getTime()} atProduct={this.state.currentCampaignHere[0]} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} closeScreen={() => {
                                                    this.setState({ updatePricing: true })
                                                }} />}
                                                {this.state.currentCampaignHere[0].active && this.state.currentCampaignHere[0].timings && <div id="innerbtnboeknulocalevent">
                                                    {this.state.currentCampaignHere[0].timings.length > 1 && (<button className="timing-btnContainer">
                                                        <div className="main-tibtncont" onClick={() => {
                                                            this.setState({ dropdownOpenTiming: !this.state.dropdownOpenTiming })
                                                        }}>
                                                            {this.state.selectedTiming ? (<font>{this.state.currentCampaignHere[0].timings[this.state.selectedTimeIndex].startTimeEvent}-{this.state.currentCampaignHere[0].timings[this.state.selectedTimeIndex].endTimeEvent} uur</font>) : (<font>Selecteer Tijd</font>)}
                                                            <RiArrowDownSLine />
                                                        </div>
                                                        <div className="dropdownContainer-tibtn" style={{ opacity: this.state.dropdownOpenTiming ? 1 : 0, pointerEvents: this.state.dropdownOpenTiming ? "all" : "none" }}>
                                                            <ul>
                                                                {this.state.currentCampaignHere[0].timings.map((t, index) => {
                                                                    let isUitverkocht = false
                                                                    return (<li onClick={() => {
                                                                        if (!isUitverkocht) {
                                                                            this.setState({ selectedTiming: true, dropdownOpenTiming: false, selectedTimeIndex: index, reserved_event_id: t.eventId })
                                                                        }
                                                                    }}>{t.startTimeEvent}-{t.endTimeEvent} uur {isUitverkocht ? ` (Uitverkocht)` : ""}</li>)
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </button>)}
                                                    {this.props.showQrCode ? (
                                                        <div className="qr-at-campaign">
                                                            <QRCodeCanvas value={`${window.location.origin}/campaign/${this.props.currentCompany.user_id}`} size={200} />
                                                        </div>
                                                    ) : (this.state.currentCampaignHere[0].timings.length === 1 || (this.state.currentCampaignHere[0].timings.length > 1 && this.state.selectedTiming)) && (<>
                                                        {this.state.currentUserHere &&
                                                            !this.state.currentUserCharityMember &&
                                                            this.state.showPlaceholderLocalEvent &&
                                                            this.getLocalEventSubscribeButton()}
                                                        {!this.state.currentUserId &&
                                                            !this.state.notShowTimerGuestLocalEvent
                                                            ? this.state.showPlaceholderLocalEvent &&
                                                            this.checkForWinkelmandButtonLocalEvent()
                                                            : !this.state.currentUserId
                                                                ? this.state.showPlaceholderLocalEvent && (
                                                                    <button
                                                                        style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton"
                                                                    >
                                                                        <a>UITVERKOCHT</a>
                                                                    </button>
                                                                )
                                                                : this.state.showPlaceholderLocalEvent &&
                                                                this.getWinkelmandbuttonUsersLocalBlock()}
                                                    </>)}
                                                </div>}
                                                {window.innerWidth > 1008 ? (<div className='labels-cltsmhnc'>
                                                    <label onClick={() => {
                                                        this.setState({ showDescriptionPopupDesktop: true })
                                                    }}>OMSCHRIJVING</label>
                                                    {this.getUseOfVoucherlocalEvent(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                    {this.getfilelink(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                    {/* Bookmark Icon */}
                                                    {this.state.currentUserId && !this.props.hideBookMarkIcon && this.checkForBookMarkIconEvent(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                    {this.state.currentUserHere.user_id === this.state.currentCampaignHere[0].created_by && !this.props.redirectToPaymentLink && <div className="icons-lcltsmhnc" onClick={() => {
                                                        if (window.innerWidth > 1008) {
                                                            this.props.onEditOpen()
                                                        } else {
                                                            localStorage.setItem("localEventInfo", JSON.stringify(this.state.currentCampaignHere[0]))
                                                            history.push(`/editor/local_event`)
                                                        }
                                                    }}>
                                                        <AiOutlineEdit style={{ color: "#C17F6B" }} />
                                                    </div>}
                                                    {/* {this.state.localEventInfoMobile && (<label onClick={() => {
                                                        this.setState({ showInfoPopup: true })
                                                    }}>
                                                        <VscInfo />
                                                    </label>)} */}
                                                </div>) : (<div className="icons-cltsmhnc-mob">
                                                    {/* <div className="icons-lcltsmhnc">
                                                        <VscInfo onClick={() => {
                                                            this.setState({ showMoreDotsPopup: true })
                                                        }} />
                                                    </div> */}
                                                    {this.state.currentUserId && !this.props.hideBookMarkIcon && this.checkForBookMarkIconEvent(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                </div>)}
                                            </div>
                                        </div>
                                        <div className='right-tsmhnc' style={{ backgroundImage: this.state.currentCampaignHere[0].localEventPlaceHolderIsImage ? `url("${this.state.currentCampaignHere[0].image}")` : "none", overflow: "hidden" }}>
                                            {!this.state.currentCampaignHere[0].localEventPlaceHolderIsImage && (
                                                <video
                                                    src={this.state.currentCampaignHere[0].image}
                                                    autoPlay
                                                    muted
                                                    loop
                                                    playsInline
                                                    className="localeventvideo"
                                                ></video>
                                            )}
                                            <div className="overlay-rtsmhnc"></div>
                                            {this.props.redirectToPaymentLink && (<div className="logo-event-absolute">
                                                {window.innerWidth > 1008 && (<img src={this.state.landing_page_new_logo_main ? this.state.landing_page_new_logo_main : logolandingpage} />)}
                                                {window.innerWidth > 1008 && (<img src={this.state.landing_page_new_logo_small ? this.state.landing_page_new_logo_small : playiconlandingpage} style={{ marginTop: 30 }} />)}
                                                {window.innerWidth < 1008 && (<img src={this.state.landing_page_new_logo_main_mobile ? this.state.landing_page_new_logo_main_mobile : logolandingpagemobile} />)}
                                                {window.innerWidth < 1008 && (<img src={this.state.landing_page_new_logo_small_mobile ? this.state.landing_page_new_logo_small_mobile : playiconlandingpagemobile} style={{ marginTop: 30 }} />)}
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                {this.state.useOfVouchersPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.voucherExceptionsPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.termsTextPopup && <div className="overlay-popup-vdd"></div>}
                {this.state.showDescriptionPopupDesktop && <div className="overlay-popup-vdd"></div>}
                {this.state.showInfoPopup && <div className="overlay-popup-vdd"></div>}

                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.useOfVouchersPopup ? 1 : 0,
                        pointerEvents: this.state.useOfVouchersPopup ? "all" : "none",
                        width: "100%",
                        maxWidth: 900,
                        padding: 30,
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ useOfVouchersPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">Gebruik van voucher</p>
                    <p className="main-desc-cpv">{this.state.currentUseOfVoucher}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.voucherExceptionsPopup ? 1 : 0,
                        pointerEvents: this.state.voucherExceptionsPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ voucherExceptionsPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">UITZONDERINGEN</p>
                    <p className="main-desc-cpv">{this.state.currentVoucherExceptions}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.termsTextPopup ? 1 : 0,
                        pointerEvents: this.state.termsTextPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ termsTextPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">VOORWAARDEN</p>
                    <p className="main-desc-cpv">{this.state.currentTermsText}</p>
                </div>
                <div
                    className="card-popup-vdd cpv-desc"
                    style={{
                        opacity: this.state.showDescriptionPopupDesktop ? 1 : 0,
                        pointerEvents: this.state.showDescriptionPopupDesktop ? "all" : "none",
                        width: "100%",
                        maxWidth: 900,
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showDescriptionPopupDesktop: false });
                        }}
                    />
                    <img src={descriptionLogoTop} alt="logo-desc" style={{ widows: 90, height: 45, objectFit: "contain" }} />
                    <p className="main-title-cpv">INFORMATIE</p>
                    <p className="main-desc-cpv">
                        {this.state.currentCampaignHere.length > 0 && (<div dangerouslySetInnerHTML={this.createDescPopup(this.state.currentCampaignHere[0].desc)}></div>)}
                        {/* {this.state.currentCampaignHere.length > 0 && this.state.currentCampaignHere[0].desc} */}
                    </p>
                </div>

                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.showInfoPopup ? 1 : 0,
                        pointerEvents: this.state.showInfoPopup ? "all" : "none",
                        width: "100%",
                        maxWidth: 900,
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showInfoPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">OMSCHRIJVING</p>
                    <p className="main-desc-cpv">
                        {this.state.localEventInfoMobile}
                    </p>
                </div>



                {/* campaign info popup */}

                {this.state.showDescriptionPopup && (
                    <div
                        className="overlay-asp"
                        onClick={() => {
                            this.setState({
                                showOptionsToAdd: false,
                                showDescriptionPopup: false,
                            });
                        }}
                    ></div>
                )}
                {this.state.currentCampaignHere.length > 0 && (<div
                    className="add-stuff-popup"
                    style={{
                        height: this.state.showDescriptionPopup ? "auto" : 0,
                        minHeight: this.state.showDescriptionPopup ? 350 : 0,
                        pointerEvents: this.state.showDescriptionPopup ? "all" : "none",
                        overflow: !this.state.showDescriptionPopup && "hidden",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showDescriptionPopup: false });
                        }}
                    />
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none" }}
                    >
                        Omschrijving
                    </p>
                    <p className="desc-content-asp">
                        <div dangerouslySetInnerHTML={this.createDescPopup(this.state.currentCampaignHere[0].desc)}></div>
                        {/* {this.state.currentCampaignHere[0].desc} */}
                    </p>
                </div>)}


                {this.state.showMoreDotsPopup && (
                    <div
                        className="overlay-asp"
                        onClick={() => {
                            this.setState({
                                showOptionsToAdd: false,
                                showMoreDotsPopup: false,
                            });
                        }}
                    ></div>
                )}
                {this.state.currentCampaignHere.length > 0 && (<div
                    className="add-stuff-popup"
                    style={{
                        height: this.state.showMoreDotsPopup ? "auto" : 0,
                        minHeight: this.state.showMoreDotsPopup ? 350 : 0,
                        pointerEvents: this.state.showMoreDotsPopup ? "all" : "none",
                        overflow: !this.state.showMoreDotsPopup && "hidden",
                        paddingTop: this.state.openDetailedInner && 50,
                        paddingBottom: this.state.openDetailedInner && 50,
                    }}
                >
                    {this.state.openDetailedInner ? (<>
                        <ChevronLeft
                            onClick={() => {
                                this.setState({
                                    openDetailedInner: false,
                                    headingOdiMain: "",
                                    paraOdiMain: "",
                                });
                            }}
                            style={{
                                right: "auto",
                                left: 10
                            }}
                        />
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 0 }}
                        >
                            {this.state.headingOdiMain}
                        </p>
                        <p className="desc-content-asp">
                            {this.state.paraOdiMain}
                        </p>
                    </>) : (<>
                        <CloseIcon
                            onClick={() => {
                                this.setState({ showMoreDotsPopup: false });
                            }}
                        />
                        {this.getAboutLocalEventMobile()}
                        {this.getUseOfVoucherlocalEventMobile()}
                        {this.getfilelinkMobile()}
                    </>)}
                </div>)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        product: state.campaignsReducer.product,
        vouchers: state.campaignsReducer.vouchers,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        global_campaigns: state.campaignsReducer.global_campaigns,
        subscription_plans: state.globalReducers.subscription_plans,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch(updateUser(user)),
        updateGuestUserCart: (guest_user_cart) => dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) => dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (guest_user_cart_extend_opportunity) => dispatch(updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsNewSection);
