import React, { Component } from "react";
import SearchIcon from "@material-ui/icons/Search";
import firebase from "../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import swal from "sweetalert";
import { connect } from "react-redux";
import hwb from "../assets/heartwhiteborder.png";
import {
  updateCurrentMoments,
  updateAlbumToAdd,
  updateMoreAlbumsAdded,
  updateSignUpFieldsFilled,
  updateSelectedExclusive,
  updateCurrentMomentsFriendsPage,
  updateAlbumToAddFriends,
  updateMoreAlbumsAddedFriends,
  updateSelectedExclusiveFriends,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateTitle,
  updateBhodiAbouts,
  updateBhodiFaqs,
} from "../Redux/actions/authActions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import LikeIcon from "../assets/hearticonmainblack.png";
import LikeFilledIcon from "../assets/heartfilleimageblack.png";
import headerBhodiIcon from "../assets/bhodi-header-mobile.png";
import CartNonUserMobile from "./CartNonUserMobile";
import RightMenuOrders from "./RightMenuOrders";
import { IoScanCircleOutline } from "react-icons/io5";
import { BiMenu } from "react-icons/bi";
import { MdOutlinePrivacyTip, MdOutlinePrivateConnectivity } from "react-icons/md";
import SettingsSeparate from "./SettingsSeparate";
import { RiGitRepositoryPrivateLine, RiUserSettingsLine } from "react-icons/ri";
import { TbFolderStar } from "react-icons/tb";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BsChevronLeft, BsTag } from "react-icons/bs";
import { FaQ } from "react-icons/fa6";
import { TiInfo } from "react-icons/ti";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import { AiOutlineSearch } from "react-icons/ai";
import { history } from "../Config/Routes";
import { followUnfollowCompany } from "../Functions/friendFunctions";
import RightMenuIncomingOrders from "./RightMenuIncomingOrders";
const axios = require("axios");

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class NavbarMainFullWidthCompanyMobile extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      search: false,
      searchText: "",
      rightModalSettings: false,
      arrForSearch: [],
      checkedPrivate: false,
      checkedHideDonation: false,
      checkedHideFriendsInfo: false,
      checkedHideProfileImage: false,
      checkedHideProfileInfo: false,
      showPostsActive: true,
      showItemsDiv: false,
      showItems: false,
      showLists: true,
      showFriends: false,
      showProductsOwn: false,
      showFriendstab: false,
      showProductsBookmarked: false,
      showBookmarkTab: false,
      showProductsOthertab: false,
      memberProducts: [],
      paymentEmail: "",
      nationality_country_code: "",
      phone_number_details_country_code: "",
      phone_number_national_number: "",
      business_address_line1: "",
      business_address_state: "",
      business_address_city: "",
      business_address_country_code: "",
      business_address_postal_code: "",
      bussiness_legal_name: "",
      business_description: "",
      website_urls: "",
      phone_type: "",
      given_name: "",
      surname: "",
      preferred_language_code: "",
      primary_currency_code: "",
      currentUserMember: "",
      userInfoRight: true,
      typeOfOrder: "incoming",
      showCartOrder: true,
      impressiePhotosWork: [],
      savtBtnText: "SAVE",
      listsOpacity: 1,
      listsPointers: "all",
      isDesktop: true,
      isMenuOpen: false,
      isScrolled: false,
      menuItemsModals: true,
      deliverTextOne: "",
      deliverTextTwo: "",
      productsBackColor: "#F5F5F5",
      lengthOfOtherProducts: [],
      lengthOfOtherVouchers: [],
      lengthOfOtherCampaigns: [],
      voucherSettings: false,
      voucherExceptions: "",
      termsText: "",
      hideMenuShop: false,
      hideMenuVouchers: false,
      arrCurrentProducts: [],
      lengthOfCurrentVouhers: [],
      currentUser: "",
      hideMenuContent: false,
      lengthOfCurrentContents: [],
      lengthOfOtherContents: [],
      currentUserProducts: [],
      showContentTab: false,
      kvkCompany: "",
      bhodiFaqs: [],
      arrDropdowns: [],
      bhodiAbouts: [],
      arrDropdownsAbouts: [],
      currentUserId: "",
      orderidToUpload: 0,
      currentUserOfPopups: "",
      redeem_code: "",
      guestUserCart: [],
      currentUserHere: "",
      deleteBtnText: "SAVE",
      showDelete: false,
      password: "",
      showPassword: false,
      confirmPassword: "",
      showconfirmPassword: false,
      currentPassword: "",
      showcurrentPassword: false,
      showLists: true,
      liveEventCompany: [],
      notSeenMessages: [],
      showProductsCurrently: [],
      charityCount: 0,
      toShowAlbum: "",
      toShowAlbum: false,
      toShowAlbumImg: "",
      updated: true,
      commentsReplies: [],
      maxLimitAlleReacties: 15,
    };
  }

  componentWillReceiveProps(e) {
    if (e.fillUpAddress) {
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "none";
      }
      this.setState({ rightModalSettings: true });

      document.querySelector("html").style.overflowY = "hidden"

      if (window.innerWidth < 1009) {
        this.setState({ userInfoRight: false, menuItemsModals: true });
      }
    }
  }

  updateTitle() {
    // check for connect paypal


    let rightMenuOpenDefault = localStorage.getItem("mustShowOpenRightMenu");

    if (rightMenuOpenDefault) {
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "none";
      }
      this.setState({ rightModalSettings: true });
      document.querySelector("html").style.overflowY = "hidden"

      if (window.innerWidth < 1009) {
        this.setState({ userInfoRight: false, menuItemsModals: true });
      }
      localStorage.removeItem("mustShowOpenRightMenu");
    }

    let route = window.location.pathname;
    if (route.includes("/friends")) {
      localStorage.setItem("onCurrentTabCompany", "showFriends");
      this.setState({
        showLists: false,
        doLessHeight: false,
        showItems: false,
        showFriends: true,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/charity")) {
      localStorage.setItem("onCurrentTabCompany", "showCharity");
      this.setState({
        showLists: false,
        doLessHeight: false,
        showCharity: true,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/contact")) {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showLists: true,
        doLessHeight: true,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/volgend")) {
      localStorage.setItem("onCurrentTabCompany", "showproductsfriends");
      this.setState({
        showProductsFriends: true,
        showLists: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
      });
    } else if (route.includes("/products")) {
      localStorage.setItem("onCurrentTabCompany", "showProductsNew");
      this.setState({
        showProductsNew: true,
        showProductsFriends: false,
        showLists: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
      });
      // this.setState({ showProductsNew: true, showProductsFriends: false, showLists: false, doLessHeight: false, showItems: false, showFriends: false, showImpression: false, showVoucher: false, showPosts: false, showProductsBookmarked: false, showCharity: false, showItemsShops: false, showItemsShops: false })
    } else {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showLists: true,
        doLessHeight: true,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    }

    let userid = localStorage.getItem("userid");

    firebase
      .database()
      .ref("charity_videos/" + userid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let objected = Object.entries(a);
          let filtered = objected.filter((e) => {
            return !e[1].seen
          })
          this.setState({ charityCount: filtered.length });
        }
      });

    if (this.props.bhodi_faqs) {
      let a = this.props.bhodi_faqs;
      if (a) {
        let arrSec = a;
        let arrDropdowns = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdowns.push(obj);
        });
        this.setState({ bhodiFaqs: arrSec, arrDropdowns });
      } else {
        this.setState({ bhodiFaqs: [], arrDropdowns: [] });
      }
    }
    if (this.props.bhodi_abouts) {
      let a = this.props.bhodi_abouts;
      if (a) {
        let arrSec = a;
        let arrDropdownsAbouts = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdownsAbouts.push(obj);
        });
        this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
      } else {
        this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
      }
    }

    firebase
      .database()
      .ref("bhodi_faqs")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdowns = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdowns.push(obj);
          });
          this.setState({ bhodiFaqs: arrSec, arrDropdowns });
        } else {
          this.setState({ bhodiFaqs: [], arrDropdowns: [] });
        }
        this.props.updateBhodiFaqs(arrSec)
      });

    firebase
      .database()
      .ref("bhodi_abouts")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdownsAbouts = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdownsAbouts.push(obj);
          });
          this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
        } else {
          this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
        }
        this.props.updateBhodiAbouts(arrSec)
      });

    if (this.props.message) {
      let allMessages = [];
      this.props.message.map((g) => {
        let objected = Object.entries(g[1]);
        objected.map((y) => {
          allMessages.push(y[1]);
        });
      });
      let filtered = allMessages.filter((g) => {
        return !g.seen && g.type === "reciever";
      });
      this.setState({ notSeenMessages: filtered });
    } else {
      this.setState({ notSeenMessages: [] });
    }

    this.setState({ currentUserId: userid });

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filter = this.props.user.filter((u) => {
          return u.user_name_id === this.props.member_id;
        });

        if (this.props.bhodi_products && filter.length > 0) {
          let filtered = this.props.bhodi_products.filter((t) => {
            let isAvailable = true
            if (t.variations_data) {
              let objected = Object.entries(t.variations_data)
              let filtered = objected.filter((g) => {
                return Number(g[1].qty) > 0
              })
              if (filtered.length === 0) {
                isAvailable = false
              }
            } else {
              if (Number(t.qty) <= 0) {
                isAvailable = false
              }
            }
            return t.created_by === filter[0].user_id && t.images && isAvailable
          })
          if (filtered.length > 0) {
            this.setState({ currentUserProducts: filtered })
          }
        }


        if (this.props.global_campaigns) {
          if (this.props.global_campaigns.length > 0) {
            let filteredLiveevent = this.props.global_campaigns.filter((b) => {
              if (b[1].localEventAccessible) {
                if (!b[1].localEventBuyButton) {
                  return false;
                }
              }
              if (b[1].takeFromVendor) {
                let filteredLiveEventVendor = this.props.global_campaigns.filter((g) => {
                  return g[1].created_by === b[1].vendor_id && g[1].pushKey === b[1].event_id
                })
                if (filteredLiveEventVendor.length > 0) {
                  if (!filteredLiveEventVendor[0][1].active) {
                    return false;
                  }
                }
              }
              return (
                b[1].created_by === filter[0].user_id &&
                b[1].campaignType === "local_event" &&
                b[1].active
              );
              // return (
              //   b[1].created_by === filter[0].user_id &&
              //   b[1].campaignType === "local_event" &&
              //   b[1].active &&
              //   b[1].localEventBuyButton
              // );
            });
            this.setState({ liveEventCompany: filteredLiveevent });
          }
        }
        let filterCurrentHere = this.props.user.filter((u) => {
          return u.user_id === userid;
        });
        this.setState({ currentUserHere: filterCurrentHere[0] });
        let filteredImpressie = [];
        if (this.props.all_impressies) {
          if (this.props.all_impressies.length > 0)
            filteredImpressie = this.props.all_impressies.filter((u) => {
              return u[0] === filter[0].user_id;
            });
        }
        this.setState({ impressiePhotosWork: filteredImpressie });
        let currentData = filter[0];
        if (filter.length > 0) {
          if (currentData) {
            let a = currentData;
            this.setState({
              currentUserMember: a,
              currentUser: a,
              create_subscription: a.create_subscription,
              userid: userid,
              url: a.user_name_id,
              firstName: a.first_name,
              lastName: a.last_name,
              city: a.city,
              zipCode: a.zipCode,
              street: a.street,
              houseNumber: a.houseNumber,
              country: a.country,
              phoneNumber: a.phoneNumber,
              email: a.email,
              companyName: a.company_name,
              kvkCompany: a.kvkCompany,
              company_website: a.company_website,
              companyEmail: a.email,
              profileImageUrl: a.profile_image,
              description: a.user_description,
              currentUserId: userid,
              paymentEmail: a.paymentEmail,
            });
            if (a.hideMenuShop) {
              this.setState({ hideMenuShop: a.hideMenuShop });
            } else {
              this.setState({ hideMenuShop: false });
            }
            if (!a.profile_image) {
              a.profile_image =
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
            if (a.accountType === "global") {
              this.setState({ checkedPrivate: false });
            } else {
              this.setState({ checkedPrivate: true });
            }
            if (a.voucherExceptions) {
              this.setState({ voucherExceptions: a.voucherExceptions });
            }
            if (a.termsText) {
              this.setState({ termsText: a.termsText });
            }
            if (a.subscription) {
              if (a.subscription.status === "active") {
                this.setState({ showDelete: false });
              } else {
                this.setState({ showDelete: true });
              }
            } else {
              if (checkForSubscribedExclusives(a)) {
                this.setState({ showDelete: false })
              } else {
                this.setState({ showDelete: true });
              }
            }
            // if (a.user_id === userid) {
            //     let email = a.email
            //     let password = a.pass
            //     console.log(email, '/working')
            //     console.log(password, '/working')
            //     var user = firebase.auth().currentUser;
            //     console.log(user, '/current user')
            //     var credential = firebase.auth.EmailAuthProvider.credential(
            //         email,
            //         password
            //     );;
            //     user.reauthenticateWithCredential(credential)
            // }
          }
        }
      }
    }

    if (this.props.member_id) {
      firebase
        .database()
        .ref("users")
        .on("value", (data) => {
          let a = data.val();
          let objected = Object.entries(a);
          objected.map((y) => {
            if (y[1].user_name_id === this.props.member_id) {
              let a = y[1];
              this.setState({
                currentUserMember: a,
                currentUser: a,
                create_subscription: a.create_subscription,
                userid: userid,
                url: a.user_name_id,
                firstName: a.first_name,
                lastName: a.last_name,
                city: a.city,
                zipCode: a.zipCode,
                street: a.street,
                houseNumber: a.houseNumber,
                country: a.country,
                phoneNumber: a.phoneNumber,
                email: a.email,
                companyName: a.company_name,
                kvkCompany: a.kvkCompany,
                company_website: a.company_website,
                companyEmail: a.email,
                profileImageUrl: a.profile_image,
                description: a.user_description,
                currentUserId: userid,
                paymentEmail: a.paymentEmail,
              });
              if (!a.profile_image) {
                a.profile_image =
                  "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
              }
              if (a.accountType === "global") {
                this.setState({ checkedPrivate: false });
              } else {
                this.setState({ checkedPrivate: true });
              }
              if (a.voucherExceptions) {
                this.setState({ voucherExceptions: a.voucherExceptions });
              }
              if (a.termsText) {
                this.setState({ termsText: a.termsText });
              }
              if (a.subscription) {
                if (a.subscription.status === "active") {
                  this.setState({ showDelete: false });
                } else {
                  this.setState({ showDelete: true });
                }
              } else {
                if (checkForSubscribedExclusives(a)) {
                  this.setState({ showDelete: false })
                } else {
                  this.setState({ showDelete: true });
                }
              }
            }
            if (y[1].user_id === userid) {
              let a = y[1];
              this.setState({ currentUserHere: a });
            }
          });
        });
    }

    if (window.innerWidth > 1009) {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: true,
      });
    } else {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: false,
      });
      window.onscroll = () => {
        if (window.scrollY === 0) {
          this.setState({ isScrolled: false });
        } else {
          this.setState({ isScrolled: true });
        }
      };
    }

    if (this.props.member_id) {
      let sessionStored = localStorage.getItem("onCurrentTabCompany");
      if (sessionStored) {
        if (sessionStored === "showItems") {
          this.setState({
            showLive: false,
            showProductsNew: false,
            showItems: true,
            doLessHeight: false,
            showLists: false,
            showFriends: false,
            showImpression: false,
            showVoucher: false,
            showPosts: false,
            showProductsBookmarked: false,
            showCharity: false,
            showItemsShops: false,
            showProductsFriends: false,
          });
        } else if (sessionStored === "showLists") {
          this.setState({
            showLive: false,
            showProductsNew: false,
            showLists: true,
            doLessHeight: true,
            showItems: false,
            showFriends: false,
            showImpression: false,
            showVoucher: false,
            showPosts: false,
            showProductsBookmarked: false,
            showCharity: false,
            showItemsShops: false,
            showProductsFriends: false,
          });
        } else if (sessionStored === "showFriends") {
          this.setState({
            showLive: false,
            showProductsNew: false,
            showLists: false,
            doLessHeight: false,
            showItems: false,
            showFriends: true,
            showImpression: false,
            showVoucher: false,
            showPosts: false,
            showProductsBookmarked: false,
            showCharity: false,
            showItemsShops: false,
            showProductsFriends: false,
          });
        } else if (sessionStored === "showImpression") {
          this.setState({
            showLive: false,
            showProductsNew: false,
            showLists: false,
            doLessHeight: false,
            showItems: false,
            showFriends: false,
            showImpression: true,
            showVoucher: false,
            showPosts: false,
            showProductsBookmarked: false,
            showCharity: false,
            showItemsShops: false,
            showProductsFriends: false,
          });
        } else if (sessionStored === "showCharity") {
          this.setState({
            showLive: false,
            showProductsNew: false,
            showLists: false,
            doLessHeight: false,
            showCharity: true,
            showItems: false,
            showFriends: false,
            showImpression: false,
            showVoucher: false,
            showPosts: false,
            showProductsBookmarked: false,
            showItemsShops: false,
            showProductsFriends: false,
          });
        } else if (sessionStored === "showproductsfriends") {
          this.setState({
            showLive: false,
            showProductsNew: false,
            showProductsFriends: true,
            showLists: false,
            doLessHeight: false,
            showItems: false,
            showFriends: false,
            showImpression: false,
            showVoucher: false,
            showPosts: false,
            showProductsBookmarked: false,
            showCharity: false,
            showItemsShops: false,
            showItemsShops: false,
          });
        } else if (sessionStored === "charityConfirmation") {
          this.setState({
            showLive: false,
            showProductsNew: false,
            showProductsFriends: false,
            showLists: false,
            doLessHeight: false,
            showItems: false,
            showFriends: false,
            showImpression: false,
            showVoucher: false,
            showPosts: false,
            showProductsBookmarked: false,
            showCharity: false,
            showItemsShops: false,
            showItemsShops: false,
          });
        }
      }

      let uid = localStorage.getItem("userid");

      // let bhodi_campaigns = this.props.global_campaigns;
      // if (bhodi_campaigns) {
      //   if (bhodi_campaigns.length > 0) {
      //     let arr = [];
      //     let allCampaignsCompleted = [];
      //     let allCampaignsCompletedToRender = [];
      //     let arrCurrentCampaigns = [];
      //     let objected = bhodi_campaigns;
      //     objected.map((e) => {
      //       e[1].type = "campaign";
      //       if (e[1].active) {
      //         allCampaignsCompletedToRender.push(e[1]);
      //         if (e[1].desc) {
      //           e[1].desc = e[1].desc.substr(0, 200);
      //         }
      //         allCampaignsCompleted.push(e[1]);
      //       }
      //     });
      //     allCampaignsCompleted.map((e) => {
      //       e.type = "campaign";
      //       if (e.created_by !== uid) {
      //         let filtered = this.props.user.filter((f) => {
      //           return f.user_id === e.created_by;
      //         });
      //         if (filtered.length > 0) {
      //           let f = filtered[0];
      //           if (f.followed_by || f.friends) {
      //             let objectedFriends = [];
      //             if (f.followed_by) {
      //               objectedFriends = Object.entries(f.followed_by);
      //             }
      //             if (f.friends) {
      //               let objectedCheck = Object.entries(f.friends);
      //               objectedFriends = objectedFriends.concat(objectedCheck);
      //             }
      //             objectedFriends.map((g) => {
      //               if (g[1].user_id === uid) {
      //                 if (e.active) {
      //                   if (e.desc) {
      //                     e.desc = e.desc.substr(0, 520);
      //                     e.short_desc = e.desc.substr(0, 120);
      //                   }
      //                   if (e.campaignType !== "charity") {
      //                     e.images = [e.image];
      //                     e.imagesObjected = [
      //                       { image: e.image, pushKey: e.pushKey },
      //                     ];
      //                   }
      //                   if (e.campaignType === "local_event") {
      //                     let arrTimingsAvailable = [];
      //                     if (e.timings) {
      //                       e.timings.map((y) => {
      //                         if (e.timings[0].isoStartTime && e.timings[0].isoEndTime) {
      //                           let checkedInOfCurrentBlock = [];
      //                           if (e.checked_in_by) {
      //                             Object.entries(e.checked_in_by).map((t) => {
      //                               checkedInOfCurrentBlock.push(t[1]);
      //                             });
      //                           }
      //                           let getCurrentDate = new Date();
      //                           if (
      //                             getCurrentDate.getTime() <
      //                             new Date(Date.parse(e.timings[0].isoEndTime)).getTime()
      //                           ) {
      //                             if (
      //                               checkedInOfCurrentBlock.length <
      //                               Number(y.personsAmountEvent)
      //                             ) {
      //                               arrTimingsAvailable.push(y);
      //                             }
      //                           }
      //                         }
      //                       });
      //                     }
      //                     if (arrTimingsAvailable.length > 0) {
      //                       arr.push(e);
      //                     }
      //                   }
      //                 }
      //               }
      //             });
      //           }
      //         }
      //       }
      //       if (e.created_by === uid) {
      //         arrCurrentCampaigns.push(e);
      //       }
      //     });
      //     this.setState({ lengthOfOtherCampaigns: arr });
      //   }
      // }
    }
    if (!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing")) {
      firebase
        .database()
        .ref("users/" + userid)
        .once("value", (data) => {
          let a = data.val();
          if (a) {
            if (a.user_name_id === this.props.member_id) {
              this.props.updateSignUpFieldsFilled("");
            }
            if (a.create_subscription) {
              this.setState({ create_subscription: a.create_subscription });
            }
            setTimeout(() => {
              firebase
                .database()
                .ref("users/" + userid + "/create_subscription")
                .set(false)
            }, 1000);
          }
        });
    }
  }

  componentDidMount() {
    this.updateTitle();
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
  }

  handleForm(e) {
    e.preventDefault();
    return false;
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // localStorage.setItem("userid", "")
        // window.location.href = "/login"
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        localStorage.removeItem("cookie_item");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
      });
  }

  saveEmailPassword() {
    const { email, password, confirmPassword, url, currentPassword } =
      this.state;

    let filteredEmail = [];
    let filteredURL = [];

    if (email !== this.state.currentUserMember.email) {
      filteredEmail = this.props.user.filter((h) => {
        return h.email === email;
      });
    }

    if (url !== this.state.currentUserMember.user_name_id) {
      filteredURL = this.props.user.filter((h) => {
        return h.user_name_id === url;
      });
    }

    if (filteredEmail.length === 0 && filteredURL.length === 0) {
      this.setState({ savtBtnText: "SAVING.." });

      let userid = localStorage.getItem("userid");
      if (url && url !== this.state.currentUserMember.user_name_id) {
        firebase
          .database()
          .ref("users/" + userid + "/user_name_id")
          .set(url)
          .then(() => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                localStorage.setItem("userid", "");
                window.location.href = "/login";
              });
          });
      } else {
        console.log("fill some thing");
      }
      let changePassword = confirmPassword;
      if (email !== "") {
        let user = firebase.auth().currentUser;
        user.updateEmail(email).then(() => {
          firebase
            .database()
            .ref("users/" + this.state.userid + "/email")
            .set(email)
            .then(() => { });
        });
      }
      if (password !== changePassword) {
        if (password !== "" && changePassword !== "") {
          if (currentPassword === "") {
            this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
          } else {
            this.setState({ fillCurrentPassword: false });
          }
        }
        this.setState({ changedPassword: true });
      } else {
        if (password !== "" && changePassword !== "") {
          if (currentPassword !== "") {
            this.setState({
              changedPassword: false,
              fillCurrentPassword: false,
            });
          } else {
            this.setState({
              savtBtnText: "SAVE",
              changedPassword: false,
              fillCurrentPassword: true,
            });
          }
        }
      }
      if (
        password !== "" &&
        changePassword !== "" &&
        currentPassword !== "" &&
        password === changePassword
      ) {
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          currentPassword
        );
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            this.setState({ fillCurrentPassword: false });
            var newPassword = password;
            user
              .updatePassword(newPassword)
              .then((e) => {
                bcrypt.genSalt(saltRounds, (err, salt) => {
                  bcrypt.hash(newPassword, salt, (err, hash) => {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, newPassword)
                      .then((succ) => {
                        // firebase
                        //   .database()
                        //   .ref("users/" + this.state.userid + "/pass")
                        //   .set(newPassword)
                        //   .then(() => {
                        firebase
                          .database()
                          .ref("users/" + this.state.userid + "/password")
                          .set(hash)
                          .then(() => {
                            this.setState({
                              password: "",
                              confirmPassword: "",
                            });
                            window.location.reload();
                          });
                        // });
                      });
                  });
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((err) => {
            if (err.code === "auth/wrong-password") {
              this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
            }
          });
      }

      setTimeout(() => {
        this.setState({ savtBtnText: "SAVE" });
      }, 3000);
    } else {
      if (filteredEmail.length > 0) {
        this.setState({ emailAlreadyTooked: true });
      } else {
        this.setState({ emailAlreadyTooked: false });
      }
      if (filteredURL.length > 0) {
        this.setState({ urlNotAvailable: true });
      } else {
        this.setState({ urlNotAvailable: false });
      }
    }
  }

  fileUpload(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`userimages/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/profile_image")
          .set(snapUrl)
          .then(() => { });
      });
    });
  }

  checkForFollowIcon() {
    if (this.state.currentUserMember.followed_by) {
      let objected = Object.entries(this.state.currentUserMember.followed_by);
      let filtered = objected.filter((g) => {
        return g[1].user_id === this.state.currentUserId;
      });
      if (filtered.length > 0) {
        if (this.state.currentUserMember.friends) {
          let objectedFriends = Object.entries(
            this.state.currentUserMember.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            return (
              <li
                style={{ cursor: "pointer" }}
                onClick={this.followCompany.bind(this)}
              >
                <div className="captionvoucherstuff charityimageonvoucher">
                  <img src={LikeFilledIcon} style={{ borderRadius: 0 }} />
                </div>
                <label style={{ cursor: "pointer" }}>Ontvolgen</label>
              </li>
            );
            return (
              <img
                src={hwb}
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 0,
                  marginLeft: 10,
                  marginRight: 0,
                }}
                onClick={this.followCompany.bind(this)}
              />
            );
          }
        } else {
          return (
            <li
              style={{ cursor: "pointer" }}
              onClick={this.followCompany.bind(this)}
            >
              <div className="captionvoucherstuff charityimageonvoucher">
                <img src={LikeFilledIcon} style={{ borderRadius: 0 }} />
              </div>
              <label style={{ cursor: "pointer" }}>Ontvolgen</label>
            </li>
          );
          // return <img src={hwb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
        }
      } else {
        if (this.state.currentUserMember.friends) {
          let objectedFriends = Object.entries(
            this.state.currentUserMember.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            return (
              <li
                style={{ cursor: "pointer" }}
                onClick={this.followCompany.bind(this)}
              >
                <div className="captionvoucherstuff charityimageonvoucher">
                  <img src={LikeIcon} style={{ borderRadius: 0 }} />
                </div>
                <label style={{ cursor: "pointer" }}>Volgen</label>
              </li>
            );
            // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
          }
        } else {
          return (
            <li
              style={{ cursor: "pointer" }}
              onClick={this.followCompany.bind(this)}
            >
              <div className="captionvoucherstuff charityimageonvoucher">
                <img src={LikeIcon} style={{ borderRadius: 0 }} />
              </div>
              <label style={{ cursor: "pointer" }}>Volgen</label>
            </li>
          );
          // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
        }
      }
    } else {
      if (this.state.currentUserMember.friends) {
        let objectedFriends = Object.entries(
          this.state.currentUserMember.friends
        );
        let filteredFriends = objectedFriends.filter((g) => {
          return g[1].user_id === this.state.currentUserId;
        });
        if (filteredFriends.length === 0) {
          return (
            <li
              style={{ cursor: "pointer" }}
              onClick={this.followCompany.bind(this)}
            >
              <div className="captionvoucherstuff charityimageonvoucher">
                <img src={LikeIcon} style={{ borderRadius: 0 }} />
              </div>
              <label style={{ cursor: "pointer" }}>Volgen</label>
            </li>
          );
          // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
        }
      } else {
        return (
          <li
            style={{ cursor: "pointer" }}
            onClick={this.followCompany.bind(this)}
          >
            <div className="captionvoucherstuff charityimageonvoucher">
              <img src={LikeIcon} style={{ borderRadius: 0 }} />
            </div>
            <label style={{ cursor: "pointer" }}>Volgen</label>
          </li>
        );
        // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
      }
    }
  }

  followCompany() {
    followUnfollowCompany(this.state.currentUser, this.state.currentUserId, this.props.user)
  }

  // followCompany() {
  //   if (this.state.currentUser.followed_by) {
  //     let objected = Object.entries(this.state.currentUser.followed_by);
  //     let filtered = objected.filter((g) => {
  //       return g[1].user_id === this.state.currentUserId;
  //     });
  //     if (filtered.length > 0) {
  //       if (this.state.currentUser.newFollowersList) {
  //         let objectedSec = Object.entries(
  //           this.state.currentUser.newFollowersList
  //         );
  //         let filteredNewFollowers = objectedSec.filter((g) => {
  //           return g[1].user_id === this.state.currentUserId;
  //         });
  //         if (filteredNewFollowers.length > 0) {
  //           let newFollowers = Number(this.state.currentUser.newFollowers);
  //           newFollowers = newFollowers - 1;
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               this.state.currentUser.user_id +
  //               "/newFollowersList/" +
  //               filteredNewFollowers[0][1].push_key
  //             )
  //             .remove();
  //           firebase
  //             .database()
  //             .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
  //             .set(newFollowers);
  //         }
  //       }
  //       let filteredCurrentUser = this.props.user.filter((h) => {
  //         return h.user_id === this.state.currentUserId;
  //       });
  //       if (filteredCurrentUser[0].following) {
  //         let objectedSec = Object.entries(filteredCurrentUser[0].following);
  //         let filteredSec = objectedSec.filter((g) => {
  //           return g[1].user_id === this.state.currentUser.user_id;
  //         });
  //         if (filteredSec.length > 0) {
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               this.state.currentUserId +
  //               "/following/" +
  //               filteredSec[0][1].push_key
  //             )
  //             .remove();
  //         }
  //       }
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           this.state.currentUser.user_id +
  //           "/followed_by/" +
  //           filtered[0][1].push_key
  //         )
  //         .remove();
  //     } else {
  //       let pushFirst = firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserId + "/following")
  //         .push().key;
  //       let pushSecond = firebase
  //         .database()
  //         .ref("users/" + this.state.currentUser.user_id + "/followed_by")
  //         .push().key;
  //       let pushSecondNew = firebase
  //         .database()
  //         .ref("users/" + this.state.currentUser.user_id + "/newFollowersList")
  //         .push().key;
  //       let newFollowers = 0;
  //       let obj = {
  //         user_id: this.state.currentUser.user_id,
  //         push_key: pushFirst,
  //       };
  //       let objSec = {
  //         user_id: this.state.currentUserId,
  //         push_key: pushSecond,
  //       };
  //       let objThird = {
  //         user_id: this.state.currentUserId,
  //         push_key: pushSecondNew,
  //       };
  //       if (this.state.currentUser.newFollowers) {
  //         newFollowers = Number(this.state.currentUser.newFollowers);
  //       }
  //       newFollowers = newFollowers + 1;
  //       firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
  //         .set(obj);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           this.state.currentUser.user_id +
  //           "/followed_by/" +
  //           pushSecond
  //         )
  //         .set(objSec);
  //       firebase
  //         .database()
  //         .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
  //         .set(newFollowers);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           this.state.currentUser.user_id +
  //           "/newFollowersList/" +
  //           pushSecondNew
  //         )
  //         .set(objThird);
  //     }
  //   } else {
  //     let pushFirst = firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserId + "/following")
  //       .push().key;
  //     let pushSecond = firebase
  //       .database()
  //       .ref("users/" + this.state.currentUser.user_id + "/followed_by")
  //       .push().key;
  //     let pushSecondNew = firebase
  //       .database()
  //       .ref("users/" + this.state.currentUser.user_id + "/newFollowersList")
  //       .push().key;
  //     let newFollowers = 0;
  //     let obj = {
  //       user_id: this.state.currentUser.user_id,
  //       push_key: pushFirst,
  //     };
  //     let objSec = {
  //       user_id: this.state.currentUserId,
  //       push_key: pushSecond,
  //     };
  //     let objThird = {
  //       user_id: this.state.currentUserId,
  //       push_key: pushSecondNew,
  //     };
  //     if (this.state.currentUser.newFollowers) {
  //       newFollowers = Number(this.state.currentUser.newFollowers);
  //     }
  //     newFollowers = newFollowers + 1;
  //     firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
  //       .set(obj);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         this.state.currentUser.user_id +
  //         "/followed_by/" +
  //         pushSecond
  //       )
  //       .set(objSec);
  //     firebase
  //       .database()
  //       .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
  //       .set(newFollowers);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         this.state.currentUser.user_id +
  //         "/newFollowersList/" +
  //         pushSecondNew
  //       )
  //       .set(objThird);
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.title) {
      this.setState({ updated: false });
      this.updateTitle();
      this.props.updateTitle(false);

      setTimeout(() => {
        this.setState({ updated: true });
      }, 250);
    }

    if (
      prevProps.closeCompleteRightMenu !== this.props.closeCompleteRightMenu
    ) {
      if (this.props.closeCompleteRightMenu) {
        this.setState({ rightModalSettings: false });
        document.querySelector("html").style.overflowY = "auto"

        let element = document.getElementById("footermain");
        if (element) {
          element.style.display = "flex";
        }
        if (window.innerWidth < 1009) {
          document.getElementById("root").style.position = "static";
          document.getElementById("root").style.overflow = "hidden";
        }
        if (this.props.closeAboutBhodiRightMenu) {
          this.props.closeAboutBhodiRightMenu();
        }
        document.getElementById("root").style.position = "static";
        if (this.props.revertBackCloseCompleteRightMenu) {
          this.props.revertBackCloseCompleteRightMenu();
        }
      }
    }

    if (prevProps.message !== this.props.message) {
      if (this.props.message) {
        let allMessages = [];
        this.props.message.map((g) => {
          let objected = Object.entries(g[1]);
          objected.map((y) => {
            allMessages.push(y[1]);
          });
        });
        let filtered = allMessages.filter((g) => {
          return !g.seen;
        });
        this.setState({ notSeenMessages: filtered });
      } else {
        this.setState({ notSeenMessages: [] });
      }
    }
    if (this.props.showGegevensPopup) {
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "none";
      }
      this.setState({ rightModalSettings: true });
      document.querySelector("html").style.overflowY = "hidden"

      if (window.innerWidth < 1009) {
        this.setState({ userInfoRight: false, menuItemsModals: true });
      }
      if (this.props.closeShowGegevensPopup) {
        this.props.closeShowGegevensPopup();
      }
    }
    if (this.props.bhodi_products !== prevProps.bhodi_products) {
      let filter = this.props.user.filter((u) => {
        return u.user_name_id === this.props.member_id;
      });
      if (filter.length > 0) {
        if (this.props.bhodi_products) {
          let filtered = this.props.bhodi_products.filter((t) => {
            let isAvailable = true
            if (t.variations_data) {
              let objected = Object.entries(t.variations_data)
              let filtered = objected.filter((g) => {
                return Number(g[1].qty) > 0
              })
              if (filtered.length === 0) {
                isAvailable = false
              }
            } else {
              if (Number(t.qty) <= 0) {
                isAvailable = false
              }
            }
            return t.created_by === filter[0].user_id && t.images && isAvailable
          })
          if (filtered.length > 0) {
            this.setState({ currentUserProducts: filtered })
          }
        } else {
          this.setState({ currentUserProducts: [] })
        }
      }
    }
    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      if (this.props.global_campaigns) {
        if (this.props.global_campaigns.length > 0) {
          let filter = this.props.user.filter((u) => {
            return u.user_name_id === this.props.member_id;
          });
          let filteredLiveevent = this.props.global_campaigns.filter((b) => {
            if (b[1].localEventAccessible) {
              if (!b[1].localEventBuyButton) {
                return false;
              }
            }
            if (b[1].takeFromVendor) {
              let filteredLiveEventVendor = this.props.global_campaigns.filter((g) => {
                return g[1].created_by === b[1].vendor_id && g[1].pushKey === b[1].event_id
              })
              if (filteredLiveEventVendor.length > 0) {
                if (!filteredLiveEventVendor[0][1].active) {
                  return false;
                }
              }
            }
            return (
              b[1].created_by === filter[0].user_id &&
              b[1].campaignType === "local_event" &&
              b[1].active
            );
            // return b[1].created_by === filter[0].user_id && b[1].campaignType === "local_event" && b[1].active && b[1].localEventBuyButton
          });
          this.setState({ liveEventCompany: filteredLiveevent });
        }
      }
      // if (!window.location.href.includes("volgend")) {
      //   let uid = localStorage.getItem("userid");
      //   let bhodi_campaigns = this.props.global_campaigns;
      //   if (bhodi_campaigns) {
      //     if (bhodi_campaigns.length > 0) {
      //       let arr = [];
      //       let allCampaignsCompleted = [];
      //       let allCampaignsCompletedToRender = [];
      //       let arrCurrentCampaigns = [];
      //       let objected = bhodi_campaigns;
      //       objected.map((e) => {
      //         e[1].type = "campaign";
      //         if (e[1].active) {
      //           allCampaignsCompletedToRender.push(e[1]);
      //           if (e[1].desc) {
      //             e[1].desc = e[1].desc.substr(0, 200);
      //           }
      //           allCampaignsCompleted.push(e[1]);
      //         }
      //       });
      //       allCampaignsCompleted.map((e) => {
      //         e.type = "campaign";
      //         if (e.created_by !== uid) {
      //           let filtered = this.props.user.filter((f) => {
      //             return f.user_id === e.created_by;
      //           });
      //           if (filtered.length > 0) {
      //             let f = filtered[0];
      //             if (f.followed_by || f.friends) {
      //               let objectedFriends = [];
      //               if (f.followed_by) {
      //                 objectedFriends = Object.entries(f.followed_by);
      //               }
      //               objectedFriends.map((g) => {
      //                 if (g[1].user_id === uid) {
      //                   if (e.active) {
      //                     if (e.desc) {
      //                       e.desc = e.desc.substr(0, 520);
      //                       e.short_desc = e.desc.substr(0, 120);
      //                     }
      //                     e.images = [e.image];
      //                     e.imagesObjected = [
      //                       { image: e.image, pushKey: e.pushKey },
      //                     ];
      //                     if (e.campaignType === "doorlopende") {
      //                       let objectedVerzilveredBy = [];
      //                       let num = 0;
      //                       if (e.verzilvered_by) {
      //                         objectedVerzilveredBy = Object.entries(
      //                           e.verzilvered_by
      //                         );
      //                         num = this.getVerzilveredQty(
      //                           objectedVerzilveredBy
      //                         );
      //                       }
      //                       let bewaaredArray = [];
      //                       if (!e.kortingsVoucherUnlimited) {
      //                         if (
      //                           num + bewaaredArray.length <
      //                           Number(e.number)
      //                         ) {
      //                           arr.push(e);
      //                         }
      //                       } else {
      //                         arr.push(e);
      //                       }
      //                     }
      //                     if (e.campaignType === "local_event") {
      //                       let arrTimingsAvailable = [];
      //                       if (e.timings) {
      //                         e.timings.map((y) => {
      //                           if (e.timings[0].isoStartTime && e.timings[0].isoEndTime) {
      //                             let checkedInOfCurrentBlock = [];
      //                             if (e.checked_in_by) {
      //                               Object.entries(e.checked_in_by).map((t) => {
      //                                 checkedInOfCurrentBlock.push(t[1]);
      //                               });
      //                             }
      //                             // let getEventDate = new Date(y.endDateEvent);
      //                             // let endTimeGood =
      //                             //   y.endTimeEvent.concat(":00");
      //                             // getEventDate.setHours(
      //                             //   endTimeGood.split(":")[0]
      //                             // );
      //                             // getEventDate.setMinutes(
      //                             //   endTimeGood.split(":")[1]
      //                             // );
      //                             let getCurrentDate = new Date();
      //                             if (
      //                               getCurrentDate.getTime() <
      //                               new Date(Date.parse(e.timings[0].isoEndTime)).getTime()
      //                             ) {
      //                               if (
      //                                 checkedInOfCurrentBlock.length <
      //                                 Number(y.personsAmountEvent)
      //                               ) {
      //                                 arrTimingsAvailable.push(y);
      //                               }
      //                             }
      //                           }
      //                         });
      //                       }
      //                       if (arrTimingsAvailable.length > 0) {
      //                         arr.push(e);
      //                       }
      //                     }
      //                     if (e.campaignType === "web_shop") {
      //                       let objectedVerzilveredBy = [];
      //                       let num = 0;
      //                       if (e.verzilvered_by) {
      //                         objectedVerzilveredBy = Object.entries(
      //                           e.verzilvered_by
      //                         );
      //                         num = this.getVerzilveredQty(
      //                           objectedVerzilveredBy
      //                         );
      //                       }
      //                       let bewaaredArray = [];
      //                       if (!e.webshopUnlimited) {
      //                         if (
      //                           num + bewaaredArray.length <
      //                           Number(e.number)
      //                         ) {
      //                           arr.push(e);
      //                         }
      //                       } else {
      //                         arr.push(e);
      //                       }
      //                     }
      //                     if (e.campaignType === "giveaway") {
      //                       let objectedVerzilveredBy = [];
      //                       let num = 0;
      //                       if (e.verzilvered_by) {
      //                         objectedVerzilveredBy = Object.entries(
      //                           e.verzilvered_by
      //                         );
      //                         num = this.getVerzilveredQty(
      //                           objectedVerzilveredBy
      //                         );
      //                       }
      //                       let bewaaredArray = [];
      //                       if (!e.giveawayUnlimited) {
      //                         if (
      //                           num + bewaaredArray.length <
      //                           Number(e.number)
      //                         ) {
      //                           arr.push(e);
      //                         }
      //                       } else {
      //                         arr.push(e);
      //                       }
      //                     }
      //                   }
      //                 }
      //               });
      //             }
      //           }
      //         }
      //         if (e.created_by === uid) {
      //           arrCurrentCampaigns.push(e);
      //         }
      //       });
      //       this.setState({ lengthOfOtherCampaigns: arr });
      //     }
      //   }
      // }
    }
    if (this.props.doShowMenuRightAbout) {
      if (this.props.doShowMenuRightAbout !== this.state.showBhodiAbouts) {
        let element = document.getElementById("footermain");
        if (element) {
          element.style.display = "none";
        }
        document.querySelector("html").style.overflowY = "hidden"

        this.setState({
          rightModalSettings: true,
          menuItemsModals: false,
          showBhodiAbouts: true,
        });
      }
    }

  }

  async deleteCurrentUser() {
    if (this.state.showDelete) {
      this.setState({ deleteBtnText: "SAVING.." });

      let allUsers = this.props.user;
      allUsers.map((u) => {
        if (u.following) {
          let objected = Object.entries(u.following);
          let filtered = objected.filter((b) => {
            return b[1].user_id === this.state.currentUserId;
          });
          if (filtered.length > 0) {
            firebase
              .database()
              .ref(
                "users/" + u.user_id + "/following/" + filtered[0][1].pushKey
              )
              .remove();
          }
        }
        if (u.followed_by) {
          let objected = Object.entries(u.followed_by);
          let filtered = objected.filter((b) => {
            return b[1].user_id === this.state.currentUserId;
          });
          if (filtered.length > 0) {
            firebase
              .database()
              .ref(
                "users/" + u.user_id + "/followed_by/" + filtered[0][1].pushKey
              )
              .remove();
          }
        }
        if (u.newFollowersList) {
          let objected = Object.entries(u.newFollowersList);
          let filtered = objected.filter((b) => {
            return b[1].user_id === this.state.currentUserId;
          });
          if (filtered.length > 0) {
            let newFollowers = u.newFollowers - 1;
            firebase
              .database()
              .ref(
                "users/" +
                u.user_id +
                "/newFollowersList/" +
                filtered[0][1].pushKey
              )
              .remove();
            firebase
              .database()
              .ref("users/" + u.user_id + "/newFollowers")
              .set(newFollowers);
          }
        }
      });
      await firebase
        .database()
        .ref("bhodi_campaigns/" + this.state.currentUserId)
        .remove();
      await firebase
        .database()
        .ref("impressiePhotos/" + this.state.currentUserId)
        .remove();
      await firebase
        .database()
        .ref("allMessages/" + this.state.currentUserId)
        .remove();
      await firebase
        .database()
        .ref("guest_carts/" + this.state.currentUserId)
        .remove();
      await firebase.auth().currentUser.delete();

      firebase
        .database()
        .ref("users/" + this.state.currentUserId)
        .remove()
        .then(() => {
          window.location.href = "/login";
        });
    } else {
      this.setState({ cancelSubscriptionPopup: true });
    }
  }


  render() {
    return (
      !this.props.hideNav &&
      this.state.updated && (
        <div>
          <div
            className={`navbarfullwidthMobile navbarfullwidthmobilefullpagesupdated navbartodowhitedefault navbartodowhitedefaultnewdesign`}
            id="navbartohidemainly"
            style={{
              overflow:
                window.location.href.includes("charityConfirmation") &&
                "hidden",
            }}
          >
            {this.state.currentUserMember && (
              <div className="top-nfwpm">
                <div className="name-wrapper-nav" style={{ position: "relative", display: "flex", alignItems: "center" }}>
                  {/* <div className="imginnermcaadd" style={{ left: 14, zIndex: 99999 }}>
                    <AddIcon style={{ color: "white" }} />
                  </div> */}
                  {/* <div className="captionvoucherstuff imguploadonvoucher" style={{ overflow: "hidden", marginRight: 0, flexShrink: 0 }}>
                    <img src={
                      this.state.currentUserMember.profile_image
                        ? this.state.currentUserMember.profile_image
                        : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                    } onClick={() => {
                      if (this.state.currentUserMember.user_id === this.state.currentUserId) {
                        this.props.showPosts();
                      }
                    }} />
                  </div> */}
                  {/* {this.props.onProductDetailed ? (<BsChevronLeft onClick={() => {
                    history.goBack()
                  }} />) :  */}
                  <p
                    onClick={() => {
                      localStorage.removeItem("onCurrentTab");
                      localStorage.removeItem("onCurrentTabCompany");
                      localStorage.removeItem("onStoriesActive");
                      history.push(`/company/${this.state.currentUserMember.user_name_id}`)
                      // this.props.showPosts();
                    }}
                    style={{ textTransform: "none", flexShrink: 0 }}
                  >
                    <img src={headerBhodiIcon} style={{ width: 100, height: 30, borderRadius: 0, objectFit: "cover", objectPosition: "center" }} />
                  </p>
                  {/* } */}
                  <div className="lists-nfwpm">
                    <ul>
                    </ul>
                  </div>
                </div>
                <div className="icons-wrapper-nav">
                  {this.props.showSearchOnGlobal && (<a
                    href="javascript:void(0)"
                    className="btn btn-black orderbasketicon btnpopupwork"
                    style={{ background: "none", paddingRight: 0 }}
                  >
                    <AiOutlineSearch
                      style={{ fontSize: 18, marginTop: 3 }}
                      onClick={() => {
                        this.setState({ showSearchLine: !this.state.showSearchLine })
                      }}
                    />
                    {/* <img
                      src={searchpicpng}
                      style={{ maxWidth: 20, marginTop: 3 }}
                    /> */}
                    {this.state.showSearchLine && (
                      <input
                        type="text"
                        className="input-inclgd"
                        onChange={(e) => {
                          if (this.props.searchFiltered) {
                            this.props.searchFiltered(e.target.value);
                          }
                        }}
                      />
                    )}
                  </a>)}
                  {!this.props.onProductDetailed && (<div
                    style={{ position: "relative", display: "inline-block" }}
                    className="righticonopacity"
                  >
                    <a
                      href="javascript:void(0)"
                      className="btn btn-black orderbasketicon btnpopupwork"
                      style={{ background: "none" }}
                      onClick={() => {
                        this.setState({ ordersRightMenu: true });
                      }}
                    >
                      <IoScanCircleOutline
                        style={{ fontSize: 18, marginTop: 3 }}
                      />
                      <div id="mustShowOnAddOrder" style={{ left: "-60%" }}>
                        <p style={{ textTransform: "none" }}>Toegevoegd</p>
                      </div>
                    </a>
                  </div>)}
                  {!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing") && (
                    <CartNonUserMobile showOnlyCount={true} />
                  )}
                  {this.state.currentUserMember.user_id ===
                    this.state.currentUserId && (<div className="own-user-countnotif" onClick={() => {
                      let element = document.getElementById("footermain");
                      if (element) {
                        element.style.display = "none";
                      }
                      this.setState({ rightModalSettings: true });
                      document.querySelector("html").style.overflowY = "hidden"

                      if (window.innerWidth < 1009) {
                        this.setState({
                          userInfoRight: false,
                          menuItemsModals: true,
                        });
                      }
                    }}>
                      <BiMenu
                        style={{ fontSize: 20 }}
                        className="menu-icon-mobile"
                      />
                    </div>
                    )
                  }
                </div>
              </div>
            )}
          </div>
          {this.state.showFriends &&
            this.state.currentUserMember.user_id ===
            this.state.currentUserId && (
              <SearchIcon
                className="dosearchiconposition"
                onClick={() => this.setState({ rightModalSearchFriends: true })}
                style={{
                  top: this.state.currentUserMember.user_description
                    ? 158
                    : 121,
                }}
              />
            )}
          <div
            className={`mnicart-flyout-inner mnicartflyout-updated updated-mnicartflyout ${this.state.rightModalSettings && !this.state.commentsPopup && !this.state.showSettingsMenu
              ? "open"
              : null
              }`}
            style={{
              maxWidth: 400,
              opacity:
                this.state.rightModalSettings && !this.state.commentsPopup && !this.state.showSettingsMenu
                  ? 1
                  : 0,
            }}
          >
            <div
              className="checkout-flyout-inner-content"
              style={{ paddingBottom: 10 }}
            >
              <div
                className="flyout-section"
                style={{ backgroundColor: "white" }}
              >
                {/* <div className="flyout-section" style={{ backgroundColor: "#FBFBFB" }}> */}
                {this.state.currentUserMember.user_id === this.state.currentUserId ? (<div className="header-companysection">
                  <p>PROFIELINSTELLINGEN</p>
                  <div>
                    {/* <ExitToAppIcon style={{ right: 40 }} onClick={this.logout.bind(this)} />
                    <div style={{ padding: 5, display: "inline" }}></div> */}
                    <CloseIcon
                      onClick={() => {
                        this.setState({ rightModalSettings: false });
                        document.querySelector("html").style.overflowY = "auto"
                        let element = document.getElementById("footermain");
                        if (element) {
                          element.style.display = "flex";
                        }
                        if (window.innerWidth < 1009) {
                          document.getElementById("root").style.position =
                            "static";
                          document.getElementById("root").style.overflow =
                            "hidden";
                        }
                        if (this.props.closeAboutBhodiRightMenu) {
                          this.props.closeAboutBhodiRightMenu();
                        }
                        document.getElementById("root").style.position = "static";
                      }}
                    />
                  </div>
                </div>) : (<div className="header-companysection">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        this.state.currentUserMember.profile_image
                          ? this.state.currentUserMember.profile_image
                          : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                      }
                      style={{ width: 40, height: 40, borderRadius: "50%" }}
                    />
                    <p>
                      {this.state.currentUserMember.company_name}
                    </p>
                  </div>
                  <CloseIcon
                    onClick={() => {
                      this.setState({ rightModalSettings: false });
                      document.querySelector("html").style.overflowY = "auto"
                      let element = document.getElementById("footermain");
                      if (element) {
                        element.style.display = "flex";
                      }
                      if (window.innerWidth < 1009) {
                        document.getElementById("root").style.position =
                          "static";
                        document.getElementById("root").style.overflow =
                          "hidden";
                      }
                      if (this.props.closeAboutBhodiRightMenu) {
                        this.props.closeAboutBhodiRightMenu();
                      }
                      document.getElementById("root").style.position = "static";
                    }}
                  />
                </div>)}
                <div className="container-companysectionright">
                  <div className="lists-companysectionright listsmobilenavbarmembernew">
                    <ul>
                      {this.state.currentUserMember.user_id !==
                        this.state.currentUserId && this.checkForFollowIcon()}

                      {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li className="litexttransformnone" style={{ cursor: "pointer", position: "relative" }}>
                            <div className="innercontltn">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => this.fileUpload(e)}
                              />
                              <div className="imginnermcaadd" style={{ top: -5, right: "auto", left: 14, zIndex: 99 }}>
                                <AddIcon style={{ color: "white" }} />
                              </div>
                              <div className="captionvoucherstuff imguploadonvoucher" style={{ overflow: "hidden", borderColor: "transparent" }}>
                                {this.state.profileImageUrl ? (
                                  <img src={this.state.profileImageUrl} />
                                ) : (
                                  <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                )}
                              </div>
                              <label>
                                Profielfoto
                              </label>
                            </div>
                          </li>
                        )}
                      {/* {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li style={{ cursor: "pointer" }}>
                            <div className="captionvoucherstuff charityimageonvoucher"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "mijn_abonnementen",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <TbFolderStar />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "mijn_abonnementen",
                                  showSettingsMenu: true,
                                });
                              }}
                            >
                              Mijn exclusives
                            </label>
                          </li>
                        )} */}
                      {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li style={{ cursor: "pointer" }}>
                            <div className="captionvoucherstuff charityimageonvoucher"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "mijn_abonnementen",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <RiUserSettingsLine />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "mijn_gegevens",
                                  showSettingsMenu: true,
                                });
                                // window.location.href = "/settings-company/mijn_gegevens"
                              }}
                            >
                              Mijn gegevens
                            </label>
                          </li>
                        )}
                      {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li style={{ cursor: "pointer" }}>
                            <div className="captionvoucherstuff charityimageonvoucher"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "privacy",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <MdOutlinePrivacyTip />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "privacy",
                                  showSettingsMenu: true,
                                });
                                // window.location.href = "/settings-company/privacy"
                              }}
                            >
                              Privacy instellingen
                            </label>
                          </li>
                        )}
                      {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li style={{ cursor: "pointer" }}>
                            <div className="captionvoucherstuff charityimageonvoucher"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "inloggegevens",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <RiGitRepositoryPrivateLine />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "inloggegevens",
                                  showSettingsMenu: true,
                                });
                                // window.location.href = "/settings-company/inloggegevens"
                              }}
                            >
                              Inloggegevens
                            </label>
                          </li>
                        )}
                      {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li style={{ cursor: "pointer" }}>
                            <div className="captionvoucherstuff charityimageonvoucher faq-icon-mobile"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "ongoing_orders",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <HiOutlineClipboardList />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "ongoing_orders",
                                  showSettingsMenu: true,
                                });
                                // window.location.href = "/settings-company/ongoing_orders"
                              }}
                            >
                              Klantbestellingen
                            </label>
                          </li>
                        )}
                      {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li style={{ cursor: "pointer" }}>
                            <div className="captionvoucherstuff charityimageonvoucher faq-icon-mobile"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "voucher_settings",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <BsTag />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "voucher_settings",
                                  showSettingsMenu: true,
                                });
                                // window.location.href = "/settings-company/voucher_settings"
                              }}
                            >
                              Voucher instellingen
                            </label>
                          </li>
                        )}
                      {this.state.bhodiFaqs.length > 0 &&
                        this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li style={{ cursor: "pointer" }}>
                            <div className="captionvoucherstuff charityimageonvoucher faq-icon-mobile"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "faqs",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <FaQ />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "faqs",
                                  showSettingsMenu: true,
                                });
                                // window.location.href = "/settings-company/faqs"
                              }}
                            >
                              FAQ
                            </label>
                          </li>
                        )}
                      {this.state.bhodiAbouts.length > 0 &&
                        this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li
                            className="right-mcls"
                            style={{ cursor: "pointer", marginBottom: 20 }}
                          >
                            <div className="captionvoucherstuff charityimageonvoucher"
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "abouts",
                                  showSettingsMenu: true,
                                });
                              }}>
                              <TiInfo />
                            </div>
                            <label
                              onClick={() => {
                                if (document.querySelector(".new-round-icons-member")) {
                                  document.querySelector(".new-round-icons-member").style.display = "none"
                                }
                                this.setState({
                                  typeOfPopup: "abouts",
                                  showSettingsMenu: true,
                                });
                                // window.location.href = "/settings-company/abouts"
                              }}
                            >
                              About
                            </label>
                          </li>
                        )}
                      {/* {this.state.currentUserMember.user_id === this.state.currentUserId && <li className="right-mcls" style={{ cursor: "pointer", marginBottom: 20 }}>
                                            <label onClick={() => {
                                                window.location.href = "/settings-company/channels"
                                            }}>Channel</label>
                                        </li>} */}
                      {this.state.currentUserMember.user_id ===
                        this.state.currentUserId && (
                          <li
                            className="right-mcls logout-mcls"
                            style={{ width: "100%" }}
                          >
                            <ExitToAppIcon onClick={this.logout.bind(this)} />
                            <label onClick={this.logout.bind(this)}>
                              Uitloggen
                            </label>
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.ordersRightMenu && (
            <div
              className="bg-overlaymini"
              onClick={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
            ></div>
          )}
          {this.state.ordersRightMenu && (
            <RightMenuIncomingOrders
              showFooter={true}
              closePopup={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
              closePopupComplete={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
            />
          )}
          <Dialog
            open={this.state.cancelSubscriptionPopup}
            onClose={() => this.setState({ cancelSubscriptionPopup: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd"
          >
            <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                To delete your account, you first must stop your charity plan.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    cancelSubscriptionPopup: false,
                    showDelete: true,
                  });
                  axios
                    .post(
                      "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
                      {
                        subscriptionId:
                          this.state.currentUserMember.subscription_id,
                        customerId: this.state.currentUserMember.stripe_id,
                      }
                    )
                    .then(() => {
                      axios
                        .post(
                          "https://us-central1-bhodi-7c327.cloudfunctions.net/deleteStripeCustomer",
                          {
                            customerId: this.state.currentUserMember.stripe_id,
                          }
                        )
                        .then(() => {
                          this.deleteCurrentUser();
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
                color="primary"
              >
                Click here to stop plan
              </Button>
              <Button
                onClick={() =>
                  this.setState({ cancelSubscriptionPopup: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
            </DialogActions>
          </Dialog>
          {this.state.showSettingsMenu && (
            <SettingsSeparate
              type={this.state.typeOfPopup}
              fromMainPage={true}
              closeOnlyCurrent={() => {
                if (document.querySelector(".new-round-icons-member")) {
                  document.querySelector(".new-round-icons-member").style.display = "block"
                }
                this.setState({ showSettingsMenu: false, typeOfPopup: "" });
              }}
              closeIncludingRightMenu={() => {
                if (document.querySelector(".new-round-icons-member")) {
                  document.querySelector(".new-round-icons-member").style.display = "block"
                }
                this.setState({
                  rightModalSettings: false,
                  showSettingsMenu: false,
                  typeOfPopup: "",
                });
                document.querySelector("html").style.overflowY = "auto"

                let element = document.getElementById("footermain");
                if (element) {
                  element.style.display = "flex";
                }
                if (window.innerWidth < 1009) {
                  document.getElementById("root").style.position = "static";
                  document.getElementById("root").style.overflow = "hidden";
                }
                if (this.props.closeAboutBhodiRightMenu) {
                  this.props.closeAboutBhodiRightMenu();
                }
                document.getElementById("root").style.position = "static";
              }}
            />
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    title: state.renderReducer.title,
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    global_campaigns: state.campaignsReducer.global_campaigns,
    all_impressies: state.globalReducers.all_impressies,
    guest_user_cart: state.cartReducer.guest_user_cart,
    message: state.authReducers.message,
    albumToAdd: state.imagesReducer.albumToAdd,
    albumToAddFriends: state.imagesReducer.albumToAddFriends,
    bhodi_abouts: state.globalReducers.bhodi_abouts,
    bhodi_faqs: state.globalReducers.bhodi_faqs,
    bhodi_products: state.productsReducers.bhodi_products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTitle: (title) => dispatch(updateTitle(title)),
    updateCurrentMoments: (currentMoments) =>
      dispatch(updateCurrentMoments(currentMoments)),
    updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    updateMoreAlbumsAdded: (morealbumsToAdd) =>
      dispatch(updateMoreAlbumsAdded(morealbumsToAdd)),
    updateSelectedExclusive: (selected_exclusive) =>
      dispatch(updateSelectedExclusive(selected_exclusive)),
    updateAlbumToAddFriends: (albumToAddFriends) =>
      dispatch(updateAlbumToAddFriends(albumToAddFriends)),
    updateMoreAlbumsAddedFriends: (morealbumsToAddFriends) =>
      dispatch(updateMoreAlbumsAddedFriends(morealbumsToAddFriends)),
    updateSelectedExclusiveFriends: (selected_exclusive_friends) =>
      dispatch(updateSelectedExclusiveFriends(selected_exclusive_friends)),
    updateCurrentMomentsFriendsPage: (currentMomentsFriends) =>
      dispatch(updateCurrentMomentsFriendsPage(currentMomentsFriends)),
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateBhodiAbouts: (bhodi_abouts) => dispatch(updateBhodiAbouts(bhodi_abouts)),
    updateBhodiFaqs: (bhodi_faqs) => dispatch(updateBhodiFaqs(bhodi_faqs)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarMainFullWidthCompanyMobile);
