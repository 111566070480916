import React, { Component } from "react";
import Footer from "../../Components/Footer";
import "./index.css";
import firebase from "../../Config/Firebase";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import {
  updateSubscriptionPlans,
  updateGlobalStuff,
} from "../../Redux/actions/authActions";
import { connect } from "react-redux";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { AiOutlinePlayCircle } from "react-icons/ai"
import FooterGreen from "../../Components/FooterGreen";
import { history } from "../../Config/Routes";
import swal from "sweetalert";
import MySubcribedExclusives from "../../Components/MySubcribedExclusives";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ChevronLeft } from "@material-ui/icons";

const axios = require("axios");

export class Charity extends Component {
  constructor() {
    super();
    this.state = {
      currentNum: 0,
      videos: [
        { key: "videoone", played: false, sound: false },
        { key: "videosec", played: false, sound: false },
      ],
      videoPopup: false,
      allVideos: [],
      videourl: "",
      limit: 5,
      showPlayIcon: true,
      notSubscribed: true,
      subscription_data: [],
      currentUser: "",
      editWork: false,
      showTopButton: true,
      toShowSections: [],
      isDesktop: false,
      loader: false,
      pauseLoader: false,
      stopLoader: false,
      savingSubscription: false,
      onCurrentPage: 0,
      isStarted: false,
      isStopped: true,
      dropdownOpen: false,
      currentUserVideos: [],
      selectedVideoToShow: "",
      currentSelectedVideoPush: "",
      charityAudioSound: "",
      updateScreenText: false,
      showBasketGap: true,
      showLoaderDefault: false,
      showCloseButtonEndSubscribe: false,
      checkUserCart: [],
      showVideo: "",
      split_amount: "",
      companiesSubscribedTo: [],
      onlyShowExclusives: false,
      cancelAlertConfirmed: false,
    };
  }

  componentDidMount() {

    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let uid = localStorage.getItem("userid");
    document.getElementById("root").style.overflow = "hidden";
    // this.interval = setInterval(
    //   () => this.setState({ timeCurrent: Date.now() }),
    //   1000
    // );

    if (this.props.guest_user_cart) {
      this.setState({ checkUserCart: this.props.guest_user_cart })
    }

    let arr = [];

    if (window.innerWidth < 1008) {
      if (document.querySelector(".navbarfullwidthMobile")) {
        document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden";
      }
      document.querySelector("html").style.overflowY = "hidden"
    }

    firebase
      .database()
      .ref("subscription_plans")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let arr = [];
          let objected = Object.entries(a);
          objected.map((y) => {
            arr.push(y[1]);
          });
          this.props.updateSubscriptionPlans(arr);
        }
      });

    // let pushKey = firebase.database().ref("subscription_plans").push().key
    // console.log(pushKey, '/see here subscription plan key')

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.charity_video_sound) {
          this.setState({ charityAudioSound: a.charity_video_sound });
        }
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          if (a.charity_video_sound) {
            this.setState({ charityAudioSound: a.charity_video_sound });
          }
        }
      });

    // let currentUserDataDefault = localStorage.getItem("userdata")
    // if (currentUserDataDefault) {
    //     let a = JSON.parse(currentUserDataDefault)
    //     if (a) {
    //         let arr = this.props.subscription_plans
    //         if (a.stripe_id) {
    //             if (a.is_paused) {
    //                 this.setState({ currentUser: a, })
    //             } if (a.is_canceled) {
    //                 this.setState({ currentUser: a, })
    //             } else {
    //                 this.setState({ currentUser: a, })
    //                 firebase.database().ref("charity_videos/0hpiikv40HhUt41HTZ9jglLY0X33").on("value", (data) => {
    //                     let a = data.val()
    //                     if (a) {
    //                         let objs = Object.entries(a)
    //                         let arr = []
    //                         objs.map((f) => {
    //                             f[1].thumbnail_url = "http://saastoday.nl/public/assets/charity_videos/charity/138/71_15530730946thumbnail.png"
    //                             arr.push(f[1])
    //                         })
    //                         let videourl = objs[this.state.currentNum][1].url
    //                         let posterurl = objs[this.state.currentNum][1].thumbnail_url
    //                         let lengthToShow = Math.ceil(arr.length / 8)
    //                         let arrSecLength = []
    //                         for (let i = 0; i < lengthToShow; i++) {
    //                             arrSecLength.push(i)
    //                         }
    //                         this.setState({ allVideos: arr, toShowSections: arrSecLength, videourl, posterurl })
    //                     }
    //                 })
    //             }
    //         } else {
    //             this.setState({ currentUser: a, notSubscribed: true })
    //         }
    //     }
    // }

    if (this.props.subscription_plans) {
      if (this.props.subscription_plans.length > 0) {
        arr = this.props.subscription_plans;
        this.setState({
          subscription_data: arr,
          plan_id: arr[0].plan_id,
          plan_amount: arr[0].price,
          stripe_plan_key: arr[0].stripe_plan_key,
          split_amount: arr[0].split_amount,
        });
      }
    }

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((y) => {
          return y.user_id === uid;
        });
        if (filtered.length > 0) {
          let a = filtered[0];
          if (a) {
            if (a.create_subscription === true) {
              this.setState({ showLoaderDefault: true });
            }
            if (a.subscribedForExclusives) {
              let subscribedArrs = []
              let objected = Object.entries(a.subscribedForExclusives)
              objected.map((t) => {
                if (t[1].payment_status === "paid" && t[1].subscription && t[1].subscription.status === "active") {
                  subscribedArrs.push(t[1])
                }
              })
              this.setState({ companiesSubscribedTo: subscribedArrs })
            }
            // localStorage.setItem("userdata", JSON.stringify(a))
            if (a.subscription) {
              if (a.subscription.status === "active") {
                // if (a.is_paused) {
                //   this.setState({
                //     currentUser: a,
                //     current_plan_id: a.plan_id,
                //     current_plan_amount: a.plan_amount,
                //     editWork: true,
                //     showTopButton: false,
                //     notSubscribed: false,
                //   });
                // }
                if (a.is_canceled) {
                  this.setState({
                    currentUser: a,
                    current_plan_id: arr[0].plan_id,
                    current_plan_amount: arr[0].price,
                    current_stripe_plan_key: arr[0].stripe_plan_key,
                    split_amount: arr[0].split_amount,
                    editWork: true,
                    showTopButton: false,
                    notSubscribed: false,
                  });
                } else {
                  this.setState({
                    currentUser: a,
                    current_plan_id: Number(a.plan_id),
                    default_plan_id: Number(a.plan_id),
                    current_plan_amount: a.plan_amount,
                    current_stripe_plan_key: a.stripe_plan_key,
                    notSubscribed: false,
                    showTopButton: true,
                    editWork: false,
                    showBasketGap: false,
                  });
                  // firebase
                  //   .database()
                  //   .ref("charity_videos/0hpiikv40HhUt41HTZ9jglLY0X33")
                  //   .on("value", (data) => {
                  //     let a = data.val();
                  //     if (a) {
                  //       let objs = Object.entries(a);
                  //       let arr = [];
                  //       objs.map((f) => {
                  //         f[1].thumbnail_url =
                  //           "http://saastoday.nl/public/assets/charity_videos/charity/138/71_15530730946thumbnail.png";
                  //         arr.push(f[1]);
                  //       });
                  //       let videourl = objs[this.state.currentNum][1].url;
                  //       let posterurl =
                  //         objs[this.state.currentNum][1].thumbnail_url;
                  //       let lengthToShow = Math.ceil(arr.length / 8);
                  //       let arrSecLength = [];
                  //       for (let i = 0; i < lengthToShow; i++) {
                  //         arrSecLength.push(i);
                  //       }
                  //       this.setState({
                  //         allVideos: arr,
                  //         toShowSections: arrSecLength,
                  //         videourl,
                  //         posterurl,
                  //       });
                  //     }
                  //   });
                }
              } else if (a.is_canceled) {
                this.setState({
                  currentUser: a,
                  current_plan_id: arr[0].plan_id,
                  current_plan_amount: arr[0].price,
                  current_stripe_plan_key: arr[0].stripe_plan_key,
                  split_amount: arr[0].split_amount,
                  editWork: true,
                  showTopButton: false,
                  notSubscribed: false,
                });
              } else {
                this.setState({ currentUser: a, notSubscribed: true });
              }
            }
            // else if (a.uniqueCode) {
            //   this.setState({
            //     currentUser: a,
            //     notSubscribed: false,
            //     showTopButton: true,
            //     editWork: false,
            //     showBasketGap: false,
            //   });
            // }
            else {
              this.setState({ currentUser: a, notSubscribed: true });
            }
          }
        }
      }
    }

    firebase
      .database()
      .ref("users/" + uid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          // localStorage.setItem("userdata", JSON.stringify(a))
          if (a.subscribedForExclusives) {
            let subscribedArrs = []
            let objected = Object.entries(a.subscribedForExclusives)
            objected.map((t) => {
              if (t[1].payment_status === "paid" && t[1].subscription && t[1].subscription.status === "active") {
                subscribedArrs.push(t[1])
              }
            })
            this.setState({ companiesSubscribedTo: subscribedArrs })
          }
          if (a.subscription) {
            if (a.subscription.status === "active") {

              if (a.is_canceled) {
                this.setState({
                  currentUser: a,
                  // current_plan_id: arr[0].plan_id,
                  // current_plan_amount: arr[0].price,
                  // split_amount: arr[0].split_amount,
                  showTopButton: false,
                  notSubscribed: false,
                });
              } else {
                this.setState({
                  currentUser: a,
                  current_plan_id: Number(data.val().plan_id),
                  default_plan_id: Number(data.val().plan_id),
                  current_plan_amount: data.val().plan_amount,
                  current_stripe_plan_key: data.val().stripe_plan_key,
                  showTopButton: true,
                  notSubscribed: false,
                  showBasketGap: false,
                });
                // firebase
                //   .database()
                //   .ref("charity_videos/0hpiikv40HhUt41HTZ9jglLY0X33")
                //   .on("value", (data) => {
                //     let a = data.val();
                //     if (a) {
                //       let objs = Object.entries(a);
                //       let arr = [];
                //       objs.map((f) => {
                //         f[1].thumbnail_url =
                //           "http://saastoday.nl/public/assets/charity_videos/charity/138/71_15530730946thumbnail.png";
                //         arr.push(f[1]);
                //       });
                //       let videourl = objs[this.state.currentNum][1].url;
                //       let posterurl =
                //         objs[this.state.currentNum][1].thumbnail_url;
                //       let lengthToShow = Math.ceil(arr.length / 8);
                //       let arrSecLength = [];
                //       for (let i = 0; i < lengthToShow; i++) {
                //         arrSecLength.push(i);
                //       }
                //       this.setState({
                //         allVideos: arr,
                //         toShowSections: arrSecLength,
                //         videourl,
                //         posterurl,
                //       });
                //     }
                //   });
              }
            } else if (a.is_canceled) {
              this.setState({
                currentUser: a,
                // current_plan_id: arr[0].plan_id,
                // current_plan_amount: arr[0].price,
                // split_amount: arr[0].split_amount,
                editWork: true,
                showTopButton: false,
                notSubscribed: false,
              });
            } else {
              this.setState({ currentUser: a, notSubscribed: true });
            }
          }
          // else if (a.uniqueCode) {
          //   this.setState({
          //     currentUser: a,
          //     notSubscribed: false,
          //     showTopButton: true,
          //     editWork: false,
          //     showBasketGap: false,
          //   });
          // }
          else {
            this.setState({ currentUser: a, notSubscribed: true });
          }
        }
      });
    // if (!this.state.notSubscribed) {
    //     window.onscroll = () => {
    //         if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 1200)) {
    //             if (!this.state.stopData) {
    //                 this.getNextData()
    //                 this.setState({ loadingMore: true })
    //             }
    //         }
    //     };
    // }

    // firebase.database().ref("charity_videos/" + uid).on("value", (data) => {
    //     let a = data.val()
    //     if (a) {
    //         let objs = Object.entries(a)
    //         let arr = []
    //         // if (this.state.allVideos.length < objs.length) {
    //         objs.map((f) => {
    //             arr.push(f[1])
    //         })
    //         console.log(arr, '/user charity videos')
    //         // let videourl = objs[this.state.currentNum][1].url
    //         // this.setState({ allVideos: arr, videourl, stopData: false, limit: this.state.limit + 5 })
    //         // }
    //     }
    // })
    console.log(this.props.charity_videos, '/see here')
    if (this.props.charity_videos) {
      let objected = Object.entries(this.props.charity_videos);
      let arr = [];
      objected.map((y) => {
        if (y[0] === uid) {
          let objectedInner = Object.entries(y[1]);
          objectedInner.map((t) => {
            arr.push(t[1]);
          });
          console.log(arr, '/see here')
          this.setState({
            currentUserVideos: arr,
            currentSelectedVideoPush: arr[arr.length - 1].pushKey,
          });
          arr.map((e) => {
            firebase.database().ref(`charity_videos/${uid}/${e.pushKey}/seen`).set(true)
          })
        }
      });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1009) {
        this.setState({ isDesktop: false, isMobile: true });
      } else {
        document.getElementById("root").style.overflow = "hidden";
        document.getElementById("root").style.position = "fixed";
        this.setState({ isDesktop: true, isMobile: false });
      }
    });
    if (window.innerWidth < 1009) {
      this.setState({ isDesktop: false, isMobile: true });
    } else {
      document.getElementById("root").style.overflow = "hidden";
      document.getElementById("root").style.position = "fixed";
      this.setState({ isDesktop: true, isMobile: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.guest_user_cart !== prevProps.guest_user_cart) {
      this.setState({ checkUserCart: this.props.guest_user_cart })
    }
  }

  componentWillUnmount() {
    document.querySelector("html").style.overflowY = "auto"
  }

  getNextData() {
    this.setState({ stopData: true });
    firebase
      .database()
      .ref("charity_videos/0hpiikv40HhUt41HTZ9jglLY0X33")
      .limitToFirst(this.state.limit)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let objs = Object.entries(a);
          let arr = [];
          if (this.state.allVideos.length < objs.length) {
            objs.map((f) => {
              arr.push(f[1]);
            });
            let videourl = objs[this.state.currentNum][1].url;
            this.setState({
              allVideos: arr,
              videourl,
              stopData: false,
              limit: this.state.limit + 5,
            });
          }
        }
      });
  }

  opacityHandler(e) {
    if (window.innerWidth < 1009) {
      if (e.target.style.opacity === "") {
        e.target.style.opacity = 1;
      } else if (e.target.style.opacity === "0") {
        e.target.style.opacity = 1;
      } else if (e.target.style.opacity === "1") {
        e.target.style.opacity = 0;
      }
    }
  }

  videoPlayPause(e) {
    let elem = document.getElementById(e);
    if (elem.paused === true) {
      elem.play();
      elem.volume = 1;
      elem.muted = false;
    } else {
      elem.pause();
      elem.volume = 0;
      elem.muted = true;
    }
  }

  playIconCheck(e) {
    console.log(e.target);
  }

  videoPlayDesktop(e) {
    let elem = e.target;
    this.setState({ showPlayIcon: !this.state.showPlayIcon });
    if (elem.paused === true) {
      elem.play();
      elem.volume = 1;
      elem.muted = false;
    } else {
      elem.pause();
      elem.volume = 0;
      elem.muted = true;
    }
  }

  playVideo(e) {
    let elem = document.getElementById("videoplay");
    let elemAudio = document.getElementById("audiotoplay");
    this.setState({ isStopped: false, isStarted: true });
    if (elem.paused === true) {
      elem.play();
      elem.volume = 1;
      elem.muted = false;
      elemAudio.play();
    } else {
      elem.pause();
      elem.volume = 0;
      elem.muted = true;
      elemAudio.pause();
    }
  }

  pauseVideo(e) {
    let elem = document.getElementById("videoplay");
    let elemAudio = document.getElementById("audiotoplay");
    this.setState({ isStopped: true, isStarted: false });
    if (elem.paused === true) {
      elem.play();
      elem.volume = 1;
      elem.muted = false;
      elemAudio.play();
    } else {
      elem.pause();
      elem.volume = 0;
      elem.muted = true;
      elemAudio.pause();
    }
  }

  checkForPlayPause() {
    let elem = document.getElementById("videoplay");
    if (elem) {
      if (elem.paused === true) {
        return (
          <div className="action-rfscm">
            <PlayCircleOutlineIcon onClick={this.playVideo.bind(this)} />
          </div>
        );
      } else {
        return (
          <div className="action-rfscm showonhoveraction">
            <PauseCircleOutlineIcon onClick={this.pauseVideo.bind(this)} />
          </div>
        );
      }
    }
  }

  randomStr(e) {
    let len = e;
    let arr = "abcdefghijklmnopqrstuvwxyz1928102489";
    var ans = "";
    for (var i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }

  saveSubscription() {
    if (this.state.checkUserCart.length > 0) {
      swal({
        title: "Let op!", text: "Momenteel liggen er items in je winkelmand. Tijdens het plaatsen van je bestelling kun je je bijdrage als Charity Member selecteren.",
        className: "swal-charity-alert",
      }).then(() => {
        history.goBack()
      })
      return false
    }

    if (this.state.notSubscribed && !this.state.savingSubscription && this.state.current_plan_id) {
      this.setState({ loader: true });
      let userId = localStorage.getItem("userid");
      let uid = localStorage.getItem("userid");

      let plan_amount = this.state.plan_amount;
      let plan_id = this.state.plan_id;

      if (userId) {
        this.setState({ savingSubscription: true, mustSelectPlanId: false });

        let currentTime = Math.floor(Date.now() / 1000);
        let getRandom = this.randomStr(6);
        let hashToken = getRandom + "" + currentTime;

        let redirect_url = "";
        if (this.state.currentUser.type == "user") {
          redirect_url = `${window.location.origin}/member/${this.state.currentUser.user_name_id}/charity`;
        } else {
          redirect_url = `${window.location.origin}/company/${this.state.currentUser.user_name_id}/charity`;
        }

        if (this.state.currentUser.stripe_id) {
          axios
            .post(
              "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCheckout",
              {
                priceId: this.state.stripe_plan_key,
                successUrl: redirect_url,
                cancelUrl: redirect_url,
                customerId: this.state.currentUser.stripe_id,
                // amount: this.state.plan_amount,
                // customerId: this.state.currentUser.stripe_id,
                // redirectUrl: redirect_url,
              }
            )
            .then((responseSec) => {
              // firebase
              //   .database()
              //   .ref("users/" + uid + "/payment_url")
              //   .set(responseSec.data.payment._links.checkout.href);
              firebase
                .database()
                .ref("users/" + uid + "/plan_id")
                .set(plan_id);
              firebase
                .database()
                .ref("users/" + uid + "/plan_amount")
                .set(plan_amount);
              firebase
                .database()
                .ref("users/" + uid + "/create_subscription")
                .set(true);
              firebase
                .database()
                .ref("users/" + uid + "/payment_id")
                .set(responseSec.data.data.id)
                .then(() => {
                  window.location.href = responseSec.data.data.url;
                  // window.location.href =
                  //   responseSec.data.payment._links.checkout.href;
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .post(
              "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCustomer",
              {
                fullName:
                  this.state.currentUser.first_name +
                  "" +
                  this.state.currentUser.last_name,
                email: this.state.currentUser.email,
              }
            )
            .then((response) => {
              axios
                .post(
                  "https://us-central1-bhodi-7c327.cloudfunctions.net/createStripeCheckout",
                  {
                    priceId: this.state.stripe_plan_key,
                    successUrl: redirect_url,
                    cancelUrl: redirect_url,
                    customerId: response.data.data.id,
                    // amount: this.state.plan_amount,
                    // customerId: response.data.customer.id,
                    // redirectUrl: redirect_url,
                  }
                )
                .then((responseSec) => {
                  console.log(responseSec);
                  firebase
                    .database()
                    .ref("users/" + uid + "/stripe_id")
                    .set(response.data.data.id);
                  // firebase
                  //   .database()
                  //   .ref("users/" + uid + "/payment_url")
                  //   .set(responseSec.data.payment._links.checkout.href);
                  firebase
                    .database()
                    .ref("users/" + uid + "/plan_id")
                    .set(plan_id);
                  firebase
                    .database()
                    .ref("users/" + uid + "/plan_amount")
                    .set(plan_amount);
                  // firebase
                  //   .database()
                  //   .ref("users/" + uid + "/mollieHashToken")
                  //   .set(hashToken);
                  firebase
                    .database()
                    .ref("users/" + uid + "/create_subscription")
                    .set(true);
                  firebase
                    .database()
                    .ref("users/" + uid + "/payment_id")
                    .set(responseSec.data.data.id)
                    .then(() => {
                      window.location.href = responseSec.data.data.url;
                      // window.location.href =
                      //   responseSec.data.payment._links.checkout.href;
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } else {
      if (!this.state.current_plan_id) {
        this.setState({ mustSelectPlanId: true })
        setTimeout(() => {
          this.setState({ mustSelectPlanId: false })
        }, 2000);
      } else {
        this.setState({ mustSelectPlanId: false })
      }
    }
  }

  updateSubscription() {
    let plan_amount = this.state.current_plan_amount;
    let plan_id = this.state.current_plan_id;

    if (this.state.checkUserCart.length > 0) {
      swal({
        title: "Let op!", text: "Momenteel liggen er items in je winkelmand. Tijdens het plaatsen van je bestelling kun je je bijdrage als Charity Member selecteren.",
        className: "swal-charity-alert",
      }).then(() => {
        history.goBack()
      })
      return false
    }

    if (plan_id !== this.state.default_plan_id && !this.state.stopLoader && !this.state.updateLoader && plan_amount !== "2.00") {
      this.setState({ updateLoader: true });
      if (this.state.currentUser.subscription && !this.state.currentUser.subscription.id) {
        let uid = localStorage.getItem("userid");
        firebase.database().ref("users/" + uid + "/plan_id").set(plan_id);
        firebase.database().ref("users/" + uid + "/plan_amount").set(plan_amount);
        firebase.database().ref("users/" + uid + "/is_paused").remove();
        firebase.database().ref("users/" + uid + "/is_canceled").remove();

        firebase.database().ref(`recurring_payments/` + this.state.currentUser.user_id + "/amount").set(plan_amount).then(() => {
          firebase.database().ref(`recurring_payments/` + this.state.currentUser.user_id + "/route_amount").set(this.state.split_amount).then(() => {
            this.setState({
              stopLoader: false,
              pauseLoader: false,
              startSubLoader: false,
              updateLoader: false,
              updateScreenText: true,
              editWork: false,
              showCloseButtonEndSubscribe: true,
            });
          })
        })
        return false;
      }
      var d = new Date();
      d.setDate(d.getDate() + 30);

      let month = d.getMonth() + 1;
      let day = d.getDate();
      let year = d.getFullYear();

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      let date = [year, month, day].join("-");
      let reverseDate = [day, month, year].join("-");
      let description = `Subscription no ${this.state.currentUser.user_id_numbers} ${this.state.currentUser.first_name} ${this.state.currentUser.last_name}`;

      axios
        .post(
          "https://us-central1-bhodi-7c327.cloudfunctions.net/updateStripeCustomerSubscription",
          {
            subscriptionItemId: this.state.currentUser.subscription.items,
            // customerId: this.state.currentUser.stripe_id,
            // amount: plan_amount,
            // startDate: date,
            // description,
            priceId: this.state.stripe_plan_key,
          }
        )
        .then((response) => {
          let uid = localStorage.getItem("userid");
          if (!response.data.error) {
            firebase
              .database()
              .ref("users/" + uid + "/plan_id")
              .set(plan_id);
            firebase
              .database()
              .ref("users/" + uid + "/plan_amount")
              .set(plan_amount)
              .then(() => {
                this.setState({
                  stopLoader: false,
                  pauseLoader: false,
                  startSubLoader: false,
                  updateLoader: false,
                  updateScreenText: true,
                  editWork: false,
                  showCloseButtonEndSubscribe: true,
                });
              });
            // firebase
            //   .database()
            //   .ref("users/" + uid + "/is_paused")
            //   .remove();
            // firebase
            //   .database()
            //   .ref("users/" + uid + "/is_canceled")
            //   .remove();
            // firebase
            //   .database()
            //   .ref("users/" + uid + "/subscription")
            //   .set(response.data.subscription)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  startNewSubscription() {
    let plan_amount = this.state.current_plan_amount;
    let plan_id = this.state.current_plan_id;
    let userid = localStorage.getItem("userid");
    let uid = localStorage.getItem("userid");

    if (this.state.checkUserCart.length > 0) {
      swal({
        title: "Let op!", text: "Momenteel liggen er items in je winkelmand. Tijdens het plaatsen van je bestelling kun je je bijdrage als Charity Member selecteren.",
        className: "swal-charity-alert",
      }).then(() => {
        history.goBack()
      })
      return false
    }

    var d = new Date();
    // d.setDate(d.getDate() + 30)


    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    let date = [year, month, day].join("-");
    let reverseDate = [day, month, year].join("-");
    let description = `Subscription no ${this.state.currentUser.user_id_numbers} ${this.state.currentUser.first_name} ${this.state.currentUser.last_name}`;

    if (plan_id && !this.state.startSubLoader) {

      this.setState({ startSubLoader: true, mustSelectPlanId: false });

      axios
        .post(
          "https://us-central1-bhodi-7c327.cloudfunctions.net/createSubscription",
          {
            customerId: this.state.currentUser.stripe_id,
            amount: plan_amount,
            startDate: date,
            description,
          }
        )
        .then((response) => {
          if (!response.data.error) {
            firebase
              .database()
              .ref("users/" + userid + "/subscription")
              .set(response.data.subscription);
            firebase
              .database()
              .ref("users/" + userid + "/plan_id")
              .set(plan_id);
            firebase
              .database()
              .ref("users/" + userid + "/plan_amount")
              .set(plan_amount);
            firebase
              .database()
              .ref("users/" + userid + "/is_canceled")
              .remove();
            firebase
              .database()
              .ref("users/" + userid + "/is_paused")
              .remove();
            firebase
              .database()
              .ref("users/" + userid + "/subscription_id")
              .set(response.data.subscription.id)
              .then(() => {
                if (this.props.onProductDetailed && this.props.closePopup) {
                  this.props.closePopup()
                } else {
                  this.setState({
                    stopLoader: false,
                    pauseLoader: false,
                    startSubLoader: false,
                    updateLoader: false,
                    updateScreenText: false,
                    editWork: false,
                    showCloseButtonEndSubscribe: true,
                  });
                }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (!plan_id) {
        this.setState({ mustSelectPlanId: true })
        setTimeout(() => {
          this.setState({ mustSelectPlanId: false })
        }, 2000);
      } else {
        this.setState({ mustSelectPlanId: false })
      }
    }
  }

  cancelSubscription(askForPopup) {
    if (this.state.checkUserCart.length > 0) {
      swal({
        title: "Let op!", text: "Momenteel liggen er items in je winkelmand. Tijdens het plaatsen van je bestelling kun je je bijdrage als Charity Member selecteren.",
        className: "swal-charity-alert",
      }).then(() => {
        history.goBack()
      })
      return false
    }

    if (askForPopup) {
      swal({
        title: "Let op!",
        text: "Als je de maandelijkse bijdrage stopt, komen de (lopende) acties te vervallen. Weet je zeker dat je wilt stoppen?",
        className: "swal-charity-alert",
        buttons: ["Nee", "Ja"]
      }).then((resp) => {
        if (resp) {
          this.cancelSubscription()
        }
      })
      return false
    }

    if (!this.state.updateLoader && !this.state.stopLoader) {
      this.setState({ stopLoader: true });
      if (this.state.currentUser.subscription && !this.state.currentUser.subscription.id) {
        let uid = localStorage.getItem("userid");

        firebase.database().ref("users/" + uid + "/plan_id").remove();
        firebase.database().ref("users/" + uid + "/plan_amount").remove();
        // firebase.database().ref("users/" + uid + "/is_canceled").set(true);

        let obj = { status: "cancel" }

        firebase.database().ref("users/" + uid + "/subscription").set(obj)
        firebase.database().ref("users/" + uid + "/recurring_canceled").set(true)

        firebase.database().ref(`recurring_payments/` + this.state.currentUser.user_id).remove().then(() => {
          this.setState({
            stopLoader: false,
            pauseLoader: false,
            startSubLoader: false,
            updateLoader: false,
          });
        })
        return false;
      }
      axios
        .post(
          "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
          {
            subscriptionId: this.state.currentUser.subscription.id,
            // customerId: this.state.currentUser.stripe_id,
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            let uid = localStorage.getItem("userid");
            firebase
              .database()
              .ref("users/" + uid + "/plan_id")
              .remove();
            firebase
              .database()
              .ref("users/" + uid + "/plan_amount")
              .remove();
            firebase
              .database()
              .ref("users/" + uid + "/company_referred")
              .remove();

            this.setState({ stopLoader: false })
            // firebase
            //   .database()
            //   .ref("users/" + uid + "/is_canceled")
            //   .set(true);
            // firebase
            //   .database()
            //   .ref("users/" + uid + "/subscription")
            //   .set(response.data.subscription)
            //   .then(() => {
            //     this.setState({
            //       stopLoader: false,
            //       pauseLoader: false,
            //       startSubLoader: false,
            //       updateLoader: false,
            //     });
            //   });
            // if (response.data.subscription !== "canceled") {
            //   firebase
            //     .database()
            //     .ref("users/" + uid + "/subscription/status")
            //     .set("cancelled");
            // }
          } else {
            alert("error in canceling subscription")
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  pauseSubscription() {
    if (this.state.checkUserCart.length > 0) {
      swal({
        title: "Let op!", text: "Momenteel liggen er items in je winkelmand. Tijdens het plaatsen van je bestelling kun je je bijdrage als Charity Member selecteren.",
        className: "swal-charity-alert",
      }).then(() => {
        history.goBack()
      })
      return false
    }

    this.setState({ pauseLoader: true });
    axios
      .post(
        "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
        {
          subscriptionId: this.state.currentUser.subscription.id,
          // customerId: this.state.currentUser.stripe_id,
        }
      )
      .then((response) => {
        let uid = localStorage.getItem("userid");
        console.log(response)
        // let objSubscription = {
        //   status: event.data.object.status,
        //   id: event.data.object.id,
        //   customerId: event.data.object.customer,
        //   createdAt: event.data.object.created,
        //   startDate: event.data.object.start_date,
        // }

        // if (event.data.object.items && event.data.object.items.data && event.data.object.items.data.length > 0) {
        //   objSubscription.items = event.data.object.items.data[0].id
        // }
        // firebase
        //   .database()
        //   .ref("users/" + uid + "/is_paused")
        //   .set(true)
        //   .then(() => {
        //     firebase
        //       .database()
        //       .ref("users/" + uid + "/subscription/status")
        //       .set("cancelled")
        //       .then(() => {
        //         this.setState({
        //           stopLoader: false,
        //           pauseLoader: false,
        //           startSubLoader: false,
        //           updateLoader: false,
        //         });
        //       });
        //   });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onLeave(origin, destination, direction) {
    console.log("on leave: " + origin.index);
  }

  afterLoad(origin, destination, direction) {
    this.setState({ countsArray: [], onCurrentPage: destination.index });
  }

  getDatesOfCharityVideos() {
    var dateOfThirdMonths = new Date();
    dateOfThirdMonths.setMonth(dateOfThirdMonths.getMonth() - 12);
    dateOfThirdMonths.setDate(1);
    dateOfThirdMonths.setHours(0, 0, 0, 0);

    return (
      <ul>
        {this.state.currentUserVideos.map((y) => {
          if (y.created_at) {
            if (y.created_at > dateOfThirdMonths) {
              return (
                <li
                  onClick={() => {
                    this.setState({ currentSelectedVideoPush: y.pushKey });
                  }}
                >
                  {/* {new Date(y.created_at).getDate()}- */}
                  {this.getMonthName(new Date(y.created_at).getMonth() + 1)}
                  {/* {new Date(y.created_at).getFullYear()} */}
                </li>
              );
            }
          }
        })}
      </ul>
    );
  }

  getSelectedDateGood() {
    let filtered = this.state.currentUserVideos.filter((y) => {
      return y.pushKey === this.state.currentSelectedVideoPush;
    });
    if (filtered.length > 0) {
      if (filtered[0].created_at) {
        return (
          <p style={{ cursor: "pointer" }}>
            {/* {new Date(filtered[0].created_at).getDate()}{" "} */}
            {this.getMonthName(new Date(filtered[0].created_at).getMonth() + 1)}{" "}
            {/* {new Date(filtered[0].created_at).getFullYear()} */}
          </p>
        );
      }
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  getCurrentVideoURL() {
    let filtered = this.state.currentUserVideos.filter((y) => {
      return y.pushKey === this.state.currentSelectedVideoPush;
    });
    console.log(filtered, "/this URL to show");
    if (filtered.length > 0) {
      if (filtered[0].created_at) {
        return filtered[0].videoUrl;
      }
    }
  }

  goToLanding() {
    if (this.state.currentUser.type === "company") {
      window.location.href = `/company/${this.state.currentUser.user_name_id}`;
    } else {
      window.location.href = `/member/${this.state.currentUser.user_name_id}`;
    }
  }

  askForNewVideo() {
    let userid = localStorage.getItem("userid")

    firebase.database().ref(`users/${userid}/maxVideosToMake`).set(0)
    // firebase.database().ref(`users/${userid}/video_demanded`).set(true)
    firebase.database().ref(`users/${userid}/video_demanded_at`).set(new Date().toISOString())
  }

  isMoreThanOneMonthDifference(isoDate) {
    const isoDateObject = new Date(isoDate);

    const today = new Date();

    const monthDifference = (today.getFullYear() - isoDateObject.getFullYear()) * 12 + today.getMonth() - isoDateObject.getMonth();

    return monthDifference > 1;
  }

  checkForMoreVideoAsking() {
    let mealPerCharity = Number(this.state.currentUser.plan_amount) / 6
    // if (this.state.currentUserVideos.length === 0) {
    //   // if (mealPerCharity > this.state.currentUser.maxVideosToMake || this.state.currentUserVideos.length === 0) {
    //   return false;
    // }


    let isoDateObject = ""

    if (this.state.currentUserVideos.length > 0) {
      let videosSorted = this.state.currentUserVideos.sort((a, b) => {
        return b.created_at - a.created_at
      });
      isoDateObject = new Date(videosSorted[0].created_at);
      const today = new Date();

      const monthDifference = (today.getFullYear() - isoDateObject.getFullYear()) * 12 + today.getMonth() - isoDateObject.getMonth();

      if (monthDifference < 1) {
        return false;
      }
    }

    return <div className="more-videos-asking">
      {(this.state.currentUser.video_demanded_at && this.state.currentUserVideos.length === 0) ? (<p>Video coming your way soon, stay tuned</p>) : (this.state.currentUser.video_demanded_at && isoDateObject && isoDateObject.getTime() < new Date(Date.parse(this.state.currentUser.video_demanded_at)).getTime()) ? <p>Video coming your way soon, stay tuned</p> :
        // this.state.currentUser.video_demanded_at ? (this.isMoreThanOneMonthDifference(this.state.currentUser.video_demanded_at) ?
        //   (<p onClick={this.askForNewVideo.bind(this)}>
        //     Need more videos? Ask for it
        //   </p>) : <></>) :
        this.state.currentUser.maxVideosToMake && (<p onClick={this.askForNewVideo.bind(this)}>
          Need more videos? Ask for it
        </p>)}
    </div>
  }

  render() {
    return (
      <div className={`charitypageinnermain charitypageupdatedmain ${(window.innerWidth < 1008) ? "charitypagemembersupdatednew" : "charitypagecompanyupdatednew"}`}>
        {this.state.onlyShowExclusives && (<div className="loaderareaduplicate mcaloader loaderAreaUpdatedNavbar">
          <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
            {!this.state.isDesktop && (
              <ArrowBackIcon
                onClick={() => {
                  this.setState({
                    onlyShowExclusives: false,
                  });
                }}
                style={{
                  position: "absolute",
                  top: 15,
                  left: 10,
                  fontSize: 18,
                }}
              />
            )}
            <div
              className="heading-mca"
              style={{
                textAlign: "center",
                position: "relative",
                padding: "13px",
              }}
            >
              <font>MIJN EXCLUSIVES</font>
              <div>
                <ChevronLeft
                  onClick={() => {
                    this.setState({ onlyShowExclusives: false });
                  }}
                  style={{
                    fontSize: 18,
                  }}
                />
                <div className="spacing-between-icons"></div>
                <CloseIcon
                  onClick={() => {
                    this.setState({
                      onlyShowExclusives: false,
                    });
                    // this.setState({ rightModalSettings: false, privacyRight: false, channelPageRight: false, userInfoRight: false, shopSettings: false, voucherSettings: false, emailPassword: false, myOrdersRight: false, allOrdersRight: false, showBhodiFaqs: false, showBhodiAbouts: false })
                  }}
                  style={{
                    fontSize: 18,
                  }}
                />
              </div>
            </div>
            <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
              <div className="form-body-mca">
                <div className="inner-mca-settings">
                  <div
                    className="mca-settings-list"
                    style={{
                      flexDirection: "column",
                      backgroundColor: "transparent",
                      width: "90%",
                      margin: "10px auto",
                    }}
                  >
                    <div
                      className="wrapperforfullwidth"
                      style={{ position: "relative" }}
                    >
                      <MySubcribedExclusives />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
        {this.state.showTopButton &&
          !this.state.notSubscribed &&
          this.state.isDesktop && !this.state.showCloseButtonEndSubscribe && (
            <div
              className="top-btncharity"
              style={{
                position: "fixed",
                right: !this.state.editWork
                  ? 150
                  : 175,
                width: this.state.editWork && "auto",
                top: 24,
              }}
              onClick={() => {
                if (this.state.editWork) {
                  this.setState({ editWork: false });
                } else {
                  this.setState({ editWork: true, onlyShowExclusives: false });
                }
              }}
            >
              {this.state.editWork ? <CloseIcon /> : <EditIcon />}
              {this.state.editWork ? (
                <font>Sluit</font>
              ) : (
                <font>Jouw bijdrage</font>
              )}
            </div>
          )}
        {/* {(
          <div
            className={`top-btncharity ${this.state.notSubscribed ? "topbtncharity-exclusive-less" : "topbtncharity-exclusive"}`}
            style={{
              position: "fixed",
              right: (this.state.showTopButton && !this.state.notSubscribed && this.state.isDesktop && !this.state.showCloseButtonEndSubscribe) ? (!this.state.onlyShowExclusives ? 310 : 335) : (!this.state.onlyShowExclusives ? 150 : 175),
              width: this.state.onlyShowExclusives && "auto",
              top: 24,
            }}
            onClick={() => {
              if (this.state.onlyShowExclusives) {
                this.setState({ onlyShowExclusives: false });
              } else {
                this.setState({ onlyShowExclusives: true, editWork: false });
              }
            }}
          >
            {this.state.onlyShowExclusives ? <CloseIcon /> : <EditIcon />}
            {this.state.isDesktop && (this.state.onlyShowExclusives ? (
              <font>Sluit</font>
            ) : (
              <font>Mijn exclusives</font>
            ))}
          </div>
        )} */}
        {this.state.showTopButton &&
          !this.state.notSubscribed &&
          !this.state.isDesktop && !this.state.showCloseButtonEndSubscribe && (
            <div
              className="top-btncharity topbtncharityupdated"
              onClick={() => {
                if (this.state.editWork) {
                  this.setState({ editWork: false });
                } else {
                  this.setState({ editWork: true });
                }
              }}
            >
              {this.state.editWork ? <CloseIcon /> : <EditIcon />}
            </div>
          )}
        {/* {this.state.showCloseButtonEndSubscribe &&
          !this.state.notSubscribed &&
          this.state.isDesktop && (
            <div
              className="top-btncharity"
              style={{
                position: "fixed",
                right: !this.state.showBasketGap
                  ? !this.state.showCloseButtonEndSubscribe
                    ? 110
                    : 135
                  : !this.state.showCloseButtonEndSubscribe
                    ? 150
                    : 175,
                width: "auto",
                top: 24,
              }}
              onClick={() => {
                this.setState({ showCloseButtonEndSubscribe: true });
              }}
            >
              <CloseIcon />
              <font>Sluit</font>
            </div>
          )}
        {this.state.showCloseButtonEndSubscribe &&
          !this.state.notSubscribed &&
          !this.state.isDesktop && (
            <div
              className="top-btncharity topbtncharityupdated"
              onClick={() => {
                this.setState({ showCloseButtonEndSubscribe: false });
              }}
            >
              <CloseIcon />
            </div>
          )} */}
        {this.state.currentUser.create_subscription ? (
          <div
            style={{
              position: this.state.isDesktop && "fixed",
              width: "100%",
              height: this.props.onProductDetailed ? "100%" : (window.innerWidth < 1008) ? `${window.innerHeight - 132.36}px` : "calc(100% - 110px)",
              top: this.props.onProductDetailed ? 0 : 110,
              paddingBottom: !this.state.isDesktop && 250,
            }}
            className="charitysectionmainwork"
          >
            <div
              className="charityfullpageupdated"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: this.state.isDesktop && "fixed",
              width: "100%",
              height: this.props.onProductDetailed ? "100%" : (window.innerWidth < 1008) ? `${window.innerHeight - 132.36}px` : "calc(100% - 110px)",
              top: this.props.onProductDetailed ? 0 : window.innerWidth > 1390 ? 110 : 70,
              paddingBottom: !this.state.isDesktop && 250,
            }}
            className="charitysectionmainwork"
          >
            {/* <div className="loader-fullPage" style={{ opacity: this.state.loader ? 1 : 0 }}>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div> */}
            {/* <NavSecMain /> */}
            {(this.state.notSubscribed && !this.state.isMobile) ? (
              <div
                className="vs-main"
                id="aanbieding"
                style={{
                  backgroundImage: "none",
                  position: "static",
                  zIndex: 99999999999,
                }}
              >
                <div
                  style={{
                    position: this.state.isDesktop && "fixed",
                    width: "100%",
                    height: this.props.onProductDetailed ? "100%" : (window.innerWidth < 1008) ? `${window.innerHeight - 132.36}px` : "calc(100% - 110px)",
                    top: this.props.onProductDetailed ? 0 : window.innerWidth > 1390 ? 110 : 70,
                    zIndex: 9999999,
                    paddingBottom: !this.state.isDesktop && 250,
                  }}
                  className={`charitysectionmainwork newvisitwork updatedCharityClassNotSubscribed`}
                >
                  <video
                    src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bg-bhodi.mp4?alt=media&token=a1fdec11-797f-4619-8414-f0d329c23fb4&_gl=1*edigq3*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5NjYwMTYzNS40MDMuMS4xNjk2NjAyMjY5LjYwLjAuMA."
                    autoPlay
                    playsInline
                    muted
                    loop
                    className="absolutevideofullbg"
                  ></video>
                  <div
                    className="third-sec-cdd"
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <div
                      className="footer-sscdd"
                      style={{
                        marginTop: 0,
                        alignItems: "initial",
                        justifyContent: "center",
                        height: "auto",
                      }}
                    >
                      <div
                        className="left-fsscdd"
                        style={{
                          backgroundColor: "#285151",
                          maxWidth: "26%",
                          textAlign: "center",
                        }}
                      >
                        <div className="charity-lfss">
                          <p
                            className="heading-charity-lfss"
                            style={{
                              fontWeight: 400,
                              fontSize: 14,
                              textTransform: "none",
                            }}
                          >
                            Hoi{" "}
                            <b>
                              {this.state.currentUser.type === "user"
                                ? this.state.currentUser.first_name
                                : this.state.currentUser.company_name}
                            </b>
                            ,
                          </p>
                          {(this.state.currentUser.subscription && this.state.currentUser.subscription.status === "charged_back") && <p className="para-charity-lfss">
                            Je betaling is helaas niet gelukt en je donatie is gestopt.
                          </p>}
                          {(this.state.showLoaderDefault && this.state.currentUser.payment_status !== "paid") && <p className="para-charity-lfss" style={{ color: "#C17F6B" }}>
                            Je aanmelding is helaas niet gelukt.
                          </p>}
                          <p className="para-charity-lfss">
                            Word Charity Member en krijg toegang tot
                            <br />
                            exclusieve aanbiedingen op onze website.
                          </p>
                          <p className="para-charity-lfss">
                            Als Charity Member ondersteun je kinderen
                            <br />
                            en scholen in Tanzania met maaltijden en
                            <br />
                            materialen. Van deze uitgifte ontvang je
                            <br />
                            een bedankvideo vanuit Tanzania.
                          </p>
                          <p className="para-charity-lfss">
                            Je maandelijkse bijdrage kun je op ieder
                            <br />
                            gewenst moment aanpassen of stopzetten.
                          </p>
                          <p className="para-charity-lfss">
                            Selecteer je bijdrage:
                          </p>
                          <div
                            className="radios-charity-lfss"
                            style={{ justifyContent: "center" }}
                          >
                            <span
                              className="tooltiptext tooltipinfo charitytooltiptext"
                              style={{ display: this.state.mustSelectPlanId ? "inline-block" : "none" }}
                            >
                              Selecteer
                            </span>
                            {this.state.subscription_data.length > 0 &&
                              [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                if (!e.active) {
                                  if (e.plan_id !== this.state.default_plan_id) {
                                    return false;
                                  }
                                }
                                return (
                                  <div className="inner-rclfss">
                                    <input
                                      type="radio"
                                      id="radiocharity"
                                      name="charity"
                                      value={e.plan_id}
                                      checked={
                                        e.plan_id === this.state.current_plan_id
                                      }
                                      onChange={() =>
                                        this.setState({
                                          plan_id: e.plan_id,
                                          plan_amount: e.price,
                                          stripe_plan_key: e.stripe_plan_key,
                                          current_plan_id: e.plan_id,
                                          current_plan_amount: e.price,
                                          current_stripe_plan_key: e.stripe_plan_key,
                                          split_amount: e.split_amount,
                                        })
                                      }
                                    />
                                    <span>€ {Number(e.price)}</span>
                                  </div>
                                );
                              })}
                          </div>
                          {!this.state.notSubscribed &&
                            this.state.currentUser.payment_status ===
                            "paid" && (
                              <button
                                className="button-charity-lfss bcl-newdesign"
                                style={{ marginRight: 5 }}
                              >
                                Stoppen
                              </button>
                            )}
                          <button
                            className="button-charity-lfss bcl-newdesign"
                            style={{ marginRight: 5 }}
                            onClick={this.goToLanding.bind(this)}
                          >
                            Sluit
                          </button>
                          <button
                            className="button-charity-lfss bcl-newdesign"
                            onClick={this.saveSubscription.bind(this)}
                            style={{
                              backgroundColor:
                                this.state.savingSubscription && "black",
                              color: this.state.savingSubscription && "white",
                            }}
                          >
                            {this.state.savingSubscription ? (
                              <font>
                                <i class="fa fa-spinner fa-spin"></i> Opslaan
                              </font>
                            ) : (
                              <font>Opslaan</font>
                            )}
                          </button>
                          {/* <button className="button-charity-lfss bcl-newdesign" onClick={this.saveSubscription.bind(this)} style={{ backgroundColor: this.state.startSubLoader && "black", color: this.state.startSubLoader && "white" }}>{!this.state.startSubLoader ? <font>OPSLAAN </font> : <font><i class="fa fa-spinner fa-spin"></i> OPSLAAN</font>}</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              !this.state.isMobile && (
                <div
                  className="vs-main"
                  id="aanbieding"
                  style={{
                    backgroundImage: "none",
                    position: "static",
                    zIndex: 99999999999,
                  }}
                >
                  {this.state.editWork ? (
                    <div
                      style={{
                        position: this.state.isDesktop && "fixed",
                        width: "100%",
                        height: this.props.onProductDetailed ? "100%" : (window.innerWidth < 1008) ? `${window.innerHeight - 132.36}px` : "calc(100% - 110px)",
                        top: this.props.onProductDetailed ? 0 : window.innerWidth > 1390 ? 110 : 70,
                        zIndex: 9999999,
                        paddingBottom: !this.state.isDesktop && 250,
                      }}
                      className={`charitysectionmainwork newvisitwork ${this.state.currentUser.user_description &&
                        "descriptionofuseralso"
                        }`}
                    >
                      <video
                        src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bg-bhodi.mp4?alt=media&token=a1fdec11-797f-4619-8414-f0d329c23fb4&_gl=1*edigq3*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5NjYwMTYzNS40MDMuMS4xNjk2NjAyMjY5LjYwLjAuMA."
                        autoPlay
                        playsInline
                        muted
                        loop
                        className="absolutevideofullbg"
                      ></video>
                      <div
                        className="third-sec-cdd"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <div
                          className="footer-sscdd"
                          style={{
                            marginTop: 0,
                            alignItems: "initial",
                            justifyContent: "center",
                            height: "auto",
                          }}
                        >
                          <div
                            className="left-fsscdd"
                            style={{
                              backgroundColor: "#285151",
                              maxWidth: "26%",
                              textAlign: "center",
                            }}
                          >
                            {this.state.currentUser.is_canceled ? (
                              <div className="charity-lfss">
                                {/* <img src={heart} style={{ marginTop: 0, marginBottom: 20 }} /> */}
                                <p
                                  className="heading-charity-lfss"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    textTransform: "none",
                                  }}
                                >
                                  Hoi{" "}
                                  <b>
                                    {this.state.currentUser.type === "user"
                                      ? this.state.currentUser.first_name
                                      : this.state.currentUser.company_name}
                                  </b>
                                  ,
                                </p>
                                {/* <p className="para-charity-lfss">Word charity member en krijg toegang tot<br />exclusieve aanbiedingen op onze website.</p> */}
                                {/* <p className="para-charity-lfss">Als charity member ondersteun je kinderen en scholen in Tanzania met maaltijden en materialen. Hiervan ontvang je elke maand een bedankvideo vanuit Tanzania.</p> */}
                                {/* <p className="para-charity-lfss">Je maandelijkse bijdrage kun je op ieder<br />gewenst moment aanpassen of stopzetten.<br /> Selecteer je bijdrage:</p> */}
                                {(this.state.currentUser.subscription && this.state.currentUser.subscription.status === "charged_back") && <p className="para-charity-lfss">
                                  Je betaling is helaas niet gelukt en je donatie is gestopt.
                                </p>}
                                {(this.state.showLoaderDefault && this.state.currentUser.payment_status !== "paid") && <p className="para-charity-lfss" style={{ color: "#C17F6B" }}>
                                  Je aanmelding is helaas niet gelukt.
                                </p>}
                                <p className="para-charity-lfss">
                                  Word Charity Member en krijg toegang tot
                                  <br />
                                  exclusieve aanbiedingen op onze website.
                                </p>
                                <p className="para-charity-lfss">
                                  Als Charity Member ondersteun je kinderen
                                  <br />
                                  en scholen in Tanzania met maaltijden en
                                  <br />
                                  materialen. Van deze uitgifte ontvang je
                                  <br />
                                  een bedankvideo vanuit Tanzania.
                                </p>
                                <p className="para-charity-lfss">
                                  Je maandelijkse bijdrage kun je op ieder
                                  <br />
                                  gewenst moment aanpassen of stopzetten.
                                </p>
                                <p className="para-charity-lfss">
                                  Selecteer je bijdrage:
                                </p>
                                <div
                                  className="radios-charity-lfss"
                                  style={{ justifyContent: "center" }}
                                >
                                  <span
                                    className="tooltiptext tooltipinfo charitytooltiptext"
                                    style={{ display: this.state.mustSelectPlanId ? "inline-block" : "none" }}
                                  >
                                    Selecteer
                                  </span>
                                  {this.state.subscription_data.length > 0 &&
                                    [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                      if (!e.active) {
                                        if (e.plan_id !== this.state.default_plan_id) {
                                          return false;
                                        }
                                      }
                                      return (
                                        <div className="inner-rclfss">
                                          <input
                                            type="radio"
                                            id="radiocharity"
                                            name="charity"
                                            value={e.plan_id}
                                            checked={
                                              e.plan_id ===
                                              this.state.current_plan_id
                                            }
                                            onChange={() =>
                                              this.setState({
                                                plan_id: e.plan_id,
                                                plan_amount: e.price,
                                                stripe_plan_key: e.stripe_plan_key,
                                                current_plan_id: e.plan_id,
                                                current_plan_amount: e.price,
                                                current_stripe_plan_key: e.stripe_plan_key,
                                                split_amount: e.split_amount,
                                              })
                                            }
                                          />
                                          <span>€ {Number(e.price)}</span>
                                        </div>
                                      );
                                    })}
                                </div>
                                {!this.state.notSubscribed &&
                                  this.state.currentUser.payment_status ===
                                  "paid" &&
                                  !this.state.currentUser.is_paused &&
                                  !this.state.currentUser.is_canceled && (
                                    <button
                                      className="button-charity-lfss bcl-newdesign"
                                      style={{
                                        marginRight: 5,
                                        backgroundColor:
                                          this.state.stopLoader && "black",
                                        color: this.state.stopLoader && "white",
                                      }}
                                      onClick={this.cancelSubscription.bind(
                                        this, true
                                      )}
                                    >
                                      {!this.state.stopLoader ? (
                                        <font>Stoppen</font>
                                      ) : (
                                        <font>
                                          <i class="fa fa-spinner fa-spin"></i>{" "}
                                          Stoppen
                                        </font>
                                      )}
                                    </button>
                                  )}
                                {/* {!this.state.notSubscribed && this.state.currentUser.payment_status === "paid" && !this.state.currentUser.is_paused && !this.state.currentUser.is_canceled && <button className="button-charity-lfss bcl-newdesign" style={{ marginRight: 5 }} onClick={this.cancelSubscription.bind(this)}>{!this.state.stopLoader ? <font>STOPPEN</font> : <font><i class="fa fa-spinner fa-spin"></i> STOPPING</font>}</button>} */}
                                {/* {!this.state.notSubscribed && !this.state.currentUser.is_paused && !this.state.currentUser.is_canceled && <button className="button-charity-lfss bcl-newdesign" style={{ marginRight: 5, backgroundColor: "white", color: "black" }} onClick={this.pauseSubscription.bind(this)}>{!this.state.pauseLoader ? <font>PAUSE</font> : <font><i class="fa fa-spinner fa-spin"></i> PAUSING</font>}</button>} */}
                                {this.props.onProductDetailed && this.props.closePopup ? (<button
                                  className="button-charity-lfss bcl-newdesign"
                                  style={{ marginRight: 5 }}
                                  onClick={() => this.props.closePopup()}
                                >
                                  Sluit
                                </button>) : !(window.innerWidth < 1008) && (<button
                                  className="button-charity-lfss bcl-newdesign"
                                  style={{ marginRight: 5 }}
                                  onClick={this.goToLanding.bind(this)}
                                >
                                  Sluit
                                </button>)}
                                <button
                                  className="button-charity-lfss bcl-newdesign"
                                  onClick={this.startNewSubscription.bind(this)}
                                  style={{
                                    backgroundColor:
                                      this.state.startSubLoader && "black",
                                    color: this.state.startSubLoader && "white",
                                  }}
                                >
                                  {!this.state.startSubLoader ? (
                                    <font>Opslaan </font>
                                  ) : (
                                    <font>
                                      <i class="fa fa-spinner fa-spin"></i>{" "}
                                      Opslaan
                                    </font>
                                  )}
                                </button>
                              </div>
                            ) : (
                              <div className="charity-lfss">
                                <p
                                  className="heading-charity-lfss"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    textTransform: "none",
                                  }}
                                >
                                  Hoi{" "}
                                  <b>
                                    {this.state.currentUser.type === "user"
                                      ? this.state.currentUser.first_name
                                      : this.state.currentUser.company_name}
                                  </b>
                                  ,
                                </p>
                                {/* <p className="heading-charity-lfss" style={{ fontWeight: 400, fontSize: 16, textTransform: "none" }}>Hoi {this.state.currentUser.type === 'user' ? this.state.currentUser.first_name : this.state.currentUser.company_name},</p> */}
                                <p className="para-charity-lfss">
                                  Wil je jouw bijdrage verhogen of verlagen?
                                  <br />
                                  Dan kun je dat hieronder aanpassen door
                                  <br />
                                  het juiste bedrag te selecteren en op de
                                  <br />
                                  knop met Wijzigen te klikken.
                                </p>
                                <p className="para-charity-lfss">
                                  Wil je jouw bijdrage helemaal stopzetten?
                                  <br />
                                  Dan bedanken wij je voor jouw steun van de
                                  <br />
                                  afgelopen tijd. Door op de knop met Stoppen
                                  <br />
                                  te klikken wordt je bijdrage direct gestopt.
                                </p>
                                <p className="para-charity-lfss">
                                  Selecteer je bijdrage:
                                </p>
                                {/* <p className="para-charity-lfss">Bedankt voor je maandelijkse steun!<br />Als charity member maak je een mooi verschil in het leven van schoolkinderen in Tanzania. Met jouw bijdrage ondersteun je de kinderen en scholen met maaltijden en materialen. En jij krijgt toegang tot exclusieve acties.</p>
                                            <p className="para-charity-lfss">Je maandelijkse bijdrage kun je eenvoudig en op ieder gewenst moment aanpassen of stopzetten.</p> */}
                                <div
                                  className="radios-charity-lfss"
                                  style={{ justifyContent: "center" }}
                                >
                                  {this.state.currentUser.plan_amount === "2.00" && (<div className="inner-rclfss">
                                    <input
                                      type="radio"
                                      id="radiocharity"
                                      name="charity"
                                      value={100}
                                      checked={this.state.current_plan_id === 100}
                                      onChange={() =>
                                        this.setState({
                                          plan_id: 100,
                                          plan_amount: "2.00",
                                          stripe_plan_key: "price_1PRTPzGbWn2wWpJR9lkU7twU",
                                          current_plan_id: 100,
                                          current_plan_amount: "2.00",
                                          current_stripe_plan_key: "price_1PRTPzGbWn2wWpJR9lkU7twU",
                                          split_amount: "0.5",
                                        })
                                      }
                                    />
                                    <span>€ 2</span>
                                  </div>)}
                                  {this.state.subscription_data.length > 0 &&
                                    [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                      if (!e.active) {
                                        if (e.plan_id !== this.state.default_plan_id) {
                                          return false;
                                        }
                                      }
                                      return (
                                        <div className="inner-rclfss">
                                          <input
                                            type="radio"
                                            id="radiocharity"
                                            name="charity"
                                            value={e.plan_id}
                                            checked={
                                              e.plan_id ===
                                              this.state.current_plan_id
                                            }
                                            onChange={() =>
                                              this.setState({
                                                plan_id: e.plan_id,
                                                plan_amount: e.price,
                                                stripe_plan_key: e.stripe_plan_key,
                                                current_plan_id: e.plan_id,
                                                current_plan_amount: e.price,
                                                current_stripe_plan_key: e.stripe_plan_key,
                                                split_amount: e.split_amount,
                                              })
                                            }
                                          />
                                          <span>€ {Number(e.price)}</span>
                                        </div>
                                      );
                                    })}
                                </div>
                                {!this.state.notSubscribed &&
                                  this.state.currentUser.payment_status ===
                                  "paid" &&
                                  !this.state.currentUser.is_paused &&
                                  !this.state.currentUser.is_canceled && (
                                    <button
                                      className="button-charity-lfss bcl-newdesign"
                                      style={{
                                        marginRight: 5,
                                        backgroundColor:
                                          this.state.stopLoader && "black",
                                        color: this.state.stopLoader && "white",
                                      }}
                                      onClick={this.cancelSubscription.bind(
                                        this, true
                                      )}
                                    >
                                      {!this.state.stopLoader ? (
                                        <font>Stoppen</font>
                                      ) : (
                                        <font>
                                          <i class="fa fa-spinner fa-spin"></i>{" "}
                                          Stoppen
                                        </font>
                                      )}
                                    </button>
                                  )}
                                <button
                                  className="button-charity-lfss bcl-newdesign"
                                  onClick={this.updateSubscription.bind(this)}
                                  style={{
                                    backgroundColor:
                                      this.state.updateLoader && "black",
                                    color: this.state.updateLoader && "white",
                                  }}
                                >
                                  {!this.state.updateLoader ? (
                                    <font>Wijzigen </font>
                                  ) : (
                                    <font>
                                      <i class="fa fa-spinner fa-spin"></i>{" "}
                                      Wijzigen
                                    </font>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) :
                    // this.state.currentUserVideos.length > 0 ? (
                    //   <ReactFullpage
                    //     onLeave={this.onLeave.bind(this)}
                    //     afterLoad={this.afterLoad.bind(this)}
                    //     responsiveWidth="1008"
                    //     render={({ state, fullpageApi }) => {
                    //       return (
                    //         <div style={{ height: "100%" }}>
                    //           <div className="section">
                    //             <div
                    //               className="first-section-charitymain"
                    //               style={{ padding: "0px 50px" }}
                    //             >
                    //               <div className="left-fscm">
                    //                 <div
                    //                   className="top-lfscm"
                    //                   onClick={() => {
                    //                     if (
                    //                       this.state.currentUserVideos.length > 1
                    //                     ) {
                    //                       this.setState({
                    //                         dropdownOpen:
                    //                           !this.state.dropdownOpen,
                    //                       });
                    //                     }
                    //                   }}
                    //                 >
                    //                   <CameraAltIcon />
                    //                   {/* <p>22 July 2021</p> */}
                    //                   {this.getSelectedDateGood()}
                    //                   {this.state.currentUserVideos.length >
                    //                     1 && <KeyboardArrowDownIcon />}
                    //                   {this.state.dropdownOpen && (
                    //                     <div className="dropdown-tlfscm">
                    //                       {this.getDatesOfCharityVideos()}
                    //                     </div>
                    //                   )}
                    //                 </div>
                    //                 <div className="content-lfscm">
                    //                   <p className="mainheading-clfscm">
                    //                     CHARITY VIDEO
                    //                   </p>
                    //                   <p className="secheading-clfscm">
                    //                     MEMBER AANBIEDINGEN
                    //                   </p>
                    //                   <p className="para-charity-lfss">
                    //                     Hii BFF. Thank you so much for your
                    //                     support.! :)
                    //                   </p>
                    //                   <p className="para-charity-lfss">
                    //                     Word charity member en krijg toegang tot
                    //                     <br />
                    //                     exclusieve aanbiedingen op onze website.
                    //                   </p>
                    //                   <p className="para-charity-lfss">
                    //                     Als charity member ondersteun je kinderen
                    //                     en scholen in Tanzania met maaltijden en
                    //                     materialen. Van deze uitgifte ontvang je
                    //                     een bedankvideo vanuit Tanzania.
                    //                   </p>
                    //                   <p className="para-charity-lfss">
                    //                     Je maandelijkse bijdrage kun je op ieder
                    //                     gewenst moment stopzetten of aanpassen.
                    //                     <br /> Selecteer je bijdrage:
                    //                   </p>
                    //                   <p
                    //                     className="para-charity-lfss"
                    //                     style={{ marginTop: 50, marginBottom: 5 }}
                    //                   >
                    //                     All the best
                    //                   </p>
                    //                   <p className="para-charity-lfss">
                    //                     Member Name
                    //                   </p>
                    //                 </div>
                    //               </div>
                    //               <div className="right-fscm">
                    //                 {this.checkForPlayPause()}
                    //                 <video
                    //                   id="videoplay"
                    //                   loop
                    //                   src={this.getCurrentVideoURL()}
                    //                 >
                    //                   {/* <source src={this.getCurrentVideoURL()} type="video/mp4" /> */}
                    //                 </video>
                    //                 <audio
                    //                   loop
                    //                   src={this.state.charityAudioSound}
                    //                   id="audiotoplay"
                    //                 ></audio>
                    //               </div>
                    //             </div>
                    //             <div
                    //               className="unds-buttons"
                    //               style={{ alignItems: "center", bottom: 90 }}
                    //             >
                    //               <font
                    //                 style={{
                    //                   fontSize: 13,
                    //                   letterSpacing: 1,
                    //                   marginRight: 8,
                    //                   fontWeight: 600,
                    //                   cursor: "pointer",
                    //                 }}
                    //               >
                    //                 Lees meer
                    //               </font>
                    //               <div
                    //                 className="inner-undb"
                    //                 onClick={() => {
                    //                   fullpageApi.moveSectionDown();
                    //                 }}
                    //               >
                    //                 <KeyboardArrowDownIcon />
                    //               </div>
                    //             </div>
                    //           </div>
                    //           <div
                    //             className="section"
                    //             style={{ backgroundColor: "white" }}
                    //           >
                    //             <div
                    //               className="first-section-charitymain"
                    //               style={{ flexDirection: "column" }}
                    //             >
                    //               <div className="titletext-charity">
                    //                 <LocationOnIcon />
                    //                 <p className="title-ttc">BHODI ACADEMY</p>
                    //                 <p className="date-ttc">22 JULY 2021</p>
                    //               </div>
                    //               <div className="images-inner-charity">
                    //                 <div className="container-img-charity">
                    //                   <div className="iic-inner">
                    //                     <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/voucherimages%2FCJ_FALL21_JULY_HOMEPAGE_1944x%20(1).jpg?alt=media&token=67c377be-41a9-4017-b561-9853348e03e3" />
                    //                   </div>
                    //                   <div className="iic-inner">
                    //                     <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/voucherimages%2FCJ_FALL21_JULY_HOMEPAGE_1944x%20(1).jpg?alt=media&token=67c377be-41a9-4017-b561-9853348e03e3" />
                    //                   </div>
                    //                   <div className="iic-inner">
                    //                     <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/voucherimages%2FCJ_FALL21_JULY_HOMEPAGE_1944x%20(1).jpg?alt=media&token=67c377be-41a9-4017-b561-9853348e03e3" />
                    //                   </div>
                    //                   <div className="iic-inner">
                    //                     <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/voucherimages%2FCJ_FALL21_JULY_HOMEPAGE_1944x%20(1).jpg?alt=media&token=67c377be-41a9-4017-b561-9853348e03e3" />
                    //                   </div>
                    //                   <div className="iic-inner">
                    //                     <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/voucherimages%2FCJ_FALL21_JULY_HOMEPAGE_1944x%20(1).jpg?alt=media&token=67c377be-41a9-4017-b561-9853348e03e3" />
                    //                   </div>
                    //                   <div className="iic-inner">
                    //                     <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/voucherimages%2FCJ_FALL21_JULY_HOMEPAGE_1944x%20(1).jpg?alt=media&token=67c377be-41a9-4017-b561-9853348e03e3" />
                    //                   </div>
                    //                 </div>
                    //               </div>
                    //             </div>
                    //             <div
                    //               className="unds-buttons"
                    //               style={{ alignItems: "center", bottom: 90 }}
                    //             >
                    //               {this.state.onCurrentPage > 0 && (
                    //                 <div
                    //                   className="inner-undb"
                    //                   onClick={() => {
                    //                     fullpageApi.moveSectionUp();
                    //                   }}
                    //                 >
                    //                   <KeyboardArrowUpIcon />
                    //                 </div>
                    //               )}
                    //             </div>
                    //           </div>
                    //         </div>
                    //       );
                    //     }}
                    //   />
                    // ) 
                    this.state.currentUserVideos.length > 0 ? (<div
                      className="charityfullpageupdated"
                      style={{ height: "100%" }}
                    >
                      <div
                        style={{
                          position: this.state.isDesktop && "fixed",
                          width: "100%",
                          height: this.props.onProductDetailed ? "100%" : (window.innerWidth < 1008) ? `${window.innerHeight - 132.36}px` : "calc(100% - 110px)",
                          top: this.props.onProductDetailed ? 0 : 110,
                          left: 0,
                          zIndex: 9999999,
                          paddingBottom: !this.state.isDesktop && 250,
                        }}
                        className={`charitysectionmainwork newvisitwork updatedVideosMadeWork ${this.state.currentUser.user_description &&
                          "descriptionofuseralso"
                          }`}
                      >
                        <video
                          src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bg-bhodi.mp4?alt=media&token=a1fdec11-797f-4619-8414-f0d329c23fb4&_gl=1*edigq3*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5NjYwMTYzNS40MDMuMS4xNjk2NjAyMjY5LjYwLjAuMA."
                          playsInline
                          autoPlay
                          muted
                          loop
                          className="absolutevideofullbg"
                        ></video>
                        <div
                          className="third-sec-cdd"
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          <div
                            className="footer-sscdd"
                            style={{
                              marginTop: 0,
                              alignItems: "initial",
                              justifyContent: "center",
                              height: "auto",
                            }}
                          >
                            <div
                              className="left-fsscdd"
                              style={{
                                backgroundColor: "#285151",
                                maxWidth: "26%",
                                textAlign: "center",
                              }}
                            >
                              <div className="charity-lfss">
                                <p
                                  className="charitysection-heading"
                                  style={{
                                    fontWeight: 600,
                                    color: "white"
                                  }}
                                >
                                  BHODI CHARITY
                                </p>
                                <p className="para-charity-lfss" style={{ color: "#C17F6B", fontFamily: "Oswald", fontSize: 18 }}>
                                  Bedankvideo
                                </p>
                                <p className="para-charity-lfss" style={{ fontFamily: window.innerWidth > 1009 && "Oswald" }}>
                                  Ons team in Tanzania is aan de slag
                                  <br />
                                  gegaan met de maaltijduitgifte. Bekijk
                                  <br />
                                  hieronder je persoonlijke bedankvideo.
                                </p>
                                {!this.state.currentUser.hideWizard && <button style={{ marginTop: 0 }}
                                  className="button-charity-lfss bcl-newdesign"
                                  onClick={() => {
                                    if (this.state.currentUser.type === "company") {
                                      history.push(`/company/${this.state.currentUser.user_name_id}`)
                                    } else {
                                      history.push(`/member/${this.state.currentUser.user_name_id}`)
                                    }
                                  }}
                                >
                                  <font>Explore</font>
                                </button>}
                                <div
                                  className="top-lfscm"
                                  onClick={() => {
                                    if (
                                      this.state.currentUserVideos.length > 1
                                    ) {
                                      this.setState({
                                        dropdownOpen:
                                          !this.state.dropdownOpen,
                                      });
                                    }
                                  }}
                                >
                                  {this.getSelectedDateGood()}
                                  {this.state.currentUserVideos.length >
                                    1 && <KeyboardArrowDownIcon />}
                                  {this.state.dropdownOpen && (
                                    <div className="dropdown-tlfscm">
                                      {this.getDatesOfCharityVideos()}
                                    </div>
                                  )}
                                </div>
                                <AiOutlinePlayCircle className="aiplay-videonew" onClick={() => {
                                  let filtered = this.state.currentUserVideos.filter((y) => {
                                    return y.pushKey === this.state.currentSelectedVideoPush;
                                  });
                                  if (filtered.length > 0) {
                                    if (filtered[0].created_at) {
                                      this.setState({ showVideo: filtered[0].videoUrl })
                                    }
                                  }
                                }} />
                                {this.checkForMoreVideoAsking()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>) : (
                      <div
                        className="charityfullpageupdated"
                        style={{ height: "100%" }}
                      >
                        <div
                          style={{
                            position: this.state.isDesktop && "fixed",
                            width: "100%",
                            height: this.props.onProductDetailed ? "100%" : (window.innerWidth < 1008) ? `${window.innerHeight - 132.36}px` : "calc(100% - 110px)",
                            top: this.props.onProductDetailed ? 0 : 110,
                            left: 0,
                            zIndex: 9999999,
                            paddingBottom: !this.state.isDesktop && 250,
                          }}
                          className={`charitysectionmainwork newvisitwork ${this.state.currentUser.user_description &&
                            "descriptionofuseralso"
                            }`}
                        >
                          <video
                            src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bg-bhodi.mp4?alt=media&token=a1fdec11-797f-4619-8414-f0d329c23fb4&_gl=1*edigq3*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5NjYwMTYzNS40MDMuMS4xNjk2NjAyMjY5LjYwLjAuMA."
                            playsInline
                            autoPlay
                            muted
                            loop
                            className="absolutevideofullbg"
                          ></video>
                          <div
                            className="third-sec-cdd"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            <div
                              className="footer-sscdd"
                              style={{
                                marginTop: 0,
                                alignItems: "initial",
                                justifyContent: "center",
                                height: "auto",
                              }}
                            >
                              <div
                                className="left-fsscdd"
                                style={{
                                  backgroundColor: "#285151",
                                  maxWidth: "26%",
                                  textAlign: "center",
                                }}
                              >
                                {this.state.updateScreenText ? (
                                  <div className="charity-lfss">
                                    <p
                                      className="heading-charity-lfss"
                                      style={{
                                        fontWeight: 400,
                                        fontSize: 14,
                                        textTransform: "none",
                                      }}
                                    >
                                      Hoi{" "}
                                      <b>
                                        {this.state.currentUser.type === "user"
                                          ? this.state.currentUser.first_name
                                          : this.state.currentUser.company_name}
                                      </b>
                                      ,
                                    </p>
                                    <p className="para-charity-lfss">
                                      Je hebt zojuist een wijziging aangebracht
                                      <br />
                                      in je maandelijkse bijdrage aan Bhodi.
                                    </p>
                                    <p className="para-charity-lfss">
                                      Je wijziging is goed ontvangen!
                                    </p>
                                    <p className="para-charity-lfss">
                                      We zijn blij dat je ervoor gekozen hebt
                                      <br />
                                      om de kinderen en scholen te blijven
                                      <br />
                                      ondersteunen met je bijdrage.
                                    </p>
                                    {this.state.showCloseButtonEndSubscribe && !(window.innerWidth < 1008) && <button
                                      className="button-charity-lfss bcl-newdesign"
                                      onClick={() => {
                                        if (this.state.currentUser.type === "company") {
                                          history.push(`/company/${this.state.currentUser.user_name_id}`)
                                        } else {
                                          history.push(`/member/${this.state.currentUser.user_name_id}`)
                                        }
                                      }}
                                    >
                                      <font>SLUIT </font>
                                    </button>}
                                  </div>
                                ) : (
                                  <div className="charity-lfss">
                                    <p
                                      className="charitysection-heading"
                                      style={{
                                        fontWeight: 600,
                                        color: "white"
                                      }}
                                    >
                                      BHODI CHARITY
                                    </p>
                                    <p className="para-charity-lfss" style={{ color: "#C17F6B", fontFamily: "Oswald", fontSize: 18 }}>
                                      Bedankvideo
                                    </p>
                                    <p className="para-charity-lfss">
                                      Ons team in Tanzania gaat voor je aan de
                                      <br />
                                      slag met de maaltijduitgifte. Binnenkort
                                      <br />
                                      ontvang je jouw persoonlijke bedankvideo.
                                    </p>
                                    {!this.state.currentUser.hideWizard && <button style={{ marginTop: 0 }}
                                      className="button-charity-lfss bcl-newdesign"
                                      onClick={() => {
                                        if (this.state.currentUser.type === "company") {
                                          history.push(`/company/${this.state.currentUser.user_name_id}`)
                                        } else {
                                          history.push(`/member/${this.state.currentUser.user_name_id}`)
                                        }
                                      }}
                                    >
                                      <font>Explore</font>
                                    </button>}
                                    {this.state.showCloseButtonEndSubscribe && !(window.innerWidth < 1008) && <button style={{ marginTop: 10 }}
                                      className="button-charity-lfss bcl-newdesign"
                                      onClick={() => {
                                        if (this.state.currentUser.type === "company") {
                                          history.push(`/company/${this.state.currentUser.user_name_id}`)
                                        } else {
                                          history.push(`/member/${this.state.currentUser.user_name_id}`)
                                        }
                                      }}
                                    >
                                      <font>SLUIT </font>
                                    </button>}
                                    {this.checkForMoreVideoAsking()}
                                  </div>
                                  // <div className="charity-lfss">
                                  //   <p
                                  //     className="heading-charity-lfss"
                                  //     style={{
                                  //       fontWeight: 400,
                                  //       fontSize: 14,
                                  //       textTransform: "none",
                                  //     }}
                                  //   >
                                  //     Hoi{" "}
                                  //     <b>
                                  //       {this.state.currentUser.type === "user"
                                  //         ? this.state.currentUser.first_name
                                  //         : this.state.currentUser.company_name}
                                  //     </b>
                                  //     ,
                                  //   </p>
                                  //   <p className="para-charity-lfss">
                                  //     Hartelijk dank voor je aanmelding!
                                  //   </p>
                                  //   <p className="para-charity-lfss">
                                  //     Ons team in Tanzania gaat voor je aan de
                                  //     <br />
                                  //     slag met de maaltijduitgifte. Binnenkort
                                  //     <br />
                                  //     ontvang je hiervan een persoonlijke en
                                  //     <br />
                                  //     op naam ingesproken bedankvideo.
                                  //   </p>
                                  //   <p className="para-charity-lfss">
                                  //     Als Charity Member krijg je nu toegang
                                  //     <br />
                                  //     tot exclusieve pop-up events en acties.
                                  //   </p>
                                  //   {this.state.showCloseButtonEndSubscribe && <button
                                  //     className="button-charity-lfss bcl-newdesign"
                                  //     onClick={() => {
                                  //       if (this.state.currentUser.type === "company") {
                                  //         history.push(`/company/${this.state.currentUser.user_name_id}`)
                                  //       } else {
                                  //         history.push(`/member/${this.state.currentUser.user_name_id}`)
                                  //       }
                                  //     }}
                                  //   >
                                  //     <font>SLUIT </font>
                                  //   </button>}
                                  // </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )
            )}
            {this.state.isMobile && !this.state.notSubscribed && (
              <div
                className="mobile-displaycharity mobileupdatedcharitypageown mobiledisplaycharity charitywithscrollenabledinnersmalldevices"
                style={{
                  // height: "100vh",
                  backgroundColor: this.state.editWork ? "#285151" : "none",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {this.state.editWork ? (
                  <div
                    style={{
                      width: "100%",
                      height: this.props.onProductDetailed ? "100%" : (window.innerWidth < 1008) ? `${window.innerHeight - 132.36}px` : "calc(100% - 110px)",
                      top: this.props.onProductDetailed ? 0 : 110,
                      zIndex: 9999999,
                      paddingBottom: !this.state.isDesktop && 250,
                      paddingTop: 120,
                    }}
                    className={`charitysectionmainwork newvisitwork ${this.state.currentUser.user_description &&
                      "descriptionofuseralso"
                      }`}
                  >
                    <div
                      className="third-sec-cdd"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <div
                        className="footer-sscdd"
                        style={{
                          marginTop: 0,
                          alignItems: "initial",
                          justifyContent: "center",
                          height: "auto",
                        }}
                      >
                        <div
                          className="left-fsscdd"
                          style={{
                            backgroundColor: "#285151",
                            maxWidth: "26%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.currentUser.is_canceled ? (
                            <div className="charity-lfss">
                              <p
                                className="heading-charity-lfss"
                                style={{
                                  fontWeight: 400,
                                  fontSize: 14,
                                  textTransform: "none",
                                }}
                              >
                                Hoi{" "}
                                <b>
                                  {this.state.currentUser.type === "user"
                                    ? this.state.currentUser.first_name
                                    : this.state.currentUser.company_name}
                                </b>
                                ,
                              </p>
                              {(this.state.currentUser.subscription && this.state.currentUser.subscription.status === "charged_back") && <p className="para-charity-lfss">
                                Je betaling is helaas niet gelukt en je donatie is gestopt.
                              </p>}
                              <p className="para-charity-lfss">
                                Word Charity Member en krijg toegang tot
                                <br />
                                exclusieve aanbiedingen op onze website.
                              </p>
                              <p className="para-charity-lfss">
                                Als Charity Member ondersteun je kinderen
                                <br />
                                en scholen in Tanzania met maaltijden en
                                <br />
                                materialen. Van deze uitgifte ontvang je
                                <br />
                                een bedankvideo vanuit Tanzania.
                              </p>
                              <p className="para-charity-lfss">
                                Je maandelijkse bijdrage kun je op ieder
                                <br />
                                gewenst moment aanpassen of stopzetten.
                              </p>
                              <p className="para-charity-lfss">
                                Selecteer je bijdrage:
                              </p>
                              <div
                                className="radios-charity-lfss"
                                style={{ justifyContent: "center" }}
                              >
                                {this.state.subscription_data.length > 0 &&
                                  [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                    if (!e.active) {
                                      if (e.plan_id !== this.state.default_plan_id) {
                                        return false;
                                      }
                                    }
                                    return (
                                      <div className="inner-rclfss">
                                        <input
                                          type="radio"
                                          id="radiocharity"
                                          name="charity"
                                          value={e.plan_id}
                                          checked={
                                            e.plan_id ===
                                            this.state.current_plan_id
                                          }
                                          onChange={() =>
                                            this.setState({
                                              plan_id: e.plan_id,
                                              plan_amount: e.price,
                                              stripe_plan_key: e.stripe_plan_key,
                                              current_plan_id: e.plan_id,
                                              current_plan_amount: e.price,
                                              current_stripe_plan_key: e.stripe_plan_key,
                                              split_amount: e.split_amount,
                                            })
                                          }
                                        />
                                        <span>€ {Number(e.price)}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                              {!this.state.notSubscribed &&
                                this.state.currentUser.payment_status ===
                                "paid" &&
                                !this.state.currentUser.is_paused &&
                                !this.state.currentUser.is_canceled && (
                                  <button
                                    className="button-charity-lfss bcl-newdesign"
                                    style={{
                                      marginRight: 5,
                                      backgroundColor:
                                        this.state.stopLoader && "black",
                                      color: this.state.stopLoader && "white",
                                    }}
                                    onClick={this.cancelSubscription.bind(this, true)}
                                  >
                                    {!this.state.stopLoader ? (
                                      <font>Stoppen</font>
                                    ) : (
                                      <font>
                                        <i class="fa fa-spinner fa-spin"></i>{" "}
                                        Stoppen
                                      </font>
                                    )}
                                  </button>
                                )}
                              {!(window.innerWidth < 1008) && (<button
                                className="button-charity-lfss bcl-newdesign"
                                style={{ marginRight: 5 }}
                                onClick={this.goToLanding.bind(this)}
                              >
                                Sluit
                              </button>)}
                              <button
                                className="button-charity-lfss bcl-newdesign"
                                onClick={this.startNewSubscription.bind(this)}
                                style={{
                                  backgroundColor:
                                    this.state.startSubLoader && "black",
                                  color: this.state.startSubLoader && "white",
                                }}
                              >
                                {!this.state.startSubLoader ? (
                                  <font>Opslaan </font>
                                ) : (
                                  <font>
                                    <i class="fa fa-spinner fa-spin"></i>{" "}
                                    Opslaan
                                  </font>
                                )}
                              </button>
                            </div>
                          ) : (
                            <div className="charity-lfss">
                              <p
                                className="heading-charity-lfss"
                                style={{
                                  fontWeight: 400,
                                  fontSize: 14,
                                  textTransform: "none",
                                }}
                              >
                                Hoi{" "}
                                <b>
                                  {this.state.currentUser.type === "user"
                                    ? this.state.currentUser.first_name
                                    : this.state.currentUser.company_name}
                                </b>
                                ,
                              </p>
                              <p className="para-charity-lfss">
                                Wil je jouw bijdrage verhogen of verlagen?
                                <br />
                                Dan kun je dat hieronder aanpassen door
                                <br />
                                het juiste bedrag te selecteren en op de
                                <br />
                                knop met Wijzigen te klikken.
                              </p>
                              <p className="para-charity-lfss">
                                Wil je jouw bijdrage helemaal stopzetten?
                                <br />
                                Dan bedanken wij je voor jouw steun van de
                                <br />
                                afgelopen tijd. Door op de knop met Stoppen
                                <br />
                                te klikken wordt je bijdrage direct gestopt.
                              </p>
                              <p className="para-charity-lfss">
                                Selecteer je bijdrage:
                              </p>
                              <div
                                className="radios-charity-lfss"
                                style={{ justifyContent: "center" }}
                              >
                                {this.state.currentUser.plan_amount === "2.00" && (<div className="inner-rclfss">
                                  <input
                                    type="radio"
                                    id="radiocharity"
                                    name="charity"
                                    value={100}
                                    checked={this.state.current_plan_id === 100}
                                    onChange={() =>
                                      this.setState({
                                        plan_id: 100,
                                        plan_amount: "2.00",
                                        stripe_plan_key: "price_1PRTPzGbWn2wWpJR9lkU7twU",
                                        current_plan_id: 100,
                                        current_plan_amount: "2.00",
                                        current_stripe_plan_key: "price_1PRTPzGbWn2wWpJR9lkU7twU",
                                        split_amount: "0.5",
                                      })
                                    }
                                  />
                                  <span>€ 2</span>
                                </div>)}
                                {this.state.subscription_data.length > 0 &&
                                  [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                    if (!e.active) {
                                      if (e.plan_id !== this.state.default_plan_id) {
                                        return false;
                                      }
                                    }
                                    return (
                                      <div className="inner-rclfss">
                                        <input
                                          type="radio"
                                          id="radiocharity"
                                          name="charity"
                                          value={e.plan_id}
                                          checked={
                                            e.plan_id ===
                                            this.state.current_plan_id
                                          }
                                          onChange={() =>
                                            this.setState({
                                              plan_id: e.plan_id,
                                              plan_amount: e.price,
                                              stripe_plan_key: e.stripe_plan_key,
                                              current_plan_id: e.plan_id,
                                              current_plan_amount: e.price,
                                              current_stripe_plan_key: e.stripe_plan_key,
                                              split_amount: e.split_amount,
                                            })
                                          }
                                        />
                                        <span>€ {Number(e.price)}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                              {!this.state.notSubscribed &&
                                this.state.currentUser.payment_status ===
                                "paid" &&
                                !this.state.currentUser.is_paused &&
                                !this.state.currentUser.is_canceled && (
                                  <button
                                    className="button-charity-lfss bcl-newdesign"
                                    style={{
                                      marginRight: 5,
                                      backgroundColor:
                                        this.state.stopLoader && "black",
                                      color: this.state.stopLoader && "white",
                                    }}
                                    onClick={this.cancelSubscription.bind(this, true)}
                                  >
                                    {!this.state.stopLoader ? (
                                      <font>Stoppen</font>
                                    ) : (
                                      <font>
                                        <i class="fa fa-spinner fa-spin"></i>{" "}
                                        Stoppen
                                      </font>
                                    )}
                                  </button>
                                )}
                              <button
                                className="button-charity-lfss bcl-newdesign"
                                onClick={this.updateSubscription.bind(this)}
                                style={{
                                  backgroundColor:
                                    this.state.updateLoader && "black",
                                  color: this.state.updateLoader && "white",
                                }}
                              >
                                {!this.state.updateLoader ? (
                                  <font>Wijzigen </font>
                                ) : (
                                  <font>
                                    <i class="fa fa-spinner fa-spin"></i>{" "}
                                    Wijzigen
                                  </font>
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="charityfullpageupdated charitynewfullupdated" style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "static",
                        width: "100%",
                        left: 0,
                        zIndex: 9999999,
                      }}
                      className={`charitysectionmainwork newvisitwork dochangesonmobilecharityfirst ${this.state.currentUser.user_description &&
                        "descriptionofuseralso"
                        }`}
                    >
                      <video
                        src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bg-bhodi.mp4?alt=media&token=a1fdec11-797f-4619-8414-f0d329c23fb4&_gl=1*edigq3*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5NjYwMTYzNS40MDMuMS4xNjk2NjAyMjY5LjYwLjAuMA."
                        autoPlay
                        playsInline
                        muted
                        loop
                        className="absolutevideofullbg"
                      ></video>
                      <div
                        className="third-sec-cdd"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        {this.state.currentUserVideos.length > 0 ? (<div
                          className="footer-sscdd"
                          style={{
                            marginTop: 0,
                            alignItems: "initial",
                            justifyContent: "center",
                            height: "auto",
                          }}
                        >
                          <div
                            className="left-fsscdd"
                            style={{
                              backgroundColor: "#285151",
                              maxWidth: "26%",
                              textAlign: "center",
                            }}
                          >
                            <div className="charity-lfss">
                              <p
                                className="charitysection-heading"
                                style={{
                                  fontWeight: 600,
                                  color: "white"
                                }}
                              >
                                BHODI CHARITY
                              </p>
                              <p className="para-charity-lfss" style={{ color: "#C17F6B", fontFamily: "Oswald", fontSize: 18 }}>
                                Bedankvideo
                              </p>
                              <p className="para-charity-lfss" style={{ fontFamily: window.innerWidth > 1009 && "Oswald" }}>
                                Ons team in Tanzania is aan de slag
                                <br />
                                gegaan met de maaltijduitgifte. Bekijk
                                <br />
                                hieronder je persoonlijke bedankvideo.
                              </p>
                              {!this.state.currentUser.hideWizard && <button style={{ marginTop: 0 }}
                                className="button-charity-lfss bcl-newdesign"
                                onClick={() => {
                                  if (this.state.currentUser.type === "company") {
                                    history.push(`/company/${this.state.currentUser.user_name_id}`)
                                  } else {
                                    history.push(`/member/${this.state.currentUser.user_name_id}`)
                                  }
                                }}
                              >
                                <font>Explore</font>
                              </button>}
                              <div
                                className="top-lfscm mobiletoplfscm"
                                onClick={() => {
                                  if (
                                    this.state.currentUserVideos.length > 1
                                  ) {
                                    this.setState({
                                      dropdownOpen:
                                        !this.state.dropdownOpen,
                                    });
                                  }
                                }}
                              >
                                {this.getSelectedDateGood()}
                                {this.state.currentUserVideos.length >
                                  1 && <KeyboardArrowDownIcon />}
                                {this.state.dropdownOpen && (
                                  <div className="dropdown-tlfscm">
                                    {this.getDatesOfCharityVideos()}
                                  </div>
                                )}
                              </div>
                              <AiOutlinePlayCircle className="aiplay-videonew" onClick={() => {
                                let filtered = this.state.currentUserVideos.filter((y) => {
                                  return y.pushKey === this.state.currentSelectedVideoPush;
                                });
                                if (filtered.length > 0) {
                                  if (filtered[0].created_at) {
                                    this.setState({ showVideo: filtered[0].videoUrl })
                                  }
                                }
                              }} />
                              {this.checkForMoreVideoAsking()}
                            </div>
                          </div>
                        </div>) : (<div
                          className="footer-sscdd"
                          style={{
                            marginTop: 0,
                            alignItems: "initial",
                            justifyContent: "center",
                            height: "auto",
                          }}
                        >
                          <div
                            className="left-fsscdd"
                            style={{
                              backgroundColor: "#285151",
                              maxWidth: "26%",
                              textAlign: "center",
                            }}
                          >
                            {this.state.updateScreenText ? (
                              <div className="charity-lfss">
                                <p
                                  className="heading-charity-lfss"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    textTransform: "none",
                                  }}
                                >
                                  Hoi{" "}
                                  <b>
                                    {this.state.currentUser.type === "user"
                                      ? this.state.currentUser.first_name
                                      : this.state.currentUser.company_name}
                                  </b>
                                  ,
                                </p>
                                <p className="para-charity-lfss">
                                  Je hebt zojuist een wijziging aangebracht
                                  <br />
                                  in je maandelijkse bijdrage aan Bhodi.
                                </p>
                                <p className="para-charity-lfss">
                                  Je wijziging is goed ontvangen!
                                </p>
                                <p className="para-charity-lfss">
                                  We zijn blij dat je ervoor gekozen hebt
                                  <br />
                                  om de kinderen en scholen te blijven
                                  <br />
                                  ondersteunen met je bijdrage.
                                </p>
                                {this.state.showCloseButtonEndSubscribe && !(window.innerWidth < 1008) && <button
                                  className="button-charity-lfss bcl-newdesign"
                                  onClick={() => {
                                    if (this.state.currentUser.type === "company") {
                                      history.push(`/company/${this.state.currentUser.user_name_id}`)
                                    } else {
                                      history.push(`/member/${this.state.currentUser.user_name_id}`)
                                    }
                                  }}
                                >
                                  <font>Sluit </font>
                                </button>}
                              </div>
                            ) : (
                              <div className="charity-lfss">
                                <p
                                  className="charitysection-heading"
                                  style={{
                                    fontWeight: 600,
                                    color: "white"
                                  }}
                                >
                                  BHODI CHARITY
                                </p>
                                <p className="para-charity-lfss" style={{ color: "#C17F6B", fontFamily: "Oswald", fontSize: 18 }}>
                                  Bedankvideo
                                </p>
                                <p className="para-charity-lfss">
                                  Ons team in Tanzania gaat voor je aan de
                                  <br />
                                  slag met de maaltijduitgifte. Binnenkort
                                  <br />
                                  ontvang je jouw persoonlijke bedankvideo.
                                </p>
                                {!this.state.currentUser.hideWizard && <button style={{ marginTop: 0 }}
                                  className="button-charity-lfss bcl-newdesign"
                                  onClick={() => {
                                    if (this.state.currentUser.type === "company") {
                                      history.push(`/company/${this.state.currentUser.user_name_id}`)
                                    } else {
                                      history.push(`/member/${this.state.currentUser.user_name_id}`)
                                    }
                                  }}
                                >
                                  <font>Explore</font>
                                </button>}
                                {this.state.showCloseButtonEndSubscribe && !(window.innerWidth < 1008) && <button style={{ marginTop: 0 }}
                                  className="button-charity-lfss bcl-newdesign"
                                  onClick={() => {
                                    if (this.state.currentUser.type === "company") {
                                      history.push(`/company/${this.state.currentUser.user_name_id}`)
                                    } else {
                                      history.push(`/member/${this.state.currentUser.user_name_id}`)
                                    }
                                  }}
                                >
                                  <font>Sluit </font>
                                </button>}
                                {this.checkForMoreVideoAsking()}
                              </div>
                              // <div className="charity-lfss">
                              //   <p
                              //     className="heading-charity-lfss"
                              //     style={{
                              //       fontWeight: 400,
                              //       fontSize: 14,
                              //       textTransform: "none",
                              //     }}
                              //   >
                              //     Hoi{" "}
                              //     <b>
                              //       {this.state.currentUser.type === "user"
                              //         ? this.state.currentUser.first_name
                              //         : this.state.currentUser.company_name}
                              //     </b>
                              //     ,
                              //   </p>
                              //   <p className="para-charity-lfss">
                              //     Hartelijk dank voor je aanmelding!
                              //   </p>
                              //   <p className="para-charity-lfss">
                              //     Ons team in Tanzania gaat voor je aan de
                              //     <br />
                              //     slag met de maaltijduitgifte. Binnenkort
                              //     <br />
                              //     ontvang je hiervan een persoonlijke en
                              //     <br />
                              //     op naam ingesproken bedankvideo.
                              //   </p>
                              //   <p className="para-charity-lfss">
                              //     Als Charity Member krijg je nu toegang
                              //     <br />
                              //     tot exclusieve pop-up events en acties.
                              //   </p>
                              //   {this.state.showCloseButtonEndSubscribe && <button
                              //     className="button-charity-lfss bcl-newdesign"
                              //     onClick={() => {
                              //       if (this.state.currentUser.type === "company") {
                              //         history.push(`/company/${this.state.currentUser.user_name_id}`)
                              //       } else {
                              //         history.push(`/member/${this.state.currentUser.user_name_id}`)
                              //       }
                              //     }}
                              //   >
                              //     <font>SLUIT </font>
                              //   </button>}
                              // </div>
                            )}
                          </div>
                        </div>)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {this.state.isMobile && this.state.notSubscribed && (
              <div
                className="mobile-displaycharity mobileupdatedcharitypageown mobiledisplaycharity charitywithscrollenabledinnersmalldevices"
                style={{
                  // height: "100vh",
                  backgroundColor: this.state.editWork ? "#285151" : "none",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="charityfullpageupdated charitynewfullupdated" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "static",
                      width: "100%",
                      left: 0,
                      zIndex: 9999999,
                    }}
                    className={`charitysectionmainwork newvisitwork dochangesonmobilecharityfirst ${this.state.currentUser.user_description &&
                      "descriptionofuseralso"
                      }`}
                  >
                    <video
                      src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bg-bhodi.mp4?alt=media&token=a1fdec11-797f-4619-8414-f0d329c23fb4&_gl=1*edigq3*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5NjYwMTYzNS40MDMuMS4xNjk2NjAyMjY5LjYwLjAuMA."
                      autoPlay
                      playsInline
                      muted
                      loop
                      className="absolutevideofullbg"
                    ></video>
                    <div
                      className="third-sec-cdd"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <div
                        className="footer-sscdd"
                        style={{
                          marginTop: 0,
                          alignItems: "initial",
                          justifyContent: "center",
                          height: "auto",
                        }}
                      >
                        <div
                          className="left-fsscdd"
                          style={{
                            backgroundColor: "#285151",
                            maxWidth: "26%",
                            textAlign: "center",
                          }}
                        >
                          <div className="charity-lfss">
                            <p
                              className="heading-charity-lfss"
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                textTransform: "none",
                              }}
                            >
                              Hoi{" "}
                              <b>
                                {this.state.currentUser.type === "user"
                                  ? this.state.currentUser.first_name
                                  : this.state.currentUser.company_name}
                              </b>
                              ,
                            </p>
                            {(this.state.currentUser.subscription && this.state.currentUser.subscription.status === "charged_back") && <p className="para-charity-lfss">
                              Je betaling is helaas niet gelukt en je donatie is gestopt.
                            </p>}
                            {(this.state.showLoaderDefault && this.state.currentUser.payment_status !== "paid") && <p className="para-charity-lfss" style={{ color: "#C17F6B" }}>
                              Je aanmelding is helaas niet gelukt.
                            </p>}
                            <p className="para-charity-lfss">
                              Word Charity Member en krijg toegang tot
                              <br />
                              exclusieve aanbiedingen op onze website.
                            </p>
                            <p className="para-charity-lfss">
                              Als Charity Member ondersteun je kinderen
                              <br />
                              en scholen in Tanzania met maaltijden en
                              <br />
                              materialen. Van deze uitgifte ontvang je
                              <br />
                              een bedankvideo vanuit Tanzania.
                            </p>
                            <p className="para-charity-lfss">
                              Je maandelijkse bijdrage kun je op ieder
                              <br />
                              gewenst moment aanpassen of stopzetten.
                            </p>
                            <p className="para-charity-lfss">
                              Selecteer je bijdrage:
                            </p>
                            <div
                              className="radios-charity-lfss"
                              style={{ justifyContent: "center" }}
                            >
                              <span
                                className="tooltiptext tooltipinfo charitytooltiptext"
                                style={{ display: this.state.mustSelectPlanId ? "inline-block" : "none" }}
                              >
                                Selecteer
                              </span>
                              {this.state.subscription_data.length > 0 &&
                                [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                  if (!e.active) {
                                    if (e.plan_id !== this.state.default_plan_id) {
                                      return false;
                                    }
                                  }
                                  return (
                                    <div className="inner-rclfss">
                                      <input
                                        type="radio"
                                        id="radiocharity"
                                        name="charity"
                                        value={e.plan_id}
                                        checked={
                                          e.plan_id === this.state.current_plan_id
                                        }
                                        onChange={() =>
                                          this.setState({
                                            plan_id: e.plan_id,
                                            plan_amount: e.price,
                                            stripe_plan_key: e.stripe_plan_key,
                                            current_plan_id: e.plan_id,
                                            current_plan_amount: e.price,
                                            current_stripe_plan_key: e.stripe_plan_key,
                                            split_amount: e.split_amount,
                                          })
                                        }
                                      />
                                      <span>€ {Number(e.price)}</span>
                                    </div>
                                  );
                                })}
                            </div>
                            {!this.state.notSubscribed &&
                              this.state.currentUser.payment_status ===
                              "paid" && (
                                <button
                                  className="button-charity-lfss bcl-newdesign"
                                  style={{ marginRight: 5 }}
                                >
                                  Stoppen
                                </button>
                              )}
                            <button
                              className="button-charity-lfss bcl-newdesign"
                              style={{ marginRight: 5 }}
                              onClick={this.goToLanding.bind(this)}
                            >
                              Sluit
                            </button>
                            <button
                              className="button-charity-lfss bcl-newdesign"
                              onClick={this.saveSubscription.bind(this)}
                              style={{
                                backgroundColor:
                                  this.state.savingSubscription && "black",
                                color: this.state.savingSubscription && "white",
                              }}
                            >
                              {this.state.savingSubscription ? (
                                <font>
                                  <i class="fa fa-spinner fa-spin"></i> Opslaan
                                </font>
                              ) : (
                                <font>Opslaan</font>
                              )}
                            </button>
                            {/* <button className="button-charity-lfss bcl-newdesign" onClick={this.saveSubscription.bind(this)} style={{ backgroundColor: this.state.startSubLoader && "black", color: this.state.startSubLoader && "white" }}>{!this.state.startSubLoader ? <font>OPSLAAN </font> : <font><i class="fa fa-spinner fa-spin"></i> OPSLAAN</font>}</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.showVideo && (
              <div className="video-popup-clientshow">
                <div className="container-vpcshow">
                  <div className="top-cvpcshow">
                    {/* <label>{this.state.selectedClientName}</label> */}
                    <CloseIcon onClick={() => {
                      this.setState({ showVideo: "", selectedClientName: "" })
                    }} />
                  </div>
                  <div className="video-cvpcshow">
                    {window.innerWidth > 1008 ? (<video src={this.state.showVideo} controls playsInline></video>) : (<video src={this.state.showVideo} controls playsInline autoPlay muted></video>)}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subscription_plans: state.globalReducers.subscription_plans,
    user: state.authReducers.user,
    charity_videos: state.globalReducers.charity_videos,
    global_values: state.globalReducers.global_values,
    guest_user_cart: state.cartReducer.guest_user_cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSubscriptionPlans: (subscription_plans) =>
      dispatch(updateSubscriptionPlans(subscription_plans)),
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Charity);
