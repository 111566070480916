import React, { Component } from 'react'
import { connect } from "react-redux";
import {
    updateBhodiFaqsGuests,
} from "../../Redux/actions/authActions";
import { ExpandMore } from '@material-ui/icons';
import axios from "axios";
import swal from "sweetalert";

export class GuestFaqComponent extends Component {
    constructor() {
        super()
        this.state = {
            subject: "",
            subjectAlert: false,
            firstName: "",
            firstNameAlert: false,
            lastName: "",
            lastNameAlert: false,
            message: "",
            messageAlert: false,
            emailSent: false,
            sendingEmail: false,
            email: "",
            emailAlert: false,
        }
    }

    onSubmit() {
        const { firstName, lastName, subject, message, email } = this.state

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (firstName.trim() !== "") {
            this.setState({ firstNameAlert: false })
        } else {
            this.setState({ firstNameAlert: true })
        }
        if (lastName.trim() !== "") {
            this.setState({ lastNameAlert: false })
        } else {
            this.setState({ lastNameAlert: true })
        }
        if (message.trim() !== "") {
            this.setState({ messageAlert: false })
        } else {
            this.setState({ messageAlert: true })
        }
        if (subject !== "") {
            this.setState({ subjectAlert: false })
        } else {
            this.setState({ subjectAlert: true })
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailAlert: true });
        } else {
            this.setState({ emailAlert: false });
        }

        if (firstName && lastName && subject && message && email && regexEmail.test(email)) {
            this.setState({ sendingEmail: true })
            var dataForAPI = JSON.stringify({
                sendToEmail: "info@bhodi.com",
                subject,
                innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>Got a new inquiry</h2><p>First Name: ${firstName}</p><p>Last Name: ${lastName}</p><p>Email: ${email}</p><p>Message: ${message}</p></body></html>`,
            });

            var config = {
                method: "post",
                url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendEmailMailjet",
                headers: {
                    "Content-Type": "application/json",
                },
                data: dataForAPI,
            };

            axios(config)
                .then((response) => {
                    if (response.data.statusCode === 201) {
                        this.setState({ emailSent: true, sendingEmail: false, message: "", subject: "" })
                    } else {
                        swal({
                            title: "Oops!",
                            text: "Error in sending email",
                            icon: "error",
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <>
                {this.state.emailSent ? (<>
                    <p style={{ marginTop: 30 }}>
                        <strong style={{ color: "black" }}>Hartelijk voor je bericht!</strong>
                        <br />
                        <br />
                        We hebben je bericht ontvangen en<br />
                        doen we ons best om te reageren.
                        <br />
                        <br />
                        Wij werken zonder helpdesk.<br />
                        Hierdoor besparen we kosten en<br />
                        kunnen we daardoor meer besteden<br />
                        aan onze impact. Kijk alvast bij de<br />
                        meest gestelde vragen.
                    </p>
                    <button className='sluit-button-formcontact' onClick={() => {
                        this.setState({ emailSent: false })
                    }}>Sluit</button>
                </>) : (<form className='form-contact' onSubmit={(e) => {
                    e.preventDefault()
                    this.onSubmit()
                }}>
                    <div className="form-miur formfullwidthftcic">
                        <label>Neem contact op</label>
                        <div className='wrapper-select-new'>
                            <select
                                value={this.state.subject}
                                onChange={(e) => {
                                    this.setState({
                                        subject: e.target.value,
                                    });
                                }}
                            >
                                <option value="" disabled selected hidden>
                                    Onderwerp
                                </option>
                                <option value="Algemeen">Algemeen</option>
                                <option value="Bedrijven">Bedrijven</option>
                                <option value="Melding">Melding</option>
                                <option value="Feedback">Feedback</option>
                            </select>
                            <ExpandMore
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 5,
                                    bottom: 10,
                                    fontSize: 18,
                                    pointerEvents: "none",
                                    pointerEvents: "none",
                                }}
                                className='expand-more'
                            />
                        </div>
                        {this.state.subjectAlert && (
                            <label style={{ color: "red" }}>Verplicht veld</label>
                        )}
                    </div>
                    <div className="form-miur formfullwidthftcic">
                        <label>Email</label>
                        <input
                            value={this.state.email}
                            onChange={(e) => {
                                this.setState({ email: e.target.value })
                            }}
                        />
                        {this.state.emailAlert && (
                            <label style={{ color: "red" }}>Verplicht veld</label>
                        )}
                    </div>
                    <div className="form-miur formfullwidthftcic">
                        <label>First Name</label>
                        <input
                            value={this.state.firstName}
                            onChange={(e) => {
                                this.setState({ firstName: e.target.value })
                            }}
                        />
                        {this.state.firstNameAlert && (
                            <label style={{ color: "red" }}>Verplicht veld</label>
                        )}
                    </div>
                    <div className="form-miur formfullwidthftcic">
                        <label>Last Name</label>
                        <input
                            value={this.state.lastName}
                            onChange={(e) => {
                                this.setState({ lastName: e.target.value })
                            }}
                        />
                        {this.state.lastNameAlert && (
                            <label style={{ color: "red" }}>Verplicht veld</label>
                        )}
                    </div>
                    <div className="form-miur formfullwidthftcic">
                        <label>Message</label>
                        <textarea
                            value={this.state.message}
                            onChange={(e) => {
                                this.setState({ message: e.target.value })
                            }}
                        ></textarea>
                        {this.state.messageAlert && (
                            <label style={{ color: "red" }}>Verplicht veld</label>
                        )}
                    </div>
                    <button>{this.state.sendingEmail && (<i class="fa fa-spinner fa-spin"></i>)}{this.state.sendingEmail && " "}Submit</button>
                </form>)}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        bhodi_faqs_guests: state.globalReducers.bhodi_faqs_guests,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBhodiFaqsGuests: (bhodi_faqs_guests) => dispatch(updateBhodiFaqsGuests(bhodi_faqs_guests)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GuestFaqComponent);