import React, { Component } from 'react'
import Switch from "@material-ui/core/Switch";
import {
    updateGlobalStuff,
} from "../Redux/actions/authActions";
import firebase from "../Config/Firebase"
import { connect } from 'react-redux';
import axios from 'axios';
import swal from 'sweetalert';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

export class MySubcribedExclusives extends Component {

    constructor() {
        super()
        this.state = {
            currentUserHere: "",
            currentSubscriptions: [],
            pendingApprovals: [],
            onPendingApproval: true,
            takeConfirmationForUpdate: false,
            isUpdatingSubscriptionOf: "",
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")
        let filtered = this.props.user.filter((g) => {
            return g.user_id === userid
        })
        if (filtered.length > 0) {
            if (filtered[0].subscribedForExclusives) {
                let arr = []
                let objected = Object.entries(filtered[0].subscribedForExclusives)
                objected.map((e) => {
                    if (e[1].payment_status === "paid" && e[1].subscription && (e[1].subscription.status === "active" || e[1].subscription.status === "paused")) {
                        arr.push(e[1])
                    }
                })
                let pendingApprovals = []
                arr.map((t) => {
                    let filtered = this.props.user.filter((g) => {
                        return g.user_id === t.userId
                    })
                    if (filtered.length > 0) {
                        if (filtered[0].productsInfo && filtered[0].productsInfo.subscriptionAmountExclusive) {
                            if (filtered[0].productsInfo.subscriptionAmountExclusive !== "show_all") {
                                if (filtered[0].productsInfo.subscriptionAmountExclusive !== t.amount) {
                                    pendingApprovals.push(t)
                                }
                            }
                        }
                    }
                })
                this.setState({ currentSubscriptions: arr, pendingApprovals })
            } else {
                this.setState({ currentSubscriptions: [] })
            }
            this.setState({ currentUserHere: filtered[0] })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            let userid = localStorage.getItem("userid")
            let filtered = this.props.user.filter((g) => {
                return g.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].subscribedForExclusives) {
                    let arr = []
                    let objected = Object.entries(filtered[0].subscribedForExclusives)
                    objected.map((e) => {
                        if (e[1].payment_status === "paid" && e[1].subscription && (e[1].subscription.status === "active" || e[1].subscription.status === "paused")) {
                            arr.push(e[1])
                        }
                    })
                    let pendingApprovals = []
                    arr.map((t) => {
                        let filtered = this.props.user.filter((g) => {
                            return g.user_id === t.userId
                        })
                        if (filtered.length > 0) {
                            if (filtered[0].productsInfo && filtered[0].productsInfo.subscriptionAmountExclusive) {
                                if (filtered[0].productsInfo.subscriptionAmountExclusive !== "show_all") {
                                    if (filtered[0].productsInfo.subscriptionAmountExclusive !== t.amount) {
                                        pendingApprovals.push(t)
                                    }
                                }
                            }
                        }
                    })
                    this.setState({ currentSubscriptions: arr, pendingApprovals })
                } else {
                    this.setState({ currentSubscriptions: [] })
                }
                this.setState({ currentUserHere: filtered[0] })
            }
        }
    }

    getMemberImage(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e;
        });
        if (filtered.length > 0) {
            if (filtered[0].profile_image) {
                return filtered[0].profile_image;
            } else {
                return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
        }
    }

    getMemberName(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e;
        });
        if (filtered.length > 0) {
            if (filtered[0].type === "user") {
                return filtered[0].first_name + " " + filtered[0].last_name;
            } else {
                return filtered[0].company_name;
            }
        }
    }

    getMemberCurrentAmount(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e;
        });
        if (filtered.length > 0) {
            if (filtered[0].productsInfo && filtered[0].productsInfo.subscriptionAmountExclusive) {
                return Number(filtered[0].productsInfo.subscriptionAmountExclusive);
            }
        }
    }

    cancelSubscription(e) {
        axios.post(
            "https://us-central1-bhodi-7c327.cloudfunctions.net/cancelStripeCustomerSubscription",
            {
                subscriptionId: e.subscription.id,
            }
        ).then(() => {
            swal({
                title: "Cool",
                text: "Subscription cancelled successfully.",
                icon: "success",
            }).then(() => {
                let clickedUser = this.props.user.filter((t) => {
                    return t.user_id === e.userId
                })
                let userid = localStorage.getItem("userid")
                if (clickedUser.length > 0) {
                    if (clickedUser[0].subscribersForExclusives) {
                        let objected = Object.entries(clickedUser[0].subscribersForExclusives)
                        objected.map((g) => {
                            if (g[1].userId === userid) {
                                firebase.database().ref(`users/${e.userId}/subscribersForExclusives/${g[1].pushKey}`).remove()
                                firebase.database().ref(`users/${userid}/subscribedForExclusives/${e.pushKey}`).remove()
                            }
                        })
                    }
                }
            })
        })
    }

    updateSubscription(e, newAmount) {
        let getPlanId = this.props.subscription_plans.filter((t) => {
            return t.price === newAmount
        })
        if (getPlanId.length > 0) {
            axios.post(
                "https://us-central1-bhodi-7c327.cloudfunctions.net/updateStripeCustomerSubscription",
                {
                    subscriptionItemId: e.subscription.items,
                    priceId: getPlanId[0].stripe_plan_key,
                }
            )
                .then((response) => {
                    if (!response.data.error) {
                        swal({
                            title: "Cool",
                            text: "Subscription activated successfully.",
                            icon: "success",
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <div className='subscribed-exclusives-container'>
                {this.state.pendingApprovals.length > 0 && (<div className='approvals-label'>
                    {this.state.onPendingApproval ? (<label onClick={() => {
                        this.setState({ onPendingApproval: false })
                    }}>Sluit</label>) : (<label onClick={() => {
                        this.setState({ onPendingApproval: true })
                    }}>Approvals ({this.state.pendingApprovals.length})</label>)}
                </div>)}
                {(this.state.onPendingApproval && this.state.pendingApprovals.length > 0) ? this.state.pendingApprovals.map((e) => {
                    return (<div className='container-subexcont cubex-approvals'>
                        <div className='flex-cubexapprovals'>
                            <img src={this.getMemberImage(e.userId)} />
                            <div className='content-csubex'>
                                <p>{this.getMemberName(e.userId)} have switched his exclusive subscription price to {this.getMemberCurrentAmount(e.userId)} EUR per maand</p>
                            </div>
                            <div className='buttons-approval'>
                                <button onClick={() => {
                                    this.cancelSubscription(e)
                                }}>Cancel</button>
                                <button>Approve</button>
                            </div>
                        </div>
                    </div>)
                }) : this.state.currentSubscriptions.length > 0 ? this.state.currentSubscriptions.map((e) => {
                    return (<div className='container-subexcont'>
                        <img src={this.getMemberImage(e.userId)} />
                        <div className='content-csubex'>
                            <p>{this.getMemberName(e.userId)}</p>
                            <p>{Number(e.amount)} per maand</p>
                        </div>
                        <Switch
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            defaultChecked={true}
                            checked={(e.subscription && e.subscription.status === "active") ? true : false}
                            onClick={() => {
                                if (e.subscription && e.subscription.status === "active") {
                                    this.cancelSubscription(e)
                                } else {
                                    let filtered = this.state.pendingApprovals.filter((t) => {
                                        return t.pushKey === e.pushKey
                                    })
                                    if (filtered.length > 0) {
                                        this.setState({ takeConfirmationForUpdate: true, isUpdatingSubscriptionOf: e })
                                    }
                                }
                            }}
                        />
                    </div>)
                }) : <p className='noactivesubscriptionstext'>Geen actieve abonnementen</p>}

                <Dialog
                    open={this.state.takeConfirmationForUpdate}
                    onClose={() => this.setState({ takeConfirmationForUpdate: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="edit-dialog-cdd smallertitleedcdd"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p>{this.getMemberName(this.state.isUpdatingSubscriptionOf.userId)} have switched his exclusive subscription price to {this.getMemberCurrentAmount(this.state.isUpdatingSubscriptionOf.userId)} EUR per maand</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.setState({ takeConfirmationForUpdate: false })}
                            color="primary"
                            className="close-modal-btn-edcd"
                        >
                            <CloseIcon />
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({
                                    takeConfirmationForUpdate: false,
                                });
                                let filtered = this.props.user.filter((g) => {
                                    return g.user_id === this.state.isUpdatingSubscriptionOf.userId;
                                });
                                if (filtered.length > 0) {
                                    if (filtered[0].productsInfo && filtered[0].productsInfo.subscriptionAmountExclusive) {
                                        this.updateSubscription(this.state.isUpdatingSubscriptionOf, filtered[0].productsInfo.subscriptionAmountExclusive);
                                    }
                                }
                            }}
                            color="primary"
                        >
                            UPDATE
                        </Button>
                        {/* <Button onClick={() => this.setState({ beforeCloseMenuConfirm: false })} color="primary">SAVE</Button> */}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        bhodi_products: state.productsReducers.bhodi_products,
        subscription_plans: state.globalReducers.subscription_plans,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MySubcribedExclusives);