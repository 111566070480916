let invalidChars = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "/",
    ".",
    "'",
    ";",
    "[",
    "]",
    "=",
    "`",
    "",
];

let pricesId = {
    '3.95': 'price_1OV8hhGbWn2wWpJRtmW74esA',
    '4.50': 'price_1OV8ihGbWn2wWpJR8fe1oWCw',
    '4.95': 'price_1OUncVGbWn2wWpJR83UrhNus',
    '5.00': 'price_1OUncVGbWn2wWpJR83UrhNus',
    '5.50': 'price_1OUncVGbWn2wWpJR83UrhNus',
    '6.00': 'price_1OUncVGbWn2wWpJR83UrhNus',
    '6.50': 'price_1OUncVGbWn2wWpJR83UrhNus',
    '7.50': 'price_1OUncVGbWn2wWpJR83UrhNus',
    '8.50': 'price_1OUncVGbWn2wWpJR83UrhNus',
    '12.50': 'price_1OUncVGbWn2wWpJR83UrhNus'
}

export {
    invalidChars,
    pricesId,
}