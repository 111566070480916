import React, { Component } from 'react'
import Switch from "@material-ui/core/Switch";
import AddEditProduct from './AddEditProduct';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from 'react-redux';
import AddIcon from "@material-ui/icons/Add";
import {
    updateGlobalStuff,
    updateGuestUserCart,
    updateStockImages,
    updateGuestUserCartTime,
    updateGuestUserCartExtendOpportunity,
    updateBhodiProducts,
} from "../../Redux/actions/authActions";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase"
import "./styles.css"
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ExclusiveProductsEditor from '../../Components/ExclusiveProductsEditor';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BiCalendar, BiChevronRight, BiDownload } from 'react-icons/bi';
import PublishIcon from "@material-ui/icons/Publish";
import * as XLSX from "xlsx";
import "./rightmenustyles.css"
import { BsCheck2 } from 'react-icons/bs';
import swal from "sweetalert";
import { AiFillStar, AiOutlineArrowUp, AiOutlineInfoCircle, AiOutlineStar } from 'react-icons/ai';
import { MdAdd, MdClose, MdOutlineWorkspacePremium, MdTimer } from 'react-icons/md';
import { FiChevronRight } from 'react-icons/fi';
import { ArtTrack, ChevronLeft } from '@material-ui/icons';
import GuestProductEditor from '../../Components/GuestProductEditor';
import moment from 'moment';
import { GiConsoleController } from 'react-icons/gi';
import SearchIcon from "@material-ui/icons/Search";
import { FaLink } from 'react-icons/fa6';
import { HiOutlineDuplicate } from "react-icons/hi"
import { pricesId } from '../../Config/Constants';
var convert = require("xml-js")

let dbRef = firebase.database().ref("bhodi_products")

const axios = require("axios");

export class AdminProducts extends Component {

    constructor() {
        super()
        this.state = {
            allProducts: [],
            categoryData: {},
            allSectors: [],
            sectors: [],
            completeCategories: [],
            completeSizes: [],
            onCurrentPage: 1,
            editScreenOpen: true,
            allProductsDuplicated: [],
            currentProductToEdit: "",
            productsAsExclusive: false,
            productsAsCarousel: true,
            currentUserHere: "",
            productsTimer: "",
            currentProductCarousel: 0,
            dropdownKeys: [],
            csvSelectedName: "",
            titleImportField: "",
            descImportField: "",
            priceImportField: "",
            skuImportField: "",
            sizesImportField: "",
            sizesSkuImportField: "",
            categoriesImportField: "",
            genderImportField: "",
            mainSector: "",
            allSectors: [],
            categoriesToMap: [],
            sizesToMap: [],
            gendersToMap: [],
            all_categories: [],
            all_sizes: [],
            completeCSVArray: [],
            dropFileImages: [],
            productImages: [],
            keysAttribute: [],
            globalDesc: "",
            deliveryNote: "",
            freeItemText: "",
            specialGrouping: "",
            csvSelectedNameStock: "",
            dropdownKeysStock: [],
            maxProductsToShow: 4,
            completeCSVArrayStock: [],
            skuImportFieldStock: "",
            stockImportFieldStock: "",
            priceImportFieldStock: "",
            alreadyCategoriesData: [],
            alreadySizesData: [],
            alreadyGendersData: [],
            defaultLoad: 20,
            showMoveToTop: false,
            xmlUrl: "",
            xmlUrlShifted: "https://",
            xmlUrlStock: "",
            xmlUrlShiftedStock: "https://",
            savingProductsImport: false,
            imageImportType: "manual",
            productImportType: "manual",
            stockImportType: "manual",
            dropdownKeysImages: [],
            completeCSVArrayImages: [],
            filteredProducts: [],
            defaultLoadFiltered: 20,
            globalGender: [],
            itemsToShow: 1,
            itemsMaxProductCart: "unlimited",
            intervalTimer: "",
            allTimes: [
                { label: "01:00" },
                { label: "01:15" },
                { label: "01:30" },
                { label: "01:45" },
                { label: "02:00" },
                { label: "02:15" },
                { label: "02:30" },
                { label: "02:45" },
                { label: "03:00" },
                { label: "03:15" },
                { label: "03:30" },
                { label: "03:45" },
                { label: "04:00" },
                { label: "04:15" },
                { label: "04:30" },
                { label: "04:45" },
                { label: "05:00" },
                { label: "05:15" },
                { label: "05:30" },
                { label: "05:45" },
                { label: "06:00" },
                { label: "06:15" },
                { label: "06:30" },
                { label: "06:45" },
                { label: "07:00" },
                { label: "07:15" },
                { label: "07:30" },
                { label: "07:45" },
                { label: "08:00" },
                { label: "08:15" },
                { label: "08:30" },
                { label: "08:45" },
                { label: "09:00" },
                { label: "09:15" },
                { label: "09:30" },
                { label: "09:45" },
                { label: "10:00" },
                { label: "10:15" },
                { label: "10:30" },
                { label: "10:45" },
                { label: "11:00" },
                { label: "11:15" },
                { label: "11:30" },
                { label: "11:45" },
                { label: "12:00" },
                { label: "12:15" },
                { label: "12:30" },
                { label: "12:45" },
                { label: "13:00" },
                { label: "13:15" },
                { label: "13:30" },
                { label: "13:45" },
                { label: "14:00" },
                { label: "14:15" },
                { label: "14:30" },
                { label: "14:45" },
                { label: "15:00" },
                { label: "15:15" },
                { label: "15:30" },
                { label: "15:45" },
                { label: "16:00" },
                { label: "16:15" },
                { label: "16:30" },
                { label: "16:45" },
                { label: "17:00" },
                { label: "17:15" },
                { label: "17:30" },
                { label: "17:45" },
                { label: "18:00" },
                { label: "18:15" },
                { label: "18:30" },
                { label: "18:45" },
                { label: "19:00" },
                { label: "19:15" },
                { label: "19:30" },
                { label: "19:45" },
                { label: "20:00" },
                { label: "20:15" },
                { label: "20:30" },
                { label: "20:45" },
                { label: "21:00" },
                { label: "21:15" },
                { label: "21:30" },
                { label: "21:45" },
                { label: "22:00" },
                { label: "22:15" },
                { label: "22:30" },
                { label: "22:45" },
                { label: "23:00" },
                { label: "23:15" },
                { label: "23:30" },
                { label: "23:45" },
                { label: "24:00" },
            ],
            startDateAnnouncement: "",
            startTimeAnnouncement: "",

            announcementTitle: "",
            announcementDesc: "",
            announcementMediaUrl: "",
            announcementMediaFile: "",
            announcementMediaType: "",
            announcementEnabled: false,
            notRequireImage: false,
            currentAnnouncementPresent: "",

            intervalSmallBannerImage: "",
            intervalBigBannerImage: "",

            shippingCost: "free",
            specialProductsCount: 5,
            shipmentPriceId: "",

            subscriptionAmountProducts: "",
            subscriptionAmountProductsSplit: "",

            allIndexes: [],
            allProductsToCheckSwap: [],

            shopOnline: true,

            allCompaniesForProducts: [],
            allCompaniesForProductsDuplicate: [],
            allCompaniesForProductsFilters: [],

            allCompaniesForProductsLandingPage: [],
            allCompaniesForProductsDuplicateLandingPage: [],
            allCompaniesForProductsFiltersLandingPage: [],

            currentCompanyIdForImport: "",

            orderedProduct: "",
            allOrderedProducts: [],
            productOrderedBy: "",

            pricesPriceId: pricesId,
            showAnnouncementWithoutTiming: false,

            company_on_landing_page: "",
        }
    }

    async componentDidMount() {
        this.checkForShowingIndexes()

        let userid = this.props.currentMemberData.user_id;

        if (process.env.REACT_APP_ADMIN_ID === userid) {
            this.setState({ isAdminSettings: true })
        }

        if (this.props.global_values) {
            if (this.props.global_values.maxProductsToShow) {
                this.setState({ itemsToShow: this.props.global_values.maxProductsToShow })
            }
            if (this.props.global_values.shippingCostProducts) {
                this.setState({ shippingCost: this.props.global_values.shippingCostProducts })
            }
            if (this.props.global_values.products_count_new) {
                this.setState({ specialProductsCount: this.props.global_values.products_count_new })
            }
            if (this.props.global_values.productsTimer) {
                this.setState({ productsTimer: this.props.global_values.productsTimer })
            }
            if (this.props.global_values.company_on_landing_page) {
                this.setState({ company_on_landing_page: this.props.global_values.company_on_landing_page })
            }
        }


        // let todayDate = new Date().getDate()
        // if (todayDate === 4) {
        //     firebase.database().ref("limited_cart_companies").once("value", (data) => {
        //         let a = data.val()
        //         let arr = []
        //         if (a) {
        //             let objected = Object.entries(a)
        //             objected.map((t) => {
        //                 arr.push(t[0])
        //             })
        //             arr.map((t) => {
        //                 let promiseGetProducts = new Promise((res, rej) => {
        //                     firebase.database().ref("bhodi_products").orderByChild("created_by").equalTo(t).once("value", (dataInner) => {
        //                         let aInner = dataInner.val()
        //                         let arrProductsVerzilvered = []
        //                         if (aInner) {
        //                             Object.entries(aInner).map((b) => {
        //                                 if (b[1].verzilvered_by) {
        //                                     arrProductsVerzilvered.push(b[1])
        //                                 }
        //                             })
        //                         }
        //                         res(arrProductsVerzilvered)
        //                     })
        //                 })
        //                 promiseGetProducts.then((arr) => {
        //                     let removedProducts = 0
        //                     if (arr.length > 0) {
        //                         arr.map((t) => {
        //                             firebase.database().ref("bhodi_products/" + t.pushKey + "/verzilvered_by").remove().then(() => {
        //                                 removedProducts = removedProducts + 1
        //                                 if (removedProducts === arr.length) {
        //                                     console.log("see today limited")
        //                                 }
        //                             })
        //                         })
        //                     }
        //                 })
        //             })
        //         }
        //     })
        // }

        if (window.innerWidth < 1008) {
            document.querySelector(".navbarfullwidthMobile").classList.add("navbartodowhitedefault")
            document.getElementById("navbartohidemainly").style.overflowX = "hidden"
        }

        // let newUrl = new URL(window.location.href);
        // let trackingId = newUrl.searchParams.get("merchantId");
        // if (trackingId) {
        //     var config = {
        //         method: "get",
        //         url: "https://us-central1-bhodi-7c327.cloudfunctions.net/getAuthDetails",
        //         headers: {},
        //     };

        //     axios(config)
        //         .then((response) => {
        //             if (response.status === 200) {
        //                 let accessToken = response.data.access_token;

        //                 var configAnother = {
        //                     method: "get",
        //                     url: `https://us-central1-bhodi-7c327.cloudfunctions.net/getMerchantId?trackingId=${trackingId}`,
        //                     headers: {
        //                         authToken: accessToken,
        //                         "Content-Type": "application/json",
        //                     },
        //                 };

        //                 axios(configAnother)
        //                     .then((responseNew) => {
        //                         let obj = {
        //                             merchant_id: responseNew.data.merchant_id,
        //                             tracking_id: responseNew.data.tracking_id,
        //                         };
        //                         firebase
        //                             .database()
        //                             .ref("users/" + userid + "/paypalInfo")
        //                             .set(obj)
        //                             .then(() => {
        //                                 swal({
        //                                     title: "Cool.",
        //                                     text: "Paypal connected successfully",
        //                                     icon: "success",
        //                                 }).then(() => {
        //                                     setTimeout(() => {
        //                                         window.location.href = `/company/${this.props.memberId}/edit-products`;
        //                                     }, 1000);
        //                                 })
        //                             });
        //                     })
        //                     .catch(function (error) {
        //                         console.log(error);
        //                     });
        //             }
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // }


        let newDate = new Date();
        let monthToday = newDate.getMonth() + 1;
        let dateTodayToMake = newDate.getDate();
        if (monthToday < 10) {
            monthToday = `0${monthToday}`;
        }
        if (dateTodayToMake < 10) {
            dateTodayToMake = `0${dateTodayToMake}`;
        }
        let makeToday = `${newDate.getFullYear()}-${monthToday}-${dateTodayToMake}`;

        this.setState({
            todayDateToBeMax: makeToday,
        });

        window.onscroll = () => {
            if (this.state.filteredProducts.length > 0) {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 300) && this.state.filteredProducts.length > this.state.defaultLoadFiltered && !this.state.showLoader) {
                    this.setState({ showLoader: true, showMoveToTop: true })
                    setTimeout(() => {
                        this.setState({ defaultLoadFiltered: this.state.defaultLoadFiltered + 20, showLoader: false })
                    }, 2000);
                }
            } else {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 300) && this.state.allProductsDuplicated.length > this.state.defaultLoad && !this.state.showLoader) {
                    this.setState({ showLoader: true, showMoveToTop: true })
                    setTimeout(() => {
                        this.setState({ defaultLoad: this.state.defaultLoad + 20, showLoader: false })
                    }, 2000);
                }
            }
        }

        document.getElementById("root").style.position = "static"
        document.getElementById("root").style.overflow = "auto"


        // if (window.location.search) {
        //     let urlMain = new URLSearchParams(window.location.search)
        //     if (urlMain.get("code")) {
        //         let obj = {
        //             code: urlMain.get("code"),
        //         }
        //         if (urlMain.get("state")) {
        //             obj.state = urlMain.get("state")
        //         }
        //         axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/generateMollieAccessToken", { code: obj.code })
        //             .then((response) => {
        //                 if (response.data.statusCode === 201) {
        //                     obj.auth_token = response.data.access_token

        //                     axios({
        //                         url: "https://us-central1-bhodi-7c327.cloudfunctions.net/getOrganizationDetails",
        //                         method: "get",
        //                         headers: {
        //                             authToken: response.data.access_token,
        //                         }
        //                     }).then((resp) => {
        //                         if (resp.data.statusCode === 201) {
        //                             obj.org_id = resp.data.id

        //                             firebase.database().ref(`users/${userid}/mollie_credentials`).set(obj).then(() => {
        //                                 swal({
        //                                     title: "Cool.",
        //                                     text: "Mollie connected successfully",
        //                                     icon: "success",
        //                                 })
        //                             })
        //                         }
        //                     }).then((err) => {
        //                         swal({
        //                             title: "Sorry.",
        //                             text: "Error occured in connecting mollie",
        //                             icon: "error",
        //                         })
        //                         console.log(err, '/err')
        //                     })
        //                 }
        //             }).catch((err) => {
        //                 swal({
        //                     title: "Sorry.",
        //                     text: "Error occured in connecting mollie",
        //                     icon: "error",
        //                 })
        //                 console.log(err, '/err')
        //             })
        //     }
        // }

        // firebase.database().ref("product_images_to_upload").remove()

        firebase.database().ref("mapped_categories/" + userid).on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ alreadyCategoriesData: a })
            }
        })

        firebase.database().ref("mapped_sizes/" + userid).on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ alreadySizesData: a })
            }
        })

        firebase.database().ref("mapped_genders/" + userid).on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ alreadyGendersData: a })
            }
        })

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((j) => {
                    return j.user_id === userid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    if (a.productsAsExclusive) {
                        this.setState({ productsAsExclusive: true })
                    }
                    if (a.shop_offline) {
                        this.setState({ shopOnline: false })
                    } else {
                        this.setState({ shopOnline: true })
                    }
                    if (a.productsInfo) {
                        if (process.env.REACT_APP_ADMIN_ID === userid) {
                            this.setState({
                                maxProductsToShow: a.productsInfo.maxProductsToShow ? a.productsInfo.maxProductsToShow : 4,
                                intervalTimer: a.productsInfo.intervalTimer ? a.productsInfo.intervalTimer : "",
                            })
                        } else {
                            this.setState({
                                maxProductsToShow: a.productsInfo.maxProductsToShow ? a.productsInfo.maxProductsToShow : 4,
                            })
                        }
                        if (a.productsInfo.globalDesc) {
                            this.setState({
                                globalDesc: a.productsInfo.globalDesc,
                            })
                        }
                        if (a.productsInfo.deliveryNote) {
                            this.setState({
                                deliveryNote: a.productsInfo.deliveryNote,
                            })
                        }
                        if (a.productsInfo.freeItemText) {
                            this.setState({
                                freeItemText: a.productsInfo.freeItemText,
                            })
                        }
                        if (a.productsInfo.globalGender) {
                            this.setState({
                                globalGender: a.productsInfo.globalGender,
                            })
                        }
                        if (a.productsInfo.subscriptionAmountExclusive) {
                            this.setState({ subscriptionAmountProducts: a.productsInfo.subscriptionAmountExclusive })
                        }
                        if (a.productsInfo.subscriptionAmountExclusiveSplitted) {
                            this.setState({ subscriptionAmountProductsSplit: a.productsInfo.subscriptionAmountExclusive })
                        }
                    }
                    // if (a.productsIntervalTimer) {
                    //     this.setState({ intervalTimer: a.productsIntervalTimer })
                    // }
                    if (a.productsAsCarousel) {
                        this.setState({ productsAsCarousel: true })
                    }
                    if (a.interval_small_banner_image) {
                        this.setState({ intervalSmallBannerImage: a.interval_small_banner_image })
                    }
                    if (a.interval_big_banner_image) {
                        this.setState({ intervalBigBannerImage: a.interval_big_banner_image })
                    }
                    if (a.announcement) {
                        this.setState({
                            announcementTitle: a.announcement.title,
                            announcementDesc: a.announcement.desc,
                            announcementBannerDate: a.announcement.announcementBannerDate,
                            announcementBannerTime: a.announcement.announcementBannerTime,
                            notRequireImage: true,
                            currentAnnouncementPresent: a.announcement,
                            announcementMediaType: a.announcement.bannerType,
                            announcementMediaUrl: a.announcement.bannerMedia,
                            showAnnouncementWithoutTiming: a.announcement.showAnnouncementWithoutTiming || false,
                            // announcementEnabled: a.announcement.announcementEnabled,
                        })
                        if (a.announcement.active) {
                            this.setState({
                                announcementEnabled: true,
                            })
                        }
                    }


                    this.setState({ currentUserHere: a, isGuestUser: false, itemsMaxProductCart: a.itemsMaxProductCart || "unlimited" });
                }
            }
        }

        firebase
            .database()
            .ref("users/" + userid)
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    if (a.productsAsExclusive) {
                        this.setState({ productsAsExclusive: true })
                    }
                    if (a.shop_offline) {
                        this.setState({ shopOnline: false })
                    } else {
                        this.setState({ shopOnline: true })
                    }
                    if (a.productsInfo) {
                        if (process.env.REACT_APP_ADMIN_ID === userid) {
                            this.setState({
                                maxProductsToShow: a.productsInfo.maxProductsToShow ? a.productsInfo.maxProductsToShow : 4,
                                intervalTimer: a.productsInfo.intervalTimer ? a.productsInfo.intervalTimer : "",
                            })
                        } else {
                            this.setState({
                                maxProductsToShow: a.productsInfo.maxProductsToShow ? a.productsInfo.maxProductsToShow : 4,
                            })
                        }
                        if (a.productsInfo.globalDesc) {
                            this.setState({
                                globalDesc: a.productsInfo.globalDesc,
                            })
                        }
                        if (a.productsInfo.deliveryNote) {
                            this.setState({
                                deliveryNote: a.productsInfo.deliveryNote,
                            })
                        }
                        if (a.productsInfo.freeItemText) {
                            this.setState({
                                freeItemText: a.productsInfo.freeItemText,
                            })
                        }
                        if (a.productsInfo.globalGender) {
                            this.setState({
                                globalGender: a.productsInfo.globalGender,
                            })
                        }
                        if (a.productsInfo.subscriptionAmountExclusive) {
                            this.setState({ subscriptionAmountProducts: a.productsInfo.subscriptionAmountExclusive })
                        }
                        if (a.productsInfo.subscriptionAmountExclusiveSplitted) {
                            this.setState({ subscriptionAmountProductsSplit: a.productsInfo.subscriptionAmountExclusiveSplitted })
                        }
                    }
                    // if (a.productsIntervalTimer) {
                    //     this.setState({ intervalTimer: a.productsIntervalTimer })
                    // }
                    if (a.productsAsCarousel) {
                        this.setState({ productsAsCarousel: true })
                    }
                    if (a.interval_small_banner_image) {
                        this.setState({ intervalSmallBannerImage: a.interval_small_banner_image })
                    }
                    if (a.interval_big_banner_image) {
                        this.setState({ intervalBigBannerImage: a.interval_big_banner_image })
                    }
                    if (a.announcement) {
                        this.setState({
                            announcementTitle: a.announcement.title,
                            announcementDesc: a.announcement.desc,
                            announcementBannerDate: a.announcement.announcementBannerDate,
                            announcementBannerTime: a.announcement.announcementBannerTime,
                            notRequireImage: true,
                            currentAnnouncementPresent: a.announcement,
                            announcementMediaType: a.announcement.bannerType,
                            announcementMediaUrl: a.announcement.bannerMedia,
                            showAnnouncementWithoutTiming: a.announcement.showAnnouncementWithoutTiming || false,
                        })
                        if (a.announcement.active) {
                            this.setState({
                                announcementEnabled: true,
                            })
                        }
                    }


                    this.setState({ currentUserHere: a, isGuestUser: false, itemsMaxProductCart: a.itemsMaxProductCart || "unlimited" });
                }
            });


        if (this.props.bhodi_products) {
            let filtered = this.props.bhodi_products.filter((g) => {
                return g.created_by === userid
            })
            if (userid === process.env.REACT_APP_ADMIN_ID) {
                filtered = this.props.bhodi_products
            }
            let sortedProducts = [...filtered].sort((a, b) => {
                return b.timestamp * 100 - a.timestamp * 100;
            });
            if (filtered.length > 0) {

                // sortedProducts.map((e) => {
                //     firebase.database().ref(`bhodi_products/${e.pushKey}/desc`).set("")
                //     firebase.database().ref(`bhodi_products/${e.pushKey}/gender`).set([])
                // })
                const extractedObjects = sortedProducts.map(obj => (obj.verzilvered_by ? Object.values(obj.verzilvered_by) : []));
                const flattenedArray = extractedObjects.flat();

                this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts, allOrderedProducts: flattenedArray })
            } else {
                this.setState({ allProducts: [], allProductsDuplicated: [] })
            }

            let filteredAvailable = this.props.bhodi_products.filter((g) => {
                return g.created_by === this.props.currentMemberData.user_id && g.images
            })
            let sortedProductsAvailable = [...filteredAvailable].sort((a, b) => {
                return b.timestamp * 100 - a.timestamp * 100;
            });
            if (filteredAvailable.length > 0) {
                let availableStockProducts = []
                sortedProductsAvailable.map((product) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.inCartOf) {
                        if (currentProduct.sizesActive) {
                            let stocksAvailableSizes = []
                            currentProduct.variations_data.map((e) => {
                                let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                                if (filteredStockLength.length < Number(e.qty)) {
                                    stocksAvailableSizes.push(e)
                                }
                            })
                            if (stocksAvailableSizes.length === 0) {
                                console.log("no stock")
                            } else {
                                availableStockProducts.push(product)
                            }
                        } else {
                            let objected = Object.entries(product.inCartOf)
                            if (objected.length >= Number(currentProduct.qty)) {
                                console.log("no stock")
                            } else {
                                availableStockProducts.push(product)
                            }
                        }
                    } else {
                        availableStockProducts.push(product)
                    }
                })
                if (availableStockProducts.length > 0) {
                    let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
                    this.setState({ currentProductCarousel: currentInd })
                }
            }
        } else {
            this.setState({ allProducts: [], allProductsDuplicated: [] })
        }

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });
                if (arr.length > 0) {
                    if (this.state.selectedSector) {
                        this.setState({ sectors: arr });
                    } else {
                        this.setState({ sectors: arr, currentSector: arr[0] });
                    }
                    firebase
                        .database()
                        .ref("bhodi_categories_products")
                        .on("value", (data) => {
                            let a = data.val();
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let categories = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        categories.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        categories.push(obj);
                                    }
                                });
                                if (categories.length > 0) {
                                    this.setState({
                                        completeCategories: objected,
                                    });
                                }
                            } else {
                                this.setState({ completeCategories: [] });
                            }
                        });
                    firebase
                        .database()
                        .ref("bhodi_sizes_products")
                        .on("value", (data) => {
                            let a = data.val();
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let sizes = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        sizes.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        sizes.push(obj);
                                    }
                                });
                                if (sizes.length > 0) {
                                    this.setState({
                                        completeSizes: objected,
                                    });
                                }
                            } else {
                                this.setState({ completeSizes: [] });
                            }
                        });
                }
            }
        }

        // await fetch("https://www.zinzi.nl/media/catalog/product/C/H/7970_CH-A2_1.jpg").then((r) => {
        //     console.log(r, '/check')
        //     console.log(r.blob(), '/check')
        // })
    }


    checkForShowingIndexes() {
        let maxProductsToShow = this.state.maxProductsToShow
        if (this.props.currentMemberData && this.props.currentMemberData.productsInfo && this.props.currentMemberData.productsInfo.maxProductsToShow) {
            maxProductsToShow = this.props.currentMemberData.productsInfo.maxProductsToShow
            this.setState({ maxProductsToShow: this.props.currentMemberData.productsInfo.maxProductsToShow })
        }

        let indexToStartProducts = 0
        let indexToStartProductsWeekly = 0
        let indexToStartProductsDaily = 0

        let allDatesWeekly = []
        let currentWeekAt = ""
        let currentDayAt = ""
        let allDatesDaily = []

        if (this.props.currentMemberData) {
            this.setState({ currentMemberData: this.props.currentMemberData })
            if (this.props.currentMemberData.announcement && this.props.currentMemberData.announcement.active) {
                if (new Date(Date.parse(this.props.currentMemberData.announcement.end_date_iso)).getTime() > new Date().getTime()) {
                    this.setState({ showBannerAnnouncement: true, loading: false })
                }
            }
            firebase
                .database()
                .ref("users/" + this.props.currentMemberData.user_id)
                .on("value", (data) => {
                    let a = data.val();
                    if (a) {
                        if (a.productsInfo && a.productsInfo.maxProductsToShow && a.productsInfo.maxProductsToShow !== this.state.maxProductsToShow) {
                            maxProductsToShow = a.productsInfo.maxProductsToShow
                            this.setState({ maxProductsToShow: a.productsInfo.maxProductsToShow })
                        }

                        this.setState({ currentMemberData: a });

                        setTimeout(() => {
                            if (this.props.bhodi_products) {
                                let filtered = this.props.bhodi_products.filter((g) => {
                                    let isAvailable = true
                                    return g.created_by === this.props.currentMemberData.user_id && isAvailable
                                })

                                let isRepeating = false
                                let availableProducts = []
                                filtered.map((product, index) => {
                                    let currentProduct = Object.assign({}, product)
                                    if (product.active) {
                                        if (product.inCartOf || product.verzilvered_by) {
                                            if (currentProduct.sizesActive) {
                                                let stocksAvailableSizes = []
                                                currentProduct.variations_data.map((e) => {
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            } else {
                                                let objectedCart = []
                                                let objectedVerzilvered = []

                                                if (product.inCartOf) {
                                                    objectedCart = Object.entries(product.inCartOf)
                                                }
                                                if (product.verzilvered_by) {
                                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                                }
                                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            }
                                        } else {
                                            if (product.sizesActive) {
                                                let stocksAvailableSizes = []
                                                product.variations_data.map((e) => {
                                                    if (Number(e.qty) === 0) {
                                                        return false;
                                                    }
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            } else {
                                                if (Number(product.qty) === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            }
                                        }
                                    }
                                })

                                this.processProducts(filtered, maxProductsToShow, a.recurring, indexToStartProducts, "", isRepeating)
                            }
                        }, 2000);
                    }
                });
        }

        let userid = this.props.currentMemberData.user_id;
        this.setState({ currentUserId: userid })

        let lastCaught = ""
        dbRef.orderByChild("created_by").equalTo(this.props.currentMemberData.user_id).on("value", (data) => {
            let a = data.val()
            if (a) {
                let allProducts = []
                let objected = Object.entries(a)
                objected.map((e) => {
                    // if (e[1].images) {
                    allProducts.push(e[1])
                    // }
                })

                let bhodiProducts = this.props.bhodi_products
                if (bhodiProducts && bhodiProducts.length > 0) {
                    let arrToSet = []
                    bhodiProducts.map((e) => {
                        let alreadyAvailable = allProducts.filter((t) => {
                            return t.pushKey === e.pushKey
                        })
                        if (alreadyAvailable.length > 0) {
                            arrToSet.push(alreadyAvailable[0])
                        } else {
                            arrToSet.push(e)
                        }
                    })
                    allProducts.map((t) => {
                        let filtered = arrToSet.filter((g) => {
                            return g.pushKey === t.pushKey
                        })
                        if (filtered.length === 0) {
                            arrToSet.push(t)
                        }
                    })

                    this.props.updateBhodiProducts(arrToSet)
                }

                let isRepeating = false
                let availableProducts = []
                allProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            }
                        } else {
                            if (product.sizesActive) {
                                let stocksAvailableSizes = []
                                product.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            } else {
                                if (Number(product.qty) === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            }
                            // availableProducts.push(product)
                        }
                    }
                })

                this.processProducts(allProducts, maxProductsToShow, this.props.currentMemberData.recurring, indexToStartProducts, "", isRepeating)
            }
        })
    }


    processProducts(filtered, maxProductsToShow, recurring, indexToStartProducts, avoidAvailability, isRepeating) {
        let mustProcess = true

        let currentIndex = indexToStartProducts - 1
        let sortedProducts = [...filtered].sort((a, b) => {
            return a.sort_time * 100 - b.sort_time * 100;
        });

        if (filtered.length > 0) {
            let availableStockProducts = []
            let availableIndexes = []

            sortedProducts.map((product, index) => {
                let currentProduct = Object.assign({}, product)
                if (product.variations_data) {
                    let objected = Object.entries(product.variations_data)
                    let filtered = objected.filter((t) => {
                        return Number(t[1].qty) > 0
                    })
                    if (filtered.length === 0) {
                        return false;
                    }
                } else {
                    if (Number(product.qty) === 0) {
                        return false;
                    }
                }
                if (!product.images) {
                    return false;
                }
                if (product.active) {
                    if (product.inCartOf || product.verzilvered_by) {
                        if (currentProduct.sizesActive) {
                            let stocksAvailableSizes = []
                            currentProduct.variations_data.map((e) => {
                                let filteredStockLength = []
                                let filteredStockLengthVerzilvered = []
                                if (product.inCartOf) {
                                    filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                        return t[1].selectedSize === e.size
                                    })
                                }
                                if (product.verzilvered_by) {
                                    filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                        return t[1].selectedSize === e.size
                                    })
                                }
                                if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                    stocksAvailableSizes.push(e)
                                }
                            })
                            if (stocksAvailableSizes.length === 0) {
                                console.log("no stock")
                            } else {
                                availableStockProducts.push(product)
                                availableIndexes.push(index)
                            }
                        } else {
                            let objectedCart = []
                            let objectedVerzilvered = []

                            if (product.inCartOf) {
                                objectedCart = Object.entries(product.inCartOf)
                            }
                            if (product.verzilvered_by) {
                                objectedVerzilvered = Object.entries(product.verzilvered_by)
                            }
                            if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                console.log("no stock")
                            } else {
                                availableStockProducts.push(product)
                                availableIndexes.push(index)
                            }
                        }
                    } else {
                        availableStockProducts.push(product)
                        availableIndexes.push(index)
                    }
                }
            })

            if (availableStockProducts.length === 3) {
                this.setState({ keepShowing: true })
            } else {
                this.setState({ keepShowing: false })
            }

            if (availableStockProducts.length === 0) {
                this.setState({ noCarouselProducts: true })
            }
            let maxIndexesToShow = []
            let maxIndexesToShowNext = []

            maxIndexesToShow = [...availableIndexes].splice(0, maxProductsToShow).reverse()

            maxIndexesToShowNext = availableIndexes.splice(0, maxProductsToShow).reverse()

            let productsToRender = []
            let productsAllRecurring = []

            maxIndexesToShow.map((index, ind) => {
                let currentProduct = Object.assign(sortedProducts[index], {})
                productsToRender.push(currentProduct)
            })

            let productsToRenderNext = []
            maxIndexesToShowNext.map((index, ind) => {
                let currentProduct = Object.assign(sortedProducts[index], {})
                productsToRenderNext.push(currentProduct)
            })

            // let productsSwapped = productsToSwap.concat(productsToRender).concat(productsTooked)
            // let productsSwapped = this.state.productsSwapped.concat(productsToSwap).concat(productsToRender)

            this.setState({ allIndexes: maxIndexesToShow, productsToRender, allProductsToCheckSwap: sortedProducts })

        }
    }

    componentWillUnmount() {
        dbRef.orderByChild("created_by").equalTo(this.props.currentMemberData.user_id).off()
    }

    getCreatorImage(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                    return filtered[0].profile_image;
                } else {
                    return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
                }
            }
        }
    }

    getCreatorName(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "user") {
                    return filtered[0].first_name
                } else {
                    return filtered[0].company_name
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.global_values !== prevProps.global_values) {
            if (this.props.global_values.maxProductsToShow) {
                this.setState({ itemsToShow: this.props.global_values.maxProductsToShow })
            }
            if (this.props.global_values.shippingCostProducts) {
                this.setState({ shippingCost: this.props.global_values.shippingCostProducts })
            }
            if (this.props.global_values.products_count_new) {
                this.setState({ specialProductsCount: this.props.global_values.products_count_new })
            }
            if (this.props.global_values.productsTimer) {
                this.setState({ productsTimer: this.props.global_values.productsTimer })
            }
            if (this.props.global_values.company_on_landing_page) {
                this.setState({ company_on_landing_page: this.props.global_values.company_on_landing_page })
            }
        }

        if (prevProps.bhodi_products !== this.props.bhodi_products) {
            if (this.props.bhodi_products) {
                let userid = this.props.currentMemberData.user_id;

                let filtered = this.props.bhodi_products.filter((g) => {
                    return g.created_by === userid
                })
                if (userid === process.env.REACT_APP_ADMIN_ID) {
                    filtered = this.props.bhodi_products
                }
                let sortedProducts = [...filtered].sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (filtered.length > 0) {
                    if (this.state.filteredProducts.length > 0) {
                        let duplicatedFilter = this.state.filteredProducts.slice()
                        let newArr = []
                        duplicatedFilter.map((t, i) => {
                            let filteredInner = sortedProducts.filter((g) => {
                                return g.pushKey === t.pushKey
                            })
                            if (filteredInner.length > 0) {
                                newArr.push(filteredInner[0])
                            }
                        })
                        this.setState({ filteredProducts: newArr })
                    }

                    const extractedObjects = sortedProducts.map(obj => (obj.verzilvered_by ? Object.values(obj.verzilvered_by) : []));
                    const flattenedArray = extractedObjects.flat();

                    if (this.state.allOrderedProducts.length > 0) {
                        const newObjects = flattenedArray.filter(newObj => !this.state.allOrderedProducts.some(oldObj => oldObj.pushKey === newObj.pushKey));

                        if (newObjects.length > 0) {
                            let productOrdered = ""
                            this.props.bhodi_products.map((t) => {
                                if (t.verzilvered_by) {
                                    let filtered = Object.entries(t.verzilvered_by).filter((g) => {
                                        return g[1].pushKey === newObjects[0].pushKey
                                    })
                                    if (filtered.length > 0) {
                                        productOrdered = t
                                    }
                                }
                            })
                            this.setState({ orderedProduct: productOrdered, productOrderedBy: newObjects[0].user_id, newOrderComing: true })
                        }
                    }

                    this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts, allOrderedProducts: flattenedArray })
                } else {
                    this.setState({ allProducts: [], allProductsDuplicated: [] })
                }

                let filteredAvailable = this.props.bhodi_products.filter((g) => {
                    return g.created_by === this.props.currentMemberData.user_id && g.images
                })
                let sortedProductsAvailable = [...filteredAvailable].sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (filteredAvailable.length > 0) {
                    let availableStockProducts = []
                    sortedProductsAvailable.map((product) => {
                        let currentProduct = Object.assign({}, product)
                        if (product.inCartOf) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                        return t[1].selectedSize === e.size
                                    })
                                    if (filteredStockLength.length < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                }
                            } else {
                                let objected = Object.entries(product.inCartOf)
                                if (objected.length >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                }
                            }
                        } else {
                            availableStockProducts.push(product)
                        }
                    })
                    if (availableStockProducts.length > 0) {
                        let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
                        this.setState({ currentProductCarousel: currentInd })
                    }
                }
            } else {
                this.setState({ allProducts: [], allProductsDuplicated: [] })
            }
        }
    }

    saveGlobalSettings() {
        // firebase.database().ref(`users/${userid}/productsIntervalTimer`).set(this.state.intervalTimer)

        // firebase.database().ref(`bhodi_home/maxProductsToShow`).set(this.state.itemsToShow)

        firebase.database().ref(`bhodi_home/shippingCostProducts`).set(this.state.shippingCost)
        firebase.database().ref(`bhodi_home/products_count_new`).set(this.state.specialProductsCount)
        firebase.database().ref(`bhodi_home/shipmentPriceIdStripe`).set(this.state.shipmentPriceId)

        firebase.database().ref(`bhodi_home/productsTimer`).set(this.state.productsTimer)

        setTimeout(() => {
            document.querySelector("html").style.overflowY = "auto"
            this.setState({ globalSettingsPopup: false })
        }, 1000);
    }

    saveCurrentData() {
        let userid = this.props.currentMemberData.user_id
        let currentTime = new Date().getTime()
        // firebase.database().ref(`users/${userid}/productsIntervalTimer`).set(this.state.intervalTimer)

        firebase.database().ref(`users/${userid}/productsAsExclusive`).set(this.state.productsAsExclusive)
        firebase.database().ref(`users/${userid}/productsInfo/globalDesc`).set(this.state.globalDesc)
        firebase.database().ref(`users/${userid}/productsInfo/deliveryNote`).set(this.state.deliveryNote)
        firebase.database().ref(`users/${userid}/productsInfo/freeItemText`).set(this.state.freeItemText)
        firebase.database().ref(`users/${userid}/productsInfo/globalGender`).set(this.state.globalGender)

        firebase.database().ref(`users/${userid}/itemsMaxProductCart`).set(this.state.itemsMaxProductCart)
        firebase.database().ref(`users/${userid}/productsInfo/maxProductsToShow`).set(this.state.maxProductsToShow)

        if (this.state.isAdminSettings) {
            firebase.database().ref(`users/${userid}/productsInfo/intervalTimer`).set(this.state.intervalTimer)
        }

        if (this.state.subscriptionAmountProducts) {
            firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusive`).set(this.state.subscriptionAmountProducts)
            if (this.state.subscriptionAmountProductsSplit) {
                firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusiveSplitted`).set(this.state.subscriptionAmountProductsSplit)
            } else {
                firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusiveSplitted`).set("")
            }
        } else {
            firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusive`).set("")
            firebase.database().ref(`users/${userid}/productsInfo/subscriptionAmountExclusiveSplitted`).set("")
        }


        setTimeout(() => {
            document.querySelector("html").style.overflowY = "auto"
            this.setState({ settingsPopup: false })
        }, 1000);
    }

    processXmlFromURL() {
        this.setState({ processingXml: true })
        axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/fetchXml", { url: this.state.xmlUrl })
            .then((response) => {
                if (response.data.statusCode === 201) {
                    let result = convert.xml2json(response.data.fileData, { compact: true, spaces: 4 })
                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        let keysAttribute = []
                        filteredOfKeys.map((t) => {
                            let obj = {
                                attributeDefault: t,
                                editedAttribute: t,
                                attributeToShow: false,
                            }
                            keysAttribute.push(obj)
                        })
                        this.setState({
                            dropdownKeys: filteredOfKeys,
                            completeCSVArray: currentArray.items.item,
                            keysAttribute,
                            selectedXml: true,
                            processingXml: false
                        });
                    }
                } else {
                    this.setState({
                        processingXml: false,
                        errorInXml: true
                    })
                }
            }).catch(() => {
                this.setState({
                    processingXml: false,
                    errorInXml: true
                })
            })
    }

    fileUploadcsv(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].type.includes("/xml")) {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    let readXml = e.target.result

                    let result = convert.xml2json(readXml, { compact: true, spaces: 4 })

                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        let keysAttribute = []
                        filteredOfKeys.map((t) => {
                            let obj = {
                                attributeDefault: t,
                                editedAttribute: t,
                                attributeToShow: false,
                            }
                            keysAttribute.push(obj)
                        })

                        this.checkForDefaultSelected(filteredOfKeys)
                        let checkGenderPresent = filteredOfKeys.findIndex((e) => {
                            return e.toLowerCase() === "gender"
                        })
                        if (checkGenderPresent !== -1) {

                            let allGendersOfCsv = []
                            currentArray.items.item.map((g) => {
                                let currentText = g[filteredOfKeys[checkGenderPresent]]._text
                                if (currentText && currentText !== undefined) {
                                    let mapped = ""
                                    if (this.state.alreadyGendersData.length > 0) {
                                        let filteredInner = this.state.alreadyGendersData.filter((g) => {
                                            return g.gender === currentText
                                        })
                                        if (filteredInner.length > 0) {
                                            mapped = filteredInner[0].mapped
                                        }
                                    }
                                    let obj = {
                                        gender: currentText,
                                        mapped,
                                    }
                                    allGendersOfCsv.push(obj)
                                }
                            });
                            let filteredOfGenders = allGendersOfCsv.filter(
                                (v, i, a) => a.findIndex((t) => t.gender === v.gender) === i
                            );
                            this.setState({
                                gendersToMap: filteredOfGenders,
                            });

                            this.setState({ genderImportField: filteredOfKeys[checkGenderPresent] })
                        } else {
                            this.setState({ genderImportField: "" })
                        }
                        let checkCategoryPresent = filteredOfKeys.findIndex((e) => {
                            return e.toLowerCase() === "category"
                        })
                        if (checkCategoryPresent !== -1) {
                            let allCategoriesOfCsv = []
                            currentArray.items.item.map((g) => {
                                let currentText = g[filteredOfKeys[checkCategoryPresent]]._text
                                if (currentText && currentText !== undefined) {
                                    let mapped = ""
                                    if (this.state.alreadyCategoriesData.length > 0) {
                                        let filteredInner = this.state.alreadyCategoriesData.filter((g) => {
                                            return g.cat === currentText
                                        })
                                        if (filteredInner.length > 0) {
                                            mapped = filteredInner[0].mapped
                                        }
                                    }
                                    let obj = {
                                        cat: currentText,
                                        mapped,
                                    }
                                    allCategoriesOfCsv.push(obj)
                                }
                            });
                            let filteredOfCategories = allCategoriesOfCsv.filter(
                                (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                            );
                            this.setState({
                                categoriesToMap: filteredOfCategories,
                            });

                            this.setState({ categoriesImportField: filteredOfKeys[checkCategoryPresent] })
                        } else {
                            this.setState({ categoriesImportField: "" })
                        }
                        let checkSizePresent = filteredOfKeys.findIndex((e) => {
                            return e.toLowerCase() === "size"
                        })
                        if (checkSizePresent !== -1) {
                            let allSizesOfCsv = []
                            currentArray.items.item.map((g) => {
                                let currentText = g[filteredOfKeys[checkSizePresent]]._text
                                if (currentText && currentText !== undefined) {
                                    let mapped = ""
                                    if (this.state.alreadySizesData.length > 0) {
                                        let filteredInner = this.state.alreadySizesData.filter((g) => {
                                            return g.cat === currentText
                                        })
                                        if (filteredInner.length > 0) {
                                            mapped = filteredInner[0].mapped
                                        }
                                    }
                                    let obj = {
                                        cat: currentText,
                                        mapped,
                                    }
                                    allSizesOfCsv.push(obj)
                                }
                            });
                            let filteredOfSizes = allSizesOfCsv.filter(
                                (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                            );
                            this.setState({
                                sizesToMap: filteredOfSizes,
                            });
                        }

                        this.setState({
                            dropdownKeys: filteredOfKeys,
                            completeCSVArray: currentArray.items.item,
                            keysAttribute,
                        });
                    }

                    // let item = doc.getElementsByTagName("item")
                    // let allProducts = []
                    // for (var key in item) {
                    //     allProducts.push([])
                    //     let nodes = item[key].childNodes
                    //     for (var ele in nodes) {
                    //         if (nodes[ele]) {
                    //             allProducts[key].push(nodes[ele])
                    //         }
                    //     }
                    // }
                    // console.log(allProducts)
                };
                reader.readAsText(e.target.files[0]);
                e.target.value = "";
                // const xhr = new XMLHttpRequest()
                // xhr.open("GET", e.target.files[0])

                // xhr.addEventListener("load", () => {
                //     const data = xhr.responseText
                //     console.log(data)
                // })

                // xhr.send()
            } else {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: false })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let allKeys = [];
                    // let allCategoriesOfCsv = []
                    json.map((e) => {
                        let keysOfObject = Object.keys(e);
                        keysOfObject.map((t) => {
                            allKeys.push(t);
                        });
                        // if (e.Category) {
                        //     let obj = {
                        //         cat: e.Category,
                        //         mapped: ""
                        //     }
                        //     allCategoriesOfCsv.push(obj)
                        // } else if (e.category) {
                        //     let obj = {
                        //         cat: e.category,
                        //         mapped: ""
                        //     }
                        //     allCategoriesOfCsv.push(obj)
                        // }
                    });
                    let filteredOfKeys = allKeys.filter(
                        (v, i, a) => a.findIndex((t) => t === v) === i
                    );
                    let keysAttribute = []
                    filteredOfKeys.map((t) => {
                        let obj = {
                            attributeDefault: t,
                            editedAttribute: t,
                            attributeToShow: false,
                        }
                        keysAttribute.push(obj)
                    })
                    // let filteredOfCategories = allCategoriesOfCsv.filter(
                    //     (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                    // );

                    this.checkForDefaultSelected(filteredOfKeys)
                    let checkGenderPresent = filteredOfKeys.findIndex((e) => {
                        return e.toLowerCase() === "gender"
                    })
                    if (checkGenderPresent !== -1) {

                        let allGendersOfCsv = []
                        json.map((g) => {
                            let currentText = g[filteredOfKeys[checkGenderPresent]]
                            if (currentText && currentText !== undefined) {
                                let mapped = ""
                                if (this.state.alreadyGendersData.length > 0) {
                                    let filteredInner = this.state.alreadyGendersData.filter((g) => {
                                        return g.gender === currentText
                                    })
                                    if (filteredInner.length > 0) {
                                        mapped = filteredInner[0].mapped
                                    }
                                }
                                let obj = {
                                    gender: currentText,
                                    mapped,
                                }
                                allGendersOfCsv.push(obj)
                            }
                        });
                        let filteredOfGenders = allGendersOfCsv.filter(
                            (v, i, a) => a.findIndex((t) => t.gender === v.gender) === i
                        );
                        this.setState({
                            gendersToMap: filteredOfGenders,
                        });

                        this.setState({ genderImportField: filteredOfKeys[checkGenderPresent] })
                    } else {
                        this.setState({ genderImportField: "" })
                    }
                    let checkCategoryPresent = filteredOfKeys.findIndex((e) => {
                        return e.toLowerCase() === "category"
                    })
                    if (checkCategoryPresent !== -1) {
                        let allCategoriesOfCsv = []
                        json.map((g) => {
                            let currentText = g[filteredOfKeys[checkCategoryPresent]]
                            if (currentText && currentText !== undefined) {
                                let mapped = ""
                                if (this.state.alreadyCategoriesData.length > 0) {
                                    let filteredInner = this.state.alreadyCategoriesData.filter((g) => {
                                        return g.cat === currentText
                                    })
                                    if (filteredInner.length > 0) {
                                        mapped = filteredInner[0].mapped
                                    }
                                }
                                let obj = {
                                    cat: currentText,
                                    mapped,
                                }
                                allCategoriesOfCsv.push(obj)
                            }
                        });
                        let filteredOfCategories = allCategoriesOfCsv.filter(
                            (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                        );
                        this.setState({
                            categoriesToMap: filteredOfCategories,
                        });

                        this.setState({ categoriesImportField: filteredOfKeys[checkCategoryPresent] })
                    } else {
                        this.setState({ categoriesImportField: "" })
                    }
                    let checkSizePresent = filteredOfKeys.findIndex((e) => {
                        return e.toLowerCase() === "size"
                    })
                    if (checkSizePresent !== -1) {
                        let allSizesOfCsv = []
                        json.map((g) => {
                            let currentText = g[filteredOfKeys[checkSizePresent]]
                            if (currentText && currentText !== undefined) {
                                let mapped = ""
                                if (this.state.alreadySizesData.length > 0) {
                                    let filteredInner = this.state.alreadySizesData.filter((g) => {
                                        return g.cat === currentText
                                    })
                                    if (filteredInner.length > 0) {
                                        mapped = filteredInner[0].mapped
                                    }
                                }
                                let obj = {
                                    cat: currentText,
                                    mapped,
                                }
                                allSizesOfCsv.push(obj)
                            }
                        });
                        let filteredOfSizes = allSizesOfCsv.filter(
                            (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                        );
                        this.setState({
                            sizesToMap: filteredOfSizes,
                        });
                    }

                    this.setState({
                        dropdownKeys: filteredOfKeys,
                        completeCSVArray: json,
                        keysAttribute,
                        // categoriesToMap: filteredOfCategories,
                    });
                };
                reader.readAsArrayBuffer(e.target.files[0]);
                e.target.value = "";
            }
        }
    }

    processXmlFromURLStock() {
        this.setState({ processingXmlStock: true })
        axios.post("https://us-central1-bhodi-7c327.cloudfunctions.net/fetchXml", { url: this.state.xmlUrlStock })
            .then((response) => {
                if (response.data.statusCode === 201) {
                    let result = convert.xml2json(response.data.fileData, { compact: true, spaces: 4 })
                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        this.setState({
                            dropdownKeysStock: filteredOfKeys,
                            completeCSVArrayStock: currentArray.items.item,
                            processingXmlStock: false,
                            selectedXml: true,
                        });
                    }
                } else {
                    this.setState({
                        processingXmlStock: false,
                        errorInXmlStock: true
                    })
                }
            }).catch(() => {
                this.setState({
                    processingXmlStock: false,
                    errorInXmlStock: true
                })
            })
    }

    checkForDefaultSelected(filteredOfKeys) {
        let checkTitlePresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "title"
        })
        if (checkTitlePresent !== -1) {
            this.setState({ titleImportField: filteredOfKeys[checkTitlePresent] })
        } else {
            this.setState({ titleImportField: "" })
        }
        let checkDescPresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "description"
        })
        if (checkDescPresent !== -1) {
            this.setState({ descImportField: filteredOfKeys[checkDescPresent] })
        } else {
            this.setState({ descImportField: "" })
        }
        let checkSkuPresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "sku"
        })
        if (checkSkuPresent !== -1) {
            this.setState({ skuImportField: filteredOfKeys[checkSkuPresent] })
        } else {
            this.setState({ skuImportField: "" })
        }
        let checkVarSkuPresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "var sku"
        })
        if (checkVarSkuPresent !== -1) {
            this.setState({ sizesSkuImportField: filteredOfKeys[checkVarSkuPresent] })
        } else {
            this.setState({ sizesSkuImportField: "" })
        }
        let checkSizePresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "size"
        })
        if (checkSizePresent !== -1) {
            this.setState({ sizesImportField: filteredOfKeys[checkSizePresent] })
        } else {
            this.setState({ sizesImportField: "" })
        }
    }



    checkForDefaultSelectedStock(filteredOfKeys) {
        let checkSkuPresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "sku"
        })
        if (checkSkuPresent !== -1) {
            this.setState({ skuImportFieldStock: filteredOfKeys[checkSkuPresent] })
        } else {
            this.setState({ skuImportFieldStock: "" })
        }
        let checkStockPresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "stock"
        })
        if (checkStockPresent !== -1) {
            this.setState({ stockImportFieldStock: filteredOfKeys[checkStockPresent] })
        } else {
            this.setState({ stockImportFieldStock: "" })
        }
        let checkPricePresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "price"
        })
        if (checkPricePresent !== -1) {
            this.setState({ priceImportFieldStock: filteredOfKeys[checkPricePresent] })
        } else {
            this.setState({ priceImportFieldStock: "" })
        }
    }

    fileUploadcsvStock(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].type.includes("/xml")) {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    let readXml = e.target.result

                    let result = convert.xml2json(readXml, { compact: true, spaces: 4 })

                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        this.checkForDefaultSelectedStock(filteredOfKeys)
                        this.setState({
                            dropdownKeysStock: filteredOfKeys,
                            completeCSVArrayStock: currentArray.items.item,
                        });
                    }

                };
                reader.readAsText(e.target.files[0]);
                e.target.value = "";
            } else {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: false })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let allKeys = [];

                    json.map((e) => {
                        let keysOfObject = Object.keys(e);
                        keysOfObject.map((t) => {
                            allKeys.push(t);
                        });
                    });
                    let filteredOfKeys = allKeys.filter(
                        (v, i, a) => a.findIndex((t) => t === v) === i
                    );
                    this.checkForDefaultSelectedStock(filteredOfKeys)
                    this.setState({
                        dropdownKeysStock: filteredOfKeys,
                        completeCSVArrayStock: json,
                    });
                };
                reader.readAsArrayBuffer(e.target.files[0]);
                e.target.value = "";
            }
        }
    }

    fileUploadcsvImages(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].type.includes("/xml")) {
                this.setState({ csvSelectedNameImage: e.target.files[0].name, selectedXml: true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    let readXml = e.target.result

                    let result = convert.xml2json(readXml, { compact: true, spaces: 4 })

                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        this.setState({
                            dropdownKeysImages: filteredOfKeys,
                            completeCSVArrayImages: currentArray.items.item,
                        });
                    }

                };
                reader.readAsText(e.target.files[0]);
                e.target.value = "";
            } else {
                this.setState({ csvSelectedNameImage: e.target.files[0].name, selectedXml: false })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let allKeys = [];

                    json.map((e) => {
                        let keysOfObject = Object.keys(e);
                        keysOfObject.map((t) => {
                            allKeys.push(t);
                        });
                    });
                    let filteredOfKeys = allKeys.filter(
                        (v, i, a) => a.findIndex((t) => t === v) === i
                    );
                    this.setState({
                        dropdownKeysImages: filteredOfKeys,
                        completeCSVArrayImages: json,
                    });
                };
                reader.readAsArrayBuffer(e.target.files[0]);
                e.target.value = "";
            }
        }
    }

    fileUploadImages(e) {

        e.preventDefault();
        let dropFiles = this.state.dropFileImages;

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            objected.map((f, i) => {
                const reader = new FileReader();
                dropFiles.push(objected[i][1]);
                reader.addEventListener("load", () => {
                    let img = new Image();
                    let result = reader.result;
                    img.onload = (e) => {
                        let arr = this.state.productImages;
                        arr.push(result);
                        this.setState({
                            productImages: arr,
                            dropFileImages: dropFiles,
                        });
                    };
                    img.src = result;
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });
        }
    }

    saveProductImport() {
        const { mainSector, titleImportField, specialGrouping, descImportField, skuImportField, sizesImportField, sizesSkuImportField, genderImportField, categoriesImportField, globalDesc } = this.state

        let allProducts = []
        let uid = this.state.currentCompanyIdForImport ? this.state.currentCompanyIdForImport : this.props.currentMemberData.user_id;

        let seeMappedCategories = this.state.categoriesToMap.filter((e) => {
            return !e.mapped
        })

        let seeMappedGenders = this.state.gendersToMap.filter((e) => {
            return !e.mapped
        })

        let seeMappedSizes = this.state.sizesToMap.filter((e) => {
            return !e.mapped
        })

        let filteredAttributes = []
        if (this.state.keysAttribute.length > 0) {
            filteredAttributes = this.state.keysAttribute.filter((e) => {
                return e.attributeToShow && this.state.specialGrouping !== e.attributeDefault && this.state.titleImportField !== e.attributeDefault && this.state.descImportField !== e.attributeDefault && this.state.sizesImportField !== e.attributeDefault && this.state.sizesSkuImportField !== e.attributeDefault && this.state.genderImportField !== e.attributeDefault && this.state.skuImportField !== e.attributeDefault && this.state.categoriesImportField !== e.attributeDefault
            })
        }

        if (mainSector && titleImportField && skuImportField && seeMappedSizes.length === 0 && seeMappedCategories.length === 0 && categoriesImportField && seeMappedGenders.length === 0) {
            // if (mainSector && titleImportField && globalDesc && skuImportField && sizesImportField && sizesSkuImportField && seeMappedCategories.length === 0 && categoriesImportField && seeMappedGenders.length === 0) {
            this.setState({ mainSectorAlert: false, globalDescAlert: false, titleAlert: false, descAlert: false, categoriesAlert: false, skuAlert: false, sizesAlert: false, sizeSkuAlert: false, genderAlert: false, mapCategoriesAlert: false, mapGendersAlert: false })

            let currentTimeStamp = new Date().getTime()
            let timestamp = Math.floor(Date.now() / 100);

            this.state.completeCSVArray.map((e) => {
                currentTimeStamp = currentTimeStamp + 5
                timestamp = timestamp + 8000

                let filterCurrentProduct = this.props.bhodi_products.filter((g) => {
                    if (this.state.selectedXml) {
                        return g.sku === e[skuImportField]._text && g.created_by === uid
                    } else {
                        return g.sku === e[skuImportField] && g.created_by === uid
                    }
                })

                let title = ""
                let desc = ""
                let sku = ""
                let size = ""
                let categories = ""
                let gender = ""
                if (this.state.selectedXml) {
                    if (titleImportField) {
                        title = e[titleImportField]._text
                    }
                    if (skuImportField) {
                        sku = e[skuImportField]._text
                    }
                    if (descImportField) {
                        desc = e[descImportField]._text
                    }
                    if (categoriesImportField) {
                        categories = e[categoriesImportField]._text
                    }
                    if (genderImportField) {
                        gender = e[genderImportField]._text
                    }
                    if (sizesImportField) {
                        size = e[sizesImportField]._text
                    }
                } else {
                    if (titleImportField) {
                        title = e[titleImportField]
                    }
                    if (skuImportField) {
                        sku = e[skuImportField]
                    }
                    if (descImportField) {
                        desc = e[descImportField]
                    }
                    if (categoriesImportField) {
                        categories = e[categoriesImportField]
                    }
                    if (genderImportField) {
                        gender = e[genderImportField]
                    }
                    if (sizesImportField) {
                        size = e[sizesImportField]
                    }
                }

                console.log(timestamp, '/timestamp it iss')
                let obj = {
                    title: title,
                    price: 0,
                    sku: sku,
                    desc: desc ? desc : "",
                    main_sector: mainSector,
                    active: true,
                    created_at: currentTimeStamp,
                    timestamp: timestamp,
                    sort_time: timestamp,
                    created_by: uid,
                    sizesActive: false,
                    qty: 0,
                    images: [],
                }
                if (desc) {
                    obj.descAdded = true
                } else {
                    obj.descAdded = false
                }
                if (filteredAttributes.length > 0) {
                    let attributesMain = []
                    filteredAttributes.map((t) => {
                        let attributeValue = ""
                        if (this.state.selectedXml) {
                            if (t.attributeDefault) {
                                attributeValue = e[t.attributeDefault]._text
                            }
                        } else {
                            if (t.attributeDefault) {
                                attributeValue = e[t.attributeDefault]
                            }
                        }
                        let obj = {
                            attribute: t.editedAttribute,
                            value: attributeValue,
                        }
                        if (attributeValue && attributeValue !== undefined) {
                            attributesMain.push(obj)
                        }
                    })
                    obj.attributesToShow = attributesMain
                }
                if (filterCurrentProduct.length > 0) {
                    obj.created_at = filterCurrentProduct[0].created_at
                    obj.timestamp = filterCurrentProduct[0].timestamp
                    if (filterCurrentProduct[0].images) {
                        obj.images = filterCurrentProduct[0].images
                    }
                    obj.pushKey = filterCurrentProduct[0].pushKey
                    obj.sort_time = filterCurrentProduct[0].sort_time
                }
                if (size) {
                    obj.sizesActive = true
                    let objVariation = {
                        size: size,
                        qty: 0,
                        sku: "",
                    }
                    if (this.state.sizesToMap.length > 0) {
                        let currentSize = this.state.sizesToMap.filter((g) => {
                            return g.cat === size
                        })
                        if (currentSize.length > 0) {
                            if (currentSize[0].mapped) {
                                objVariation.size = currentSize[0].mapped
                            }
                        }
                    }
                    if (sizesSkuImportField) {
                        objVariation.sku = this.state.selectedXml ? e[sizesSkuImportField]._text : e[sizesSkuImportField]
                    } else {
                        objVariation.sku = sku
                    }
                    if (filteredAttributes.length > 0) {
                        let attributesMain = []
                        filteredAttributes.map((t) => {
                            let attributeValue = ""
                            if (this.state.selectedXml) {
                                if (t.attributeDefault) {
                                    attributeValue = e[t.attributeDefault]._text
                                }
                            } else {
                                if (t.attributeDefault) {
                                    attributeValue = e[t.attributeDefault]
                                }
                            }
                            if (attributeValue && attributeValue !== undefined) {
                                let obj = {
                                    attribute: t.editedAttribute,
                                    value: attributeValue,
                                }
                                attributesMain.push(obj)
                            }
                        })
                        objVariation.attributesToShow = attributesMain
                    }
                    let arr = [objVariation]
                    obj.variations_data = arr
                } else {
                    obj.variations_data = []
                }
                if (categories) {
                    let currentCategory = this.state.categoriesToMap.filter((g) => {
                        return g.cat === categories
                    })
                    if (currentCategory.length > 0) {
                        obj.main_category = [currentCategory[0].mapped]
                    }
                }
                if (gender) {
                    let currentGender = this.state.gendersToMap.filter((g) => {
                        return g.gender === gender
                    })
                    if (currentGender.length > 0) {
                        obj.gender = [currentGender[0].mapped]
                    } else {
                        obj.gender = []
                        // if (this.state.globalGender.length > 0) {
                        //     obj.gender = this.state.globalGender
                        // }
                    }
                } else {
                    obj.gender = []
                    //     if (this.state.globalGender.length > 0) {
                    //     obj.gender = this.state.globalGender
                    // }
                }
                if (specialGrouping) {
                    if (this.state.selectedXml) {
                        if (e[specialGrouping]._text) {
                            obj.grouping_via = e[specialGrouping]._text
                        }
                    } else {
                        if (e[specialGrouping]) {
                            obj.grouping_via = e[specialGrouping]
                        }
                    }
                }
                allProducts.push(obj)
            })


            let mergedArrayToMake = []
            let alreadyPushedArrays = []

            if (specialGrouping) {
                allProducts.map((e, i) => {
                    if (!alreadyPushedArrays.includes(e.grouping_via)) {
                        if (!e.pushKey) {
                            let storageKey = firebase.database().ref("bhodi_products").push().key;
                            e.pushKey = storageKey
                        }

                        mergedArrayToMake.push(e)
                        if (e.grouping_via) {
                            alreadyPushedArrays.push(e.grouping_via)
                        }
                    } else {
                        let indexAt = mergedArrayToMake.findIndex((g) => {
                            return g.grouping_via == e.grouping_via
                        })
                        if (mergedArrayToMake[indexAt].gender) {
                            if (!mergedArrayToMake[indexAt].gender.includes(e.gender[0])) {
                                if (e.gender && e.gender.length > 0) {
                                    mergedArrayToMake[indexAt].gender.push(e.gender[0])
                                }
                            }
                        } else {
                            if (e.gender && e.gender.length > 0) {
                                mergedArrayToMake[indexAt].gender = [e.gender[0]]
                            }
                        }
                        if (mergedArrayToMake[indexAt].main_category) {
                            if (!mergedArrayToMake[indexAt].main_category.includes(e.main_category[0])) {
                                if (e.main_category && e.main_category.length > 0) {
                                    mergedArrayToMake[indexAt].main_category.push(e.main_category[0])
                                }
                            }
                        } else {
                            if (e.main_category && e.main_category.length > 0) {
                                mergedArrayToMake[indexAt].main_category = [e.main_category[0]]
                            }
                        }
                        if (mergedArrayToMake[indexAt].variations_data) {
                            if (e.variations_data && e.variations_data.length > 0) {
                                mergedArrayToMake[indexAt].variations_data.push(e.variations_data[0])
                            }
                        } else {
                            if (e.variations_data && e.variations_data.length > 0) {
                                mergedArrayToMake[indexAt].variations_data = [e.variations_data[0]]
                            }
                        }
                    }
                })
            } else {
                allProducts.map((e, i) => {
                    if (!alreadyPushedArrays.includes(e.sku)) {
                        if (!e.pushKey) {
                            let storageKey = firebase.database().ref("bhodi_products").push().key;
                            e.pushKey = storageKey
                        }

                        mergedArrayToMake.push(e)
                        alreadyPushedArrays.push(e.sku)
                    } else {
                        let indexAt = alreadyPushedArrays.findIndex((g) => {
                            return g === e.sku
                        })
                        if (mergedArrayToMake[indexAt].gender) {
                            if (!mergedArrayToMake[indexAt].gender.includes(e.gender[0])) {
                                if (e.gender && e.gender.length > 0) {
                                    mergedArrayToMake[indexAt].gender.push(e.gender[0])
                                }
                            }
                        } else {
                            if (e.gender && e.gender.length > 0) {
                                mergedArrayToMake[indexAt].gender = [e.gender[0]]
                            }
                        }
                        if (mergedArrayToMake[indexAt].main_category) {
                            if (!mergedArrayToMake[indexAt].main_category.includes(e.main_category[0])) {
                                if (e.main_category && e.main_category.length > 0) {
                                    mergedArrayToMake[indexAt].main_category.push(e.main_category[0])
                                }
                            }
                        } else {
                            if (e.main_category && e.main_category.length > 0) {
                                mergedArrayToMake[indexAt].main_category = [e.main_category[0]]
                            }
                        }
                        if (mergedArrayToMake[indexAt].variations_data) {
                            if (e.variations_data && e.variations_data.length > 0) {
                                mergedArrayToMake[indexAt].variations_data.push(e.variations_data[0])
                            }
                        } else {
                            if (e.variations_data && e.variations_data.length > 0) {
                                mergedArrayToMake[indexAt].variations_data = [e.variations_data[0]]
                            }
                        }
                    }
                })
            }

            let filterMyProducts = this.props.bhodi_products
            // .filter((g) => {
            //     return g.created_by === uid
            // })

            let productsToChange = []
            if (mergedArrayToMake.length > 0) {
                [...mergedArrayToMake].map((e) => {
                    let filtered = filterMyProducts.filter((g) => {
                        if (g.variations_data && g.variations_data.length > 0) {
                            let filteredInner = g.variations_data.filter((t) => {
                                return t.sku === e.sku
                            })
                            if (filteredInner.length > 0) {
                                return filteredInner.length > 0
                            } else {
                                return g.sku === e.sku
                            }
                        } else {
                            return g.sku === e.sku
                        }
                    })

                    let clonedObj = Object.assign({}, filtered[0])

                    if (filtered.length > 0) {
                        let allVariations = []

                        let merged = { ...clonedObj, ...e }

                        if (clonedObj.variations_data && clonedObj.variations_data.length > 0 && e.variations_data && e.variations_data.length > 0) {
                            let concatted = clonedObj.variations_data.concat(e.variations_data)
                            allVariations = concatted.filter(
                                (v, i, a) => a.findIndex((t) => t.sku === v.sku) === i
                            );
                            merged.variations_data = allVariations
                        } else if (clonedObj.variations_data && clonedObj.variations_data.length > 0) {
                            allVariations = clonedObj.variations_data
                            merged.variations_data = allVariations
                            merged.sizesActive = true
                        }


                        merged.qty = clonedObj.qty
                        merged.price = clonedObj.price

                        productsToChange.push(merged)
                    } else {
                        productsToChange.push(e)
                    }
                })
            } else {
                productsToChange = mergedArrayToMake
            }

            if (this.state.alreadyGendersData.length > 0) {
                let concat = this.state.gendersToMap.concat(this.state.alreadyGendersData)
                let filteredOfGenders = concat.filter(
                    (v, i, a) => a.findIndex((t) => t.gender === v.gender) === i
                );
                firebase.database().ref("mapped_genders/" + uid).set(filteredOfGenders)
            } else {
                firebase.database().ref("mapped_genders/" + uid).set(this.state.gendersToMap)
            }

            if (this.state.alreadySizesData.length > 0) {
                let concat = this.state.sizesToMap.concat(this.state.alreadySizesData)
                let filteredOfSizes = concat.filter(
                    (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                );
                firebase.database().ref("mapped_sizes/" + uid).set(filteredOfSizes)
            } else {
                firebase.database().ref("mapped_sizes/" + uid).set(this.state.sizesToMap)
            }

            if (this.state.alreadyCategoriesData.length > 0) {
                let concat = this.state.categoriesToMap.concat(this.state.alreadyCategoriesData)
                let filteredOfCategories = concat.filter(
                    (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                );
                firebase.database().ref("mapped_categories/" + uid).set(filteredOfCategories)
            } else {
                firebase.database().ref("mapped_categories/" + uid).set(this.state.categoriesToMap)
            }
            this.setState({ savingProductsImport: true })

            let allPushed = []
            let promise = new Promise((res, rej) => {
                let sortedChanged = [...productsToChange].sort((a, b) => {
                    return a.sort_time * 100 - b.sort_time * 100;
                })
                let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                    return a.sort_time * 100 - b.sort_time * 100;
                })
                sortedAllProducts.map((t, i) => {
                    let filtered = sortedChanged.filter((y) => {
                        return y.pushKey === t.pushKey
                    })
                    if (filtered.length > 0) {
                        sortedAllProducts[i] = filtered[0]
                    }
                })
                sortedChanged.map((t, i) => {
                    let filtered = sortedAllProducts.filter((y) => {
                        return y.pushKey === t.pushKey
                    })
                    if (filtered.length === 0) {
                        sortedAllProducts.push(t)
                    }
                })
                if (this.state.allIndexes.length > 0) {
                    let newIndexes = Array.from(this.state.allIndexes.reverse());
                    if (newIndexes.length > 0) {
                        sortedAllProducts.map((t) => {
                            if (!t.images) {
                                return false;
                            }
                            if (!t.active) {
                                return false;
                            }
                            if (t.sizesActive) {
                                let stocksAvailableSizes = []
                                t.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (t.inCartOf) {
                                        filteredStockLength = Object.entries(t.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (t.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(t.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                            } else {
                                if (Number(t.qty) === 0) {
                                    return false;
                                }
                            }
                            let findIndex = this.state.allProductsToCheckSwap.findIndex((g) => {
                                return g.pushKey === t.pushKey
                            })
                            if (findIndex !== -1) {
                                if (newIndexes.length < Number(this.state.maxProductsToShow) && !newIndexes.includes(findIndex)) {
                                    newIndexes.push(findIndex)
                                }
                            }
                        })

                        let allRemainingIndexes = []
                        sortedAllProducts.map((j, ind) => {
                            if (!newIndexes.includes(ind)) {
                                allRemainingIndexes.push(ind)
                            }
                        })
                        let allProductsInOrderIndexes = newIndexes.concat(allRemainingIndexes)

                        let timestamp = Math.floor(Date.now() / 100);

                        let allProductsToSet = []
                        allProductsInOrderIndexes.map((e, i) => {
                            timestamp = timestamp + 8000
                            sortedAllProducts[e].sort_time = timestamp
                            allProductsToSet.push(sortedAllProducts[e])
                        })

                        sortedAllProducts.sort((a, b) => {
                            return a.sort_time * 100 - b.sort_time * 100;
                        }).map((t) => {
                            if (t.variations_data) {
                                let objected = Object.entries(t.variations_data)
                                let filtered = objected.filter((g) => {
                                    return Number(g[1].qty) > 0
                                })
                                if (filtered.length === 0) {
                                    if (t.inCartOf) {
                                        // obj.inCartOf = currentObject.inCartOf
                                        let entries = Object.entries(t.inCartOf)
                                        entries.map((g) => {
                                            let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                            let obj = {
                                                pushKey: pushKeyNotification,
                                                notification: `${t.sku} was deleted by company`,
                                                created_at: new Date().getTime(),
                                                notificationFor: g[1].cookie
                                            }
                                            firebase.database().ref("notifications_data/" + g[1].cookie + "/" + pushKeyNotification).set(obj)
                                            firebase.database().ref("guest_carts/" + g[1].cookie + "/" + g[1].cartPushKey).remove()
                                        })
                                    }
                                }
                            } else {
                                if (Number(t.qty) === 0) {
                                    if (t.inCartOf) {
                                        // obj.inCartOf = currentObject.inCartOf
                                        let entries = Object.entries(t.inCartOf)
                                        entries.map((g) => {
                                            let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                            let obj = {
                                                pushKey: pushKeyNotification,
                                                notification: `${t.sku} was deleted by company`,
                                                created_at: new Date().getTime(),
                                                notificationFor: g[1].cookie
                                            }
                                            firebase.database().ref("notifications_data/" + g[1].cookie + "/" + pushKeyNotification).set(obj)
                                            firebase.database().ref("guest_carts/" + g[1].cookie + "/" + g[1].cartPushKey).remove()
                                        })
                                    }
                                }
                            }

                            allPushed.push(t)
                            firebase.database().ref(`bhodi_products/${t.pushKey}`).set(t)
                            if (allPushed.length === sortedAllProducts.length) {
                                res()
                            }
                        })
                    }
                } else {
                    productsToChange.sort((a, b) => {
                        return a.sort_time * 100 - b.sort_time * 100;
                    }).map((e) => {
                        allPushed.push(e)
                        firebase.database().ref("bhodi_products/" + e.pushKey).set(e)
                        if (allPushed.length === productsToChange.length) {
                            setTimeout(() => {
                                res()
                            }, 3000);
                        }
                    })
                }
            })
            // let promise = new Promise((res, rej) => {
            //     productsToChange.map((e) => {
            //         allPushed.push(e)
            //         firebase.database().ref("bhodi_products/" + e.pushKey).set(e)
            //         if (allPushed.length === productsToChange.length) {
            //             setTimeout(() => {
            //                 res()
            //             }, 3000);
            //         }
            //     })
            // })
            promise.then(() => {
                // this.setState({ savingProductsImport: false })
                swal({
                    title: "Gereed",
                    text: "De producten zijn succesvol geïmporteerd ",
                    icon: "success",
                }).then(() => {
                    window.location.reload()
                })
            })
        } else {
            if (mainSector) {
                this.setState({ mainSectorAlert: false })
            } else {
                this.setState({ mainSectorAlert: true })
            }
            if (titleImportField) {
                this.setState({ titleAlert: false })
            } else {
                this.setState({ titleAlert: true })
            }
            // if (descImportField) {
            //     this.setState({ descAlert: false })
            // } else {
            //     this.setState({ descAlert: true })
            // }
            if (skuImportField) {
                this.setState({ skuAlert: false })
            } else {
                this.setState({ skuAlert: true })
            }
            // if (sizesImportField) {
            //     this.setState({ sizesAlert: false })
            // } else {
            //     this.setState({ sizesAlert: true })
            // }
            // if (sizesSkuImportField) {
            //     this.setState({ sizeSkuAlert: false })
            // } else {
            //     this.setState({ sizeSkuAlert: true })
            // }
            // if (genderImportField) {
            //     this.setState({ genderAlert: false })
            // } else {
            //     this.setState({ genderAlert: true })
            // }
            if (categoriesImportField) {
                this.setState({ categoriesAlert: false })
            } else {
                this.setState({ categoriesAlert: true })
            }
            if (seeMappedCategories.length === 0) {
                this.setState({ mapCategoriesAlert: false })
            } else {
                this.setState({ mapCategoriesAlert: true })
            }
            if (seeMappedSizes.length === 0) {
                this.setState({ mapSizesAlert: false })
            } else {
                this.setState({ mapSizesAlert: true })
            }
            if (seeMappedGenders.length === 0) {
                this.setState({ mapGendersAlert: false })
            } else {
                this.setState({ mapGendersAlert: true })
            }
        }
    }

    saveProductImages() {
        let uid = this.props.currentMemberData.user_id;

        let allImagesToUpload = []
        let matchedSkus = []
        this.setState({ uploadingImages: true })
        this.state.dropFileImages.map((g) => {
            let fileName = g.name.split("_")[0]
            let currentSku = fileName.split(".")[0]

            let filterCurrentProduct = this.props.bhodi_products.filter((g) => {
                return g.sku === currentSku
            })

            if (filterCurrentProduct.length > 0) {
                if (filterCurrentProduct[0].images) {
                    let filteredImagePresent = filterCurrentProduct[0].images.filter((t) => {
                        let filePath = t.split("/")[7].split("?")[0]
                        const search = "%2F"
                        let replacer = new RegExp(search, "g")
                        let replacedPath = filePath.replace(replacer, "/")
                        let fileName = replacedPath.split("/")[1].split("-")
                        fileName.shift()
                        let fileNameMain = fileName.join("-")
                        return fileNameMain === g.name
                    })
                    if (filteredImagePresent.length === 0) {
                        allImagesToUpload.push(g)
                        if (!matchedSkus.includes(currentSku)) {
                            matchedSkus.push(currentSku)
                        }
                    } else {
                        let filePath = filteredImagePresent[0].split("/")[7].split("?")[0]
                        const search = "%2F"
                        let replacer = new RegExp(search, "g")
                        let replacedPath = filePath.replace(replacer, "/")
                        // g.restrictPath = replacedPath
                        g.pathStrict = replacedPath
                        allImagesToUpload.push(g)
                        if (!matchedSkus.includes(currentSku)) {
                            matchedSkus.push(currentSku)
                        }
                    }
                } else {
                    allImagesToUpload.push(g)
                    if (!matchedSkus.includes(currentSku)) {
                        matchedSkus.push(currentSku)
                    }
                }
                // allImagesToUpload.push(g)
                // if (!matchedSkus.includes(currentSku)) {
                //     matchedSkus.push(currentSku)
                // }
            }
            // if (filterCurrentProduct.length)
        })
        let promiseImages = new Promise((res) => {
            let arrUploaded = []
            if (allImagesToUpload.length > 0) {
                allImagesToUpload.map((e) => {
                    let fileName = e.name.split("_")[0]
                    let currentSku = fileName.split(".")[0]
                    let pathToUpload = `bhodi_products_images/${new Date().getTime()}-${e.name}`
                    if (e.pathStrict) {
                        pathToUpload = e.pathStrict
                    }
                    delete e.pathStrict
                    firebase
                        .storage()
                        .ref()
                        .child(`${pathToUpload}`)
                        // .child(e.pathStrict ? e.pathStrict : `bhodi_products_images/${new Date().getTime()}-${e.name}`)
                        .put(e)
                        .then((snapshot) => {
                            snapshot.ref.getDownloadURL().then((snapUrl) => {
                                let obj = {
                                    sku: currentSku,
                                    image_url: snapUrl,
                                }
                                arrUploaded.push(obj)
                                if (arrUploaded.length === allImagesToUpload.length) {
                                    res(arrUploaded)
                                }
                            })
                        })
                })
            } else {
                res([])
            }
        })
        promiseImages.then((allUploadedImages) => {
            let productsToPush = []
            matchedSkus.map((e) => {
                let allImagesForSku = []
                let filtered = allUploadedImages.filter((g) => {
                    return g.sku === e
                })
                if (filtered.length > 0) {
                    filtered.map((t) => {
                        allImagesForSku.push(t.image_url)
                    })
                }

                let filterCurrentProduct = this.props.bhodi_products.filter((g) => {
                    return g.sku === e
                })
                if (filterCurrentProduct.length > 0) {
                    let productObject = Object.assign({}, filterCurrentProduct[0])
                    if (productObject.images) {
                        let allImagesToSet = [...allImagesForSku]
                        productObject.images.map((t) => {
                            let filePath = t.split("/")[7].split("?")[0]
                            const search = "%2F"
                            let replacer = new RegExp(search, "g")
                            let replacedPath = filePath.replace(replacer, "/")
                            let fileName = replacedPath.split("/")[1].split("-")
                            fileName.shift()
                            let fileNameMain = fileName.join("-")

                            let filtered = allImagesForSku.filter((g) => {
                                let filePathInner = g.split("/")[7].split("?")[0]
                                let replacerInner = new RegExp(search, "g")
                                let replacedPathInner = filePathInner.replace(replacerInner, "/")
                                let fileNameInner = replacedPathInner.split("/")[1].split("-")
                                fileNameInner.shift()
                                let fileNameMainInner = fileNameInner.join("-")
                                return fileNameMain === fileNameMainInner
                            })

                            if (filtered.length === 0) {
                                allImagesToSet.push(t)
                            }
                        })
                        productObject.images = allImagesToSet
                    } else {
                        productObject.images = allImagesForSku
                    }
                    productsToPush.push(productObject)
                }
            })

            let sortedAllProductsToSet = [...productsToPush].sort((a, b) => {
                return a.sort_time * 100 - b.sort_time * 100;
            })

            let allPushed = []

            let promise = new Promise((res, rej) => {
                sortedAllProductsToSet.map((t) => {
                    let clonedObj = Object.assign({}, t)

                    allPushed.push(t)

                    firebase.database().ref(`bhodi_products/${clonedObj.pushKey}`).set(clonedObj)
                    if (allPushed.length === sortedAllProductsToSet.length) {
                        res()
                    }
                })
            })
            promise.then(() => {
                swal({
                    title: "Gereed",
                    text: "De afbeeldingen zijn succesvol geïmporteerd",
                    icon: "success",
                }).then(() => {
                    window.location.reload()
                })
            })

        })
        // promiseImages.then((allUploadedImages) => {
        //     // if (allUploadedImages.length === 0) {
        //     //     swal({
        //     //         title: "warning",
        //     //         text: "Images with same name already exist",
        //     //         icon: "success",
        //     //     })
        //     //     return false;
        //     // }
        //     let promiseUploaded = new Promise((res, rej) => {
        //         let allUploaded = []

        //         let filterMyProducts = this.props.bhodi_products.filter((g) => {
        //             return g.created_by === uid
        //         })

        //         let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
        //             return a.sort_time * 100 - b.sort_time * 100;
        //         })

        //         matchedSkus.map((e) => {
        //             let allImagesForSku = []
        //             let filtered = allUploadedImages.filter((g) => {
        //                 return g.sku === e
        //             })
        //             if (filtered.length > 0) {
        //                 filtered.map((t) => {
        //                     allImagesForSku.push(t.image_url)
        //                 })
        //             }

        //             let filterCurrentProduct = this.props.bhodi_products.filter((g) => {
        //                 return g.sku === e && g.created_by === uid
        //             })
        //             if (filterCurrentProduct.length > 0) {
        //                 let findIndex = sortedAllProducts.findIndex((t) => {
        //                     return t.pushKey === filterCurrentProduct[0].pushKey
        //                 })
        //                 if (filterCurrentProduct[0].images) {
        //                     let allImagesToSet = [...allImagesForSku]
        //                     filterCurrentProduct[0].images.map((t) => {
        //                         let filePath = t.split("/")[7].split("?")[0]
        //                         const search = "%2F"
        //                         let replacer = new RegExp(search, "g")
        //                         let replacedPath = filePath.replace(replacer, "/")
        //                         let fileName = replacedPath.split("/")[1].split("-")
        //                         fileName.shift()
        //                         let fileNameMain = fileName.join("-")

        //                         let filtered = allImagesForSku.filter((g) => {
        //                             let filePathInner = g.split("/")[7].split("?")[0]
        //                             let replacerInner = new RegExp(search, "g")
        //                             let replacedPathInner = filePathInner.replace(replacerInner, "/")
        //                             let fileNameInner = replacedPathInner.split("/")[1].split("-")
        //                             fileNameInner.shift()
        //                             let fileNameMainInner = fileNameInner.join("-")
        //                             return fileNameMain === fileNameMainInner
        //                         })

        //                         if (filtered.length === 0) {
        //                             allImagesToSet.push(t)
        //                         }
        //                     })
        //                     if (findIndex !== -1) {
        //                         sortedAllProducts[findIndex].images = allImagesToSet
        //                     }
        //                     // firebase.database().ref("bhodi_products/" + filterCurrentProduct[0].pushKey + "/images").set(allImagesToSet)
        //                 } else {
        //                     if (findIndex !== -1) {
        //                         sortedAllProducts[findIndex].images = allImagesForSku
        //                     }
        //                     // firebase.database().ref("bhodi_products/" + filterCurrentProduct[0].pushKey + "/images").set(allImagesForSku)
        //                 }

        //                 // allUploaded.push(e)
        //                 // if (allUploaded.length === matchedSkus.length) {
        //                 //     res()
        //                 // }
        //             }
        //         })

        //         if (this.state.allIndexes.length > 0) {
        //             let newIndexes = Array.from(this.state.allIndexes.reverse());
        //             if (newIndexes.length > 0) {
        //                 sortedAllProducts.map((t) => {
        //                     if (!t.active) {
        //                         return false;
        //                     }
        //                     if (t.sizesActive) {
        //                         let stocksAvailableSizes = []
        //                         t.variations_data.map((e) => {
        //                             if (Number(e.qty) === 0) {
        //                                 return false;
        //                             }
        //                             let filteredStockLength = []
        //                             let filteredStockLengthVerzilvered = []
        //                             if (t.inCartOf) {
        //                                 filteredStockLength = Object.entries(t.inCartOf).filter((t) => {
        //                                     return t[1].selectedSize === e.size
        //                                 })
        //                             }
        //                             if (t.verzilvered_by) {
        //                                 filteredStockLengthVerzilvered = Object.entries(t.verzilvered_by).filter((t) => {
        //                                     return t[1].selectedSize === e.size
        //                                 })
        //                             }
        //                             if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
        //                                 stocksAvailableSizes.push(e)
        //                             }
        //                         })
        //                         if (stocksAvailableSizes.length === 0) {
        //                             return false;
        //                         }
        //                     } else {
        //                         if (Number(t.qty) === 0) {
        //                             return false;
        //                         }
        //                     }
        //                     let findIndex = this.state.allProductsToCheckSwap.findIndex((g) => {
        //                         return g.pushKey === t.pushKey
        //                     })
        //                     if (findIndex !== -1) {
        //                         if (newIndexes.length < Number(this.state.maxProductsToShow) && !newIndexes.includes(findIndex)) {
        //                             newIndexes.push(findIndex)
        //                         }
        //                     }
        //                 })

        //                 let allRemainingIndexes = []
        //                 sortedAllProducts.map((j, ind) => {
        //                     if (!newIndexes.includes(ind)) {
        //                         allRemainingIndexes.push(ind)
        //                     }
        //                 })
        //                 let allProductsInOrderIndexes = newIndexes.concat(allRemainingIndexes)

        //                 let timestamp = Math.floor(Date.now() / 100);

        //                 let allProductsToSet = []

        //                 allProductsInOrderIndexes.map((e, i) => {
        //                     timestamp = timestamp + 8000
        //                     sortedAllProducts[e].sort_time = timestamp
        //                     allProductsToSet.push(sortedAllProducts[e])
        //                 })

        //                 let allPushed = []
        //                 sortedAllProducts.sort((a, b) => {
        //                     return a.sort_time * 100 - b.sort_time * 100;
        //                 }).map((t) => {
        //                     allPushed.push(t)
        //                     firebase.database().ref(`bhodi_products/${t.pushKey}`).set(t)
        //                     if (allPushed.length === sortedAllProducts.length) {
        //                         res()
        //                     }
        //                 })
        //             }
        //         } else {
        //             let allPushed = []
        //             sortedAllProducts.sort((a, b) => {
        //                 return a.sort_time * 100 - b.sort_time * 100;
        //             }).map((t) => {
        //                 allPushed.push(t)
        //                 firebase.database().ref(`bhodi_products/${t.pushKey}`).set(t)
        //                 if (allPushed.length === sortedAllProducts.length) {
        //                     res()
        //                 }
        //             })
        //         }
        //     })
        //     promiseUploaded.then(() => {
        //         swal({
        //             title: "Gereed",
        //             text: "De afbeeldingen zijn succesvol geïmporteerd",
        //             icon: "success",
        //         }).then(() => {
        //             this.setState({
        //                 dropFileImages: [],
        //                 productImages: [],
        //                 importImagesPopup: false,
        //                 uploadingImages: false,
        //             })
        //             window.location.reload()
        //         })
        //     })
        // })
    }

    shiftIdsOnePositionBefore(array) {
        if (!Array.isArray(array) || array.length < 2) {
            return;
        }

        const firstId = array[0].sort_time;
        for (let i = 1; i < array.length; i++) {
            array[i - 1].sort_time = array[i].sort_time;
        }
        array[array.length - 1].sort_time = firstId;

        console.log(array, '/check out this here changed')
    }

    saveProductStocks() {
        const { skuImportFieldStock, stockImportFieldStock, priceImportFieldStock } = this.state

        let allStocks = []
        let uid = this.props.currentMemberData.user_id;

        if (skuImportFieldStock && stockImportFieldStock && priceImportFieldStock) {
            // if (mainSector && titleImportField && globalDesc && skuImportField && sizesImportField && sizesSkuImportField && seeMappedCategories.length === 0 && categoriesImportField && seeMappedGenders.length === 0) {
            this.setState({ skuAlertStock: false, stockAlertStock: false, priceAlertStock: false })

            this.state.completeCSVArrayStock.map((e) => {
                let price = ""
                let stock = ""
                let sku = ""
                if (this.state.selectedXml) {
                    if (priceImportFieldStock) {
                        price = e[priceImportFieldStock]._text
                    }
                    if (skuImportFieldStock) {
                        sku = e[skuImportFieldStock]._text
                    }
                    if (stockImportFieldStock) {
                        stock = e[stockImportFieldStock]._text
                    }
                } else {
                    if (priceImportFieldStock) {
                        price = e[priceImportFieldStock]
                    }
                    if (skuImportFieldStock) {
                        sku = e[skuImportFieldStock]
                    }
                    if (stockImportFieldStock) {
                        stock = e[stockImportFieldStock]
                    }
                }

                let obj = {
                    price,
                    sku,
                    stock,
                }

                allStocks.push(obj)
            })


            let allCompaniesStock = []
            let allProductsStock = []
            allStocks.map((t) => {
                let filtered = this.props.bhodi_products.filter((g) => {
                    if (g.variations_data) {
                        let filtered = g.variations_data.filter((e) => {
                            return e.sku === t.sku
                        })
                        if (filtered.length > 0) {
                            return g
                        }
                    } else {
                        return g.sku === t.sku
                    }
                })
                if (filtered.length > 0) {
                    let filteredCreator = this.props.user.filter((g) => {
                        return g.user_id === filtered[0].created_by
                    })
                    if (filteredCreator.length > 0) {
                        allProductsStock.push(filtered[0])
                        allCompaniesStock.push(filteredCreator[0])
                    }
                }
            })

            allCompaniesStock = [...allCompaniesStock].filter((v, i, a) => a.findIndex(t => (t.user_id === v.user_id)) === i)

            let allProductsToset = []

            allCompaniesStock.map((t) => {
                let filterMyProducts = this.props.bhodi_products.filter((g) => {
                    return g.created_by === t.user_id
                })
                let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                    return a.sort_time * 100 - b.sort_time * 100;
                })
                let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                filterMyProducts.map((e) => {
                    let mustPushProduct = false
                    let filtered = allStocks.filter((g) => {
                        return g.sku === e.sku
                    })
                    let clonedObj = Object.assign({}, e)
                    if (filtered.length > 0) {
                        if (!e.variations_data) {
                            if (filtered[0].stock) {
                                clonedObj.qty = filtered[0].stock.toString()
                            } else {
                                clonedObj.qty = "0"
                                sortTimeLast = sortTimeLast + 8000
                                clonedObj.sort_time = sortTimeLast
                            }
                        }
                        if (filtered[0].price) {
                            clonedObj.price = filtered[0].price.toString()
                        } else {
                            clonedObj.price = "0"
                        }
                        mustPushProduct = true
                    }
                    if (clonedObj.variations_data && clonedObj.variations_data.length > 0) {
                        clonedObj.variations_data.map((t) => {
                            let filtered = allStocks.filter((g) => {
                                return g.sku === t.sku
                            })
                            if (filtered.length > 0) {
                                if (filtered[0].stock) {
                                    t.qty = filtered[0].stock.toString()
                                } else {
                                    t.qty = "0"
                                }
                                if (filtered[0].price) {
                                    t.price = filtered[0].price.toString()
                                } else {
                                    t.price = "0"
                                }
                                mustPushProduct = true
                            }
                        })
                        let filtered = clonedObj.variations_data.filter((t) => {
                            return Number(t.qty) > 0
                        })
                        if (filtered.length === 0) {
                            sortTimeLast = sortTimeLast + 8000
                            clonedObj.sort_time = sortTimeLast
                        }
                    }
                    if (mustPushProduct) {
                        allProductsToset.push(clonedObj)
                    }
                })
            })
            let sortedAllProductsToSet = [...allProductsToset].sort((a, b) => {
                return a.sort_time * 100 - b.sort_time * 100;
            })
            console.log(sortedAllProductsToSet, '/see')

            let allPushed = []

            let promise = new Promise((res, rej) => {
                sortedAllProductsToSet.map((t) => {
                    let clonedObj = Object.assign({}, t)
                    if (clonedObj.variations_data) {
                        let objected = Object.entries(clonedObj.variations_data)
                        let filtered = objected.filter((g) => {
                            return Number(g[1].qty) > 0
                        })
                        if (filtered.length === 0) {
                            if (clonedObj.inCartOf) {
                                let entries = Object.entries(clonedObj.inCartOf)
                                entries.map((g) => {
                                    let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                    let obj = {
                                        pushKey: pushKeyNotification,
                                        notification: `${clonedObj.sku} was deleted by company`,
                                        created_at: new Date().getTime(),
                                        notificationFor: g[1].cookie
                                    }
                                    firebase.database().ref("notifications_data/" + g[1].cookie + "/" + pushKeyNotification).set(obj)
                                    firebase.database().ref("guest_carts/" + g[1].cookie + "/" + g[1].cartPushKey).remove()
                                })
                            }
                        }
                    } else {
                        if (Number(clonedObj.qty) === 0) {
                            if (clonedObj.inCartOf) {
                                let entries = Object.entries(clonedObj.inCartOf)
                                entries.map((g) => {
                                    let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                    let obj = {
                                        pushKey: pushKeyNotification,
                                        notification: `${clonedObj.sku} was deleted by company`,
                                        created_at: new Date().getTime(),
                                        notificationFor: g[1].cookie
                                    }
                                    firebase.database().ref("notifications_data/" + g[1].cookie + "/" + pushKeyNotification).set(obj)
                                    firebase.database().ref("guest_carts/" + g[1].cookie + "/" + g[1].cartPushKey).remove()
                                })
                            }
                        }
                    }

                    delete clonedObj.inCartOf
                    delete clonedObj.verzilvered_by

                    allPushed.push(t)
                    firebase.database().ref(`bhodi_products/${clonedObj.pushKey}`).set(clonedObj)
                    if (allPushed.length === sortedAllProductsToSet.length) {
                        res()
                    }
                })
            })
            promise.then(() => {
                swal({
                    title: "Gereed",
                    text: "De voorraad is succesvol geïmporteerd",
                    icon: "success",
                }).then(() => {
                    window.location.reload()
                })
            })

            // let filterMyProducts = this.props.bhodi_products.filter((g) => {
            //     return g.created_by === uid
            // })

            // let productsToChange = []
            // if (filterMyProducts.length > 0) {
            //     [...filterMyProducts].map((e) => {
            //         let mustPushProduct = false
            //         let filtered = allStocks.filter((g) => {
            //             return g.sku === e.sku
            //         })
            //         let clonedObj = Object.assign({}, e)
            //         if (filtered.length > 0) {
            //             if (!e.variations_data) {
            //                 if (filtered[0].stock) {
            //                     clonedObj.qty = filtered[0].stock.toString()
            //                 } else {
            //                     clonedObj.qty = "0"
            //                 }
            //             }
            //             if (filtered[0].price) {
            //                 clonedObj.price = filtered[0].price.toString()
            //             } else {
            //                 clonedObj.price = "0"
            //             }
            //             mustPushProduct = true
            //         }
            //         if (clonedObj.variations_data && clonedObj.variations_data.length > 0) {
            //             clonedObj.variations_data.map((t) => {
            //                 let filtered = allStocks.filter((g) => {
            //                     return g.sku === t.sku
            //                 })
            //                 if (filtered.length > 0) {
            //                     if (filtered[0].stock) {
            //                         t.qty = filtered[0].stock.toString()
            //                     } else {
            //                         t.qty = "0"
            //                     }
            //                     if (filtered[0].price) {
            //                         t.price = filtered[0].price.toString()
            //                     } else {
            //                         t.price = "0"
            //                     }
            //                     mustPushProduct = true
            //                 }
            //             })
            //         }
            //         if (mustPushProduct) {
            //             productsToChange.push(clonedObj)
            //         }
            //     })

            //     if (productsToChange.length > 0) {
            //         this.setState({ uploadingStocks: true })
            //     }

            //     setTimeout(() => {
            //         let allPushed = []
            //         let promise = new Promise((res, rej) => {
            //             let sortedChanged = [...productsToChange].sort((a, b) => {
            //                 return a.sort_time * 100 - b.sort_time * 100;
            //             })
            //             let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
            //                 return a.sort_time * 100 - b.sort_time * 100;
            //             })
            //             sortedAllProducts.map((t, i) => {
            //                 let filtered = sortedChanged.filter((y) => {
            //                     return y.pushKey === t.pushKey
            //                 })
            //                 if (filtered.length > 0) {
            //                     sortedAllProducts[i] = filtered[0]
            //                 }
            //             })
            //             if (this.state.allIndexes.length > 0) {
            //                 let newIndexes = Array.from(this.state.allIndexes.reverse());
            //                 if (newIndexes.length > 0) {
            //                     sortedAllProducts.map((t) => {
            //                         if (!t.images) {
            //                             return false;
            //                         }
            //                         if (!t.active) {
            //                             return false;
            //                         }
            //                         if (t.sizesActive) {
            //                             let stocksAvailableSizes = []
            //                             t.variations_data.map((e) => {
            //                                 if (Number(e.qty) === 0) {
            //                                     return false;
            //                                 }
            //                                 let filteredStockLength = []
            //                                 let filteredStockLengthVerzilvered = []
            //                                 if (t.inCartOf) {
            //                                     filteredStockLength = Object.entries(t.inCartOf).filter((t) => {
            //                                         return t[1].selectedSize === e.size
            //                                     })
            //                                 }
            //                                 if (t.verzilvered_by) {
            //                                     filteredStockLengthVerzilvered = Object.entries(t.verzilvered_by).filter((t) => {
            //                                         return t[1].selectedSize === e.size
            //                                     })
            //                                 }
            //                                 if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
            //                                     stocksAvailableSizes.push(e)
            //                                 }
            //                             })
            //                             if (stocksAvailableSizes.length === 0) {
            //                                 return false;
            //                             }
            //                         } else {
            //                             if (Number(t.qty) === 0) {
            //                                 return false;
            //                             }
            //                         }
            //                         let findIndex = this.state.allProductsToCheckSwap.findIndex((g) => {
            //                             return g.pushKey === t.pushKey
            //                         })
            //                         if (findIndex !== -1) {
            //                             if (newIndexes.length < Number(this.state.maxProductsToShow) && !newIndexes.includes(findIndex)) {
            //                                 newIndexes.push(findIndex)
            //                             }
            //                         }
            //                     })

            //                     let allRemainingIndexes = []
            //                     sortedAllProducts.map((j, ind) => {
            //                         if (!newIndexes.includes(ind)) {
            //                             allRemainingIndexes.push(ind)
            //                         }
            //                     })
            //                     let allProductsInOrderIndexes = newIndexes.concat(allRemainingIndexes)

            //                     let timestamp = Math.floor(Date.now() / 100);

            //                     let allProductsToSet = []
            //                     allProductsInOrderIndexes.map((e, i) => {
            //                         timestamp = timestamp + 8000
            //                         sortedAllProducts[e].sort_time = timestamp
            //                         allProductsToSet.push(sortedAllProducts[e])
            //                     })

            //                     sortedAllProducts.sort((a, b) => {
            //                         return a.sort_time * 100 - b.sort_time * 100;
            //                     }).map((t) => {
            //                         if (t.variations_data) {
            //                             let objected = Object.entries(t.variations_data)
            //                             let filtered = objected.filter((g) => {
            //                                 return Number(g[1].qty) > 0
            //                             })
            //                             if (filtered.length === 0) {
            //                                 if (t.inCartOf) {
            //                                     // obj.inCartOf = currentObject.inCartOf
            //                                     let entries = Object.entries(t.inCartOf)
            //                                     entries.map((g) => {
            //                                         let pushKeyNotification = firebase.database().ref("notifications_data").push().key
            //                                         let obj = {
            //                                             pushKey: pushKeyNotification,
            //                                             notification: `${t.sku} was deleted by company`,
            //                                             created_at: new Date().getTime(),
            //                                             notificationFor: g[1].cookie
            //                                         }
            //                                         firebase.database().ref("notifications_data/" + g[1].cookie + "/" + pushKeyNotification).set(obj)
            //                                         firebase.database().ref("guest_carts/" + g[1].cookie + "/" + g[1].cartPushKey).remove()
            //                                     })
            //                                 }
            //                             }
            //                         } else {
            //                             if (Number(t.qty) === 0) {
            //                                 if (t.inCartOf) {
            //                                     // obj.inCartOf = currentObject.inCartOf
            //                                     let entries = Object.entries(t.inCartOf)
            //                                     entries.map((g) => {
            //                                         let pushKeyNotification = firebase.database().ref("notifications_data").push().key
            //                                         let obj = {
            //                                             pushKey: pushKeyNotification,
            //                                             notification: `${t.sku} was deleted by company`,
            //                                             created_at: new Date().getTime(),
            //                                             notificationFor: g[1].cookie
            //                                         }
            //                                         firebase.database().ref("notifications_data/" + g[1].cookie + "/" + pushKeyNotification).set(obj)
            //                                         firebase.database().ref("guest_carts/" + g[1].cookie + "/" + g[1].cartPushKey).remove()
            //                                     })
            //                                 }
            //                             }
            //                         }

            //                         allPushed.push(t)
            //                         firebase.database().ref(`bhodi_products/${t.pushKey}`).set(t)
            //                         if (allPushed.length === sortedAllProducts.length) {
            //                             res()
            //                         }
            //                     })
            //                 }
            //             } else {
            //                 sortedChanged.sort((a, b) => {
            //                     return a.sort_time * 100 - b.sort_time * 100;
            //                 }).map((t) => {
            //                     allPushed.push(t)
            //                     firebase.database().ref(`bhodi_products/${t.pushKey}`).set(t)
            //                     if (allPushed.length === sortedChanged.length) {
            //                         res()
            //                     }
            //                 })
            //             }
            //         })
            //         promise.then(() => {
            //             swal({
            //                 title: "Gereed",
            //                 text: "De voorraad is succesvol geïmporteerd",
            //                 icon: "success",
            //             }).then(() => {
            //                 window.location.reload()
            //             })
            //         })
            //     }, 1000);

            //     // all products updated
            //     // productsToChange.map((t) => {
            //     //     firebase.database().ref(`bhodi_products/${t.pushKey}`).set(t)
            //     // })
            // }
        } else {
            if (skuImportFieldStock) {
                this.setState({ skuAlertStock: false })
            } else {
                this.setState({ skuAlertStock: true })
            }
            if (priceImportFieldStock) {
                this.setState({ priceAlertStock: false })
            } else {
                this.setState({ priceAlertStock: true })
            }
            if (stockImportFieldStock) {
                this.setState({ stockAlertStock: false })
            } else {
                this.setState({ stockAlertStock: true })
            }
        }
    }

    saveProductImagesFile() {
        const { skuImportFieldImage, urlImportFieldImage } = this.state

        let allImages = []
        let uid = this.props.currentMemberData.user_id;

        if (skuImportFieldImage && urlImportFieldImage) {
            // if (mainSector && titleImportField && globalDesc && skuImportField && sizesImportField && sizesSkuImportField && seeMappedCategories.length === 0 && categoriesImportField && seeMappedGenders.length === 0) {
            this.setState({ skuAlertImage: false, urlAlertImage: false })

            this.state.completeCSVArrayImages.map((e) => {
                let url = ""
                let sku = ""
                if (this.state.selectedXml) {
                    if (urlImportFieldImage) {
                        url = e[urlImportFieldImage]._text
                    }
                    if (skuImportFieldImage) {
                        sku = e[skuImportFieldImage]._text
                    }
                } else {
                    if (urlImportFieldImage) {
                        url = e[urlImportFieldImage]
                    }
                    if (skuImportFieldImage) {
                        sku = e[skuImportFieldImage]
                    }
                }

                if (sku && url) {
                    let obj = {
                        url,
                        sku,
                    }

                    allImages.push(obj)
                }
            })

            let filterMyProducts = this.props.bhodi_products.filter((g) => {
                return g.created_by === uid
            })

            let productsToChange = []
            if (filterMyProducts.length > 0) {
                [...filterMyProducts].map((e) => {
                    let filtered = allImages.filter((g) => {
                        return g.sku === e.sku
                    })
                    let clonedObj = Object.assign({}, e)
                    if (filtered.length > 0) {
                        // let pushKey = firebase.database().ref("product_images_to_upload").push().key
                        let pushKey = firebase.database().ref("product_images_to_upload/" + uid).push().key
                        let obj = {
                            pushKey,
                            productKey: clonedObj.pushKey,
                            sku: e.sku,
                            imageUrl: filtered[0].url,
                            image_of: uid,
                        }
                        productsToChange.push(obj)
                    }
                    if (clonedObj.variations_data && clonedObj.variations_data.length > 0) {
                        clonedObj.variations_data.map((t) => {
                            let filtered = allImages.filter((g) => {
                                return g.sku === t.sku
                            })
                            if (filtered.length > 0) {
                                // let pushKey = firebase.database().ref("product_images_to_upload").push().key
                                let pushKey = firebase.database().ref("product_images_to_upload/" + uid).push().key
                                let obj = {
                                    pushKey,
                                    productKey: clonedObj.pushKey,
                                    sku: t.sku,
                                    imageUrl: filtered[0].url,
                                }
                                productsToChange.push(obj)
                            }
                        })
                    }
                })

                let filteredOfImages = productsToChange.filter(
                    (v, i, a) => a.findIndex((t) => t.pushKey === v.pushKey && t.imageUrl === v.imageUrl) === i
                );
                let productsToUpload = filteredOfImages

                if (productsToChange.length > 0) {
                    this.setState({ uploadingImagesDirect: true })
                }

                setTimeout(() => {
                    let allPushed = []
                    let promise = new Promise((res, rej) => {
                        productsToUpload.map((t) => {
                            allPushed.push(t)
                            // firebase.database().ref(`product_images_to_upload/${t.pushKey}`).set(t)
                            firebase.database().ref(`product_images_to_upload/${uid}/${t.pushKey}`).set(t)
                            if (allPushed.length === productsToUpload.length) {
                                res()
                            }
                        })
                    })
                    promise.then(() => {
                        firebase.database().ref(`users/${uid}/show_maintanance_banner`).set(true)
                        swal({
                            title: "Cool.",
                            text: "Your images will be uploaded in a while",
                            icon: "success",
                        }).then(() => {
                            window.location.reload()
                        })
                    })
                }, 1000);
            }
        } else {
            if (skuImportFieldImage) {
                this.setState({ skuAlertImage: false })
            } else {
                this.setState({ skuAlertImage: true })
            }
            if (urlImportFieldImage) {
                this.setState({ urlAlertImage: false })
            } else {
                this.setState({ urlAlertImage: true })
            }
        }
    }

    uploadBannerImage(e, type) {
        let uid = this.props.currentMemberData.user_id;
        let a = e.target.files[0];

        if (type === "small") {
            this.setState({ uploadingSmall: true, uploadedSmall: false })
        } else {
            this.setState({ uploadingBig: true, uploadedBig: false })
        }

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            objected.map((f, i) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    let img = new Image();
                    let result = reader.result;
                    img.onload = (e) => {
                        if (type === "small") {
                            this.setState({
                                intervalSmallBannerImage: result,
                            });
                        } else {
                            this.setState({
                                intervalBigBannerImage: result,
                            });
                        }
                    };
                    img.src = result;
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });

            firebase
                .storage()
                .ref()
                .child(`banner_product_images/${a.name}`)
                .put(a)
                .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                        if (type === "small") {
                            firebase
                                .database()
                                .ref("users/" + uid + "/interval_small_banner_image")
                                .set(snapUrl);
                            this.setState({ uploadingSmall: false, uploadedSmall: true })
                        } else {
                            firebase
                                .database()
                                .ref("users/" + uid + "/interval_big_banner_image")
                                .set(snapUrl);
                            this.setState({ uploadingBig: false, uploadedBig: true })
                        }
                    });
                });
        }
    }

    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    saveAnnouncementBanner() {
        let uid = this.props.currentMemberData.user_id;

        const { announcementTitle, announcementDesc, announcementBannerDate, announcementEnabled, announcementBannerTime, announcementMediaFile, announcementMediaType, showAnnouncementWithoutTiming } = this.state

        let imagePresent = true
        let alertForDateTime = true

        if (!this.state.notRequireImage) {
            if (announcementMediaFile.length === 0) {
                imagePresent = false
            }
        }

        if (!showAnnouncementWithoutTiming) {
            if (announcementBannerDate && announcementBannerTime) {
                alertForDateTime = false
            } else {
                alertForDateTime = true
            }
        } else {
            alertForDateTime = false
        }

        if (announcementTitle && announcementDesc && !alertForDateTime && imagePresent) {
            this.setState({ announcementTitleAlert: false, announcementDescAlert: false, noTimingSelectedAnnouncement: false, announcementImageAlert: false })

            let announcementDate = new Date(Number(announcementBannerDate.split("-")[0]), Number(announcementBannerDate.split("-")[1] - 1), Number(announcementBannerDate.split("-")[2]), Number(announcementBannerTime.split(":")[0]), Number(announcementBannerTime.split(":")[1]), 0).getTime()
            let announcementDateISO = new Date(Number(announcementBannerDate.split("-")[0]), Number(announcementBannerDate.split("-")[1] - 1), Number(announcementBannerDate.split("-")[2]), Number(announcementBannerTime.split(":")[0]), Number(announcementBannerTime.split(":")[1]), 0).toISOString()

            if (announcementMediaFile.length > 0) {
                let obj = {
                    title: announcementTitle,
                    desc: announcementDesc,
                    bannerType: announcementMediaType,
                    endDate: announcementDate,
                    announcementBannerDate,
                    announcementBannerTime,
                    active: announcementEnabled,
                    end_date_iso: announcementDateISO,
                    showAnnouncementWithoutTiming,
                }
                firebase
                    .storage()
                    .ref()
                    .child(`banner_products_media/${announcementMediaFile[0].name}${new Date().getTime()}`)
                    .put(announcementMediaFile[0])
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((snapUrl) => {
                            obj.bannerMedia = snapUrl

                            firebase.database().ref(`users/${uid}/announcement`).set(obj).then(() => {
                                swal({
                                    title: "Cool.",
                                    text: "De announcement banner is succesvol aangemaakt",
                                    icon: "success",
                                }).then(() => {
                                    this.setState({ announcementBannerSettings: false })
                                })
                            })
                        })
                    })
            } else if (this.state.currentAnnouncementPresent) {
                let obj = {
                    title: announcementTitle,
                    desc: announcementDesc,
                    bannerType: this.state.currentAnnouncementPresent.bannerType,
                    endDate: announcementDate,
                    announcementBannerDate,
                    announcementBannerTime,
                    bannerMedia: this.state.currentAnnouncementPresent.bannerMedia,
                    active: announcementEnabled,
                    end_date_iso: announcementDateISO,
                    showAnnouncementWithoutTiming,
                }
                firebase.database().ref(`users/${uid}/announcement`).set(obj).then(() => {
                    swal({
                        title: "Cool.",
                        text: "De announcement banner is succesvol aangemaakt",
                        icon: "success",
                    }).then(() => {
                        this.setState({ announcementBannerSettings: false })
                    })
                })
            }
        } else {
            if (announcementTitle) {
                this.setState({ announcementTitleAlert: false })
            } else {
                this.setState({ announcementTitleAlert: true })
            }
            if (announcementDesc) {
                this.setState({ announcementDescAlert: false })
            } else {
                this.setState({ announcementDescAlert: true })
            }
            if (!this.state.showAnnouncementWithoutTiming) {
                if (announcementBannerDate && announcementBannerTime) {
                    this.setState({ noTimingSelectedAnnouncement: false })
                } else {
                    this.setState({ noTimingSelectedAnnouncement: true })
                }
            }
            if (imagePresent) {
                this.setState({ announcementImageAlert: false })
            } else {
                this.setState({ announcementImageAlert: true })
            }
        }

    }

    uploadMediaAnnouncement(e) {
        e.preventDefault();
        let dropFiles = [];

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            if (e.target.files[0].type.match(/image.*/)) {
                this.setState({ announcementMediaType: "image" })
            } else {
                this.setState({ announcementMediaType: "video" })
            }
            objected.map((f, i) => {
                const reader = new FileReader();
                dropFiles.push(objected[i][1]);
                reader.addEventListener("load", () => {
                    let img = new Image();
                    let result = reader.result;
                    // img.onload = (e) => {
                    this.setState({
                        announcementMediaUrl: result,
                        announcementMediaFile: dropFiles,
                    });
                    // };
                    // img.src = result;
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });
        }
    }


    getAllStock(product) {
        let qty = 0;
        if (product.variations_data && product.variations_data.length > 0) {
            product.variations_data.map((t) => {
                qty += Number(t.qty)
            })
        } else {
            qty = product.qty
        }
        return qty
    }


    getAvailableStock(product) {
        let qty = 0;
        let inCartOf = []
        let verzilveredBy = []
        if (product.inCartOf) {
            inCartOf = Object.entries(product.inCartOf)
        }
        if (product.verzilvered_by) {
            verzilveredBy = Object.entries(product.verzilvered_by)
        }
        if (product.variations_data && product.variations_data.length > 0) {
            product.variations_data.map((t) => {
                qty += Number(t.qty)
            })
        } else {
            qty = product.qty
        }
        return qty - inCartOf.length - verzilveredBy.length
    }

    favoriteUnfavoriteProduct(product) {
        if (product.is_bookmarked) {
            firebase.database().ref("bhodi_products/" + product.pushKey + "/is_bookmarked").remove()
        } else {
            let bookmarkedProductAlready = this.state.allProducts.filter((t) => {
                return t.is_bookmarked
            })
            if (bookmarkedProductAlready.length > 0) {
                bookmarkedProductAlready.map((g) => {
                    firebase.database().ref("bhodi_products/" + g.pushKey + "/is_bookmarked").remove()
                })
            }
            firebase.database().ref("bhodi_products/" + product.pushKey + "/is_bookmarked").set(true)
        }
    }

    activeUnactiveProduct(product) {
        // product.active = !product.active

        // this.setState({
        //     hello: "world",
        // });

        let promiseOne = new Promise((res) => {
            if (product.is_bookmarked || product.onlyAvailableViaURL) {
                res()
            } else {
                let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
                    if (productInner.sizesActive) {
                        let stocksAvailableSizes = []
                        productInner.variations_data.map((e) => {
                            if (Number(e.qty) === 0) {
                                return false;
                            }
                            let filteredStockLength = []
                            let filteredStockLengthVerzilvered = []
                            if (productInner.inCartOf && !product.isSpecialProduct && !product.availableForCompanyGuest) {
                                filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                            }
                            if (productInner.verzilvered_by) {
                                filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                            }
                            if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                stocksAvailableSizes.push(e)
                            }
                        })
                        if (stocksAvailableSizes.length === 0) {
                            return false;
                        }
                    } else {
                        let filteredStockLength = []
                        let filteredStockLengthVerzilvered = []
                        if (productInner.inCartOf && !product.isSpecialProduct && !product.availableForCompanyGuest) {
                            filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
                                return !t[1].selectedSize
                            })
                        }
                        if (productInner.verzilvered_by) {
                            filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                                return !t[1].selectedSize
                            })
                        }
                        if (Number(productInner.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(productInner.qty) === 0) {
                            return false;
                        }
                    }

                    if (product.availableForCompanyGuest) {
                        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.availableForCompanyGuest && productInner.created_by === product.created_by
                    } else if (product.isSpecialProduct) {
                        if (product.productForPage) {
                            return !productInner.availableOnTime && productInner.images && productInner.active && productInner.isSpecialProduct && productInner.created_by === product.created_by && productInner.productForPage === product.productForPage
                        }
                        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.isSpecialProduct && productInner.created_by === product.created_by && !productInner.productForPage
                    } else {
                        return !productInner.availableOnTime && productInner.images && productInner.active && !productInner.is_bookmarked && !productInner.isSpecialProduct && !productInner.onlyAvailableViaURL && productInner.created_by === product.created_by
                    }
                })
                // let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
                //     return productInner.created_by === product.created_by
                // })
                let filterCurrentUser = this.props.user.filter((g) => {
                    return g.user_id === product.created_by
                })
                if (filterCurrentUser.length > 0 && filterMyProducts.length > 0) {

                    let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                        return a.sort_time * 100 - b.sort_time * 100;
                    })
                    let currentProductIndex = sortedAllProducts.findIndex((t) => {
                        return t.pushKey === product.pushKey
                    })


                    let maxProductsToShow = 4

                    if (product.isSpecialProduct || product.availableForCompanyGuest) {
                        maxProductsToShow = 5
                        if (this.props.global_values) {
                            if (this.props.global_values.products_count_new) {
                                maxProductsToShow = Number(this.props.global_values.products_count_new)
                            }
                        }
                    } else if (filterCurrentUser[0].productsInfo) {
                        if (filterCurrentUser[0].productsInfo.maxProductsToShow) {
                            maxProductsToShow = Number(filterCurrentUser[0].productsInfo.maxProductsToShow)
                        }
                    }

                    if (filterCurrentUser[0].productsInfo.intervalTimer && currentProductIndex !== -1 && currentProductIndex < maxProductsToShow && sortedAllProducts.length > maxProductsToShow) {
                        let newerProduct = sortedAllProducts[maxProductsToShow]

                        let dateCreated = new Date()
                        let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                        if (splitted[1].includes("second")) {
                            dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                        } else if (splitted[1].includes("minute")) {
                            dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                        } else {
                            dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                        }

                        firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())

                    } else if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer && sortedAllProducts.length < maxProductsToShow && !product.active) {
                        let newerProduct = product

                        let dateCreated = new Date()
                        let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                        if (splitted[1].includes("second")) {
                            dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                        } else if (splitted[1].includes("minute")) {
                            dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                        } else {
                            dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                        }

                        firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())
                    }
                    //  else if (filterMyProductsAvailable.length < maxProductsToShow && filterCurrentUser[0].productsInfo.intervalTimer && product.active) {
                    //     let newerProduct = product

                    //     console.log(newerProduct, '/see here')

                    //     let dateCreated = new Date()
                    //     let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                    //     if (splitted[1].includes("second")) {
                    //         dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                    //     } else if (splitted[1].includes("minute")) {
                    //         dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                    //     } else {
                    //         dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                    //     }

                    //     firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())
                    // }

                    let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                    sortTimeLast = sortTimeLast + 8000

                    if (sortedAllProducts[sortedAllProducts.length - 1].pushKey !== product.pushKey) {
                        firebase.database().ref(`bhodi_products/${product.pushKey}/sort_time`).set(sortTimeLast).then(() => {
                            res()
                        })
                    } else {
                        res()
                    }
                } else {
                    res()
                }
            }
        })
        promiseOne.then(() => {
            product.active = !product.active

            this.setState({
                hello: "world",
            });

            if (!product.active) {
                if (product.inCartOf) {
                    let entries = Object.entries(product.inCartOf)
                    entries.map((t) => {
                        let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                        let obj = {
                            pushKey: pushKeyNotification,
                            notification: `${product.sku} was deleted by company`,
                            created_at: new Date().getTime(),
                            notificationFor: t[1].cookie
                        }
                        firebase.database().ref("notifications_data/" + t[1].cookie + "/" + pushKeyNotification).set(obj)
                        firebase.database().ref("guest_carts/" + t[1].cookie + "/" + t[1].cartPushKey).remove()
                    })
                    firebase.database().ref("bhodi_products/" + product.pushKey + "/inCartOf").remove()
                }
            }

            firebase.database().ref("bhodi_products/" + product.pushKey + "/active").set(product.active)
        })
    }


    copyToClipboard(product) {
        this.setState({ copiedTextDone: true });
        var textField = document.createElement("textarea");
        textField.innerText = `${window.location.origin}/product-detailed?key=${product.pushKey}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        setTimeout(() => {
            this.setState({ copiedTextDone: false })
        }, 3000);
    }

    getBuyerName(uid) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === uid
        })
        if (filtered.length > 0) {
            if (filtered[0].type === "company") {
                return `${filtered[0].company_name}`
            } else {
                return `${filtered[0].first_name} ${filtered[0].last_name}`
            }
        }
    }

    getBuyerAddress(uid) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === uid
        })
        if (filtered.length > 0) {
            const { street, houseNumber, zipCode, city } = filtered[0]
            return `${street} ${houseNumber}, ${zipCode} ${city}`
        }
    }

    duplicateCurrentProduct(product) {
        let objToMake = Object.assign({}, product)
        delete objToMake.images

        let timestamp = Math.floor(Date.now() / 100);
        let storageKey = firebase.database().ref("bhodi_products").push().key;

        objToMake.created_at = new Date().getTime()
        objToMake.timestamp = timestamp
        objToMake.sort_time = timestamp
        objToMake.active = false
        objToMake.pushKey = storageKey

        firebase.database().ref("bhodi_products/" + storageKey).set(objToMake)

        let allProducts = [...this.props.bhodi_products]
        allProducts.push(objToMake)
        // this.props.updateBhodiProducts(allProducts)
    }

    render() {
        return (
            this.state.guestEditorMain ? (
                <GuestProductEditor closePopup={() => {
                    this.setState({ guestEditorMain: false })
                }} />
            ) : this.state.guestEditor ? (
                <ExclusiveProductsEditor closePopup={() => {
                    this.setState({ guestEditor: false })
                }} />
            ) : <div>
                <button className="top-absolute-copied" style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Gekopieerd</button>
                <div className={`popup-main-neworder ${this.state.newOrderComing && "active"}`}>
                    {this.state.orderedProduct && this.state.productOrderedBy && (<div className='inner-panew'>
                        <MdClose onClick={() => {
                            this.setState({ newOrderComing: false })
                        }} />
                        <h3>CONGRATULATIONS</h3>
                        <p>{this.getBuyerName(this.state.productOrderedBy)} Just ordered Product {this.state.orderedProduct.title}</p>
                        <p>Delivery Address: {this.getBuyerAddress(this.state.productOrderedBy)}</p>
                    </div>)}
                </div>
                {this.state.announcementBannerSettings ? this.state.settingsPopup && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>ANNOUNCEMENT BANNER</span>
                            <CloseIcon onClick={() => this.setState({ announcementBannerSettings: false })} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Titel</label>
                                <input type="text" value={this.state.announcementTitle} onChange={(e) => {
                                    this.setState({ announcementTitle: e.target.value })
                                }} />
                                {this.state.announcementTitleAlert && (
                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                )}
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Omschrijving</label>
                                <textarea value={this.state.announcementDesc} onChange={(e) => {
                                    this.setState({ announcementDesc: e.target.value })
                                }} ></textarea>
                                {this.state.announcementDescAlert && (
                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                )}
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label className="dolabelsuppercase">Afbeelding of video</label>
                                <div className='wrapper-select-new'>
                                    <input
                                        type="text"
                                        value={"Upload"}
                                        disabled
                                        style={{
                                            fontSize: 13,
                                            letterSpacing: 1,
                                            backgroundColor: "white",
                                        }}
                                        className="dolabelsuppercase inputplaceholderbold"
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => this.uploadMediaAnnouncement(e)}
                                        accept="image/*, video/*"
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "80%",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            opacity: 0,
                                        }}
                                    />
                                    <PublishIcon
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: 5,
                                            fontSize: 18,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                                {this.state.announcementImageAlert && (
                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                )}
                            </div>
                            {this.state.announcementMediaUrl && (<div className="image-campaigns-new">
                                {this.state.announcementMediaType === "image" ? (<div
                                    className="image-sfcplp"
                                    style={{
                                        backgroundImage: `url("${this.state.announcementMediaUrl}")`,
                                    }}
                                >
                                </div>) : <div
                                    className="image-sfcplp"
                                    style={{ backgroundImage: "none" }}
                                >
                                    <video src={this.state.announcementMediaUrl} autoPlay muted loop style={{ maxWidth: "100%" }}></video>
                                </div>}
                            </div>)}
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Show Without Timing</label>
                                <Switch
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    checked={this.state.showAnnouncementWithoutTiming}
                                    onClick={() => {
                                        this.setState({ showAnnouncementWithoutTiming: !this.state.showAnnouncementWithoutTiming })
                                    }}
                                />
                                {/* <input type="text" value={this.state.showAnnouncementWithoutTiming} onChange={(e) => {
                                    this.setState({ showAnnouncementWithoutTiming: e.target.value })
                                }} /> */}
                            </div>
                            {(<div
                                className="form-miur formmiurpinksvg formfullwidthftcic form-inner-for-datetime"
                                style={{ position: "relative" }}
                            >
                                <label>Producten beschikbaar vanaf</label>
                                <div className="inner-top-main" style={{ width: "100%", }}>
                                    <div className="right-fifd">
                                        <div className="datinput-fifd">
                                            <BiCalendar />
                                            {this.state.announcementBannerDate ? (
                                                <label>
                                                    {this.getFormattedDate(this.state.announcementBannerDate)}
                                                </label>
                                            ) : (
                                                <label>Datum</label>
                                            )}
                                            <input
                                                type="date"
                                                min={this.state.todayDateToBeMax}
                                                value={this.state.announcementBannerDate}
                                                onChange={(e) => {
                                                    this.setState({
                                                        announcementBannerDate: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="select-fifd">
                                            <MdTimer />
                                            <select
                                                value={this.state.announcementBannerTime}
                                                onChange={(e) => {
                                                    this.setState({
                                                        announcementBannerTime: e.target.value,
                                                    });
                                                }}
                                            >
                                                <option disabled selected hidden value="">
                                                    Tijd
                                                </option>
                                                {this.state.allTimes.map((y) => {
                                                    return (
                                                        <option value={y.label}>
                                                            {y.label}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.noTimingSelectedAnnouncement && (
                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                )}
                            </div>)}
                            {/* <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>
                                    {this.state.announcementEnabled ? "Actief" : "Niet Actief"}
                                    <Switch
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                        checked={this.state.announcementEnabled}
                                        onClick={() => {
                                            this.setState({ announcementEnabled: !this.state.announcementEnabled })
                                        }}
                                    />
                                </label>
                            </div> */}
                            <div className='save-button-main'>
                                <button onClick={this.saveAnnouncementBanner.bind(this)}>OPSLAAN</button>
                            </div>
                        </div>
                    </div>
                </div>) : this.state.globalSettingsPopup ? (<div className='right-menu-main-settings rmms-popups-morewidth'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>GLOBAL SETTINGS</span>
                            <CloseIcon onClick={() => {
                                document.querySelector("html").style.overflowY = "auto"
                                this.setState({ globalSettingsPopup: false })
                            }} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Handelingskosten
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ shippingCostInfo: !this.state.shippingCostInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.shippingCostInfo ? 1 : 0, pointerEvents: this.state.shippingCostInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ shippingCostInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Het is mogelijk om de verzendkosten en handelingskosten
                                            in rekening te brengen richting de klant. Het geld
                                            wordt direct overgemaakt naar jullie gekoppelde paypal account.
                                            De klant kan eenvoudig afrekenen met Ideal of Paypal.
                                        </label>
                                    </div>
                                </label>
                                {/* <label>Handelingskosten <div className='green-round-info'>?</div></label> */}
                                <div className='wrapper-select-new'>
                                    <select value={this.state.shippingCost} onChange={(e) => {
                                        let shipmentPriceId = ""
                                        if (e.target.value !== "" && e.target.value !== "free") {
                                            shipmentPriceId = this.state.pricesPriceId[e.target.value]
                                        }
                                        this.setState({ shippingCost: e.target.value, shipmentPriceId: shipmentPriceId || "" })
                                    }}>
                                        <option value="" hidden selected>Selecteer</option>
                                        <option value="free">Free</option>
                                        <option value="3.95">€ 3,95</option>
                                        <option value="4.50">€ 4,50</option>
                                        <option value="4.95">€ 4,95`</option>
                                        <option value="5.00">€ 5,00</option>
                                        <option value="5.50">€ 5,50</option>
                                        <option value="6.00">€ 6,00</option>
                                        <option value="6.50">€ 6,50</option>
                                        <option value="7.50">€ 7,50</option>
                                        <option value="8.50">€ 8,50</option>
                                        <option value="12.50">€ 12,50</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    SPECIAL PRODUCTS COUNT
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ specialProductsCountInfo: !this.state.specialProductsCountInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.specialProductsCountInfo ? 1 : 0, pointerEvents: this.state.specialProductsCountInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ specialProductsCountInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Het is mogelijk om de verzendkosten en handelingskosten
                                            in rekening te brengen richting de klant. Het geld
                                            wordt direct overgemaakt naar jullie gekoppelde paypal account.
                                            De klant kan eenvoudig afrekenen met Ideal of Paypal.
                                        </label>
                                    </div>
                                </label>
                                {/* <label>Handelingskosten <div className='green-round-info'>?</div></label> */}
                                <div className='wrapper-select-new'>
                                    <select value={this.state.specialProductsCount.toString()} onChange={(e) => {
                                        this.setState({ specialProductsCount: Number(e.target.value) })
                                    }}>
                                        <option value="" hidden selected>Selecteer</option>
                                        <option value="5">5</option>
                                        <option value="8">8</option>
                                        <option value="12">12</option>
                                        <option value="24">24</option>
                                        <option value="9999999999">Unlimited</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            {/* <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Items to show
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ itemsToShowInfo: !this.state.itemsToShowInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.itemsToShowInfo ? 1 : 0, pointerEvents: this.state.itemsToShowInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ itemsToShowInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Bepaal het aantal producten dat er per keer getooond mag
                                            worden op jullie productenpagina. Wanneer er een product
                                            verkocht is, komt er automatisch weer een nieuw product beschikbaar.
                                        </label>
                                    </div>
                                </label>
                                <div className='wrapper-select-new'>
                                    <select value={this.state.itemsToShow} onChange={(e) => {
                                        this.setState({ itemsToShow: e.target.value })
                                    }}>
                                        {[1, 3, 6, 9, 12, 15, 18].map((e) => {
                                            return (<option value={e} key={Math.random()}>{e}</option>)
                                        })}
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div> */}
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Countdown
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ countDownStartInfo: !this.state.countDownStartInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.countDownStartInfo ? 1 : 0, pointerEvents: this.state.countDownStartInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ countDownStartInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Wanneer er een product verkocht is, word er automatisch
                                            een nieuw product getoond. Het nieuwe product is voorzien
                                            van een aftelperiode. Wanneer deze periode is bereikt
                                            kan het product daadwerkelijk besteld worden.
                                            Stel eenvoudig de tijd in.
                                        </label>
                                    </div>
                                </label>
                                {/* <label>Countdown <div className='green-round-info'>?</div></label> */}
                                {this.state.productsAsCarousel && (<div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                                    <select value={this.state.productsTimer} onChange={(e) => {
                                        this.setState({ productsTimer: e.target.value })
                                    }}>
                                        <option value="">Selecteer</option>
                                        <option value="1 minute">1 minute</option>
                                        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                                            return (<option value={`${e} minutes`}>{e} minutes</option>)
                                        })}
                                        <option value="1 hour">1 hour</option>
                                        {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((e) => {
                                            return (<option value={`${e} hours`}>{e} hours</option>)
                                        })}
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>)}
                            </div>
                            <div className='save-button-main'>
                                <button onClick={this.saveGlobalSettings.bind(this)}>OPSLAAN</button>
                            </div>
                        </div>
                    </div>
                </div>) : this.state.settingsPopup && (<div className='right-menu-main-settings rmms-popups-morewidth'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>SETTINGS</span>
                            <CloseIcon onClick={() => {
                                document.querySelector("html").style.overflowY = "auto"
                                this.setState({ settingsPopup: false })
                            }} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            {/* {this.state.itemsToShow == 1 ? (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label className="dolabelsuppercase">Afbeelding banner</label>
                                <div className='wrapper-select-new'>
                                    <input
                                        type="text"
                                        value={this.state.uploadedBig ? "Uploaded" : this.state.uploadingBig ? "Uploading" : "Upload foto"}
                                        disabled
                                        style={{
                                            fontSize: 13,
                                            letterSpacing: 1,
                                            backgroundColor: "white",
                                        }}
                                        className="dolabelsuppercase inputplaceholderbold"
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => this.uploadBannerImage(e, "big")}
                                        accept="image/*"
                                        multiple
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "80%",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            opacity: 0,
                                        }}
                                    />
                                    <PublishIcon
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: 5,
                                            bottom: (this.state.productImageAlert || this.state.productImageAlertMax) ? 37 : 10,
                                            fontSize: 18,
                                            pointerEvents:
                                                this.state.productImageType ===
                                                "uploadfromcomp" && "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>) : (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label className="dolabelsuppercase">Afbeelding banner</label>
                                <div className='wrapper-select-new'>
                                    <input
                                        type="text"
                                        value={this.state.uploadedSmall ? "Uploaded" : this.state.uploadingSmall ? "Uploading" : "Upload foto"}
                                        disabled
                                        style={{
                                            fontSize: 13,
                                            letterSpacing: 1,
                                            backgroundColor: "white",
                                        }}
                                        className="dolabelsuppercase inputplaceholderbold"
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => this.uploadBannerImage(e, "small")}
                                        accept="image/*"
                                        multiple
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "80%",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            opacity: 0,
                                        }}
                                    />
                                    <PublishIcon
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: 5,
                                            bottom: (this.state.productImageAlert || this.state.productImageAlertMax) ? 37 : 10,
                                            fontSize: 18,
                                            pointerEvents:
                                                this.state.productImageType ===
                                                "uploadfromcomp" && "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>)} */}
                            {/* {this.state.itemsToShow == 1 ? (<div className="image-campaigns-new">
                                <div
                                    className="image-sfcplp"
                                    style={{
                                        backgroundImage: `url("${this.state.intervalBigBannerImage}")`,
                                    }}
                                >
                                </div>
                                <div
                                    className="editimageupload"
                                    onClick={() => {
                                        let uid = this.props.currentMemberData.user_id;

                                        firebase
                                            .database()
                                            .ref("users/" + uid + "/interval_big_banner_image")
                                            .remove();

                                        this.setState({
                                            intervalBigBannerImage: "",
                                        });
                                    }}
                                >
                                    <DeleteIcon />
                                </div>
                            </div>) : (<div className="image-campaigns-new">
                                <div
                                    className="image-sfcplp"
                                    style={{
                                        backgroundImage: `url("${this.state.intervalSmallBannerImage}")`,
                                    }}
                                >
                                </div>
                                <div
                                    className="editimageupload"
                                    onClick={() => {
                                        let uid = this.props.currentMemberData.user_id;

                                        firebase
                                            .database()
                                            .ref("users/" + uid + "/interval_small_banner_image")
                                            .remove();

                                        this.setState({
                                            intervalSmallBannerImage: "",
                                        });
                                    }}
                                >
                                    <DeleteIcon />
                                </div>
                            </div>)} */}
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Globale omschrijving
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ globalDescriptionInfo: !this.state.globalDescriptionInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.globalDescriptionInfo ? 1 : 0, pointerEvents: this.state.globalDescriptionInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ globalDescriptionInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Voeg een algemene productomschrijving toe. Op deze manier voorkom
                                            je dat er informatie ontbreekt bij producten die zijn geimporteerd.
                                        </label>
                                    </div>
                                </label>
                                <div className='wrapper-select-new'>
                                    <input value={this.state.globalDesc} onChange={(e) => {
                                        this.setState({ globalDesc: e.target.value })
                                    }} />
                                </div>
                                {this.state.globalDescAlert && (
                                    <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                )}
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Verzend en retourinformatie
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ deliveryNoteInfo: !this.state.deliveryNoteInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.deliveryNoteInfo ? 1 : 0, pointerEvents: this.state.deliveryNoteInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ deliveryNoteInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Geef extra informatie over verzendtijden en retouren.
                                        </label>
                                    </div>
                                </label>
                                <div className='wrapper-select-new'>
                                    <input value={this.state.deliveryNote} onChange={(e) => {
                                        this.setState({ deliveryNote: e.target.value })
                                    }} />
                                </div>
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Waarom gratis
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ freeItemTextInfo: !this.state.freeItemTextInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.freeItemTextInfo ? 1 : 0, pointerEvents: this.state.freeItemTextInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ freeItemTextInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Free Item Text
                                        </label>
                                    </div>
                                </label>
                                <div className='wrapper-select-new'>
                                    <input value={this.state.freeItemText} onChange={(e) => {
                                        this.setState({ freeItemText: e.target.value })
                                    }} />
                                </div>
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Doelgroep
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ globalGenderInfo: !this.state.globalGenderInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.globalGenderInfo ? 1 : 0, pointerEvents: this.state.globalGenderInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ globalGenderInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Heb je geen doelgroep veld bij je productenimport, maar
                                            zijn alle producten wel voor een bepaalde doelgroep?
                                            Dan kun je deze hier selecten.
                                        </label>
                                    </div>
                                </label>
                                <div className='wrapper-select-new'>
                                    <select
                                        value={this.state.globalGender[0]}
                                        onChange={(e) => {
                                            this.setState({ globalGender: [e.target.value] })
                                        }}>
                                        {this.state.globalGender.length === 0 && (
                                            <option value="" disabled selected hidden>
                                                Selecteer
                                            </option>
                                        )}
                                        <option value="dames">DAMES</option>
                                        <option value="heren">HEREN</option>
                                        <option value="kinderen">KINDEREN</option>
                                        <option value="unisex">UNISEX</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <label>Promotie banner</label>
                                    <div className='switch-icon-wrapper swi-smallone' style={{ marginBottom: 15, position: "relative" }}>
                                        <Switch
                                            color="primary"
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            checked={this.state.announcementEnabled}
                                            onClick={() => {
                                                let uid = this.props.currentMemberData.user_id;
                                                if (this.state.currentUserHere.announcement) {
                                                    if (this.state.announcementEnabled) {
                                                        firebase.database().ref(`users/${uid}/announcement/active`).set(false)
                                                    } else {
                                                        firebase.database().ref(`users/${uid}/announcement/active`).set(true)
                                                    }
                                                }
                                                this.setState({ announcementEnabled: !this.state.announcementEnabled })
                                            }}
                                        />

                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ announcementBannerInfo: !this.state.announcementBannerInfo })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.announcementBannerInfo ? 1 : 0, pointerEvents: this.state.announcementBannerInfo ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ announcementBannerInfo: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Met een announcement banner kun je een vooraankondiging tonen met een tijd en datum
                                                wanneer de producten producten getoond gaan worden op jullie productenpagina.
                                                De banner is voorzien van een aftelperiode. Wanneer deze periode is bereikt, verdwijnt
                                                automatisch de banner op het scherm van de gebruiker en worden de producten getoond.
                                            </label>
                                        </div>
                                        {/* <div className='green-round-info'>?</div> */}
                                    </div>
                                </div>
                                {/* <div className='green-round-info'>?</div> */}
                                {this.state.announcementEnabled && (<p className='paragraphasfield' style={{ position: "relative" }}
                                    onClick={() => this.setState({ announcementBannerSettings: true })}
                                >
                                    Settings
                                    {/* <div className='switch-icon-wrapper'>
                                        <div className='green-round-info gri-svg'>
                                            <MdAdd />
                                            <BsCheck2 />
                                        </div>
                                    </div> */}
                                    <FiChevronRight
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </p>)}
                            </div>
                            {this.state.isAdminSettings ? (<>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label style={{ position: "relative" }}>
                                        Max Products To Show
                                    </label>
                                    <div className='wrapper-select-new'>
                                        <select
                                            value={this.state.maxProductsToShow}
                                            onChange={(e) => {
                                                this.setState({ maxProductsToShow: e.target.value })
                                            }}
                                        >
                                            {[4, 8, 16, 24, 999999999].map((e) => {
                                                return (<option value={e} key={Math.random()}>{e === 999999999 ? "Unlimited" : e}</option>)
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label style={{ position: "relative" }}>
                                        Interval Timer
                                    </label>
                                    <div className='wrapper-select-new'>
                                        <select
                                            value={this.state.intervalTimer}
                                            onChange={(e) => {
                                                this.setState({ intervalTimer: e.target.value })
                                            }}
                                        >
                                            {!this.state.intervalTimer && (<option value="" disabled hidden>Selecteer</option>)}
                                            {[10, 20, 30, 40, 50].map((e) => {
                                                return (<option value={`${e} seconds`}>{e} seconds</option>)
                                            })}
                                            <option value="1 minute">1 minute</option>
                                            {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                                                return (<option value={`${e} minutes`}>{e} minutes</option>)
                                            })}
                                            <option value="1 hour">1 hour</option>
                                            {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((e) => {
                                                return (<option value={`${e} hours`}>{e} hours</option>)
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                            </>) : (<>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label style={{ position: "relative" }}>
                                        Max Products To Show
                                    </label>
                                    <div className='wrapper-select-new'>
                                        <select
                                            value={this.state.maxProductsToShow}
                                            onChange={(e) => {
                                                this.setState({ maxProductsToShow: e.target.value })
                                            }}
                                        >
                                            {[4, 8, 16, 24, 999999999].map((e) => {
                                                return (<option value={e} key={Math.random()}>{e === 999999999 ? "Unlimited" : e}</option>)
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                            </>)}
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label style={{ position: "relative" }}>
                                    Items p.p maand
                                </label>
                                <div className='wrapper-select-new'>
                                    <select
                                        value={this.state.itemsMaxProductCart}
                                        onChange={(e) => {
                                            this.setState({ itemsMaxProductCart: e.target.value })
                                        }}
                                    >
                                        {["unlimited", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                                            return (<option value={e} key={Math.random()}>{e}</option>)
                                        })}
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            <div className='save-button-main'>
                                <button onClick={this.saveCurrentData.bind(this)}>OPSLAAN</button>
                            </div>
                            {/* <div className='buttons-switch-editproduct'>
                                <Switch
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    checked={this.state.productsAsExclusive}
                                    onClick={() => {
                                        let userid = this.props.currentMemberData.user_id
                                        firebase.database().ref(`users/${userid}/productsAsExclusive`).set(!this.state.productsAsExclusive)
                                        this.setState({ productsAsExclusive: !this.state.productsAsExclusive })
                                    }}
                                />
                                <label>PRODUCTS AS EXCLUSIVE</label>
                            </div>
                            <div className='buttons-switch-editproduct'>
                                <Switch
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    checked={this.state.productsAsCarousel}
                                    onClick={() => {
                                        let userid = this.props.currentMemberData.user_id
                                        firebase.database().ref(`users/${userid}/productsAsCarousel`).set(!this.state.productsAsCarousel)
                                        this.setState({ productsAsCarousel: !this.state.productsAsCarousel })
                                    }}
                                />
                                <label>PRODUCTS CAROUSEL</label>
                            </div>
                            {this.state.productsAsCarousel && (<div className='buttons-switch-editproduct' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                <label>PRODUCTS CAROUSEL</label>
                                <div className='carousel-main-slider'>
                                    <select defaultValue={this.state.productsTimer} onChange={(e) => {
                                        let userid = this.props.currentMemberData.user_id
                                        firebase.database().ref(`users/${userid}/productsTimer`).set(e.target.value)
                                    }}>
                                        {!this.state.productsTimer && (<option value="" disabled hidden>Selecteer</option>)}
                                        <option value="1 minute">1 minute</option>
                                        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                                            return (<option value={`${e} minutes`}>{e} minutes</option>)
                                        })}
                                        <option value="1 hour">1 hour</option>
                                        {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((e) => {
                                            return (<option value={`${e} hours`}>{e} hours</option>)
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>)}
                            <div className='buttons-switch-editproduct' style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ guestEditor: true })
                            }}>
                                <BiEdit />
                                <label>EXCLUSIVE EDITOR</label>
                            </div> */}
                        </div>
                    </div>
                </div>)}
                {this.state.companySettingsPopup && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood newrightmenucompaniesname'>
                        <div className='header-rmmi-rmms'>
                            <span>COMPANY</span>
                            <CloseIcon onClick={() => {
                                this.setState({ companySettingsPopup: false })
                                document.querySelector("html").style.overflowY = "auto"
                            }} />
                        </div>
                        <div className="search-rm-main">
                            <div className="wrapper-select-new">
                                <input type="text" placeholder="Zoek in je vriendenlijst"
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            let filtered = this.state.allCompaniesForProductsDuplicate.filter((t) => {
                                                return t.company_name.toLowerCase().includes(e.target.value.toLowerCase())
                                            })
                                            if (filtered.length > 0) {
                                                this.setState({ allCompaniesForProductsFilters: filtered, noFiltersCompanies: false })
                                            } else {
                                                this.setState({ allCompaniesForProductsFilters: [], noFiltersCompanies: true })
                                            }
                                        } else {
                                            this.setState({ allCompaniesForProductsFilters: [], noFiltersCompanies: false, allCompaniesForProducts: this.state.allCompaniesForProductsDuplicate })
                                        }
                                    }}
                                />
                                <SearchIcon
                                    className="expand-more"
                                />
                            </div>
                        </div>
                        <div className='content-rmmi-rmms contentrmirmms-newcompanies'>
                            {this.state.noFiltersCompanies ? (<label>
                                No Filters Found
                            </label>) : this.state.allCompaniesForProductsFilters.length > 0 ? this.state.allCompaniesForProductsFilters.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.props.currentMemberData.user_id === t.user_id} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            }) : this.state.allCompaniesForProducts.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.props.currentMemberData.user_id === t.user_id} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            })}
                            <div className='save-button-main' style={{ marginTop: 40 }} onClick={() => {
                                let allElements = document.getElementsByName("companyselection")
                                if (allElements) {
                                    // Initialize a variable to store the selected value
                                    let selectedValue = "";

                                    // Loop through the radio buttons to find the selected one
                                    for (const radioButton of allElements) {
                                        if (radioButton.checked) {
                                            // Set the selected value to the value of the checked radio button
                                            selectedValue = radioButton.value;
                                            break; // Exit the loop once a checked radio button is found
                                        }
                                    }

                                    // Display the selected value (you can modify this part as needed)
                                    if (selectedValue !== "") {
                                        document.querySelector("html").style.overflowY = "auto"
                                        this.props.changeCompany(selectedValue)
                                    }
                                }
                            }}>
                                <button>OPSLAAN</button>
                            </div>
                        </div>
                    </div>
                </div>)}
                {this.state.companySettingsPopupLandingPage && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood newrightmenucompaniesname'>
                        <div className='header-rmmi-rmms'>
                            <span>COMPANY</span>
                            <CloseIcon onClick={() => {
                                this.setState({ companySettingsPopupLandingPage: false })
                                document.querySelector("html").style.overflowY = "auto"
                            }} />
                        </div>
                        <div className="search-rm-main">
                            <div className="wrapper-select-new">
                                <input type="text" placeholder="Zoek in je vriendenlijst"
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            let filtered = this.state.allCompaniesForProductsDuplicateLandingPage.filter((t) => {
                                                return t.company_name.toLowerCase().includes(e.target.value.toLowerCase())
                                            })
                                            if (filtered.length > 0) {
                                                this.setState({ allCompaniesForProductsFiltersLandingPage: filtered, noFiltersCompaniesLandingPage: false })
                                            } else {
                                                this.setState({ allCompaniesForProductsFiltersLandingPage: [], noFiltersCompaniesLandingPage: true })
                                            }
                                        } else {
                                            this.setState({ allCompaniesForProductsFiltersLandingPage: [], noFiltersCompaniesLandingPage: false, allCompaniesForProductsLandingPage: this.state.allCompaniesForProductsDuplicateLandingPage })
                                        }
                                    }}
                                />
                                <SearchIcon
                                    className="expand-more"
                                />
                            </div>
                        </div>
                        <div className='content-rmmi-rmms contentrmirmms-newcompanies'>
                            {this.state.noFiltersCompanies ? (<label>
                                No Filters Found
                            </label>) : this.state.allCompaniesForProductsFiltersLandingPage.length > 0 ? this.state.allCompaniesForProductsFiltersLandingPage.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselectionlandingpage' value={t.user_id} defaultChecked={this.state.company_on_landing_page === t.user_id} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            }) : this.state.allCompaniesForProductsLandingPage.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselectionlandingpage' value={t.user_id} defaultChecked={this.state.company_on_landing_page === t.user_id} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            })}
                            <div className='save-button-main' style={{ marginTop: 40 }} onClick={() => {
                                let allElements = document.getElementsByName("companyselectionlandingpage")
                                if (allElements) {
                                    // Initialize a variable to store the selected value
                                    let selectedValue = "";

                                    // Loop through the radio buttons to find the selected one
                                    for (const radioButton of allElements) {
                                        if (radioButton.checked) {
                                            // Set the selected value to the value of the checked radio button
                                            selectedValue = radioButton.value;
                                            break; // Exit the loop once a checked radio button is found
                                        }
                                    }

                                    // Display the selected value (you can modify this part as needed)
                                    if (selectedValue !== "") {
                                        document.querySelector("html").style.overflowY = "auto"
                                        firebase.database().ref(`bhodi_home/company_on_landing_page`).set(selectedValue)
                                    }
                                }
                            }}>
                                <button>OPSLAAN</button>
                            </div>
                        </div>
                    </div>
                </div>)}
                {this.state.companySettingsPopupImport && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood newrightmenucompaniesname'>
                        <div className='header-rmmi-rmms'>
                            <span>COMPANY</span>
                            <ChevronLeft onClick={() => {
                                this.setState({ importProductsPopup: true, companySettingsPopupImport: false })
                            }} />
                        </div>
                        <div className="search-rm-main">
                            <div className="wrapper-select-new">
                                <input type="text" placeholder="Zoek in je vriendenlijst"
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            let filtered = this.state.allCompaniesForProductsDuplicate.filter((t) => {
                                                return t.company_name.toLowerCase().includes(e.target.value.toLowerCase())
                                            })
                                            if (filtered.length > 0) {
                                                this.setState({ allCompaniesForProductsFilters: filtered, noFiltersCompanies: false })
                                            } else {
                                                this.setState({ allCompaniesForProductsFilters: [], noFiltersCompanies: true })
                                            }
                                        } else {
                                            this.setState({ allCompaniesForProductsFilters: [], noFiltersCompanies: false, allCompaniesForProducts: this.state.allCompaniesForProductsDuplicate })
                                        }
                                    }}
                                />
                                <SearchIcon
                                    className="expand-more"
                                />
                            </div>
                        </div>
                        <div className='content-rmmi-rmms contentrmirmms-newcompanies'>
                            {this.state.noFiltersCompanies ? (<label>
                                No Filters Found
                            </label>) : this.state.allCompaniesForProductsFilters.length > 0 ? this.state.allCompaniesForProductsFilters.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.state.currentCompanyIdForImport ? this.state.currentCompanyIdForImport === t.user_id : this.props.currentMemberData.user_id === t.user_id} onClick={() => {
                                        this.setState({ currentCompanyIdForImport: t.user_id })
                                    }} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            }) : this.state.allCompaniesForProducts.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.state.currentCompanyIdForImport ? this.state.currentCompanyIdForImport === t.user_id : this.props.currentMemberData.user_id === t.user_id} onClick={() => {
                                        this.setState({ currentCompanyIdForImport: t.user_id })
                                    }} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>)}
                {this.state.importStockPopup && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>Stock import</span>
                            <CloseIcon onClick={() => {
                                document.querySelector("html").style.overflowY = "auto"
                                this.setState({ importStockPopup: false })
                            }} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            {/* <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>
                                    Stock sheet (csv or xml)
                                    <div className='green-round-info'>?</div>
                                </label>
                                <div className='wrapper-select-new wrapper-select-green'>
                                    <input value={this.state.csvSelectedNameStock ? this.state.csvSelectedNameStock : "Select file"} />
                                    <input type="file"
                                        style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 9999, cursor: "pointer", opacity: 0 }}
                                        accept=".csv, .xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={this.fileUploadcsvStock.bind(this)}
                                    />
                                    <BiDownload
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div> */}
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Upload Type</label>
                                <div className='wrapper-select-new'>
                                    <select value={this.state.stockImportType} onChange={(e) => {
                                        this.setState({
                                            dropdownKeysStock: [],
                                            completeCSVArrayStock: [],
                                            csvSelectedNameStock: "",
                                        });

                                        this.setState({ stockImportType: e.target.value })
                                    }}>
                                        <option value="manual">File Upload</option>
                                        <option value="fileUploader">URL (XML)</option>;
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            {this.state.stockImportType === "manual" ? (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                {/* <label style={{ position: "relative" }}>
                                    Stock sheet (csv or xml)
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ stockSheetInfo: !this.state.stockSheetInfo })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.stockSheetInfo ? 1 : 0, pointerEvents: this.state.stockSheetInfo ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ stockSheetInfo: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Kies de juiste branche voor je bedrijf
                                            en ontdek meteen welke type acties je
                                            beschikbaar kunt stellen op je pagina.
                                        </label>
                                    </div>
                                </label> */}
                                <div className='wrapper-select-new'>
                                    {/* <div className='wrapper-select-new wrapper-select-green'> */}
                                    <input value={this.state.csvSelectedNameStock ? this.state.csvSelectedNameStock : "Select file"} />
                                    <input type="file"
                                        style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 9999, cursor: "pointer", opacity: 0 }}
                                        accept=".csv, .xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={this.fileUploadcsvStock.bind(this)}
                                    />
                                    <BiDownload
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>) : (
                                <>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        {/* <label>
                                            XML sheet URL
                                        </label> */}
                                        <div className='wrapper-select-new'>
                                            {/* <div className='wrapper-select-new wrapper-select-green'> */}
                                            <input value={this.state.xmlUrlShiftedStock} onChange={(e) => {
                                                if (!e.target.value.includes("http")) {
                                                    this.setState({ xmlUrlShiftedStock: `https://${e.target.value}` })
                                                } else {
                                                    this.setState({ xmlUrlShiftedStock: e.target.value })
                                                }
                                                this.setState({ xmlUrlStock: e.target.value })
                                            }} placeholder='https://' />
                                        </div>
                                    </div>
                                    <div className='save-button-process'>
                                        <button onClick={this.processXmlFromURLStock.bind(this)}>Start</button>
                                    </div>
                                </>
                            )}
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                            </div>
                            {this.state.dropdownKeysStock.length > 0 ? <>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Sku</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.skuImportFieldStock} onChange={(e) => {
                                            this.setState({ skuImportFieldStock: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeysStock.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.skuAlertStock && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Stock</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.stockImportFieldStock} onChange={(e) => {
                                            this.setState({ stockImportFieldStock: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeysStock.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.stockAlertStock && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Price</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.priceImportFieldStock} onChange={(e) => {
                                            this.setState({ priceImportFieldStock: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeysStock.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.priceAlertStock && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>
                                <div className='save-button-main' style={{ marginTop: 40 }}>
                                    {this.state.uploadingStocks ? <button>SAVING</button> : (<button onClick={this.saveProductStocks.bind(this)}>OPSLAAN</button>)}
                                </div>
                            </> : this.state.processingXmlStock ? <div className="loadMoreProducts">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <label>Processing</label>
                            </div> : this.state.errorInXmlStock && <div className="loadMoreProducts">
                                <label>Sorry, not able to process XML</label>
                            </div>}
                        </div>
                    </div>
                </div>)}
                {this.state.importImagesPopup && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>Images import</span>
                            <CloseIcon onClick={() => {
                                document.querySelector("html").style.overflowY = "auto"
                                this.setState({ importImagesPopup: false })
                            }} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Upload Type</label>
                                <div className='wrapper-select-new'>
                                    <select value={this.state.imageImportType} onChange={(e) => {
                                        this.setState({ imageImportType: e.target.value })
                                    }}>
                                        <option value="manual">Manual</option>
                                        <option value="fileUploader">File (CSV or XML)</option>;
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            {this.state.imageImportType === "fileUploader" ? (
                                <>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        {/* <label>
                                            Image sheet (csv or xml)
                                            <div className='green-round-info'>?</div>
                                        </label> */}
                                        <div className='wrapper-select-new'>
                                            {/* <div className='wrapper-select-new wrapper-select-green'> */}
                                            <input value={this.state.csvSelectedNameImage ? this.state.csvSelectedNameImage : "Select file"} />
                                            <input type="file"
                                                style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 9999, cursor: "pointer", opacity: 0 }}
                                                accept=".csv, .xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                onChange={this.fileUploadcsvImages.bind(this)}
                                            />
                                            <BiDownload
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    right: 5,
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                    </div>
                                    {this.state.dropdownKeysImages.length > 0 && <>
                                        <div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            <label>Sku</label>
                                            <div className='wrapper-select-new'>
                                                <select value={this.state.skuImportFieldImage} onChange={(e) => {
                                                    this.setState({ skuImportFieldImage: e.target.value })
                                                }}>
                                                    <option value="" disabled selected hidden>Selecteer</option>
                                                    {this.state.dropdownKeysImages.map((t) => {
                                                        return <option value={t}>{t}</option>;
                                                    })}
                                                </select>
                                                <ExpandMoreIcon
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 5,
                                                        right: 5,
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                            {this.state.skuAlertImage && (
                                                <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                            )}
                                        </div>
                                        <div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            <label>Image URL</label>
                                            <div className='wrapper-select-new'>
                                                <select value={this.state.urlImportFieldImage} onChange={(e) => {
                                                    this.setState({ urlImportFieldImage: e.target.value })
                                                }}>
                                                    <option value="" disabled selected hidden>Selecteer</option>
                                                    {this.state.dropdownKeysImages.map((t) => {
                                                        return <option value={t}>{t}</option>;
                                                    })}
                                                </select>
                                                <ExpandMoreIcon
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 5,
                                                        right: 5,
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                            {this.state.urlAlertImage && (
                                                <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                            )}
                                        </div>
                                        <div className='save-button-main' style={{ marginTop: 40 }}>
                                            {this.state.uploadingImagesDirect ? <button>SAVING</button> : (<button onClick={this.saveProductImagesFile.bind(this)}>OPSLAAN</button>)}
                                        </div>
                                    </>}
                                </>) : (<>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        {/* <label>
                                            Images (jpg or png)
                                            <div className='green-round-info'>?</div>
                                        </label> */}
                                        <div className='wrapper-select-new'>
                                            {/* <div className='wrapper-select-new wrapper-select-green'> */}
                                            <input value={this.state.productImages.length > 0 ? "Selected" : "Select files"} />
                                            <input type="file"
                                                style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 9999, cursor: "pointer", opacity: 0 }}
                                                accept="image/*"
                                                multiple
                                                onChange={this.fileUploadImages.bind(this)}
                                            />
                                            <BiDownload
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    right: 5,
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                    </div>
                                    {this.state.productImages.length > 0 && (<div className='save-button-main' style={{ marginTop: 30, marginBottom: 30 }}>
                                        {this.state.uploadingImages ? <button>SAVING</button> : (<button onClick={this.saveProductImages.bind(this)}>OPSLAAN</button>)}
                                    </div>)}
                                    {this.state.productImages.length > 0 && (<div style={{ display: "flex", flexWrap: "wrap" }} className="row-image-productsadd">
                                        {this.state.productImages.map((e, index) => {
                                            return (
                                                <div className="image-campaigns-new" style={{ width: "auto", marginRight: 15 }}>
                                                    <div
                                                        className="image-sfcplp"
                                                        style={{
                                                            backgroundImage: `url("${e}")`,
                                                        }}
                                                    >
                                                    </div>
                                                    <div
                                                        className="editimageupload"
                                                        onClick={() => {
                                                            let dropFiles = [...this.state.dropFileImages]
                                                            let productImages = [...this.state.productImages]
                                                            productImages.splice(index, 1)
                                                            dropFiles.splice(index, 1)
                                                            this.setState({ dropFileImages: dropFiles, productImages })
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>)}
                                </>)}
                        </div>
                    </div>
                </div>)}
                {this.state.attributeSelection ? (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood attributerightmenu'>
                        <div className='header-rmmi-rmms'>
                            <span>Attributes</span>
                            <ChevronLeft onClick={() => this.setState({ attributeSelection: false })} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            {this.state.keysAttribute.length > 0 && this.state.keysAttribute.map((e) => {
                                if (this.state.specialGrouping !== e.attributeDefault && this.state.titleImportField !== e.attributeDefault && this.state.descImportField !== e.attributeDefault && this.state.sizesImportField !== e.attributeDefault && this.state.sizesSkuImportField !== e.attributeDefault && this.state.genderImportField !== e.attributeDefault && this.state.skuImportField !== e.attributeDefault && this.state.categoriesImportField !== e.attributeDefault) {
                                    return (<div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        <label>
                                            {e.attributeDefault}
                                            <div className='switch-icon-wrapper'>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={e.attributeToShow}
                                                    onClick={() => {
                                                        e.attributeToShow = !e.attributeToShow
                                                        this.setState({ keysAttribute: this.state.keysAttribute })
                                                    }}
                                                />
                                            </div>
                                        </label>
                                        {e.attributeToShow && (<input type='text' placeholder='Attribute To Show' defaultValue={e.editedAttribute} onBlur={(g) => {
                                            e.editedAttribute = g.target.value
                                            this.setState({ keysAttribute: this.state.keysAttribute })
                                        }} />)}
                                    </div>)
                                }
                            })}
                        </div>
                    </div>
                </div>) : this.state.mappingGenders ? (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>Map Genders</span>
                            <ChevronLeft onClick={() => this.setState({ mappingGenders: false })} />
                            {/* <CloseIcon onClick={() => this.setState({ mappingGenders: false })} /> */}
                        </div>
                        <div className='content-rmmi-rmms'>
                            {this.state.gendersToMap.length > 0 && this.state.gendersToMap.map((e) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>
                                        {e.gender}
                                    </label>
                                    <div className='wrapper-select-new wrapper-select-green'>
                                        <select
                                            value={e.mapped}
                                            onChange={(g) => {
                                                e.mapped = g.target.value
                                                this.setState({ gendersToMap: this.state.gendersToMap })
                                            }}>
                                            {!e.mapped && (
                                                <option value="" disabled selected hidden>
                                                    Selecteer
                                                </option>
                                            )}
                                            <option value="dames">DAMES</option>
                                            <option value="heren">HEREN</option>
                                            <option value="kinderen">KINDEREN</option>
                                            <option value="unisex">UNISEX</option>
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>) : this.state.mappingCategories ? (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>Map Categories</span>
                            <ChevronLeft onClick={() => this.setState({ mappingCategories: false })} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            {this.state.categoriesToMap.length > 0 && this.state.categoriesToMap.map((e) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>
                                        {e.cat}
                                    </label>
                                    <div className='wrapper-select-new wrapper-select-green'>
                                        <select
                                            value={e.mapped}
                                            onChange={(g) => {
                                                e.mapped = g.target.value
                                                this.setState({ categoriesToMap: this.state.categoriesToMap })
                                            }}>
                                            {!e.mapped && (
                                                <option value="" disabled selected hidden>
                                                    Selecteer
                                                </option>
                                            )}
                                            {this.state.all_categories.map((g) => {
                                                return <option value={g.category_id}>{g.title}</option>
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>) : this.state.mappingSizes ? (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>Map Sizes</span>
                            <ChevronLeft onClick={() => this.setState({ mappingSizes: false })} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            {this.state.sizesToMap.length > 0 && this.state.sizesToMap.map((e) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>
                                        {e.cat}
                                    </label>
                                    <div className='wrapper-select-new wrapper-select-green'>
                                        <select
                                            value={e.mapped}
                                            onChange={(g) => {
                                                e.mapped = g.target.value
                                                this.setState({ sizesToMap: this.state.sizesToMap })
                                            }}>
                                            {!e.mapped && (
                                                <option value="" disabled selected hidden>
                                                    Selecteer
                                                </option>
                                            )}
                                            {this.state.all_sizes.map((g) => {
                                                return <option value={g.title}>{g.title}</option>
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>) : this.state.importProductsPopup && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>Product import</span>
                            <CloseIcon onClick={() => {
                                document.getElementById("root").style.overflowY = "auto"
                                this.setState({
                                    importProductsPopup: false,
                                    dropdownKeys: [],
                                    csvSelectedName: "",
                                    all_categories: [],
                                    selectedMain: [],
                                    mainSector: "",
                                    titleImportField: "",
                                    descImportField: "",
                                    priceImportField: "",
                                    skuImportField: "",
                                    sizesImportField: "",
                                    sizesSkuImportField: "",
                                    categoriesImportField: "",
                                    genderImportField: "",
                                    mainSector: "",
                                    // allSectors: [],
                                    categoriesToMap: [],
                                    sizesToMap: [],
                                    gendersToMap: [],
                                    all_categories: [],
                                    all_sizes: [],
                                    completeCSVArray: [],
                                })
                            }} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>
                                    Type bedrijf
                                </label>
                                <div className='wrapper-select-new wrapper-select-green'>
                                    <select
                                        value={this.state.mainSector}
                                        onChange={(e) => {
                                            let filtered = this.state.completeCategories.filter((y) => {
                                                return y[0] === e.target.value
                                            })
                                            if (filtered.length > 0) {
                                                this.setState({ all_categories: filtered[0][1], selectedMain: [], mainSector: e.target.value })
                                            } else {
                                                this.setState({ all_categories: [], selectedMain: [], mainSector: "" })
                                            }

                                            let filteredSizes = this.state.completeSizes.filter((y) => {
                                                return y[0] === e.target.value
                                            })
                                            if (filteredSizes.length > 0) {
                                                this.setState({ all_sizes: filteredSizes[0][1] })
                                            }

                                            if (this.state.categoriesImportField) {
                                                let allCategoriesOfCsv = []
                                                this.state.completeCSVArray.map((e) => {
                                                    let currentText = this.state.selectedXml ? e[this.state.categoriesImportField]._text : e[this.state.categoriesImportField]
                                                    if (currentText && currentText !== undefined) {
                                                        let mapped = ""
                                                        if (this.state.alreadyCategoriesData.length > 0) {
                                                            let filteredInner = this.state.alreadyCategoriesData.filter((g) => {
                                                                return g.cat === currentText
                                                            })
                                                            if (filteredInner.length > 0) {
                                                                mapped = filteredInner[0].mapped
                                                            }
                                                        }
                                                        let obj = {
                                                            cat: currentText,
                                                            mapped,
                                                        }
                                                        allCategoriesOfCsv.push(obj)
                                                    }
                                                });
                                                let filteredOfCategories = allCategoriesOfCsv.filter(
                                                    (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                                                );
                                                this.setState({
                                                    categoriesToMap: filteredOfCategories,
                                                });
                                            }
                                        }}>
                                        {!this.state.mainSector && (
                                            <option value="" disabled selected hidden>
                                                Selecteer
                                            </option>
                                        )}
                                        {this.state.allSectors.map((g) => {
                                            return <option value={g.sector_id}>{g.sector_name}</option>
                                        })}
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                                {this.state.mainSectorAlert && (
                                    <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                )}
                            </div>
                            {this.state.mainSector && (<>
                                {this.props.currentMemberData.user_id === process.env.REACT_APP_ADMIN_ID && (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                    onClick={() => {
                                        if (this.props.user) {
                                            let filtered = this.props.user.filter((g) => {
                                                return g.type === "company"
                                            })
                                            if (filtered.length > 0) {
                                                this.setState({ allCompaniesForProducts: filtered, allCompaniesForProductsDuplicate: filtered })
                                            }
                                        }

                                        this.setState({ companySettingsPopupImport: true, selectingCompanyForImport: true, importProductsPopup: false, })
                                    }}
                                >
                                    <div className='wrapper-select-new'>
                                        <input value="Select Company" readOnly />
                                        <BiChevronRight
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>)}
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Upload Type</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.productImportType} onChange={(e) => {
                                            this.setState({
                                                dropdownKeys: [],
                                                completeCSVArray: [],
                                                keysAttribute: [],
                                                csvSelectedName: "",
                                            });

                                            this.setState({ productImportType: e.target.value })
                                        }}>
                                            <option value="manual">File Upload</option>
                                            <option value="fileUploader">URL (XML)</option>;
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                                {this.state.productImportType === "manual" ? (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    {/* <label style={{ position: "relative" }}>
                                        Product sheet (csv or xml)
                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ productSheetInfo: !this.state.productSheetInfo })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.productSheetInfo ? 1 : 0, pointerEvents: this.state.productSheetInfo ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ productSheetInfo: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Kies de juiste branche voor je bedrijf
                                                en ontdek meteen welke type acties je
                                                beschikbaar kunt stellen op je pagina.
                                            </label>
                                        </div>
                                    </label> */}
                                    <div className='wrapper-select-new'>
                                        <input value={this.state.csvSelectedName ? this.state.csvSelectedName : "Select file"} />
                                        <input type="file"
                                            style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 9999, cursor: "pointer", opacity: 0 }}
                                            accept=".csv, .xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={this.fileUploadcsv.bind(this)}
                                        />
                                        <BiDownload
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>) : (
                                    <>
                                        <div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            {/* <label>
                                                XML sheet URL
                                            </label> */}
                                            <div className='wrapper-select-new'>
                                                {/* <div className='wrapper-select-new wrapper-select-green'> */}
                                                <input value={this.state.xmlUrlShifted} onChange={(e) => {
                                                    if (!e.target.value.includes("http")) {
                                                        this.setState({ xmlUrlShifted: `https://${e.target.value}` })
                                                    } else {
                                                        this.setState({ xmlUrlShifted: e.target.value })
                                                    }
                                                    this.setState({ xmlUrl: e.target.value })
                                                }} placeholder='https://' />
                                            </div>
                                        </div>
                                        <div className='save-button-process'>
                                            <button onClick={this.processXmlFromURL.bind(this)}>Start</button>
                                        </div>
                                    </>
                                )}
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                </div>
                            </>)}
                            {this.state.dropdownKeys.length > 0 ? <>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Title</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.titleImportField} onChange={(e) => {
                                            this.setState({ titleImportField: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeys.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.titleAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Description</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.descImportField} onChange={(e) => {
                                            this.setState({ descImportField: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeys.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.descAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>
                                {/* <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label style={{ position: "relative" }}>
                                        Global Description
                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ globalDescriptionInfo: !this.state.globalDescriptionInfo })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.globalDescriptionInfo ? 1 : 0, pointerEvents: this.state.globalDescriptionInfo ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ globalDescriptionInfo: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Kies de juiste branche voor je
                                                en ontdek meteen welke type acties je
                                                kunt stellen op je pagina.
                                            </label>
                                        </div>
                                    </label>
                                    <div className='wrapper-select-new'>
                                        <input value={this.state.globalDesc} onChange={(e) => {
                                            this.setState({ globalDesc: e.target.value })
                                        }} />
                                    </div>
                                    {this.state.globalDescAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div> */}
                                {/* <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Price</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.priceImportField} onChange={(e) => {
                                            this.setState({ priceImportField: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeys.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.priceAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div> */}
                                <div className='row-form-miur'>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        <label>Sku</label>
                                        <div className='wrapper-select-new'>
                                            <select value={this.state.skuImportField} onChange={(e) => {
                                                this.setState({ skuImportField: e.target.value })
                                            }}>
                                                <option value="" disabled selected hidden>Selecteer</option>
                                                {this.state.dropdownKeys.map((t) => {
                                                    return <option value={t}>{t}</option>;
                                                })}
                                            </select>
                                            <ExpandMoreIcon
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    right: 5,
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                        {this.state.skuAlert && (
                                            <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                        )}
                                    </div>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        <label style={{ position: "relative" }}>
                                            Variable sku
                                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                this.setState({ variableSkuInfo: !this.state.variableSkuInfo })
                                            }}>
                                                <font>?</font>
                                            </div>
                                            <div className="popup-gri-thifc" style={{ opacity: this.state.variableSkuInfo ? 1 : 0, pointerEvents: this.state.variableSkuInfo ? "all" : "none" }}>
                                                <CloseIcon onClick={() => this.setState({ variableSkuInfo: false })} />
                                                <label style={{ fontSize: 15 }}>
                                                    Kies de juiste branche voor je bedrijf
                                                    en ontdek meteen welke type acties je
                                                    beschikbaar kunt stellen op je pagina.
                                                </label>
                                            </div>
                                        </label>
                                        <div className='wrapper-select-new'>
                                            <select value={this.state.sizesSkuImportField} onChange={(e) => {
                                                this.setState({ sizesSkuImportField: e.target.value })
                                            }}>
                                                <option value="" disabled selected hidden>Selecteer</option>
                                                {this.state.dropdownKeys.map((t) => {
                                                    return <option value={t}>{t}</option>;
                                                })}
                                            </select>
                                            <ExpandMoreIcon
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    right: 5,
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                        {this.state.sizeSkuAlert && (
                                            <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label style={{ position: "relative" }}>
                                        Group Based on
                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ groupBasedOnInfo: !this.state.groupBasedOnInfo })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.groupBasedOnInfo ? 1 : 0, pointerEvents: this.state.groupBasedOnInfo ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ groupBasedOnInfo: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Kies de juiste branche voor je
                                                en ontdek meteen welke type acties je
                                                kunt stellen op je pagina.
                                            </label>
                                        </div>
                                    </label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.specialGrouping} onChange={(e) => {
                                            this.setState({ specialGrouping: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeys.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <BiChevronRight
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                                <div className='row-form-miur'>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        <label>Sizes</label>
                                        <div className='wrapper-select-new'>
                                            <select value={this.state.sizesImportField} onChange={(e) => {
                                                this.setState({ sizesImportField: e.target.value })
                                            }}>
                                                <option value="" disabled selected hidden>Selecteer</option>
                                                {this.state.dropdownKeys.map((t) => {
                                                    return <option value={t}>{t}</option>;
                                                })}
                                            </select>
                                            <ExpandMoreIcon
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    right: 5,
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                        {this.state.sizesAlert && (
                                            <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                        )}
                                    </div>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative" }}
                                    >
                                        <label>Gender</label>
                                        <div className='wrapper-select-new'>
                                            <select value={this.state.genderImportField} onChange={(e) => {
                                                this.setState({ genderImportField: e.target.value })

                                                let allGendersOfCsv = []
                                                this.state.completeCSVArray.map((g) => {
                                                    let obj = {
                                                        gender: g[e.target.value],
                                                        mapped: ""
                                                    }
                                                    allGendersOfCsv.push(obj)
                                                });
                                                let filteredOfGenders = allGendersOfCsv.filter(
                                                    (v, i, a) => a.findIndex((t) => t.gender === v.gender) === i
                                                );
                                                this.setState({
                                                    gendersToMap: filteredOfGenders,
                                                });
                                            }}>
                                                <option value="" disabled selected hidden>Selecteer</option>
                                                {this.state.dropdownKeys.map((t) => {
                                                    return <option value={t}>{t}</option>;
                                                })}
                                            </select>
                                            <ExpandMoreIcon
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    right: 5,
                                                    pointerEvents: "none",
                                                }}
                                                className='expand-more'
                                            />
                                        </div>
                                        {this.state.genderAlert && (
                                            <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                        )}
                                    </div>
                                </div>
                                {this.state.sizesToMap.length > 0 && (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                    onClick={() => {
                                        this.setState({ mappingSizes: true })
                                        setTimeout(() => {
                                            document.querySelector(".rightmenuinner-rmmms").scrollTo(0, 0)
                                        }, 100);
                                    }}
                                >
                                    {/* <label>Map Genders</label> */}
                                    <div className='wrapper-select-new'>
                                        <input value="Mapping Sizes" readOnly />
                                        <BiChevronRight
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.mapSizesAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>)}
                                {this.state.gendersToMap.length > 0 && (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                    onClick={() => {
                                        this.setState({ mappingGenders: true })
                                        setTimeout(() => {
                                            document.querySelector(".rightmenuinner-rmmms").scrollTo(0, 0)
                                        }, 100);
                                    }}
                                >
                                    {/* <label>Map Genders</label> */}
                                    <div className='wrapper-select-new'>
                                        <input value="Mapping" readOnly />
                                        <BiChevronRight
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.mapGendersAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>)}
                                {/* <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label style={{ position: "relative" }}>
                                        Global Gender
                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ globalGenderInfo: !this.state.globalGenderInfo })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.globalGenderInfo ? 1 : 0, pointerEvents: this.state.globalGenderInfo ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ globalGenderInfo: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Kies de juiste branche voor je
                                                en ontdek meteen welke type acties je
                                                kunt stellen op je pagina.
                                            </label>
                                        </div>
                                    </label>
                                    <div className='wrapper-select-new'>
                                        <select
                                            value={this.state.globalGender[0]}
                                            onChange={(e) => {
                                                this.setState({ globalGender: [e.target.value] })
                                            }}>
                                            {this.state.globalGender.length === 0 && (
                                                <option value="" disabled selected hidden>
                                                    Selecteer
                                                </option>
                                            )}
                                            <option value="dames">DAMES</option>
                                            <option value="heren">HEREN</option>
                                            <option value="kinderen">KINDEREN</option>
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div> */}
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Categories</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.categoriesImportField} onChange={(e) => {
                                            this.setState({ categoriesImportField: e.target.value })

                                            let allCategoriesOfCsv = []
                                            this.state.completeCSVArray.map((g) => {
                                                let currentText = this.state.selectedXml ? g[e.target.value]._text : g[e.target.value]
                                                if (currentText && currentText !== undefined) {
                                                    let mapped = ""
                                                    if (this.state.alreadyCategoriesData.length > 0) {
                                                        let filteredInner = this.state.alreadyCategoriesData.filter((g) => {
                                                            return g.cat === currentText
                                                        })
                                                        if (filteredInner.length > 0) {
                                                            mapped = filteredInner[0].mapped
                                                        }
                                                    }
                                                    let obj = {
                                                        cat: currentText,
                                                        mapped,
                                                    }
                                                    allCategoriesOfCsv.push(obj)
                                                }
                                            });
                                            let filteredOfCategories = allCategoriesOfCsv.filter(
                                                (v, i, a) => a.findIndex((t) => t.cat === v.cat) === i
                                            );
                                            this.setState({
                                                categoriesToMap: filteredOfCategories,
                                            });
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeys.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <BiChevronRight
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.categoriesAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>
                                {this.state.mainSector && this.state.categoriesToMap.length > 0 && (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                    onClick={() => {
                                        this.setState({ mappingCategories: true })
                                        setTimeout(() => {
                                            document.querySelector(".rightmenuinner-rmmms").scrollTo(0, 0)
                                        }, 100);
                                    }}
                                >
                                    {/* <label>Map categories</label> */}
                                    <div className='wrapper-select-new'>
                                        <input value="Mapping" readOnly />
                                        <BiChevronRight
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.mapCategoriesAlert && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>)}
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                    onClick={() => {
                                        this.setState({ attributeSelection: true })
                                        setTimeout(() => {
                                            document.querySelector(".rightmenuinner-rmmms").scrollTo(0, 0)
                                        }, 100);
                                    }}
                                >
                                    <label>
                                        Attributes
                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ attributesInfo: !this.state.attributesInfo })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.attributesInfo ? 1 : 0, pointerEvents: this.state.attributesInfo ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ attributesInfo: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Kies de juiste branche voor je
                                                en ontdek meteen welke type acties je
                                                kunt stellen op je pagina.
                                            </label>
                                        </div>
                                    </label>
                                    <div className='wrapper-select-new'>
                                        <input value="Selecteer" readOnly />
                                        <BiChevronRight
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                                {this.state.savingProductsImport ? (<div className='save-button-main' style={{ marginTop: 40 }}>
                                    <button>SAVING...</button>
                                </div>) : (<div className='save-button-main' style={{ marginTop: 40 }}>
                                    <button onClick={this.saveProductImport.bind(this)}>OPSLAAN</button>
                                </div>)}
                            </> : this.state.processingXml ? <div className="loadMoreProducts">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <label>Processing</label>
                            </div> : this.state.errorInXml && <div className="loadMoreProducts">
                                <label>Sorry, not able to process XML</label>
                            </div>}
                        </div>
                    </div>
                </div>)}
                {this.state.editScreenOpen && (<div className="edit-screen-products" style={{ width: window.innerWidth < 1008 && "100%", paddingTop: 40 }}>
                    <div className='top-edit-screen-products'>
                        <CloseIcon onClick={() => this.setState({ editScreenOpen: false })} />
                        <label>ADJUSTMENTS</label>
                    </div>
                    <div className='buttons-top-main' style={{ justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                        <div style={{ display: "flex" }}>
                            <button style={{ padding: 0, backgroundColor: "transparent", color: "black", textAlign: window.innerWidth < 1008 && "left" }}>Total: {this.state.allProducts.length}</button>
                            {window.innerWidth > 1008 && (<div className='input-btm-products'>
                                <input type='text' placeholder='Search Products' onChange={(e) => {
                                    if (e.target.value) {
                                        let filtered = [...this.state.allProducts].filter((g) => {
                                            return g.title.toLowerCase().includes(e.target.value.toLowerCase()) || g.sku.toLowerCase().includes(e.target.value.toLowerCase())
                                        })
                                        if (filtered.length > 0) {
                                            this.setState({
                                                filteredProducts: filtered,
                                                defaultLoadFiltered: 20,
                                                noFilteredProducts: false,
                                            })
                                        } else {
                                            this.setState({
                                                noFilteredProducts: true,
                                            })
                                        }
                                    } else {
                                        this.setState({
                                            noFilteredProducts: false,
                                            filteredProducts: [],
                                        })
                                    }
                                }} />
                            </div>)}
                        </div>
                        {window.innerWidth < 1008 && (<div className='input-btm-products' style={{ width: "100%", marginTop: 15 }}>
                            <input type='text' style={{ width: "100%" }} placeholder='Search Products' onChange={(e) => {
                                if (e.target.value) {
                                    let filtered = [...this.state.allProducts].filter((g) => {
                                        return g.title.toLowerCase().includes(e.target.value.toLowerCase()) || g.sku.toLowerCase().includes(e.target.value.toLowerCase())
                                    })
                                    if (filtered.length > 0) {
                                        this.setState({
                                            filteredProducts: filtered,
                                            defaultLoadFiltered: 20,
                                            noFilteredProducts: false,
                                        })
                                    } else {
                                        this.setState({
                                            noFilteredProducts: true,
                                        })
                                    }
                                } else {
                                    this.setState({
                                        noFilteredProducts: false,
                                        filteredProducts: [],
                                    })
                                }
                            }} />
                        </div>)}
                        <div style={{ display: "flex", overflowX: window.innerWidth < 1008 && "auto" }} className='scroller-button-mobile'>
                            {window.innerWidth > 1008 && (<button onClick={() => this.setState({ deletingAllProduct: true })}>DELETE ALL PRODUCTS</button>)}
                            {/* {window.innerWidth > 1008 && (<div className='input-btm-products'>
                                <input type='text' placeholder='Search Products' onChange={(e) => {
                                    if (e.target.value) {
                                        let filtered = [...this.state.allProducts].filter((g) => {
                                            return g.title.toLowerCase().includes(e.target.value.toLowerCase()) || g.sku.toLowerCase().includes(e.target.value.toLowerCase())
                                        })
                                        if (filtered.length > 0) {
                                            this.setState({
                                                filteredProducts: filtered,
                                                defaultLoadFiltered: 20,
                                                noFilteredProducts: false,
                                            })
                                        } else {
                                            this.setState({
                                                noFilteredProducts: true,
                                            })
                                        }
                                    } else {
                                        this.setState({
                                            noFilteredProducts: false,
                                            filteredProducts: [],
                                        })
                                    }
                                }} />
                            </div>)} */}
                            {window.innerWidth < 1008 && (<button onClick={() => this.setState({ deletingAllProduct: true })}>DELETE ALL PRODUCTS</button>)}
                            <button onClick={() => {
                                if (window.innerWidth > 1008) {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({ importStockPopup: true })
                                } else {
                                    this.setState({ showOnlyDesktop: true })
                                }
                            }}>STOCK IMPORT</button>
                            <button onClick={() => {
                                if (window.innerWidth > 1008) {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({ importImagesPopup: true, imageImportType: "manual" })
                                } else {
                                    this.setState({ showOnlyDesktop: true })
                                }
                            }}>IMAGES IMPORT</button>
                            <button onClick={() => {
                                if (window.innerWidth > 1008) {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({ importProductsPopup: true, productImportType: "manual" })
                                } else {
                                    this.setState({ showOnlyDesktop: true })
                                }
                            }}>PRODUCT IMPORT</button>
                            <button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                if (this.props.user) {
                                    let filtered = this.props.user.filter((g) => {
                                        return g.type === "company"
                                    })
                                    if (filtered.length > 0) {
                                        this.setState({ allCompaniesForProducts: filtered, allCompaniesForProductsDuplicate: filtered })
                                    }
                                }
                                this.setState({ companySettingsPopup: true })
                            }}>COMPANY</button>
                            <button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                if (this.props.user) {
                                    let filtered = this.props.user.filter((g) => {
                                        return g.type === "company"
                                    })
                                    if (filtered.length > 0) {
                                        this.setState({ allCompaniesForProductsLandingPage: filtered, allCompaniesForProductsDuplicateLandingPage: filtered })
                                    }
                                }
                                this.setState({ companySettingsPopupLandingPage: true })
                            }}>COMPANY LP</button>
                            <button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({ settingsPopup: true })
                            }}>SETTINGS</button>
                            <button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({ addEditProductPopup: true })
                            }}>CREATE PRODUCT</button>
                            {this.props.currentMemberData.user_id === process.env.REACT_APP_ADMIN_ID ? <button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({ globalSettingsPopup: true })
                            }}>GLOBAL SETTINGS</button> : (<button className="shopofflinebutton" style={{ padding: 0, backgroundColor: "transparent", color: "black", textAlign: window.innerWidth < 1008 && "left" }}>
                                Shop Online
                                <Switch
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    checked={this.state.shopOnline}
                                    onClick={() => {
                                        let uid = this.props.currentMemberData.user_id

                                        if (this.state.shopOnline) {
                                            this.state.allProducts.map((e) => {
                                                if (e.inCartOf) {
                                                    let entries = Object.entries(e.inCartOf)
                                                    entries.map((t) => {
                                                        let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                                        let obj = {
                                                            pushKey: pushKeyNotification,
                                                            notification: `${e.sku} was deleted by company`,
                                                            created_at: new Date().getTime(),
                                                            notificationFor: t[1].cookie
                                                        }
                                                        firebase.database().ref("notifications_data/" + t[1].cookie + "/" + pushKeyNotification).set(obj)
                                                        firebase.database().ref("guest_carts/" + t[1].cookie + "/" + t[1].cartPushKey).remove()
                                                    })
                                                }
                                                firebase.database().ref("bhodi_products/" + e.pushKey + "/inCartOf").remove()
                                            })

                                            firebase.database().ref(`users/${uid}/shop_offline`).set(true)
                                        } else {
                                            firebase.database().ref(`users/${uid}/shop_offline`).set(false)
                                        }

                                        // this.setState({ shopOnline: !this.state.shopOnline })
                                    }}
                                />
                            </button>)}
                        </div>
                    </div>
                    <div className='content-edit-screen-products' style={{ overflow: window.innerWidth < 1008 && "hidden" }}>
                        <div className='table-esproducts'>
                            <div className='table-heading-esproducts'>
                                <p>Image</p>
                                <p>Title</p>
                                <p>SKU</p>
                                {/* <p>Stock</p> */}
                                <p>Stock Available</p>
                                <p>Bewerken</p>
                                <p>Verwijder</p>
                                <p>Actief</p>
                                <p>Favoriet</p>
                            </div>
                            <div className='table-content-esproducts-main'>
                                {this.state.noFilteredProducts ? <p>No available products</p> : this.state.filteredProducts.length > 0 ? [...this.state.filteredProducts].slice(0, this.state.defaultLoadFiltered).map((product, index) => {
                                    return (<div className='table-content-esproducts'>
                                        <p>
                                            <div className='bg-img' style={{ backgroundImage: (product.images && product.images.length > 0) ? `url("${product.images[product.featuredImage ? product.featuredImage : 0]}")` : `url("${process.env.REACT_APP_PLACEHOLDER_IMAGE}")` }}>

                                            </div>
                                            {/* {(product.images && product.images.length > 0) ? (<img src={product.images[product.featuredImage ? product.featuredImage : 0]} />) : <img src={process.env.REACT_APP_PLACEHOLDER_IMAGE} />} */}
                                        </p>
                                        <p style={{ textTransform: "uppercase" }}>{product.title}</p>
                                        <p>{product.sku}</p>
                                        {/* <p>{this.getAllStock(product)}</p> */}
                                        <p>{this.getAvailableStock(product)}</p>
                                        <p>
                                            <EditIcon onClick={() => {
                                                if (index === this.state.currentProductCarousel && this.state.currentUserHere.productsAsCarousel) {
                                                    this.setState({ avoidEditProduct: true })
                                                } else {
                                                    // document.getElementById("root").style.overflow = "hidden"
                                                    document.querySelector("html").style.overflowY = "hidden"
                                                    this.setState({ currentProductToEdit: product, addEditProductPopup: true })
                                                }
                                            }} />
                                            <FaLink style={{ marginLeft: 10 }} onClick={this.copyToClipboard.bind(this, product)} />
                                            <HiOutlineDuplicate style={{ marginLeft: 10 }} onClick={this.duplicateCurrentProduct.bind(this, product)} />
                                        </p>
                                        <p>
                                            <DeleteIcon onClick={() => {
                                                this.setState({ currentProductToDelete: product, deletingProduct: true })
                                            }} />
                                        </p>
                                        <p>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={product.active}
                                                // onClick={() => {
                                                //     product.active = !product.active

                                                //     this.setState({
                                                //         hello: "world",
                                                //     });

                                                //     let promiseOne = new Promise((res) => {
                                                //         let filterMyProducts = this.props.bhodi_products.filter((g) => {
                                                //             return g.created_by === product.created_by
                                                //         })
                                                //         let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                                                //             return a.sort_time * 100 - b.sort_time * 100;
                                                //         })

                                                //         let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                                                //         sortTimeLast = sortTimeLast + 8000

                                                //         if (sortedAllProducts[sortedAllProducts.length - 1].pushKey !== product.pushKey) {
                                                //             firebase.database().ref(`bhodi_products/${product.pushKey}/sort_time`).set(sortTimeLast).then(() => {
                                                //                 res()
                                                //             })
                                                //         } else {
                                                //             res()
                                                //         }
                                                //     })
                                                //     promiseOne.then(() => {
                                                //         if (!product.active) {
                                                //             if (product.inCartOf) {
                                                //                 let entries = Object.entries(product.inCartOf)
                                                //                 entries.map((t) => {
                                                //                     let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                                //                     let obj = {
                                                //                         pushKey: pushKeyNotification,
                                                //                         notification: `${product.sku} was deleted by company`,
                                                //                         created_at: new Date().getTime(),
                                                //                         notificationFor: t[1].cookie
                                                //                     }
                                                //                     firebase.database().ref("notifications_data/" + t[1].cookie + "/" + pushKeyNotification).set(obj)
                                                //                     firebase.database().ref("guest_carts/" + t[1].cookie + "/" + t[1].cartPushKey).remove()
                                                //                 })
                                                //                 firebase.database().ref("bhodi_products/" + product.pushKey + "/inCartOf").remove()
                                                //             }
                                                //         }

                                                //         firebase.database().ref("bhodi_products/" + product.pushKey + "/active").set(product.active)
                                                //     })
                                                // }}
                                                onClick={this.activeUnactiveProduct.bind(this, product)}
                                            />
                                        </p>
                                        <p>
                                            {product.is_bookmarked ? (<AiFillStar onClick={() => {
                                                this.favoriteUnfavoriteProduct(product)
                                            }} />) : (<AiOutlineStar onClick={() => {
                                                this.favoriteUnfavoriteProduct(product)
                                            }} />)}
                                        </p>
                                    </div>)
                                }) : [...this.state.allProductsDuplicated].slice(0, this.state.defaultLoad).map((product, index) => {
                                    return (<div className='table-content-esproducts'>
                                        <p>
                                            <div className='bg-img' style={{ backgroundImage: (product.images && product.images.length > 0) ? `url("${product.images[product.featuredImage ? product.featuredImage : 0]}")` : `url("${process.env.REACT_APP_PLACEHOLDER_IMAGE}")` }}>

                                            </div>
                                            {/* {(product.images && product.images.length > 0) ? (<img src={product.images[product.featuredImage ? product.featuredImage : 0]} />) : <img src={process.env.REACT_APP_PLACEHOLDER_IMAGE} />} */}
                                        </p>
                                        <p style={{ textTransform: "uppercase" }}>{product.title}</p>
                                        <p>{product.sku}</p>
                                        {/* <p>{this.getAllStock(product)}</p> */}
                                        <p>{this.getAvailableStock(product)}</p>
                                        <p>
                                            <EditIcon onClick={() => {
                                                if (index === this.state.currentProductCarousel && this.state.currentUserHere.productsAsCarousel) {
                                                    this.setState({ avoidEditProduct: true })
                                                } else {
                                                    document.querySelector("html").style.overflowY = "hidden"
                                                    // document.getElementById("root").style.overflow = "hidden"
                                                    this.setState({ currentProductToEdit: product, addEditProductPopup: true })
                                                }
                                            }} />
                                            <FaLink style={{ marginLeft: 10 }} onClick={this.copyToClipboard.bind(this, product)} />
                                            <HiOutlineDuplicate style={{ marginLeft: 10 }} onClick={this.duplicateCurrentProduct.bind(this, product)} />
                                        </p>
                                        <p>
                                            <DeleteIcon onClick={() => {
                                                this.setState({ currentProductToDelete: product, deletingProduct: true })
                                            }} />
                                        </p>
                                        {window.innerWidth < 1008 && (<p>
                                            <AiOutlineInfoCircle onClick={() => {
                                                this.setState({ currentProductToShowInfo: product, showingInfoOfProduct: true })
                                            }} />
                                        </p>)}
                                        <p>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={product.active}
                                                // onClick={() => {
                                                //     product.active = !product.active
                                                //     // this.storeThingsAtState()
                                                //     // product.active = !product.active
                                                //     this.setState({
                                                //         hello: "world",
                                                //     });

                                                //     let promiseOne = new Promise((res) => {
                                                //         let filterMyProducts = this.props.bhodi_products.filter((g) => {
                                                //             return g.created_by === product.created_by
                                                //         })
                                                //         let filterCurrentUser = this.props.user.filter((g) => {
                                                //             return g.user_id === product.created_by
                                                //         })
                                                //         if (filterCurrentUser.length > 0 && filterMyProducts.length > 0) {

                                                //             let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                                                //                 return a.sort_time * 100 - b.sort_time * 100;
                                                //             })
                                                //             let currentProductIndex = sortedAllProducts.findIndex((t) => {
                                                //                 return t.pushKey === product.pushKey
                                                //             })


                                                //             let maxProductsToShow = 4

                                                //             if (filteredUserCreator[0].productsInfo) {
                                                //                 if (filteredUserCreator[0].productsInfo.maxProductsToShow) {
                                                //                     maxProductsToShow = filteredUserCreator[0].productsInfo.maxProductsToShow
                                                //                 }
                                                //             }

                                                //             if (filteredUserCreator[0].productsInfo.intervalTimer && currentProductIndex !== -1 && currentProductIndex < maxProductsToShow && sortedAllProducts.length > maxProductsToShow) {
                                                //                 let newerProduct = sortedAllProducts[maxProductsToShow]

                                                //                 let dateCreated = new Date()
                                                //                 let splitted = filteredUserCreator[0].productsInfo.intervalTimer.split(" ")

                                                //                 if (splitted[1].includes("second")) {
                                                //                     dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                                                //                 } else if (splitted[1].includes("minute")) {
                                                //                     dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                                                //                 } else {
                                                //                     dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                                                //                 }

                                                //                 firebase.database().ref(`bhodi_products/${newerProduct.created_by}/buttonTimingToShow`).set(dateCreated.toISOString())

                                                //             }

                                                //             let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                                                //             sortTimeLast = sortTimeLast + 8000

                                                //             if (sortedAllProducts[sortedAllProducts.length - 1].pushKey !== product.pushKey) {
                                                //                 firebase.database().ref(`bhodi_products/${product.pushKey}/sort_time`).set(sortTimeLast).then(() => {
                                                //                     res()
                                                //                 })
                                                //             } else {
                                                //                 res()
                                                //             }
                                                //         } else {
                                                //             res()
                                                //         }
                                                //         // firebase.database().ref(`bhodi_products/${product.pushKey}/sort_time`).set(sortTimeLast).then(() => {
                                                //         //     res()
                                                //         // })
                                                //     })
                                                //     // let promiseOne = new Promise((res) => {
                                                //     //     if (product.active) {
                                                //     //         if (this.state.allProductsToCheckSwap.length > 0) {
                                                //     //             let findIndex = this.state.allProductsToCheckSwap.findIndex((t) => {
                                                //     //                 return t.pushKey === product.pushKey
                                                //     //             })

                                                //     //             let filtered = this.state.allIndexes.filter((t) => {
                                                //     //                 return findIndex < t
                                                //     //             })

                                                //     //             if (filtered.length > 0) {
                                                //     //                 let currentLastSort = this.state.allProductsToCheckSwap[this.state.allIndexes[0]].sort_time
                                                //     //                 let currentLastPlusOneSort = this.state.allProductsToCheckSwap[this.state.allIndexes[0] + 1].sort_time

                                                //     //                 if (currentLastPlusOneSort == (currentLastSort + 20)) {
                                                //     //                     firebase.database().ref(`bhodi_products/${this.state.allProductsToCheckSwap[this.state.allIndexes[0] + 1].pushKey}/sort_time`).set(currentLastPlusOneSort + 20)
                                                //     //                 }

                                                //     //                 currentLastSort = currentLastSort + 20
                                                //     //                 firebase.database().ref(`bhodi_products/${product.pushKey}/sort_time`).set(currentLastSort)

                                                //     //                 setTimeout(() => {
                                                //     //                     res()
                                                //     //                 }, 2000);
                                                //     //             } else {
                                                //     //                 res()
                                                //     //             }
                                                //     //         } else {
                                                //     //             res()
                                                //     //         }
                                                //     //     } else {
                                                //     //         res()
                                                //     //     }
                                                //     // })
                                                //     promiseOne.then(() => {
                                                //         if (!product.active) {
                                                //             if (product.inCartOf) {
                                                //                 // obj.inCartOf = currentObject.inCartOf
                                                //                 let entries = Object.entries(product.inCartOf)
                                                //                 entries.map((t) => {
                                                //                     let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                                //                     let obj = {
                                                //                         pushKey: pushKeyNotification,
                                                //                         notification: `${product.sku} was deleted by company`,
                                                //                         created_at: new Date().getTime(),
                                                //                         notificationFor: t[1].cookie
                                                //                     }
                                                //                     firebase.database().ref("notifications_data/" + t[1].cookie + "/" + pushKeyNotification).set(obj)
                                                //                     firebase.database().ref("guest_carts/" + t[1].cookie + "/" + t[1].cartPushKey).remove()
                                                //                 })
                                                //                 firebase.database().ref("bhodi_products/" + product.pushKey + "/inCartOf").remove()
                                                //             }
                                                //         }

                                                //         firebase.database().ref("bhodi_products/" + product.pushKey + "/active").set(product.active)
                                                //     })
                                                // }}
                                                onClick={this.activeUnactiveProduct.bind(this, product)}
                                            />
                                        </p>
                                        <p>
                                            {product.is_bookmarked ? (<AiFillStar onClick={() => {
                                                this.favoriteUnfavoriteProduct(product)
                                            }} />) : (<AiOutlineStar onClick={() => {
                                                this.favoriteUnfavoriteProduct(product)
                                            }} />)}
                                        </p>
                                    </div>)
                                })}
                            </div>
                        </div>
                        {this.state.showLoader && <div className="loadMoreProducts">
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <label>Loading More</label>
                        </div>}
                        {this.state.showMoveToTop && <div className='move-to-top-button' onClick={() => {
                            window.scrollTo(0, 0)
                            this.setState({ showMoveToTop: false })
                        }}>
                            <AiOutlineArrowUp />
                        </div>}
                    </div>
                </div>)}
                {this.state.addEditProductPopup && (<AddEditProduct closePopup={() => {
                    document.querySelector("html").style.overflowY = "auto"
                    // document.getElementById("root").style.overflow = "auto"
                    this.setState({ addEditProductPopup: false, currentProductToEdit: "" })
                }} userIdToSwap={this.props.currentMemberData.user_id} allProductsToCheckSwap={this.state.allProductsToCheckSwap} allIndexes={this.state.allIndexes} allCategories={this.state.completeCategories} allSectors={this.state.sectors} currentProductToEdit={this.state.currentProductToEdit} />)}
                <Dialog
                    open={this.state.deletingProduct}
                    onClose={() => this.setState({ deletingProduct: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-delete-campaigntest"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Weet je zeker dat je het product wilt verwijderen
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                let allImages = this.state.currentProductToDelete.images
                                let promiseFirst = new Promise((res, rej) => {
                                    let removedImages = 0
                                    if (allImages && allImages.length > 0) {
                                        allImages.map((e) => {
                                            let filePath = e.split("/")[7].split("?")[0]
                                            const search = "%2F"
                                            let replacer = new RegExp(search, "g")
                                            let replacedPath = filePath.replace(replacer, "/")
                                            firebase.storage().ref().child(replacedPath).delete().then(() => {
                                                removedImages = removedImages + 1
                                                if (allImages.length === removedImages) {
                                                    res()
                                                }
                                            }).catch(() => {
                                                res()
                                            })
                                        })
                                    } else {
                                        res()
                                    }
                                })

                                promiseFirst.then(() => {
                                    firebase.database().ref("bhodi_products/" + this.state.currentProductToDelete.pushKey).remove()
                                    let allProducts = [...this.props.bhodi_products]
                                    let filteredIndex = allProducts.findIndex((e) => {
                                        return e.pushKey === this.state.currentProductToDelete.pushKey
                                    })
                                    if (filteredIndex !== -1) {
                                        allProducts.splice(filteredIndex, 1)
                                        this.props.updateBhodiProducts(allProducts)
                                    }
                                    this.setState({ deletingProduct: false })
                                })
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                        <Button
                            onClick={() => this.setState({ deletingProduct: false })}
                            color="primary"
                        >
                            NEE
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showingInfoOfProduct}
                    onClose={() => this.setState({ showingInfoOfProduct: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-more-info-showinginfo"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    {this.state.currentProductToShowInfo && (<DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            SKU: {this.state.currentProductToShowInfo.sku}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Stock: {this.getAllStock(this.state.currentProductToShowInfo)}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Stock Available: {this.getAvailableStock(this.state.currentProductToShowInfo)}
                        </DialogContentText>
                    </DialogContent>)}
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ showingInfoOfProduct: false, currentProductToShowInfo: "" })
                            }}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showOnlyDesktop}
                    onClose={() => this.setState({ showOnlyDesktop: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-more-info-showinginfo"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Option only available at desktop
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ showOnlyDesktop: false })
                            }}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.deletingAllProduct}
                    onClose={() => this.setState({ deletingAllProduct: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-delete-campaigntest"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Weet je zeker dat je het product wilt verwijderen
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                let deletedProducts = 0
                                let promiseMain = new Promise((resolve, rej) => {
                                    this.state.allProducts.map((e) => {
                                        let allImages = e.images
                                        let promiseFirst = new Promise((res, rej) => {
                                            let removedImages = 0
                                            if (allImages && allImages.length > 0) {
                                                allImages.map((g) => {
                                                    let filePath = g.split("/")[7].split("?")[0]
                                                    const search = "%2F"
                                                    let replacer = new RegExp(search, "g")
                                                    let replacedPath = filePath.replace(replacer, "/")
                                                    firebase.storage().ref().child(replacedPath).delete().then(() => {
                                                        removedImages = removedImages + 1
                                                        if (allImages.length === removedImages) {
                                                            res()
                                                        }
                                                    }).catch(() => {
                                                        removedImages = removedImages + 1
                                                        if (allImages.length === removedImages) {
                                                            res()
                                                        }
                                                    })
                                                })
                                            } else {
                                                res()
                                            }
                                        })

                                        promiseFirst.then(() => {
                                            if (e.inCartOf) {
                                                let entries = Object.entries(e.inCartOf)
                                                entries.map((t) => {
                                                    let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                                                    let obj = {
                                                        pushKey: pushKeyNotification,
                                                        notification: `${e.sku} was deleted by company`,
                                                        created_at: new Date().getTime(),
                                                        notificationFor: t[1].cookie
                                                    }
                                                    firebase.database().ref("notifications_data/" + t[1].cookie + "/" + pushKeyNotification).set(obj)
                                                    firebase.database().ref("guest_carts/" + t[1].cookie + "/" + t[1].cartPushKey).remove()
                                                })
                                            }
                                            firebase.database().ref("bhodi_products/" + e.pushKey).remove().then(() => {
                                                deletedProducts = deletedProducts + 1
                                                if (deletedProducts === this.state.allProducts.length) {
                                                    resolve()
                                                }
                                            })
                                        })
                                    })
                                })
                                promiseMain.then(() => {
                                    window.location.reload()
                                })
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                        <Button
                            onClick={() => this.setState({ deletingAllProduct: false })}
                            color="primary"
                        >
                            NEE
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.avoidEditProduct}
                    onClose={() => this.setState({ avoidEditProduct: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-delete-campaigntest"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sorry, you are not available to edit this product.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ avoidEditProduct: false })
                            }}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        global_campaigns: state.campaignsReducer.global_campaigns,
        images: state.postsReducers.images,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        subscription_plans: state.globalReducers.subscription_plans,
        globalsectors: state.categoriesReducer.globalsectors,
        products_new: state.campaignsReducer.products_new,
        admin_boolean: state.globalReducers.admin_boolean,
        stock_images: state.globalReducers.stock_images,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (
            guest_user_cart_extend_opportunity
        ) =>
            dispatch(
                updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
            ),
        updateStockImages: (stock_images) =>
            dispatch(updateStockImages(stock_images)),
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminProducts);