import React, { Component } from 'react'
import AdminNavbar from './AdminNavbar'
import axios from "axios";
import firebase from '../Config/Firebase'
import "./admin.css"

export class AdminDashboard extends Component {
  constructor() {
    super()
    this.state = {
      allUsers: [],
      allPosts: [],
      allCampaigns: [],
    }
  }

  componentDidMount() {
    // firebase.database().ref("all_orders").orderByChild("orderAlreadyCounted").equalTo(null).once("value", (data)=>{
    //   let a = data.val()
    //   if (a) {
    //     let ordersToback = []
    //     let objected = Object.entries(a)
    //     objected.map((y)=>{
    //       if (y[1].order_type === "campaign") {
    //         console.log(y[1].products[0], '/see here')
    //       }
    //     })
    //   }
    // })
    // var dataForAPI = JSON.stringify({
    //   sendToEmail: "fahimrazaofficial2022@gmail.com",
    //   subject: "Testing Mail 3!",
    //   innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><p>Hi Fahim,<br /><br />Hartelijk dank voor je aanmelding!<br />We hebben je account aangemaakt op bhodi.com.<br /><a href="https://www.bhodi.com" style="color: black;">Klik op deze link</a> en kies een wachtwoord voor je account.</p><p>Op Bhodi.com krijg je toegang tot allerlei leuke<br />acties en kun je binnenkort je persoonlijke en op<br />naam ingesproken bedankvideo uit Tanzania hier bekijken.</p><br /><p>Met vriendelijke groet,</p><p>Team Bhodi</p><a href="https://www.bhodi.com" style="color: black;">www.bhodi.com</a></body></html>`,
    // });

    // var config = {
    //   method: "post",
    //   url: "https://us-central1-bhodi-7c327.cloudfunctions.net/sendEmailMailjet",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: dataForAPI,
    // };

    // axios(config)
    //   .then((response) => {
    //     if (response.data.statusCode === 201) {
    //       console.log(response)
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    firebase.database().ref("allImages").once("value", (data) => {
      let a = data.val()
      if (a) {
        this.setState({ allPosts: Object.entries(a) })
      }
    })
    firebase.database().ref("users").once("value", (data) => {
      let a = data.val()
      if (a) {
        this.setState({ allUsers: Object.entries(a) })
      }
    })
    firebase.database().ref("bhodi_campaigns").once("value", (data) => {
      let a = data.val()
      if (a) {
        let allCampaigns = []
        let objected = Object.entries(a)
        objected.map((e) => {
          if (e[1]) {
            let objectedInner = Object.entries(e[1])
            objectedInner.map((t) => {
              allCampaigns.push(t)
            })
          }
        })
        this.setState({ allCampaigns })
      }
    })
  }

  checkForUserLength(type) {
    if (this.state.allUsers.length > 0) {
      let filtered = []
      if (type === "member") {
        filtered = this.state.allUsers.filter((t) => {
          return t[1].type === "user" && (!t[1].subscription || (t[1].subscription && t[1].subscription.status !== "active"))
        })
      } else if (type === "member-charity") {
        filtered = this.state.allUsers.filter((t) => {
          return t[1].type === "user" && t[1].subscription && t[1].subscription.status === "active"
        })
      } else if (type === "company") {
        filtered = this.state.allUsers.filter((t) => {
          return t[1].type === "company" && (!t[1].subscription || (t[1].subscription && t[1].subscription.status !== "active"))
        })
      } else if (type === "company-charity") {
        filtered = this.state.allUsers.filter((t) => {
          return t[1].type === "company" && t[1].subscription && t[1].subscription.status === "active"
        })
      }
      return filtered.length
    } else {
      return `0`
    }
  }

  checkForCampaignsLength(type) {
    if (this.state.allCampaigns.length > 0) {
      let filtered = []
      console.log(this.state.allCampaigns)
      if (type === "vouchers") {
        filtered = this.state.allCampaigns.filter((t) => {
          return t[1].campaignType !== "local_event" && t[1].active
        })
      } else if (type === "vouchers-inactive") {
        filtered = this.state.allCampaigns.filter((t) => {
          return t[1].campaignType !== "local_event" && !t[1].active
        })
      } else if (type === "events") {
        filtered = this.state.allCampaigns.filter((t) => {
          return t[1].campaignType === "local_event" && t[1].active
        })
      } else if (type === "events-inactive") {
        filtered = this.state.allCampaigns.filter((t) => {
          return t[1].campaignType === "local_event" && !t[1].active
        })
      }
      return filtered.length
    } else {
      return `0`
    }
  }

  render() {
    return (
      <div>
        <AdminNavbar dashboardPage={true} />
        <div className='admin-dashboard-main'>
          <div className='container-admain'>
            <div className='block-admain'>
              <h2>Vouchers</h2>
              <p>{this.checkForCampaignsLength("vouchers")}</p>
            </div>
            <div className='block-admain'>
              <h2>InActive Vouchers</h2>
              <p>{this.checkForCampaignsLength("vouchers-inactive")}</p>
            </div>
            <div className='block-admain'>
              <h2>Events</h2>
              <p>{this.checkForCampaignsLength("events")}</p>
            </div>
            <div className='block-admain'>
              <h2>InActive Events</h2>
              <p>{this.checkForCampaignsLength("events-inactive")}</p>
            </div>
            <div className='block-admain' style={{ cursor: "pointer" }} onClick={() => {
              window.location.href = "/edit-users"
            }}>
              <h2>Users</h2>
              <p>{this.state.allUsers.length}</p>
            </div>
            <div className='block-admain' style={{ cursor: "pointer" }} onClick={() => {
              window.location.href = "/edit-users?type=member"
            }}>
              <h2>Members</h2>
              <p>{this.checkForUserLength("member")}</p>
            </div>
            <div className='block-admain' style={{ cursor: "pointer" }} onClick={() => {
              window.location.href = "/edit-users?type=member-charity"
            }}>
              <h2>Charity Members</h2>
              <p>{this.checkForUserLength("member-charity")}</p>
            </div>
            <div className='block-admain' style={{ cursor: "pointer" }} onClick={() => {
              window.location.href = "/edit-users?type=company"
            }}>
              <h2>Company</h2>
              <p>{this.checkForUserLength("company")}</p>
            </div>
            <div className='block-admain' style={{ cursor: "pointer" }} onClick={() => {
              window.location.href = "/edit-users?type=company-charity"
            }}>
              <h2>Charity Companies</h2>
              <p>{this.checkForUserLength("company-charity")}</p>
            </div>
            <div className='block-admain'>
              <h2>Posts</h2>
              <p>{this.state.allPosts.length}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminDashboard